<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span class="title">汇报人：</span>
                        <el-input v-model="userName" placeholder="请输入汇报人" style="width: 20%"></el-input>
                        <span class="title" style="margin-left: 15px">日报时间:</span>
                        <el-date-picker style="height: 32px;margin-left: 10px;" v-model="disabledDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetArticleList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px" v-if="roleType == 1">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="汇报人" prop="userName">
                        <!-- <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.userName }}</el-button>
                        </template> -->
                    </el-table-column>
                    <el-table-column label="工作总结" prop="summary"></el-table-column>
                    <el-table-column label="日报时间" prop="reportDate">
                        <template slot-scope="scope">
                            {{scope.row.reportDate.split('00:00:00')[0]}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="openDetails2(scope.row)" type="text" size="mini" class="el-icon-view" style="color: #F56C6C;">查看详情</el-button>
                            <el-button v-if="scope.row.self == '1' && roleType == 1" @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button v-if="scope.row.self == '1' && roleType == 1" @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            disabledDate: "",
            nowdatda: "",
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            userName: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            articleTitle: "",
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            roleType: ""
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getDailyReportList()
        this.roleType = window.localStorage.getItem("roleType")
    },
    methods: {
        // 获取列表
        async getDailyReportList() {
            const data = {
                userName: this.userName,
                dateFrom: this.disabledDate[0],
                dateTo: this.disabledDate[1],
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetDailyReportList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                console.log(this.operationList.reportDate)
                this.total = res.maxRecords
            }
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getDailyReportList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "GongZuoRiBaoDetail",
                query: {
                    dailyReportId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "GongZuoRiBaoDetail",
                query: {
                    dailyReportId: row.dailyReportId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getDailyReportList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getDailyReportList()
        },
        //重置
        cancelbreedList() {
            this.disabledDate = ""
            this.userName = ""
            this.currentPage = 1
            this.getDailyReportList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.dailyReportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteDailyReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then(res => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getDailyReportList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch(err => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        xiangqing(index, row) {
            this.drow = row.dailyReportId
            this.$router.push({
                name: "GongZuoRiBaoDetail",
                query: {
                    dailyReportId: this.drow,
                    num: 2,
                    state: 3
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
