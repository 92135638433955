<template>
    <div>
        <el-card class="search" v-if="isUpdate">
            <el-row>
                <el-col>
                    <el-button size="mini" @click="updateGenJin('','add','新增跟进记录')" type="primary" icon="el-icon-plus">新增</el-button>
                    <el-button @click="deleteGenJin('','')" type="warning" size="mini" icon="el-icon-delete">删除</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-table ref="multipleTable"  v-loading="loading" :data="GenJinList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="genJinJiLuSelection" border stripe>
                <el-table-column type="selection" width="40"> </el-table-column>
                <el-table-column label="客户姓名" prop="customerName"></el-table-column>
                <el-table-column label="跟进日期" prop="genJinTime"></el-table-column>
                <el-table-column label="跟进内容" prop="genJinInfo"></el-table-column>
                <el-table-column label="跟进人" prop="genJinRenName"></el-table-column>
                <!-- <el-table-column label="跟进人" prop="genJinRenName" v-if="roleType!=1"></el-table-column> -->
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作" v-if="isUpdate">
                    <template slot-scope="scope" class="right" >
                        
                        <el-button v-if="scope.row.isDisplay==1" @click.native.stop="updateGenJin(scope.row,'update','修改跟进记录')" type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                        <el-button v-if="roleType!='1'&&scope.row.isDisplay==1||roleType=='1'&&scope.row.genJinUserId==genJinUserId" @click.native.stop="deleteGenJin(scope.row,'oneGe')" type="text" size="mini" class="el-icon-delete caozuo" style="color: #f00">删除</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                    <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                </div>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!-- 带看房源 -->
        <el-dialog title="带看客源" :visible.sync="keyuanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <KeYuanList :isGenJin='true' @colseKeyuanDialog="colseKeyuanDialog"></KeYuanList>
        </el-dialog>
        <!-- 经纪人列表 -->
        <el-dialog title="经纪人列表" :visible.sync="jingjirenDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <TongXunLuList :isQianyueOpen='true' @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>
        <el-dialog :title="GenJinDialogTitle" :visible.sync="dialogFormVisible" :before-close="cancelGenJin" width="50%" class="xuqiuDialog">
            <el-form ref="GenJinRuleForm" :model="GenJinForm" :rules="GenJinRules" label-position="right">
                <el-form-item label="客户" label-width="13%" prop="customerId">
                    <el-button size="mini" class="buttonInput button_tanchu" @click="openKeyuanDialog()" style="width: 95%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="GenJinForm.customerName=='请选择客户'" style="color:#c0c4cc">{{ GenJinForm.customerName }}</span>
                        <span v-if="GenJinForm.customerName!='请选择客户'">{{ GenJinForm.customerName }}</span>
                    </el-button>
                </el-form-item>
                <!-- 新增 -->
                <el-form-item v-if="isClick == 1 && (roleType == '2' ||roleType == '5' || roleType == '6')" label="经纪人" label-width="13%" prop="agentId">
                    <el-button :disabled='disabled' size="mini" class="buttonInput button_tanchu" @click="openjingjirenDialog()" style="width: 95%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="GenJinForm.agentName=='请选择经纪人'" style="color:#c0c4cc">{{ GenJinForm.agentName }}</span>
                        <span v-if="GenJinForm.agentName!='请选择经纪人'">{{ GenJinForm.agentName }}</span>
                    </el-button>
                </el-form-item>
                <!-- 修改 -->
                <el-form-item v-if="isClick == 2 && (roleType == '2' ||roleType == '5' || roleType == '6')" label="经纪人" label-width="13%" prop="agentName">
                    <el-input :disabled='disabled' v-model="GenJinForm.agentName" style="width: 95%;" placeholder="请选择经纪人"></el-input>
                </el-form-item>
                <el-form-item label="跟进日期" label-width="13%" prop="genJinTime">
                    <el-date-picker v-model="GenJinForm.genJinTime" style="width: 95%;" type="datetime" placeholder="请选择跟进日期" :picker-options="pickerOptions" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="跟进内容" label-width="13%" prop="genJinInfo">
                    <el-input maxlength="256" v-model="GenJinForm.genJinInfo" style="width: 95%;" :rows="5" type="textarea" placeholder="请输入跟进内容" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="dialog-footer">
                    <el-button type="warning" size="mini" @click="cancelGenJin">取消</el-button>
                    <el-button type="primary" size="mini" :disabled="GenJinDisabled" @click="saveGenJin">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 跟进需求 -->
        <el-dialog class="xuqiuDialog" title="跟进需求" :visible.sync="GenJinRequirementVisible" width="50%" :before-close="GenJinRequirementClose">
            <el-tabs class="qiugou-tabs" style="top: 0px;background-color: #fff;" v-for="(item,i) in xuqiuList" :key="item.value">
                <el-radio v-model="genJinRequirementChecked" :label="item" :style="{'top':state=='1'?'150px':'80px'}" style="float: right;right: 0;">(请点击按钮选择需求！)</el-radio>
                <el-tab-pane :label="item.requirementName">
                    <div>
                        <el-card>
                            <el-row>
                                <el-col :span="12" v-if="state=='1'">
                                    <span class="titleLabel">购房目的：</span>
                                    <span class="labelValue">{{ruleForm[i].gouFangMuDi}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">房屋用途：</span>
                                    <span class="labelValue">{{ruleForm[i].fangWuYongTu}}</span>
                                </el-col>
                                <el-col :span="12" v-if="state=='0'">
                                    <span class="titleLabel">是否整租:</span>
                                    <span class="labelValue">{{ruleForm[i].isZhengZu}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <span class="titleLabel">意向价位：</span>
                                    <span class="labelValue">{{ruleForm[i].priceFrom}}{{state=='1'?'万元':'元'}}-{{ruleForm[i].priceTo}}{{state=='1'?'万元':'元'}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">需求等级：</span>
                                    <span class="labelValue">{{ruleForm[i].requirementLevel}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <span class="titleLabel">面积：</span>
                                    <span class="labelValue">{{ruleForm[i].squareFrom!=''&&ruleForm[i].SquareTo!=''?ruleForm[i].squareFrom+'㎡-'+ruleForm[i].SquareTo+'㎡':''}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">居室：</span>
                                    <span class="labelValue">{{ruleForm[i].roomCountFrom!=''&&ruleForm[i].roomCountTo!=''?ruleForm[i].roomCountFrom + "居"+'-'+ruleForm[i].roomCountTo + "居":''}}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <span class="titleLabel">城市：</span>
                                    <span class="labelValue">{{ruleForm[i].city}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">意向商圈：</span>
                                    <span class="labelValue">{{ruleForm[i].areaNames}}</span>
                                </el-col>
                            </el-row>
                            <el-row v-if="state=='1'">
                                <el-col :span="12">
                                    <span class="titleLabel">装修状况：</span>
                                    <span class="labelValue">{{ruleForm[i].zhuangXiuZhuangKuang}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">朝向：</span>
                                    <span class="labelValue">{{ruleForm[i].orientations.length>0?ruleForm[i].orientations.toString():''}}</span>
                                </el-col>
                            </el-row>
                            <el-row v-if="state=='1'">
                                <el-col :span="12">
                                    <span class="titleLabel">楼层：</span>
                                    <span class="labelValue">{{ruleForm[i].louCengFrom!=''&&ruleForm[i].louCengTo!=''?ruleForm[i].louCengFrom+'层-'+ruleForm[i].louCengTo+'层':''}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">楼龄：</span>
                                    <span class="labelValue">{{ruleForm[i].houseAgeFrom!=''&&ruleForm[i].houseAgeTo!=''?ruleForm[i].houseAgeFrom+'年-'+ruleForm[i].houseAgeTo+'年':''}}</span>
                                </el-col>
                            </el-row>
                            <el-row v-if="state=='1'">
                                <el-col :span="12">
                                    <span class="titleLabel">贷款首付/全款可付：</span>
                                    <span class="labelValue">{{ruleForm[i].ShouFuFrom!=''&&ruleForm[i].ShouFuTo!=''?ruleForm[i].ShouFuFrom+'万元-'+ruleForm[i].ShouFuTo+'万元':''}}</span>
                                </el-col>
                                <el-col :span="12">
                                    <span class="titleLabel">付款方式：</span>
                                    <span class="labelValue">{{ruleForm[i].FuKuanFangShi}}</span>
                                </el-col>
                            </el-row>
                            <el-row>

                                <el-col :span="12" v-if="state=='1'&&ruleForm[i].FuKuanFangShi!='全款'">
                                    <span class="titleLabel">月供：</span>
                                    <span class="labelValue">{{ruleForm[i].YueGongFrom!=''&&ruleForm[i].YueGongTo!=''?ruleForm[i].YueGongFrom+'元-'+ruleForm[i].YueGongTo+'元':''}}</span>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>

<script>
import KeYuanList from "../../components/KeYuan/KeYuanList.vue"
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue"

export default {
    name: "GetStaffList",
    inject: ["reload"],
    components: { KeYuanList, TongXunLuList },
    data() {
        return {
            roleType: "",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            dialogFormVisible: false,
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            GenJinType: "",
            GenJinDialogTitle: "",
            customerId: "",
            GenJinForm: {
                customerId: "",
                customerName: "请选择客户",
                agentId: "",
                agentName: "请选择经纪人",
                genJinRequirementId: "",
                genJinTime: "",
                genJinInfo: ""
            },
            GenJinDisabled: false,
            keyuanDialog: false,
            jingjirenDialog: false,
            GenJinRequirementVisible: false,
            orientations: [0, 0, 0, 0, 0, 0, 0, 0],
            GenJinRules: {
                customerId: [
                    {
                        required: true,
                        message: "请选择客户",
                        trigger: "change"
                    }
                ],
                agentId: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "change"
                    }
                ],
                genJinRequirementId: [
                    {
                        required: true,
                        message: "请选择跟进需求",
                        trigger: "change"
                    }
                ],
                genJinTime: [
                    {
                        required: true,
                        message: "请选择跟进日期",
                        trigger: "change"
                    }
                ],
                genJinInfo: [
                    {
                        required: true,
                        message: "请输入跟进内容",
                        trigger: "blur"
                    }
                ],
                agentName: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "blur"
                    }
                ]
            },
            isClick: "",
            GenJinList: [],
            checkedGenJinIdList: [],
            ruleForm: [],
            xuqiuList: [],
            state: "",
            zhuangxiuOptions: [],
            cascaderShangquanOptions: [],
            genJinRequirementChecked: "",
            orientationOptions: this.$commonJs.orientationOptions,
            disabled: false,
            isUpdate: false,
            genJinUserId:""
        }
    },
    created() {
        //获取角色
        this.roleType = localStorage.getItem("roleType")
        this.genJinUserId = window.localStorage.getItem("userId")
        if (this.roleType == 1|| this.roleType == 2 || this.roleType == 6) {
            this.isUpdate = true //是否可以修改
        }
        /* 获取所有的日志列表 */
        this.GetGenJinList()
        this.getShuJuZiDianList()
    },
    watch: {
        genJinRequirementChecked() {
            this.GenJinForm.genJinRequirementId =
                this.genJinRequirementChecked.id
            this.GenJinRequirementVisible = false
        }
    },
    methods: {
        //数据字典
        getShuJuZiDianList() {
            let that = this
            that.$commonJs
                .getShuJuZiDianList(
                    "XINXILAIYUAN,KEHUBIAOQIAN,FANGWUYONGTU,ZHUANGXIUZHUANGKUANG,SHANGQUAN"
                )
                .then((data) => {
                    that.zhuangxiuOptions = data.ZHUANGXIUZHUANGKUANG
                    that.cascaderShangquanOptions = data.cascaderShangquan
                })
        },
        //显示跟进需求
        GenJinRequirement() {
            if (this.GenJinForm.customerId == "") {
                this.$message("请选择客户")
            } else if (this.customerId != this.GenJinForm.customerId) {
                this.GenJinRequirementVisible = true
                this.GetCustomerDetail()
            } else {
                this.GenJinRequirementVisible = true
            }
            this.customerId = this.GenJinForm.customerId
        },
        /* 获取跟进需求列表 */
        GetCustomerDetail() {
            let that = this,
                dtRequirements = []
            that.xuqiuList = []
            that.ruleForm = []
            that.ruleFormDetail = []
            that.loading = true
            that.$http
                .get(that.$url.GetCustomerDetail, {
                    params: {
                        customerId: this.GenJinForm.customerId //客源id
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (
                            that.state == "1" &&
                            res.data.dtRequirementsToBuy.length > 0
                        ) {
                            dtRequirements = res.data.dtRequirementsToBuy //求购需求
                        }
                        if (
                            that.state == "0" &&
                            res.data.dtRequirementsToRent.length > 0
                        ) {
                            dtRequirements = res.data.dtRequirementsToRent //求租需求
                        }
                        if (dtRequirements.length > 0) {
                            dtRequirements.forEach((item, index) => {
                                item.shangquan = []
                                item.xuqiuType = ""
                                item.yiTuijian = false
                                item.id =
                                    that.state == "1"
                                        ? item.requirementToBuyId
                                        : item.requirementToRentId

                                item.priceFrom =
                                    that.state == "1"
                                        ? item.priceFrom / 10000
                                        : item.priceFrom
                                item.priceTo =
                                    that.state == "1"
                                        ? item.priceTo / 10000
                                        : item.priceTo
                                item.ShouFuFrom =
                                    that.state == "1"
                                        ? item.ShouFuFrom / 10000
                                        : item.ShouFuFrom
                                item.ShouFuTo =
                                    that.state == "1"
                                        ? item.ShouFuTo / 10000
                                        : item.ShouFuTo

                                that.zhuangxiuOptions.forEach((element) => {
                                    //装修状况
                                    if (
                                        element.value ===
                                        item.zhuangXiuZhuangKuangId
                                    ) {
                                        item.zhuangXiuZhuangKuang =
                                            element.label
                                        return false
                                    }
                                })
                                item.areaNames = item.areaNames.replace(
                                    //意向商圈
                                    "/",
                                    "-"
                                )
                                let areaIds = item.areaIds.split(",")
                                that.cascaderShangquanOptions.forEach(
                                    //意向商圈
                                    (cascaderShangquan, index) => {
                                        cascaderShangquan.children.forEach(
                                            (children, x) => {
                                                areaIds.forEach((areaId, y) => {
                                                    if (
                                                        children.value ===
                                                        areaId
                                                    ) {
                                                        item.shangquan.push([
                                                            cascaderShangquan.label,
                                                            children.value
                                                        ])
                                                    }
                                                })
                                            }
                                        )
                                    }
                                )
                                if (
                                    item.orientations != "" &&
                                    item.orientations != undefined &&
                                    item.orientations != null
                                ) {
                                    //朝向
                                    let orientations = item.orientations
                                        .toString()
                                        .split("")
                                    item.orientations = []
                                    that.orientationOptions.forEach(
                                        (element, y) => {
                                            if (orientations[y] != "0") {
                                                item.orientations.push(
                                                    element.label
                                                )
                                                that.orientations[y] = 1
                                            }
                                        }
                                    )
                                } else {
                                    item.orientations = []
                                }
                                that.xuqiuList.push(item)
                            })
                        }
                        that.ruleForm = that.xuqiuList
                        console.log("that.ruleForm", that.ruleForm)
                        that.ruleFormDetail = JSON.parse(
                            JSON.stringify(that.xuqiuList)
                        )
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        /* 获取列表 */
        GetGenJinList() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetGenJinList, {
                    params: {
                        isCustomerGenJin: "1",
                        isHomePage: "0",
                        currentPage: that.currentPage,
                        pageSize: that.pageSize
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            that.GenJinList = res.data.dtList
                        } else {
                            that.GenJinList = []
                        }
                        that.total = res.data.maxRecords
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        colseKeyuanDialog(keyuanData) {
            this.state = keyuanData.state
            this.GenJinForm.customerId = keyuanData.customerId
            this.GenJinForm.customerName = keyuanData.customerName
            this.keyuanDialog = false
        },
        openKeyuanDialog() {
            this.keyuanDialog = true
        },
        //选中后关闭经纪人弹层
        colseJingJiRenDialog(jingJiRenData) {
            this.GenJinForm.agentName = jingJiRenData.userName
            this.GenJinForm.agentId = jingJiRenData.userId
            this.jingjirenDialog = false
        },
        //经纪人弹层
        openjingjirenDialog() {
            this.jingjirenDialog = true
        },
        //关闭跟进需求弹层
        GenJinRequirementClose() {
            this.GenJinRequirementVisible = false
        },
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetGenJinList()
        },
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetGenJinList()
        },
        updateGenJin(item, type, title) {
            console.log(type)
            if (type == "add") {
                this.isClick = 1
            }
            if (type == "update") {
                this.isClick = 2
            }
            this.dialogFormVisible = true
            this.GenJinType = type
            this.GenJinDialogTitle = title
            if (item != "") {
                this.disabled = true
                this.GenJinForm = Object.assign(this.GenJinForm, item)
                this.genJinRequirementChecked = this.GenJinForm
                this.GenJinForm.agentName = this.GenJinForm.genJinRenName
                this.GenJinForm.agentId = item.userId
            } else {
                this.disabled = false
                this.genJinRequirementChecked = ""
                this.GenJinForm.genJinId = ""
                this.GenJinForm.customerName = "请选择客户"
                this.GenJinForm.genJinTime = ""
                this.GenJinForm.genJinInfo = ""
                this.GenJinForm.agentId = ""
                this.GenJinForm.agentName = "请选择经纪人"
                this.GenJinForm.genJinRequirementId = ""
                this.genJinRequirementChecked = ""
            }
        },
        saveGenJin() {
            let that = this,
                url = "",
                method = ""
            that.$refs.GenJinRuleForm.validate(async (valid) => {
                if (valid) {
                    that.GenJinDisabled = true
                    const data = {
                        customerId: that.GenJinForm.customerId,
                        genJinTime: that.$commonJs.dateTimeFormatter(
                            that.GenJinForm.genJinTime
                        ),
                        genJinInfo: that.GenJinForm.genJinInfo
                    }
                    if (this.GenJinType == "add") {
                        url = that.$url.InsertGenJin
                        ;(data.userId = that.GenJinForm.agentId),
                            (method = "post")
                    } else {
                        url = that.$url.UpdateGenJin
                        data.genJinId = that.GenJinForm.genJinId
                        method = "put"
                    }
                    that.$http[method](url, data, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    }).then((res) => {
                        if (res.data.msgCode == 1) {
                            that.dialogFormVisible = false
                            if (that.GenJinType == "add") {
                                that.$message.success("跟进信息新增成功！")
                            } else {
                                that.$message.success("跟进信息修改成功！")
                            }
                            that.GenJinDisabled = false
                            that.GetGenJinList()
                        } else {
                            that.GenJinDisabled = false
                            that.$message.error(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        cancelGenJin() {
            this.dialogFormVisible = false
            this.genJinRequirementChecked = ""
            this.GenJinForm.customerId = ""
            this.GenJinForm.customerName = "请选择客户"
            this.GenJinForm.agentId = ""
            this.GenJinForm.agentName = "请选择经纪人"
            this.GenJinForm.genJinRequirementId = ""
            this.GenJinForm.genJinTime = ""
            this.GenJinForm.genJinInfo = ""
        },
        genJinJiLuSelection(val) {
            this.checkedGenJinIdList = []
            if (val.length > 0) {
                val.forEach((item) => {
                    this.checkedGenJinIdList.push(item.genJinId)
                })
            }
        },
        deleteGenJin(item, type) {
            let that = this,
                genJinId = []
            if (type == "oneGe") {
                genJinId.push(item.genJinId)
            } else {
                if (that.checkedGenJinIdList == 0) {
                    that.$message.warning("请选择要删除的跟进记录！")
                    return false
                } else {
                    genJinId = that.checkedGenJinIdList
                }
            }
            this.$confirm("确认要删除吗？")
                .then((_) => {
                    that.loading = true
                    this.$http
                        .delete(this.$url.DeleteGenJin, {
                            data: genJinId,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            that.loading = false
                            if (res.data.msgCode == 1) {
                                // that.$message.success("跟进记录删除成功！")
                                that.GetGenJinList()
                            } else {
                                that.$message.error(res.data.message)
                            }
                        })
                    done()
                })
                .catch((_) => {})
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.qiugou-tabs {
    font-size: 14px;
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        padding-left: 20px;
        margin: 0px;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-card__body {
        margin: 5px 20px;
        padding: 0px !important;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }
}
.xuqiuDialog /deep/ .el-dialog__body {
    padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
    right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
    padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
    margin: 2px 0 2px 3px !important;
}
/deep/.xuqiuDialog .el-tabs {
    margin-top: -5px !important;
    .el-tabs__header {
        border-bottom: none !important;
        margin: 0px;
        .el-tabs__nav {
            color: #000 !important;
            border: none !important;
        }
        .el-tabs__nav > .is-active {
            color: rgb(238, 103, 22) !important;
            background: transparent !important;
        }
        .el-tabs__item {
            border-left: none !important;
        }
    }
    .el-tabs__content {
        margin-left: 14px !important;
    }
}
</style>
