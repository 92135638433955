import Vue from "vue";
import Router from "vue-router";
import Home from "../components/Home.vue";
import GetStatistics from "../components/Home/GetStatistics.vue"; // 首页
import GetLogin from "../components/Home/GetLogin.vue"; // 登陆
import UpdatePassword from "../components/Home/UpdatePassword.vue"; // 修改密码
import Personal from "../components/Home/Personal.vue"; // 个人中心
import JiXiaoTongJiList from "../components/JiXiaoTongJi/JiXiaoTongJiList.vue"; // 作业统计列表
// import JiXiaoTongJiDetail from "../components/JiXiaoTongJi/JiXiaoTongJiDetail.vue"; // 作业统计详情

import XinFangList from "../components/XinFang/XinFangList.vue"; // 新房列表
import XinFangDetail from "../components/XinFang/XinFangDetail.vue"; // 新房详情
import ChuZuList from "../components/ChuZu/ChuZuList.vue"; // 二手房出租列表
import ChuZuDetail from "../components/ChuZu/ChuZuDetail.vue"; // 二手房出租详情
import ChuShouList from "../components/ChuShou/ChuShouList.vue"; // 二手房出售列表
import ChuShouDetail from "../components/ChuShou/ChuShouDetail.vue"; // 二手房出售详情
import FenPeiZiYuanList from "../components/FenPeiZiYuan/FenPeiZiYuanList.vue"; //分配房源
import KeYuanList from "../components/KeYuan/KeYuanList.vue"; // 求购列表
import AddKeYuan from "../components/KeYuan/AddKeYuan.vue"; // 求购新增
import KeYuanDetail from "../components/KeYuan/KeYuanDetail.vue"; // 求购详情
import FangYuanGenJinJiLuList from "../components/FangYuanGenJinJiLu/FangYuanGenJinJiLuList.vue"; // 房源跟进记录

import KeYuanChuShouList from "../components/KeYuan/KeYuanChuShouList.vue"; // 客源二手房出售列表

import FenPeiKeYuanList from "../components/KeYuan/FenPeiKeYuanList.vue"; // 分配客源列表

import KeHuGenJinJiLuList from "../components/KeHuGenJinJiLu/KeHuGenJinJiLuList.vue"; // 客户跟进记录列表
import KeHuGenJinJiLuDetail from "../components/KeHuGenJinJiLu/KeHuGenJinJiLuDetail.vue";

import KeYuanZiXunList from "../components/KeYuanZiXunList/KeYuanZiXunList.vue"; // 客户咨询列表

import XianSuoList from "../components/XianSuo/XianSuoList.vue"; // 线索列表
import XianSuoDetail from "../components/XianSuo/XianSuoDetail.vue"; // 线索详情
import QianYueList from "../components/QianYue/QianYueList.vue"; // 签约列表
import QianYueDetail from "../components/QianYue/QianYueDetail.vue"; // 签约详情
import AddQianYue from "../components/QianYue/AddQianYue.vue"; // 签约详情
import AddQianYueOffline from "../components/QianYue/AddQianYueOffline.vue"; // 线下合同创建
import ZhengCeWenJianList from "../components/ZhengCeWenJian/ZhengCeWenJianList.vue"; // 政策文件查询列表
import ZhengCeWenJianDetail from "../components/ZhengCeWenJian/ZhengCeWenJianDetail.vue"; // 政策文件查询详情
import TongXunLuList from "../components/TongXunLu/TongXunLuList.vue"; // 通讯录列表
import TongXunLuDetail from "../components/TongXunLu/TongXunLuDetail.vue"; // 通讯录详情
import YeJiMingXiList from "../components/YeJiMingXi/YeJiMingXiList.vue"; // 业绩明细列表
import YeJiMingXiDetail from "../components/YeJiMingXi/YeJiMingXiDetail.vue"; // 业绩明细详情
import GongZuoRiBaoList from "../components/GongZuoRiBao/GongZuoRiBaoList.vue"; // 工作日报列表
import GongZuoRiBaoDetail from "../components/GongZuoRiBao/GongZuoRiBaoDetail.vue"; // 工作日报详情

import GongGaoList from "../components/GongGaoGuanLi/GongGaoList.vue"; // 公告管理列表
import GongGaoDetail from "../components/GongGaoGuanLi/GongGaoDetail.vue"; // 公告管理详情
import GongZiList from "../components/GongZi/GongZiList.vue"; // 工资列表
import GongZiDetail from "../components/GongZi/GongZiDetail.vue"; // 工资详情
import BaoXiaoJiLuList from "../components/BaoXiaoJiLu/BaoXiaoJiLuList.vue"; // 报销记录列表
import BaoXiaoJiLuDetail from "../components/BaoXiaoJiLu/BaoXiaoJiLuDetail.vue"; // 报销记录详情
import RenYuanGuanLiList from "../components/RenYuanGuanLi/RenYuanGuanLiList.vue"; // 人员管理列表
import RenYuanGuanLiDetail from "../components/RenYuanGuanLi/RenYuanGuanLiDetail.vue"; // 人员管理详情
import ZuZhiJiaGouList from "../components/ZuZhiJiaGou/ZuZhiJiaGouList.vue"; // 组织架构列表
import ZuZhiJiaGouDetail from "../components/ZuZhiJiaGou/ZuZhiJiaGouDetail.vue"; // 组织架构详情

import DianPuList from "../components/DianPu/DianPuList.vue"; // 店铺列表
import DianPuDetail from "../components/DianPu/DianPuDetail.vue"; // 店铺列表

import JiaoYiQuanShuList from "../components/JiaoYiQuanShu/JiaoYiQuanShuList.vue"; // 交易权属列表

import HaiBaoList from "../components/HaiBao/HaiBaoList.vue"; // 打印海报列表

import TiChengBiList from "../components/TiChengBi/TiChengBiList.vue"; // 提成比列表
import TiChengBiDetail from "../components/TiChengBi/TiChengBiDetail.vue"; // 提成比详情
import JueSeQuanXianList from "../components/JueSeQuanXian/JueSeQuanXianList.vue"; // 角色权限列表
import JueSeQuanXianDetail from "../components/JueSeQuanXian/JueSeQuanXianDetail.vue"; // 角色权限详情
import ShangHuGuanLiList from "../components/ShangHuGuanLi/ShangHuGuanLiList.vue"; // 商户管理列表
import ShangHuGuanLiDetail from "../components/ShangHuGuanLi/ShangHuGuanLiDetail.vue"; // 商户管理详情
import LouPanZiDian1List from "../components/LouPanZiDian1/LouPanZiDian1List.vue"; // 管理员楼盘字典列表
import LouPanZiDian1Detail1 from "../components/LouPanZiDian1/LouPanZiDian1Detail1.vue"; // 管理员楼盘字典详情1
import LouPanZiDian1Detail2 from "../components/LouPanZiDian1/LouPanZiDian1Detail2.vue"; // 管理员楼盘字典详情2
import RiZhiList from "../components/RiZhi/RiZhiList.vue"; // 日志列表
import RiZhiDetail from "../components/RiZhi/RiZhiDetail.vue"; // 日志详情
import Zhuce from "../components/Home/Zhuce.vue"; // 注册
import AllAgentList from "../components/AllAgent/AllAgentList.vue"; // 首页查看待办事项
import XiaoXiZhongXinList from "../components/XiaoXiZhongXin/XiaoXiZhongXinList.vue"; // 消息中心列表

import GongXiangChiList from "../components/GongXiangChi/GongXiangChiList.vue"; // 共享池列表
import GongXiangChiDetail from "../components/GongXiangChi/GongXiangChiDetail.vue"; // 共享池详情
import YaoShiList from "../components/YaoShi/YaoShiList.vue"; // 钥匙列表
import YaoShiDetail from "../components/YaoShi/YaoShiDetail.vue"; // 钥匙详情
import ZuoYeJiLuList from "../components/ZuoYeJiLu/ZuoYeJiLuList.vue"; // 作业记录列表
import JiaoYiJiLuList from "../components/JiaoYiJiLu/JiaoYiJiLuList.vue"; // 交易记录列表
import JiaoYiJiLuDetail from "../components/JiaoYiJiLu/JiaoYiJiLuDetail.vue"; // 交易记录详情
import TuiKuanShenQingList from "../components/TuiKuanShenQing/TuiKuanShenQingList.vue"; // 退款申请列表
import TuiKuanShenQingDetail from "../components/TuiKuanShenQing/TuiKuanShenQingDetail.vue"; // 退款申请详情
import ShouXuList from "../components/ShouXu/ShouXuList.vue"; // 手续列表
import ShouXuDetail from "../components/ShouXu/ShouXuDetail.vue"; // 手续详情
import YeJiList from "../components/YeJi/YeJiList.vue"; // 业绩列表
import YeJiAdd from "../components/YeJi/YeJiAdd.vue"; // 业绩新增

import YeJiDetail from "../components/YeJi/YeJiDetail.vue"; // 业绩详情
import CaiWuList from "../components/CaiWu/CaiWuList.vue"; // 财务列表
import shoujuList from "../components/CaiWu/shoujuList.vue"; // 收据

import YingShouDetail from "../components/CaiWu/YingShouDetail.vue"; // 应收详情
import FangYuanXianSuoList from "../components/FangYuanXianSuo/FangYuanXianSuoList.vue"; //房源线索列表
import FangYuanXianSuoDetail from "../components/FangYuanXianSuo/FangYuanXianSuoDetail.vue"; //房源线索详情
import GuiZhangZhiDuList from "../components/GuiZhangZhiDu/GuiZhangZhiDuList.vue"; //规章制度列表
import GuiZhangZhiDuDetail from "../components/GuiZhangZhiDu/GuiZhangZhiDuDetail.vue"; //规章制度详情
import PeiXunKeChengList from "../components/PeiXunKeCheng/PeiXunKeChengList.vue"; //培训课程列表
import PeiXunKeChengDetail from "../components/PeiXunKeCheng/PeiXunKeChengDetail.vue"; //培训课程详情
import FangYuanGongXiangChiList from "../components/FangYuanGongXiangChi/FangYuanGongXiangChiList.vue"; //房源共享池列表
import FangYuanGongXiangChiDetail from "../components/FangYuanGongXiangChi/FangYuanGongXiangChiDetail.vue"; //房源共享池详情

import KeYuanGongXiangChiList from "../components/KeYuanGongXiangChi/KeYuanGongXiangChiList.vue";
import ShiKanDetail from "../components/ZuShouDetail/ShiKanDetail.vue"; //房源租售实勘强请
import FangYuanYaoShiDetail from "../components/ZuShouDetail/YaoShiDetail.vue"; //房源租售钥匙详情
import ZhengJianDetail from "../components/ZuShouDetail/ZhengJianDetail.vue"; //房源租售证件详情
import YeZhuXinXi from "../components/ZuShouDetail/YeZhuXinXi.vue"; //房源租售vip详情
import WeiHuDetail from "../components/ZuShouDetail/WeiHuDetail.vue"; //房源租售vip详情
import ZuLinTemplate from "../components/QianYue/WordTemplate/ZuLinTemplate.vue"; //租赁合同模板
import MaiMaiTemplate from "../components/QianYue/WordTemplate/MaiMaiTemplate.vue"; //租赁合同模板
import CheWeiZuLinTemplate from "../components/QianYue/WordTemplate/CheWeiZuLinTemplate.vue"; //车位租赁合同模板
import CheWeiMaiMaiTemplate from "../components/QianYue/WordTemplate/CheWeiMaiMaiTemplate.vue"; //车位买卖合同模板
import Contract from "../components/QianYue/Contract.vue"; //合同附件

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", redirect: "/GetLogin" },
    { path: "/GetLogin", component: GetLogin },
    {
      path: "/",
      component: Home,
      redirect: "/GetStatistics",
      children: [
        {
          path: "/GetStatistics",
          name: "GetStatistics",
          component: GetStatistics
        }, // 首页
        {
          path: "/UpdatePassword",
          name: "UpdatePassword",
          component: UpdatePassword
        }, // 修改密码
        { path: "/Personal", name: "Personal", component: Personal }, // 个人中心
        {
          path: "/JiXiaoTongJiList",
          name: "JiXiaoTongJiList",
          component: JiXiaoTongJiList
        }, // 绩效统计列表
        // {
        //   path: "/JiXiaoTongJiDetail",
        //   name: "JiXiaoTongJiDetail",
        //   component: JiXiaoTongJiDetail
        // }, // 绩效统计详情

        { path: "/XinFangList", name: "XinFangList", component: XinFangList }, // 新房列表
        {
          path: "/XinFangDetail",
          name: "XinFangDetail",
          component: XinFangDetail
        }, // 新房详情
        { path: "/ChuZuList", name: "ChuZuList", component: ChuZuList }, // 二手房出租列表
        { path: "/ChuZuDetail", name: "ChuZuDetail", component: ChuZuDetail }, // 二手房出租详情
        { path: "/ChuShouList", name: "ChuShouList", component: ChuShouList }, // 二手房出售列表
        {
          path: "/ChuShouDetail",
          name: "ChuShouDetail",
          component: ChuShouDetail
        }, // 二手房出售详情
        {
          path: "/FenPeiZiYuanList",
          name: "FenPeiZiYuanList",
          component: FenPeiZiYuanList
        }, // 分配房源
        { path: "/KeYuanList", name: "KeYuanList", component: KeYuanList },
        // 房源跟进记录
        {
          path: "/FangYuanGenJinJiLuList",
          name: "FangYuanGenJinJiLuList",
          component: FangYuanGenJinJiLuList
        },
        {
          path: "/AddKeYuan",
          name: "AddKeYuan",
          component: AddKeYuan
        }, // 求购新增
        {
          path: "/KeYuanDetail",
          name: "KeYuanDetail",
          component: KeYuanDetail
        }, // 求购详情
        {
          path: "/KeYuanChuShouList",
          name: "KeYuanChuShouList",
          component: KeYuanChuShouList
        }, // 分配客源
        {
          path: "/FenPeiKeYuanList",
          name: "FenPeiKeYuanList",
          component: FenPeiKeYuanList
        }, // 客源房源列表
        {
          path: "/KeHuGenJinJiLuList",
          name: "KeHuGenJinJiLuList",
          component: KeHuGenJinJiLuList
        }, // 客户跟进记录列表
        {
          path: "/KeHuGenJinJiLuDetail",
          name: "KeHuGenJinJiLuDetail",
          component: KeHuGenJinJiLuDetail
        }, // 客户跟进记录详情
        {
          path: "/KeYuanZiXunList",
          name: "KeYuanZiXunList",
          component: KeYuanZiXunList
        }, // 客户咨询列表
        {
          path: "/XianSuoList",
          name: "XianSuoList",
          component: XianSuoList
        }, // 线索列表
        {
          path: "/XianSuoDetail",
          name: "XianSuoDetail",
          component: XianSuoDetail
        }, // 线索详情
        { path: "/QianYueList", name: "QianYueList", component: QianYueList }, // 签约列表
        {
          path: "/QianYueDetail",
          name: "QianYueDetail",
          component: QianYueDetail
        }, // 签约详情
        {
          path: "/AddQianYue",
          name: "AddQianYue",
          component: AddQianYue
        }, // 添加签约
        {
          path: "/AddQianYueOffline",
          name: "AddQianYueOffline",
          component: AddQianYueOffline
        }, // 添加签约
        {
          path: "/ZhengCeWenJianList",
          name: "ZhengCeWenJianList",
          component: ZhengCeWenJianList
        }, // 政策文件查询列表
        {
          path: "/ZhengCeWenJianDetail",
          name: "ZhengCeWenJianDetail",
          component: ZhengCeWenJianDetail
        }, // 政策文件查询详情
        {
          path: "/TongXunLuList",
          name: "TongXunLuList",
          component: TongXunLuList
        }, // 通讯录列表
        {
          path: "/TongXunLuDetail",
          name: "TongXunLuDetail",
          component: TongXunLuDetail
        }, // 通讯录详情
        {
          path: "/YeJiMingXiList",
          name: "YeJiMingXiList",
          component: YeJiMingXiList
        }, // 业绩明细列表
        {
          path: "/YeJiMingXiDetail",
          name: "YeJiMingXiDetail",
          component: YeJiMingXiDetail
        }, // 业绩明细详情
        {
          path: "/GongZuoRiBaoList",
          name: "GongZuoRiBaoList",
          component: GongZuoRiBaoList
        }, // 工作日报列表
        {
          path: "/GongZuoRiBaoDetail",
          name: "GongZuoRiBaoDetail",
          component: GongZuoRiBaoDetail
        }, // 工作日报详情
        { path: "/GongGaoList", name: "GongGaoList", component: GongGaoList }, // 公告管理列表
        {
          path: "/GongGaoDetail",
          name: "GongGaoDetail",
          component: GongGaoDetail
        }, // 公告管理详情
        { path: "/GongZiList", name: "GongZiList", component: GongZiList }, // 工资列表
        {
          path: "/GongZiDetail",
          name: "GongZiDetail",
          component: GongZiDetail
        }, // 工资详情
        {
          path: "/BaoXiaoJiLuList",
          name: "BaoXiaoJiLuList",
          component: BaoXiaoJiLuList
        }, // 报销记录列表
        {
          path: "/BaoXiaoJiLuDetail",
          name: "BaoXiaoJiLuDetail",
          component: BaoXiaoJiLuDetail
        }, // 报销记录详情
        {
          path: "/RenYuanGuanLiList",
          name: "RenYuanGuanLiList",
          component: RenYuanGuanLiList
        }, // 人员管理列表
        {
          path: "/RenYuanGuanLiDetail",
          name: "RenYuanGuanLiDetail",
          component: RenYuanGuanLiDetail
        }, // 人员管理详情
        {
          path: "/ZuZhiJiaGouList",
          name: "ZuZhiJiaGouList",
          component: ZuZhiJiaGouList
        }, // 组织架构列表
        {
          path: "/ZuZhiJiaGouDetail",
          name: "ZuZhiJiaGouDetail",
          component: ZuZhiJiaGouDetail
        }, // 组织架构详情
        {
          path: "/TiChengBiList",
          name: "TiChengBiList",
          component: TiChengBiList
        }, // 数据字典列表
        {
          path: "/TiChengBiDetail",
          name: "TiChengBiDetail",
          component: TiChengBiDetail
        }, // 数据字典详情
        {
          path: "/JueSeQuanXianList",
          name: "JueSeQuanXianList",
          component: JueSeQuanXianList
        }, // 角色权限列表
        {
          path: "/JueSeQuanXianDetail",
          name: "JueSeQuanXianDetail",
          component: JueSeQuanXianDetail
        }, // 角色权限详情
        {
          path: "/ShangHuGuanLiList",
          name: "ShangHuGuanLiList",
          component: ShangHuGuanLiList
        }, // 商户管理列表
        {
          path: "/ShangHuGuanLiDetail",
          name: "ShangHuGuanLiDetail",
          component: ShangHuGuanLiDetail
        }, // 商户管理详情
        {
          path: "/LouPanZiDian1List",
          name: "LouPanZiDian1List",
          component: LouPanZiDian1List
        }, // 管理员楼盘字典列表

        {
          path: "/HaiBaoList",
          name: "HaiBaoList",
          component: HaiBaoList
        }, // 海报
        {
          path: "/LouPanZiDian1Detail1",
          name: "LouPanZiDian1Detail1",
          component: LouPanZiDian1Detail1
        }, // 管理员楼盘字典详情1
        {
          path: "/LouPanZiDian1Detail2",
          name: "LouPanZiDian1Detail2",
          component: LouPanZiDian1Detail2
        }, // 管理员楼盘字典详情2
        { path: "/RiZhiList", name: "RiZhiList", component: RiZhiList }, // 日志列表
        { path: "/RiZhiDetail", name: "RiZhiDetail", component: RiZhiDetail }, // 日志详情
        { path: "/Zhuce", name: "Zhuce", component: Zhuce }, // 注册
        {
          path: "/AllAgentList",
          name: "AllAgentList",
          component: AllAgentList
        }, // 注册
        {
          path: "/XiaoXiZhongXinList",
          name: "XiaoXiZhongXinList",
          component: XiaoXiZhongXinList
        }, // 消息中心列表

        {
          path: "/KeHuBiaoQianList",
          name: "KeHuBiaoQianList",
          component: JiaoYiQuanShuList
        }, //客户标签

        {
          path: "/HuXingList",
          name: "HuXingList",
          component: JiaoYiQuanShuList
        },
        {
          path: "/HuXingDetail",
          name: "HuXingDetail",
          component: JiaoYiQuanShuList
        },
        {
          path: "/WuYeLeiXingList",
          name: "WuYeLeiXingList",
          component: JiaoYiQuanShuList
        },
        {
          path: "/FuYongFangShiList",
          name: "FuYongFangShiList",
          component: JiaoYiQuanShuList
        },
        {
          path: "/XinXiLaiYuanList",
          name: "XinXiLaiYuanList",
          component: JiaoYiQuanShuList
        },

        {
          path: "/ZhuangxiuzhuangkuangList",
          name: "ZhuangxiuzhuangkuangList",
          component: JiaoYiQuanShuList
        },

        {
          path: "/JianZhuJieGouList",
          name: "JianZhuJieGouList",
          component: JiaoYiQuanShuList
        },

        {
          path: "/GongXiangChiList",
          name: "GongXiangChiList",
          component: GongXiangChiList
        }, //共享池列表
        {
          path: "/GongXiangChiDetail",
          name: "GongXiangChiDetail",
          component: GongXiangChiDetail
        }, //共享池详情
        { path: "/YaoShiList", name: "YaoShiList", component: YaoShiList }, //钥匙列表
        {
          path: "/YaoShiDetail",
          name: "YaoShiDetail",
          component: YaoShiDetail
        }, //钥匙详情
        {
          path: "/ZuoYeJiLuList",
          name: "ZuoYeJiLuList",
          component: ZuoYeJiLuList
        }, //作业记录列表
        {
          path: "/JiaoYiJiLuList",
          name: "JiaoYiJiLuList",
          component: JiaoYiJiLuList
        }, //交易记录列表
        {
          path: "/JiaoYiJiLuDetail",
          name: "JiaoYiJiLuDetail",
          component: JiaoYiJiLuDetail
        }, //交易记录详情
        {
          path: "/TuiKuanShenQingList",
          name: "TuiKuanShenQingList",
          component: TuiKuanShenQingList
        }, //退款申请列表
        {
          path: "/TuiKuanShenQingDetail",
          name: "TuiKuanShenQingDetail",
          component: TuiKuanShenQingDetail
        }, //退款申请详情
        { path: "/ShouXuList", name: "ShouXuList", component: ShouXuList }, //手续列表
        {
          path: "/ShouXuDetail",
          name: "ShouXuDetail",
          component: ShouXuDetail
        }, //手续详情
        { path: "/YeJiList", name: "YeJiList", component: YeJiList }, //业绩列表
        { path: "/YeJiAdd", name: "YeJiAdd", component: YeJiAdd }, //业绩新增
        
        { path: "/YeJiDetail", name: "YeJiDetail", component: YeJiDetail }, //业绩详情
        { path: "/CaiWuList", name: "CaiWuList", component: CaiWuList }, //财务列表
        { path: "/shoujuList", name: "shoujuList", component: shoujuList }, //收据列表
        {
          path: "/YingShouDetail",
          name: "YingShouDetail",
          component: YingShouDetail
        }, //应收详情
        {
          path: "/FangYuanXianSuoList",
          name: "FangYuanXianSuoList",
          component: FangYuanXianSuoList
        }, //房源线索列表
        {
          path: "/FangYuanXianSuoDetail",
          name: "FangYuanXianSuoDetail",
          component: FangYuanXianSuoDetail
        }, //房源线索详情
        {
          path: "/GuiZhangZhiDuList",
          name: "GuiZhangZhiDuList",
          component: GuiZhangZhiDuList
        }, //规章制度列表
        {
          path: "/GuiZhangZhiDuDetail",
          name: "GuiZhangZhiDuDetail",
          component: GuiZhangZhiDuDetail
        }, //规章制度详情
        {
          path: "/PeiXunKeChengList",
          name: "PeiXunKeChengList",
          component: PeiXunKeChengList
        }, //培训课程列表
        {
          path: "/PeiXunKeChengDetail",
          name: "PeiXunKeChengDetail",
          component: PeiXunKeChengDetail
        }, //培训课程详情
        {
          path: "/FangYuanGongXiangChiList",
          name: "FangYuanGongXiangChiList",
          component: FangYuanGongXiangChiList
        }, //房源共享池列表
        {
          path: "/FangYuanGongXiangChiDetail",
          name: "FangYuanGongXiangChiDetail",
          component: FangYuanGongXiangChiDetail
        }, //房源共享池详情
        {
          path: "/KeYuanGongXiangChiList",
          name: "KeYuanGongXiangChiList",
          component: KeYuanGongXiangChiList
        },
        {
          path: "/ShiKanDetail",
          name: "ShiKanDetail",
          component: ShiKanDetail
        }, //房源租售实勘详情
        {
          path: "/FangYuanYaoShiDetail",
          name: "FangYuanYaoShiDetail",
          component: FangYuanYaoShiDetail
        }, //房源租售钥匙详情
        {
          path: "/ZhengJianDetail",
          name: "ZhengJianDetail",
          component: ZhengJianDetail
        }, //房源租售证件详情
        {
          path: "/YeZhuXinXi",
          name: "YeZhuXinXi",
          component: YeZhuXinXi
        }, //房源租售vip详情
        {
          path: "/WeiHuDetail",
          name: "WeiHuDetail",
          component: WeiHuDetail
        }, //房源租售维护详情
        {
          path: "/ShangQuanList",
          name: "ShangQuanList",
          component: JiaoYiQuanShuList
        }, //商圈
        {
          path: "/JiaoYiQuanShuList",
          name: "JiaoYiQuanShuList",
          component: JiaoYiQuanShuList
        }, //交易权属
        {
          path: "/FangWuYongTuList",
          name: "FangWuYongTuList",
          component: JiaoYiQuanShuList
        }, //房屋用途
        {
          path: "/DianPuList",
          name: "DianPuList",
          component: DianPuList
        }, //店铺列表
        {
          path: "/DianPuDetail",
          name: "DianPuDetail",
          component: DianPuDetail
        }, //店铺详情
        {
          path: "/ZuLinTemplate",
          name: "ZuLinTemplate",
          component: ZuLinTemplate
        }, //租赁合同模板
        {
          path: "/MaiMaiTemplate",
          name: "MaiMaiTemplate",
          component: MaiMaiTemplate
        }, //买卖合同模板
        {
          path: "/CheWeiZuLinTemplate",
          name: "CheWeiZuLinTemplate",
          component: CheWeiZuLinTemplate
        }, //车位租赁合同模板
        {
          path: "/CheWeiMaiMaiTemplate",
          name: "CheWeiMaiMaiTemplate",
          component: CheWeiMaiMaiTemplate
        }, //车位买卖合同模板
        {
          path: "/Contract",
          name: "Contract",
          component: Contract
        },
        
      ]
    }
  ]
});
export default router;
// import Vuex from 'vuex'

// Vue.use(Vuex)

// const store = new Vuex.Store({
//     state: {

//     },
//     mutations: {

//     }
// })
