<template>
    <div>
        <el-tabs class="qiugou-tabs" style="height: 40px;position: relative;top: 0px;margin-top: 10px;background-color: #fff;">
            <el-tab-pane label="主体合同">
                <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="14%">
                    <el-card>
                        <div style="float: right;color: rgb(18, 104, 192);padding: 0px 15px;line-height: 30px;border-radius: 3px;">扫描二维码可通过手机相册上传哦</div>
                        <h4 style="margin-top:0px;margin-bottom:0px;">合同基本信息</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="上传文件" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="" label-width="14%">
                                    <el-button type="primary" size="mini" :disabled="disabled" @click="onSubmit">确认</el-button>
                                    <el-button type="warning" size="mini" :disabled="disabled" @click="cancel" style="margin-left:10px">上一步</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="备件信息">
                <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="14%">
                    <el-card>
                        <h4 style="margin-top:0px;margin-bottom:0px;">房产证</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="房产证" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card>
                        <h4 style="margin-top:0px;margin-bottom:0px;">卖方身份证</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="卖方身份证" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card>
                        <h4 style="margin-top:0px;margin-bottom:0px;">买方身份证</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="买方身份证" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card>
                        <h4 style="margin-top:0px;margin-bottom:0px;">其他</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="房屋照片" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="产调单" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="结婚证" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-row style="margin: 20px;">
                        <el-col :span="12">
                            <el-form-item label="" label-width="14%">
                                <el-button type="primary" size="mini" :disabled="disabled" @click="onSubmit">确认</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="定金收据">
                <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="14%">
                    <el-card>
                        <!-- <div style="float: right;color: rgb(18, 104, 192);padding: 0px 15px;line-height: 30px;border-radius: 3px;">扫描二维码可通过手机相册上传哦</div> -->
                        <h4 style="margin-top:0px;margin-bottom:0px;">定金收据</h4>
                        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                        <el-row style="margin-top: 15px;">
                            <el-col :span="24">
                                <el-form-item label="上传文件" label-width="7%">
                                    <el-upload :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*,.docx" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="" label-width="14%">
                                    <el-button type="primary" size="mini" :disabled="disabled" @click="onSubmit">确认</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    props: {
        /*编辑器的内容*/
        value: null,
        /*图片大小*/
        maxSize: {
            type: Number,
            default: 4000 //kb
        }
    },
    components: {},
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        var checkUrl = (rule, value, cb) => {
            if (this.fileGuids.length !== 0) {
                return cb()
            } else {
                cb(new Error("请上传文件"))
            }
        }
        return {
            UploadPics: this.$url.UploadContracts,
            state: this.$route.query.state,
            contractId: this.$route.query.contractId,
            name: "",
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            form: {},
            rules: {},
            hetongData: [
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                },
                {
                    img: require("../../../public/img/logo.jpg"),
                    time: "2021-05-10 14:30:45"
                }
            ],
            disabled: false
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
    },
    methods: {
        fileChange() {
            this.disabled = true
        },
        //图片上传成功
        success(response) {
            if (response.msgCode == "1") {
                this.disabled = false
                this.ShiKanPics.push(response.id)
                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        //图片上传id
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        //图片上传失败
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
        },
        //图片上传删除
        handleBeforeRemove(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            mm = file.name
            let ids = new Set(this.ShiKanPics)
            ids.delete(mm)
            this.ShiKanPics = Array.from(ids)
        },
        onSubmit() {
            // const params = {
            //     keting: this.ShiKanPics.join(",")
            // }
            // const data = {
            //     contractId: this.contractId,
            //     shiKanInfo: JSON.stringify(params)
            // }
            // this.$http.put(this.$url.UpdateHouseShiKan, data).then(res => {
            //     if (res.data.msgCode == 1) {
            //         this.$message.success("合同保存成功!")
            //     } else {
            //         this.$message.error(res.data.message)
            //     }
            // })
        },
        cancel() {
            this.$emit("Previousstep", "2")
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-step__main {
    padding-left: 0px !important;
    flex-grow: 1;
}
.genJinRequirement {
    /deep/ .el-tabs__header {
        padding-left: 0px !important;
        margin: 0px !important;
    }
    /deep/ .el-dialog__body {
        padding: 0px 20px !important;
    }
}
.padding-r0 /deep/ input {
    padding-right: 0px !important;
}
.daikan {
    width: 70px;
    height: 30px;
    text-align: center;
    float: left;
    background: #f3f3f3 !important;
    margin-right: 15px;
    color: #000 !important;
}
.daikanActive {
    background: #ffe4d3 !important;
    color: #df0202 !important;
}
.daikanFanKui {
    width: 60px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    float: left;
}
.yichengjiao {
    position: absolute;
    background: rgb(238, 103, 22) !important;
    height: 22px;
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    width: 50px;
    text-align: center;
}
.hr {
    border-bottom: 1px solid #e4e7ed;
    border-top: 0px;
    margin: 15px 0;
}
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
.qiugou-tabs {
    font-size: 14px;
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        padding-left: 20px;
        margin: 0px;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-step.is-vertical .el-step__line {
        height: 20px;
        top: 23px;
        left: 6px;
        width: 1px;
    }
    /deep/ .el-step.is-vertical .el-step__head {
        width: 13px;
        display: none !important;
    }
    /deep/ .el-step__icon {
        background: #df0202;
        width: 13px;
        height: 13px;
        border: 2px solid;
        border-color: #e6baa8;
    }
    /deep/ .el-step__icon-inner {
        display: none;
    }
    /deep/ .el-step.is-vertical .el-step__title {
        padding-bottom: 0px;
    }
    /deep/ .el-step__title.is-wait,
    /deep/ .el-step__description {
        color: #000;
        font-size: 14px;
    }
    /deep/ .el-card__body {
        margin: 5px 20px;
        padding: 0px !important;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }
}
.xinzengXuQiu {
    color: #df0202 !important;
    padding-right: 20px;
    border-radius: 3px;
    float: right;
    font-weight: 600;
    margin-top: 3px;
    font-size: 14px;
}
.addXuqiu {
    margin-top: -28px;
    z-index: 1111;
    position: absolute;
    right: 0px;
}
.xuqiuForm-item {
    display: flex;
    /deep/ .el-form-item {
        width: 29%;
        margin-bottom: 0px !important;
    }
    /deep/ .el-form-item__content {
        margin-left: 0px;
    }
}
.div-tabs {
    background-color: #fff;
    margin-top: 15px;
}
.tabs-title {
    padding: 10px 20px;
    font-weight: 600;
}

.xuqiuDialog /deep/ .el-dialog__body {
    padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
    right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
    padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
    margin: 2px 0 2px 3px !important;
}
/deep/.xuqiuDialog .el-tabs {
    margin-top: -5px !important;
    .el-tabs__header {
        border-bottom: none !important;
        margin: 0px;
        .el-tabs__nav {
            color: #000 !important;
            border: none !important;
        }
        .el-tabs__nav > .is-active {
            color: rgb(238, 103, 22) !important;
            background: transparent !important;
        }
        .el-tabs__item {
            border-left: none !important;
        }
    }
    .el-tabs__content {
        margin-left: 14px !important;
    }
}
// /deep/ .keyuanXuQiu .el-tabs__nav-wrap {
//     display: none !important;
// }
</style>