<template>
    <div>
        <el-card class="search">
            <el-row>
                <el-col :span="8">
                    <span class="title">楼盘及楼栋：</span>
                    <el-input placeholder="楼盘及楼栋" v-model="LouPanNameOrNo" class="media-width"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="title">房源编号：</span>
                    <el-input placeholder="房源编号" v-model="houseNo" class="media-width"></el-input>
                </el-col>
                <el-col :span="8">
                    <el-button type="warning" size="mini" style="margin-left:5px;float:right;" @click="resultHouseList">重置</el-button>
                    <el-button type="primary" size="mini" style="margin-left:15px;float:right;" @click="getHouseList">查询</el-button>
                </el-col>
            </el-row>
            <hr style="height:1px;border:none;backgroundColor:#eee;" />
            <el-row>
                <span class="title">范围:</span>
                <div v-for="item in item1" :key="item.label" :value="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive1 === item.label }" @click="changeClass1(item.label)">
                    {{ item.label }}
                </div>
            </el-row>
            <el-row style="margin-top: 3px;">
                <span class="title">价格:</span>
                <div v-for="item in item3" :key="item.label" :value="item.value" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive3 === item.label }" @click="changeClass3(item.label,item.value)">
                    {{ item.label }}
                </div>
                <span style="margin-left:0.5%;font-size:14px">
                    <el-input maxlength="5" placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="priceFrom" style="width:60px;"></el-input>
                    -
                    <el-input maxlength="5" placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckPrice" v-model="priceTo" style="width:60px;"></el-input>
                    万
                </span>
            </el-row>
            <el-row style="margin-top: 3px;">
                <span class="title">面积:</span>
                <div v-for="item in item4" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive4 === item.label }" @click="changeClass4(item.label,item.value)">
                    {{ item.label }}
                </div>
                <span style="margin-left:0.5%;font-size:14px">
                    <el-input placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="squareFrom" style="width:60px;"></el-input>
                    -
                    <el-input placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckSquare" v-model="squareTo" style="width:60px;"></el-input>
                    平米
                </span>
            </el-row>
            <el-row style="margin-top: 3px;">
                <span class="title" style="float:left;">户型:</span>
                <div style="font-size: 14px;float:left;margin-left:0.75%;" class="xuanxiang2" :class="{ active: isActive5 === '不限' }" @click="changeClass5('不限','')">不限</div>
                <div v-for="item in HuXing" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive5 === item.label }" @click="changeClass5(item.label,item.commonMasterId)">
                    {{ item.label }}
                </div>
            </el-row>
        </el-card>

        <el-row>
            <el-col :span="18">
                <el-card>
                    <div style="padding-left: 10px">
                        推荐房源
                    </div>
                    <div v-if="HouseList.length>0">
                        <div style="margin: 15px 0;" v-for="(item,index) in HouseList" :key="index">
                            <el-row>
                                <el-col :span="6">
                                    <img class="listimg" v-if="item.oneKeting!=''" :src="item.oneKeting">
                                    <img class="listimg" v-else src="../../../public/img/zanwutupian.jpg">
                                </el-col>
                                <el-col :span="10">
                                    <el-tooltip :content="item.louPanName +item.houseNo " placement="top" effect="light">
                                        <div class="row1">{{item.louPanName}} {{item.houseNo}}</div>
                                    </el-tooltip>
                                    <div class="row2">{{item.square}}平 {{item.huXingName}} {{item.unitPrice}}元/平</div>
                                    <div class="row2" style="line-height:0px">维护人：{{item.maintenanceUserName}}</div>
                                    <div class="row3" v-if="state == '1'">{{item.priceToSell}}元</div>
                                    <div class="row3" v-if="state == '0'">{{item.priceToLet}}元</div>
                                </el-col>
                                <el-col :span="8">
                                    <img class="click" @click="clickxuanzhong(index)" v-if="item.xuanzhong ==false" src="../../../public/img/clickno.png">
                                    <img class="click" @click="clickxuanzhong(index)" v-else src="../../../public/img/click.png">
                                </el-col>
                            </el-row>
                        </div>
                        <!-- 分页区域 -->
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                    </div>
                    <div v-else style="text-align: center;">
                        <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card style="height:400px; position: relative;">
                    <div style="padding-left: 10px">
                        已选房源
                    </div>
                    <div v-for="(item,index) in xuanzhonglist" :key="index">
                        {{item.louPanName}}&nbsp;&nbsp;&nbsp;
                        <span style="color:red">
                            {{item.houseNo}}
                        </span>
                    </div>
                    <div class="button">
                        <el-button class="nobutton" size="medium" @click="yesgo(1)">取消</el-button>
                        <el-button class="yesbutton" size="medium" @click="yesgo(2)">确定</el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import varData from "../ChuZu/varData.js"
export default {
    name: "KeYuanChuShouList",
    inject: ["reload"],
    data() {
        return {
            fangyuanloudong: [],
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            item1: [],
            item3: [],
            item4: [],
            isActive1: "不限",
            isActive3: "不限",
            isActive4: "不限",
            isActive5: "不限",
            HuXing: [],
            priceFrom: 0,
            priceTo: 0,
            sortBy: "",
            squareFrom: 0,
            squareTo: 0,
            huXingId: "",
            options: [],
            houseNo: "",
            louPanId: "",
            LouPanNameOrNo: "",
            louDongId: "",
            HouseList: [],
            chaoxiangList: [],
            fangxiangToString: "",
            cengshu: "",
            cengshu1: "",
            Array: [],
            roleType: "",
            IsConfirmed: "",
            favourite: "",
            xuanzhonglist: [],
            QueryType: ""
        }
    },
    props: {
        /* 是否为客源带看 */
        isKeyuanDaikan: {
            type: Boolean,
            default: false
        },
        state: {
            type: String
        },
        dkfylist: {
            type: Array
        },
        daiKanType: {
            type: String
        },
        isFangYuanGenJin: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.getNodes()
        var varData1 = varData.Function()
        this.item1 = varData1.item1
        this.item3 = varData1.item3
        this.item4 = varData1.item4
    },
    watch: {
        dkfylist(val) {
            if (val.length == 0) {
                this.xuanzhonglist = []
                this.HouseList.forEach(function (i, index) {
                    i.xuanzhong = false
                })
            }
        },
        state(val) {
            console.log(val)
            this.getShuJuZiDianList()
            this.currentPage = 1
            this.getHouseList()
        },
        fangyuanloudong(val) {
            console.log(val)
            if (val.length != undefined && val.length == 1) {
                this.louPanId = val[0]
            } else {
                this.louPanId = val[0]
            }
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        this.getShuJuZiDianList()
        this.getHouseList()
    },
    methods: {
        yesgo(type) {
            if (this.isKeyuanDaikan) {
                this.$emit("colseFangyuanDialog", this.xuanzhonglist, type)
            }
            if (this.isFangYuanGenJin) {
                this.$emit("colseFangyuanDialog", this.xuanzhonglist, type)
            }
        },
        //获取选中数据
        getxuanzhonglist() {
            var that = this
            that.HouseList.forEach(function (i, index) {
                if (i.xuanzhong == true) {
                    that.xuanzhonglist.push(i)
                }
            })
            that.quchong(that.xuanzhonglist, "houseId")
        },
        //去重
        quchong(arr, u_key) {
            let that = this
            let map = new Map()
            arr.forEach((item, index) => {
                if (!map.has(item[u_key])) {
                    map.set(item[u_key], item)
                }
            })
            that.xuanzhonglist = [...map.values()]
        },
        //多选框
        clickxuanzhong(num) {
            var that = this
            if (this.daiKanType == "add") {
                //新增
                if (that.xuanzhonglist.length < 3) {
                    that.HouseList.forEach(function (i, index) {
                        if (num == index) {
                            if (i.xuanzhong == true) {
                                i.xuanzhong = false
                                that.xuanzhonglist.forEach(function (e, index) {
                                    if (e.houseId == i.houseId) {
                                        that.xuanzhonglist.splice(index, 1)
                                    }
                                })
                            } else {
                                i.xuanzhong = true
                            }
                        }
                    })
                    that.getxuanzhonglist()
                } else {
                    that.HouseList.forEach(function (i, index) {
                        if (num == index && i.xuanzhong == true) {
                            i.xuanzhong = false
                            that.xuanzhonglist.forEach(function (e, index) {
                                if (e.houseId == i.houseId) {
                                    that.xuanzhonglist.splice(index, 1)
                                }
                            })
                        }
                    })
                    that.getxuanzhonglist()
                    if (that.xuanzhonglist.length >= 3) {
                        this.$message.error("最多同时带看三个房源!")
                    }
                }
            } else {
                //修改
                if (that.xuanzhonglist.length < 1) {
                    that.HouseList.forEach(function (i, index) {
                        if (num == index) {
                            if (i.xuanzhong == true) {
                                i.xuanzhong = false
                                that.xuanzhonglist.forEach(function (e, index) {
                                    if (e.houseId == i.houseId) {
                                        that.xuanzhonglist.splice(index, 1)
                                    }
                                })
                            } else {
                                i.xuanzhong = true
                            }
                        }
                    })
                    that.getxuanzhonglist()
                } else {
                    that.HouseList.forEach(function (i, index) {
                        if (num == index && i.xuanzhong == true) {
                            i.xuanzhong = false
                            that.xuanzhonglist.forEach(function (e, index) {
                                if (e.houseId == i.houseId) {
                                    that.xuanzhonglist.splice(index, 1)
                                }
                            })
                        }
                    })
                    that.getxuanzhonglist()
                    if (that.xuanzhonglist.length >= 1) {
                        this.$message.error("只能选择一个房源")
                    }
                }
            }
        },
        getHouseList() {
            var that = this
            that.loading = true
            const data = {
                LouPanNameOrNo: that.LouPanNameOrNo,
                louPanId: that.louPanId,
                louDongId: that.louDongId,
                houseNo: that.houseNo,
                priceFrom: that.priceFrom * 10000,
                priceTo: that.priceTo * 10000,
                squareFrom: that.squareFrom,
                squareTo: that.squareTo,
                huXingId: that.huXingId,
                currentPage: that.currentPage,
                pageSize: that.pageSize,
                isHouseToSell: that.state,
                sortBy: that.sortBy,
                IsConfirmed: "1",
                QueryType: that.QueryType
            }
            that.$http.post(that.$url.HouseList, data).then((res) => {
                if (res.data.msgCode == 1) {
                    that.chaoxiangList = []
                    if (res.data.dtList.length != 0) {
                        for (let i = 0; i < res.data.dtList.length; i++) {
                            let list = res.data.dtList[i].orientations.split("")
                            res.data.dtList[i].orientations =
                                that.chaoxiangList.join("/")
                            that.chaoxiangList = []
                            res.data.dtList[i].oneKeting = ""
                            if (
                                res.data.dtList[i].shiKanInfo != null &&
                                JSON.parse(res.data.dtList[i].shiKanInfo)
                                    .keting != ""
                            ) {
                                if (
                                    JSON.parse(
                                        res.data.dtList[i].shiKanInfo
                                    ).keting.split(",")[0] != null
                                ) {
                                    // 出售
                                    // if (this.state == 1) {
                                    //     console.log("出售")
                                    //     res.data.dtList[i].oneKeting =
                                    //         that.$url.houses +
                                    //         JSON.parse(
                                    //             res.data.dtList[i].shiKanInfo
                                    //         ).keting.split(",")[0]
                                    // } else {
                                        console.log("出租")
                                        res.data.dtList[i].oneKeting =
                                            that.$url.houses +
                                            JSON.parse(
                                                res.data.dtList[i].shiKanInfo
                                            ).keting.split(",")[0]
                                    // }
                                }
                            }
                        }
                        that.total = res.data.maxRecords
                        if (that.xuanzhonglist.length == 0) {
                            res.data.dtList.forEach(function (i) {
                                i.xuanzhong = false
                            })
                        } else {
                            res.data.dtList.forEach(function (y) {
                                y.xuanzhong = false
                                that.xuanzhonglist.forEach(function (x) {
                                    if (x.houseId == y.houseId) {
                                        y.xuanzhong = true
                                    } else {
                                    }
                                })
                            })
                        }
                        that.HouseList = res.data.dtList
                    } else {
                        that.HouseList = []
                    }
                } else {
                    that.$message.error(res.data.message)
                }
                that.loading = false
            })
        },

        CheckPrice() {
            if (this.priceFrom > this.priceTo) {
                this.$message.error("最小价格不能大于最大价格!")
                let temp = ""
                temp = this.priceFrom
                this.priceFrom = this.priceTo
                this.priceTo = temp
                this.getHouseList()
            }
        },
        CheckSquare() {
            if (this.squareFrom > this.squareTo) {
                this.$message.error("最小面积不能大于最大面积!")
                let temp = ""
                temp = this.squareFrom
                this.squareFrom = this.squareTo
                this.squareTo = temp
                this.getHouseList()
            }
        },
        resultHouseList() {
            this.LouPanNameOrNo = ""
            this.fangyuanloudong = []
            this.louPanId = ""
            this.louDongId = ""
            this.houseNo = ""
            this.houseNo = ""
            this.priceFrom = 0
            this.priceTo = 0
            this.squareFrom = 0
            this.squareTo = 0
            this.huXingId = ""
            this.currentPage = 1
            this.pageSize = 10
            this.isHouseToSell = this.state
            this.sortBy = ""
            this.item3 = varData.Function()["item3"]
            this.item4 = varData.Function()["item4"]
            if (this.isKeyuanDaikan) {
                varData.Function()["shaixuan"][7] = [
                    {
                        value: "8-1",
                        label: "审核已通过"
                    }
                ]
            }
            this.changeClass1("不限")
            this.changeClass3("不限", "0,0")
            this.changeClass4("不限", "0,0")
            this.changeClass5("不限", "")
            this.getHouseList()
        },
        getShuJuZiDianList() {
            /* 获取交易权属列表 */
            let that = this
            this.$commonJs.getShuJuZiDianList("HUXINGN").then((data) => {
                that.HuXing = data.HuXing
            })
        },
        changeClass1(i) {
            this.isActive1 = i
            if (i == "不限") {
                this.QueryType = ""
            } else {
                this.QueryType = i
            }
            this.getHouseList()
        },

        changeClass3(i, v) {
            this.isActive3 = i
            let list = v.split(",")
            this.priceFrom = list[0] * 1
            this.priceTo = list[1] * 1
            this.getHouseList()
        },
        changeClass4(i, v) {
            this.isActive4 = i
            let list = v.split(",")
            this.squareFrom = list[0] * 1
            this.squareTo = list[1] * 1
            this.getHouseList()
        },
        changeClass5(i, v) {
            this.isActive5 = i
            this.huXingId = v
            this.getHouseList()
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getHouseList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getHouseList()
        },
        getNodes(val) {
            this.$http
                .get(this.$url.GetLouPanList, {
                    params: {
                        louPanNo: "", //楼盘编号
                        louPanName: "", //楼盘名称
                        areaIds: "", //商圈id
                        louPanAddress: "", //楼盘地址
                        kaiFaDanWei: "", //开发单位
                        isConfirmed: "",
                        currentPage: 1,
                        pageSize: 10
                    }
                })
                .then(
                    (response) => {
                        if (response.data && response.data.msgCode === 1) {
                            let Items = response.data.dtList
                            if (response.data.dtList.length > 0) {
                                // 初始化 加载一级
                                this.options = Items.map((value, i) => {
                                    return {
                                        louPanId: value.louPanId,
                                        louPanName: value.louPanName,
                                        cities: []
                                    }
                                })
                                let louPanId1 = ""
                                if (val != undefined) {
                                    louPanId1 = val.toString()
                                }
                                if (louPanId1) {
                                    this.$http
                                        .get(this.$url.GetLouDongList, {
                                            params: {
                                                louPanId: louPanId1, //楼盘编号
                                                currentPage: 1,
                                                pageSize: 10
                                            }
                                        })
                                        .then((res) => {
                                            let Items1 = res.data.dtList
                                            this.options.map((value, i) => {
                                                if (val != undefined) {
                                                    if (
                                                        value.louPanId ===
                                                        val[0]
                                                    ) {
                                                        this.louPanId =
                                                            value.louPanId
                                                        if (
                                                            !value.cities.length
                                                        ) {
                                                            value.cities =
                                                                Items1.map(
                                                                    (
                                                                        value,
                                                                        i
                                                                    ) => {
                                                                        return {
                                                                            louPanId:
                                                                                value.louDongId,
                                                                            louPanName:
                                                                                value.gongChengLouHao
                                                                        }
                                                                    }
                                                                )
                                                        }
                                                    }
                                                }
                                            })
                                        })
                                }
                            }
                        } else {
                            console.log(response.data.msg)
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }
    }
}
</script>

<style lang="less" scoped>
.listimg {
    margin: 0 auto;
    width: 70%;
    display: block;
}
.row1 {
    cursor: pointer;
    width: 232px;
    margin-top: -4px;
    overflow: hidden;
    line-height: 20px;
    color: #f90505;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.row2 {
    line-height: 53px;
    color: #999;
    font-size: 14px;
}
.row3 {
    line-height: 57px;
    color: #f93838;
    font-size: 14px;
}
.click {
    margin-top: 40px;
    width: 25px;
    float: right;
    margin-right: 70px;
}
.button {
    position: absolute;
    bottom: 5px;
    right: 20px;
}
.el-card {
    margin-top: 15px;
}
.title {
    font-size: 14px;
}
.el-checkbox {
    margin-right: 10px;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}
/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
    // z-index: 1111;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    margin-left: 1.5% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0 3px;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>
<style>
.el-cascader__suggestion-panel {
    height: 204px;
}
</style>