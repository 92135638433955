<template>
  <div>
    <div>
      <!-- 面包屑导航区域 -->
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>工作日报</el-breadcrumb-item>

      </el-breadcrumb> -->
      <!-- 中间卡片视图区域 -->
      <el-card class="search">
        <el-row>
          <el-col :span="6">
            <span style="font-size: 14px;">楼盘名称或楼盘编号：</span>
            <el-input placeholder="请输入楼盘名称或楼盘编号" style="width:200px;" maxlength="12" v-model="louPanNameOrNo"></el-input>
          </el-col>
          <el-col :span="6">
            <span style="font-size: 14px;">房源编号：</span>
            <el-input placeholder="请输入房源编号" style="width:200px;" maxlength="8" v-model="houseNo"></el-input>
          </el-col>
          <el-col :span="12">
            <span style="float: right;">
              <el-button size="mini" style="margin-left: 20px;" type="primary" @click="chaxun">查询</el-button>
              <el-button size="mini" @click="reset" type="warning">重置</el-button>
            </span>
          </el-col>
        </el-row>
        <el-row v-if="roleType == 5 || roleType == 6">
          <span class="title">层级:</span>
          <div v-for="item in item1" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2"
            :class="{ active: isActive1 === item.label }" @click="changeClass1(item.label, item.value)">
            {{ item.label }}
          </div>
        </el-row>
        <el-row v-if="roleType == 1 || roleType == 2">
          <span class="title">层级:</span>
          <div v-for="item in item2" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2"
            :class="{ active: isActive2 === item.label }" @click="changeClass2(item.label, item.value)">
            {{ item.label }}
          </div>
        </el-row>
        <el-row>
          <span class="title">客户需求:</span>
          <div v-for="item in item3" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2"
            :class="{ active: isActive3 === item.label }" @click="changeClass3(item.label, item.value)">
            {{ item.label }}
          </div>
        </el-row>
        <!-- <el-row>
                    <span class="title">掉落原因:</span>
                    <div v-for="item in item4" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive4 === item.label }" @click="changeClass4(item.label,item.value)">
                        {{ item.label }}
                    </div>
                </el-row> -->
        <el-row>
          <span class="title">进池时间:</span>
          <div v-for="item in item5" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2"
            :class="{ active: isActive5 === item.label }" @click="changeClass5(item.label, item.value)">
            {{ item.label }}
          </div>
        </el-row>
        <el-row>
          <span class="title">原维护人:</span>
          <div v-for="item in item6" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2"
            :class="{ active: isActive6 === item.label }" @click="changeClass6(item.label, item.value)">
            {{ item.label }}
          </div>
        </el-row>
        <!-- <el-row style="margin-top: 10px">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                        <el-button size="mini" @click="handleDelete1()" type="primary" icon="el-icon-delete" style="background-color:rgb(215 163 16); border: none">删除</el-button>
                    </el-col>
                </el-row> -->
      </el-card>
      <!-- 底部内容区域 -->
      <el-card>
        <div style="padding: 10px 10px 0 10px;">
          共有{{ total }}条数据
          <span style="text-decoration: underline;float:right;cursor: pointer;">
            <span>
              <!-- <el-button v-if="roleType == 2||roleType == 5||roleType == 6" size="mini" @click="fenpei1(1)" style="margin-left: 15px;" type="primary ">批量分配</el-button> -->
              <el-button v-if="roleType == 1" size="mini" @click="renling1" style="margin-left: 15px;"
                type="primary">批量认领</el-button>
            </span>
          </span>
          <!-- <el-button v-if="roleType != 6" size="mini" @click="renling1" style="margin-left: 15px;float:right;" type="primary ">批量认领</el-button> -->
        </div>
        <el-table ref="multipleTable" v-loading="loading" :data="gongxiangList" tooltip-effect="dark" :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe
          class="matop0">
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column label="楼盘名称" prop="louPanName">
            <template slot-scope="scope">
              <span @click="tofangyudetail(scope.row)" style="cursor: pointer;">
                {{ scope.row.louPanName }}</span><br /><span style="color:red">{{ scope.row.houseNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="面积" prop="square">
            <template slot-scope="scope"> {{ scope.row.square }}㎡ </template>
          </el-table-column>
          <el-table-column label="户型" prop="huXingId"> </el-table-column>
          <el-table-column label="朝向" prop="orientations"></el-table-column>
          <el-table-column label="租售类型" prop="isHouseToSell"></el-table-column>
          <el-table-column label="租售价格" prop="price">
            <template slot-scope="scope">
              {{ scope.row.price }}
              <!-- {{scope.row.price.indexOf('/') > -1 ? scope.row.price.split('/')[0]+'元' + scope.row.price.split('/')[0]+'元/月':scope.row.price}} -->
            </template>
          </el-table-column>
          <el-table-column label="掉落原因" prop="reason"></el-table-column>
          <el-table-column label="进池时间" prop="entryPoolTime"></el-table-column>
          <el-table-column v-if="roleType == 1" label="操作">
            <template slot-scope="scope" class="right">
              <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

              <!-- <el-button v-if="roleType == 2||roleType == 5||roleType == 6" @click.native.stop="fenpei(scope.$index, scope.row,0)" type="text" size="mini" class="el-icon-plus" style="color: red;">去分配</el-button> -->
              <el-button v-if="roleType == 1" @click.native.stop="renling(scope.$index, scope.row)" type="text"
                size="mini" class="el-icon-plus" style="color: red;">认领</el-button>
              <!-- <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button> -->
            </template>
          </el-table-column>
          <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
            <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
            <p style="font-size: 14px;margin: 0;line-height: 30px;"></p>
          </div>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </el-card>
    </div>

    <el-dialog title="指定经纪人" :visible.sync="gongxiangDialog" width="70%" :before-close="handleClose1">
      <TongXunLuList :isFenPeiZiYuan="true" @colseJingJiRenDialog="isJingJiRenData"></TongXunLuList>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gongxiangDialog = false">取 消</el-button>
        <el-button type="primary" @click="gongxiangDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var _index;
import TongXunLuList from "../TongXunLu/TongXunLuList.vue";
export default {
  components: {
    TongXunLuList
  },
  name: "GetStaffList",
  inject: ["reload"],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },

      louPanNameOrNo: "", //楼盘名称
      houseNo: "", //房源编号
      editableTabs: [],
      drow: "",
      roleType: "",
      gongxiangDialogPageSize: 10,
      gongxiangDialogCurrentPage: 1,
      loading: false,
      petBreedName: "",
      role: 1,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      operationList: [],
      articleTitle: "",
      enabled: "",
      isActive1: "不限",
      isActive2: "本商户",
      isActive3: "不限",
      isActive4: "不限",
      isActive5: "不限",
      isActive6: "不限",
      kehuxingming: "",
      xuqiuleixing: "",
      xuqiu: "",
      weituoshijian: "",
      yuanweituoren: "",
      diaoluoyuanyin: "",
      shengyushijian: "",
      gongxiangDialogName: "",
      gongxiangDialogLoading: false,
      gongxiangDialog: false,
      gongxiangDialogTotal: 0,
      FangYuanGongXiangChiData: JSON.parse(
        window.localStorage.getItem("FangYuanGongXiangChiData")
      ),
      options: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "0",
          label: "否"
        }
      ],
      gongxiangList: [],
      item1: [
        {
          value: "0",
          label: "不限"
        },
        {
          value: "1",
          label: "本商圈"
        }
        // {
        //     value: "2",
        //     label: "商户共享池"
        // }
      ],
      item2: [
        {
          value: "0",
          label: "本商户"
        },
        {
          value: "1",
          label: "本商圈"
        }
        // {
        //     value: "2",
        //     label: "商户共享池"
        // }
      ],
      item3: [
        {
          value: "",
          label: "不限"
        },
        {
          value: "1",
          label: "买房"
        },
        {
          value: "0",
          label: "租赁"
        }
      ],
      item4: [
        {
          value: "",
          label: "不限"
        },
        {
          value: "维护不当",
          label: "维护不当"
        },
        {
          value: "离职调转",
          label: "离职调转"
        },
        {
          value: "手动无效",
          label: "手动无效"
        }
      ],
      item5: [
        {
          value: "",
          label: "不限"
        },
        {
          value: "1",
          label: "今天"
        },
        {
          value: "2",
          label: "近三天"
        },
        {
          value: "3",
          label: "本周"
        }
      ],
      item6: [
        {
          value: "0",
          label: "不限"
        },
        {
          value: "1",
          label: "我自己"
        }
      ],
      multipleSelection1: [],
      isHouseToSell: "",
      reason: "",
      entryPoolTimeStart: "",
      chaoxiangList: [],
      userId: "",
      IsOurShop: "",
      JingJiRenData: "",
      form: {
        userId: "",
        houseId: ""
      },
      val: ""
    };
  },
  created() {
    /* 获取所有的日志列表 */
    this.roleType = window.localStorage.getItem("roleType");
    this.userId = window.localStorage.getItem("userId");
    this.editableTabs = window.localStorage.getItem("editableTabs");

    if (this.editableTabs) {
      this.editableTabs = JSON.parse(this.editableTabs);
      this.editableTabs.forEach(item => {
        if (this.activeIndex == item.path) {
          this.activeIndex = this.activeIndex;
          this.editableTabsValue = item.name.toString();
        }
      });
    }
    if (this.roleType == 5 || this.roleType == 6) {
      this.IsOurShop = "0";
    } else if (this.roleType == 1 || this.roleType == 2) {
      this.IsOurShop = "1";
    }
    if (this.FangYuanGongXiangChiData) {
      this.currentPage = this.FangYuanGongXiangChiData.CurrentPage;
      this.pageSize = this.FangYuanGongXiangChiData.PageSize;
      this.louPanNameOrNo = this.FangYuanGongXiangChiData.louPanNameOrNo
      this.houseNo = this.FangYuanGongXiangChiData.houseNo
      // 层级
      this.IsOurShop = this.FangYuanGongXiangChiData.IsOurShop;
      if (this.FangYuanGongXiangChiData.IsOurShop == "0") {
        this.isActive1 = "不限"
        this.isActive2 = "本商户"
      } else if (this.FangYuanGongXiangChiData.IsOurShop == "1") {
        this.isActive1 = "本商圈"
        this.isActive2 = "本商圈"
      }
      // 客户需求
      this.isHouseToSell = this.FangYuanGongXiangChiData.isHouseToSell;
      if (this.FangYuanGongXiangChiData.isHouseToSell == "") {
        this.isActive3 = "不限"
      } else if (this.FangYuanGongXiangChiData.isHouseToSell == "0") {
        this.isActive3 = "租赁"
      } else if (this.FangYuanGongXiangChiData.isHouseToSell == "1") {
        this.isActive3 = "买房"
      }
      // 原维护人
      this.exMaintenanceUserId = this.FangYuanGongXiangChiData.exMaintenanceUserId;
      this.isActive5 =
        this.FangYuanGongXiangChiData.exMaintenanceUserId == ""
          ? "不限"
          : '我自己';

      // this.isActive1 =
      //   this.FangYuanGongXiangChiData.IsOurShop == ""
      //     ? "不限"
      //     : '本商圈';
    }
    this.getHousePoolList();
  },
  methods: {

    //查询
    chaxun() {
      this.currentPage = 1;
      this.getHousePoolList();
    },
    //重置
    reset() {
      this.louPanNameOrNo = "";
      this.houseNo = "";
      this.IsOurShop = "0"
      this.isHouseToSell = ""
      this.entryPoolTimeStart = ""
      this.exMaintenanceUserId = ""
      this.isActive1 = "不限"
      this.isActive2 = "不限"
      this.isActive3 = "不限"
      this.isActive4 = "不限"
      this.isActive5 = "不限"
      this.isActive6 = "不限"
      this.currentPage = 1;
      this.getHousePoolList();
    },

    tofangyudetail(row) {
      var that = this;
      if (row.isHouseToSell == "出租") {
        that.addTab(
          row.louPanName,
          "ChuZuDetail?louPanId=" +
          row.louPanId +
          "&houseId=" +
          row.houseId +
          "&canMerchantEdit=" +
          row.canMerchantEdit +
          "&canShopEdit=" +
          row.canShopEdit +
          "&num=1&state=2&=isConfirmed=" +
          row.isConfirmed
        );
        this.$router.push({
          name: "ChuZuDetail",
          query: {
            // currentPage1:this.currentPage,
            louPanId: row.louPanId,
            enabled: row.enabled,
            houseId: row.houseId,
            canMerchantEdit: row.canMerchantEdit,
            canShopEdit: row.canShopEdit,
            num: 1,
            state: 2,
            isConfirmed: row.isConfirmed,
            louCeng: row.louCengDuan,
            Ftype: "fangyuan"
          }
        });
      } else {
        this.addTab(
          row.louPanName,
          "ChuShouDetail?louPanId=" +
          row.louPanId +
          "&houseId=" +
          row.houseId +
          "&canMerchantEdit=" +
          row.canMerchantEdit +
          "&canShopEdit=" +
          row.canShopEdit +
          "&num=1&state=2&=isConfirmed=" +
          row.isConfirmed +
          "&louCeng=" +
          row.louCengDuan
        );
        var JiShou;
        if (row.isOnUrgentSale == "1") {
          this.JiShou = true;
        } else {
          this.JiShou = false;
        }
        this.$router.push({
          name: "ChuShouDetail",
          query: {
            // currentPage1:this.currentPage,
            isOnUrgentSale: this.JiShou,
            louPanId: row.louPanId,
            enabled: row.enabled,
            houseId: row.houseId,
            canMerchantEdit: row.canMerchantEdit,
            canShopEdit: row.canShopEdit,
            num: 1,
            state: 2,
            isConfirmed: row.isConfirmed,
            louCeng: row.louCengDuan,
            Ftype: "fangyuan"
          }
        });
      }
    },
    getHousePoolList() {
      let that = this;
      const data = {
        louPanNameOrNo: this.louPanNameOrNo,
        houseNo: this.houseNo,
        isHouseToSell: that.isHouseToSell,
        IsOurShop: that.IsOurShop,
        reason: that.reason,
        entryPoolTimeStart: that.entryPoolTimeStart,
        exMaintenanceUserId: that.exMaintenanceUserId,
        CurrentPage: that.currentPage,
        PageSize: that.pageSize
      };
      localStorage.setItem("FangYuanGongXiangChiData", JSON.stringify(data));
      this.$http
        .post(this.$url.HousePoolList, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.total = res.data.maxRecords;
            that.gongxiangList = res.data.dtList;
            console.log(res.data.dtList);
            if (res.data.dtList.length != 0) {
              for (let i = 0; i < res.data.dtList.length; i++) {
                // if (
                //     res.data.dtList[i].price.indexOf("/") > -1
                // ) {
                //     res.data.dtList[i].price =
                //         res.data.dtList[i].price.split("/")[0] +
                //         "元" +
                //         " \n " +
                //         res.data.dtList[i].price.split("/")[1] +
                //         "元/月"
                // } else if (
                //     res.data.dtList[i].price.indexOf("售价") >
                //         -1 &&
                //     res.data.dtList[i].price.indexOf("租价") ==
                //         -1
                // ) {
                //     res.data.dtList[i].price =
                //         res.data.dtList[i].price + "元"
                // } else if (
                //     res.data.dtList[i].price.indexOf("租价") >
                //         -1 &&
                //     res.data.dtList[i].price.indexOf("售价") ==
                //         -1
                // ) {
                //     res.data.dtList[i].price =
                //         res.data.dtList[i].price + "元/月"
                // }
                let list = res.data.dtList[i].orientations.split("");
                list.forEach(function (item, i) {
                  if (i == 0 && item == "1") {
                    that.chaoxiangList.push("东");
                  }
                  if (i == 1 && item == "1") {
                    that.chaoxiangList.push("东南");
                  }
                  if (i == 2 && item == "1") {
                    that.chaoxiangList.push("南");
                  }
                  if (i == 3 && item == "1") {
                    that.chaoxiangList.push("西南");
                  }
                  if (i == 4 && item == "1") {
                    that.chaoxiangList.push("西");
                  }
                  if (i == 5 && item == "1") {
                    that.chaoxiangList.push("西北");
                  }
                  if (i == 6 && item == "1") {
                    that.chaoxiangList.push("北");
                  }
                  if (i == 7 && item == "1") {
                    that.chaoxiangList.push("东北");
                  }
                });
                res.data.dtList[i].orientations = that.chaoxiangList.join("/");
                that.chaoxiangList = [];
              }
            }
          }
        });
    },
    changeClass1(i, v) {
      this.isActive1 = i;
      if (v == "1") {
        this.IsOurShop = "1";
      } else {
        this.IsOurShop = "0";
      }
      this.getHousePoolList();
    },
    changeClass2(i, v) {
      this.isActive2 = i;
      if (v == "1") {
        this.IsOurShop = "1";
      } else {
        this.IsOurShop = "0";
      }
      this.getHousePoolList();
    },
    handleClose1() {
      this.gongxiangDialog = false;
    },
    isJingJiRenData(row) {
      console.log(row);
      console.log("asd", this.val);
      this.JingJiRenData = row;
      this.form.userId = row.userId;
      this.gongxiangDialog = false;
      if (this.val == 1) {
        this.updateHousePoolRenLing1();
      } else if (this.val == 0) {
        this.updateHousePoolRenLing();
      }
    },

    changeClass3(i, v) {
      this.isActive3 = i;
      this.isHouseToSell = v;
      this.getHousePoolList();
    },
    changeClass4(i, v) {
      this.isActive4 = i;
      this.reason = v;
      this.getHousePoolList();
    },
    changeClass5(i, v) {
      this.isActive5 = i;
      var myDate = new Date();
      var myYear = myDate.getFullYear();
      var myMonth = myDate.getMonth() + 1;
      var myDay = myDate.getDate();
      var myHour = myDate.getHours();
      var myMinute = myDate.getMinutes();
      var myTime = myDate.getSeconds();

      if (v != "") {
        if (v == 2) {
          myDay = myDay - 3;
        } else if (v == 3) {
          myDay = myDay - 7;
        }
        this.entryPoolTimeStart =
          myYear +
          "/" +
          (myMonth < 10 ? "0" + myMonth : myMonth) +
          "/" +
          (myDay < 10 ? "0" + myDay : myDay) +
          " " +
          (myHour < 10 ? "0" + myHour : myHour) +
          ":" +
          (myMinute < 10 ? "0" + myMinute : myMinute) +
          ":" +
          (myTime < 10 ? "0" + myTime : myTime);
      } else {
        this.entryPoolTimeStart = "";
      }
      this.getHousePoolList();
    },
    changeClass6(i, v) {
      this.isActive6 = i;
      if (v == 0) {
        this.exMaintenanceUserId = "";
      } else if (v == 1) {
        this.exMaintenanceUserId = this.userId;
      }
      this.getHousePoolList();
    },
    jumpToInsert() {
      this.$router.push({
        name: "FangYuanGongXiangChiDetail",
        query: {
          articleId: 0,
          num: 0,
          state: 1
        }
      });
    },
    openDetails2(row) {
      //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
      this.$router.push({
        name: "FangYuanGongXiangChiDetail",
        query: {
          articleId: row.articleId,
          num: 1,
          state: 2
        }
      });
    },
    // 获取多选框的值
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getHousePoolList();
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getHousePoolList();
    },
    //重置
    cancelbreedList() {
      this.articleTitle = "";
      this.enabled = "";
      this.currentPage = 1;
    },
    // 删除数据
    handleDelete(index) {
      var data1 = [index.articleId];
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const { data: res } = this.$http
            .delete(this.$url.deleteArticle, {
              data: data1,
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              }
            })
            .then(res => {
              this.loading = false;
              if (res.data.msgCode == 1) {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.GetPetList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            });
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },

    // 删除数据
    async handleDelete1() {
      var data1 = this.multipleSelection;
      var data2 = [];
      if (this.multipleSelection == undefined) {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        });
      } else {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].articleId);
            }
            const { data: res } = this.$http.delete(this.$url.deleteArticle, {
              data: data2,
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              }
            });
            if (res.data.msgCode == 1) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.reload();
            } else {
              this.$message.success(res.message);
            }
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err
            });
          });
      }
    },
    fenpei(index, row, val) {
      this.val = val;
      this.gongxiangDialog = true;
      this.form.houseId = row.houseId;
    },
    updateHousePoolRenLing() {
      const data = [];
      data.push(this.form.userId, this.form.houseId);
      this.$http
        .put(this.$url.UpdateHousePoolRenLing, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.getHousePoolList();
          }
        })
        .catch(err => {
          console.log(err.data);
        });
    },
    updateHousePoolRenLing1() {
      var data = [];
      data.push(this.form.userId);
      this.multipleSelection.forEach(i => {
        data.push(i.houseId);
      });
      this.$http
        .put(this.$url.UpdateHousePoolRenLing, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.getHousePoolList();
          }
        })
        .catch(err => {
          console.log(err.data);
        });
    },
    renling(index, row) {
      this.$confirm("此操作将认领该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const data = [];
          data.push(row.houseId);
          this.$http
            .put(this.$url.UpdateHousePoolRenLing, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              }
            })
            .then(res => {
              if (res.data.msgCode == 1) {
                this.getHousePoolList();
              } else {
                this.$message.error(res.data.message)
              }
            })
            .catch(err => {
              console.log(err.data);
            });
        })
        .catch(err => { });
    },
    fenpei1(val) {
      if (this.multipleSelection == undefined) {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        });
      } else {
        this.val = val;
        this.gongxiangDialog = true;
      }
    },
    renling1() {
      let that = this;
      if (this.multipleSelection == undefined) {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        });
      } else {
        this.$confirm("此操作将认领该任务, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            const data = [];
            this.multipleSelection.forEach(i => {
              data.push(i.houseId);
            });
            this.$http
              .put(this.$url.UpdateHousePoolRenLing, data, {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8"
                }
              })
              .then(res => {
                if (res.data.msgCode == 1) {
                  that.getHousePoolList();
                }
              })
              .catch(err => {
                console.log(err.data);
              });
          })
          .catch(err => { });
      }
    },
    handleEdit() {
      var aa = this.$refs.multipleTable.selection;
      if (aa.length > 1) {
        this.$message({
          message: "请选择一条数据!"
        });
      } else if (aa == "") {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        });
      } else {
        // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
        this.$router.push({
          name: "FangYuanGongXiangChiDetail",
          query: {
            articleId: aa[0].articleId,
            num: 2,
            state: 2
          }
        });
        // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
      }
    },
    queren() {
      console.log(this.multipleSelection1);
      // this.gongxiangName = this.multipleSelection2.join()
      if (this.multipleSelection1.length == 0) {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        })
          .then(() => { })
          .catch(err => {
            this.gongxiangDialog = false;
          });
      } else {
        this.$confirm("此操作将把任务分配, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.gongxiangDialog = false;
          })
          .catch(err => { });
      }
    },
    quxiao() {
      this.gongxiangDialog = false;
    },
    gongxiangDialogDetails(row) {
      this.$confirm("此操作将将此任务分配, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.gongxiangDialog = false;
        })
        .catch(err => { });
    },
    // gongxiangDialogSizeChange(newSize) {
    //     this.pageSize = newSize
    //     // this.getOrganizationaList()
    // },
    /* 监听页码值改变的事件 */
    gongxiangDialogCurrentChange(newPage) {
      this.currentPage = newPage;
      // this.getOrganizationaList()
    },
    Searchgongxiang() { },
    Resetgongxiang() { },
    // 点击导航栏新增tab页
    addTab(targetName, path) {
      let flag = true; //判断是否需要新增页面
      var tabs = this.editableTabs; //活跃当前tab数组
      for (var i = 0; i < tabs.length; i++) {
        //如果存在相同的tab页 不新增tab页
        if (tabs[i].path === path) {
          this.editableTabsValue = tabs[i].name.toString(); //定位到已打开页面
          this.$router.push(path);
          flag = false;
        }
      }
      // 需要新增页面
      if (flag) {
        let newTabName = this.editableTabs.length.toString();
        this.editableTabs.push({
          title: targetName,
          name: newTabName,
          path: path
        });
        this.editableTabsValue = newTabName;
        window.localStorage.setItem(
          "editableTabs",
          JSON.stringify(this.editableTabs)
        );
      }
      this.reload();
      window.localStorage.setItem("activeIndex", path);
    }
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 15px;
}
</style>
