<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <span style="float:right;">
                <el-button size="mini" class="el-button1" type="primary" @click="goBack">返回</el-button>
            </span>
        </el-card>
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
            <el-card>
                <h4 style="margin-top:0px;margin-bottom:0px;">实勘信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传客厅图片" label-width="10%">
                            <el-upload multiple :file-list="picture" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传卧室图片" label-width="10%">
                            <el-upload multiple :file-list="picture1" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success1" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove1" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传卫生间图片" label-width="10%">
                            <el-upload multiple :file-list="picture2" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success2" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove2" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传厨房图片" label-width="10%">
                            <el-upload multiple :file-list="picture3" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success3" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove3" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传户型图图片" label-width="10%">
                            <el-upload multiple :file-list="picture4" :action="UploadPics" :limit="2" list-type="picture-card" :on-progress="fileChange" :on-success="success4" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove4" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传其他图片" label-width="10%">
                            <el-upload multiple :file-list="picture5" :action="UploadPics" :limit="10" list-type="picture-card" :on-progress="fileChange" :on-success="success5" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove5" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="" label-width="20%">
                            <el-button type="primary" size="mini" :disabled="disabled" @click="onSubmit">确认</el-button>
                            <el-button type="warning" size="mini" @click="cancel">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            num: this.$route.query.num,
            houseId: this.$route.query.houseId,
            name: "",
            UploadPics: "",
            type: this.$route.query.type,
            picture: [],
            picture1: [],
            picture2: [],
            picture3: [],
            picture4: [],
            picture5: [],
            dialogVisible: false,
            dialogImageUrl: "",
            form: {},
            shiKanInfo: [],
            ShiKanPics: [],
            ShiKanPics1: [],
            ShiKanPics2: [],
            ShiKanPics3: [],
            ShiKanPics4: [],
            ShiKanPics5: [],

            rules: {},
            disabled: false,
            MyHeader: {
                currentRole: "",
                accessToken: "",
                fp: ""
            },
            removeType: ""
        }
    },
    created() {
        // this.reload()
        this.MyHeader.accessToken = localStorage.getItem("accessToken") //获取accessToken
        this.MyHeader.fp = localStorage.getItem("fp") //浏览器指纹fp
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        this.name = "实勘信息"
        this.GetHouseDetail()
        // if (this.type == 1) {
        //     // 出租
        //     this.removeType = "houses"
        //     this.UploadPics = this.$url.UploadHouses
        // } else {
        //     // 出售
        //     this.removeType = "houses"
        //     this.UploadPics = this.$url.UploadHouses
        // }
        this.removeType = "houses"
        this.UploadPics = this.$url.UploadHouses
        console.log(this.removeType)
        console.log(this.UploadPics)
    },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        //图片上传成功
        success(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.ShiKanPics.push(response.id)

                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        success1(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.ShiKanPics1.push(response.id)

                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        success2(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.ShiKanPics2.push(response.id)
                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        success3(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.ShiKanPics3.push(response.id)

                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        success4(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.ShiKanPics4.push(response.id)

                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        success5(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }

                this.disabled = false

                this.ShiKanPics5.push(response.id)

                this.$message.success("上传图片成功")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        fileChange() {
            this.disabled = true
        },

        //图片上传id
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        //图片上传失败
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
        },
        //图片上传删除
        handleBeforeRemove(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            mm = file.name
            let ids = new Set(this.ShiKanPics)
            ids.delete(mm)
            this.ShiKanPics = Array.from(ids)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        handleBeforeRemove1(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            mm = file.name
            let ids = new Set(this.ShiKanPics1)
            ids.delete(mm)
            this.ShiKanPics1 = Array.from(ids)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        handleBeforeRemove2(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            mm = file.name
            let ids = new Set(this.ShiKanPics2)
            ids.delete(mm)
            this.ShiKanPics2 = Array.from(ids)
            console.log(file.response)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        handleBeforeRemove3(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            mm = file.name
            let ids = new Set(this.ShiKanPics3)
            ids.delete(mm)
            this.ShiKanPics3 = Array.from(ids)
            console.log(file.response)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        handleBeforeRemove4(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            let ids = new Set(this.ShiKanPics4)
            ids.delete(mm)
            this.ShiKanPics4 = Array.from(ids)

            console.log(this.removeType)
            // 删除服务器照片
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        handleBeforeRemove5(file, fileList) {
            var mm = ""
            if (file.response != undefined) {
                mm = file.response.id
            } else {
                mm = file.name
            }
            let ids = new Set(this.ShiKanPics5)
            ids.delete(mm)
            this.ShiKanPics5 = Array.from(ids)
            console.log(this.type)
            console.log(this.removeType)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, this.removeType)
            // })
        },
        onSubmit() {
            const params = {
                keting: this.ShiKanPics.join(","),
                woshi: this.ShiKanPics1.join(","),
                weishengjian: this.ShiKanPics2.join(","),
                chufang: this.ShiKanPics3.join(","),
                huxingtu: this.ShiKanPics4.join(","),
                qita: this.ShiKanPics5.join(",")
            }
            if (this.ShiKanPics4.length == 0) {
                this.$message.error("请上传户型图")
                return
            } else {
                if (
                    this.ShiKanPics.length +
                        this.ShiKanPics1.length +
                        this.ShiKanPics2.length +
                        this.ShiKanPics3.length +
                        this.ShiKanPics5.length >
                    0
                ) {
                    this.disabled = false
                } else {
                    this.$message.error("除户型图外，至少上传一张图片")
                    return
                }
            }

            const data = {
                houseId: this.houseId,
                businessType: "SHIKAN",
                shiKanInfo: JSON.stringify(params)
            }
            this.$http.put(this.$url.UpdateHouseShiKan, data).then((res) => {
                if (res.data.msgCode == 1) {
                    this.$message.success("实勘信息修改成功!")
                    this.$router.go(-1)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        GetHouseDetail() {
            var that = this
            this.$http
                .get(this.$url.GetHouseDetail, {
                    params: {
                        houseId: this.houseId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        console.log(res.data)
                        console.log(that.type)
                        if (res.data.dtList[0].shiKanInfo != null) {
                            that.shiKanInfo = JSON.parse(
                                res.data.dtList[0].shiKanInfo
                            )
                            console.log("shiKanInfo", that.shiKanInfo)
                            if (
                                that.shiKanInfo.keting != "" &&
                                that.shiKanInfo.keting != null
                            ) {
                                let picture = that.shiKanInfo.keting.split(",")
                                that.ShiKanPics = picture
                                // 出售实勘
                                // if (that.type == 2) {
                                //     picture.forEach((item) => {
                                //         that.picture.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture.forEach((item) => {
                                        that.picture.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                            if (
                                that.shiKanInfo.woshi != "" &&
                                that.shiKanInfo.woshi != null
                            ) {
                                let picture1 = that.shiKanInfo.woshi.split(",")
                                that.ShiKanPics1 = picture1
                                // 出售
                                // if (that.type == 2) {
                                //     picture1.forEach((item) => {
                                //         that.picture1.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture1.forEach((item) => {
                                        that.picture1.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                            if (
                                that.shiKanInfo.weishengjian != "" &&
                                that.shiKanInfo.weishengjian != null
                            ) {
                                let picture2 =
                                    that.shiKanInfo.weishengjian.split(",")
                                that.ShiKanPics2 = picture2
                                // 出售
                                // if (that.type == 2) {
                                //     picture2.forEach((item) => {
                                //         that.picture2.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture2.forEach((item) => {
                                        that.picture2.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                            if (
                                that.shiKanInfo.chufang != "" &&
                                that.shiKanInfo.chufang != null
                            ) {
                                let picture3 =
                                    that.shiKanInfo.chufang.split(",")
                                that.ShiKanPics3 = picture3
                                // 出售
                                // if (that.type == 2) {
                                //     picture3.forEach((item) => {
                                //         that.picture3.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture3.forEach((item) => {
                                        that.picture3.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                            if (
                                that.shiKanInfo.huxingtu != "" &&
                                that.shiKanInfo.huxingtu != null
                            ) {
                                let picture4 =
                                    that.shiKanInfo.huxingtu.split(",")
                                that.ShiKanPics4 = picture4
                                // 出售
                                // if (that.type == 2) {
                                //     picture4.forEach((item) => {
                                //         that.picture4.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture4.forEach((item) => {
                                        that.picture4.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                            if (
                                that.shiKanInfo.qita != "" &&
                                that.shiKanInfo.qita != null
                            ) {
                                let picture5 = that.shiKanInfo.qita.split(",")
                                that.ShiKanPics5 = picture5
                                // 出售
                                // if (that.type == 2) {
                                //     picture5.forEach((item) => {
                                //         that.picture5.push({
                                //             name: item,
                                //             url:
                                //                 that.$url.houses +
                                //                 item
                                //         })
                                //     })
                                // } else {
                                    picture5.forEach((item) => {
                                        that.picture5.push({
                                            name: item,
                                            url:
                                                that.$url.houses +
                                                item
                                        })
                                    })
                                // }
                            }
                        }
                    }
                })
        },
        cancel() {
            this.$router.go(-1)
            this.reload()
        }
    }
}
</script>