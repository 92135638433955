import { render, staticRenderFns } from "./GongGaoDetail.vue?vue&type=template&id=275e2170&scoped=true"
import script from "./GongGaoDetail.vue?vue&type=script&lang=js"
export * from "./GongGaoDetail.vue?vue&type=script&lang=js"
import style0 from "./GongGaoDetail.vue?vue&type=style&index=0&id=275e2170&prod&lang=less&scoped=true"
import style1 from "./GongGaoDetail.vue?vue&type=style&index=1&id=275e2170&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275e2170",
  null
  
)

export default component.exports