<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <!-- <router-link :to="{ path: '/ShangHuGuanLiList' }"> -->
            <el-button size="mini" class="el-button1" type="warning" style="float: right" @click="cancel">返回</el-button>
            <!-- </router-link> -->
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">


                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商户:" label-width="27%" prop="merchantName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.merchantName"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="法人名:" label-width="20%" prop="bossName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="企业名称:" label-width="27%" prop="companyName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="法人身份证号:" label-width="20%" prop="bossIdCardNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossIdCardNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人手机号:" label-width="27%" prop="bossMobile">
                            <el-input :disabled="flag" :maxlength="11" style="width: 75.5%" v-model="form.bossMobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="法人银行名称:" label-width="20%" prop="bossBankName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossBankName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人银行卡号 :" label-width="27%" prop="bossBankNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossBankNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="开户行名称:" label-width="20%" prop="companyBankName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyBankName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开户行账号:" label-width="27%" prop="companyBankNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyBankNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="税号:" label-width="20%" prop="companyTaxNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyTaxNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="营业执照:" label-width="27%" prop="businessLicensePicId">
                            <span class="redStar" style="margin-left: -87px;">*</span>
                            <el-upload :class="{isHide:hide}" :disabled="flag" style="margin-top: -22px;" :action="UploadPics" :limit="1" :on-exceed='pictureLength' :file-list='picture' list-type="picture-card" :on-success="pictureSuccess" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="num1== 1">
                        <el-form-item label="是否可用:" label-width="20%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 45%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                </el-row>

                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="27%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="27%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="13.5%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import commonJs from "../../assets/js/common.js" //共通js方法
export default {
    inject: ["reload"],
    data() {
        // 手机号
        var inputPhone = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入法人手机号"))
                this.loading = false
            } else if (!commonJs.numberPhone(value)) {
                callback(new Error("格式不符，请填写正确的手机号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        // 身份证
        var IdcardNo = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入法人身份证号"))
            } else if (!commonJs.shenfenZheng(value)) {
                callback(new Error("格式不符，请填写正确的身份证号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        // 税号
        var TaxNo = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入税号"))
            } else if (!commonJs.suiHao(value)) {
                callback(new Error("格式不符，请填写正确的税号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        // 银行卡号
        var bankAccountValid = (rule, value, callback) => {
            const strBin =
                "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99"
            if (!value) {
                return callback(new Error("收款账户不能为空"))
            } else if (!Number.isInteger(+value)) {
                callback(new Error("银行卡号必须全为数字"))
            } else if (value.trim().length < 12 || value.trim().length > 19) {
                callback(new Error("银行卡号长度必须在12到19之间"))
            } else if (strBin.indexOf(value.substring(0, 2)) === -1) {
                callback(new Error("银行卡号开头6位不符合规范"))
            } else {
                callback()
            }
        }

        return {
            hide: false,
            options1: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            num1: "",
            num: this.$route.query.num,
            merchantId: this.$route.query.merchantId,
            name: "",
            options: [],
            flag: false,
            MyHeader: { currentRole: "", accessToken: "", fp: "" },
            loading: true,
            id: "",
            form: {
                merchantName: "",
                enabled: "",
                // 法人名
                bossName: "",
                // 企业名称
                companyName: "",
                // 税号
                companyTaxNo: "",
                // 法人身份证号
                bossIdCardNo: "",
                // 法人手机号
                bossMobile: "",
                // 银行账户
                bossBankName: "",
                // 法人银行卡号
                bossBankNo: "",
                //  开户行名称
                companyBankName: "",
                // 开户行账号
                companyBankNo: "",
                // 营业执照
                businessLicensePicId: ""
            },
            merchantId: "",
            currentPage: 1,
            pageSize: 100,
            rules: {
                merchantId: [
                    {
                        required: true,
                        message: "请输入商户ID",
                        trigger: "blur"
                    }
                ],
                merchantName: [
                    {
                        required: true,
                        message: "请输入商户",
                        trigger: "blur"
                    }
                ],

                // 法人名
                bossName: [
                    {
                        required: true,
                        message: "请输入法人名",
                        trigger: "blur"
                    }
                ],
                // 企业名称
                companyName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur"
                    }
                ],
                // 税号
                companyTaxNo: [
                    {
                        required: true,
                        validator: TaxNo,
                        trigger: "blur"
                    }
                ],
                // 法人身份证号
                bossIdCardNo: [
                    {
                        required: true,
                        validator: IdcardNo,
                        trigger: "blur"
                    }
                ],
                // 法人手机号
                bossMobile: [
                    {
                        required: true,
                        validator: inputPhone,
                        trigger: "blur"
                    }
                ],
                // 法人银行名称
                bossBankName: [
                    {
                        required: true,
                        message: "请输入法人银行名称",
                        trigger: "blur"
                    }
                ],
                // 法人银行卡号
                bossBankNo: [
                    {
                        required: true,
                        validator: bankAccountValid,
                        trigger: "blur"
                    }
                ],
                //  开户行名称
                companyBankName: [
                    {
                        required: true,
                        message: "请输入开户行名称",
                        trigger: "blur"
                    }
                ],
                // 开户行账号
                companyBankNo: [
                    {
                        required: true,
                        message: "请输入开户行账号",
                        trigger: "blur"
                    }
                ]
                // 营业执照
                // businessLicensePicId: [
                //     {
                //         required: true,
                //         message: "请上传营业执照",
                //         trigger: "blur"
                //     }
                // ]
            },
            UploadPics: this.$url.UploadOthers,
            dialogImageUrl: "",
            dialogVisible: false,
            picture: [
                // {
                //     url:
                //         this.$url.othersPicSrc +
                //         "3912541d455e4f218d4e4610066b8781.jfif"
                // }
            ],
            pictureId: ""
        }
    },

    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        this.merchantId = this.$route.query.merchantId
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "商户新增"
            this.form = {}
        } else if (this.num == 1) {
            this.name = "商户详情"
            this.flag = true

            this.num1 = 1
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "商户修改"
            this.getArticleDetail()
        }
    },
    methods: {
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        merchantName: this.form.merchantName,
                        enabled: this.enabled,
                        companyName: this.form.companyName,
                        companyTaxNo: this.form.companyTaxNo,
                        companyBankNo: this.form.companyBankNo,
                        companyBankName: this.form.companyBankName,
                        bossName: this.form.bossName,
                        BossIdCardNo: this.form.bossIdCardNo,
                        bossMobile: this.form.bossMobile,
                        bossBankNo: this.form.bossBankNo,
                        bossBankName: this.form.bossBankName,
                        businessLicensePicId: this.pictureId
                    }
                    if (this.pictureId == "") {
                        return this.$message.error("请上传营业执照")
                    }
                    const { data: res } = await this.$http.post(
                        this.$url.InsertMerchant,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        // this.$message.success("新增商户成功")
                        // this.$router.push({ path: "/ShangHuGuanLiList" })
                        this.$router.go(-1)
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        merchantId: this.merchantId,
                        merchantName: this.form.merchantName,
                        enabled: this.form.enabled,
                        companyName: this.form.companyName,
                        companyTaxNo: this.form.companyTaxNo,
                        companyBankNo: this.form.companyBankNo,
                        companyBankName: this.form.companyBankName,
                        bossName: this.form.bossName,
                        BossIdCardNo: this.form.bossIdCardNo,
                        bossMobile: this.form.bossMobile,
                        bossBankNo: this.form.bossBankNo,
                        bossBankName: this.form.bossBankName,
                        businessLicensePicId: this.pictureId
                    }
                    if (this.pictureId == "") {
                        return this.$message.error("请上传营业执照")
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateMerchant,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        // this.$message.success("修改商户成功")
                        // this.$router.push({ path: "/ShangHuGuanLiList" })
                        this.$router.go(-1)
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // 获取详情
        async getArticleDetail() {
            const data = {
                merchantId: this.merchantId
            }
            await this.$http
                .get(this.$url.GetMerchantDetail, {
                    params: data
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        if (
                            this.form.businessLicensePicId != null &&
                            this.form.businessLicensePicId != ""
                        ) {
                            this.picture.push({
                                name: this.form.businessLicensePicId,
                                url:
                                    this.$url.othersPicSrc +
                                    this.form.businessLicensePicId
                            })
                            this.pictureId = this.form.businessLicensePicId
                            this.hide = true
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },

        cancel() {
            // this.$router.push({ path: "/ShangHuGuanLiList" })
            this.$router.go(-1)
        },
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].reportId)
                        }
                        this.$http
                            .delete(this.$url.deleteReport, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        handleDelete(index) {
            var data1 = [index.reportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.deleteReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {})
        },
        // 上传图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
            console.log(this.dialogImageUrl)
            console.log(file)
        },
        handleRemove(file, fileList) {
            this.pictureId = ""
            this.hide = false

            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, "others")
            // })
        },
        pictureSuccess(file) {
            this.pictureId = file.id
            this.hide = true
        },
        pictureLength(file) {
            console.log(file)
            this.$message.success("最多只能上传一张图片!")
        }
    }
}
</script>

<style scoped>
.isHide /deep/ .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>