<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <!-- <router-link :to="{ path: '/RenYuanGuanLiList' }"> -->
            <el-button size="mini" @click="goBack" class="el-button1" type="warning" style="float: right">返回</el-button>
            <!-- </router-link> -->

            <el-button v-if="roleType != '3' && num != 1" size="mini" class="el-button1" type="primary"
                @click="addjibengongzi()" style="margin-right:10px; float: right">设置基本工资</el-button>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户类型:" label-width="20%">
                            <el-checkbox-group v-model="checkedUserType" @change="handleCheckeduserType">
                                <template v-if="roleType != 4"><el-checkbox v-for="userType in userTypeList"
                                        :label="userType.value" :key="userType.value" :disabled="flag">{{ userType.label
                                        }}</el-checkbox></template>
                                <template v-else><el-checkbox v-for="userType in userTypeListRS" :label="userType.value"
                                        :key="userType.value" :disabled="flag">{{ userType.label }}</el-checkbox></template>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别:" prop="gender" label-width="25%">
                            <el-radio-group v-model="form.gender" style="width: 50%" :disabled="flag">
                                <el-radio label="1">男</el-radio>
                                <el-radio label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户名:" label-width="20%" prop="userName">
                            <el-input placeholder="请输入用户名" style="width: 80%" v-model="form.userName" maxlength="11"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地址:" label-width="20%">
                            <el-input placeholder="请输入地址 " style="width: 80%" v-model="form.address" maxlength="11"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="手机号:" label-width="20%" prop="mobile1">
                            <el-input placeholder="请输入手机号" style="width: 80%" v-model="form.mobile1" maxlength="11"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备用手机号:" label-width="20%">
                            <el-input placeholder="请输入手机号" style="width: 80%" v-model="form.mobile2" maxlength="11"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="邮箱:" label-width="20%" prop="email">
                            <el-input placeholder="请输入邮箱" style="width: 80%" v-model="form.email"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="等级:" prop="userLevel" label-width="20%">
                            <el-select v-model="form.userLevel" placeholder="请选择等级" @change="handle" :disabled="flag1"
                                style="width: 80%;">
                                <el-option v-for=" item in gradeList" :label="item.label" :key="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商户名称:" label-width="20%" prop="MerchantName" v-if="MerchantFalse">
                            <el-button size="menium" class="buttonInput button_tanchu" @click="merchantDialogTrue"
                                :disabled="flag" style="width: 80%;height: 22px;line-height:0px;text-align: left;">
                                <span v-if="MerchantName == '请选择商户'" style="color:#c0c4cc">{{ MerchantName }}</span>
                                <span v-if="MerchantName != '请选择商户'">{{ MerchantName }}</span>
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="店铺名称:" label-width="20%" prop="ShopName">
                            <el-button size="menium" class="buttonInput button_tanchu" @click="shopDialogTrue"
                                :disabled="flag" style="width: 80%;height: 22px;line-height:0px;text-align: left;">
                                <span v-if="ShopName == '请选择店铺'" style="color:#c0c4cc">{{ ShopName }}</span>
                                <span v-if="ShopName != '请选择店铺'">{{ ShopName }}</span>
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="isShangQuanJingLi">
                        <el-form-item label="身份证号:" label-width="20%" prop="idCardNo">
                            <el-input placeholder="请输入身份证号" style="width: 80%" v-model="form.idCardNo" maxlength="18"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="!isShangQuanJingLi">
                        <el-form-item label="身份证号:" label-width="20%" prop="idCardNo1">
                            <el-input placeholder="请输入身份证号" style="width: 80%" v-model="form.idCardNo1" maxlength="18"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行卡号:" label-width="20%" prop="bankAccount">
                            <el-input placeholder="请输入银行卡号" style="width: 80%" v-model="form.bankAccount" maxlength="18"
                                :disabled="flag"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="入职时间:" label-width="20%" prop="entryTime">
                            <el-date-picker v-model="form.entryTime" type="date" format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd" placeholder="请选择" style="width: 80%"> </el-date-picker>
                            <!-- <el-date-picker v-model="form.entryTime" style="width: 90%;" type="datetime" placeholder="请选择入职时间" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker> -->
                            <!-- <el-input placeholder="请选择入职时间" style="width: 80%" v-model="form.entryTime" :disabled="flag"></el-input> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-show="num != 0">
                        <el-form-item label="积分:" label-width="20%" prop="integral">
                            <el-input placeholder="请填写积分" style="width: 80%" v-model="form.integral"
                                :disabled="flag1"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row>
                    <el-col :span="12" v-if="this.num != 0">
                        <el-form-item label="是否可用:" prop="enabled" label-width="20%">
                            <el-radio-group v-model="enabled" style="width: 50%" :disabled="flag" @change="keyong">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="this.num == 2 && (roleType == 5 || roleType == 6)">
                        <el-form-item label="" prop="enabled" label-width="20%">
                            <el-button @click="clean" size="mini" type="primary">
                                重置密码
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="this.num != 0 && dialogImageUrl != ''">
                    <el-col :span="12">
                        <el-form-item label="头像:" label-width="20%" prop="pictureId">

                            <img width="100%" :src="dialogImageUrl" id="touxiang" v-if="logo" alt />

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime
                        }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime
                        }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName
                        }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName
                        }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
            <!-- 商户弹出层 -->
            <el-dialog :visible.sync="merchantDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">商户：</span>
                            <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary"
                                    @click="cgetMerchantList">查询</el-button>
                                <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col>
                            <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                            <el-button size="mini" @click="handleDelete1()" type="warning"
                                icon="el-icon-delete">删除</el-button>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="MerchantDialogDetails" :data="operationList1"
                    tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center', 'color': '#333', 'font-size': '14px' }"
                    :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="商户">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.merchantName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                    :current-page="merchantCurrentPage" :page-sizes="[10, 20, 50]" :page-size="merchantPageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="merchantDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 店铺弹出层 -->
            <el-dialog :visible.sync="shopDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">店铺：</span>
                            <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary"
                                    @click="cgetDianPuList">查询</el-button>
                                <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px" v-if="roleType == 6">
                        <el-col>
                            <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                            <el-button size="mini" @click="handleDelete1()" type="warning"
                                icon="el-icon-delete">删除</el-button>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="ShopDialogDetails" :data="operationList2" tooltip-effect="dark"
                    :header-cell-style="{ 'text-align': 'center', 'color': '#333', 'font-size': '14px' }"
                    :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="店铺" prop="shopName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.shopName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户" prop="merchantName"></el-table-column>
                    <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                    :current-page="shopCurrentPage" :page-sizes="[10, 20, 50]" :page-size="shopPageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="shopDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 分配经纪人弹出层 -->
            <el-dialog :visible.sync="UserDialog" direction="rtl" width="70%" top="4%" class="dialogCss"
                :before-close="handleClose">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">联系人：</span>
                            <el-input v-model="userName" placeholder="请输入联系人" style="width: 20%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary"
                                    @click="cgetJingJiRenList">查询</el-button>
                                <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                    <!-- <el-row style="margin-top: 10px">
                        <el-col>
                            <el-button size="mini" @click="fenpei" type="primary" icon="el-icon-edit" style="background-color: rgb(14 153 219); border: none; margin-left: 10px">分配</el-button>
                        </el-col>
                    </el-row> -->
                </el-card>
                <!-- 底部内容区域 -->
                <el-table class="tableButtonPnone" ref="multipleTable" :data="operationList3" tooltip-effect="dark"
                    :header-cell-style="{ 'text-align': 'center', 'color': '#333', 'font-size': '14px' }"
                    :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="头像" prop="imgtoux" width="80">
                        <template slot-scope="scope">
                            <div class="block">
                                <el-avatar :size="50" :src="scope.row.imgtoux"></el-avatar>
                            </div>
                            <span :class="scope.row.key == '1' ? 'el-icon-key' : ''"
                                style="position: absolute;right:3px;bottom:20px;font-weight:bold;color: #000;transform: rotate(30deg);"></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商圈" prop="areaNames"></el-table-column>
                    <el-table-column label="联系人" prop="userName"></el-table-column>
                    <el-table-column label="性别" prop="gender"></el-table-column>
                    <el-table-column label="职位" prop="userType1" width="300"></el-table-column>
                    <el-table-column label="联系方式(一)" prop="mobile1"></el-table-column>
                    <el-table-column label="联系方式(二)" prop="mobile2"></el-table-column>
                    <el-table-column label="邮箱" prop="email"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="fenpei(scope.row)" type="text" size="mini" class="el-icon-edit"
                                style="color: #1890ff;">分配</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
                    :current-page="userCurrentPage" :page-sizes="[10, 20, 50]" :page-size="userPageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="userDialogTotal">
                </el-pagination>
            </el-dialog>
        </el-card>
        <el-dialog class="zhiyinDialog" title="基本工资" :visible.sync="gongziDialogVisible" width="70%">
            <el-form :model="form" ref="form" label-width="100px" class="demo-dynamic">
                <el-form-item prop="currentBasicSalary" label="基本工资" style="width:90%">
                    <el-input v-model="form.currentBasicSalary" style="width:27%"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">确 定</el-button>
                    <el-button @click="gongziDialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import commonJs from "../../assets/js/common.js" //共通js方法
import "../RenYuanGuanLi/RenYuanGuanLi.css"
export default {
    inject: ["reload"],
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        var inputPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"))
                this.loading = false
            } else if (!commonJs.numberPhone(value)) {
                callback(new Error("格式不符，请填写正确的手机号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        // 身份证
        var inputIdCardNo = (rule, value, callback) => {
            let that = this
            if (that.isShangQuanJingLi && value === "") {
                callback(new Error("请输入法人身份证号"))
            } else if (
                value !== "" &&
                value != undefined &&
                !commonJs.shenfenZheng(value)
            ) {
                callback(new Error("格式不符，请填写正确的身份证号!"))
                that.loading = false
            } else {
                callback()
            }
        }
        return {
            form: {
                currentBasicSalary: 0
            },
            gongziDialogVisible: false,
            isShangQuanJingLi: false,
            merchantId: localStorage.getItem("merchantId"),
            MyHeader: { accessToken: "", fp: "" },
            userId: this.$route.query.userId, //用户id
            merchantId: "",
            num: this.$route.query.num, //权限 （0 新增 1 详情 2 修改）
            flag: false, //判断输入框是否可修改
            flag1: false,//判断积分输入框（只有平台管理员可以修改）
            picture_flag: false,
            name: "", //标题头
            userType: "", //用户类型
            checkedUserType: [], //用户类型选中
            enabled: "1", //是否可用
            isMax: false,
            roleType: "",
            picture: [],
            dialogVisible: false,
            logo: true,
            dialogImageUrl: "",
            merchantName: "",
            MerchantName: "请选择商户",
            merchantId: this.$route.query.merchantId,
            merchantPageSize: 10,
            merchantCurrentPage: 1,
            merchantDialog: false,
            merchantDialogTotal: 0,
            operationList1: [],

            shopName: "",
            ShopName: "请选择店铺",
            shopId: this.$route.query.shopId,
            shopPageSize: 10,
            shopCurrentPage: 1,
            shopDialog: false,
            shopDialogTotal: 0,
            operationList2: [],
            MerchantFalse: true,

            UserDialog: false,
            userName: "",
            operationList3: [],
            userPageSize: 10,
            userCurrentPage: 1,
            userDialogTotal: 0,
            //用户类型多选值
            userTypeList: [
                {
                    value: "1",
                    label: "经纪人"
                },
                {
                    value: "2",
                    label: "商圈经理"
                },
                {
                    value: "3",
                    label: "商户财务"
                },
                {
                    value: "4",
                    label: "人事专员"
                },
                {
                    value: "5",
                    label: "店东"
                },
                {
                    value: "6",
                    label: "平台管理员"
                },
                {
                    value: "7",
                    label: "交易中心"
                },
                {
                    value: "9",
                    label: "超级经纪人"
                }
            ],
            //用户类型多选值
            userTypeListRS: [
                {
                    value: "1",
                    label: "经纪人"
                },
                {
                    value: "2",
                    label: "商圈经理"
                },
                {
                    value: "3",
                    label: "商户财务"
                },
                {
                    value: "4",
                    label: "人事专员"
                },
                {
                    value: "7",
                    label: "交易中心"
                }
            ],
            MyHeader: {
                accessToken: "",
                fp: ""
            },
            form: {
                idCardNo: "",
                idCardNo1: "",
                userLevel: "实习期",
                userName: "",
                gender: "1",
                address: "",
                email: "",
                mobile1: "",
                mobile2: "",
                pictureId: "",
                MerchantName: "",
                ShopName: "",
                bankAccount: "",
                entryTime: "",
                integral: "",
            },
            rules: {
                mobile1: [
                    { required: true, validator: inputPhone, trigger: "blur" }
                ],
                MerchantName: [
                    { required: true, message: "请选择商铺", trigger: "blur" }
                ],
                ShopName: [
                    { required: true, message: "请选择店铺", trigger: "blur" }
                ],
                idCardNo: [
                    {
                        required: true,
                        validator: inputIdCardNo,
                        trigger: "blur"
                    }
                ],
                idCardNo1: [
                    {
                        required: false,
                        validator: inputIdCardNo,
                        trigger: "blur"
                    }
                ],
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur"
                    }
                ],
                userType: [
                    {
                        required: true,
                        message: "请选择用户类型",
                        trigger: "blur"
                    }
                ],
                userLevel: [
                    {
                        required: true,
                        message: "请选择等级",
                        trigger: "blur"
                    }
                ],
                entryTime: [
                    {
                        required: true,
                        message: "请选择入职时间",
                        trigger: "blur"
                    }
                ]
            },
            gradeList: [
                {
                    label: "实习期",
                    value: "0"
                },
                {
                    label: "A1",
                    value: "1"
                },
                {
                    label: "A2",
                    value: "2"
                },
                {
                    label: "A3",
                    value: "3"
                },
                {
                    label: "A4",
                    value: "4"
                },
                {
                    label: "A5",
                    value: "5"
                },
                {
                    label: "A6",
                    value: "6"
                },
                {
                    label: "A7",
                    value: "7"
                },
                {
                    label: "A8",
                    value: "8"
                },
                {
                    label: "A9",
                    value: "9"
                },
                {
                    label: "A10",
                    value: "10"
                },
            ]
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.merchantId = localStorage.getItem("merchantId")
        this.roleType = localStorage.getItem("roleType")
        if (this.roleType == 4 || this.roleType == 5) {
            this.MerchantName = ""
            this.MerchantFalse = false
            this.getDianPuList()
        }
        if (this.roleType == 6) {
            this.getMerchantList()
        }

        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "人员管理新增"
            // document.getElementsByClassName(".touxiang").styte("none")
            // this.flag4 = false;
            // this.isShangQuanJingLi = true
        } else if (this.num == 1) {
            this.name = "人员管理详情"
            this.flag = true
            this.flag1 = true
            this.isMax = true
            //  document.getElementsByClassName(".ql-toolbar").styte("none")
            this.GetUserDetail()
        } else if (this.num == 2) {
            this.name = "人员管理修改"
            if (this.roleType != 6 && this.roleType != 4) {
                this.flag1 = true
            }
            this.GetUserDetail()
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        // 添写基本工资
        async submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        userId: this.userId,
                        currentBasicSalary: this.form.currentBasicSalary
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateUserCurrentBasicSalary,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改基本工资成功")
                        this.gongziDialogVisible = false
                        // this.GetUserDetail()
                        this.reload()
                    } else {
                        var message = commonJs.getUrlError(res.message)
                        this.$message.error(message)
                    }
                } else {
                    return false
                }
            })
        },
        //填写基本工资弹层
        addjibengongzi(index, row) {
            this.gongziDialogVisible = true
        },
        clean() {
            var data = {
                mobile: this.form.mobile1
            }
            this.$confirm("此操作将重置密码, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .post(this.$url.ResetUserPassword + "/" + data.mobile)
                        .then((res) => {
                            this.loading = false
                            if (res.data.success == "true") {
                                this.$message({
                                    type: "success",
                                    message: "重置成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: "重置失败!"
                                })
                            }
                        })
                })
                .catch((err) => { })
        },
        handle(val) {
            console.log(val, "2")
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log(this.multipleSelection);
        },
        // 详情
        async GetUserDetail() {
            await this.$http
                .get(this.$url.GetUserDetail, {
                    params: {
                        userId: encodeURIComponent(this.userId)
                    }
                })
                .then((res) => {
                    var that = this
                    if (res.data.msgCode == 1) {
                        that.form = res.data.dtList[0]
                        that.enabled = res.data.dtList[0].enabled
                        that.userTypeList.forEach(function (item, i) {
                            if (
                                res.data.dtList[0].userType.indexOf(
                                    item.value
                                ) != -1
                            ) {
                                that.checkedUserType.push(item.value)
                            }
                        })
                        console.log(that.checkedUserType)
                        console.log(that.checkedUserType.indexOf("2"))
                        if (that.checkedUserType.indexOf("2") != -1) {
                            that.isShangQuanJingLi = true
                            console.log(that.$refs, "2")
                        } else {
                            that.isShangQuanJingLi = false
                            // console.log(that.$refs.form.rules)
                            // console.log(that.$refs, "2")
                            // that.$refs.form.rules.idCardNo[0].required = false
                            // that.$refs.form.clearValidateField("idCardNo")

                            console.log(that.$refs, "2")
                        }
                        if (that.num == 1) {
                            if (that.form.idCardNo) {
                                if (that.form.idCardNo.length == 18) {
                                    that.form.idCardNo =
                                        that.form.idCardNo.slice(0, 6) +
                                        "************"
                                } else if (that.form.idCardNo.length == 17) {
                                    that.form.idCardNo =
                                        that.form.idCardNo.slice(0, 6) +
                                        "***********"
                                } else {
                                    that.form.idCardNo =
                                        that.form.idCardNo.slice(0, 6) +
                                        "*********"
                                }
                            }
                        }
                        if (that.checkedUserType.indexOf("2") != -1) {
                            that.form.idCardNo1 = res.data.dtList[0].idCardNo
                        } else {
                            that.form.idCardNo = res.data.dtList[0].idCardNo
                        }
                        if (
                            res.data.dtList[0].pictureId != null &&
                            res.data.dtList[0].pictureId != ""
                        ) {
                            this.dialogImageUrl =
                                this.$url.avatarsPicSrc +
                                res.data.dtList[0].pictureId
                        } else {
                            this.dialogImageUrl = ""
                        }
                        that.form.MerchantName = res.data.dtList[0].merchantName
                        that.form.ShopName = res.data.dtList[0].shopName
                        that.MerchantName = res.data.dtList[0].merchantName
                        that.ShopName = res.data.dtList[0].shopName
                        that.merchantId = res.data.dtList[0].merchantId
                        that.shopId = res.data.dtList[0].shopId
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        // 新增按钮
        async onSubmit(formName) {
            console.log(this.merchantId)
            console.log(this.shopId)
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.userType = this.checkedUserType
                        .toString()
                        .replace(/,/g, "")
                    if (this.isShangQuanJingLi == false) {
                        this.form.idCardNo = this.form.idCardNo1
                    }
                    const data = {
                        // 身份证
                        idCardNo: this.form.idCardNo,
                        bankAccount: this.form.bankAccount,
                        Userlevel: this.form.userLevel,
                        userName: this.form.userName,
                        gender: this.form.gender,
                        mobile1: this.form.mobile1,
                        mobile2: this.form.mobile2,
                        address: this.form.address,
                        email: this.form.email,
                        userType: this.userType,
                        merchantId: this.merchantId,
                        shopId: this.shopId,
                        entryTime: this.form.entryTime,
                        integral: 0,
                    }
                    if (this.userType === "") {
                        this.loading = false
                        return this.$message.error("请选择用户类型")
                    }
                    // if (this.merchantId == "") {
                    //     this.loading = false
                    //     return this.$message.error("请选择商户")
                    // }
                    // if (this.shopId == undefined) {
                    //     this.loading = false
                    //     return this.$message.error("请选择店铺")
                    // }
                    this.$http.post(this.$url.InsertUser, data).then((res) => {
                        if (res.data.result === 1) {
                            this.$message.success("人员新增成功!")
                            this.$router.push({ path: "/RenYuanGuanLiList" })
                        } else {
                            this.loading = false
                            this.$message.error(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        // 更新按钮
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.$refs[formName].validateField("idCardNo", (valid) => {
                        if (valid) {
                            console.log("2")
                        }
                    })
                    this.userType = this.checkedUserType
                        .toString()
                        .replace(/,/g, "")
                    if (this.isShangQuanJingLi == false) {
                        this.form.idCardNo = this.form.idCardNo1
                    }
                    const data = {
                        idCardNo: this.form.idCardNo,
                        Userlevel: this.form.userLevel,
                        userId: this.userId,
                        userName: this.form.userName,
                        gender: this.form.gender,
                        mobile1: this.form.mobile1,
                        mobile2: this.form.mobile2,
                        address: this.form.address,
                        email: this.form.email,
                        bankAccount: this.form.bankAccount,
                        userType: this.userType,
                        merchantId: this.merchantId,
                        enabled: this.enabled,
                        shopId: this.shopId,
                        entryTime: this.form.entryTime,
                        integral: this.form.integral,
                    }
                    if (this.merchantId == "") {
                        this.loading = false
                        return this.$message.error("请选择商户")
                    }
                    if (this.shopId == undefined) {
                        this.loading = false
                        return this.$message.error("请选择店铺")
                    }
                    // console.log(diseaseIds)
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateUser,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改人员信息成功")
                        this.$router.push({ path: "/RenYuanGuanLiList" })
                    } else {
                        var message = commonJs.getUrlError(res.message)
                        this.$message.error(message)
                    }
                } else {
                    return false
                }
            })
        },
        // 用户类型多选选中
        handleCheckeduserType(value) {
            this.checkedUserType = value
            for (var i = 0; i < this.checkedUserType.length - 1; i++) {
                for (var j = 0; j < this.checkedUserType.length - i - 1; j++) {
                    if (this.checkedUserType[j] > this.checkedUserType[j + 1]) {
                        var temp = this.checkedUserType[j]
                        this.checkedUserType[j] = this.checkedUserType[j + 1]
                        this.checkedUserType[j + 1] = temp
                    }
                }
            }
            if (this.checkedUserType.indexOf("2") != -1) {
                this.isShangQuanJingLi = true
            } else {
                this.isShangQuanJingLi = false
            }
            console.log(this.checkedUserType)
        },
        // 返回上一页
        cancel() {
            this.$router.push({ path: "/RenYuanGuanLiList" })
        },
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].reportId)
                        }
                        this.$http
                            .delete(this.$url.deleteReport, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    var message = commonJs.getUrlError(
                                        res.message
                                    )
                                    this.$message.error(message)
                                    // this.$message({
                                    //     type: "error",
                                    //     message: res.data.message
                                    // })
                                }
                            })
                    })
                    .catch((err) => { })
            }
        },
        handleDelete(index) {
            var data1 = [index.reportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.deleteReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                var message = commonJs.getUrlError(res.message)
                                this.$message.error(message)
                                // this.$message({
                                //     type: "error",
                                //     message: res.data.message
                                // })
                            }
                        })
                })
                .catch((err) => { })
        },
        success(response) {
            this.personalForm.pictureId = response.id
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length == 0) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        async handleBeforeRemove(file, fileList) {
            console.log(fileList)
            console.log(file)
            this.personalForm.pictureId = ""
            if (this.num == 2) {
                // this.mm = file.response.id
                var mm = file.url.slice(42)
                this.mm = mm
                var data = {
                    pictureId: this.mm,
                    moduleName: "PetBreed"
                }
                const { data: res } = await this.$http.delete(
                    this.$url.deleteOneFile(this.mm, data.moduleName),
                    {
                        data
                    }
                )
                if (res.msgCode == 1) {
                    this.$message.success("删除成功!")
                    this.isMax = false
                    this.personalForm.pictureId = ""
                } else if (res.msgCode != 1) {
                    this.$message.error(res.message)
                    this.personalForm.pictureId = ""
                    this.isMax = false
                }
            }
        },
        alertInsert() {
            this.dialogFormVisible = true
        },
        edit() {
            this.diseaseIds.push()
            // console.log(this.diseaseIds)
            this.dialogFormVisible = false
        },
        // 商户弹出层
        merchantDialogTrue() {
            this.merchantDialog = true
        },
        // 选择店铺弹出层之前判断是否已选择商户
        shopDialogTrue() {
            if (this.MerchantName == "请选择商户") {
                this.$message.error("请先选择商户")
            } else {
                this.shopDialog = true
                this.getDianPuList()
            }
        },
        // 重置密码
        resetPassword() { },
        // 商户查询
        cgetMerchantList() {
            this.currentPage = 1
            this.getMerchantList()
        },
        /* 获取商户列表 */
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.merchantCurrentPage,
                pageSize: this.merchantPageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList1 = res.dtList
                this.merchantDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    console.log(res.dtList[i])
                    if (res.dtList[i].enabled === "1") {
                        this.operationList1[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList1[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听商户pagesize改变的事件 */
        handleSizeChange1(newSize) {
            this.merchantPageSize = newSize
            this.getMerchantList()
        },
        /* 监听商户页码值改变的事件 */
        handleCurrentChange1(newPage) {
            this.merchantCurrentPage = newPage
            this.getMerchantList()
        },
        //重置商户搜多条件
        cancelMerchantList() {
            this.merchantName = ""
            this.enabled = ""
            this.merchantCurrentPage = 1
            this.getMerchantList()
        },

        handleSelectionChange(val) {
            this.multipleSelection = val
            const multipleSelection1 = val
            this.multipleSelection2 = []
            for (let i = 0; i < multipleSelection1.length; i++) {
                console.log(multipleSelection1[i].name)
                this.multipleSelection2.push(multipleSelection1[i].name)
            }
        },
        // 选择商户赋值
        MerchantDialogDetails(row) {
            console.log(row)
            const that = this
            that.MerchantName = row.merchantName
            setTimeout(function () {
                that.$refs.form.clearValidate(["MerchantName"]) // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
            }, 30)
            that.form.MerchantName = row.merchantName
            that.merchantId = row.merchantId
            that.merchantDialog = false
            this.getDianPuList()
            this.ShopName = ""
        },
        // 查询店铺
        cgetDianPuList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        /* 获取店铺列表 */
        async getDianPuList() {
            const data = {
                areaIds: "", //商圈id
                shopName: this.shopName,
                merchantId: this.merchantId,
                currentPage: this.shopCurrentPage,
                pageSize: this.shopPageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("获取店铺成功")
                this.operationList2 = res.dtList
                this.shopDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList2[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList2[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听店铺pagesize改变的事件 */
        handleSizeChange2(newSize) {
            this.shopPageSize = newSize
            this.getDianPuList()
        },
        /* 监听店铺页码值改变的事件 */
        handleCurrentChange2(newPage) {
            this.shopCurrentPage = newPage
            this.getDianPuList()
        },
        //重置店铺搜索条件
        cancelDianPuList() {
            this.shopName = ""
            this.enabled = ""
            this.shopCurrentPage = 1
            this.getDianPuList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
            const multipleSelection1 = val
            this.multipleSelection2 = []
            for (let i = 0; i < multipleSelection1.length; i++) {
                console.log(multipleSelection1[i].name)
                this.multipleSelection2.push(multipleSelection1[i].name)
            }
        },
        // 选择店铺赋值
        ShopDialogDetails(row) {
            console.log(row)
            const that = this
            that.ShopName = row.shopName
            setTimeout(function () {
                that.$refs.form.clearValidate(["ShopName"]) // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
            }, 30)
            that.form.ShopName = row.shopName
            that.shopId = row.shopId
            that.shopDialog = false
        },
        // 判断是否可用 如果值为0 将其分配给其它经纪人
        keyong(e) {
            if (e == 0) {
                // this.$confirm(
                //     "若设置此人员为不可用，则保存后，此人员的所有房源角色和客源，都将被自动分配给其他经纪人，是否继续？",
                //     "提示",
                //     {
                //         confirmButtonText: "是",
                //         cancelButtonText: "否",
                //         type: "warning"
                //     }
                // )
                //     .then(() => {
                //         this.UserDialog = true
                //         this.getJingJiRenList()
                //     })
                //     .catch((err) => {
                //         this.enabled = "1"
                //     })
            }
        },
        // 查询经纪人
        cgetJingJiRenList() {
            this.currentPage = 1
            this.getJingJiRenList()
        },
        /* 获取经纪人列表 */
        async getJingJiRenList() {
            const data = {}
            if (this.roleType == 6) {
                data.isHomePage = "0"
                data.userName = this.userName
                data.mobile = ""
                data.currentPage = this.userCurrentPage
                data.pageSize = this.userPageSize
            }

            if (this.roleType == 5) {
                data.isHomePage = "0"
                data.userName = this.userName
                data.mobile = ""
                data.currentPage = this.userCurrentPage
                data.pageSize = this.userPageSize
                data.merchantId = this.merchantId
                // const data = {
                //     isHomePage: "0",
                //     userName: this.userName,
                //     mobile: "",
                //     // shopId: "",
                //     merchantId: this.merchantId,
                //     currentPage: this.userCurrentPage,
                //     pageSize: this.userPageSize
                // }
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取通讯录成功")
                this.operationList3 = res.dtList
                this.userDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList3[i].userType1 = ""
                    this.operationList3[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList3[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList3[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList3[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList3[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList3[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList3[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList3[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList3[i].userType1.length > 0) {
                        this.operationList3[i].userType1 = this.operationList3[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList3[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList3[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList3[i].imgtoux = this.$url.girlImg
                        }
                    } else {
                        this.operationList3[i].imgtoux =
                            this.$url.avatarsPicSrc +
                            this.operationList3[i].pictureId
                    }
                }

                console.log(this.operationList3)
            } else {
                this.$message.success(res.message)
            }
        },
        async getUserList() {
            const { data: res } = await this.$http.get(this.$url.GetUserList, {
                params: {
                    userName: this.userName,
                    mobile: this.mobile,
                    userType: this.userType,
                    enabled: this.enabled,
                    currentPage: "1",
                    pageSize: "10"
                }
            })
            if (res.msgCode == 1) {
                this.operationList3 = res.dtList
                console.log("2")
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList3[i].userType1 = ""
                    this.operationList3[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList3[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList3[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList3[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList3[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList3[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList3[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList3[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList3[i].userType1.length > 0) {
                        this.operationList3[i].userType1 = this.operationList3[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList3[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList3[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList3[i].imgtoux = this.$url.girlImg
                        }
                    } else {
                        this.operationList3[i].imgtoux =
                            this.$url.avatarsPicSrc +
                            this.operationList3[i].pictureId
                    }
                }
            }
        },
        /* 监听经纪人pagesize改变的事件 */
        handleSizeChange3(newSize) {
            this.userPageSize = newSize
            this.getMerchantList()
        },
        /* 监听经纪人页码值改变的事件 */
        handleCurrentChange3(newPage) {
            this.userCurrentPage = newPage
            this.getJingJiRenList()
        },
        //重置经纪人搜索条件
        cancelbreedList() {
            this.userName = ""
            this.enabled = ""
            this.currentPage = 1
            this.getJingJiRenList()
        },
        // 获取经纪人多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },

        // 分配给经纪人
        fenpei(row) {
            var data1 = row.userId
            var data = {
                userId: data1
            }
            this.$confirm("是否分配给此经纪人?", "提示", {
                confirmButtonText: "是",
                cancelButtonText: "否",
                type: "warning"
            })
                .then(() => {
                    this.$http
                        .post(this.$url.SetUserDisabled, data)
                        .then((res) => {
                            if (res.data.result === 1) {
                                this.$message.success("人员分配成功!")
                                this.UserDialog = false
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        handleClose(done) {
            this.enabled = "1"
            done()
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}

.el-card {
    margin-top: 15px;
}

.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}</style>
<style lang="less">.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}

.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.editornone .ql-toolbar {
    display: none;
}

.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}</style>