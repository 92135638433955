<template>
    <div class="qianyue">
        <el-card class="intanle">
            <span v-if="state == '2'" class="h1">签约(合同)修改</span>
            <span v-if="state == '0'" class="h1">签约(合同)新增</span>
            <el-button style="float: right" size="mini" @click="goBack" class="el-button" type="primary">返回</el-button>
            <el-button v-if="contractStatus == '10'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="draft()">保存草稿</el-button>
            <!-- <el-button v-if="contractStatus=='10'" style="float: right" class="el-button1" type="warning" size="mini" @click="apply()">提交申请</el-button> -->
            <el-button v-if="contractStatus == '10' && roleType != 1" style="float: right" class="el-button1" type="warning"
                size="mini" @click="applyPass()">审核通过</el-button>
            <el-button v-if="contractStatus == '20'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="save()">保存合同</el-button>
            <el-button v-if="contractStatus == '30'" style="float: right" class="el-button1" size="mini" type="warning"
                @click="Submit()">电子签章</el-button>
            <el-button v-if="roleType == 2 || roleType == 5 || roleType == 6" style="float: right" class="el-button1" size="mini"
                type="warning" @click="onSave(2)">下载合同</el-button>
        </el-card>
        <el-backtop></el-backtop>
        <el-card class="body">
            <div class="header">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <h3 class="text_align_r">
                <span>合同编号：</span><input :disabled="flag" class="inputW_150 input_borderBottom_ccc"
                    v-model="form.contractNo" />
            </h3>

            <div class="quote_info clearfix">
                <h2 class="h2_title margin_top_50">停车位/车库租赁合同</h2>
                <h3 class="font_weight500 d-flex just-bew">
                    甲方（出租方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.jiaName" />
                    证件类型及号码：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.jiaIdCardNo" />
                    联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaMobile" />



                </h3>
                <h3 class="font_weight500 d-flex just-bew">

                    代理人：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaDLName" />
                    证件类型及号码：<input :disabled="flag" class="inputW_250 input_borderBottom_000"
                        v-model="form.jiaDLIdCardNo" />
                    联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaDLMobile" />
                </h3>
                <h3 class="font_weight500 d-flex just-bew">
                    乙方（承租方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yiName" />
                    证件类型及号码：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.yiIdCardNo" />
                    联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiMobile" />
                </h3>
                <h3 class="font_weight500 d-flex just-bew">
                    代理人：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiDLName" />
                    证件类型及号码：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.yiDLIdCardNo" />
                    联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiDLMobile" />
                </h3>

                <h3 class="font_weight500">
                    丙方（居间方）：<input :disabled="flag" class="inputW_23 input_borderBottom_000" v-model="form.companyName" />
                    联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.companyMobile" />
                </h3>


                <h3 class="font_weight500">
                    <span style="margin-left:40px">经丙方居间撮合，甲方作为所有权人，自愿将本合同第一条所列停车</span> <input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    出租给乙方使用，乙方在完全了解该车<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    详细情况的前提下，自愿承租该车<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.parkingLot" />,
                    其用途为停车专用。具体事宜约定如下，共同遵守。
                </h3>
                <!-- 第一条 -->
                <h3>第一条 停车位详情</h3>
                <h3 class="font_weight500"> 小区名称：<input :disabled="flag" class="inputW_40 input_borderBottom_000"
                        v-model="form.fir_xiaoquName" />
                    建筑面积：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.fir_area" />
                    停放车辆型号及牌照：<input :disabled="flag" class="inputW_250 input_borderBottom_000"
                        v-model="form.fir_carName" />
                    停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    编号：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.parkingLotNo" />
                </h3>

                <!-- 第二条 -->
                <h3>第二条 租赁期限</h3>
                <h3 class="font_weight500">
                    1、租赁期限：自<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.sec_zuLinFromYear" :maxlength="4" />年
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_zuLinFromMonth"
                        :maxlength="2" @blur="rulesDate(7, 'sec_zuLinFromMonth')" />月
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_zuLinFromDay"
                        :maxlength="2" @blur="rulesDate(8, 'sec_zuLinFromDay')" />日起，
                    至<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.sec_zuLinToYear"
                        :maxlength="4" />年
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_zuLinToMonth"
                        :maxlength="2" @blur="rulesDate(9, 'sec_zuLinToMonth')" />月
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_zuLinToDay"
                        :maxlength="2" @blur="rulesDate(10, 'sec_zuLinToDay')" />日
                    共计<input class="inputW_80px input_borderBottom_000" v-model="form.sec_monthNumber" />个月，
                </h3>
                <h3 class="font_weight500">
                    2、甲方应在<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.sec_jiaofuYear1"
                        :maxlength="4" />年
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_jiaofuMonth1"
                        :maxlength="2" @blur="rulesDate(7, 'sec_jiaofuMonth1')" />月
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_jiaofuDay1"
                        :maxlength="2" @blur="rulesDate(8, 'sec_jiaofuDay1')" />
                    日前将上述停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    交付给乙方，租贷期满，乙方在同等条件下享有优先承租权，如果到期乙方不再续租，甲方可另行出租。

                </h3>

                <!-- 第三条 -->
                <h3>第三条 租金、押金及其支付方式</h3>
                <h3 class="font_weight500">
                    1、租金按
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_zujinType"
                            label="1">月度</el-radio>
                    </span>
                    <span style="padding-left:10px">
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_zujinType"
                            label="2">季度</el-radio>
                    </span>
                    <span style="padding-left:10px">
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_zujinType"
                            label="3">半年度</el-radio>
                    </span>
                    <span style="padding-left:10px">
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_zujinType"
                            label="4">年度</el-radio>
                    </span>
                    支付。乙方应在租金到期前<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.thi_tiqianDay" />
                    天向甲方支付下期租金，租金逾期缴纳到<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.thi_yuqiDay" />
                    天，甲方可单方面解除本合同，要求乙方停止对该车<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    的使用，并可另行出租该车<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    而无需征得乙方任何形式的同意，同时，乙方应按天计算缴纳当月实际使用车<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    的停车费用，已经缴纳的押金不予退还。
                </h3>
                <h3 class="font_weight500">
                    2、乙方应于<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.thi_tiaojian" />
                    （日期或条件）向甲方支付第一笔租金（即<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.thi_zujinMonth" />
                    个月租金），计人民币（小写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.thi_zujin_xiaoxie" @change="getDX(form.thi_zujin_xiaoxie, 1)" />
                    元（大写）<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.thi_zujin_daxie" />
                    元整。
                </h3>
                <h3 class="font_weight500">
                    3、租金的支付方式为：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_payType"
                            label="1">现金</el-radio>
                    </span>
                    <span style="padding-left:10px">
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.thi_payType"
                            label="2">银行转账</el-radio>
                    </span>
                    如为银行转账方式，甲方银行账户为：开户人：<input :disabled="flag" class="inputW_150 input_borderBottom_000"
                        v-model="form.thi_kaihuName" />
                    ；账号： <input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.thi_zhangHao" />
                    ；开户行：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.thi_kaiHuHang" />
                </h3>
                <!-- 第四条 -->
                <h3>第四条 其他费用</h3>
                <h3 class="font_weight500">
                    （1）在签订本合同时，由<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.fou_zhiFuFang" />
                    方应向丙方支付中介服务费人民币（小写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.fou_zhongjieFei_xiaoxie" @change="getDX(form.fou_zhongjieFei_xiaoxie, 2)" />
                    元（大写）<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.fou_zhongjieFei_daxie" />
                    元整。
                </h3>
                <h3 class="font_weight500">
                    （2）因车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    租赁产生的相关税费，由<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.fou_chengDanFang" />
                    方全额承担。
                </h3>
                <h3 class="font_weight500">
                    （3）租赁期内的车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    物业管理等其他相关费用由<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.fou_jiaoFeiFang" />
                    方按照车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    物业管理方或费用收缴方规定的时间和金额，及时缴纳。
                </h3>

                <!-- 第五条 -->
                <h3>第五条 甲、乙、丙三方的权利和义务</h3>
                <h3 class="font_weight500">
                    ■甲方：
                </h3>
                <h3 class="font_weight500">1、应按照本合同的约定向乙方提供达到车辆停放要求的空置停车<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    。</h3>
                <h3 class="font_weight500">2、在乙方未履行租金，物业管理费或其他相关费用缴付义务时，有权进行催收。</h3>
                <h3 class="font_weight500">3、甲方对乙方车辆及车内财产无任何保管、保卫义务，对乙方车辆及车内财产的损毁灭失 不承担任何民事责任（包括赔偿）。</h3>
                <h3 class="font_weight500">4、如出现第三方侵权的，例如其他车辆或人员碰撞、擦挂乙方车辆，乙方应追究第三方责任，不由甲方承担责任。</h3>
                <h3 class="font_weight500">5、在合同期限内，甲方如欲转让该车<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    ，应提前告知乙方，乙方享有优先购买权。</h3>
                <h3 class="font_weight500">6、甲方应确保上述物业权属真实、清晰，无任何法律纠纷和限制租赁交易的情况，否则由甲方承担相应的违约责任和法律责任。</h3>
                <h3 class="font_weight500">
                    7、甲方委托他人签订本合同的，受托人需出具甲方的委托书并且确认已受到甲方的真实委托，如因委托内容出现争议导致本合同不能正常履行甲方及委托人应当承担因此造成的任何责任。</h3>

                <h3 class="font_weight500">
                    ■乙方：
                </h3>
                <h3 class="font_weight500">1、应合理使用该停车<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    ，并遵守物业管理方的相关管理规定。</h3>
                <h3 class="font_weight500">2、不得将停车<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    转租或转借给他人。</h3>
                <h3 class="font_weight500">3、停放的机动车辆发生变更时，应及时告知甲方，并到物业管理方报备。</h3>
                <h3 class="font_weight500">4、不得在
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType"
                            label="1">地上</el-radio>
                    </span>
                    <span style="padding-left:10px">
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType"
                            label="2">地下</el-radio>
                    </span>
                    车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    上清洗车辆，非紧急情况，不得在停车<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.parkingLot" />
                    上拆卸修理车辆。
                </h3>
                <h3 class="font_weight500">5、在承租期内，如果因自身行为，造成停车<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    或相邻停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    、相邻停靠车辆的损害的，应承担全部法律责任。</h3>

                <h3 class="font_weight500">
                    ■丙方：
                </h3>
                <h3 class="font_weight500">1、利用自身专业知识，为甲、乙双方提供物业租赁的专业意见和资讯。</h3>
                <h3 class="font_weight500">2、事前提供书面的物业租赁风险告知书，提醒交易双方规避风险。</h3>
                <h3 class="font_weight500">居间协调，促成双方签订本合同的同时，有权按照合同约定及时足额收取中介服务费。</h3>


                <!-- 第六条 -->
                <h3>3、第六条 其他</h3>
                <h3 class="font_weight500">1、甲方无正当理由，提前收回该停车<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    的。甲方应按年租金的20％向乙方支付违约金，乙方未经甲方同意中途擅自退租的，已经缴纳未使用的租金及押金不予退还。</h3>
                <h3 class="font_weight500">2、在履行本合同过程中发生争议的，甲、乙双方应本着诚实信用、公开平等的原则协商解决，协商不成的，任何一方可向停车
                    <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
                    所在地人民法院提起诉讼。
                </h3>
                <h3 class="font_weight500">3、本合同一式三份，具有同等法律效力，三方各执一份，自各方签字（盖章）之日起生效。（以下无正文）</h3>

                <div style="margin:100px 0">
                    <h3 class="font_weight500" style="display:flex;">
                        <span style="flex:2">出租人（签章）：</span>
                        <span style="flex:1">承租人（签章）：</span>
                    </h3>
                    <h3 class="font_weight500" style="display:flex;">
                        <span style="flex:2">【代理人】（签章）：</span>
                        <span style="flex:1">【代理人】（签章）：</span>
                    </h3>
                    <h3 class="font_weight500" style="display:flex;">
                        <span style="flex:2">日期：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                                v-model="form.qianShu_Year" :maxlength="4" />年<input :disabled="flag"
                                class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month" :maxlength="2"
                                @blur="rulesDate(7, 'qianShu_Month')" />月<input :disabled="flag"
                                class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Day" :maxlength="2"
                                @blur="rulesDate(8, 'qianShu_Day')" />日</span>
                        <span style="flex:1">日期：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                                v-model="form.qianShu_Year" :maxlength="4" />年<input :disabled="flag"
                                class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month" :maxlength="2"
                                @blur="rulesDate(7, 'qianShu_Month')" />月<input :disabled="flag"
                                class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Day" :maxlength="2"
                                @blur="rulesDate(8, 'qianShu_Day')" />日</span>
                    </h3>
                    <h3 class="font_weight500" style="display:flex;margin-top:50px">
                        <span>居间人：<input :disabled="flag" class="inputW_250 input_borderBottom_000"
                                v-model="form.companyName" /></span>
                    </h3>
                    <h3 class="font_weight500" style="display:flex;">
                        <span style="flex:2">签约经纪人：<input :disabled="flag" class="inputW_150 input_borderBottom_000"
                                v-model="form.qianshu_jjrName" /></span>
                        <span style="flex:1">联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000"
                                v-model="form.qianshu_jjrMobile" /></span>
                    </h3>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import JsZipUtils from "jszip-utils"
import FileSaver from "file-saver"
import ZuLinTemplate from "./CheWeiZuLinTemplate.js"
import QianYueJs from "../QianYue.js"
import url from "../../../config/url.js"
import { Loading } from "element-ui"
import axios from "axios"
import { Message } from "element-ui"
export default {
    inject: ["reload"],
    data() {
        return {
            loading: false,
            disabled: true,
            flag: false,
            userId: localStorage.getItem("userId"),
            // contractId: this.$route.query.contractId,
            form: ZuLinTemplate.form,
            formCheck: ZuLinTemplate.formCheck,
            MyHeader: {
                currentRole: "",
                accessToken: "",
                fp: ""
            }
        }
    },
    props: {
        formData: {
            type: Object,
            default: {}
        },
        form1: {
            type: Object,
            default: {}
        },
        contractId: {
            type: String,
            default: ""
        },
        state: {
            type: String,
            default: ""
        },
        contractStatus: {
            type: String,
            default: ""
        }
    },
    created() {
        if (this.contractStatus >= "20") {
            this.flag = true
        }
        this.MyHeader.accessToken = localStorage.getItem("accessToken") //获取accessToken
        this.MyHeader.fp = localStorage.getItem("fp") //浏览器指纹fp
        this.roleType = window.localStorage.getItem("roleType")
        console.log(this.roleType)
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        this.form.contractNo = this.formData.contractNo;//合同编号
        this.form.companyName = this.formData.companyName//公司名称
        var d = new Date()
        this.form.qianShu_Year = d.getFullYear();//签署日期 年
        this.form.qianShu_Month = d.getMonth() + 1;//签署日期 月
        this.form.qianShu_Day = d.getDate();//签署日期 日
        this.form.jiaName = this.formData.sellerName//甲方姓名
        this.form.jiaIdCardNo = this.formData.sellerIdCardNo//甲方身份证号
        this.form.jiaMobile = this.formData.sellerMobile//甲方手机号
        this.form.yiName = this.formData.buyerName//乙方姓名
        this.form.yiIdCardNo = this.formData.buyerIdCardNo//乙方身份证号
        this.form.yiMobile = this.formData.buyerMobile//乙方手机号
        this.form.fir_xiaoquName = this.formData.contractHouseAddress//小区名称

        if (JSON.stringify(this.form1) != "{}") {
            this.form = this.form1
            this.formCheck.thi_zujinType = this.form.thi_zujinType//租金方式
            this.formCheck.thi_payType = this.form.thi_payType//支付方式
            this.formCheck.parkingLotType = this.form.parkingLotType//停车位类型
        }
    },
    watch: {
        "formCheck.jf_YTGFWDJBRadio"(val, oldVal) {
            console.log(val)
        }
    },
    methods: {
        onSetp(type) {
            this.$emit("onSetp", type)
        },
        goBack() {
            this.$router.go(-1)
        },
        concatData(a, b, checkedParams) {
            let data = []
            const arr = [...a, ...b]
            data = arr.filter((item) => {
                return !(a.includes(item) && b.includes(item))
            })
            data.forEach((element) => {
                checkedParams[element] = "□"
            })
            b.forEach((element) => {
                checkedParams[element] = "√"
            })
            return checkedParams
        },
        async onSave(type) {
            let that = this
            that.loadingFunction()
            JsZipUtils.getBinaryContent(
                "停车位租赁合同.docx",
                function (error, content) {
                    if (error) {
                        throw error
                    }
                    const zip = new PizZip(content)

                    const doc = new Docxtemplater(zip, {
                        paragraphLoop: true,
                        linebreaks: true
                    })

                    //租金付款方式
                    if (that.formCheck.thi_zujinType == "1") {
                        that.form.zjFuKuanFangShi_Month = "√"
                    } else if (that.formCheck.thi_zujinType == "2") {
                        that.form.zjFuKuanFangShi_JiFu = "√"
                    } else if (that.formCheck.thi_zujinType == "3") {
                        that.form.zjFuKuanFangShi_BanNianFu = "√"
                    } else if (that.formCheck.thi_zujinType == "4") {
                        that.form.zjFuKuanFangShi_Year = "√"
                    } else {
                        that.form.zjFuKuanFangShi_Month = "□"
                        that.form.zjFuKuanFangShi_JiFu = "□"
                        that.form.zjFuKuanFangShi_BanNianFu = "□"
                        that.form.zjFuKuanFangShi_Year = "□"
                    }
                    //租金支付方式
                    if (that.formCheck.thi_payType == "1") {
                        that.form.zjFuKuanFangShi_YinHangZhuangZhang = "√"
                    } else if (that.formCheck.thi_payType == "2") {
                        that.form.zjFuKuanFangShi_XianJin = "√"
                    } else {
                        that.form.zjFuKuanFangShi_YinHangZhuangZhang = "□"
                        that.form.zjFuKuanFangShi_XianJin = "□"
                    }
                    //停车位类型
                    if (that.formCheck.parkingLotType == "1") {
                        that.form.diShangCheWei = "√"
                    } else if (that.formCheck.parkingLotType == "2") {
                        that.form.diXiaCheWei = "√"
                    } else {
                        that.form.diShangCheWei = "□"
                        that.form.diXiaCheWei = "□"
                    }


                    doc.setData({
                        ...that.form,
                    })

                    try {
                        doc.render()
                    } catch (error) {
                        function replaceErrors(key, value) {
                            if (value instanceof Error) {
                                return Object.getOwnPropertyNames(value).reduce(
                                    function (error, key) {
                                        error[key] = value[key]
                                        return error
                                    },
                                    {}
                                )
                            }
                            return value
                        }
                        console.log(
                            JSON.stringify({ error: error }, replaceErrors)
                        )

                        if (
                            error.properties &&
                            error.properties.errors instanceof Array
                        ) {
                            const errorMessages = error.properties.errors
                                .map(function (error) {
                                    return error.properties.explanation
                                })
                                .join("\n")
                        }
                        throw error
                    }
                    const blob = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    })
                    if (type == 2) {
                        that.loading.close()
                        // that.save()
                        saveAs(blob, "停车位租赁合同.docx")
                    } else if (type == 1) {
                        that.httpRequest(blob, that.contractId, type)
                        // that.save()
                        // that.draft()
                        that.loading.close()
                    }
                }
            )
        },
        httpRequest(blob, contractId, type) {
            let that = this,
                params = {},
                url1 = url.TransferFileToESign
            const loading = Loading.service({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            let a = new FileReader()
            a.onload = function (e) {
                params = {
                    base64File: e.target.result.split("base64,")[1],
                    contractId: contractId
                }
                axios
                    .post(url1, params, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    .then((res) => {
                        if (res.data.msgCode == 1) {
                            setTimeout(function () {
                                Message.success({
                                    message: "签约基本信息保存成功！"
                                })
                                loading.close()
                                that.reload()
                                // that.Submit();
                                // return true
                            }, 3000)
                        } else {
                            Message.error({
                                message: res.data.message,
                                duration: 5000
                            })
                            loading.close()
                            // return true
                        }
                    })
                    .catch((res) => {
                        Message.error("请求错误")
                    })
            }
            a.readAsDataURL(blob)
        },
        applyPass() {
            let that = this
            that.loading = true
            const data = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.ConfirmComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
        },
        draft() {
            let that = this,
                param = {}
            that.form.thi_zujinType = that.formCheck.thi_zujinType
            that.form.thi_payType = that.formCheck.thi_payType
            that.form.parkingLotType = that.formCheck.parkingLotType

            param = {
                ContractId: that.contractId,
                ContractInfo: JSON.stringify(that.form)
            }
            that.$http
                .put(that.$url.UpdateContractDraft, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("签约基本信息保存成功！")
                        that.loading.close()
                    } else {
                        that.loading.close()
                        that.$message.error(res.data.message)
                    }
                })
        },
        save() {
            let that = this,
                param = {}
            that.disabled = true
            if (that.form.thi_zujin_xiaoxie == "x") {
                return that.$message.error("请填写租金金额")
            }
            if (that.form.fou_zhongjieFei_xiaoxie == "x") {
                return that.$message.error("请填写服务费金额")
            }
            param = {
                dingJinAmount: 0,
                jiaFangFuWuFeiAmount: Number(that.form.fou_zhongjieFei_xiaoxie),
                yiFangFuWuFeiAmount: 0,
                baoZhangFuWuFeiAmount: 0,
                isDingZhuanYong: "0",
                userId: that.formData.userId,
                contractId: that.contractId,
                shopId: that.formData.shopId,
                merchantId: that.formData.merchantId,
                houseId: that.formData.houseId,
                customerId: that.formData.customerId,
                tradeType: that.formData.tradeType,
                type: that.formData.type,
                contractHouseAddress: that.formData.contractHouseAddress,
                contractPrice: Number(that.form.thi_zujin_xiaoxie)
                // param.signDate = that.signDate

                // fileId1: that.form.fileId1.split(".")[0]
                //contractInfo: JSON.stringify(that.form)
            }
            that.$http
                .put(that.$url.UpdateContract, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    that.disabled = false
                    if (res.data.msgCode == 1) {
                        that.onSave(1)
                        that.loading.close()
                        if (!that.contractId) {
                            this.$router.go(-1)
                        }
                    } else {
                        that.loading.close()
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    that.disabled = false
                    that.loading.close()
                    this.$message.error("请求错误")
                })
        },
        // 邮箱校验
        onlyRules() {
            if (
                this.form.chuZu_Email != "x" &&
                this.form.chuZu_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuZu_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuZu_Email = ""
            }
            if (
                this.form.chuZuDL_Email != "x" &&
                this.form.chuZuDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuZuDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuZuDL_Email = ""
            }
            if (
                this.form.chengZu_Email != "x" &&
                this.form.chengZu_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chengZu_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chengZu_Email = ""
            }
            if (
                this.form.chengZuDL_Email != "x" &&
                this.form.chengZuDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chengZuDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chengZuDL_Email = ""
            }
        },
        rulesDate(num, param) {
            if (this.form[param] !== "") {
                this.form[param] = parseInt(this.form[param])
                if (
                    num % 2 == 1 &&
                    (this.form[param] > 12 || this.form[param] < 1)
                ) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的月份")
                } else if (this.form[param] > 31 || this.form[param] < 1) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的日期")
                }
            }
        },
        rulesIdNo() {
            // 证件号码
            if (
                this.form.sellerIdCardNo != "x" &&
                !this.$commonJs.shenfenZheng(this.form.sellerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.sellerIdCardNo = ""
                return
            }
            if (
                this.form.chuZuDL_ZhengJianNum != "x" &&
                !this.$commonJs.shenfenZheng(this.form.chuZuDL_ZhengJianNum)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.chuZuDL_ZhengJianNum = ""
                return
            }
            if (
                this.form.buyerIdCardNo != "x" &&
                !this.$commonJs.shenfenZheng(this.form.buyerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.buyerIdCardNo = ""
                return
            }
            if (
                this.form.chengZuDL_ZhengJianNum != "x" &&
                !this.$commonJs.shenfenZheng(this.form.chengZuDL_ZhengJianNum)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.chengZuDL_ZhengJianNum = ""
                return
            }
        },
        rulesMobile(val) {
            if (
                val === 1 &&
                this.form.sellerMobile != "x" &&
                !this.$commonJs.numberPhone(this.form.sellerMobile)
            ) {
                this.form.sellerMobile = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 2 &&
                this.form.chuZuDL_Phone != "x" &&
                !this.$commonJs.numberPhone(this.form.chuZuDL_Phone)
            ) {
                this.form.chuZuDL_Phone = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 3 &&
                this.form.buyerMobile != "x" &&
                !this.$commonJs.numberPhone(this.form.buyerMobile)
            ) {
                this.form.buyerMobile = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 4 &&
                this.form.chengZuDL_Phone != "x" &&
                !this.$commonJs.numberPhone(this.form.chengZuDL_Phone)
            ) {
                this.form.chengZuDL_Phone = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 5 &&
                this.form.LXDH != "x" &&
                !this.$commonJs.numberPhone(this.form.LXDH)
            ) {
                this.form.LXDH = ""
                this.$message.error("请输入正确的手机号码")
            }
        },
        moneyRule(param) {
            if (
                this.form[param] != "" &&
                !this.$commonJs.moneyFormat(this.form[param] * 1)
            ) {
                this.form[param] = "x"
                this.$message.error("请输入正确金额")
            }
            if (
                param == "YueZu_XiaoXieYuan" &&
                this.form.YueZu_XiaoXieYuan != "x"
            ) {
                if (this.form.YueZu_XiaoXieYuan != "") {
                    this.form.YueZu_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.YueZu_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.YueZu_DaXieYuan = "x"
                    this.form.YueZu_XiaoXieYuan = "x"
                }
            }
            if (param == "zj_Money1" && this.form.zj_Money1 != "x") {
                if (this.form.zj_Money1 != "") {
                    this.form.zj_Money1 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money1 = "x"
                }
            }
            if (param == "zj_Money2" && this.form.zj_Money2 != "x") {
                if (this.form.zj_Money2 != "") {
                    this.form.zj_Money2 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money2 = "x"
                }
            }
            if (param == "zj_Money3" && this.form.zj_Money3 != "x") {
                if (this.form.zj_Money3 != "") {
                    this.form.zj_Money3 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money3 = "x"
                }
            }
            if (param == "zj_Money4" && this.form.zj_Money4 != "x") {
                if (this.form.zj_Money4 != "") {
                    this.form.zj_Money4 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money4 = "x"
                }
            }
            if (
                param == "BaoZhengJin_XiaoXieYuan" &&
                this.form.BaoZhengJin_XiaoXieYuan != "x"
            ) {
                if (this.form.BaoZhengJin_XiaoXieYuan != "") {
                    this.form.BaoZhengJin_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.BaoZhengJin_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.BaoZhengJin_DaXieYuan = "x"
                    this.form.BaoZhengJin_XiaoXieYuan = "x"
                }
            }
            if (
                param == "jf_JuJianFangXiaoXieYuan" &&
                this.form.jf_JuJianFangXiaoXieYuan != "x"
            ) {
                if (this.form.jf_JuJianFangXiaoXieYuan != "") {
                    this.form.jf_JuJianFangDaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.jf_JuJianFangXiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.jf_JuJianFangDaXieYuan = "x"
                    this.form.jf_JuJianFangXiaoXieYuan = "x"
                }
            }
            if (
                param == "yf_JuJianFangXiaoXieYuan" &&
                this.form.yf_JuJianFangXiaoXieYuan != "x"
            ) {
                if (this.form.yf_JuJianFangXiaoXieYuan != "") {
                    this.form.yf_JuJianFangDaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.yf_JuJianFangXiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.yf_JuJianFangDaXieYuan = "x"
                    this.form.yf_JuJianFangXiaoXieYuan = "x"
                }
            }
        },
        loadingFunction() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
        },
        beforeUpload(file) {
            this.loadingFunction()
        },
        Submit(type) {
            let that = this
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            that.disabled = true
            let param = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.FlowOneStepESign, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((result) => {
                    that.disabled = false
                    that.loading.close()
                    if (result.data.msgCode == 1) {
                        that.$message.success("合同提交成功！")
                        that.$router.go(-1)
                    } else {
                        that.$message.error(result.data.message)
                    }
                })
                .catch((result) => {
                    that.loading.close()
                    that.disabled = false
                    that.$message.error("请求错误")
                })
        },

        smallToBig(money, num) {
            //  将数字金额转换为大写金额
            var cnNums = new Array(
                "零",
                "壹",
                "贰",
                "叁",
                "肆",
                "伍",
                "陆",
                "柒",
                "捌",
                "玖"
            ); //汉字的数字
            var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
            var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
            var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
            var cnInteger = "整"; //整数金额时后面跟的字符
            var cnIntLast = "元"; //整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum; //金额整数部分
            var decimalNum; //金额小数部分
            //输出的中文金额字符串
            var chineseStr = "";
            var parts; //分离金额后用的数组，预定义
            if (money == "") {
                return "";
            }

            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return "超出最大处理数字";
            }
            if (money == 0) {
                chineseStr = cnNums[0]
                if (num == 1) {
                    this.form.thi_zujin_daxie = chineseStr;
                } else if (num == 2) {
                    this.form.fou_zhongjieFei_daxie = chineseStr;
                }
                return chineseStr;
            }

            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0, money.length - 2);
            decimalNum = money.substr(money.length - 2);

            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == "0") {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                // chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != "") {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != "0") {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == "") {
                chineseStr += cnNums[0]
            } else if (decimalNum == "" || /^0*$/.test(decimalNum)) {
                // chineseStr += cnInteger;
            }
            if (num == 1) {
                this.form.thi_zujin_daxie = chineseStr;
            } else if (num == 2) {
                this.form.fou_zhongjieFei_daxie = chineseStr;
            }

        },
        getDX(number, num) {
            this.smallToBig(number, num);
        }
    }
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    padding: 1% 4%;
    font-size: 12px;
    font-family: "宋体";
    list-style: none;
    box-sizing: border-box;

    .font_weight {
        font-weight: 600;
    }

    .text_align_l {
        text-align: left;
    }

    .text_align_r {
        text-align: right;
    }

    .border_bottom {
        border-bottom: 1px solid #000;
    }

    .header_title {
        color: #b3b3b3;
        font-weight: 400;
        line-height: 25px;
        border-bottom: 1px solid #ccc;
    }

    input {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        font-size: 16px;
        color: red;
        text-align: center;
        height: 20px !important;
        vertical-align: sub;
    }

    .input_borderBottom_ccc {
        border-bottom: 1px solid #ccc;
    }

    .input_borderBottom_000 {
        border-bottom: 1px solid #000;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
    }

    /deep/ .el-radio__input+.el-radio__label,
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #000 !important;
    }

    /deep/ .el-checkbox__inner {
        border: 1px solid #000 !important;
    }

    /deep/ .el-input__inner {
        border: 0 !important;
        font-size: 16px;
        color: red;
        text-align: center;
    }

    .text-indent {
        text-indent: 35px;
    }

    .border0 {
        border: 0;
    }

    .border_000 {
        border: 1px solid #000;
    }

    .inputW_50px {
        width: 50px;
    }

    .inputW_80px {
        width: 80px;
    }

    .inputW_150 {
        width: 150px;
    }

    .inputW_250 {
        width: 250px;
    }

    .inputW_15 {
        width: 15%;
    }

    .inputW_30 {
        width: 30%;
    }

    .inputW_23 {
        width: 23%;
    }

    .inputW_40 {
        width: 40%;
    }

    .margin_top_150 {
        margin-top: 150px !important;
    }

    .margin_top_100 {
        margin-top: 100px !important;
    }

    .margin_top_50 {
        margin-top: 50px !important;
    }

    .margin_top_20 {
        margin-top: 20px !important;
    }

    .margin_top_10 {
        margin-top: 10px !important;
    }

    .font_weight500 {
        font-weight: 500;
    }

    .font_weight600 {
        font-weight: 600;
    }

    .float_left {
        float: left;
    }

    .float_right {
        float: right;
    }

    // 清除浮动
    .clearfix:after {
        content: "";
        height: 0;
        line-height: 0;
        display: block;
        clear: both;
        visibility: hidden;
    }

    .clearfix {
        zoom: 1;
    }

    .el-card {
        line-height: 25px !important;
    }

    p {
        margin: 0;
    }

    .quote_info {
        font-size: 15px;
        color: #000;
        width: 70%;
        margin: 0 auto;

        .h3_title {
            float: left;
            width: 100%;
            font-size: 30px;
            letter-spacing: 1px;
            line-height: 100px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }

        .h2_title {
            // float: left;
            width: 100%;
            font-size: 25px;
            letter-spacing: 1px;
            line-height: 50px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }
    }

    ul,
    li {
        list-style: none;
    }

    li {
        border: 1px solid #000;
        line-height: 35px;
    }

    .width_10 {
        width: 10%;
        text-align: center;
        font-weight: 600;
    }

    .width_90 {
        width: 90%;
        text-align: center;
        font-weight: 600;
    }

    .width_98 {
        width: 98%;
    }

    .width_full {
        width: 100%;
    }

    .display_flex {
        display: flex;
    }

    .d-flex {
        display: flex;
    }

    .just-bew {
        justify-content: space-between;
    }
}

.nav1 {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 40px;
    text-align: center;
    margin: 10px 0 !important;
}

.nav2 {
    margin-left: 0 !important;
}

/deep/ .el-upload-list {
    display: contents;

    .el-upload-list__item-name {
        width: 140px;
    }
}

.header {
    height: 70px;
    border-bottom: 1px solid #ccc;

    img {
        display: block;
        float: left;
        width: 90px;
    }

    h3 {
        float: right;
        line-height: 90px;
        color: #b3b3b3;
        font-weight: 400;
    }
}

.el-button {
    margin: 5px;
}

/deep/.el-radio__inner {
    border: 1px solid #df0202;
}

/deep/.el-radio__inner:hover {
    border: 1px solid #0262df;
}

/deep/input[type="checkbox"] {
    position: relative;
    border: 1px solid #df0202 !important;
    border-radius: 3px;
    width: 13px;
    height: 13px !important;
    top: -1px;
    -webkit-appearance: none;
}

/deep/input[type="checkbox"]:disabled {
    cursor: not-allowed;
    border-color: rgba(153, 153, 153, 0.3) !important;
    background-color: rgb(241, 241, 241);
}

/deep/input[type="checkbox"]:hover {
    border: 1px solid #0262df !important;
}

/deep/input[type="checkbox"]:hover:disabled {
    border: 1px solid #dbdbdb !important;
}

/deep/input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 13px;
    margin-top: 0px !important;
    font-weight: bold;
    background-color: #ee6716;
    top: -1px;
    position: absolute;
    left: -1px;
    width: 13px;
    height: 13px !important;
}

/deep/ input:disabled {
    background-color: #fff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #ee6716;
}</style>