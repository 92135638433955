<template>
  <div>
    <el-card style="margin-top: 6px !important">
      <el-tabs
        v-model="tabPane"
        @tab-click="handleClickTab"
        class="qiugou-tabs"
        style="position: relative; top: 0px; background-color: #fff"
      >
        <el-tab-pane name="待分配客源" label="待分配客源">
          <el-card style="margin: 0 !important; border: none"> </el-card>
        </el-tab-pane>
        <el-tab-pane name="已分配客源" label="已分配客源">
          <el-card style="margin: 0 !important; border: none"> </el-card>
        </el-tab-pane>
      </el-tabs>
      <el-row v-if="tabPane == '待分配客源'" style="margin-top: 10px">
        <el-col :span="5">
          <span style="font-size: 14px">客户姓名：</span>
          <el-input
            placeholder="请输入客户姓名"
            style="width: 200px"
            maxlength="12"
            v-model="customerName"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <span style="font-size: 14px">手机号：</span>
          <el-input
            placeholder="请输入客户手机号"
            style="width: 200px"
            maxlength="12"
            v-model="tel"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <span style="font-size: 14px; margin-left: 20px">维护人姓名：</span>
          <el-input
            placeholder="请输入维护人姓名"
            style="width: 200px"
            maxlength="12"
            v-model="userName"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <span style="font-size: 14px; margin-left: 20px">客源有效性：</span>
          <el-select v-model="enabled" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <span style="float: right">
            <el-button
              size="mini"
              style="margin-left: 20px"
              type="primary"
              @click="chaxun"
              >查询</el-button
            >
            <el-button size="mini" @click="reset" type="warning"
              >重置</el-button
            >
          </span>
        </el-col>
      </el-row>

      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="CustomerList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px',
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column
          label="姓名"
          width="170px;"
          prop="customerName"
        ></el-table-column>
        <el-table-column label="手机号" prop="tel1"></el-table-column>
        <el-table-column
          label="客户标签"
          prop="commonMasterName"
        ></el-table-column>
        <el-table-column
          label="有效性"
          v-if="tabPane == '待分配客源'"
          prop="enabled"
        ></el-table-column>
        <el-table-column
          label="客户等级"
          prop="customerScore"
        ></el-table-column>
        <el-table-column label="维护人" prop="userName" v-if="tabPane == '待分配客源'"></el-table-column>
        <el-table-column
          label="已分配角色"
          prop="remark"
          v-if="tabPane != '待分配客源'"
          :key="1"
        ></el-table-column>
        <el-table-column
          label="被分配经纪人"
          prop="userName"
          v-if="tabPane != '待分配客源'"
          :key="2"
        ></el-table-column>
        <el-table-column
          label="分配时间"
          prop="createTime"
          v-if="tabPane != '待分配客源'"
          :key="3"
          width="200"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="165px;"
          v-if="tabPane == '待分配客源'"
        >
          <template class="right" slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              class="el-icon-edit caozuo"
              style="color: #1890ff"
              @click="OpenFenPei(scope.row)"
              >分配</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
          <img
            style="width: 200px; margin-top: 50px"
            src="../../../public/img/noList.png"
          />
          <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
        </div>
      </el-table>

      <el-dialog
        title="待分配角色"
        :visible.sync="FenPeiVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="分配角色:"
            prop="radio"
            label-width="25%"
            style="height: 50px"
          >
            <div>
              <el-checkbox-group v-model="ruleForm.radio">
                <el-checkbox class="duiqi" label="create"
                  >录入人：<br />{{ radioLuRu }}</el-checkbox
                >
                <el-checkbox class="duiqi" label="userId"
                  >维护人：<br />{{ radioWeiHu }}</el-checkbox
                >
                <el-checkbox class="duiqi" label="maintenance"
                  >合作维护人：<br />{{ radioHZweihu }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="指定经纪人:"
            prop="userName"
            label-width="25%"
            style="margin-top: 0px"
          >
            <el-button
              @click="JingJiRenVisible = true"
              style="
                width: 84%;
                height: 32px;
                text-align: left;
                line-height: 0px;
              "
            >
              <span
                :style="{
                  color:
                    ruleForm.userName == '请选择指定经纪人'
                      ? '#c0c4cc'
                      : '#666',
                }"
                >{{ ruleForm.userName }}</span
              >
            </el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="cancel">取 消</el-button>
          <el-button
            size="mini"
            type="primary"
            @click="addRole"
            style="margin-right: 83px; margin-top: 10px"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="指定经纪人"
        :visible.sync="JingJiRenVisible"
        width="70%"
        :before-close="handleClose1"
      >
        <!-- <TongXunLuList :isQianyueOpen='true' @colseJingJiRenDialog=isJingJiRenData></TongXunLuList> -->
        <TongXunLuList
          :isQianyueOpen="true"
          @colseJingJiRenDialog="isJingJiRenData"
        ></TongXunLuList>
        <span slot="footer" class="dialog-footer">
          <el-button @click="JingJiRenVisible = false">取 消</el-button>
          <el-button type="primary" @click="JingJiRenVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import TongXunLuList from "../TongXunLu/TongXunLuList.vue";
export default {
  components: {
    TongXunLuList,
  },
  name: "FenPeiKeYuanList",
  inject: ["reload"],
  data() {
    return {
      customerName: "", // 客户姓名
      tel:"",//客户手机号
      userName: "", //维护人姓名
      state: this.$route.query.state,
      tabPane: "待分配客源",
      isFeiPei: "",
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      CustomerList: [], //客户列表,
      FenPeiVisible: false,
      radio: [],
      radioLuRu: "",
      radioHZweihu: "",
      radioWeiHu: "",
      ruleForm: {
        radio: [],
        userName: "请选择指定经纪人",
      },
      rules: {
        radio: [
          {
            required: true,
            message: "请选择分配角色",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: "请选择指定经纪人",
            trigger: "blur",
          },
        ],
      },
      JingJiRenVisible: false,
      JingJiRenData: "",
      HouseData: "",
      options: [
        {
          value: "0",
          label: "无效",
        },
        {
          value: "1",
          label: "有效",
        },
      ],
      enabled: "1", //有效性
    };
  },
  created() {
    /* 获取所有的日志列表 */
    if (this.state == "1") {
      this.isFeiPei = "1";
      this.tabPane = "待分配客源";
    } else if (this.state == "0") {
      this.isFeiPei = "0";
      this.tabPane = "已分配客源";
    }
    if (this.fangyuanState == "1") {
      this.isFeiPei = "1";
      this.tabPane = "待分配客源";
    } else if (this.fangyuanState == "0") {
      this.isFeiPei = "0";
      this.tabPane = "已分配客源";
    }
    if (this.tabPane == "待分配客源") {
      this.getFenPeiList();
    }
  },
  methods: {
    // 查询
    chaxun() {
      this.getFenPeiList();
    },
    // 重置
    reset() {
      this.customerName = "";
      this.tel = ""
      this.userName = "";
      this.enabled = "1";
      this.getFenPeiList();
    },
    async getFenPeiList() {
      const data = {
        customerName: this.customerName,
        tel:this.tel,
        userName: this.userName,
        enabled: this.enabled,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      const { data: res } = await this.$http.get(
        this.$url.GetAssignmentCustomerList,
        {
          params: data,
        }
      );
      if (res.msgCode == 1) {
        res.result.map((item) => {
          item.enabled = item.enabled == "0" ? "无效" : "有效";
        });
        this.CustomerList = res.result;
        this.total = res.maxRecords;
      }
    },
    async getHistoryList() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      const { data: res } = await this.$http.get(
        this.$url.GetAssignmentHistoryCustomer,
        {
          params: data,
        }
      );
      if (res.msgCode == 1) {
        this.CustomerList = res.dtList;
        this.total = res.maxRecords;
      }
    },
    handleClickTab(tab, event) {
      this.tabPane = tab.label;
      if (tab.label == "待分配客源") {
        this.isFeiPei = "1";
        this.getFenPeiList();
      } else if (tab.label == "已分配客源") {
        this.isFeiPei = "0";
        this.getHistoryList();
      }
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      if (this.tabPane == "待分配客源") {
        this.getFenPeiList();
      } else {
        this.getHistoryList();
      }
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      if (this.tabPane == "待分配客源") {
        this.getFenPeiList();
      } else {
        this.getHistoryList();
      }
    },
    OpenFenPei(row) {
      console.log(this.radioLuRu);
      console.log(this.radioWeiHu);
      console.log(this.radioHZweihu);
      this.HouseData = row;
      this.FenPeiVisible = true;
      this.radioLuRu = this.HouseData.createPersonName;
      this.radioWeiHu = this.HouseData.userName;
      this.radioHZweihu = this.HouseData.maintenanceUserName;
    },
    cancel() {
      this.ruleForm.radio = [];
      this.radioLuRu = "";
      this.radioWeiHu = "";
      this.radioHZweihu = "";
      this.ruleForm.userName = "";
      console.log(this.radioLuRu);
      console.log(this.radioWeiHu);
      console.log(this.radioHZweihu);
      this.FenPeiVisible = false;
    },
    handleClose() {
      this.ruleForm.radio = [];
      this.FenPeiVisible = false;
    },
    handleClose1() {
      this.JingJiRenVisible = false;
    },
    addRole() {
      console.log(this.ruleForm.radio);
      console.log(this.ruleForm.radio.toString());
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.userName == "请选择指定经纪人") {
            this.$message.error("请选择指定经纪人！");
            return;
          }
          const data = {
            CustomerId: this.HouseData.customerId,
            CustomerRole: this.ruleForm.radio.toString(),
            userId: this.JingJiRenData.userId,
          };
          const { data: res } = await this.$http.put(
            this.$url.UpdateAssignmentCustomer,
            data
          );
          if (res.msgCode == 1) {
            this.$message.success("分配成功");
            this.getFenPeiList();
          } else {
            this.$message.error(res.result);
          }
          this.FenPeiVisible = false;
          this.ruleForm.radio = [];
          this.radioLuRu = "";
          this.radioWeiHu = "";
          this.radioHZweihu = "";
          this.ruleForm.userName = "";
        }
      });
    },
    isJingJiRenData(row) {
      this.JingJiRenData = row;
      this.ruleForm.userName = row.userName;
      this.JingJiRenVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.qiugou-tabs {
  font-size: 14px;
  /deep/ .el-tabs__nav > .is-active {
    background: transparent !important;
    color: #df0202 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #df0202;
  }
  /deep/ .el-tabs__header {
    margin: 0px;
  }
  /deep/ .el-tabs__active-bar,
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-tabs__content {
    height: 0 !important;
  }
  /deep/ .el-tabs__item:hover {
    background-color: transparent;
  }
}
/deep/ .el-dialog__body {
  padding: 0 20px !important;
}
.duiqi {
  vertical-align: top;
}
/deep/ .el-checkbox__input {
  vertical-align: top !important;
  margin-top: 2px;
}
/deep/ .el-checkbox__label {
  vertical-align: top !important;
}
</style>
