<template>
  <div>
    <el-card class="search">
      <el-row style="margin-top: 3px;height: auto;">
        <el-col :span="1">
          <span class="title">商圈:</span>
        </el-col>
        <el-col :span="20">
          <el-tabs
            v-model="activeName"
            style="margin-left:0.5%;"
            type="card"
            @tab-click="handleClick"
          >
            <el-tab-pane
              name="不限"
              label="不限"
              style="margin-left: 0.5% !important"
            >
              <span slot="label" @click="gobuxian">不限</span>
            </el-tab-pane>
            <el-tab-pane
              v-for="item in regionList1"
              :value="item.name"
              :key="item.name"
              :label="item.name"
            >
              <el-checkbox-group
                :max="3"
                style="font-size: 14px;float:left;margin:5px;"
                v-model="daQuInclude"
              >
                <el-checkbox
                  v-for="item1 in item.includeShangQuan"
                  :label="item1.areaIds"
                  :value="item.areaIds"
                  :key="item1.areaIds"
                  >{{ item1.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span class="title">楼盘编号：</span>
          <el-input
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            v-model="louPanNo"
            placeholder="请输入楼盘编号"
            style="width: 15%"
          ></el-input>
          <span class="title" style="margin-left: 15px;">楼盘名称：</span>
          <el-input
            v-model="louPanName"
            placeholder="请输入楼盘名称"
            style="width: 15%"
          ></el-input>

          <span class="title" style="margin-left: 15px;">项目地址：</span>
          <el-input
            v-model="louPanAddress"
            placeholder="请输入项目地址"
            style="width: 15%"
          ></el-input>

          <span class="title" style="margin-left: 15px;">开发单位：</span>
          <el-input
            v-model="kaiFaDanWei"
            placeholder="请输入开发单位"
            style="width: 15%"
          ></el-input>

          <span style="float: right;">
            <el-button
              size="mini"
              style="margin-left: 20px;"
              type="primary"
              @click="getloupanListCX"
              >查询</el-button
            >
            <el-button size="mini" @click="cancelbreedList" type="warning"
              >重置</el-button
            >
          </span>
        </el-col>
      </el-row>
      <span class="title">是否审核：</span>
      <el-select
        v-model="isConfirmed"
        placeholder="请选择是否审核"
        style="width: 15%"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="title" style="margin-left: 15px;">是否可用：</span>
      <el-select
        v-model="isPublic"
        placeholder="请选择是否可用"
        style="width: 15%"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-row style="margin-top: 10px">
        <el-col>
          <el-button
            size="mini"
            @click="loupanInsert()"
            type="primary"
            icon="el-icon-plus"
            style=" border: none"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-table
        ref="multipleTable"
        @row-click="xzLouPan"
        v-loading="loading"
        :data="loupanList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column label="楼盘编号" prop="name" width="130">
          <template slot-scope="scope">
            <el-button
              class="tableButSpan"
              v-if="!isLouPanOpen"
              @click="loupanXiangqing(scope.row)"
              type="text"
              size="mini"
              style="color: #1268c0;text-decoration: underline"
              >{{ scope.row.louPanNo }}</el-button
            >
            <p v-if="isLouPanOpen">{{ scope.row.louPanNo }}</p>
          </template>
        </el-table-column>
        <el-table-column label="楼盘名称" prop="louPanName"></el-table-column>
        <el-table-column
          label="所属商圈"
          width="140"
          prop="areaNames"
        ></el-table-column>
        <el-table-column
          label="项目地址"
          prop="louPanAddress"
        ></el-table-column>
        <el-table-column label="开发单位" prop="kaiFaDanWei"></el-table-column>
        <el-table-column
          width="80"
          label="已审核"
          prop="isConfirmed"
        ></el-table-column>
        <el-table-column
          width="80"
          label="是否可用"
          prop="isPublic"
        ></el-table-column>
        <el-table-column v-if="!isLouPanOpen" label="操作" width="180">
          <template slot-scope="scope" class="right">
            <el-button
              @click.native.stop="loupanUpdate(scope.$index, scope.row)"
              type="text"
              size="mini"
              class="el-icon-edit"
              style="color: #1890ff;"
              >修改</el-button
            >
            <el-button
              @click.native.stop="handleDelete(scope.row)"
              type="text"
              size="mini"
              class="el-icon-delete"
              style="color: #da0909;"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
var _index;
export default {
  name: "LouPanZiDian1List",
  inject: ["reload"],
  data() {
    return {
      drow: "",
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loupanList: [
        {
          louPanNo: "",
          louPanName: "",
          chengqu: "",
          areaName: "",
          louPanAddress: "",
          kaiFaDanWei: "",
          isConfirmed: "",
          isPublic: ""
        }
      ],
      louPanNo: "", //楼盘编号
      louPanName: "", //楼盘名称
      areaIds: "", //商圈id
      louPanAddress: "", //楼盘地址
      kaiFaDanWei: "", //开发单位
      isConfirmed: "", //是否审核
      isPublic: "", //是否可用
      options: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "2",
          label: "否"
        }
      ],
      list: "",
      regionList1: [
        {
          name: "",
          includeShangQuan: [{ name: "", areaIds: "" }]
        }
      ],
      // 商圈区域多选框数据
      activeName: "不限",
      daQuInclude: [],
      loupanParams: JSON.parse(window.localStorage.getItem("loupanParams"))
    };
  },
  created() {
    if (this.loupanParams) {
      if (this.loupanParams.areaIds != "") {
        this.daQuInclude = this.loupanParams.areaIds.split(",");
      }
      this.louPanNo = this.loupanParams.louPanNo;
      this.louPanName = this.loupanParams.louPanName;
      this.louPanAddress = this.loupanParams.louPanAddress;
      this.kaiFaDanWei = this.loupanParams.kaiFaDanWei;
      this.isConfirmed = this.loupanParams.isConfirmed;
      this.isPublic = this.loupanParams.isPublic;
      this.currentPage = this.loupanParams.currentPage;
      this.pageSize = this.loupanParams.pageSize;
    }
    if (localStorage.getItem("loupanActiveName")) {
      this.activeName = localStorage.getItem("loupanActiveName");
    }
    this.getShuJuZiDianList();
    /* 获取所有的日志列表 */
    this.getloupanList();
  },
  props: {
    isLouPanOpen: {
      //签约打开
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(tab, event) {
      this.daQuInclude = [];
    },
    xzLouPan(row) {
      if (this.isLouPanOpen) {
        this.$emit("colseLouPanDialog", row);
      }
    },
    gobuxian() {
      this.daQuInclude = "";
    },
    // 查询
    getloupanListCX() {
      this.currentPage = 1;
      this.getloupanList();
    },
    // 新增
    loupanInsert() {
      this.$router.push({
        name: "LouPanZiDian1Detail1",
        query: {
          louPanId: 0,
          num1: 0,
          state: 1
        }
      });
    },
    // 详情
    loupanXiangqing(row) {
      this.$router.push({
        name: "LouPanZiDian1Detail1",
        query: {
          louPanId: row.louPanId,
          louPanNo: row.louPanNo,
          num1: 1,
          state: 2
        }
      });
    },
    // 修改
    loupanUpdate(index, row) {
      this.drow = row.louPanId;
      this.$router.push({
        name: "LouPanZiDian1Detail1",
        query: {
          louPanId: this.drow,
          louPanNo: row.louPanNo,
          num1: 2,
          state: 3
        }
      });
    },
    // 获取多选框的值
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getloupanList();
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getloupanList();
    },
    //重置
    cancelbreedList() {
      this.louPanNo = ""; //楼盘编号
      this.louPanName = ""; //楼盘名称
      this.daQuInclude = ""; //商圈id
      this.louPanAddress = ""; //楼盘地址
      this.kaiFaDanWei = ""; //开发单位
      this.isConfirmed = ""; //是否审核
      this.isPublic = ""; //是否可用
      this.activeName = "不限";
      this.currentPage = 1;
      this.getloupanList();
    },
    // 查询列表
    async getloupanList() {
      var that = this;
      that.loading = true;
      var daQuInclude = "";
      if (that.daQuInclude == "") {
        daQuInclude = "";
      } else {
        daQuInclude = that.daQuInclude.join(",");
      }
      console.log("@", daQuInclude);
      const params = {
        louPanNo: this.louPanNo, //楼盘编号
        louPanName: this.louPanName, //楼盘名称
        louPanAddress: this.louPanAddress, //楼盘地址
        kaiFaDanWei: this.kaiFaDanWei, //开发单位
        isConfirmed: this.isConfirmed,
        isPublic: this.isPublic,
        areaIds: daQuInclude, //商圈id
        currentPage: this.currentPage,
        pageSize: this.pageSize
      };
      localStorage.setItem("loupanParams", JSON.stringify(params));
      localStorage.setItem("loupanActiveName", this.activeName);
      await this.$http
        .get(this.$url.GetLouPanList, {
          params: params
        })
        .then(res => {
          var that = this;
          that.loading = false;
          if (res.data.msgCode == 1) {
            that.loupanList = res.data.dtList;
            console.log(this.loupanList);
            that.loupanList.forEach(item => {
              if (item.isConfirmed == 1) {
                item.isConfirmed = "是";
              } else {
                item.isConfirmed = "否";
              }
              if (item.isPublic == 1) {
                item.isPublic = "是";
              } else {
                item.isPublic = "否";
              }
            });
            that.total = res.data.maxRecords;
          } else {
            that.$message.success(res.data.message);
          }
        })
        .catch(res => {
          that.$message.success("查询失败！");
          that.loading = false;
        });
    },
    // 删除数据
    handleDelete(index) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .delete(this.$url.DeleteLouPan, {
              data: [index.louPanId]
            })
            .then(res => {
              this.loading = false;
              if (res.data.msgCode == 1) {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.getloupanList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            });
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
        });
    },
    getShuJuZiDianList() {
      /* 获取交易权属列表 */
      let that = this;
      let quyu = [],
        name = "";
      let buxian = {
        value: "0",
        label: "不限"
      };
      this.$commonJs.getShuJuZiDianList("SHANGQUAN").then(data => {
        that.regionList1 = data.shangquan;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 15px;
}
// 商圈
/deep/ .el-tabs__item {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}
/deep/ .el-tabs__nav > .is-active {
  color: red !important;
  background: none !important;
  height: 20px;
}
/deep/ .el-tabs__header {
  margin: 0;
}
.el-checkbox {
  margin-left: 10px;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
  height: 20px;
  line-height: 20px;
  border: 0;
  padding: 0;
  margin-left: 2% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
  border: 0;
}
/deep/ .el-tabs__nav .is-top {
  margin-left: 5px;
}
/deep/
  .el-tabs--top.el-tabs--card
  > .el-tabs__header
  .el-tabs__item:nth-child(2) {
  padding: 0;
}
/deep/ .el-tabs__active-bar .is-top {
  width: 0 !important;
}
</style>
