<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col :span="6">
                        <span class="title">联系人：</span>
                        <el-input v-model="userName" placeholder="请输入联系人" style="width: 70%"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <span class="title">手机号：</span>
                        <el-input v-model="mobile" placeholder="请输入手机号" style="width: 70%"></el-input>
                    </el-col>
                    <span style="float: right;">
                        <el-button size="mini" style="margin-left: 20px;" type="primary"
                            @click="cgetJingJiRenList">查询</el-button>
                        <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                    </span>
                </el-row>
                <!-- <el-row style="margin-top: 10px">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button> -->
                <!-- </el-col> -->
                <!-- </el-row> -->
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table class="tableButtonPnone" @row-click="xzJingJiRen" ref="multipleTable"  v-loading="loading"
                    :data="operationList" tooltip-effect="dark"
                    :header-cell-style="{ 'text-align': 'center', 'color': '#333', 'font-size': '14px' }"
                    :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="头像" prop="pictureId" width="80">
                        <template slot-scope="scope">
                            <div class="block">
                                <el-avatar :size="50" :src="scope.row.imgtoux"></el-avatar>

                            </div>
                            <span :class="scope.row.key == '1' ? 'el-icon-key' : ''"
                                style="position: absolute;right:3px;bottom:20px;font-weight:bold;color: #000;transform: rotate(30deg);"></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="联系人" prop="userName"></el-table-column>
                    <el-table-column label="性别" prop="gender" width="100"></el-table-column>
                    <el-table-column label="门店名称" prop="shopName"></el-table-column>
                    <el-table-column label="等级" prop="userLevel" width="100"></el-table-column>
                    <el-table-column label="职位" prop="userType1" width="150"></el-table-column>
                    <el-table-column label="商圈" prop="areaNames" width="180"></el-table-column>
                    <el-table-column label="联系方式(一)" prop="mobile1"></el-table-column>
                    <el-table-column label="联系方式(二)" prop="mobile2"></el-table-column>
                    <el-table-column label="邮箱" prop="email"></el-table-column>
                    <el-table-column label="点赞" v-if="!isQianyueOpen" width="100">
                        <template slot-scope="scope">
                            <el-button @click="getZhan(scope.row)" type="text">
                                <!-- 亮 -->
                                <img v-if="scope.row.thumbUp == 1" src="../../../public/img/isZhanok.png" alt=""
                                    class="zhanCss" />
                                <!-- 不亮 -->
                                <img v-if="scope.row.thumbUp == 0" src="../../../public/img/isZhan.png" alt=""
                                    class="zhanCss" />
                            </el-button>
                            <span class="isZhanSpan">{{ scope.row.thumbUpCount }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column> -->
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
import "../TongXunLu/TongXunLu.css"
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            drow: "",
            loading: false,
            userName: "",
            mobile: "",
            shopId: "",
            currentPage: 1,
            pageSize: 10,
            total: 3,
            operationList: [],
            enabled: "",
            merchantId: "",
            roleType: ""
            // merchantId: localStorage.getItem("merchantId")
        }
    },

    props: {
        isQianyueOpen: {
            //签约打开
            type: Boolean,
            default: false
        },
        fenchengindex: {
            type: Number,
            default: 0
        },
        isfencheng: {
            //分成打开
            type: Boolean,
            default: false
        },
        isFenPei: {
            //分配房源打开
            type: Boolean,
            default: false
        }
    },
    created() {
        this.roleType = window.localStorage.getItem("roleType")
        this.shopId = window.localStorage.getItem("shopId")
        this.merchantId = window.localStorage.getItem("merchantId")
        if (this.isQianyueOpen == true && this.roleType == 2) {
            this.getJingJiRenList1()
        } else if (this.isQianyueOpen == true && this.roleType == 5) {
            this.getJingJiRenList2()
        } else if (this.isFenPei = true && this.roleType == 2) {
            this.getJingJiRenListFenpei()
        } else {
            this.getJingJiRenList()
        }
    },
    methods: {
        xzJingJiRen(row) {
            if (this.isQianyueOpen || this.isFenPei) {
                this.$emit("colseJingJiRenDialog", row, this.fenchengindex)
            }
        },
        // 查询
        cgetJingJiRenList() {
            this.currentPage = 1
            this.getJingJiRenList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "TongXunLuDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "TongXunLuDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取列表 */
        async getJingJiRenList() {
            this.loading = true
            var Myself = ""
            if (this.isfencheng) {
                Myself = 1
            }
            const data = {
                includeMyself: Myself,
                userName: this.userName,
                mobile: this.mobile,
                // shopId: this.shopId,
                isHomePage: "0",
                // merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList[i].userType1 = ""
                    this.operationList[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList[i].userType1.length > 0) {
                        this.operationList[i].userType1 = this.operationList[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId != null) {
                        this.operationList[i].imgtoux =
                            this.$url.avatarsPicSrc + res.dtList[i].pictureId
                        console.log(this.operationList[i].imgtoux)
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList[i].imgtoux = this.$url.girlImg
                        }
                    }
                }
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        async getJingJiRenList1() {
            this.loading = true
            const data = {
                userName: this.userName,
                mobile: this.mobile,
                shopId: this.shopId,
                isHomePage: "0",
                // merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList[i].userType1 = ""
                    this.operationList[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList[i].userType1.length > 0) {
                        this.operationList[i].userType1 = this.operationList[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId != null) {
                        this.operationList[i].imgtoux =
                            this.$url.avatarsPicSrc + res.dtList[i].pictureId
                        console.log(this.operationList[i].imgtoux)
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList[i].imgtoux = this.$url.girlImg
                        }
                    }
                }
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        async getJingJiRenList2() {
            this.loading = true
            const data = {
                userName: this.userName,
                mobile: this.mobile,
                // shopId: this.shopId,
                isHomePage: "0",
                merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList[i].userType1 = ""
                    this.operationList[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList[i].userType1.length > 0) {
                        this.operationList[i].userType1 = this.operationList[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId != null) {
                        this.operationList[i].imgtoux =
                            this.$url.avatarsPicSrc + res.dtList[i].pictureId
                        console.log(this.operationList[i].imgtoux)
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList[i].imgtoux = this.$url.girlImg
                        }
                    }
                }
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        async getJingJiRenListFenpei() {
            this.loading = true
            var Myself = ""
            if (this.isFenPei) {
                Myself = 1
            }
            const data = {
                includeMyself: Myself,
                userName: this.userName,
                mobile: this.mobile,
                shopId: this.shopId,
                isHomePage: "0",
                // merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    this.operationList[i].userType1 = ""
                    this.operationList[i].imgtoux = ""
                    if (res.dtList[i].gender === "1") {
                        res.dtList[i].gender = "男"
                    }
                    if (res.dtList[i].gender === "2") {
                        res.dtList[i].gender = "女"
                    }
                    if (res.dtList[i].userType.indexOf("1") != -1) {
                        this.operationList[i].userType1 = "经纪人,"
                    }
                    if (res.dtList[i].userType.indexOf("2") != -1) {
                        this.operationList[i].userType1 += "商圈经理,"
                    }
                    if (res.dtList[i].userType.indexOf("3") != -1) {
                        this.operationList[i].userType1 += "商户财务,"
                    }
                    if (res.dtList[i].userType.indexOf("4") != -1) {
                        this.operationList[i].userType1 += "人事专员,"
                    }
                    if (res.dtList[i].userType.indexOf("5") != -1) {
                        this.operationList[i].userType1 += "店东,"
                    }
                    if (res.dtList[i].userType.indexOf("6") != -1) {
                        this.operationList[i].userType1 += "平台管理员,"
                    }
                    if (res.dtList[i].userType.indexOf("7") != -1) {
                        this.operationList[i].userType1 += "交易中心,"
                    }
                    // 去除最后面的逗号
                    if (this.operationList[i].userType1.length > 0) {
                        this.operationList[i].userType1 = this.operationList[
                            i
                        ].userType1.substr(
                            0,
                            this.operationList[i].userType1.length - 1
                        )
                    }
                    if (res.dtList[i].pictureId != null) {
                        this.operationList[i].imgtoux =
                            this.$url.avatarsPicSrc + res.dtList[i].pictureId
                        console.log(this.operationList[i].imgtoux)
                    }
                    if (res.dtList[i].pictureId == null) {
                        if (res.dtList[i].gender == "男") {
                            this.operationList[i].imgtoux = this.$url.boyImg
                        }
                        if (res.dtList[i].gender === "女") {
                            this.operationList[i].imgtoux = this.$url.girlImg
                        }
                    }
                }
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            // this.getJingJiRenList()
            if (this.isQianyueOpen == true && this.roleType == 2) {
                this.getJingJiRenList1()
            } else if (this.isQianyueOpen == true && this.roleType == 5) {
                this.getJingJiRenList2()
            } else if (this.isFenPei = true && this.roleType == 2) {
                this.getJingJiRenListFenpei()
            } else {
                this.getJingJiRenList()
            }
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            if (this.isQianyueOpen == true && this.roleType == 2) {
                this.getJingJiRenList1()
            } else if (this.isQianyueOpen == true && this.roleType == 5) {
                this.getJingJiRenList2()
            } else if (this.isFenPei = true && this.roleType == 2) {
                this.getJingJiRenListFenpei()
            } else {
                this.getJingJiRenList()
            }
            // this.getJingJiRenList()
        },
        //重置
        cancelbreedList() {
            this.mobile = ""
            this.userName = ""
            this.enabled = ""
            this.currentPage = 1
            if (this.isQianyueOpen == true && this.roleType == 2) {
                this.getJingJiRenList1()
            } else if (this.isQianyueOpen == true && this.roleType == 5) {
                this.getJingJiRenList2()
            } else if (this.isFenPei = true && this.roleType == 2) {
                this.getJingJiRenListFenpei()
            } else {
                this.getJingJiRenList()
            }
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    const { data: res } = this.$http
                        .delete(this.$url.deleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.GetPetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.loading = true
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                        const { data: res } = this.$http.delete(
                            this.$url.deleteArticle,
                            {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.data.msgCode == 1) {
                            this.loading = false
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.reload()
                        } else {
                            this.loading = false
                            this.$message.success(res.message)
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "TongXunLuDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$router.push({
                    name: "TongXunLuDetail",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        },
        //点赞
        async getZhan(data) {
            const data1 = {
                UserId: data.userId
            }
            const { data: res } = await this.$http.post(
                this.$url.SubmitThumbUp,
                data1
            )
            if (res.msgCode == 1) {
                this.getJingJiRenList()
            } else {
                this.$message.success(res.message)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
