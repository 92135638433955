<template>
    <div>
        <div>
            <!-- 面包屑导航区域 -->
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>关注房源</el-breadcrumb-item>

      </el-breadcrumb> -->
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span style="font-size: 14px;">用户名：</span>
                        <el-input v-model="userName" placeholder="请输入用户名" style="width: 15%"></el-input>
                        <span style="font-size: 14px;margin-left: 15px">用户类型：</span>
                        <el-select v-model="userType" placeholder="请选择用户类型" style="width: 15%">
                            <el-option v-for="item in userTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span style="font-size: 14px;margin-left: 15px">手机号：</span>
                        <el-input v-model="mobile" placeholder="请输入手机号" style="width: 15%"></el-input>
                        <span style="font-size: 14px;margin-left: 15px">是否可用：</span>
                        <el-select v-model="enabled" placeholder="请选择是否可用" style="width: 15%">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        <span style="float: right">
                            <el-button size="mini" style="margin-left: 20px" type="primary"
                                @click="OnGetList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px" v-if="MerchantFalse">
                    <!-- <el-col>
            <el-button size="mini"  @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="background-color:rgb(18,104,192); border: none">新增</el-button> -->
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus"
                            style="border: none">新增</el-button>
                        <!-- <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button> -->
                    </el-col>
                    <!-- <el-button size="mini" 
                       @click="handleEdit"
                       type="primary"
                       icon="el-icon-edit"
                       style="background-color: rgb(14 153 219); border: none; margin-left: 10px">修改</el-button> -->
                    <!-- <el-button size="mini" 
                       @click="handleDelete1()"
                       type="primary"
                       icon="el-icon-delete"
                       style="background-color:rgb(215 163 16); border: none">删除</el-button>
          </el-col>
        </el-row> -->
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark"
                    :header-cell-style="{
                        'text-align': 'center',
                        color: '#333',
                        'font-size': '14px',
                    }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                    <el-table-column label="用户名" prop="userName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini"
                                style="color: #1268c0; text-decoration: underline">{{ scope.row.userName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" prop="gender"></el-table-column>
                    <el-table-column label="用户类型" prop="userType1"></el-table-column>
                    <el-table-column label="商户名称" prop="merchantName"></el-table-column>
                    <el-table-column label="店铺名称" prop="shopName"></el-table-column>
                    <el-table-column label="手机号" prop="mobile1"></el-table-column>
                    <el-table-column label="等级" prop="userLevel"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="操作" v-if="MerchantFalse">
                        <template slot-scope="scope" class="right">
                            <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini"
                                class="el-icon-edit" style="color: #1890ff">修改</el-button>
                            <!-- <el-button
                         @click.native.stop="handleDelete(scope.row)"
                         type="text"
                         size="mini" 
                         class="el-icon-check"
                         style="color: #f6ba03;">成交</el-button> -->
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini"
                                class="el-icon-delete" style="color: #da0909">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                        <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
import commonJs from "../../assets/js/common.js" //共通js方法
import "../RenYuanGuanLi/RenYuanGuanLi.css"
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            MyHeader: { accessToken: "", fp: "", userType: "" },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            articleTitle: "",
            enabled: "1",
            RenYuanGuanLiParams: JSON.parse(window.localStorage.getItem("RenYuanGuanLiParams")),
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            userTypeList: [
                {
                    value: "1",
                    label: "经纪人"
                },
                {
                    value: "2",
                    label: "商圈经理"
                },
                {
                    value: "3",
                    label: "商户财务"
                },
                {
                    value: "4",
                    label: "人事专员"
                },
                {
                    value: "5",
                    label: "店东"
                },
                {
                    value: "6",
                    label: "平台管理员"
                },
                {
                    value: "7",
                    label: "交易中心"
                },
                {
                    value: "9",
                    label: "超级经纪人"
                }
            ],
            userName: "",
            mobile: "",
            userType: "",
            roleType: "",
            MerchantFalse: true
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.MyHeader.userType = localStorage.getItem("userType")
        this.roleType = localStorage.getItem("roleType")
        if (this.roleType == 3) {
            this.MerchantFalse = false
        }
        if (this.RenYuanGuanLiParams) {
            this.currentPage = this.RenYuanGuanLiParams.currentPage;
            this.pageSize = this.RenYuanGuanLiParams.pageSize;
        }
        /* 获取所有的日志列表 */
        this.GetList()
    },
    methods: {
        changeClass1(i) {
            this.isActive1 = i
        },

        changeClass2(i) {
            this.isActive2 = i
        },

        changeClass3(i) {
            this.isActive3 = i
        },
        // 查询
        OnGetList() {
            this.currentPage = 1
            this.GetList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "RenYuanGuanLiDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.$router.push({
                name: "RenYuanGuanLiDetail",
                query: {
                    userId: row.userId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async GetList() {
            let that = this
            let userName = that.userName === "" ? `%20` : that.userName
            // let userType = this.userType === "" ? `%20` : this.userType;
            // let mobile = this.mobile === "" ? `%20` : this.mobile;
            // let enabled = this.enabled === "" ? `%20` : this.enabled;
            const param = {
                userName: that.userName,
                mobile: that.mobile,
                userType: that.userType,
                enabled: that.enabled,
                currentPage: that.currentPage,
                pageSize: that.pageSize
            }
            localStorage.setItem("RenYuanGuanLiParams", JSON.stringify(param));
            that.$http
                .get(that.$url.GetUserList, {
                    params: {
                        userName: that.userName,
                        mobile: that.mobile,
                        userType: that.userType,
                        enabled: that.enabled,
                        currentPage: that.currentPage,
                        pageSize: that.pageSize
                    }
                })
                .then(function (res) {
                    if (res.data.msgCode == 1) {
                        that.operationList = res.data.dtList
                        that.loading = false
                        that.total = res.data.maxRecords
                        for (var i = 0; i < res.data.dtList.length; i++) {
                            that.operationList[i].userType1 = ""
                            if (res.data.dtList[i].gender === "1") {
                                that.operationList[i].gender = "男"
                            } else if (res.data.dtList[i].gender === "2") {
                                that.operationList[i].gender = "女"
                            }

                            if (
                                res.data.dtList[i].userType.indexOf("1") != -1
                            ) {
                                that.operationList[i].userType1 += "经纪人,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("2") != -1
                            ) {
                                that.operationList[i].userType1 += "商圈经理,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("3") != -1
                            ) {
                                that.operationList[i].userType1 += "商户财务,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("4") != -1
                            ) {
                                that.operationList[i].userType1 +=
                                    "人事专员,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("5") != -1
                            ) {
                                that.operationList[i].userType1 += "店东,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("6") != -1
                            ) {
                                that.operationList[i].userType1 += "平台管理员,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("7") != -1
                            ) {
                                that.operationList[i].userType1 += "交易中心,"
                            }
                            if (
                                res.data.dtList[i].userType.indexOf("9") != -1
                            ) {
                                that.operationList[i].userType1 += "超级经纪人,"
                            }
                            // 去除最后面的逗号
                            if (that.operationList[i].userType1.length > 0) {
                                that.operationList[
                                    i
                                ].userType1 = that.operationList[
                                    i
                                ].userType1.substr(
                                    0,
                                    that.operationList[i].userType1.length - 1
                                )
                            }
                            if (res.data.dtList[i].enabled === "1") {
                                that.operationList[i].enabled = "是"
                            } else if (res.data.dtList[i].enabled === "0") {
                                that.operationList[i].enabled = "否"
                            }
                        }
                    } else {
                        var message = commonJs.getUrlError(res.message)
                        that.$message.error(message)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetList()
        },
        //重置
        cancelbreedList() {
            this.userName = ""
            this.userType = ""
            this.mobile = ""
            this.enabled = "1"
            this.currentPage = 1
            this.GetList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.userId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteUser, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.OnGetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => err)
        },

        // 删除数据
        // async handleDelete1() {
        //     var data1 = this.multipleSelection
        //     var data2 = []
        //     if (this.multipleSelection == undefined) {
        //         this.$confirm("请勾选数据", "提示", {
        //             type: "warning"
        //         })
        //     } else {
        //         this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        //             confirmButtonText: "确定",
        //             cancelButtonText: "取消",
        //             type: "warning"
        //         })
        //             .then(() => {
        //                 for (var i = 0; i < data1.length; i++) {
        //                     data2.push(data1[i].userId)
        //                 }
        //                 const { data: res } = this.$http
        //                     .delete(this.$url.DeleteUser, {
        //                         data: data2,
        //                         headers: {
        //                             "Content-Type":
        //                                 "application/json;charset=UTF-8"
        //                         }
        //                     })
        //                     .then((res) => {
        //                         this.loading = false
        //                         if (res.data.msgCode == 1) {
        //                             this.$message({
        //                                 type: "success",
        //                                 message: "删除成功!"
        //                             })
        //                             this.OnGetList()
        //                         } else {
        //                             this.$message({
        //                                 type: "error",
        //                                 message: res.data.message
        //                             })
        //                         }
        //                     })
        //             })
        //             .catch((err) => {
        //                 this.$message({
        //                     type: "error",
        //                     message: err
        //                 })
        //             })
        //     }
        // },
        xiangqing(index, row) {
            this.userId = row.userId
            this.$router.push({
                name: "RenYuanGuanLiDetail",
                query: {
                    userId: this.userId,
                    num: 2,
                    state: 3
                }
            })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "RenYuanGuanLiDetail",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        }
    }
}
</script>

