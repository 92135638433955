<template>
    <div>
        <div>
            <!-- 底部内容区域 -->
            <el-card style="margin-top:6px !important">
                <el-row style="padding:10px; padding-top:20px">
                    <el-col :span="5" style="border:1px solid #ccc; border-radius:3px">
                        <div class="downTree">
                            <el-tree :expand-on-click-node="false" :data="treeData" default-expand-all node-key="id" ref="tree" highlight-current :props="defaultProps" @node-click="handleClickFolder" @node-contextmenu="openMenu">
                            </el-tree>
                        </div>
                        <div v-show="visible" class="showMenu" :style="{left:left+'px',top:top+'px'}">
                            <el-menu ref="elMenu" id="menu" @select="handleSelect" text-color="#000">
                                <el-menu-item v-if="newAdd" index="1" class="menuItem">
                                    <i class="el-icon-circle-plus-outline"></i><span class="menuLeft" slot="title">新建</span>
                                </el-menu-item>
                                <el-menu-item v-if="levelOne" index="2" class="menuItem">
                                    <i class="el-icon-edit"></i> <span slot="title" class="menuLeft">修改</span>
                                </el-menu-item>
                                <el-menu-item v-if="levelOne" index="3" class="menuItem">
                                    <i class="el-icon-delete"></i> <span slot="title" class="menuLeft">删除</span>
                                </el-menu-item>
                            </el-menu>
                        </div>
                    </el-col>
                    <el-col :span="19">
                        <!-- <span class="title" style="margin-left:20px">查询条件:</span>
                        <el-input v-model="queryInput" placeholder="请输入查询条件" style="width:25%;margin-left:10px"></el-input>
                        <el-button size="mini" class="el-button1" type="primary" style="margin-left:20px">查询</el-button> -->
                        <el-table style="margin-left:15px" ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                            <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                            <el-table-column v-if="this.roleType==6" label="商户" prop="merchantName"></el-table-column>
                            <el-table-column label="店铺" prop="shopName">
                                <!-- <template slot-scope="scope">
                                    <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.articleTitle }}</el-button>
                                </template> -->
                            </el-table-column>
                            <el-table-column label="所属商圈" prop="areaNames" width="260"></el-table-column>
                            <el-table-column label="是否可用" prop="enabled" width="100"></el-table-column>
                            <el-table-column label="创建时间" prop="createTime"></el-table-column>
                            <el-table-column label="操作" width="160" v-if="roleType == 6">
                                <template slot-scope="scope" class="right">
                                    <!-- {{scope.row}} -->
                                    <el-button @click.native.stop="modify(scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                                    <el-button @click.native.stop="handleDelete(scope.row.shopId)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                                </template>
                            </el-table-column>
                            <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                                <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                                <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                            </div>
                        </el-table>
                        <!-- 分页区域 -->
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" style="margin-top:30px">
                        </el-pagination>
                    </el-col>
                </el-row>
                <!-- 弹窗 -->
                <!-- <el-dialog :title="menuName" :visible.sync="menu" width="55%" @close="handleClose">
                    <el-form :model="menuRuleForm" :rules="menuRules" ref="menuRuleRef" label-width="20%" class="demo-ruleForm">
                        <el-row style="margin-top: 3px;height: auto; margin-left:10%">
                            <el-col :span="22" v-if="modifyShop">
                                <el-form-item label="商圈:" label-width="7.5%">
                                    <el-tabs v-model="list" style="margin-left:0%;" type="card" @tab-click="handleClick">
                                        <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :label="item.name">
                                            <el-checkbox-group :max="3" style="font-size: 14px;float:left;margin:5px;" v-model="checkedAreaType">
                                                <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{item1.name}}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col v-if="modifyMerchant" style="height:50px;margin-left: 12%;">
                                <el-form-item label="商户:" label-width="7.5%" prop="merchantName">
                                    <el-input style="width: 72%" v-model="menuRuleForm.merchantName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="modifyShop" style="height:50px;margin-left:8%">
                                <el-form-item label="店铺:" label-width="7.5%" prop="shopName" style="margin-left:15px">
                                    <el-input v-model="menuRuleForm.shopName" style="width:75.5%"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col v-if="modifyMerchant" style="margin-left: 3%;">
                                <el-form-item label="是否可用:" label-width="20%">
                                    <el-radio-group v-model="menuRuleForm.enabled" style="width: 45%">
                                        <el-radio label="1">是</el-radio>
                                        <el-radio label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col style="margin-top:5%;">
                                <el-form-item class="dialog-footer" label-width="10%" style="padding-bottom: 10px;">
                                    <el-button size="mini" type="warning" @click="menu = false">取消</el-button>
                                    <el-button size="mini" type="primary" v-if="num == 1" @click="onSubmit('form')">新增</el-button>
                                    <el-button size="mini" type="primary" v-if="num == 2" @click="onUpdate('form')">确定</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-dialog> -->
                <!-- <el-dialog :title="menuName" :visible.sync="menu" width="55%" @close="handleClose"> -->
                <!-- <DianPuDetail></DianPuDetail> -->
                <!-- </el-dialog> -->
            </el-card>

        </div>
    </div>
</template>

<script>
// import DianPuDetail from "../DianPu/DianPuDetail"

var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    // components: {
    //     DianPuDetail
    // },
    data() {
        return {
            menuRuleForm: {
                merchantId: "",
                shopName: "",
                merchantName: "",
                enabled: "1"
            },
            menuRules: {
                shopName: [
                    {
                        required: true,
                        message: "请输入店铺",
                        trigger: "blur"
                    }
                ],
                merchantName: [
                    {
                        required: true,
                        message: "请输入商户",
                        trigger: "blur"
                    }
                ]
            },
            // 菜单显示
            levelOne: true,
            list: "",
            menuName: "",
            visible: false,
            top: 0,
            left: 0,
            queryInput: "",
            newAdd: true,
            // 店铺
            modifyShop: false,
            // 商户
            modifyMerchant: false,
            menu: false,
            areaIds: "",
            defaultProps: {
                children: "children",
                label: "label",
                id: "id"
            },
            roleType: "",
            num: "",
            drow: "",
            loading: false,
            shopName: "",
            merchantId: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [
                {
                    articleTitle: "甲",
                    enabled: "222",
                    createTime: "2020-1-2"
                }
            ],
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            shopId: "",
            shopData: "",
            merchantName: "",
            currentNode: "",
            currentData: "",
            treeData: [],
            treeD: [],
            treeId: [],
            regionList1: [
                {
                    name: "",
                    includeShangQuan: [{ name: "", areaIds: "" }]
                }
            ],
            checkedAreaType: [], //商圈选中
            shopArr: [],
            isModify: ""
        }
    },
    mounted() {
        this.getCompanyStructureTree()
        this.getDianPuList()
    },
    created() {
        this.roleType = window.localStorage.getItem("roleType")
        /* 获取所有的日志列表 */

        this.getCompanyStructureTree()
        this.getDianPuList()
        this.$commonJs.getShuJuZiDianList("SHANGQUAN").then(data => {
            this.regionList1 = data.shangquan
            // console.log(this.regionList1)
        })
    },
    watch: {
        //点击菜单栏后自动关闭
        visible(value) {
            if (value) {
                document.body.addEventListener("click", this.closeMenu)
                // console.log(value)
            } else {
                document.body.removeEventListener("click", this.closeMenu)
            }
            // console.log(value)
        }
    },
    methods: {
        // 弹窗关闭清除校验
        handleClose() {
            // this.$refs.menuRuleRef.resetFields()
            this.menu = false
        },
        // 树形控件点击事件
        handleClickFolder(data, node) {
            this.visible = false
            this.currentNode = node
            this.currentData = data
            // 获取店铺列表
            if (data.children == undefined) {
                var id = node.parent.key
                var name = data.label
                this.currentPage = 1
                this.getDianPuList(id, name)
            }
            if (data.children !== undefined) {
                if (data.label === "平台") {
                    this.visible = false
                    var id = ""
                    var name = ""

                    this.getDianPuList(id, name)
                    return
                }
                var id = data.id
                var name = ""
                this.currentPage = 1
                this.getDianPuList(id, name)
            }
        },
        // 获取店铺列表
        async getDianPuList(id, name) {
            const data = {
                areaIds: this.areaIds, //商圈id
                // shopName: this.shopName,
                shopName: name,
                // merchantId: this.merchantId,
                merchantId: id,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("获取店铺成功")
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        // 树形控件右键显示菜单
        openMenu(e, data, node, comp) {
            // 新增子节点 定义currentData
            this.currentData = data
            this.currentNode = node
            this.$refs.elMenu.activeIndex = null
            this.visible = true
            if (this.roleType == 5) {
                this.visible = false
            }
            var x = e.x
            var y = e.y
            this.top = y
            this.left = x

            if (node.level == 1) {
                this.levelOne = false
            } else {
                this.levelOne = true
            }
            if (node.level == 3) {
                this.newAdd = false
                this.modifyShop = true
            } else {
                this.newAdd = true
                this.modifyShop = false
            }
            if (node.level == 2) {
                this.modifyMerchant = true
            } else {
                this.modifyMerchant = false
            }

            return
        },
        // 关闭菜单
        closeMenu() {
            this.visible = false
        },
        // 菜单点击
        handleSelect(key) {
            this.closeMenu()
            if (key == 1) {
                this.menu = true
                this.num = "1"
                this.menuName = "新建"
                console.log("这是新建功能")

                this.checkedAreaType = []
                if (this.currentNode.level == 1) {
                    // this.modifyMerchant = true
                    this.$router.push({
                        name: "ShangHuGuanLiDetail",
                        query: {
                            merchantId: 0,
                            num: 0,
                            state: 1
                        }
                    })
                }
                if (this.currentNode.level == 2) {
                    // console.log(this.currentData.id)
                    // this.getShopDetail(this.currentData.id)
                    this.$router.push({
                        name: "DianPuDetail",
                        query: {
                            shopId: 0,
                            num: 0,
                            state: 1,
                            isMerchantId: this.currentData.id,
                            isShowShangHu: 1
                        }
                    })
                    this.modifyShop = true
                    this.modifyMerchant = false
                }
            }
            if (key == 2) {
                this.menu = true
                this.num = "2"
                this.menuName = "修改"
                console.log("这是修改功能")
                this.isModify = "2"
                console.log(this.currentNode.parent)
                if (this.currentNode.level == 2) {
                    this.getMerchantDetail()
                    this.$router.push({
                        name: "ShangHuGuanLiDetail",
                        query: {
                            merchantId: this.currentData.id,
                            num: 2,
                            state: 3
                        }
                    })
                }
                if (this.currentNode.level == 3) {
                    this.getShopDetail()
                    this.$router.push({
                        name: "DianPuDetail",
                        query: {
                            shopId: this.currentData.id,
                            num: 2,
                            state: 3,
                            merchantId: this.shopData.merchantId
                        }
                    })
                }
            }
            if (key == 3) {
                if (this.currentNode.level == 2) {
                    let MerchantIds = this.currentData.id
                    this.handleDelete1(MerchantIds)
                }
                if (this.currentNode.level == 3) {
                    let ShopIds = this.currentData.id
                    this.handleDelete(ShopIds)
                }

                console.log(this.currentData.id, "商户")
                console.log("这是删除功能")
            }
        },
        // 修改店铺
        modify(row) {
            this.shopData = row
            this.menu = true
            this.num = "2"
            this.menuName = "修改"
            this.modifyShop = true
            this.isModify = "1"
            this.$router.push({
                name: "DianPuDetail",
                query: {
                    shopId: this.shopData.shopId,
                    num: 2,
                    state: 3,
                    merchantId: this.shopData.merchantId
                }
            })
            // this.getShopDetail(this.shopData.shopId)
        },
        // 获取树状图列表
        getCompanyStructureTree() {
            const { data: res } = this.$http
                .get(this.$url.GetCompanyStructureTree)
                .then(res => {
                    if (res.data.msgCode == 1) {
                        var jsonTree = JSON.parse(res.data.json)

                        this.treeData.push(jsonTree)
                        this.treeData.splice(0, this.treeData.length - 1)
                    }
                })
        },
        // 获取商户id
        async getMerchantList(merchantName) {
            const data = {
                merchantName: merchantName,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取列表成功")
                this.shopArr = res.dtList
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        // 新增
        async onSubmit() {
            this.$refs.menuRuleRef.validate(async valid => {
                if (valid) {
                    if (this.currentNode.level == 3) {
                        this.$message.error("最多只支持三级！")
                        return false
                    }
                    if (this.currentNode.level == 1) {
                        const data = {
                            merchantName: this.menuRuleForm.merchantName,
                            enabled: this.menuRuleForm.enabled
                        }
                        const { data: res } = await this.$http.post(
                            this.$url.InsertMerchant,
                            data,
                            {
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.msgCode == 1) {
                            // this.$message.success("新增商户成功")
                        } else {
                            this.$message.success(res.message)
                        }
                    }
                    if (this.currentNode.level == 2) {
                        const data = {
                            shopName: this.menuRuleForm.shopName,
                            areaIds: this.checkedAreaType.join(","), //商圈id
                            merchantId: this.currentData.id
                        }
                        if (this.checkedAreaType.join(",") === "") {
                            return this.$message.error("请选择所属商圈")
                        }
                        const { data: res } = await this.$http.post(
                            this.$url.InsertShop,
                            data
                        )
                        if (res.msgCode == 1) {
                            // this.$message.success("新增成功")
                        } else {
                            this.$message.success(res.message)
                        }
                    }
                    this.menu = false

                    this.getCompanyStructureTree()
                    this.getDianPuList()
                }
            })
        },
        // 获取店铺详情
        async getShopDetail(id) {
            this.shopId = id

            const data = {}
            if (this.isModify == "1") {
                data.shopId = this.shopId
            }
            if (this.isModify == "2") {
                data.shopId = this.currentData.id
            }
            this.$http
                .get(this.$url.GetShopDetail, {
                    params: data
                })
                .then(res => {
                    if (res.data.msgCode == 1) {
                        console.log(this.menuRuleForm)

                        this.menuRuleForm = res.data.dtList[0]
                        console.log(res.data.dtList[0].areaIds)
                        this.checkedAreaType = res.data.dtList[0].areaIds.split(
                            ","
                        )
                        // this.$router.push({
                        //     name: "DianPuDetail",
                        //     query: {
                        //         shopId: this.currentData.id,
                        //         num: 2,
                        //         state: 3,
                        //         merchantId: res.data.dtList[0].merchantId
                        //     }
                        // })

                        // this.$message.success("获取详情成功")
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        // 获取详情
        async getMerchantDetail() {
            const data = {
                merchantId: this.currentData.id
            }
            await this.$http
                .get(this.$url.GetMerchantDetail, {
                    params: data
                })
                .then(res => {
                    if (res.data.msgCode == 1) {
                        this.menuRuleForm = res.data.dtList[0]
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },

        // 修改店铺
        async onUpdate() {
            this.$refs.menuRuleRef.validate(async valid => {
                var that = this
                if (valid) {
                    if (this.isModify == "1") {
                        const data = {
                            shopId: that.shopId,
                            shopName: that.menuRuleForm.shopName,
                            areaIds: that.checkedAreaType.join(","),
                            merchantId: that.shopData.merchantId
                        }
                        if (this.checkedAreaType.join(",") === "") {
                            return that.$message.error("请选择所属商圈")
                        }
                        that.$http.put(that.$url.UpdateShop, data).then(res => {
                            if (res.data.msgCode == 1) {
                                that.getDianPuList()
                                that.getCompanyStructureTree()
                            } else {
                                that.$message.success(res.message)
                            }
                        })
                    }
                    if (this.currentNode.level == 3) {
                        const data = {
                            shopId: that.currentData.id,
                            shopName: that.menuRuleForm.shopName,
                            areaIds: that.checkedAreaType.join(","),
                            merchantId: that.currentNode.parent.key
                        }
                        if (this.checkedAreaType.join(",") === "") {
                            return that.$message.error("请选择所属商圈")
                        }
                        that.$http.put(that.$url.UpdateShop, data).then(res => {
                            if (res.data.msgCode == 1) {
                                // that.$message.success("修改店铺成功")
                                that.getDianPuList()
                                that.getCompanyStructureTree()
                            } else {
                                that.$message.success(res.message)
                            }
                        })
                    }
                    if (this.currentNode.level == 2) {
                        const data = {
                            merchantId: that.currentData.id,
                            merchantName: this.menuRuleForm.merchantName,
                            enabled: this.menuRuleForm.enabled
                        }
                        const { data: res } = await this.$http.put(
                            this.$url.UpdateMerchant,
                            data,
                            {
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.msgCode == 1) {
                            that.getDianPuList()
                            that.getCompanyStructureTree()
                            // this.$message.success("修改商户成功")
                        } else {
                            this.$message.success(res.message)
                        }
                    }
                    this.menu = false
                    that.getDianPuList()
                    that.getCompanyStructureTree()
                } else {
                    return false
                }
            })
        },

        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getDianPuList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getDianPuList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.getDianPuList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteShop, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then(res => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getCompanyStructureTree()
                                this.getDianPuList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch(err => err)
        },

        // 删除数据
        handleDelete1(index) {
            var data1 = [index]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteMerchant, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then(res => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getCompanyStructureTree()
                                this.getDianPuList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch(err => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "ZuZhiJiaGouDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "ZuZhiJiaGouDetail",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        },

        handleClick(tab, event) {
            // this.daQuInclude = []
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.downTree {
    padding: 5px;
    height: 545px;
    // max-height:;
    display: block;
    overflow-y: auto;
}
.showMenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: fixed;
    list-style-type: none;
    padding: 2px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    width: 130px;
}
.menuItem {
    height: 35px;
    line-height: 35px;
}
.menuLeft {
    margin-left: 5px;
}
/deep/.el-dialog__body {
    padding: 0 20px 20px 20px !important;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}
/deep/ .el-tree-node .el-tree-node__content {
    line-height: 26px;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}
// /deep/.business {
//     border: 0 !important;
//     height: 21px !important;
//     line-height: 23px !important;
// }
/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
    // z-index: 1111;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
.business {
    background: #fff;
    // border: 1px solid #ccc;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    margin-left: 2% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/ .el-tabs__nav .is-top {
    margin-left: 5px;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>

