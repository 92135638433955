<template>
  <div>
    <el-tabs
      type="border-card"
      class="tabs-qianyueList"
      style="height: 25px;position: relative;top: 0px;margin-top: 6px;"
      @tab-click="handleClick"
    >
      <el-tab-pane label="入账管理"></el-tab-pane>
      <el-tab-pane label="付款管理"></el-tab-pane>
      <!-- <el-tab-pane label="退款管理"></el-tab-pane>
            <el-tab-pane label="收据管理"></el-tab-pane>
            <el-tab-pane label="分账数据表"></el-tab-pane> -->
    </el-tabs>

    <el-card
      id="card"
      class="search caiwu"
      style="height: auto;margin-top: 0px !important;"
    >
      <el-row style="margin-top: 8px;">
        <el-col :span="6">
          <div class="titleLabel">合同编号：</div>
          <el-input
            v-model="search.ContractNo"
            placeholder="合同编号"
            class="media-width"
          ></el-input>
        </el-col>
        <!-- <el-col :span="6">
                    <div class="titleLabel">应收状态：</div>
                    <el-select class="media-width" v-model="stateValue" >
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-col> -->
        <el-col :span="6">
          <div class="titleLabel">签约店组：</div>
          <!-- <el-input v-model="search.shopName" placeholder="签约店组" class="media-width"></el-input> -->
          <el-button
            size="mini"
            @click="openDianpuDialog"
            class="buttonInput button_tanchu"
            v-model="search.ShopId"
            style="
                            width: 70%;
                            height: 22px;
                            line-height: 0px;
                            text-align: left;
                        "
          >
            <span v-if="ShopName == '请选择签约店组'" style="color: #c0c4cc">{{
              ShopName
            }}</span>
            <span v-if="ShopName != '请选择签约店组'">{{ ShopName }}</span>
          </el-button>
        </el-col>
        <el-col v-if="label == '入账管理'" :span="6">
          <div class="titleLabel">签约人：</div>
          <el-button
            size="mini"
            @click="clickAgent"
            class="buttonInput button_tanchu"
            v-model="search.UserId"
            style="
                            width: 70%;
                            height: 22px;
                            line-height: 0px;
                            text-align: left;
                        "
          >
            <span v-if="userName2 == '请选择签约人'" style="color: #c0c4cc">{{
              userName2
            }}</span>
            <span v-if="userName2 != '请选择签约人'">{{ userName1 }}</span>
          </el-button>
        </el-col>
        <el-col :span="6">
          <div class="titleLabel">资金类型：</div>
          <el-select
            v-model="stateValue2"
            placeholder="请选择业务类型"
            class="media-width"
          >
            <el-option
              v-for="item in stateOptions2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 8px">
        <!-- <el-col :span="6">
                    <div class="titleLabel">业务类型：</div>
                    <el-select v-model="stateValue1" placeholder="请选择业务类型" class="media-width">
                        <el-option v-for="item in stateOptions1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-col> -->

        <el-col :span="12">
          <div class="titleLabel" style="width:12.5% !important">
            操作时间：
          </div>
          <el-date-picker
            class="media-datepickerWidth"
            style="height: 30px;"
            v-model="createDate"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top: 5px">
        <el-col>
          <span style="float: right;margin-left:15px;" class="search-button">
            <el-button
              size="mini"
              type="primary"
              style="border: none"
              @click="jumpToInsert()"
              >新增</el-button
            >
            <el-button
              size="mini"
              type="primary"
              style="border: none"
              @click="searchList"
              >查询</el-button
            >
            <el-button
              size="mini"
              type="warning"
              style="border: none"
              @click="reset"
              >重置</el-button
            >
            <el-button
              size="mini"
              type="primary"
              style="background-color: rgb(14, 153, 219);border: none"
              >导出</el-button
            >
          </span>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <el-table
        v-if="label == '入账管理'"
        ref="ruzhangTable"
        :data="caiwuList1"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <!-- <el-table-column label="编号" prop="financialNo"></el-table-column> -->
        <el-table-column label="项目信息" min-width="130px" prop="itemInfo">
          <template slot-scope="scope">
            <span
              style="cursor: pointer;"
              @click="xiangqing(scope.$index, scope.row)"
              >{{ scope.row.itemInfo }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="付款方"
          prop="payer"
          min-width="95px"
        ></el-table-column>
        <el-table-column
          label="操作人"
          prop="accountantUser"
          min-width="100px"
        ></el-table-column>
        <el-table-column label="收款方" prop="merchantName"></el-table-column>
        <el-table-column label="资金类型" prop="moneyType"></el-table-column>
        <el-table-column
          label="应收款"
          prop="yingShouYingFuAmount"
        ></el-table-column>
        <el-table-column
          label="已收款"
          prop="shiShouShiFuAmount"
        ></el-table-column>
        <el-table-column
          label="POS机已收款"
          prop="isFuiouReceived"
        ></el-table-column>
        <el-table-column
          label="财务已确认"
          prop="isConfirmed"
        ></el-table-column>
        <!-- <el-table-column label="入账时间" prop="financialDate"></el-table-column> -->
        <el-table-column label="入账时间" prop="financialDate">
          <template slot-scope="scope">
            {{ scope.row.financialDate }}
          </template>
        </el-table-column>
        <el-table-column label="签约人" prop="signer" min-width="100px">
          <template slot-scope="scope">
            {{ scope.row.signer }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150px;">
          <template slot-scope="scope" class="right">
            <el-button
              @click.native.stop="shouju(scope.row)"
              type="text"
              size="mini"
              >收据</el-button
            >
            <el-button
              v-if="scope.row.isConfirmed != '是'"
              @click.native.stop="shenpi(scope.row)"
              type="text"
              size="mini"
              style="color: red"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="label == '付款管理'"
        ref="fukuanTable"
        :data="caiwuList2"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column label="项目信息" min-width="120px" prop="itemInfo">
          <template slot-scope="scope">
            <span
              style="cursor: pointer;"
              @click="xiangqing(scope.$index, scope.row)"
              >{{ scope.row.itemInfo }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="付款商户"
          prop="merchantName"
          min-width="90px"
        ></el-table-column>
        <el-table-column label="操作人" prop="accountantUser" min-width="100px">
        </el-table-column>
        <el-table-column
          label="收款人"
          prop="receriverName"
          min-width="90px"
        ></el-table-column>
        <el-table-column label="资金类型" prop="moneyType"></el-table-column>
        <el-table-column
          label="应付款"
          prop="yingShouYingFuAmount"
        ></el-table-column>
        <el-table-column
          label="已付款"
          prop="shiShouShiFuAmount"
        ></el-table-column>
        <el-table-column
          label="财务已确认"
          prop="isConfirmed"
        ></el-table-column>
        <el-table-column
          label="打款时间"
          prop="financialDate"
        ></el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px;">
          <template slot-scope="scope" class="right">
            <el-button
              @click.native.stop="fkshenpi(scope.row)"
              type="text"
              size="mini"
              style="color: red"
              >审批</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="search.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <el-dialog
      title="选择店铺"
      :visible.sync="dianpuDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <DianPuList
        :isCaiWu="true"
        @colseDianPuDialog="colseDianPuDialog"
      ></DianPuList>
    </el-dialog>
    <el-dialog
      :visible.sync="jingjirenDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="dialogCss"
    >
      <el-card>
        <el-row>
          <el-col>
            <p>
              <span class="el-form-item__label" style="line-height: 40px"
                >经纪人名称：</span
              >
              <el-input
                v-model="userName1"
                placeholder="请输入经纪人名称"
                style="width: 50%"
              ></el-input>
              <span style="float: right">
                <el-button
                  size="mini"
                  type="primary"
                  style="margin-right: 10px"
                  @click="getUserList"
                  >查询</el-button
                >
                <el-button size="mini" type="warning" @click="ResetWeihuren"
                  >重置</el-button
                >
              </span>
            </p>
          </el-col>
        </el-row>
      </el-card>
      <el-table
        ref="multipleTable"
        v-loading="jingjirenDialogLoading"
        :data="jingjirenDialogList"
        @row-click="jingjirenDialogDetails"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column label="用户名" prop="userName1">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column label="性别" prop="gender"></el-table-column>
        <el-table-column label="手机号" prop="mobile1"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-row>
        <el-pagination
          style="width: 70%; float: right"
          @size-change="jingjirenDialogSizeChange"
          @current-change="jingjirenDialogCurrentChange"
          :current-page="userCurrentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="userPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="jingjirenDialogTotal"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <el-dialog
      :title="titleName"
      :visible.sync="shenpiDialog"
      direction="rtl"
      width="40%"
      top="10%"
      class="xuqiuDialog"
    >
      <el-form>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="labelName" label-width="25%">
              <el-date-picker
                style="width: 60%;"
                type="datetime"
                v-model="FinancialDate"
                placeholder="请选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item style="margin-top:20px;margin-left:25%;">
              <el-button
                v-if="label == '入账管理'"
                size="mini"
                style="margin-right:10px"
                type="primary"
                @click="rzsubmit"
                >确定</el-button
              >
              <el-button
                v-if="label == '付款管理'"
                size="mini"
                style="margin-right:10px"
                type="primary"
                @click="fksubmit"
                >确定</el-button
              >
              <el-button
                size="mini"
                type="warning"
                @click="shenpiDialog = false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="shoujuDialog"
      direction="rtl"
      width="40%"
      top="5%"
      class="xuqiuDialog"
    >
      <el-form>
        <div>
          <div ref="pdf">
            <ShouJuList ref="ShouJuList"></ShouJuList>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import DianPuList from "../../components/DianPu/DianPuList.vue";
import ShouJuList from "../../components/CaiWu/shoujuList.vue";
export default {
  inject: ["reload"],
  components: {
    DianPuList,
    ShouJuList
  },
  data() {
    return {
      label: "",
      date1: "",
      date2: "",
      stateValue1: "0",
      stateValue2: "0",
      total: 0,
      ShopName: "请选择签约店组",
      dianpuDialog: false,
      jingjirenDialog: false,
      shenpiDialog: false,
      shoujuDialog: false,
      FinancialDate: "",
      financialId: "",
      caiwuList1: [],
      caiwuList2: [],
      jingjirenDialogLoading: false,
      jingjirenDialogTotal: 0,
      userCurrentPage: 1,
      userPageSize: 10,
      jingjirenDialogList: [],
      userName2: "请选择签约人",
      userName1: "",
      createDate: ["", ""],
      stateOptions1: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "买卖"
        },
        {
          value: "2",
          label: "租赁"
        }
      ],
      stateOptions2: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "房款"
        },
        {
          value: "2",
          label: "服务费"
        },
        {
          value: "3",
          label: "定金"
        }
      ],
      search: {
        FinancialType: "", //财务类型 （必填）
        MoneyType: "", //资金类型
        YingShouYingFuAmount: "", //应收款
        ShiShouShiFuAmount: "", //已收款
        ContractNo: "", //合同编号
        ShopId: "", //门店id
        UserId: "", //经纪人id
        DateFrom: "", //入账起始时间
        DateTo: "", //入账截止时间
        tradeType: "", //交易类型
        CurrentPage: 1,
        PageSize: 10
      },
      titleName: "",
      labelName: "",
      CaiWuParams: JSON.parse(window.localStorage.getItem("CaiWuParams")),
      htmlTitle: "收据"
    };
  },
  created() {
    this.label = "入账管理";
    this.titleName = "入账审批";
    this.labelName = "款项入账时间：";
    this.search.FinancialType = "0";
    if (this.CaiWuParams) {
      this.search.CurrentPage = this.CaiWuParams.CurrentPage;
      this.search.PageSize = this.CaiWuParams.PageSize;
    }
    this.getFinancialList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.label = tab.label;
      console.log(this.label);
      if (this.label == "入账管理") {
        // this.reload()
        this.titleName = "入账审批";
        this.labelName = "款项入账时间：";
        this.search.FinancialType = "0";
      } else if (this.label == "付款管理") {
        this.search.FinancialType = "1";
        this.titleName = "付款审批";
        this.labelName = "款项出账时间：";
      }
      // this.getFinancialList();
      this.reset();
    },
    jumpToInsert() {
      this.$router.push({
        name: "YingShouDetail",
        query: {
          num: 0,
          state: 1,
          label: this.label
        }
      });
    },
    openDianpuDialog() {
      this.dianpuDialog = true;
    },
    colseDianPuDialog(dianpuData) {
      this.ShopName = dianpuData.shopName;
      this.search.ShopId = dianpuData.shopId;
      this.dianpuDialog = false;
      // console.log("aabbcc", this.ShopName,this.search.ShopId)
    },
    clickAgent() {
      this.jingjirenDialog = true;
      this.getUserList();
    },
    handleSizeChange(newSize) {
      this.search.PageSize = newSize;
      this.getFinancialList();
    },
    handleCurrentChange(newPage) {
      this.search.CurrentPage = newPage;
      this.getFinancialList();
    },
    searchList() {
      if (this.stateValue2 == "0") {
        this.search.MoneyType = "";
      } else if (this.stateValue2 == "1") {
        this.search.MoneyType = "房款";
      } else if (this.stateValue2 == "2") {
        this.search.MoneyType = "代理费";
      } else {
        this.search.MoneyType = "定金";
      }
      // if (this.stateValue1 == "0") {
      //     this.search.tradeType = ""
      // } else if (this.stateValue1 == "1"){
      //     this.search.tradeType = "买卖"
      // } else {
      //     this.search.tradeType = "租赁"
      // }
      this.search.DateFrom = this.createDate[0];
      this.search.DateTo = this.createDate[1];
      this.getFinancialList();
    },
    /* 监听页码值改变的事件 */
    jingjirenDialogCurrentChange(newPage) {
      this.userCurrentPage = newPage;
      this.getUserList();
    },
    jingjirenDialogSizeChange(newSize) {
      this.userPageSize = newSize;
      this.getUserList();
    },
    //重置经纪人
    ResetWeihuren() {
      this.userName1 = "";
      this.userName2 = "请选择签约人";
      this.getUserList();
    },
    jingjirenDialogDetails(row) {
      console.log(row);
      let that = this;
      that.userName1 = row.userName;
      that.search.UserId = row.userId;
      that.userName2 = "";
      that.jingjirenDialog = false;
    },
    async getFinancialList() {
      this.loading = true;
      const param = {
        CurrentPage: this.search.CurrentPage,
        PageSize: this.search.PageSize
      };
      localStorage.setItem("CaiWuParams", JSON.stringify(param));
      await this.$http
        .get(this.$url.GetFinancialList, {
          params: this.search
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            if (res.data.dtList.length > 0) {
              res.data.dtList.map(item => {
                item.isConfirmed = item.isConfirmed == "1" ? "是" : "否";
                item.isFuiouReceived =
                  item.isFuiouReceived == "1" ? "是" : "否";
                item.financialDate =
                  item.financialDate.slice(0, 4) == "1970"
                    ? "待确认"
                    : item.financialDate;
                item.financialDate =
                  item.financialDate.slice(0, 4) == "0001"
                    ? "待确认"
                    : item.financialDate;
              });
              if (this.label == "入账管理") {
                this.caiwuList2 = [];
                this.caiwuList1 = res.data.dtList;
              } else if (this.label == "付款管理") {
                this.caiwuList1 = [];
                this.caiwuList2 = res.data.dtList;
              }
            } else {
              this.caiwuList1 = [];
              this.caiwuList2 = [];
            }
            this.loading = false;
            this.total = res.data.maxRecords;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    getUserList() {
      let that = this;
      that.jingjirenDialogLoading = true;
      that.$http
        .get(that.$url.GetJingJiRenList, {
          params: {
            isHomePage: "0",
            userName: that.userName1,
            // merchantId: localStorage.getItem("merchantId"),
            currentPage: encodeURIComponent(that.userCurrentPage),
            pageSize: encodeURIComponent(that.userPageSize)
          }
        })
        .then(function(res) {
          if (res.data.msgCode == 1) {
            that.jingjirenDialogLoading = false;
            that.jingjirenDialogList = res.data.dtList;
            that.loading = false;
            that.total = res.data.maxRecords;
            for (var i = 0; i < res.data.dtList.length; i++) {
              if (res.data.dtList[i].gender === "1") {
                that.jingjirenDialogList[i].gender = "男";
              } else if (res.data.dtList[i].gender === "2") {
                that.jingjirenDialogList[i].gender = "女";
              }
            }
            that.jingjirenDialogTotal = res.data.maxRecords;
          } else {
            that.jingjirenDialogLoading = false;
            var message = that.$commonJs.getUrlError(res.data.message);
            this.$message.error(message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    shenpi(row) {
      this.shenpiDialog = true;
      this.financialId = row.financialId;
    },
    fkshenpi(row) {
      this.shenpiDialog = true;
      this.financialId = row.financialId;
    },
    // 收据
    shouju(row) {
      //   this.shoujuDialog;
      this.$router.push({
        name: "shoujuList",
        query: {
          financialId: row.financialId
        }
      });
    },
    //入账审批
    rzsubmit() {
      this.FinancialDate = this.$commonJs.dateTimeFormatter(this.FinancialDate);
      let param = {
        financialId: this.financialId,
        FinancialDate: this.FinancialDate
      };
      this.$http
        .post(this.$url.ConfirmFinancial, param, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.$message.success("审批成功");
            this.reload();
          } else {
            this.$message.error("审批失败");
          }
        });
    },
    //付款审批
    fksubmit() {
      this.FinancialDate = this.$commonJs.dateTimeFormatter(this.FinancialDate);
      let param = {
        financialId: this.financialId,
        FinancialDate: this.FinancialDate
      };
      this.$http
        .post(this.$url.ConfirmFinancial, param, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.$message.success("审批成功");
            this.shenpiDialog = false;
            this.reset();
          } else {
            this.$message.error("审批失败");
          }
        });
    },
    reset() {
      if (this.label == "入账管理") {
        this.search = {
          FinancialType: "0", //财务类型 （必填）
          MoneyType: "", //资金类型
          YingShouYingFuAmount: "", //应收款
          ShiShouShiFuAmount: "", //已收款
          ContractNo: "", //合同编号
          ShopId: "", //门店id
          UserId: "", //经纪人id
          DateFrom: "", //入账起始时间
          DateTo: "", //入账截止时间
          CurrentPage: 1,
          PageSize: 10
        };
      } else if (this.label == "付款管理") {
        this.search = {
          FinancialType: "1", //财务类型 （必填）
          MoneyType: "", //资金类型
          YingShouYingFuAmount: "", //应收款
          ShiShouShiFuAmount: "", //已收款
          ContractNo: "", //合同编号
          ShopId: "", //门店id
          UserId: "", //经纪人id
          DateFrom: "", //入账起始时间
          DateTo: "", //入账截止时间
          CurrentPage: 1,
          PageSize: 10
        };
      }
      (this.ShopName = "请选择签约店组"),
        (this.userName2 = "请选择签约人"),
        (this.userName1 = ""),
        (this.stateValue1 = "0"),
        (this.stateValue2 = "0"),
        (this.createDate = ["", ""]),
        this.getFinancialList();
    },
    xiangqing(index, row) {
      this.$router.push({
        name: "YingShouDetail",
        query: {
          num: 1,
          state: 2,
          label: this.label,
          financialId: row.financialId,
          financialType: this.search.FinancialType
        }
      });
    }
  }
};
</script>
<style scope lang="less">
.caiwu .titleLabel {
  width: 25% !important;
}
.el-table .cell {
  white-space: pre-line;
}
// @media screen and (max-width: 1300px) {
//   .search{
//     .media-width {
//       margin-left: 15px;
//       width: 75%;
//     }
//     .media-datepickerWidth{
//       margin-left: 15px;
//       width: 87.5% !important;
//     }
//   }
// }
// @media screen and (min-width: 1300px) {
//   .search{
//     .media-width {
//       margin-left: 0px;
//       width: 63%;
//     }
//     .media-datepickerWidth{
//       margin-left: 0px;
//       width: 81.5% !important;
//     }
//   }
// }
// @media screen and (min-width: 1400px) {
//   .search{
//     .media-width {
//       width: 66%;
//     }
//     .media-datepickerWidth{
//       width: 83% !important;
//     }
//   }
// }
// @media screen and (min-width: 1600px) {
//   .search{
//     .media-width {
//       width: 70%;
//     }
//     .media-datepickerWidth{
//       width: 85% !important;
//     }
//   }
// }
// .search{
//   .media-datepickerWidth .el-range__icon,.media-datepickerWidth .el-range-separator,.el-date-editor .el-range__close-icon{
//     line-height: 24px !important;
//   }
//   .media-datepickerWidth .el-range-input{
//     height: 28px !important;
//   }
//   .search-button{
//     margin-top: 5px;
//   }
// }
</style>
