<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span class="title">商户：</span>
                        <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetMerchantList">查询</el-button>
                            <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px" v-if="!isQianyueOpen">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                        <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable" @row-click="xzShangHu"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="商户">
                        <template slot-scope="scope">
                            <el-button v-if="isQianyueOpen" class="tableButSpan" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.merchantName }}</el-button>
                            <el-button v-else class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.merchantName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>

                    <el-table-column label="操作" v-if="!isQianyueOpen">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            drow: "",
            loading: false,
            merchantName: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ]
        }
    },
    props: {
        isQianyueOpen: {
            //签约打开
            type: Boolean,
            default: false
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getMerchantList()
    },
    methods: {
        xzShangHu(row) {
            if (this.isQianyueOpen) {
                this.$emit("colseShangHuDialog", row)
            }
        },
        // 查询
        cgetMerchantList() {
            this.currentPage = 1
            this.getMerchantList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "ShangHuGuanLiDetail",
                query: {
                    merchantId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "ShangHuGuanLiDetail",
                query: {
                    merchantId: row.merchantId,
                    num: 1,
                    state: 2
                }
            })
        },
        chengjiao(index) {
            var data1 = [index.merchantId]
            this.$confirm("此操作将提交审核, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$router.push({
                        name: "ChengJiaoGaiLanDetail",
                        query: {
                            merchantId: 0,
                            num: 0,
                            state: 1
                        }
                    })
                })
                .catch((err) => err)
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    console.log(res.dtList[i])
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getMerchantList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getMerchantList()
        },
        //重置
        cancelMerchantList() {
            this.merchantName = ""
            this.enabled = ""
            this.currentPage = 1
            this.getMerchantList()
        },

        // 删除数据
        handleDelete(index) {
            var data1 = [index.merchantId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteMerchant, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getMerchantList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => err)
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                }).catch((err) => err)
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].merchantId)
                        }
                        const { data: res } = this.$http
                            .delete(this.$url.DeleteMerchant, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.getMerchantList()
                                    // this.reload()
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    })
                    .catch((err) => err)
            }
        },
        xiangqing(index, row) {
            this.drow = row.merchantId
            this.$router.push({
                name: "ShangHuGuanLiDetail",
                query: {
                    merchantId: this.drow,
                    num: 2,
                    state: 3
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
