<template>
  <div>
    <el-card style="margin-top: 6px !important;">
      <el-tabs v-model="tabPane" @tab-click="handleClickTab" class="qiugou-tabs"
        style="position: relative;top: 0px;background-color: #fff;">
        <el-tab-pane name="可分配房源" label="可分配房源">
          <el-card style="margin: 0 !important;border: none;"> </el-card>
        </el-tab-pane>
        <el-tab-pane name="已分配房源" label="已分配房源">
          <el-card style="margin: 0 !important;border: none;"> </el-card>
        </el-tab-pane>
      </el-tabs>
      <el-row v-if="tabPane == '可分配房源'" style="margin-top:10px;">
        <el-col>
          <el-col :span="6">
            <span style="font-size: 14px;">楼盘名称或楼盘编号：</span>
            <el-input placeholder="请输入楼盘名称或楼盘编号" style="width:200px;" maxlength="12" v-model="louPanNameOrNo"></el-input>
          </el-col>
          <el-col :span="5">
            <span style="font-size: 14px;">房源编号：</span>
            <el-input placeholder="请输入房源编号" style="width:200px;" maxlength="8" v-model="houseNo"></el-input>
          </el-col>
          <el-col :span="5">
            <span style="font-size: 14px">维护人姓名：</span>
            <el-input placeholder="请输入维护人姓名" style="width: 200px" maxlength="12" v-model="maintenanceUserName"></el-input>
          </el-col>
          <span style="float: right;">
            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="chaxun">查询</el-button>
            <el-button size="mini" @click="reset" type="warning">重置</el-button>
          </span>
        </el-col>
      </el-row>
      <el-table ref="multipleTable" v-loading="loading" :data="CustomerList" tooltip-effect="dark" :header-cell-style="{
        'text-align': 'center',
        color: '#333',
        'font-size': '14px'
      }" :cell-style="{ 'text-align': 'center' }" border stripe>
        <el-table-column label="房源图片" prop="shiKanInfo" width="138">
          <template slot-scope="scope">
            <div v-if="scope.row.oneKeting != null && scope.row.oneKeting != ''"
              style="max-height: 100px;overflow: hidden;">
              <el-image style="width: 100%;height: 4.6vw;position: relative;" :src="scope.row.oneKeting"
                :preview-src-list="[scope.row.oneKeting]" :key="scope.row.oneKeting">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div v-else>
              <img src="../../../public/img/zanwutupian.jpg" style="width: 100%;height:4.6vw" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="房源名称" width="170px;" prop="louPanName">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button style="width: 92%;" @click="jumpToDetail(scope.row)" type="text" size="mini">
                <div class="tableButSpan" style="font-size:14px;color:#000;text-align:center; display:inline-block;">
                  <span @click="tofangyudetail(scope.row)" style="">{{
                    scope.row.louPanName
                  }}</span><br />
                  <span style="color:red">{{ scope.row.houseNo }}</span>
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="户型" prop="huXingId"></el-table-column>
        <el-table-column label="面积" prop="square">
          <template slot-scope="scope"> {{ scope.row.square }}平 </template>
        </el-table-column>
        <!-- <el-table-column label="租售类型" prop="isHouseToSell"></el-table-column>
                <el-table-column label="总价" prop="price" width="200"></el-table-column>
                <el-table-column label="楼层" prop="floor"></el-table-column> -->
        <el-table-column label="朝向" prop="orientations"></el-table-column>
        <el-table-column label="原维护人" prop="maintenanceUserName" v-if="tabPane == '可分配房源'"></el-table-column>

        <el-table-column label="原录入人" prop="createPersonName"></el-table-column>
        <el-table-column label="已分配角色" prop="remark" v-if="tabPane != '可分配房源'" :key="1"></el-table-column>
        <el-table-column label="被分配经纪人" prop="userName" v-if="tabPane != '可分配房源'" :key="2"></el-table-column>
        <el-table-column label="分配时间" prop="createTime" v-if="tabPane != '可分配房源'" :key="3" width="200"></el-table-column>

        <el-table-column label="操作" width="165px;" v-if="tabPane == '可分配房源'">
          <template class="right" slot-scope="scope">
            <el-button type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff"
              @click="OpenFenPei(scope.row)">分配</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="分配状态" width="165px;" style="text-align: left;" v-if="tabPane == '已分配房源'" prop="xiangXiXuQiu"></el-table-column> -->
        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
          <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
          <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
        </div>
      </el-table>

      <el-dialog title="待分配角色" :visible.sync="FenPeiVisible" width="50%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="分配角色:" prop="radio" label-width="25%" style="height: 50px;">
            <div>
              <el-checkbox-group v-model="ruleForm.radio">
                <el-checkbox class="duiqi" label="create">录入人：<br />{{ radioLuRu }}</el-checkbox>
                <el-checkbox class="duiqi" label="maintenance">维护人：<br />{{ radioWeiHu }}</el-checkbox>
                <el-checkbox class="duiqi" label="shiKan">实勘人：<br />{{ radioShiKan }}</el-checkbox>
                <el-checkbox class="duiqi" label="key">钥匙人：<br />{{ radioKey }}</el-checkbox>
                <el-checkbox class="duiqi" label="certificates">证件人：<br />{{ radioZhengJian }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="指定经纪人:" prop="userName" label-width="25%" style="margin-top:0px">
            <el-button @click="JingJiRenVisible = true" style="width: 84%;height: 32px;text-align:left;line-height:0px">
              <span :style="{
                color:
                  ruleForm.userName == '请选择指定经纪人' ? '#c0c4cc' : '#666'
              }">{{ ruleForm.userName }}</span>
            </el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" @click="deleteRole" style="margin-top:10px">删 除</el-button>
          <el-button size="mini" @click="cancel">取 消</el-button>
          <el-button size="mini" type="primary" @click="addRole" style="margin-right: 83px;margin-top:10px">确
            定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="指定经纪人" :visible.sync="JingJiRenVisible" width="70%" :before-close="handleClose1">
        <TongXunLuList :isFenPei="true" :isfencheng='true' @colseJingJiRenDialog="isJingJiRenData"></TongXunLuList>
        <span slot="footer" class="dialog-footer">
          <el-button @click="JingJiRenVisible = false">取 消</el-button>
          <el-button type="primary" @click="JingJiRenVisible = false">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import TongXunLuList from "../TongXunLu/TongXunLuList.vue";
export default {
  components: {
    TongXunLuList
  },
  name: "GetQiuzuQiugouList",
  inject: ["reload"],

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      state: this.$route.query.state,
      tabPane: "可分配房源",
      isFeiPei: "",
      loading: false,
      currentPage: 1,
      pageSize: 10,
      louPanNameOrNo: "",
      houseNo: "",
      maintenanceUserName: "",
      total: 0,
      fangyuanTotal: 0,
      CustomerList: [], //客户列表,
      CustomerListHistory: [],
      chaoxiangList: [],
      chaoxiangList1: [],
      FenPeiVisible: false,
      radio: [],
      radioLuRu: "",
      radioWeiHu: "",
      radioShiKan: "",
      radioKey: "",
      radioZhengJian: "",
      ruleForm: {
        radio: [],
        userName: "请选择指定经纪人"
      },
      rules: {
        radio: [
          {
            required: true,
            message: "请选择分配角色",
            trigger: "blur"
          }
        ],
        userName: [
          {
            required: true,
            message: "请选择指定经纪人",
            trigger: "blur"
          }
        ]
      },
      JingJiRenVisible: false,
      JingJiRenData: "",
      HouseData: "",
      editableTabs: []
    };
  },
  created() {
    /* 获取所有的日志列表 */
    if (this.state == "1") {
      this.isFeiPei = "1";
      this.tabPane = "可分配房源";
    } else if (this.state == "0") {
      this.isFeiPei = "0";
      this.tabPane = "已分配房源";
    }
    if (this.fangyuanState == "1") {
      this.isFeiPei = "1";
      this.tabPane = "可分配房源";
    } else if (this.fangyuanState == "0") {
      this.isFeiPei = "0";
      this.tabPane = "已分配房源";
    }
    if (this.tabPane == "可分配房源") {
      this.getFenPeiList();
    }
    this.editableTabs = window.localStorage.getItem("editableTabs");
    if (this.editableTabs) {
      this.editableTabs = JSON.parse(this.editableTabs);
      this.editableTabs.forEach(item => {
        if (this.activeIndex == item.path) {
          this.activeIndex = this.activeIndex;
          this.editableTabsValue = item.name.toString();
        }
      });
    }
  },
  methods: {
    tofangyudetail(row) {
      var that = this;
      if (row.isHouseToSell == "出租") {
        that.addTab(
          row.louPanName,
          "ChuZuDetail?louPanId=" +
          row.loupanId +
          "&houseId=" +
          row.houseId +
          "&canMerchantEdit=" +
          row.canMerchantEdit +
          "&canShopEdit=" +
          row.canShopEdit +
          "&num=1&state=2&=isConfirmed=" +
          row.isConfirmed
        );
        this.$router.push({
          name: "ChuZuDetail",
          query: {
            louPanId: row.loupanId,
            enabled: row.enabled,
            houseId: row.houseId,
            canMerchantEdit: row.canMerchantEdit,
            canShopEdit: row.canShopEdit,
            num: 1,
            state: 2,
            isConfirmed: row.isConfirmed,
            louCeng: row.louCengDuan,
            Ftype: "fenpei"
          }
        });
      } else {
        this.addTab(
          row.louPanName,
          "ChuShouDetail?louPanId=" +
          row.loupanId +
          "&houseId=" +
          row.houseId +
          "&canMerchantEdit=" +
          row.canMerchantEdit +
          "&canShopEdit=" +
          row.canShopEdit +
          "&num=1&state=2&=isConfirmed=" +
          row.isConfirmed +
          "&louCeng=" +
          row.louCengDuan
        );
        var JiShou;
        if (row.isOnUrgentSale == "1") {
          this.JiShou = true;
        } else {
          this.JiShou = false;
        }
        this.$router.push({
          name: "ChuShouDetail",
          query: {
            isOnUrgentSale: this.JiShou,
            louPanId: row.loupanId,
            enabled: row.enabled,
            houseId: row.houseId,
            canMerchantEdit: row.canMerchantEdit,
            canShopEdit: row.canShopEdit,
            num: 1,
            state: 2,
            isConfirmed: row.isConfirmed,
            louCeng: row.louCengDuan,
            Ftype: "fenpei"
          }
        });
      }
    },
    chaxun() {
      this.currentPage = 1;
      this.getFenPeiList();
    },
    async getFenPeiList() {
      const data = {
        louPanNameOrNo: this.louPanNameOrNo,
        houseNo: this.houseNo,
        maintenanceUserName: this.maintenanceUserName,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      };
      const { data: res } = await this.$http.get(this.$url.GetAssignmentList, {
        params: data
      });
      if (res.msgCode == 1) {
        let that = this;
        this.CustomerList = res.result;
        this.total = res.maxRecords;
        for (let i = 0; i < res.result.length; i++) {
          that.chaoxiangList = [];
          let list = res.result[i].orientations.split("");
          list.forEach(function (item, i) {
            if (i == 0 && item == "1") {
              that.chaoxiangList.push("东");
            }
            if (i == 1 && item == "1") {
              that.chaoxiangList.push("东南");
            }
            if (i == 2 && item == "1") {
              that.chaoxiangList.push("南");
            }
            if (i == 3 && item == "1") {
              that.chaoxiangList.push("西南");
            }
            if (i == 4 && item == "1") {
              that.chaoxiangList.push("西");
            }
            if (i == 5 && item == "1") {
              that.chaoxiangList.push("西北");
            }
            if (i == 6 && item == "1") {
              that.chaoxiangList.push("北");
            }
            if (i == 7 && item == "1") {
              that.chaoxiangList.push("东北");
            }
          });
          res.result[i].orientations = that.chaoxiangList.join("/");
          that.chaoxiangList = [];
          res.result[i].oneKeting = "";
          if (
            res.result[i].shiKanInfo != null &&
            JSON.parse(res.result[i].shiKanInfo).keting != ""
          ) {
            if (
              JSON.parse(res.result[i].shiKanInfo).keting.split(",")[0] != null
            ) {
              // if (res.result[i].isHouseToSell == 1) {
              //   res.result[i].oneKeting =
              //     that.$url.houses +
              //     JSON.parse(res.result[i].shiKanInfo).keting.split(",")[0];
              // } else {
              res.result[i].oneKeting =
                that.$url.houses +
                JSON.parse(res.result[i].shiKanInfo).keting.split(",")[0];
              // }
            }
          }
        }
        // this.$message.success("成功")
      }
    },
    async getHistoryList() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      };
      const { data: res } = await this.$http.get(
        this.$url.GetAssignmentHistory,
        {
          params: data
        }
      );
      if (res.msgCode == 1) {
        let that = this;
        this.CustomerList = res.dtList;
        this.total = res.maxRecords;
        for (let i = 0; i < res.dtList.length; i++) {
          that.chaoxiangList1 = [];
          let list = res.dtList[i].orientations.split("");
          list.forEach(function (item, i) {
            if (i == 0 && item == "1") {
              that.chaoxiangList1.push("东");
            }
            if (i == 1 && item == "1") {
              that.chaoxiangList1.push("东南");
            }
            if (i == 2 && item == "1") {
              that.chaoxiangList1.push("南");
            }
            if (i == 3 && item == "1") {
              that.chaoxiangList1.push("西南");
            }
            if (i == 4 && item == "1") {
              that.chaoxiangList1.push("西");
            }
            if (i == 5 && item == "1") {
              that.chaoxiangList1.push("西北");
            }
            if (i == 6 && item == "1") {
              that.chaoxiangList1.push("北");
            }
            if (i == 7 && item == "1") {
              that.chaoxiangList1.push("东北");
            }
          });
          res.dtList[i].orientations = that.chaoxiangList1.join("/");
          that.chaoxiangList1 = [];
          res.dtList[i].oneKeting = "";
          if (
            res.dtList[i].shiKanInfo != null &&
            JSON.parse(res.dtList[i].shiKanInfo).keting != ""
          ) {
            if (
              JSON.parse(res.dtList[i].shiKanInfo).keting.split(",")[0] != null
            ) {
              // if (res.dtList[i].isHouseToSell == 1) {
              //   res.dtList[i].oneKeting =
              //     that.$url.houses +
              //     JSON.parse(res.dtList[i].shiKanInfo).keting.split(",")[0];
              // } else {
              res.dtList[i].oneKeting =
                that.$url.houses +
                JSON.parse(res.dtList[i].shiKanInfo).keting.split(",")[0];
              // }
            }
          }
        }
        console.log(res);
      }
    },
    handleClickTab(tab, event) {
      this.tabPane = tab.label;
      if (tab.label == "可分配房源") {
        this.isFeiPei = "1";
        this.getFenPeiList();
      } else if (tab.label == "已分配房源") {
        this.isFeiPei = "0";
        this.currentPage = 1;
        this.getHistoryList();
      }
      // this.GetCustomerList()
    },
    jumpToDetail(row) {
      // this.$router.push({
      //     name: "KeYuanDetail",
      //     query: {
      //         customerId: row.customerId,
      //         num: 1,
      //         state: this.isFeiPei
      //     }
      // })
    },
    /* 获取列表 */
    async GetCustomerList() {
      let that = this;
      that.loading = true;
      that.search.currentPage = that.currentPage;
      that.search.pageSize = that.pageSize;
      that.$http.post(that.$url.GetCustomerList, data).then(res => {
        that.loading = false;
        if (res.data.msgCode == 1) {
          res.data.dtList.forEach(element => {
            if (element.customerScore == "A") {
              element.customerScore = "一星";
            } else if (element.customerScore == "B") {
              element.customerScore = "二星";
            } else if (element.customerScore == "C") {
              element.customerScore = "三星";
            }
          });
          that.CustomerList = res.data.dtList;
        } else {
          that.$message.error(res.message);
        }
      });
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      if (this.tabPane == "可分配房源") {
        this.getFenPeiList();
      } else {
        this.getHistoryList();
      }
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      if (this.tabPane == "可分配房源") {
        this.getFenPeiList();
      } else {
        this.getHistoryList();
      }
    },
    OpenFenPei(row) {
      this.HouseData = row;
      this.FenPeiVisible = true;
      this.radioLuRu = this.HouseData.createPersonName;
      this.radioWeiHu = this.HouseData.maintenanceUserName;
      this.radioShiKan = this.HouseData.shiKanUserName;
      this.radioKey = this.HouseData.keyUserName;
      this.radioZhengJian = this.HouseData.certificatesUserName;
    },
    cancel() {
      this.ruleForm.radio = [];
      this.FenPeiVisible = false;
      this.radioLuRu = "";
      this.radioWeiHu = "";
      this.radioShiKan = "";
      this.radioKey = "";
      this.radioZhengJian = "";
      this.ruleForm.userName = "";
    },
    reset() {
      this.louPanNameOrNo = "";
      this.houseNo = "";
      this.maintenanceUserName = "";
      this.getFenPeiList();
    },
    handleClose() {
      this.cancel();
      this.FenPeiVisible = false;
    },
    handleClose1() {
      this.JingJiRenVisible = false;
    },
    addRole() {
      console.log(this.ruleForm.radio.toString());
      console.log(this.ruleForm.userName);
      console.log(this.HouseData.houseId);
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.ruleForm.userName == "请选择指定经纪人") {
            this.$message.error("请选择指定经纪人！");
            return;
          }
          const data = {
            houseId: this.HouseData.houseId,
            HouseRole: this.ruleForm.radio.toString(),
            userId: this.JingJiRenData.userId
          };
          const { data: res } = await this.$http.put(
            this.$url.UpdateHouseAssignment,
            data
          );
          if (res.msgCode == 1) {
            this.$message.success("分配成功");
            this.getFenPeiList();
          } else {
            this.$message.error(res.result);
          }
          this.FenPeiVisible = false;
          this.ruleForm.radio = [];
          this.radioLuRu = "";
          this.radioWeiHu = "";
          this.radioShiKan = "";
          this.radioKey = "";
          this.radioZhengJian = "";
          this.ruleForm.userName = "";
        }
      });
    },
    deleteRole() {
      console.log(this.ruleForm.radio.toString());
      console.log(this.ruleForm.userName);
      console.log(this.HouseData.houseId);
      //   this.$refs.ruleForm.validate(async valid => {
      // if (valid) {
      //   if (this.ruleForm.userName == "请选择指定经纪人") {
      //     this.$message.error("请选择指定经纪人！");
      //     return;
      //   }
      const data = {
        houseId: this.HouseData.houseId,
        HouseRole: this.ruleForm.radio.toString(),
        userId: ""
      };
      this.$http.put(this.$url.UpdateHouseAssignment, data).then(res => {
        if (res.data.msgCode == 1) {
          this.$message.success("删除成功");
          this.FenPeiVisible = false;
          this.ruleForm.radio = [];
          this.radioLuRu = "";
          this.radioWeiHu = "";
          this.radioShiKan = "";
          this.radioKey = "";
          this.radioZhengJian = "";
          this.ruleForm.userName = "";
          this.getFenPeiList();
        } else {
          this.$message.error(res.data.message);
        }
      });
      //   const { data: res } = await this.$http.put(
      //     this.$url.UpdateHouseAssignment,
      //     data
      //   );
      //   if (res.msgCode == 1) {
      //     this.$message.success("删除成功");
      //     this.getFenPeiList();
      //   } else {
      //     this.$message.error(res.result);
      //   }

      // }
      //   });
    },
    isJingJiRenData(row) {
      console.log(row);
      this.JingJiRenData = row;
      this.ruleForm.userName = row.userName;
      this.JingJiRenVisible = false;
    },
    // 点击导航栏新增tab页
    addTab(targetName, path) {
      let flag = true; //判断是否需要新增页面
      var tabs = this.editableTabs; //活跃当前tab数组
      for (var i = 0; i < tabs.length; i++) {
        //如果存在相同的tab页 不新增tab页
        if (tabs[i].path === path) {
          this.editableTabsValue = tabs[i].name.toString(); //定位到已打开页面
          this.$router.push(path);
          flag = false;
        }
      }
      // 需要新增页面
      if (flag) {
        let newTabName = this.editableTabs.length.toString();
        this.editableTabs.push({
          title: targetName,
          name: newTabName,
          path: path
        });
        this.editableTabsValue = newTabName;
        window.localStorage.setItem(
          "editableTabs",
          JSON.stringify(this.editableTabs)
        );
      }
      this.reload();
      window.localStorage.setItem("activeIndex", path);
    }
  }
};
</script>

<style lang="less" scoped>
.qiugou-tabs {
  font-size: 14px;

  /deep/ .el-tabs__nav>.is-active {
    background: transparent !important;
    color: #df0202 !important;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #df0202;
  }

  /deep/ .el-tabs__header {
    margin: 0px;
  }

  /deep/ .el-tabs__active-bar,
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }

  /deep/ .el-tabs__content {
    height: 0 !important;
  }

  /deep/ .el-tabs__item:hover {
    background-color: transparent;
  }
}

/deep/ .el-dialog__body {
  padding: 0 20px !important;
}

.duiqi {
  vertical-align: top;
}

/deep/ .el-checkbox__input {
  vertical-align: top !important;
  margin-top: 2px;
}

/deep/ .el-checkbox__label {
  vertical-align: top !important;
}
</style>
