<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col :span="5" style="margin: 20px 0;">
                        <span style="font-size: 14px;">经纪人姓名：</span>
                        <el-input v-model="userName" placeholder="请输入经纪人姓名" class="media-width"></el-input>
                    </el-col>
                    <el-col :span="5" style="margin: 20px 0;">
                        <span style="font-size: 14px; margin-left: 15px;">工资月份：</span>
                        <el-date-picker v-model="yyyyMM" value-format="yyyy-MM" type="month" placeholder="请选择工资月份" class="media-width" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="5" style="margin: 20px 0;" v-if="roleType == 6">
                        <span style="font-size: 14px; margin-left: 15px;">商户：</span>
                        <el-input @focus="merchantDialogTrue" placeholder="请选择商户" v-model="chickMerchantName" class="media-width"></el-input>
                    </el-col>
                    <el-col :span="5" style="margin: 20px 0;" v-if="roleType == 6 || roleType == 5 || roleType == 3 || roleType == 4">
                        <span style=" font-size: 14px;margin-left: 15px;">店铺：</span>
                        <el-input @focus="shopDialogTrue" placeholder="请选择店铺" readonly="readonly" v-model="chickShopName" class="media-width"></el-input>
                    </el-col>
                    <el-col :span="4" id="col" style="margin-top: 20px;">
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetArticleList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top:10px;">
                    <el-col :span="16">
                        <el-button size="mini" @click="moremoney()" type="warning" icon="el-icon-s-check" style="border: none">批量生成月工资</el-button>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">手动新增</el-button>
                        <el-button size="mini" @click="deleteGZ1" type="primary" icon="el-icon-delete" style="border: none">批量删除</el-button>
                    </el-col>
                    <el-col :span="8" style="text-align: right;">
                        <el-button size="mini" @click="ExportExcel()" type="primary" icon="el-icon-download" style="border: none">导出excel</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="工资月份" prop="yyyyMM"></el-table-column>
                    <el-table-column label="经纪人" prop="salaryUserName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="xiangqing(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.salaryUserName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="店铺" prop="shopName"></el-table-column>
                    <el-table-column label="基本工资" prop="basicSalary"></el-table-column>
                    <el-table-column label="绩效工资" prop="performanceSalary"></el-table-column>
                    <el-table-column label="个税" prop="personalTax"></el-table-column>
                    <el-table-column label="罚款" prop="penalty"></el-table-column>
                    <el-table-column label="实发工资" prop="salaryAmount"></el-table-column>
                    <el-table-column label="财务是否已经确认" prop="isConfirmed"></el-table-column>
                    <el-table-column width="200px" label="确认日期" prop="confirmDate"></el-table-column>
                    <el-table-column width="190px" label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button v-if="scope.row.isConfirmed!='是'" @click.native.stop="shenhe(scope.row)" type="text" size="mini" style="color: red">审核</el-button>
                            <el-button v-if="scope.row.isConfirmed!='是'" @click.native.stop="xiugai(scope.row)" type="text" size="mini" style="color: red">修改</el-button>
                            <el-button v-if="scope.row.isConfirmed!='是'" @click.native.stop="deleteGZ(scope.row)" type="text" size="mini" style="color: red">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
            <!-- 商户弹出层 -->
            <el-dialog :visible.sync="merchantDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">商户：</span>
                            <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetMerchantList">查询</el-button>
                                <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="MerchantDialogDetails" :data="operationList1" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="商户">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.merchantName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="merchantCurrentPage" :page-sizes="[10, 20, 50]" :page-size="merchantPageSize" layout="total, sizes, prev, pager, next, jumper" :total="merchantDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 店铺弹出层 -->
            <el-dialog :visible.sync="shopDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">店铺：</span>
                            <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetDianPuList">查询</el-button>
                                <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="ShopDialogDetails" :data="operationList2" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="店铺" prop="shopName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.shopName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户" prop="merchantName"></el-table-column>
                    <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="shopCurrentPage" :page-sizes="[10, 20, 50]" :page-size="shopPageSize" layout="total, sizes, prev, pager, next, jumper" :total="shopDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 批量生成月工资 -->
            <el-dialog :visible.sync='moremonerDialog' direction="rtl" width="70%" top="10%" class="dialogCss">
                <el-form :model="ruleform" ref="ruleform" label-width="100px" class="demo-dynamic">
                    <el-row>
                        <el-col :span="8" style="margin: 20px 0;">
                            <span style="margin-left: 15px;">工资月份：</span>
                            <el-date-picker style="width:70%;" v-model="ruleform.yyyyMM" value-format="yyyy-MM" type="month" placeholder="请选择工资月份" :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="8" style="margin: 20px 0;" v-if="roleType == 6">
                            <span style="margin-left: 15px;">商户：</span>
                            <el-input style="width:70%;" @focus="merchantDialogTrue" placeholder="请选择商户" v-model="ruleform.chickMerchantName"></el-input>
                        </el-col>
                        <el-col :span="8" style="margin: 20px 0;" v-if="roleType == 6 || roleType == 5">
                            <span style="margin-left: 15px;">店铺：</span>
                            <el-input style="width:70%;" @focus="shopDialogTrue" placeholder="请选择店铺" v-model="ruleform.chickShopName"></el-input>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleform')">确 定</el-button>
                        <el-button @click="moremonerDialog = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            ruleform: {
                shopId: "",
                yyyyMM: "",
                chickMerchantName: "",
                chickShopName: "",
                merchantId: ""
            },
            // formyyyyMM: "",
            // formchickMerchantName: "",
            // formchickShopName: "",
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            userName: "",
            shopId: "",
            chickShopName: "",
            shopName: "",
            yyyyMM: "",
            time: "",
            roleType: "",
            merchantDialog: false,
            merchantName: "",
            chickMerchantName: "",
            shopDialog: false,
            operationList1: [],
            merchantCurrentPage: 1,
            merchantPageSize: 10,
            merchantDialogTotal: 0,
            operationList2: [],
            shopPageSize: 10,
            shopCurrentPage: 1,
            shopDialog: false,
            shopDialogTotal: 0,
            moremonerDialog: false,
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        if(this.roleType == 3||this.roleType == 4) {
            this.ruleform.shopId = localStorage.getItem("shopId")
        }
        /* 获取列表 */
        this.getArticleList()
        // this.newTime()
    },
    mounted() {
        this.col()
    },
    methods: {
        // 批量生成月工资
        async submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        yyyyMM: this.ruleform.yyyyMM.replace("-", ""),
                        shopId: this.ruleform.shopId
                    }
                    const { data: res } = await this.$http.post(
                        this.$url.GenerateMonthlySalary,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("批量生成月工资成功")
                        this.moremonerDialog = false
                        this.getArticleList()
                    } else {
                        // var message = commonJs.getUrlError(res.message)
                        this.$message.error(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        col() {
            if (this.roleType != 6) {
                document.getElementById("col").style.marginLeft = 340 + "px"
            }
        },
        moremoney() {
            this.moremonerDialog = true
        },
        // 删除
        deleteGZ(row) {
            let that = this
            var data1 = [row.salaryId]
            that.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    that.$http
                        .delete(this.$url.DeleteSalary, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                that.$message.success("删除成功")
                                that.reload()
                            } else {
                                this.$message.error(res.data.message)
                            }
                        })
                })
                .catch((err) => err)
        },
        // 批量删除
        deleteGZ1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].salaryId)
                        }
                        this.$http
                            .delete(this.$url.DeleteSalary, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        // 审核
        shenhe(row) {
            var shenheid = ""
            this.$confirm("是否确定工资信息正确？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    shenheid = row.salaryId
                    let param = {
                        salaryId: shenheid
                    }
                    this.$http
                        .post(this.$url.ConfirmSalary, param, {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                this.$message.success("审核成功")
                                this.reload()
                            } else {
                                this.$message.error("审核失败")
                            }
                        })
                })
                .catch((err) => err)
        },
        //店铺弹出层
        shopDialogTrue() {
            if (this.roleType == 5) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 3) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 4) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 6) {
                if (this.moremonerDialog) {
                    if (
                        this.ruleform.chickMerchantName == "" ||
                        this.ruleform.chickMerchantName == null
                    ) {
                        this.$message.error("请先选择商户")
                    } else {
                        this.shopDialog = true
                        this.getDianPuList()
                    }
                } else {
                    if (
                        this.chickMerchantName == "" ||
                        this.chickMerchantName == null
                    ) {
                        this.$message.error("请先选择商户")
                    } else {
                        this.shopDialog = true
                        this.getDianPuList()
                    }
                }
            }
        },
        // 商户弹出层
        merchantDialogTrue() {
            this.getMerchantList()
            this.merchantDialog = true
        },
        // 商户查询
        cgetMerchantList() {
            this.currentPage = 1
            this.getMerchantList()
        },
        //重置商户搜多条件
        cancelMerchantList() {
            this.merchantName = ""
            this.enabled = ""
            this.merchantCurrentPage = 1
            this.getMerchantList()
        },
        /* 获取商户列表 */
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.merchantCurrentPage,
                pageSize: this.merchantPageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList1 = res.dtList
                this.merchantDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    console.log(res.dtList[i])
                    if (res.dtList[i].enabled === "1") {
                        this.operationList1[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList1[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听商户pagesize改变的事件 */
        handleSizeChange1(newSize) {
            this.merchantPageSize = newSize
            this.getMerchantList()
        },
        /* 监听商户页码值改变的事件 */
        handleCurrentChange1(newPage) {
            this.merchantCurrentPage = newPage
            this.getMerchantList()
        },
        // 选择商户赋值
        MerchantDialogDetails(row) {
            const that = this
            if (that.moremonerDialog) {
                that.ruleform.chickMerchantName = row.merchantName
                that.ruleform.merchantId = row.merchantId
                that.ruleform.chickShopName = ""
                that.ruleform.shopId = ""
            } else {
                that.chickMerchantName = row.merchantName
                that.merchantId = row.merchantId
                that.chickShopName = ""
                that.shopId = ""
            }

            that.merchantDialog = false
        },
        // 查询店铺
        cgetDianPuList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        //重置店铺搜索条件
        cancelDianPuList() {
            this.shopName = ""
            this.enabled = ""
            this.shopCurrentPage = 1
            this.getDianPuList()
        },
        /* 获取店铺列表 */
        async getDianPuList() {
            var merchantId1
            if (this.roleType == 5) {
                if (this.moremonerDialog == true) {
                    merchantId1 = this.ruleform.merchantId
                } else {
                    merchantId1 = this.merchantId
                }
                const data = {
                    areaIds: "", //商圈id
                    shopName: this.shopName,
                    // merchantId: localStorage.getItem("merchantId"),
                    merchantId: merchantId1,
                    currentPage: this.shopCurrentPage,
                    pageSize: this.shopPageSize
                }
                const { data: res } = await this.$http.get(
                    this.$url.GetShopList,
                    {
                        params: data
                    }
                )
                if (res.msgCode == 1) {
                    // this.$message.success("获取店铺成功")
                    this.operationList2 = res.dtList
                    this.shopDialogTotal = res.maxRecords
                    for (var i = 0; i < res.dtList.length; i++) {
                        if (res.dtList[i].enabled === "1") {
                            this.operationList2[i].enabled = "是"
                        }
                        if (res.dtList[i].enabled === "0") {
                            this.operationList2[i].enabled = "否"
                        }
                    }
                } else {
                    this.$message.success(res.message)
                }
            } else {
                if (this.moremonerDialog == true) {
                    merchantId1 = this.ruleform.merchantId
                } else {
                    merchantId1 = this.merchantId
                }
                const data = {
                    areaIds: "", //商圈id
                    shopName: this.shopName,
                    merchantId: merchantId1,
                    currentPage: this.shopCurrentPage,
                    pageSize: this.shopPageSize
                }
                const { data: res } = await this.$http.get(
                    this.$url.GetShopList,
                    {
                        params: data
                    }
                )
                if (res.msgCode == 1) {
                    // this.$message.success("获取店铺成功")
                    this.operationList2 = res.dtList
                    this.shopDialogTotal = res.maxRecords
                    for (var i = 0; i < res.dtList.length; i++) {
                        if (res.dtList[i].enabled === "1") {
                            this.operationList2[i].enabled = "是"
                        }
                        if (res.dtList[i].enabled === "0") {
                            this.operationList2[i].enabled = "否"
                        }
                    }
                } else {
                    this.$message.success(res.message)
                }
            }
        },
        /* 监听店铺pagesize改变的事件 */
        handleSizeChange2(newSize) {
            this.shopPageSize = newSize
            this.getDianPuList()
        },
        /* 监听店铺页码值改变的事件 */
        handleCurrentChange2(newPage) {
            this.shopCurrentPage = newPage
            this.getDianPuList()
        },
        // 选择店铺赋值
        ShopDialogDetails(row) {
            const that = this
            if (that.moremonerDialog) {
                that.ruleform.chickShopName = row.shopName
                that.ruleform.shopId = row.shopId
            } else {
                that.chickShopName = row.shopName
                that.shopId = row.shopId
            }

            that.shopDialog = false
        },

        //导出
        ExportExcel() {
            let that = this
            let filepath
            let fileName
            const data = {
                userName: that.userName,
                shopId: that.shopId,
                yyyyMM: that.yyyyMM.replace("-", "")
            }
            that.$http.post(
                that.$url.ExportSalaryListExcel,
                {
                    params: data
                }
            )
            .then((res)=>{
                if (res.data.msgCode == 1) {
                    filepath =
                        that.$url.JobAccountFileSrc + res.data.fileName
                    fileName = res.fileName
                    console.log(fileName, "11111")
                    let a = document.createElement("a")
                    a.href = filepath
                    a.setAttribute("download", fileName)
                    a.click()
                } else {
                    that.loading = false
                    that.$message.success(res.data.message)
                }
            })
        },
        newTime() {
            var myDate = new Date()
            var dateTo =
                myDate.toLocaleDateString().split("/").join("-") + " 23:59:59"
            var pastMonth = myDate.getMonth() - 2
            var time3de = myDate.setMonth(pastMonth)
            var time3denew = new Date(time3de)

            var dateFROM =
                time3denew.getFullYear() +
                "-" +
                time3denew.getMonth() +
                "-" +
                myDate.getDate()
            this.time = [dateFROM, dateTo]
            this.timeOld = [dateFROM, dateTo]
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        //新增
        jumpToInsert(row) {
            this.$router.push({
                name: "GongZiDetail",
                query: {
                    num: 0
                }
            })
        },
        // 详情
        xiangqing(row) {
            this.$router.push({
                name: "GongZiDetail",
                query: {
                    salaryId: row.salaryId,
                    num: 1
                }
            })
        },
        //修改
        xiugai(row) {
            this.$router.push({
                name: "GongZiDetail",
                query: {
                    salaryId: row.salaryId,
                    num: 2
                }
            })
        },
        /* 获取列表 */
        async getArticleList() {
            const data = {
                userName: this.userName,
                shopId: this.shopId,
                yyyyMM: this.yyyyMM.replace("-", ""),
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetSalaryList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                res.dtList.forEach((element) => {
                    if (element.isConfirmed == "0") {
                        element.isConfirmed = "否"
                    } else {
                        element.isConfirmed = "是"
                    }
                    if (element.confirmDate == "0001-01-01 00:00:00") {
                        element.confirmDate = ""
                    }
                })

                this.operationList = res.dtList

                this.loading = false
                this.total = res.maxRecords
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.userName = ""
            this.shopId = ""
            this.yyyyMM = ""
            this.chickMerchantName = ""
            this.chickShopName = ""
            this.currentPage = 1
            this.getArticleList()
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
