<template>
    <div style="margin-top:-5px">
        <el-backtop></el-backtop>
        <el-row>
            <el-col style="width: 49.5%;">
                <el-card style="height: 400px;">
                    <span class="allView" @click="jumpToGongGaoList">查看全部</span>
                    <el-tabs v-model="activeName1" @tab-click="handleClick" type="card">
                        <el-tab-pane v-for="item in tabs" :key="item.label" :label="item.title" :name="item.name">
                            <span v-for="item in gongGaoList" :key="item.ID">
                                <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                    <span class="commonHover" @click="isShowDetail(item.ID)">{{ item.title }}</span>
                                    <span style="float: right; color: #ccc;height: 20px;line-height: 20px;">{{
                                        item.createTime }}</span>
                                </el-row>
                            </span>
                        </el-tab-pane>
                        <div v-if="this.gongGaoList == '' && (this.activeName1 == 0 || this.activeName1 == 1 || this.activeName1 == 2 || this.activeName1 == 3)"
                            style="width: 100%; display: inline-grid; margin-bottom: 50px;">
                            <img style="width: 200px; margin: 50px auto;" src="../../../public/img/noList.png" />
                            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                        </div>
                    </el-tabs>
                </el-card>
            </el-col>
            <el-col style="float:right;width: 49.5%;">
                <el-card style="height: 400px;">
                    <el-tabs v-model="activeName4" @tab-click="handleClick4" type="card">
                        <el-tab-pane v-for="item in contart4" :key="item.index" :label="item.label" :name="item.name">
                            <div v-show="activeName4 == '0'" style="height: 337px;overflow-y:auto;">
                                <span v-for="item in toDolist" :key="item.ID">
                                    <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                        <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                                            <span class="commonHover" @click="examine(item)"
                                                style="display:inline-block;width:70%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{
                                                    item.title }}</span>
                                        </el-tooltip>
                                        <!-- <span class="commonHover" @click="examine(item)">{{ item.title }}</span> -->
                                        <span
                                            style="float: right; color: #ccc;height: 20px;line-height: 20px;margin-right:1%;width:23%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{
                                                item.createTime }}</span>
                                    </el-row>
                                </span>
                                <div v-if="toDolist == ''" style="width: 100%; display: inline-grid; margin-bottom: 50px;">
                                    <img style="width: 200px; margin: 50px auto;" src="../../../public/img/noList.png" />
                                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                                </div>
                            </div>
                            <div v-show="activeName4 == '1'" style="height: 337px;overflow-y:auto;">
                                <span v-for="item in xiaoxiList" :key="item.ID">
                                    <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                        <el-tooltip class="item" effect="dark" :content="item.messageTitle" placement="top">
                                            <span class="commonHover" @click="toxiaoxi"
                                                style="display:inline-block;width:70%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{
                                                    item.messageTitle }}</span>
                                        </el-tooltip>
                                        <span
                                            style="float: right; color: #ccc;height: 20px;line-height: 20px;margin-right:1%;width:23%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{
                                                item.createTime }}</span>
                                    </el-row>
                                </span>
                                <div v-if="xiaoxiList == ''"
                                    style="width: 100%; display: inline-grid; margin-bottom: 50px;">
                                    <img style="width: 200px; margin: 50px auto;" src="../../../public/img/noList.png" />
                                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <span style="font-size: 18px;font-weight: bolr;margin-top: 5px; ">待办事项</span>
                    <hr style="margin-top:0;margin-bottom: 18px;margin-left: 0;background-color: #E4E7ED;height: 1px;border: none;" />
                    <div>
                        <span v-for="item in toDolist" :key="item.ID">
                            <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                                    <span class="commonHover" @click="examine(item)" style="display:inline-block;width:380px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap">{{ item.title }}</span>
                                </el-tooltip>
                                <span style="float: right; color: #ccc;height: 20px;line-height: 20px;">{{ item.createTime }}</span>
                            </el-row>
                        </span>
                        <div v-if="this.toDolist==''" style="width: 100%; display: inline-grid; margin-bottom: 50px;">
                            <img style="width: 200px; margin: 50px auto;" src="../../../public/img/noList.png" />
                            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                        </div>
                    </div>-->
                </el-card>
            </el-col>
        </el-row>

        <el-row>
            <el-col style="width: 49.5%">
                <el-card style="height: 400px;">
                    <span class="allView" @click="jumpToYeji">查看全部</span>
                    <el-tabs v-model="activeName2" @tab-click="handleClick1" type="card">
                        <el-tab-pane v-for="item in contart" :key="item.index" :label="item.label" :name="item.name">
                            <span v-show="activeName2 == '0'" v-for="item in JingJiRenList" :key="item.userId">

                                <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                    <el-popover placement="top-start" width="250" trigger="hover">
                                        <div>
                                            <div style="font-weight:bold;margin-bottom:10px;margin-left:3px">
                                                {{ item.userName }}</div>
                                            <el-col :span="4">
                                                <!-- <div>
                                                    <el-image style="width: 60px; height: 60px" :src="item.imgUrl"></el-image>
                                                </div> -->
                                                <div class="block">
                                                    <el-avatar :size="50" :src="item.imgUrl"></el-avatar>
                                                </div>
                                            </el-col>
                                            <el-col :span="20"
                                                :style="{ 'margin-top': item.mobile2 == null || item.mobile2 == '' ? '0px' : '-15px', }">
                                                <div style="margin-left:30px">
                                                    <span style="display:inline-block;">性别：<span
                                                            v-if="item.gender == 1">男</span><span v-else>女</span> </span>
                                                    <span
                                                        :style="{ 'margin-top': item.mobile2 == null || item.mobile2 == '' ? '10px' : '5px' }"
                                                        style="display:inline-block;margin-bottom:5px;margin-top:5px">{{
                                                            '联系方式一'
                                                            + ':' + item.mobile1 }}</span>
                                                    <span v-if="item.mobile2 != null && item.mobile2 != ''"
                                                        style="display:inline-block;">{{ '联系方式二' + ':' + item.mobile2
                                                        }}</span>
                                                </div>
                                            </el-col>
                                        </div>
                                        <span class="commonHover" slot="reference">{{ item.userName }}</span>
                                    </el-popover>
                                    <span style="float: right; color: #ccc;height: 20px;line-height: 20px;">{{ item.shopName
                                    }}</span>
                                </el-row>
                            </span>

                            <span v-show="activeName2 == '2'" v-for="item in GenJinList" :key="item.genJinId">
                                <el-row style="height: 20px;line-height: 20px;font-size: 14px;margin-top:5px;">
                                    <el-tooltip class="item" effect="dark" :content="item.genJinInfo" placement="top-start">
                                        <span class="commonHover"
                                            style="display: inline-block;width: 430px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                                            @click="TongXunLuDetail(item)">{{ item.genJinInfo }}</span>
                                    </el-tooltip>

                                    <span style="float: right; color: #ccc;height: 20px;line-height: 20px;">{{
                                        item.createTime }}</span>
                                </el-row>
                            </span>
                        </el-tab-pane>
                        <div v-if="(this.activeName2 == 0 && this.JingJiRenList == '') || (this.activeName2 == 2 && this.GenJinList == '')"
                            style="width: 100%; display: inline-grid; margin-bottom: 50px;">
                            <img style="width: 200px; margin: 50px auto;" src="../../../public/img/noList.png" />
                            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                        </div>
                    </el-tabs>
                </el-card>
            </el-col>
            <el-col style="width: 49.5%;float:right;">
                <el-card style="height: 400px;">
                    <el-tabs type="card" v-model="activeName" @tab-click="tabChange">
                        <el-tab-pane label="本周排行" name="first">
                        </el-tab-pane>
                        <el-tab-pane label="本月排行" name="second">
                            <!-- <p style="font-size:14px;margin-top:0;margin-bottom:0;margin-left:10px;">业绩排行</p>
                            <div style="width: 100%; min-height: 340px;margin-top:-15px" id="chart2" ref="chart2"></div> -->
                        </el-tab-pane>
                        <el-tab-pane label="本季度排行" name="third">
                            <!-- <p style="font-size:14px;margin-top:0;margin-bottom:0;margin-left:10px;">业绩排行</p>
                            <div style="width: 100%; min-height: 340px;margin-top:-15px" id="chart3" ref="chart3"></div> -->
                        </el-tab-pane>
                    </el-tabs>
                    <p style="font-size:14px;margin-top:0;margin-bottom:0;margin-left:10px;">业绩排行</p>
                    <div style="width: 100%; min-height: 340px;margin-top:-15px" id="chart1" ref="chart1"></div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
let Echarts = require("echarts") //基础实例 注意不要使用import
// require("echarts/lib/chart/bar") //按需引入 bar = 柱状图
export default {
    inject: ["reload"],
    data() {
        return {
            activeName4: "0",
            xiaoxiList: [],
            // chart: null,
            chart1: null,
            // chart2: null,
            // chart3: null,
            activeName: "first",
            activeName1: "0",
            activeName2: "0",
            ranking: "1",
            actual: "0",
            reality: "0",
            staffName: "",
            mobile: "",
            commonID: "",
            // 公告,规章制度, 政策文件,培训课程
            gongGaoList: [],
            // 经纪人通讯录
            JingJiRenList: [],
            // 经纪人跟进记录
            GenJinList: [],
            //
            PeiXunList: [],
            newTabs: [
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "培训课程",
                    name: "2",
                    value: "培训课程",
                    data: "0000-00-00"
                },
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "培训课程",
                    name: "2",
                    value: "培训课程",
                    data: "0000-00-00"
                },
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                }
            ],
            tabs: [
                {
                    title: "公告",
                    name: "0",
                    value: "公告",
                    data: "0000-00-00"
                },
                {
                    title: "规章制度",
                    name: "1",
                    value: "规章制度",
                    data: "0000-00-00"
                },
                {
                    title: "政策文件",
                    name: "2",
                    value: "政策文件",
                    data: "0000-00-00"
                },
                {
                    title: "培训课程",
                    name: "3",
                    value: "培训课程",
                    data: "0000-00-00"
                }
            ],
            contart: [
                {
                    label: "经纪人",
                    index: "0",
                    name: "0",
                    value: "0000-00-00"
                },
                {
                    label: "经纪人跟进记录",
                    index: "2",
                    name: "2",
                    value: "0000-00-02"
                }
            ],
            contart4: [
                {
                    label: "待办事项",
                    index: "0",
                    name: "0",
                    value: "0000-00-00"
                },
                {
                    label: "消息中心",
                    index: "1",
                    name: "1",
                    value: "0000-00-01"
                }
            ],
            noticeTitle: "",
            noticeType: "",
            dateFrom: "",
            dateTo: "",
            currentPage: 1,
            pageSize: 10,
            userName: "",
            mobile: "",
            shopId: "",
            jingjiData: {},
            toDolist: [],
            roleType: "",
            merchantId: localStorage.getItem("merchantId"),
            editableTabs: [
                {
                    title: "首页",
                    name: "0",
                    path: "GetStatistics"
                }
            ],
            activeIndex: "",
            editableTabsValue: "0",
            echartxdata1: [],
            echartydata1: [],

            timeform: "",
            timeto: ""
        }
    },
    created() {
        this.editableTabs = window.localStorage.getItem("editableTabs")
        this.activeIndex = window.localStorage.getItem("activeIndex")
        if (this.editableTabs) {
            this.editableTabs = JSON.parse(this.editableTabs)
            this.editableTabs.forEach((item) => {
                if (this.activeIndex == item.path) {
                    this.activeIndex = this.activeIndex
                    this.editableTabsValue = item.name.toString()
                }
            })
        }
        this.activeName1 = this.$route.query.activeName1
        // 经纪人
        this.activeName2 = this.$route.query.activeName2
        this.getList()
        this.timeform = this.$timejs.getWeekStartDate()
        this.timeto = this.$timejs.getWeekEndDate()
        this.getArticleList()
        // 获取 公告 规章 政策文件 培训课程 列表
        this.getHomepageInfo()
        this.getJingJiRenList()

        // if()
        this.roleType = window.localStorage.getItem("roleType")
        console.log(this.roleType)
        if (
            this.roleType == "6" ||
            this.roleType == "2" ||
            this.roleType == "5"
        ) {
            this.getToDoConfirmList()
        }
        this.GetMessageList()

        // this.staffName = localStorage.getItem('staffName')
        // this.mobile = localStorage.getItem('mobile')
        // this.getNewTabs();
        // console.log(this.item.label, "l")
        if (localStorage.getItem("accessToken")) {
            this.setcookie("token", "1")
        }
    },
    methods: {
        /* 获取echars列表 */
        async getArticleList() {
            var that = this
            const data = {
                dateFrom: that.timeform,
                dateTo: that.timeto
            }
            const { data: res } = await this.$http.get(
                this.$url.GetPerformanceStatistics,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                that.echartydata1 = []
                that.echartxdata1 = []
                res.dtNotice.forEach((element) => {
                    that.echartydata1.push(element.perfomanceEachOne)
                    that.echartxdata1.push(element.userName)
                })
                that.init(that.echartxdata1, that.echartydata1)
            } else {
                that.$message.success(res.message)
            }
        },
        //设置cookie
        setcookie(name, value) {
            document.cookie = name + "=" + value
        },

        // 审核
        examine(row) {
            let id = row
            console.log(id)
            if (id.type == "出租") {
                this.$router.push({
                    name: "ChuZuDetail",
                    query: {
                        num: 1,
                        state: 2,
                        houseId: id.unifyId1,
                        louPanId: id.unifyId2,
                        isShenHe: 1
                    }
                })
            }
            if (id.type == "出售") {
                this.$router.push({
                    name: "ChuShouDetail",
                    query: {
                        num: 1,
                        state: 2,
                        houseId: id.unifyId1,
                        louPanId: id.unifyId2,
                        isShenHe: 1
                    }
                })
            }
            if (id.type == "新房") {
                this.$router.push({
                    name: "XinFangDetail",
                    query: {
                        newHouseId: id.unifyId1,
                        num: 1,
                        state: 1
                    }
                })
            }
            console.log(row)
        },
        // 获取待办事项列表
        async getToDoConfirmList() {
            const data = {
                currentPage: 1,
                pageSize: 99
            }
            const { data: res } = await this.$http.get(
                this.$url.GetToDoConfirmList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                res.dtList.forEach((ele, index) => {
                    ele.title = ele.title.replace("0000万", "万")
                })
                this.toDolist = res.dtList
            }
        },
        // 获取列表
        getList() {
            if (this.activeName2 == 0) {
                this.getJingJiRenList()
            }
            if (this.activeName2 == 1) {
            }
            if (this.activeName2 == 2) {
                this.GetGenJinList()
            }
        },
        // 获取 公告 规章 政策文件 培训课程 列表
        async getHomepageInfo() {
            await this.$http.get(this.$url.GetHomepageInfo).then((res) => {
                if (res.data.msgCode == 1) {
                    if (this.activeName1 == 0) {
                        this.gongGaoList = res.data.dtNotice
                    }
                    if (this.activeName1 == 1) {
                        this.gongGaoList = res.data.dtRules
                    }
                    if (this.activeName1 == 2) {
                        this.gongGaoList = res.data.dtPolicy
                    }
                    if (this.activeName1 == 3) {
                        this.gongGaoList = res.data.dtTrain
                    }
                }
            })
        },
        // 获取经纪人列表
        async getJingJiRenList() {
            const data = {
                // shopId: "homepage",
                isHomePage: "1",
                currentPage: this.currentPage,
                // merchantId: this.merchantId,
                pageSize: 13
            }
            const { data: res } = await this.$http.get(
                this.$url.GetJingJiRenList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.JingJiRenList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < this.JingJiRenList.length; i++) {
                    this.JingJiRenList[i].imgUrl = ""
                    if (this.JingJiRenList[i].pictureId !== null) {
                        this.JingJiRenList[i].imgUrl =
                            this.$url.avatarsPicSrc +
                            this.JingJiRenList[i].pictureId
                    }
                    if (this.JingJiRenList[i].pictureId == null) {
                        if (this.JingJiRenList[i].gender == "1") {
                            // 男
                            this.JingJiRenList[i].imgUrl = this.$url.boyImg
                        }
                        if (this.JingJiRenList[i].gender === "2") {
                            // 女
                            this.JingJiRenList[i].imgUrl = this.$url.girlImg
                        }
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        // 获取经纪人跟进列表
        GetGenJinList() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetGenJinList, {
                    params: {
                        isCustomerGenJin: 1,
                        isHomePage: 1,
                        currentPage: that.currentPage,
                        pageSize: 13
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            that.GenJinList = res.data.dtList
                        } else {
                            that.GenJinList = []
                        }
                        that.total = res.data.maxRecords
                    } else {
                        that.$message.error(res.message)
                    }
                })
        },
        // 获取详情
        isShowDetail(id) {
            this.commonID = id
            console.log(this.activeName1)
            if (this.activeName1 == 0) {
                this.$router.push({
                    name: "GongGaoDetail",
                    query: {
                        articleId: this.commonID,
                        num: 1,
                        state: 2,
                        current: 1
                    }
                })
            }
            if (this.activeName1 == 1) {
                this.$router.push({
                    name: "GuiZhangZhiDuDetail",
                    query: {
                        articleId: this.commonID,
                        num: 1,
                        state: 2,
                        articleType: "GUIZHANGZHIDU",
                        current: 1
                    }
                })
            }
            if (this.activeName1 == 2) {
                this.$router.push({
                    name: "ZhengCeWenJianDetail",
                    query: {
                        articleId: this.commonID,
                        num: 1,
                        state: 2,
                        articleType: "ZHENGCEWENJIAN",
                        current: 1
                    }
                })
            }
            if (this.activeName1 == 3) {
                this.$router.push({
                    name: "PeiXunKeChengDetail",
                    query: {
                        articleId: this.commonID,
                        num: 1,
                        state: 2,
                        articleType: "PEIXUNKECHENG",
                        current: 1
                    }
                })
            }
            console.log(this.gongGaoList)
        },
        // 经纪人跟进记录详情
        TongXunLuDetail(item) {
            this.jingjiData = JSON.stringify(item)
            this.$router.push({
                name: "KeHuGenJinJiLuDetail",
                query: {
                    num: 1,
                    form: encodeURIComponent(this.jingjiData)
                }
            })
        },
        /* 获取消息列表 */
        async GetMessageList() {
            const data = {
                messageTitle: "",
                currentPage: 1,
                pageSize: 13
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMessageList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.xiaoxiList = res.dtList
                // this.loading = false
                // this.total = res.maxRecords
            } else {
                this.$message.success(res.message)
            }
        },
        init(xdata, ydata) {
            //2.初始化
            this.chart1 = Echarts.init(this.$refs.chart1)
            //3.配置数据
            let option1 = {
                title: {
                    subtext: "销售量"
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "10%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        align: "center",
                        verticalAlign: "bottom",
                        lineHeight: -20
                    },
                    data: xdata
                }, //X轴
                // xAxis: {
                //     type: "category",
                //     data: xdata
                // }, //X轴
                yAxis: { type: "value" }, //Y轴
                series: [
                    {
                        data: ydata,
                        type: "bar"
                    }
                ] //配置项
            }
            // 4.传入数据
            this.chart1.setOption(option1)
        },
        // 公告 规章 政策文件 培训课程 tab点击
        handleClick(tab, event) {
            this.activeName1 = tab.name
            this.getHomepageInfo()
        },
        // 经纪人 店组 跟进记录 tab点击
        handleClick1(tab, event) {
            this.activeName2 = tab.name
            console.log(this.activeName2)
            if (this.activeName2 == 0) {
                this.getJingJiRenList()
            }
            if (this.activeName2 == 1) {
            }
            if (this.activeName2 == 2) {
                this.GetGenJinList()
            }
        },
        handleClick4(tab, event) {
            this.activeName4 = tab.name
            console.log(this.activeName4)
            if (this.activeName4 == 0) {
                // this.getJingJiRenList()
            }
            if (this.activeName4 == 1) {
            }
            if (this.activeName4 == 2) {
                // this.GetGenJinList()
            }
        },
        //跳转消息页面
        toxiaoxi() {
            this.$router.push("/XiaoXiZhongXinList")
        },
        tabChange(tab) {
            if (tab.name == "first") {
                this.timeform = this.$timejs.getWeekStartDate()
                this.timeto = this.$timejs.getWeekEndDate()
                this.getArticleList()
            } else if (tab.name == "second") {
                this.timeform = this.$timejs.getMonthStartDate()
                this.timeto = this.$timejs.getMonthEndDate()
                this.getArticleList()
            } else if (tab.name == "third") {
                this.timeform = this.$timejs.getQuarterStartDate()
                this.timeto = this.$timejs.getQuarterEndDate()
                this.getArticleList()
            }
        },
        jumpToGongGaoList() {
            if (this.activeName1 == "0") {
                this.addTab("公告管理", "GongGaoList")
                this.$router.push("/GongGaoList")
            } else if (this.activeName1 == "1") {
                this.addTab("规章制度", "GuiZhangZhiDuList")
                this.$router.push("/GuiZhangZhiDuList")
            } else if (this.activeName1 == 2) {
                this.addTab("政策文件", "ZhengCeWenJianList")
                this.$router.push("/ZhengCeWenJianList")
            } else if (this.activeName1 == 3) {
                this.addTab("培训课程", "PeiXunKeChengList")
                this.$router.push("/PeiXunKeChengList")
            }
        },
        jumpToAll() {
            this.$router.push("/AllAgentList")
        },
        jumpToYeji() {
            if (this.activeName2 == 0) {
                this.$router.push("/TongXunLuList")
                this.addTab("经纪人通讯录", "TongXunLuList")
            } else if (this.activeName2 == 1) {
                this.$router.push("/TongXunLuList")
                this.addTab("经纪人通讯录", "TongXunLuList")
            } else if (this.activeName2 == 2) {
                this.$router.push("/KeHuGenJinJiLuList")
                this.addTab("跟进记录", "KeHuGenJinJiLuList")
            }
        },
        // 点击导航栏新增tab页
        addTab(targetName, path) {
            let flag = true //判断是否需要新增页面
            var tabs = this.editableTabs //活跃当前tab数组
            for (var i = 0; i < tabs.length; i++) {
                //如果存在相同的tab页 不新增tab页
                if (tabs[i].path === path) {
                    this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
                    this.$router.push(path)
                    flag = false
                }
            }
            // 需要新增页面
            if (flag) {
                let newTabName = this.editableTabs.length.toString()
                this.editableTabs.push({
                    title: targetName,
                    name: newTabName,
                    path: path
                })
                this.editableTabsValue = newTabName
                window.localStorage.setItem(
                    "editableTabs",
                    JSON.stringify(this.editableTabs)
                )
            }
            this.reload()
            window.localStorage.setItem("activeIndex", path)
        }
    }
}
</script>

<style lang="less" scoped>
.commonHover:hover {
    color: #df0202;
    float: left;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    // font-weight: bold;
}

.el-tabs__nav-scroll {
    padding-left: 12px;
    background: #409eff;
}

.el-tabs__item {
    color: #fff;
    padding: 0px;
    height: 0px;
    line-height: 0px;
    opacity: 0.7;
}

.el-tabs__item.is-active {
    color: #fff;
    opacity: 1;
}

.el-icon-arrow-left {
    color: #fff;
    font-size: 14px;
}

.el-icon-arrow-right {
    color: #fff;
    font-size: 14px;
}

.el-tabs__nav-wrap.is-scrollable {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.el-tabs__nav-wrap {
    overflow: hidden;
    margin-bottom: -1px;
    position: relative;
}

.el-tabs__nav-wrap::after {
    content: “”;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: #e4e7ed;
    z-index: 1;
}

.el-tabs__active-bar {
    transition: all 0.3s;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

.allView {
    color: red;
    font-size: 12px;
    float: right;
    cursor: pointer;
    position: relative;
    z-index: 999;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}
</style>
