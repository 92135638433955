<template>
    <div>
        <div>
            <!-- 面包屑导航区域 -->
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>工作日报</el-breadcrumb-item>

      </el-breadcrumb> -->
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <span class="title">层级:</span>
                    <div v-for="item in item1" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive1 === item.label }" @click="changeClass1(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title">来源:</span>
                    <div v-for="item in item2" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive2 === item.label }" @click="changeClass2(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title">客户需求:</span>
                    <div v-for="item in item3" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive3 === item.label }" @click="changeClass3(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title">掉落原因:</span>
                    <div v-for="item in item4" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive4 === item.label }" @click="changeClass4(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title">进池时间:</span>
                    <div v-for="item in item5" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive5 === item.label }" @click="changeClass5(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title">原维护人:</span>
                    <div v-for="item in item6" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive6 === item.label }" @click="changeClass6(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                        <!-- <el-button size="mini" 
                       @click="handleEdit"
                       type="primary"
                       icon="el-icon-edit"
                       style="background-color: rgb(14 153 219); border: none; margin-left: 10px">修改</el-button> -->
                        <el-button size="mini" @click="handleDelete1()" type="primary" icon="el-icon-delete" style="background-color:rgb(215 163 16); border: none">删除</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <div style="padding-left: 10px">
                    共有{{ gongxiangList.length }}条数据
                    <span style="text-decoration: underline;float:right;cursor: pointer;">分配记录<span>
                            <el-button size="mini" style="margin-left: 15px;" type="danger">批量分配</el-button>
                        </span></span>
                </div>
                <el-table ref="multipleTable"  v-loading="loading" :data="gongxiangList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe class="matop0">
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="客户姓名" prop="kehuxingming">
                        <!-- <template slot-scope="scope">
                            <el-button size="mini" class="tableButSpan" @click="openDetails2(scope.row)" type="text" style="color: #1268c0;text-decoration: underline">{{ scope.row.articleTitle }}</el-button>
                        </template> -->

                    </el-table-column>
                    <el-table-column label="需求类型" prop="xuqiuleixing"></el-table-column>
                    <el-table-column label="需求" prop="xuqiu">
                        <template slot-scope="scope">
                            {{scope.row.xuqiu1}}<br />
                            {{scope.row.xuqiu2}}
                        </template>
                    </el-table-column>
                    <el-table-column label="委托时间" prop="weituoshijian"></el-table-column>
                    <el-table-column label="原委托人" prop="yuanweituoren"></el-table-column>
                    <el-table-column label="掉落原因" prop="diaoluoyuanyin"></el-table-column>
                    <el-table-column label="剩余时间" prop="shengyushijian"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

                            <el-button @click.native.stop="fenpei(scope.$index, scope.row)" type="text" size="mini" class="el-icon-plus" style="color: red;">去分配</el-button>
                            <!-- <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            articleTitle: "",
            enabled: "",
            isActive1: "待分配",
            isActive2: "保利海涵庄园店A组",
            isActive3: "不限",
            isActive4: "不限",
            isActive5: "不限",
            isActive6: "不限",
            kehuxingming: "",
            xuqiuleixing: "",
            xuqiu: "",
            weituoshijian: "",
            yuanweituoren: "",
            diaoluoyuanyin: "",
            shengyushijian: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            gongxiangList: [
                {
                    kehuxingming: "租两套客户",
                    xuqiuleixing: "租赁住宅",
                    xuqiu1: "4000-5000元",
                    xuqiu2: "20-80平/2-3居",
                    weituoshijian: "2021-3-10",
                    yuanweituoren: "马冬梅",
                    diaoluoyuanyin: "超期没有维护",
                    shengyushijian: "2天7小时7分钟7秒"
                },
                {
                    kehuxingming: "租两套客户",
                    xuqiuleixing: "租赁住宅",
                    xuqiu1: "4000-5000元",
                    xuqiu2: "20-80平/2-3居",
                    weituoshijian: "2021-3-10",
                    yuanweituoren: "马冬梅",
                    diaoluoyuanyin: "超期没有维护",
                    shengyushijian: "2天7小时7分钟7秒"
                },
                {
                    kehuxingming: "租两套客户",
                    xuqiuleixing: "租赁住宅",
                    xuqiu1: "4000-5000元",
                    xuqiu2: "20-80平/2-3居",
                    weituoshijian: "2021-3-10",
                    yuanweituoren: "马冬梅",
                    diaoluoyuanyin: "超期没有维护",
                    shengyushijian: "2天7小时7分钟7秒"
                }
            ],
            item1: [
                {
                    value: "0",
                    label: "待分配"
                },
                {
                    value: "1",
                    label: "店组共享池"
                },
                {
                    value: "2",
                    label: "门店共享池"
                },
                {
                    value: "3",
                    label: "大区共享池"
                },
                {
                    value: "4",
                    label: "店东共享池"
                }
            ],
            item2: [
                {
                    value: "0",
                    label: "保利海涵庄园店A组"
                }
            ],
            item3: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "1",
                    label: "买二手"
                },
                {
                    value: "2",
                    label: "租赁"
                },
                {
                    value: "3",
                    label: "新房"
                }
            ],
            item4: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "1",
                    label: "维护不当"
                },
                {
                    value: "2",
                    label: "离职调转"
                },
                {
                    value: "3",
                    label: "手动无效"
                }
            ],
            item5: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "1",
                    label: "今天"
                },
                {
                    value: "2",
                    label: "近三天"
                },
                {
                    value: "3",
                    label: "本周"
                }
            ],
            item6: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "1",
                    label: "我自己"
                }
            ]
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
    },
    methods: {
        changeClass1(i) {
            this.isActive1 = i
        },

        changeClass2(i) {
            this.isActive2 = i
        },

        changeClass3(i) {
            this.isActive3 = i
        },
        changeClass4(i) {
            this.isActive4 = i
        },
        changeClass5(i) {
            this.isActive5 = i
        },
        changeClass6(i) {
            this.isActive6 = i
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "GongZuoRiBaoDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.$router.push({
                name: "GongZuoRiBaoDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getArticleList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    } else if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.getArticleList()
        },
        async getList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode) {
                this.operationList = res.dtList
                this.total = res.maxRecords
                //   for (var i = 0; i < res.dtList.length; i++) {
                //     if (res.dtList[i].enabled === '1') {
                //       this.operationList[i].enabled = '是'
                //     } else if (res.dtList[i].enabled === '2') {
                //       this.operationList[i].enabled = '否'
                //     }
                //   }
            } else {
                this.$message.success(res.message)
            }
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.deleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.GetPetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                        const { data: res } = this.$http.delete(
                            this.$url.deleteArticle,
                            {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.reload()
                        } else {
                            this.$message.success(res.message)
                        }
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        fenpei(index, row) {
            this.drow = row.articleId
            // this.$router.push({
            //     name: "GongZuoRiBaoDetail",
            //     query: {
            //         articleId: this.drow,
            //         num: 2,
            //         state: 3
            //     }
            // })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "GongZuoRiBaoDetail",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
