<template>
  <div>
    <el-card>
      <span>{{ detail }}</span>
      <span style="float: right">
        <!-- <el-button v-if="num == 2" size="mini" type="primary">开收据</el-button> -->
        <el-button size="mini" type="primary" @click="goBack">返回</el-button>
      </span>
    </el-card>
    <el-card v-if="num == 0">
      <el-form :model="form" ref="form" :rules="caiwuRules">
        <el-row v-if="label == '入账管理'">
          <el-col :span="12">
            <el-form-item label="选择合同：" required label-width="20%">
              <el-button
                size="mini"
                @click="openHetongDialog"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
                <span
                  v-if="form.contractNo == '请选择合同'"
                  style="color: #c0c4cc"
                  >{{ form.contractNo }}</span
                >
                <span v-if="form.contractNo != '请选择合同'">{{
                  form.contractNo
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
              <el-select
                v-model="form.MoneyType"
                @change="changeType"
                placeholder="请选择资金类型"
                style="width: 60%;"
              >
                <el-option
                  v-for="item in stateOptions1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="财务类型：" label-width="20%" prop="FinancialType">
              <el-input v-if="num == 0" size="mini" 
                  v-model="form.FinancialName" 
                  :disabled="true"
                  class="buttonInput button_tanchu"
                  style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
                >
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row v-if="label == '付款管理'">
          <el-col :span="12">
            <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
              <el-select
                v-model="form.MoneyType"
                @change="changeType"
                placeholder="请选择资金类型"
                style="width: 60%;"
              >
                <el-option
                  v-for="item in stateOptions2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item v-if="paymentType==0" label="银行卡号：" label-width="20%" prop="ShiFuYinHang">
              <el-input placeholder="请输入银行卡号" v-model="form.ShiFuYinHang" style="width: 60%;"></el-input>
            </el-form-item>
            <el-form-item v-if="paymentType==1" label="发票单号：" label-width="20%" prop="ShiFuFaPiao">
              <el-input placeholder="请输入发票单号" v-model="form.ShiFuFaPiao" style="width: 60%;"></el-input>
            </el-form-item> -->
            <!-- <el-form-item v-if="paymentType==2" label="选择合同：" required label-width="20%"> -->
            <el-form-item label="选择合同：" required label-width="20%">
              <el-button
                size="mini"
                @click="openHetongDialog"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
                <span
                  v-if="form.contractNo == '请选择合同'"
                  style="color: #c0c4cc"
                  >{{ form.contractNo }}</span
                >
                <span v-if="form.contractNo != '请选择合同'">{{
                  form.contractNo
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="财务类型：" label-width="20%" prop="FinancialType">
              <el-input v-if="num == 0" size="mini" 
                  v-model="form.FinancialName" 
                  :disabled="true"
                  class="buttonInput button_tanchu"
                  style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
                >
              </el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row v-if="label == '入账管理'">
          <el-col :span="12">
            <el-form-item label="应收款：" label-width="20%" required>
              <el-input
                v-if="num == 0 && form.MoneyType != '定金'"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.YingShouYingFuAmount"
                :disabled="true"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
              </el-input>
              <el-input
                v-if="num == 0 && form.MoneyType == '定金'"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.YingShouYingFuAmount"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="实收款："
              label-width="20%"
              prop="ShiShouShiFuAmount"
            >
              <el-input
                placeholder="请输入已收款金额"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.ShiShouShiFuAmount"
                style="width: 60%;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="label == '付款管理'">
          <el-col :span="12">
            <el-form-item
              v-if="paymentType == 0 || paymentType == 1"
              label="应付款："
              label-width="20%"
              prop="YingShouYingFuAmount"
            >
              <el-input
                placeholder="请输入应付款金额"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.YingShouYingFuAmount"
                style="width: 60%;"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="paymentType == 2"
              label="应付款："
              label-width="20%"
              prop="YingShouYingFuAmount"
            >
              <el-input
                v-if="num == 0"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.YingShouYingFuAmount"
                :disabled="true"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="已付款："
              label-width="20%"
              prop="ShiShouShiFuAmount"
            >
              <el-input
                placeholder="请输入已付款金额"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.ShiShouShiFuAmount"
                style="width: 60%;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="label == '入账管理'">
          <!-- <el-col :span="12">
            <el-form-item label="已收款：" label-width="20%" prop="ShiShouShiFuAmount">
              <el-input placeholder="请输入已收款金额" v-model="form.ShiShouShiFuAmount" style="width: 60%;"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item
              label="财务入账时间："
              label-width="20%"
              prop="FinancialDate"
            >
              <el-date-picker
                style="width: 60%;"
                v-model="form.FinancialDate"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="label == '付款管理'">
          <el-col :span="12">
            <el-form-item
              label="财务出账时间："
              label-width="20%"
              prop="FinancialDate"
            >
              <el-date-picker
                style="width: 60%;"
                v-model="form.FinancialDate"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" label-width="10%">
              <el-input
                style="width:82.5%"
                v-model="form.Remark"
                type="textarea"
                :rows="5"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="num != '1'" style="margin: 20px 10px;">
          <el-col :span="12">
            <el-form-item>
              <el-button
                size="mini"
                style="margin-right:10px"
                type="primary"
                v-if="num == 0"
                @click="onSave('form', 'add')"
                >新增</el-button
              >
              <!-- <el-button size="mini" style="margin-right:10px" type="primary" v-if="this.num == 2" @click="onSave('ruleForm','undate')">确定</el-button> -->
              <el-button
                size="mini"
                type="warning"
                @click="cancel"
                v-if="num != 1"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card v-if="num == 1 && label == '入账管理'">
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">付款方:</span>
          <span class="labelValue">{{ information.payer }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">操作人:</span>
          <span class="labelValue">{{ information.accountantUser }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">收款方:</span>
          <span class="labelValue">{{ information.shopName }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">资金类型:</span>
          <span class="labelValue">{{ information.moneyType }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">应收款:</span>
          <span class="labelValue">{{ information.yingShouYingFuAmount }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">已收款:</span>
          <span class="labelValue">{{ information.shiShouShiFuAmount }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">入账时间:</span>
          <span class="labelValue">{{ information.financialDate }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">项目信息:</span>
          <span class="labelValue">{{ information.itemInfo }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">创建时间:</span>
          <span class="labelValue">{{ information.createTime }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">商户：</span>
          <span class="labelValue">{{ information.merchantName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="mr20">
          <span class="titleLabel">备注:</span>
          <span class="labelValue">{{ information.remark }}</span>
        </el-col>
      </el-row>
    </el-card>

    <el-card v-if="num == 1 && label == '付款管理'">
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">付款商户:</span>
          <span class="labelValue">{{ information.shopName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">收款人:</span>
          <span
            style="color: #4f7be2; cursor: pointer;"
            @click="tousedetail"
            class="labelValue"
            >{{ information.receriverName }}</span
          >
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">收款人银行卡号:</span>
          <span class="labelValue">{{ information.receiverBankAccount }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">操作人:</span>
          <span class="labelValue">{{ information.accountantUser }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">资金类型:</span>
          <span class="labelValue">{{ information.moneyType }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">应付款:</span>
          <span class="labelValue">{{ information.yingShouYingFuAmount }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">已付款:</span>
          <span class="labelValue">{{ information.shiShouShiFuAmount }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">打款时间:</span>
          <span class="labelValue">{{ information.financialDate }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">项目信息:</span>
          <span class="labelValue">{{ information.itemInfo }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">创建时间:</span>
          <span class="labelValue">{{ information.createTime }}</span>
        </el-col>
        <el-col :span="12" class="mr20">
          <span class="titleLabel">商户：</span>
          <span class="labelValue">{{ information.merchantName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="mr20">
          <span class="titleLabel">备注:</span>
          <span class="labelValue">{{ information.remark }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-table
        v-if="label == '入账管理'"
        ref="receiptForm"
        :data="receiptForm"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column
          label="姓名"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="身份证号"
          prop="idCardNo"
          width="200"
        ></el-table-column>
        <el-table-column label="买卖/租赁" prop="tradeType"></el-table-column>
        <el-table-column label="地址" prop="address"></el-table-column>
        <el-table-column label="收款类型" prop="moneyType" width="300"></el-table-column>
        <el-table-column label="金额" prop="amount"></el-table-column>
        <el-table-column label="付款方式" prop="paymentType" width="300"></el-table-column>
        <!-- <el-table-column label="入账时间" prop="financialDate"></el-table-column> -->
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="200"></el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      title="选择合同"
      :visible.sync="hetongDialog"
      direction="rtl"
      width="80%"
      top="4%"
      class="xuqiuDialog"
    >
      <QianYueList
        :isCaiWu="true"
        @colseHetongDialog="colseHetongDialog"
      ></QianYueList>
    </el-dialog>
  </div>
</template>
<script>
import QianYueList from "../../components/QianYue/QianYueList.vue";
export default {
  components: {
    QianYueList
  },
  data() {
    return {
      userid: localStorage.getItem("userId"),
      detail: "",
      createrRemark: "",
      num: this.$route.query.num,
      state: this.$route.query.state,
      label: this.$route.query.label,
      financialId: this.$route.query.financialId,
      financialType: this.$route.query.financialType,
      paymentType: "",
      tiCheng: "",
      fangKuan: "",
      dingJin: "",
      hetongType: "",
      tradeType: "",
      hetongDialog: false,
      information: {
        payer: "", //付款方
        accountantUser: "", //操作人
        shopName: "", //收款方
        beneficiary: "", //收款人
        moneyType: "", //资金类型
        yingShouYingFuAmount: "", //应收款
        shiShouShiFuAmount: "", //已收款
        financialDate: "", //入账时间
        itemInfo: "", //项目信息
        createTime: "", //创建时间
        remark: "", //备注
        merchantName: "" //商户
      },
      caiwuRules: {
        ShiFuYinHang: [
          {
            required: true,
            message: "请输入银行卡号",
            trigger: "blur"
          }
        ],
        ShiFuFaPiao: [
          {
            required: true,
            message: "请输入发票单号",
            trigger: "blur"
          }
        ],
        FinancialType: [
          {
            required: true,
            message: "请选择财务类型",
            trigger: "blur"
          }
        ],
        MoneyType: [
          {
            required: true,
            message: "请选择资金类型",
            trigger: "blur"
          }
        ],
        YingShouYingFuAmount: [
          {
            required: true,
            message: "请输入应付款金额",
            trigger: "blur"
          }
        ],
        ShiShouShiFuAmount: [
          {
            required: true,
            message: "请输入已收款金额",
            trigger: "blur"
          }
        ],
        FinancialDate: [
          {
            required: true,
            message: "请选择入账时间",
            trigger: "blur"
          }
        ]
      },
      form: {
        contractNo: "请选择合同", //合同编号
        FinancialType: "", //财务类型
        FinancialName: "", //财务类型名
        MoneyType: "", //资金类型
        YingShouYingFuAmount: "", //应收款
        ShiShouShiFuAmount: "", //已收款
        FinancialDate: "", //入账时间
        ContractId: "", //合同ID （非必填）
        Remark: "", //备注（非必填）
        AccountantUserId: "", //（只有在【平台】、【店东】权限下是必传）
        contractPrice: "", //总价
        agencyFee: "", //代理费
        deposit: "", //定金
        ShiFuYinHang: "", //银行卡号
        ShiFuFaPiao: "" //发票单号
      },
      stateOptions1: [],
      stateOptions2: [
        {
          value: "0",
          label: "房款"
        },
        {
          value: "1",
          label: "定金"
        }
        // {
        //   value: "0",
        //   label: "工资"
        // },
        // {
        //   value: "1",
        //   label: "报销"
        // },
        // {
        //   value: "2",
        //   label: "提成"
        // },
        // {
        //   value: "3",
        //   label: "房款"
        // },
        // {
        //   value: "4",
        //   label: "定金"
        // }
      ],
      receiptForm: []
    };
  },
  created() {
    if (this.num == 0 && this.label == "入账管理") {
      this.form.FinancialType = "0";
      this.form.FinancialName = "入账";
      this.detail = "新增入账";
    } else if (this.num == 0 && this.label == "付款管理") {
      this.form.FinancialType = "1";
      this.form.FinancialName = "付款";
      this.detail = "新增付款";
    }
    if (this.num == 1 && this.label == "入账管理") {
      this.detail = "入账详情";
      this.getDetail();
    } else if (this.num == 1 && this.label == "付款管理") {
      this.detail = "付款详情";
      this.getDetail();
    }
  },
  methods: {
    //跳转经纪人详情
    tousedetail() {
      this.$router.push({
        name: "RenYuanGuanLiDetail",
        query: {
          userId: this.information.receiverUserId,
          num: 1,
          state: 2
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    },
    openHetongDialog() {
      this.hetongDialog = true;
    },
    colseHetongDialog(hetongData) {
      let val = "";
      this.hetongType = hetongData.tradeType;
      if (this.label == "入账管理") {
        if (this.hetongType == "买卖") {
          this.stateOptions1 = [
            {
              value: "0",
              label: "房款"
            },
            {
              value: "1",
              label: "代理费"
            },
            {
              value: "2",
              label: "定金"
            }
          ];
        } else {
          this.stateOptions1 = [
            {
              value: "0",
              label: "房款"
            },
            {
              value: "1",
              label: "定金"
            }
          ];
        }
      }
      this.form.contractNo = hetongData.contractNo;
      this.form.ContractId = hetongData.contractId;
      this.hetongDialog = false;
      this.val = hetongData.contractId;
      this.getContractDetail(val);
    },
    changeType() {
      if (this.label == "入账管理") {
        if (this.form.MoneyType == "房款") {
          this.form.YingShouYingFuAmount = this.form.contractPrice;
        } else if (this.form.MoneyType == "代理费") {
          this.form.YingShouYingFuAmount = this.form.agencyFee;
        } else {
          this.form.YingShouYingFuAmount = this.form.deposit;
        }
      } else if (this.label == "付款管理") {
        if (this.form.MoneyType == "工资") {
          this.paymentType = 0;
        } else if (this.form.MoneyType == "报销") {
          this.paymentType = 1;
        } else if (this.form.MoneyType == "提成") {
          this.tradeType = 1;
          this.paymentType = 2;
        } else {
          this.paymentType = 2;
        }
      }
    },
    async getContractDetail(val) {
      let that = this;
      that.loading = true;
      await that.$http
        .get(that.$url.GetContractDetail, {
          params: {
            contractId: that.val //客源id
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            if (res.data.dtList.length > 0) {
              let data = res.data.dtList[0];
              that.form.contractPrice = data.contractPrice;
              that.form.agencyFee = data.agencyFee;
              that.form.deposit = data.deposit;
              if (this.label == "付款管理") {
                if (this.form.MoneyType == "提成") {
                  this.form.YingShouYingFuAmount = data.agencyFee;
                } else if (this.form.MoneyType == "房款") {
                  this.form.YingShouYingFuAmount = data.contractPrice;
                } else {
                  this.form.YingShouYingFuAmount = data.deposit;
                }
              }
              that.loading = false;
            } else {
              that.loading = false;
              that.$message.error(res.data.message);
            }
          }
        });
    },
    onSave(formName, type) {
      const that = this;
      if (type == "add") {
        that.$refs[formName].validate(async valid => {
          console.log("this.ruleForm", that.form);
          if (typeof that.form.FinancialDate == "string") {
            that.form.FinancialDate = that.$commonJs.dateTimeFormatter(
              that.form.FinancialDate
            );
          }
          if (valid) {
            that.loading = true;
            let param = {
              FinancialType: that.form.FinancialType,
              MoneyType: that.form.MoneyType,
              YingShouYingFuAmount: that.form.YingShouYingFuAmount,
              ShiShouShiFuAmount: Number(that.form.ShiShouShiFuAmount),
              FinancialDate: that.form.FinancialDate,
              contractId: that.form.ContractId,
              Remark: that.form.Remark,
              AccountantUserId: that.userid
            };
            that.$http
              .post(that.$url.InsertFinancial, param, {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8"
                }
              })
              .then(res => {
                that.loading = false;
                if (res.data.msgCode == 1) {
                  that.$message.success("新增成功！");
                  that.$router.go(-1);
                } else {
                  that.$message.error(res.data.message);
                }
              })
              .catch(res => {
                that.loading = false;
                that.disabled = false;
                this.$message.error("请求错误");
              });
          }
        });
      }
    },
    async getDetail() {
      let that = this;
      that.loading = true;
      // let param= {
      //   financialType: that.financialType,
      //   financialId: that.financialId
      // }
      await that.$http
        .get(that.$url.GetFinancialDetail, {
          params: {
            financialType: that.financialType,
            financialId: that.financialId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            // if (res.data.dtList.length > 0) {
            //   let data = res.data.dtList[0];
            //   this.ContractId = data.contractId;
            //   this.eSignFileUrl1 = data.eSignFileUrl
            // }
            let data = res.data.dtList[0];
            that.information = Object.assign(that.information, data);
            that.receiptForm = res.data.dataReceiptTableList;
            that.loading = false;
          } else {
            that.loading = false;
            that.$message.error(res.data.message);
          }
        })
        .catch(res => {
          this.$message.error("请求错误");
        });
    }
  }
};
</script>
<style scoped>
.labelValue {
  padding-left: 10px;
  font-size: 16px;
  color: #a7a7a7;
}
</style>
