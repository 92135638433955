<template>
    <div>
        <el-card class="search" v-if="!isFYGenJin">
            <el-row>
                <el-col>
                    <el-button size="mini" @click="updateGenJin" type="primary" icon="el-icon-plus">新增</el-button>
                    <el-button @click="deleteGenJin" type="warning" size="mini" icon="el-icon-delete">删除</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card v-if="!isFYGenJin">
            <el-table :data="FangYuanData" border style="width: 100%" v-loading="loading" ref="FangYuanRef" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="genJinJiLuSelection" stripe>
                <el-table-column type="selection" width="40"> </el-table-column>
                <el-table-column label="楼盘名称" prop="louPanName">
                    <template slot-scope="scope">
                        <span>{{ scope.row.louPanName }}<br></span>
                        <span style="color:red">{{scope.row.houseNo}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="genJinTime" label="跟进日期">
                </el-table-column>
                <el-table-column prop="genJinInfo" label="跟进内容">
                </el-table-column>
                <el-table-column prop="genJinRenName" label="跟进人">
                </el-table-column>
                <!-- <el-table-column prop="genJinRenName" label="跟进人" v-if="roleType == '5' || roleType == '6' || roleType == '2' ">
                </el-table-column> -->
                <el-table-column prop="createTime" label="创建时间">
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.isDisplay==1" @click="updateFangYuanGenJin(scope.row)" type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                        <el-button v-if="roleType!='1'&&scope.row.isDisplay==1||roleType=='1'&&scope.row.genJinUserId==genJinUserId" @click="removeFangYuan(scope.row)" type="text" size="mini" class="el-icon-delete caozuo" style="color: #f00">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog :title="title" :visible.sync="GenJinVisible" width="50%" :before-close="handleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="房源:" prop="loupanName" label-width="18%" v-if="!isFYGenJin">
                    <el-button :disabled='disabled' style="height:32px;width:90%;line-height: 0px;text-align:left" @click="openFangYuanDialog">
                        <span style="color:#c0c4cc" v-if="ruleForm.loupanName=='请选择房源'">{{ruleForm.loupanName}}</span>
                        <span v-if="ruleForm.loupanName!='请选择房源'">{{ruleForm.loupanName}}</span>
                    </el-button>
                </el-form-item>
                <el-form-item v-if="roleType == '2' ||roleType == '5' || roleType == '6'" label="经纪人:" prop="agentName" label-width="18%">
                    <el-button :disabled='disabled' style="height:32px;width:90%;line-height: 0px;text-align:left" @click="openjingjirenDialog">
                        <span v-if="ruleForm.agentName=='请选择经纪人'" style="color:#c0c4cc">{{ ruleForm.agentName }}</span>
                        <span v-if="ruleForm.agentName!='请选择经纪人'">{{ ruleForm.agentName }}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="跟进日期:" label-width="18%" prop="genJinTime">
                    <el-date-picker v-model="ruleForm.genJinTime" style="width: 90%;" type="datetime" placeholder="请选择跟进日期" :picker-options="pickerOptions" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="跟进内容:" label-width="18%" prop="genJinInfo">
                    <el-input maxlength="256" v-model="ruleForm.genJinInfo" style="width: 90%;" :rows="5" type="textarea" placeholder="请输入跟进内容" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="warning" size="mini" @click="cancel">取 消</el-button>
                <el-button type="primary" size="mini" @click="addGenJin()" style="margin-right: 56px">确 定</el-button>

            </span>
        </el-dialog>
        <el-dialog title="跟进房源" :visible.sync="fangyuanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <el-tabs v-model="fangyuanTabPane" @tab-click="handleClickTab" class="qiugou-tabs" style="position: relative;top: 0px;margin-bottom:20px;background-color: #fff;">
                <el-tab-pane name="出售房源" label="出售房源">
                </el-tab-pane>
                <el-tab-pane name="出租房源" label="出租房源">
                </el-tab-pane>
            </el-tabs>
            <KeYuanChuShouList :isFangYuanGenJin="isFangYuanGenJin" :state='state' @colseFangyuanDialog="colseFangyuanDialog"></KeYuanChuShouList>
        </el-dialog>
        <el-dialog title="经纪人列表" :visible.sync="jingjirenDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <TongXunLuList :isQianyueOpen='true' @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>

    </div>
</template>
<script>
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue"
import KeYuanChuShouList from "../../components/KeYuan/KeYuanChuShouList.vue"
export default {
    components: {
        KeYuanChuShouList,
        TongXunLuList
    },
    props: ["isXiuGaiGen"],
    props: {
        isFYGenJin: {
            type: Boolean,
            default: false
        },
        houseId: {
            type: String
        },
        isAdd: {
            type: String
        },

        xiuGaiList: {
            type: Object
            // default: {}
        },
        isXiuGaiGen: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            isFYdisabled: false,
            title: "",
            ruleForm: {
                a: ",",
                loupanName: "请选择房源",
                GenJinInfo: "",
                genJinTime: "",
                userId: "",
                loupanName1: "请选择房源",
                agentName: "请选择经纪人"
            },
            GenJinType: "",
            isFangYuanGenJin: false,
            state: "1",
            rules: {
                loupanName: [
                    {
                        required: true,
                        message: "请选择房源",
                        trigger: "blur"
                    }
                ],
                genJinInfo: [
                    {
                        required: true,
                        message: "请输入跟进内容",
                        trigger: "blur"
                    }
                ],
                genJinTime: [
                    {
                        required: true,
                        message: "请选择跟进日期",
                        trigger: "change"
                    }
                ],
                agentName: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "blur"
                    }
                ]
            },
            fangyuanTabPane: "出售房源",
            fangyuanDialog: false,
            GenJinVisible: false,
            jingjirenDialog: false,
            loading: false,
            FangYuanData: [],
            fangYuanXuanZhong: [],
            houseNo: "",
            genJinTime: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            SelectVal: "",
            roleType: "",
            disabled: false,
            JingJiRenData: {},
            genJinUserId:""
        }
    },
    created() {
        console.log(this.isXiuGaiGen, "q")
        console.log(this.houseId)
        console.log(this.isFYGenJin)
        console.log(this.xiuGaiList)
        if (this.isFYGenJin == true) {
            this.GenJinVisible = true
            if (this.isXiuGaiGen == "1") {
                this.GenJinType = "update"
                this.updateFangYuanGenJin(this.xiuGaiList)
            } else {
                this.GenJinType = "add"
            }
        }
        this.getGenJinList()
        this.roleType = window.localStorage.getItem("roleType")
        this.genJinUserId = window.localStorage.getItem("userId")
    },
    methods: {
        async getGenJinList() {
            const data = {
                isHomePage: "0",
                isCustomerGenJin: "0",
                houseNo: this.houseNo,
                genJinTime: this.genJinTime,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetGenJinList,
                {
                    params: data
                }
            )
            if (res.msgCode == "1") {
                // this.$message.success("获取列表成功")
                this.FangYuanData = res.dtList
                this.total = res.maxRecords
            }
        },
        // 确定新增、修改
        async addGenJin() {
            if (this.isAdd == 1) {
                this.GenJinType == "add"
            }
            if (this.GenJinType == "add") {
                if (this.isFYGenJin == true) {
                } else {
                    if (this.fangYuanXuanZhong.length == 0) {
                        this.$message.error("请选择房源！")
                        return
                    }
                }
            } else {
                if (this.isFYGenJin == true) {
                } else {
                    if (this.ruleForm.loupanName == "请选择房源") {
                        this.$message.error("请选择房源！")
                        return
                    }
                }
            }
            if (this.roleType == 6) {
                if (this.ruleForm.agentName == "请选择经纪人") {
                    this.$message.error("请选择经纪人！")
                    return
                }
            }
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let that = this,
                        url = "",
                        method = ""
                        console.log(that.ruleForm.genJinTime)
                    // if (typeof that.ruleForm.genJinTime == "string") {
                    //     that.ruleForm.genJinTime =
                    //         that.$commonJs.dateTimeFormatter(
                    //             that.ruleForm.genJinTime
                    //         )
                    // }
                    const data = {
                        GenJinInfo: that.ruleForm.genJinInfo,
                        GenJinTime: that.$commonJs.dateTimeFormatter(that.ruleForm.genJinTime)
                    }

                    if (that.GenJinType == "add") {
                        url = that.$url.InsertGenJin
                        data.UserId = that.JingJiRenData.userId
                        if (this.isFYGenJin) {
                            data.HouseId = this.houseId
                        } else {
                            data.HouseId = that.fangYuanXuanZhong[0].houseId
                        }
                        method = "post"
                    } else {
                        url = that.$url.UpdateGenJin
                        ;(data.HouseId = that.ruleForm.houseId),
                            (data.GenJinId = that.ruleForm.genJinId)
                        method = "put"
                    }
                    const { data: res } = await that.$http[method](url, data, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    if (res.msgCode == "1") {
                        this.GenJinVisible = false
                        this.$emit("isGJreload", true)
                        if (this.isFYGenJin != true) {
                            this.getGenJinList()
                        }
                    } else {
                        this.$message.error(res.message)
                        return
                    }
                }
            })
        },
        // 新增跟进
        updateGenJin() {
            this.title = "新增跟进记录"
            this.ruleForm = {}
            this.fangYuanXuanZhong = []
            this.ruleForm.loupanName = "请选择房源"
            this.ruleForm.agentName = "请选择经纪人"
            this.GenJinVisible = true
            this.GenJinType = "add"
        },
        // 批量删除跟进记录
        async deleteGenJin() {
            let data1 = []
            if (this.SelectVal == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                }).catch((err) => {})
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(async () => {
                        for (var i = 0; i < this.SelectVal.length; i++) {
                            data1.push(this.SelectVal[i].genJinId)
                        }
                        const { data: res } = await this.$http.delete(
                            this.$url.DeleteGenJin,
                            {
                                data: data1,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.msgCode == 1) {
                            this.loading = false
                            // this.$message.success("跟进记录删除成功！")
                            this.getGenJinList()
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                    .catch((err) => err)
            }
        },
        // 删除
        async removeFangYuan(index) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    const data1 = [index.genJinId]
                    const { data: res } = await this.$http.delete(
                        this.$url.DeleteGenJin,
                        {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    this.loading = false
                    if (res.msgCode == 1) {
                        //  this.$message.success("跟进记录删除成功！")
                        this.getGenJinList()
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => err)
        },
        // 修改跟进记录
        updateFangYuanGenJin(row) {
            this.title = "修改跟进记录"
            this.GenJinVisible = true
            this.GenJinType = "update"
            this.isFYdisabled = true
            this.ruleForm = row
            console.log(row)
            this.ruleForm.loupanName =
                this.ruleForm.louPanName +
                "\xa0\xa0\xa0" +
                this.ruleForm.houseNo
            this.ruleForm.houseId = this.ruleForm.houseId
            this.ruleForm.agentName = row.genJinRenName
            this.disabled = true
        },
        // 选中
        genJinJiLuSelection(val) {
            console.log(val)
            this.SelectVal = val
        },
        openFangYuanDialog() {
            this.fangyuanDialog = true
            this.isFangYuanGenJin = true
        },
        handleClickTab(tab) {
            if (tab.label == "出售房源") {
                this.state = "1"
            } else if (tab.label == "出租房源") {
                this.state = "0"
            }
        },
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getGenJinList()
        },
        handleCurrentChange(newSize) {
            this.currentPage = newSize
            this.getGenJinList()
        },
        handleClose() {
            this.GenJinVisible = false
            this.$emit("isGJreload", true)
        },
        colseFangyuanDialog(val, type) {
            console.log(val, type)
            this.fangYuanXuanZhong.push(val[0])
            if (this.fangYuanXuanZhong.length > 1) {
                this.fangYuanXuanZhong.splice(0, 1)
                console.log(this.fangYuanXuanZhong)
            }
            this.ruleForm.loupanName =
                val[0].louPanName + "\xa0\xa0\xa0" + val[0].houseNo
            if (type) {
                this.fangyuanDialog = false
            }
        },
        colseJingJiRenDialog(val) {
            console.log(val)
            this.jingjirenDialog = false
            this.ruleForm.agentName = val.userName
            this.JingJiRenData = val
            console.log(this.JingJiRenData)
        },
        openjingjirenDialog() {
            this.jingjirenDialog = true
        },
        cancel() {
            this.GenJinVisible = false
            this.$emit("isGJreload", true)
            this.ruleForm = {}
            if (this.isFYGenJin != true) {
                this.$nextTick(() => {
                    this.$refs.ruleForm.clearValidate()
                })
            }
        }
    }
}
</script>
<style lang='less' scoped>
.qiugou-tabs {
    font-size: 14px;
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        padding-left: 20px;
        margin: 0px;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-step.is-vertical .el-step__line {
        height: 20px;
        top: 23px;
        left: 6px;
        width: 1px;
    }
    /deep/ .el-step.is-vertical .el-step__head {
        width: 13px;
        display: none !important;
    }
    /deep/ .el-step__icon {
        background: #df0202;
        width: 13px;
        height: 13px;
        border: 2px solid;
        border-color: #e6baa8;
    }
    /deep/ .el-step__icon-inner {
        display: none;
    }
    /deep/ .el-step.is-vertical .el-step__title {
        padding-bottom: 0px;
    }
    /deep/ .el-step__title.is-wait,
    /deep/ .el-step__description {
        color: #000;
        font-size: 14px;
    }
    /deep/ .el-card__body {
        margin: 5px 20px;
        padding: 0px !important;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }
}
/deep/.xuqiuDialog .el-tabs {
    margin-top: -5px !important;
    .el-tabs__header {
        border-bottom: none !important;
        margin: 0px;
        .el-tabs__nav {
            color: #000 !important;
            border: none !important;
        }
        .el-tabs__nav > .is-active {
            color: rgb(238, 103, 22) !important;
            background: transparent !important;
        }
        .el-tabs__item {
            border-left: none !important;
        }
    }
    .el-tabs__content {
        margin-left: 14px !important;
    }
}
</style>