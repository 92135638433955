<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card>
                <el-row>
                    <el-col>
                        <span style="margin-left: 15px;">消息标题：</span>
                        <el-input placeholder="消息标题" style="width:25.5%" v-model="messageTitle" class="media-width"></el-input>
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cGetMessageList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <el-button size="mini" @click="biaojis()" type="primary" icon="el-icon-star-off" style="border: none">标记为已读</el-button>
                        <el-button size="mini" @click="handleDelete1()" type="primary" icon="el-icon-delete" style="background-color:rgb(215 163 16); border: none">删除</el-button>
                    </el-col>
                    <!-- <el-col :span="4">
                        <el-button size="mini" @click="goback()" type="primary" style="border: none;float:right;">返回</el-button>
                    </el-col> -->
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card class="xiaoxilist">
                <el-table ref="multipleTable"  v-loading="loading" :data="xiaoxiList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="消息标题" prop="title">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row,scope)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.messageTitle }}<span v-if="scope.row.isRead == 0" class="type"></span></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="消息内容" width="800" prop="messageContent"></el-table-column>
                    <el-table-column label="接收时间" prop="createTime"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>

                <el-dialog title="消息详情" :visible.sync="xiaoxiDialogVisible" :before-close="colsexiaoxiDialog" width="50%" class="xuqiuDialog">
                    <el-form ref="form" :model="xiaoxidetail" label-position="right" label-width="20%">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="消息标题:" label-width="10%" prop="xiaoxitype">{{xiaoxidetail.messageTitle}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="申请内容:" label-width="10%" prop="articleContent">{{xiaoxidetail.messageContent}}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="logtime">
                            <el-col :span="12">
                                <el-form-item label="创建时间:" label-width="20%">{{ xiaoxidetail.createTime }}</el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="更新时间:" label-width="20%">{{ xiaoxidetail.updateTime }}</el-form-item>
                            </el-col>
                        </div>
                        <el-form-item v-if="xiaoxidetail.xiaoxitype=='新建地址申请'" label-width="10%">
                            <el-button size="mini" type="primary" @click="goloupan">新建楼盘</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            roleType: localStorage.getItem("roleType"),
            xiaoxidetail: {},
            xiaoxiList: [],
            activeName: "",
            xiaoxiDialogVisible: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            messageTitle: "",
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ]
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.GetMessageList()
    },
    methods: {
        goback() {
            this.$router.go(-1)
            this.reload()
        },
        //关闭弹层
        colsexiaoxiDialog() {
            this.xiaoxiDialogVisible = false
            this.reload()
        },
        /* 获取消息详情 */
        async GetMessageDetail(row) {
            const data = {
                messageId: row.messageId
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMessageDetail,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.xiaoxidetail = res.dtList[0]
            } else {
                this.$message.success(res.message)
            }
        },
        // 查询
        cGetMessageList() {
            this.currentPage = 1
            this.GetMessageList()
        },
        //显示弹层
        openDetails2(row, scope) {
            this.xiaoxiDialogVisible = true
            if (row.isread == 0) {
                this.biaoji(row)
            }
            this.GetMessageDetail(row)
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取消息列表 */
        async GetMessageList() {
            const data = {
                messageTitle: this.messageTitle,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMessageList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.xiaoxiList = res.dtList
                this.loading = false
                this.total = res.maxRecords
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetMessageList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetMessageList()
        },
        //重置
        cancelbreedList() {
            this.messageTitle = ""
            this.GetMessageList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.messageId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteMessage, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        //标记已读
        async biaoji(row) {
            var data = [row.messageId]
            const { data: res } = await this.$http.put(
                this.$url.UpdateReadMessages,
                data,
                {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                }
            )
            if (res.msgCode == 1) {
                // this.GetMessageList()
            } else {
                this.$message.success(res.message)
            }
            // }
        },
        //多选标记已读
        async biaojis() {
            var data1 = this.multipleSelection
            var data = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                for (var i = 0; i < data1.length; i++) {
                    data.push(data1[i].messageId)
                }
                const { data: res } = await this.$http.put(
                    this.$url.UpdateReadMessages,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    }
                )
                if (res.msgCode == 1) {
                    this.reload()
                } else {
                    this.$message.success(res.message)
                }
            }
        },
        // 多选删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].messageId)
                        }
                        const { data: res } = this.$http.delete(
                            this.$url.DeleteMessage,
                            {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.reload()
                        } else {
                            this.$message.success(res.message)
                        }
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
h1 {
    margin-top: 10px;
}
.type {
    background: red;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    position: relative;
    top: -7px;
    margin-left: 4px;
    display: inline-block;
}
.shanchu {
    text-align: right;
    color: #999;
    display: none;
    cursor: pointer;
}
.milid {
    margin-top: 15px;
}
.time {
    color: #999;
    float: right;
    // text-align: right;
}
</style>
<style lang="less">
.xiaoxilist {
    .el-dialog__body {
        padding: 0 20px 30px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        display: inline-block;
        width: 100%;
    }
    // .el-collapse-item__content :hover .shanchu {
    //     display: block;
    // }
    // .el-collapse-item__header.is-active {
    //     background: #eee;
    // }
    // .el-collapse-item__header {
    //     padding-left: 15px;
    //     padding-right: 15px;
    // }
    // .el-collapse-item__content {
    //     padding: 15px 20px;
    // }
}
</style>
