export default {
  // 表单对象
  form: {
    companyName: "x", //企业名称
    companyMobile: "x", //企业联系电话
    contractNo: "x", //合同编号
    // 甲方
    jiaName: "x", //甲方姓名
    jiaAddress: "x", //甲方地址
    jiaIdCardNo: "x", //甲方证件号码
    jiaMobile: "x", // 甲方联系电话
    // 乙方
    yiName: "x", //乙方姓名
    yiAddress: "x", //乙方地址
    yiIdCardNo: "x", //乙方证件号码
    yiMobile: "x", // 乙方联系电话
    // 转让
    xiaoquName: "x", //转让小区名称
    xiaoquNo: "x", //转让小区编号
    parkingLotType: "", //停车位类型
    parkingLot: "x", //车位or车库

    // 第二条 价款及支付方式
    // 1.
    sec_carPrice_daxie: "x", //车位价格大写
    sec_carPrice_xiaoxie: "x", //车位价格小写
    // 2.
    sec_qiandingDay: "x", //协议签订几天后

    // 第三条 车位交付
    // 4.
    shifouType: "", //能否办理独立权属证书


    // 第五条 其他费用
    // 1.
    fif_jia_zhongjieFei_xiaoxie: "x", //中介费小写
    fif_jia_zhongjieFei_daxie: "x", //中介费大写
    fif_yi_zhongjieFei_xiaoxie: "x", //中介费小写
    fif_yi_zhongjieFei_daxie: "x", //中介费大写
    // 2.
    fif_chengdan:"x",

 

    // 签约
    qianShu_Year: "x", // 签署日期-年
    qianShu_Month: "x", // 签署日期-月
    qianShu_Day: "x", // 签署日期-日
    qianshu_jjrName: "x", // 经纪人姓名
    qianshu_jjrMobile: "x", // 经纪人手机号


    // 单选的值
    diShangCheWei: "□", //地上车位
    diXiaCheWei: "□", //地下车位
    shi: "□",
    fou: "□",
  },

  formCheck: {
    parkingLotType: "", //停车位类型
    shifouType: "", //能否办理独立权属证书
  },
}
