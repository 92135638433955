<template>
  <div>
    <el-card class="search">
      <el-row v-if="roleType == 5 || roleType == 6">
        <span class="title">层级:</span>
        <div
          v-for="item in item1"
          :key="item.label"
          :type="item.type"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isActive1 === item.label }"
          @click="changeClass1(item.label, item.value)"
        >
          {{ item.label }}
        </div>
      </el-row>
      <el-row v-if="roleType == 1 || roleType == 2">
        <span class="title">层级:</span>
        <div
          v-for="item in item2"
          :key="item.label"
          :type="item.type"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isActive2 === item.label }"
          @click="changeClass2(item.label, item.value)"
        >
          {{ item.label }}
        </div>
      </el-row>
      <el-row>
        <span class="title">客户需求:</span>
        <div
          v-for="item in item3"
          :key="item.label"
          :type="item.type"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isActive3 === item.label }"
          @click="changeClass3(item.label, item.value)"
        >
          {{ item.label }}
        </div>
      </el-row>
      <el-row>
        <span class="title">进池时间:</span>
        <div
          v-for="item in item5"
          :key="item.label"
          :type="item.type"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isActive5 === item.label }"
          @click="changeClass5(item.label, item.value)"
        >
          {{ item.label }}
        </div>
      </el-row>
      <el-row>
        <span class="title">原维护人:</span>
        <div
          v-for="item in item6"
          :key="item.label"
          :type="item.type"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isActive6 === item.label }"
          @click="changeClass6(item.label, item.value)"
        >
          {{ item.label }}
        </div>
      </el-row>
       <el-row style="margin-top: 10px">

        <el-col :span="6">
          <span style="font-size: 14px">手机号：</span>
          <el-input
            placeholder="请输入客户手机号"
            style="width: 250px"
            maxlength="12"
            v-model="tel"
          ></el-input>
        </el-col>


        <el-col :span="6">
          <span style="float: left">
            <el-button
              size="mini"
              style="margin-left: 20px"
              type="primary"
              @click="getCustomerPoolList"
              >查询</el-button
            >
            <el-button size="mini" @click="reset" type="warning"
              >重置</el-button
            >
          </span>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div style="padding: 10px 10px 0 10px">
        共有{{ total }}条数据
        <el-button
          v-if="roleType == 1"
          size="mini"
          @click="renling('', 'two')"
          style="margin-right: 15px; float: right"
          type="primary "
          >批量认领</el-button
        >
        <!-- <el-button v-if="roleType == 2||roleType == 5||roleType == 6" size="mini" @click="fenpei1(1)" style="margin-right: 15px;float:right;" type="primary ">批量分配</el-button> -->
      </div>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="gongxiangList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
        class="matop0"
      >
        <el-table-column type="selection" width="40"> </el-table-column>
        <el-table-column label="客户名称" prop="customerName">
          <template slot-scope="scope">
            <el-button
              style="width: 92%"
              class="tableButSpan el-rate-css"
              @click="jumpToDetail(scope.row, 2, 3)"
              type="text"
              size="mini"
            >
              {{ scope.row.customerName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="原维护人" prop="userName"></el-table-column>
        <el-table-column
          label="租售类型"
          prop="IsBuyOrToRent"
        ></el-table-column>
        <!-- <el-table-column label="详细需求" style="text-align: left;" width="350" prop="requirementInfo"></el-table-column> -->
        <el-table-column
          label="进池时间"
          prop="entryPoolTime"
        ></el-table-column>
        <el-table-column label="掉落原因" prop="reason"></el-table-column>
        <el-table-column v-if="roleType == 1" label="操作">
          <template slot-scope="scope" class="right">
            <el-button
              v-if="roleType == 1"
              @click.native.stop="renling(scope.row, 'one')"
              type="text"
              size="mini"
              class="el-icon-plus"
              style="color: #df0202"
              >认领</el-button
            >
            <!-- <el-button v-if="roleType == 2||roleType == 5||roleType == 6" @click.native.stop="fenpei(scope.$index, scope.row,0)" type="text" size="mini" class="el-icon-plus" style="color: #df0202;">去分配</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
          <img
            style="width: 200px; margin-top: 50px"
            src="../../../public/img/noList.png"
          />
          <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
        </div>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="指定经纪人"
      :visible.sync="gongxiangDialog"
      width="70%"
      :before-close="handleClose1"
    >
      <TongXunLuList
        :isFenPeiZiYuan="true"
        @colseJingJiRenDialog="isJingJiRenData"
      ></TongXunLuList>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gongxiangDialog = false">取 消</el-button>
        <el-button type="primary" @click="gongxiangDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
var _index;
import TongXunLuList from "../TongXunLu/TongXunLuList.vue";
export default {
  components: {
    TongXunLuList
  },
  name: "KeYuanGongXiangChiList",
  inject: ["reload"],
  data() {
    return {
      roleType: "",
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      gongxiangDialog: false,
      isActive1: "不限",
      isActive2: "本商户",
      isActive3: "不限",
      isActive4: "不限",
      isActive5: "不限",
      isActive6: "不限",
      gongxiangList: [],
      item1: [
        {
          value: "0",
          label: "不限"
        },
        {
          value: "1",
          label: "本店铺"
        }
      ],
       item2: [
        {
          value: "0",
          label: "本商户"
        },
        {
          value: "1",
          label: "本店铺"
        }
      ],
      item3: [
        {
          value: "",
          label: "不限"
        },
        {
          value: "1",
          label: "买房"
        },
        {
          value: "0",
          label: "租赁"
        }
      ],
      item5: [
        {
          value: "",
          label: "不限"
        },
        {
          value: "1",
          label: "今天"
        },
        {
          value: "2",
          label: "近三天"
        },
        {
          value: "3",
          label: "本周"
        }
      ],
      item6: [
        {
          value: "0",
          label: "不限"
        },
        {
          value: "1",
          label: "我自己"
        }
      ],
      multipleSelection: [],
      isHouseToSell: " ",
      reason: "",
      entryPoolTimeStart: "",
      userId: "",
      IsOurShop: "",
      tel:"",
      form: {
        userId: "",
        customerId: ""
      },
      KeYuanGongXiangChiData: JSON.parse(
        window.localStorage.getItem("KeYuanGongXiangChiData")
      ),
    };
  },
  created() {
    /* 获取所有的日志列表 */
    this.roleType = window.localStorage.getItem("roleType");
    this.userId = window.localStorage.getItem("userId");
    // if (this.roleType == 5 || this.roleType == 6) {
    //   this.IsOurShop = "0";
    // } else if (this.roleType == 1 || this.roleType == 2) {
    //   this.IsOurShop = "1";
    // }
    this.IsOurShop = "0";
    if (this.KeYuanGongXiangChiData) {
      this.currentPage = this.KeYuanGongXiangChiData.CurrentPage;
      this.pageSize = this.KeYuanGongXiangChiData.PageSize;
    }
    this.getCustomerPoolList();
  },
  methods: {
    // 查看详情
    //1求购 0是求租
    jumpToDetail(row, num, type) {
      if (row.IsBuyOrToRent == "求购") {
        this.isHouseToSell = "1";
      } else {
        this.isHouseToSell = "0";
      }
      this.$router.push({
        name: "KeYuanDetail",
        query: {
          customerId: row.customerId,
          num: num,
          state: this.isHouseToSell,
          type: type
        }
      });
    },
    getCustomerPoolList() {
      let that = this;
      const data = {
        IsOurShop: that.IsOurShop,
        isToBuy: that.isHouseToSell,
        reason: that.reason,
        entryPoolTimeStart: that.entryPoolTimeStart,
        exMaintenanceUserId: that.exMaintenanceUserId,
        tel:that.tel,
        CurrentPage: that.currentPage,
        PageSize: that.pageSize
      };
      localStorage.setItem("KeYuanGongXiangChiData", JSON.stringify(data));
      this.$http
        .post(this.$url.CustomerPoolList, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.total = res.data.maxRecords;
            that.gongxiangList = res.data.dtList;
          }
        });
    },
    handleClose1() {
      this.gongxiangDialog = false;
    },
    fenpei(index, row, val) {
      this.val = val;
      this.gongxiangDialog = true;
      this.form.customerId = row.customerId;
    },
    isJingJiRenData(row) {
      console.log(row);
      console.log("asd", this.val);
      this.form.userId = row.userId;
      this.gongxiangDialog = false;
      if (this.val == 1) {
        // this.updateCustomerPoolRenLing1()
      } else if (this.val == 0) {
        // this.updateCustomerPoolRenLing()
      }
    },
    changeClass1(i, v) {
      this.isActive1 = i;
      if (v == "1") {
        this.IsOurShop = "1";
      } else {
        this.IsOurShop = "0";
      }
      this.getCustomerPoolList();
    },
    changeClass2(i, v) {
      this.isActive2 = i;
      if (v == "1") {
        this.IsOurShop = "1";
      } else {
        this.IsOurShop = "0";
      }
      this.getCustomerPoolList();
    },
    changeClass3(i, v) {
      this.isActive3 = i;
      this.isHouseToSell = v;
      this.getCustomerPoolList();
    },
    changeClass5(i, v) {
      this.isActive5 = i;
      var myDate = new Date();
      var myYear = myDate.getFullYear();
      var myMonth = myDate.getMonth() + 1;
      var myDay = myDate.getDate();
      var myHour = myDate.getHours();
      var myMinute = myDate.getMinutes();
      var myTime = myDate.getSeconds();
      if (v != "") {
        if (v == 2) {
          myDay = myDay - 3;
        } else if (v == 3) {
          myDay = myDay - 7;
        }
        this.entryPoolTimeStart =
          myYear +
          "/" +
          (myMonth < 10 ? "0" + myMonth : myMonth) +
          "/" +
          (myDay < 10 ? "0" + myDay : myDay) +
          " " +
          "00" +
          ":" +
          "00" +
          ":" +
          "00";
      } else {
        this.entryPoolTimeStart = "";
      }
      this.getCustomerPoolList();
    },
    changeClass6(i, v) {
      this.isActive6 = i;
      if (v == 0) {
        this.exMaintenanceUserId = "";
      } else if (v == 1) {
        this.exMaintenanceUserId = this.userId;
      }
      this.getCustomerPoolList();
    },
    // 获取多选框的值
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getCustomerPoolList();
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getCustomerPoolList();
    },
    renling(row, type) {
      if (type == "two" && this.multipleSelection.length == 0) {
        this.$confirm("请勾选数据", "提示", {
          type: "warning"
        });
      } else {
        this.$confirm("此操作将认领该任务, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            const data = [];
            if (type == "one") {
              data.push(row.customerId);
            } else {
              this.multipleSelection.forEach(i => {
                data.push(i.customerId);
              });
            }
            this.$http
              .put(this.$url.UpdateCustomerPoolRenLing, data, {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8"
                }
              })
              .then(res => {
                if (res.data.msgCode == 1) {
                  this.$message.success("认领成功");
                  this.multipleSelection = [];
                  this.getCustomerPoolList();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch(err => {
                console.log(err.data);
              });
          })
          .catch(err => {});
      }
    },
    reset(){
      this.tel = "";
      this.getCustomerPoolList();
    }
    // updateCustomerPoolRenLing(){
    //     const data = []
    //     data.push(this.form.userId,this.form.customerId)
    //     this.$http
    //         .put(this.$url.UpdateCustomerPoolRenLing, data, {
    //             headers: {
    //                 "Content-Type": "application/json;charset=UTF-8"
    //             }
    //         })
    //         .then((res) => {
    //             if (res.data.msgCode == 1) {
    //                 this.getCustomerPoolList()
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err.data)
    //         })
    // },
    // updateCustomerPoolRenLing1(){
    //     var data = []
    //     data.push(this.form.userId)
    //     this.multipleSelection.forEach((i) => {
    //         data.push(i.customerId)
    //     })
    //     this.$http
    //         .put(this.$url.UpdateCustomerPoolRenLing, data, {
    //             headers: {
    //                 "Content-Type": "application/json;charset=UTF-8"
    //             }
    //         })
    //         .then((res) => {
    //             if (res.data.msgCode == 1) {
    //                 this.getCustomerPoolList()
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err.data)
    //         })
    // },
  }
};
</script>
