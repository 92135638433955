<template>
    <div>
        <div>
            <el-card class="search">
                <el-row class="mabot8">
                    <el-col>
                        <span style="font-size: 14px;">操作区间:</span>
                        <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="startTime" style="width: 20%; margin-left:10px" :picker-options="pickerOptionsStart"></el-date-picker>
                        <span style="margin-left: 15px;">-</span>
                        <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="endTime" style="width: 20%;margin-left:15px" :picker-options="pickerOptionsEnd"></el-date-picker>
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetRiZhiList">查询</el-button>
                            <el-button size="mini" @click="cancelRiZhiList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" border stripe>
                    <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                    <!-- <el-table-column label="客户名" prop="objectName"> -->
                    <!-- <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.objectName }}</el-button>
                        </template> -->
                    <!-- </el-table-column> -->
                    <el-table-column label="操作模块" prop="objectName"></el-table-column>
                    <el-table-column label="操作方式" prop="operation"></el-table-column>
                    <el-table-column label="IP地址" prop="ipAddress"></el-table-column>
                    <el-table-column width="550" label="内容" prop="contents"></el-table-column>
                    <el-table-column label="操作时间" prop="createTime" width="160"></el-table-column>
                    <!-- <el-table-column label="楼盘" prop="enabled"></el-table-column>
                    <el-table-column label="看房过程/记录" prop="createTime"></el-table-column>
                    <el-table-column label="沟通结果" prop="enabled"></el-table-column> -->
                    <el-table-column label="操作人" prop="createPersonName"></el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope" class="right"> -->
                    <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

                    <!-- <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="chengjiao(scope.row)" type="text" size="mini" class="el-icon-check" style="color: #f6ba03;">成交</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column> -->
                    <!-- <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div> -->
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            startTime: "",
            endTime: "",
            pickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.endTime) {
                        return time.getTime() > new Date(this.endTime).getTime()
                    }
                }
            },
            pickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.startTime) {
                        return (
                            time.getTime() <
                            new Date(this.startTime).getTime() - 86400000
                        )
                    }
                }
            },
            drow: "",
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: []
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.char = "-"
        var nowDate = new Date()
        var lastDate = new Date(nowDate - 1000 * 60 * 60 * 24 * 30)
        var day = nowDate.getDate()
        var day1 = lastDate.getDate()
        var month = nowDate.getMonth() + 1
        var month1 = lastDate.getMonth() + 1
        var year = nowDate.getFullYear()
        var year1 = lastDate.getFullYear()
        var hour = nowDate.getHours()
        var minute = nowDate.getMinutes()
        var seconds = nowDate.getSeconds()
        this.startTime =
            year1 +
            this.char +
            this.completeDate(month1) +
            this.char +
            this.completeDate(day1)
        this.endTime =
            year +
            this.char +
            this.completeDate(month) +
            this.char +
            this.completeDate(day) +
            "T" +
            this.completeDate(hour) +
            ":" +
            this.completeDate(minute) +
            ":" +
            this.completeDate(seconds)
        this.GetLogList()
    },
    methods: {
        // 查询
        cgetRiZhiList() {
            this.currentPage = 1
            this.GetLogList()
        },
        //补全0
        completeDate(value) {
            return value < 10 ? "0" + value : value
        },
        /* 获取日志列表 */
        async GetLogList() {
            const data = {
                startTime: encodeURIComponent(this.startTime),
                endTime: this.endTime,
                currentPage: encodeURIComponent(this.currentPage),
                pageSize: encodeURIComponent(this.pageSize)
            }
            this.loading = true
            const { data: res } = await this.$http.get(this.$url.GetLogList, {
                params: data
            })
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
            } else {
                this.$message.error(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetLogList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetLogList()
        },
        //重置
        cancelRiZhiList() {
            this.char = "-"
            var nowDate = new Date()
            var day = nowDate.getDate()
            var month = nowDate.getMonth() + 1
            var month1 = nowDate.getMonth()
            var year = nowDate.getFullYear()
            var hour = nowDate.getHours()
            var minute = nowDate.getMinutes()
            var seconds = nowDate.getSeconds()
            this.startTime =
                year +
                this.char +
                this.completeDate(month1) +
                this.char +
                this.completeDate(day)
            this.endTime =
                year +
                this.char +
                this.completeDate(month) +
                this.char +
                this.completeDate(day) +
                "T" +
                this.completeDate(hour) +
                ":" +
                this.completeDate(minute) +
                ":" +
                this.completeDate(seconds)

            this.GetLogList()
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
