<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
    </el-breadcrumb>-->
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/YeJiList' }">
                <el-button size="mini" class="el-button1" type="primary" style="float: right">返回</el-button>
            </router-link>
        </el-card>

        <!-- <el-card class="yeji-hetong">
            <h3 class="h1-title">合同基本信息</h3>
            <div class="hetong-xinxi margin-top">
                <img src="../../../public/img/logo.jpg" alt style="width: 170px;height: 130px;margin-top: 15px;float: left;" />
                <div style="width: 83%;margin-left: 3%;float: left;">
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">合同编号:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">业务类型:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">合同价格:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">客源编号:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">客户姓名:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">业主姓名:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">客源编号:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">签约职员:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">签约组织:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" class="mr20">
                            <span class="titleLabel">产权地址:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">签约时间:</span>
                            <span class="labelValue">112225564646131</span>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-card> -->

        <!-- <div class="yeji-fenpei" style="margin: 30px auto 15px;height: 30px;">
            <div style="float: left;font-size: 20px;font-weight: bold;color:#606266">业绩分配</div>
            <div style="float: right;color: #fff;background-color: #df0202;padding: 3px 15px;border-radius: 3px;">日志</div>
        </div> -->
        <!-- <el-card class="yeji-fenpei">
             <h3 class="h1-title">业绩分配</h3> 
            <div class="margin-top0">
                <div style="width: 83%;float: left;">
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">当前状态:</span>
                            <span class="labelValue">审核通过</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">审核人:</span>
                            <span class="labelValue">xxxx</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">审核时间:</span>
                            <span class="labelValue">2020-10-20 13:19:55</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">审核/驳回理由:</span>
                            <span class="labelValue"></span>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-card> -->

        <!-- <el-card class="jujiadaili">
            <h3 style="margin: 0 auto">居家代理费</h3>
            <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column label="角色类型" prop="name">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                        <div style="width: 25px;height: 25px;float: right;">
                            <img style="width:100%;height:100%;object-fit: cover;" src="../../../public/img/new-icon.png" alt />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="分配比例" prop="TakeLook"></el-table-column>
                <el-table-column label="角色人" prop="transactionNumber">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.transactionNumber" placement="top-start">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: red;">{{ scope.row.transactionNumber }}</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="角色人店主" prop="commission"></el-table-column>
                <el-table-column label="商圈经理" prop="commission">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.commission" placement="top-start">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: red;">{{ scope.row.commission }}</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="大区" prop="commission"></el-table-column>
                <el-table-column label="总监" prop="commission">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.commission" placement="top-start">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: red;">{{ scope.row.commission }}</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="加盟商" prop="commission"></el-table-column>
                <el-table-column label="分公司" prop="commission"></el-table-column>
                <el-table-column label="城市" prop="commission"></el-table-column>
                <el-table-column label="平台" prop="commission"></el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>
        </el-card> -->

        <el-card class="jujiadaili">
            <h3 style="margin: 0 auto">业绩明细</h3>
            <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" border stripe>

                <el-table-column label="结算月" prop="yyyyMM"></el-table-column>
                <el-table-column label="合同编号" prop="contractNo"></el-table-column>
                <el-table-column label="经纪人" prop="userName">
                    <!-- <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.userName" placement="top-start">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: red;">{{ scope.row.userName }}</el-button>
                        </el-tooltip>
                    </template> -->
                </el-table-column>
                <el-table-column label="业绩类型" prop="performanceType"></el-table-column>
                <el-table-column label="业绩" prop="performanceAmount"></el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            diseaseIdsid: "",
            num: this.$route.query.num,
            userId: this.$route.query.userId,
            yyyyMM: this.$route.query.yyyyMM,
            name: "",
            mm: "",
            options: [],
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            operationList: [],
            MyHeader: { accessToken: "", fp: "" },
            loading: false,
            diseaseIds: [],
            id: "",
            form: {
                articleTitle: "",
                articleContent: "",
                firstPic: "",
                weight: "",
                enabled: ""
            },
            options1: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            diseaseId: [],
            dialogFormVisible: false,
            diseaseName: "",
            petType: "",
            currentPage: 1,
            pageSize: 100,
            rules: {
                articleTitle: [
                    { required: true, message: "请输入联系人", trigger: "blur" }
                ],
                articleContent: [
                    { required: true, message: "请选选择职位", trigger: "blur" }
                ],
                weight: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur"
                    }
                ],
                firstPic: [
                    {
                        required: true,
                        message: "请上传文章图片",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        if (this.num == 1) {
            this.name = "业绩详情"
            this.flag = true
            this.isMax = true
            this.GetPerformanceDetail()
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log(this.multipleSelection);
        },
        // 详情
        async GetPerformanceDetail() {
            await this.$http
                .get(this.$url.GetPerformanceDetail, {
                    params: {
                        userId: encodeURIComponent(this.userId),
                        yyyyMM: this.yyyyMM
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.operationList = res.data.dtList
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        handleBeforeRemove(file, fileList) {
            var mm = file.url.slice(47)
            this.mm = mm
            // alert(this.mm)
            return this.$confirm(`确定移除 ？`).then(() => {
                // this.$message.success("已移除");
                var data = {
                    firstPic: mm,
                    moduleName: "PetBreed"
                }
                this.$http.delete(
                    this.$url.deleteOneFile(mm, data.moduleName),
                    {
                        data
                    }
                )
                if (res.msgCode == 1) {
                    this.$message.success("删除成功!")
                    this.isMax = false

                    this.form.firstPic = ""
                } else {
                    this.$message.success(res.message)
                }
            })
        },
        cancel() {
            this.$router.push({ path: "/TongXunLuList" })
        },
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].reportId)
                        }
                        this.$http
                            .delete(this.$url.deleteReport, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        handleDelete(index) {
            var data1 = [index.reportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.deleteReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {})
        },
        alertInsert() {
            this.dialogFormVisible = true
        },
        edit() {
            this.diseaseIds.push()
            // console.log(this.diseaseIds)
            this.dialogFormVisible = false
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>