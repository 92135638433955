<template>
    <el-container class="home-container">
        <!-- 头部区域 -->
        <el-header style="height: 40px">
            <div>
                <img src="../../public/img/logoIndex.jpg" alt="" style="width: 35px; padding-left: 68px" />
            </div>
            <div><span v-if="total != 0" style="
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: white;
            position: relative;
            left: 42px;
            top: -6px;
          "></span>
                <el-popover v-if="total != 0" placement="bottom" width="160" trigger="hover">
                    <div>您有{{ total }}条新的消息</div>
                    <span slot="reference" class="el-icon-bell" style="
            font-size: 18px;
            line-height: 40px;
            position: relative;
            right: 8px;
            top: 0px;
          " @click="changeXiaoxiTab"></span>
                </el-popover>
                <span v-else slot="reference" class="el-icon-bell" style="
            font-size: 18px;
            line-height: 40px;
            position: relative;
            right: 8px;
            top: 0px;
          " @click="changeXiaoxiTab"></span>
                <span style="font-size: 14px; margin-right: 15px; color: #eee;vertical-align:middle">等级：{{ userLevel
                }}</span>
                <span style="font-size: 14px; margin-right: 15px; color: #eee;vertical-align:middle">积分：{{ integral
                }}</span>
                <el-dropdown @command="handleCommand">
                    <div>
                        <img :src="src" style="
                border-radius: 50%;
                width: 20px;
                height: 20px;
                cursor: pointer;
                vertical-align: middle;
              " />

                        <span style="font-size: 14px; margin-right: 15px; color: #eee;vertical-align:middle">{{
                            staffName
                        }}</span>

                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item divided></el-dropdown-item>
                        <span v-for="item in userList" :key="item.userType">
                            <el-dropdown-item @click.native="changeUser(item)">{{
                                item
                            }}</el-dropdown-item>
                        </span>
                        <el-dropdown-item divided></el-dropdown-item>
                        <el-dropdown-item command="修改个人信息">修改个人信息</el-dropdown-item>
                        <el-dropdown-item command="修改密码">修改密码</el-dropdown-item>

                        <el-dropdown-item command="消息中心">消息中心</el-dropdown-item>
                        <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <span style="font-size: 14px">{{ roleTypes }}</span>
            </div>
        </el-header>
        <!-- 页面主体区域 -->
        <el-container>
            <!-- 侧边栏 -->
            <el-aside :width="isCollapse ? '64px' : '170px'">
                <div class="toggle-button" @click="toggleCollapse">|||</div>
                <!-- 侧边栏菜单区域 -->
                <el-menu :default-active="activeIndex" background-color="#df0202" text-color="#fff" active-text-color="#fff"
                    unique-opened :collapse="isCollapse" :collapse-transition="false" router>
                    <!-- 一级菜单 -->
                    <!-- 一级菜单的模板区域 -->
                    <el-menu-item @click="addTab('首页', 'GetStatistics')" index="GetStatistics">
                        <i class="icon iconfont">&#xe61a;</i>
                        <span slot="title">首页</span>
                    </el-menu-item>
                    <el-submenu index="2"
                        v-if="roleType == '1' || roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'">
                        <template slot="title">
                            <i class="icon iconfont">&#xe632;</i>
                            <span slot="title">房源</span>
                        </template>
                        <el-menu-item index="XinFangList" @click="addTab('新房', 'XinFangList')">
                            <i class="icon iconfont">&#xe62d;</i>
                            <span slot="title">新房</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('二手房出租', 'ChuZuList')" index="ChuZuList">
                            <i class="icon iconfont">&#xe612;</i>
                            <span slot="title">二手房出租</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('二手房出售', 'ChuShouList')" index="ChuShouList">
                            <i class="icon iconfont">&#xe612;</i>
                            <span slot="title">二手房出售</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('房源跟进记录', 'FangYuanGenJinJiLuList')" index="FangYuanGenJinJiLuList">
                            <i class="icon iconfont">&#xe69c;</i>
                            <span slot="title">跟进记录</span>
                        </el-menu-item>
                        <el-menu-item v-if="roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'"
                            @click="addTab('分配房源', 'FenPeiZiYuanList')" index="FenPeiZiYuanList">
                            <img class="icon iconfont" src="../../public/img/fenpei.png" />
                            <span slot="title">分配房源</span>
                        </el-menu-item>
                        <el-menu-item index="FangYuanGongXiangChiList" @click="addTab('房源共享池', 'FangYuanGongXiangChiList')">
                            <i class="icon iconfont">&#xe608;</i>
                            <span slot="title">房源共享池</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="3"
                        v-if="roleType == '1' || roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'">
                        <template slot="title">
                            <i class="icon iconfont">&#xe697;</i>

                            <span slot="title">客源</span>
                        </template>
                        <el-menu-item @click="addTab('客源', 'KeYuanList')" index="KeYuanList">
                            <i class="el-icon-user" style="margin-left: -3px;"></i>
                            <span slot="title">客源</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('客源跟进记录', 'KeHuGenJinJiLuList')" index="KeHuGenJinJiLuList">
                            <i class="icon iconfont">&#xe69c;</i>
                            <span slot="title">跟进记录</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('带看记录', 'ZuoYeJiLuList')" index="ZuoYeJiLuList">
                            <i class="icon iconfont">&#xe6bb;</i>
                            <span slot="title">带看记录</span>
                        </el-menu-item>
                        <el-menu-item v-if="roleType == '2' || roleType == '5' || roleType == 6 || roleType == '9'"
                            @click="addTab('分配客源', 'FenPeiKeYuanList')" index="FenPeiKeYuanList">
                            <i class="icon iconfont">&#xe6bb;</i>
                            <span slot="title">分配客源</span>
                        </el-menu-item>
                        <el-menu-item index="KeYuanGongXiangChiList" @click="addTab('客源共享池', 'KeYuanGongXiangChiList')">
                            <i class="icon iconfont">&#xe608;</i>
                            <span slot="title">客源共享池</span>
                        </el-menu-item>
                        <el-menu-item index="KeYuanZiXunList" @click="addTab('客源咨询', 'KeYuanZiXunList')">
                            <i class="el-icon-phone-outline"></i>
                            <span slot="title">客户咨询</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item
                        v-if="roleType == '1' || roleType == '2' || roleType == '3' || roleType == '5' || roleType == '6' || roleType == '7' || roleType == '9'"
                        index="QianYueList" @click="addTab('签约', 'QianYueList')">
                        <i class="icon iconfont">&#xe63d;</i>
                        <span slot="title">签约</span>
                    </el-menu-item>
                    <el-menu-item v-if="roleType == '3' || roleType == '5' || roleType == '6'" index="CaiWuList"
                        @click="addTab('财务', 'CaiWuList')">
                        <i class="icon iconfont">&#xe67a;</i>
                        <span slot="title">财务</span>
                    </el-menu-item>
                    <el-submenu index="1" v-if="roleType != '7'">
                        <template slot="title">
                            <i class="icon iconfont">&#xe67e;</i>
                            <span slot="title">业务</span>
                        </template>
                        <el-menu-item v-if="roleType != '3'" @click="addTab('成交记录', 'JiaoYiJiLuList')"
                            index="JiaoYiJiLuList">
                            <i class="icon iconfont">&#xe60e;</i>
                            <span slot="title">成交记录</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('业绩列表', 'YeJiList')" index="YeJiList">
                            <i class="icon iconfont">&#xe619;</i>
                            <span slot="title">业绩列表</span>
                        </el-menu-item>
                        <el-menu-item v-if="roleType != '1' && roleType != '4' && roleType != '3'"
                            @click="addTab('作业统计', 'JiXiaoTongJiList')" index="JiXiaoTongJiList">
                            <i class="icon iconfont">&#xe61d;</i>
                            <span slot="title">作业统计</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="4" v-if="roleType != '3'">
                        <template slot="title">
                            <i class="icon iconfont">&#xe666;</i>
                            <span slot="title">知识库</span>
                        </template>
                        <el-menu-item v-if="roleType == '6'" index="LouPanZiDian1List"
                            @click="addTab('楼盘字典', 'LouPanZiDian1List')">
                            <i class="icon iconfont">&#xe6ea;</i>
                            <span slot="title">楼盘字典</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('政策文件', 'ZhengCeWenJianList')" index="ZhengCeWenJianList">
                            <i class="icon iconfont">&#xe623;</i>
                            <span slot="title">政策文件</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('规章制度', 'GuiZhangZhiDuList')" index="GuiZhangZhiDuList">
                            <i class="icon iconfont">&#xe623;</i>
                            <span slot="title">规章制度</span>
                        </el-menu-item>
                        <el-menu-item @click="addTab('培训课程', 'PeiXunKeChengList')" index="PeiXunKeChengList">
                            <i class="icon iconfont">&#xe623;</i>
                            <span slot="title">培训课程</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item
                        v-if="roleType == '1' || roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'"
                        index="TongXunLuList" @click="addTab('经纪人通讯录', 'TongXunLuList')">
                        <i class="icon iconfont">&#xe711;</i>
                        <span slot="title">经纪人通讯录</span>
                    </el-menu-item>
                    <el-submenu index="5">
                        <template slot="title">
                            <i class="icon iconfont">&#xe624;</i>
                            <span slot="title">工作总结</span>
                        </template>
                        <el-menu-item @click="addTab('业绩明细', 'YeJiMingXiList')" index="YeJiMingXiList">
                            <i class="icon iconfont">&#xe659;</i>
                            <span slot="title">业绩明细</span>
                        </el-menu-item>
                        <el-menu-item
                            v-if="roleType == '1' || roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'"
                            @click="addTab('工作日报', 'GongZuoRiBaoList')" index="GongZuoRiBaoList">
                            <i class="icon iconfont">&#xe604;</i>
                            <span slot="title">工作日报</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-if="roleType == '2' || roleType == '5' || roleType == '6' || roleType == '9'"
                        index="GongGaoList" @click="addTab('公告管理', 'GongGaoList')">
                        <i class="icon iconfont">&#xe68a;</i>
                        <span slot="title">公告管理</span>
                    </el-menu-item>
                    <el-menu-item v-if="roleType == '3' ||
                        roleType == '4' ||
                        roleType == '5' ||
                        roleType == '6'
                        " index="GongZiList" @click="addTab('工资表', 'GongZiList')">
                        <i class="icon iconfont">&#xe605;</i>
                        <span slot="title">工资表</span>
                    </el-menu-item>
                    <!-- <el-menu-item v-if="roleType == '3' || roleType == '6'" index="BaoXiaoJiLuList" @click="addTab('报销记录', 'BaoXiaoJiLuList')">
                        <i class="icon iconfont">&#xe66f;</i>
                        <span slot="title">报销记录</span>
                    </el-menu-item> -->
                    <el-menu-item v-if="roleType == '3' ||
                        roleType == '4' ||
                        roleType == '5' ||
                        roleType == '6'
                        " index="RenYuanGuanLiList" @click="addTab('人员管理', 'RenYuanGuanLiList')">
                        <i class="icon iconfont">&#xe62e;</i>
                        <span slot="title">人员管理</span>
                    </el-menu-item>
                    <el-menu-item v-if="roleType == '5' || roleType == '6' || roleType == '4'" index="ZuZhiJiaGouList"
                        @click="addTab('组织架构', 'ZuZhiJiaGouList')">
                        <i class="icon iconfont">&#xe620;</i>
                        <span slot="title">组织架构</span>
                    </el-menu-item>
                    <el-menu-item v-if="roleType == '5' || roleType == '6'" index="DianPuList"
                        @click="addTab('店铺管理', 'DianPuList')">
                        <i class="el-icon-s-shop"></i>
                        <span slot="title">店铺管理</span>
                    </el-menu-item>
                    <el-submenu index="7" v-if="roleType == '5' || roleType == '6'">
                        <template slot="title">
                            <i class="el-icon-reading"></i>
                            <span slot="title">数据字典</span>
                        </template>
                        <el-menu-item index="ShangQuanList" @click="addTab('商圈', 'ShangQuanList')">
                            <i class="el-icon-bangzhu"></i>
                            <span slot="title">商圈</span>
                        </el-menu-item>
                        <el-menu-item index="JiaoYiQuanShuList" @click="addTab('交易权属', 'JiaoYiQuanShuList')">
                            <i class="el-icon-tickets"></i>
                            <span slot="title">交易权属</span>
                        </el-menu-item>
                        <el-menu-item index="FangWuYongTuList" @click="addTab('房屋用途', 'FangWuYongTuList')">
                            <i class="el-icon-school"></i>
                            <span slot="title">房屋用途</span>
                        </el-menu-item>
                        <el-menu-item index="KeHuBiaoQianList" @click="addTab('客户标签', 'KeHuBiaoQianList')">
                            <i class="el-icon-paperclip"></i>
                            <span slot="title">客户标签</span>
                        </el-menu-item>
                        <!-- <el-menu-item index="HuXingList" @click="addTab('户型', 'HuXingList')">
                            <i class="el-icon-house"></i>
                            <span slot="title">户型</span>
                        </el-menu-item> -->
                        <el-menu-item index="WuYeLeiXingList" @click="addTab('物业类型', 'WuYeLeiXingList')">
                            <i class="el-icon-discount"></i>
                            <span slot="title">物业类型</span>
                        </el-menu-item>
                        <el-menu-item index="FuYongFangShiList" @click="addTab('付佣方式', 'FuYongFangShiList')">
                            <i class="el-icon-mobile-phone"></i>
                            <span slot="title">付佣方式</span>
                        </el-menu-item>
                        <el-menu-item index="XinXiLaiYuanList" @click="addTab('信息来源', 'XinXiLaiYuanList')">
                            <i class="el-icon-chat-dot-round"></i>
                            <span slot="title">信息来源</span>
                        </el-menu-item>
                        <el-menu-item index="zhuangxiuzhuangkuangList" @click="addTab('装修状况', 'zhuangxiuzhuangkuangList')">
                            <i class="el-icon-guide"></i>
                            <span slot="title">装修状况</span>
                        </el-menu-item>
                        <el-menu-item index="JianZhuJieGouList" @click="addTab('建筑结构', 'JianZhuJieGouList')">
                            <i class="el-icon-price-tag"></i>
                            <span slot="title">建筑结构</span>
                        </el-menu-item>
                        <el-menu-item v-if="roleType == '5' || roleType == '6'" index="TiChengBiList"
                            @click="addTab('提成比设置', 'TiChengBiList')">
                            <i class="el-icon-setting"></i>
                            <span slot="title">提成比设置</span>
                        </el-menu-item>
                    </el-submenu>

                    <!-- <el-menu-item v-if="roleType == '6'" index="JueSeQuanXianList" @click="addTab('角色权限', 'JueSeQuanXianList')">
                        <i class="el-icon-thumb"></i>
                        <span slot="title">角色权限</span>
                    </el-menu-item> -->
                    <!-- <el-menu-item v-if="roleType == '6'" index="ShouXuList" @click="addTab('手续管理', 'ShouXuList')">
                        <i class="el-icon-notebook-2"></i>
                        <span slot="title">手续管理</span>
                    </el-menu-item> -->
                    <el-menu-item v-if="roleType == '6'" index="ShangHuGuanLiList"
                        @click="addTab('商户管理', 'ShangHuGuanLiList')">
                        <i class="el-icon-office-building"></i>
                        <span slot="title">商户管理</span>
                    </el-menu-item>
                    <el-menu-item v-if="roleType == '6'" index="RiZhiList" @click="addTab('日志列表', 'RiZhiList')">
                        <i class="el-icon-notebook-2"></i>
                        <span slot="title">日志列表</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <!-- 右侧内容主体 -->
            <el-main>
                <!-- tab页选项卡 -->
                <el-tabs class="tabs-caidan" style="height: 25px; position: relative; top: 0" v-model="editableTabsValue"
                    type="card" @edit="handleTabsEdit" @tab-click="tabSwitch">
                    <el-tab-pane :closable="index !== 0" :key="item.name" v-for="(item, index) in editableTabs"
                        :label="item.title" :name="item.name">
                    </el-tab-pane>
                </el-tabs>
                <!-- 路由占位符 -->
                <router-view></router-view>
            </el-main>
            <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                <span>您确定要修改角色吗?</span>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" type="warning" @click="dialogVisible = false">否</el-button>
                    <el-button size="mini" type="primary" @click="editUserType()">是</el-button>
                </span>
            </el-dialog>
        </el-container>
    </el-container>
</template>

<script>
import commonJs from "../assets/js/common.js" //共通js方法
export default {
    inject: ["reload"],
    data() {
        return {
            have: true, //是否有新消息
            // 左侧菜单数据
            dialogVisible: false,
            activeIndex: "GetStatistics",
            roleTypes: "",
            src: "/img/noTouXiang.png",
            e: "",
            e1: "",
            roleType: "",
            menulist: [],
            userType: "",
            userList: [],
            position: "下拉菜单",
            // 是否折叠
            isCollapse: false,
            // 被激活的链接地址
            activePath: "",
            staffName: "",
            userLevel: "",
            integral: "",
            editableTabsValue: "0",
            editableTabs: [
                {
                    title: "首页",
                    name: "0",
                    path: "GetStatistics"
                }
            ],
            positionList: [
                {
                    name: "0"
                },
                {
                    name: "0"
                },
                {
                    name: "0"
                },
                {
                    name: "0"
                }
            ],
            tabIndex: 0,
            total: ""
        }
    },

    created() {
        var tab = window.localStorage.getItem("editableTabs")
        if (tab == null) {
            window.localStorage.setItem(
                "editableTabs",
                JSON.stringify(this.editableTabs)
            )
        }

        /* 获取所有的日志列表 */
        this.GetMessageList()
        if (window.localStorage.getItem("roleType") == undefined) {
            let str = window.localStorage.getItem("userType").split("")[0]
            window.localStorage.setItem("roleType", str)
        }
        // this.activePath = window.sessionStorage.getItem('activePath')
        this.roleType = window.localStorage.getItem("roleType")
        this.staffName = window.localStorage.getItem("staffName")
        this.userLevel = window.localStorage.getItem("userLevel")
        this.integral = window.localStorage.getItem("integral")
        var pictureId = window.localStorage.getItem("pictureId")

        if (pictureId != "null" && pictureId != "") {
            this.src = this.$url.avatarsPicSrc + pictureId
        }
        let userRole = window.localStorage.getItem("userType")
        if (userRole == undefined) {
            this.$router.push("/GetLogin")
            return false
        }
        this.userList = window.localStorage.getItem("userType").split("")

        if (this.roleType == undefined) {
            if (this.userList[0] == "1") {
                this.roleType = "1"
                this.userList[0] = ""
            } else if (this.userList[0] == "2") {
                this.roleType = "2"
                this.userList[0] = ""
            } else if (this.userList[0] == "3") {
                this.roleType = "3"
                this.userList[0] = ""
            } else if (this.userList[0] == "4") {
                this.roleType = "4"
                this.userList[0] = ""
            } else if (this.userList[0] == "5") {
                this.roleType = "5"
                this.userList[0] = ""
            } else if (this.userList[0] == "6") {
                this.roleType = "6"
                this.userList[0] = ""
            } else if (this.userList[0] == "7") {
                this.roleType = "7"
                this.userList[0] = ""
            } else if (this.userList[0] == "9") {
                this.roleType = "9"
                this.userList[0] = ""
            }
            for (let i = 0; i < this.userList.length; i++) {
                if (this.userList[i] == "1") {
                    this.userList[i] = "切换到:经纪人"
                } else if (this.userList[i] == "2") {
                    this.userList[i] = "切换到:商圈经理"
                } else if (this.userList[i] == "3") {
                    this.userList[i] = "切换到:商户财务"
                } else if (this.userList[i] == "4") {
                    this.userList[i] = "切换到:人事专员"
                } else if (this.userList[i] == "5") {
                    this.userList[i] = "切换到:店东"
                } else if (this.userList[i] == "6") {
                    this.userList[i] = "切换到:平台管理员"
                } else if (this.userList[i] == "7") {
                    this.userList[i] = "切换到:交易中心"
                } else if (this.userList[i] == "9") {
                    this.userList[i] = "切换到:超级经纪人"
                }
            }
        } else {
            for (let i = 0; i < this.userList.length; i++) {
                if (this.userList[i] == this.roleType) {
                    this.userList[i] = ""
                }
            }
        }
        if (this.roleType == "1") {
            this.roleTypes = "经纪人"
        } else if (this.roleType == "2") {
            this.roleTypes = "商圈经理"
        } else if (this.roleType == "3") {
            this.roleTypes = "商户财务"
        } else if (this.roleType == "4") {
            this.roleTypes = "人事专员"
        } else if (this.roleType == "5") {
            this.roleTypes = "店东"
        } else if (this.roleType == "6") {
            this.roleTypes = "平台管理员"
        } else if (this.roleType == "7") {
            this.roleTypes = "交易中心"
        } else if (this.roleType == "9") {
            this.roleTypes = "超级经纪人"
        }
        for (let i = 0; i < this.userList.length; i++) {
            if (this.userList[i] == "1") {
                this.userList[i] = "切换到:经纪人"
            } else if (this.userList[i] == "2") {
                this.userList[i] = "切换到:商圈经理"
            } else if (this.userList[i] == "3") {
                this.userList[i] = "切换到:商户财务"
            } else if (this.userList[i] == "4") {
                this.userList[i] = "切换到:人事专员"
            } else if (this.userList[i] == "5") {
                this.userList[i] = "切换到:店东"
            } else if (this.userList[i] == "6") {
                this.userList[i] = "切换到:平台管理员"
            } else if (this.userList[i] == "7") {
                this.userList[i] = "切换到:交易中心"
            } else if (this.userList[i] == "9") {
                this.userList[i] = "切换到:超级经纪人"
            }
        }
        let editableTabs = window.localStorage.getItem("editableTabs")
        let activeIndex = window.localStorage.getItem("activeIndex")
        if (editableTabs) {
            this.editableTabs = JSON.parse(editableTabs)
            this.editableTabs.forEach((item) => {
                if (activeIndex == item.path) {
                    this.activeIndex = activeIndex
                    this.editableTabsValue = item.name.toString()
                }
            })
        }
    },
    methods: {
        /* 获取消息列表 */
        async GetMessageList() {
            const data = {
                messageTitle: "",
                currentPage: 1,
                pageSize: 10
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMessageList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                var list = []
                res.dtList.forEach(function (i) {
                    if (i.isRead == 0) {
                        list.push(i)
                    }
                })
                this.total = list.length
            } else {
                this.$message.success(res.message)
            }
        },
        handleCommand(command) {
            if (command == "修改个人信息") {
                this.addTab("个人中心", "Personal")
                this.personal()
            } else if (command == "修改密码") {
                this.addTab("修改密码", "UpdatePassword")
                this.changePsd()
            } else if (command == "切换角色") {
                // this.changeUser()
            } else if (command == "消息中心") {
                this.addTab("消息中心", "XiaoXiZhongXinList")
                this.changeXiaoxi()
            } else if (command == "退出登录") {
                window.localStorage.removeItem("editableTabs")
                window.localStorage.removeItem("activeIndex")
                this.logout()
            }
        },
        // 点击按钮，切换菜单的折叠与展开
        toggleCollapse() {
            this.isCollapse = !this.isCollapse
        },
        logout() {
            window.localStorage.clear()
            this.$router.push("/GetLogin")
        },
        changePsd() {
            this.$router.push("/UpdatePassword")
        },
        changeXiaoxiTab() {
            this.addTab("消息中心", "XiaoXiZhongXinList")
            this.changeXiaoxi()
        },
        changeXiaoxi() {
            this.have = false
            this.$router.push("/XiaoXiZhongXinList")
        },
        personal() {
            this.$router.push("/Personal")
        },

        // 点击导航栏新增tab页
        addTab(targetName, path) {
            let flag = true //判断是否需要新增页面
            var tabs = this.editableTabs //活跃当前tab数组
            for (var i = 0; i < tabs.length; i++) {
                //如果存在相同的tab页 不新增tab页
                if (tabs[i].path === path) {
                    this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
                    this.$router.push(path)
                    flag = false
                }
            }
            // 需要新增页面
            if (flag) {
                let newTabName = this.editableTabs.length.toString()
                this.editableTabs.push({
                    title: targetName,
                    name: newTabName,
                    path: path
                })
                this.editableTabsValue = newTabName
                window.localStorage.setItem(
                    "editableTabs",
                    JSON.stringify(this.editableTabs)
                )
            }
            window.localStorage.setItem("activeIndex", path)
        },
        // 删除tab页
        handleTabsEdit(targetName, action) {
            if (action === "remove") {
                let tabs = this.editableTabs
                let activeName = this.editableTabsValue
                if (activeName === targetName) {
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1]
                            if (nextTab) {
                                activeName = nextTab.name.toString()
                                this.tabSwitch1(activeName, 1)
                            }
                        }
                    })
                } else {
                    this.tabSwitch1(activeName, 2)
                }
                this.editableTabs = tabs.filter(
                    (tab) => tab.name !== targetName
                )
                this.editableTabs.forEach((item, index) => {
                    item.name = index.toString()
                    if (this.activeIndex == item.path) {
                        this.editableTabsValue = item.name
                    }
                })
                window.localStorage.setItem(
                    "editableTabs",
                    JSON.stringify(this.editableTabs)
                )
            }
        },
        //点击tab页跳转页面
        tabSwitch() {
            let path = this.editableTabs[this.editableTabsValue].path
            this.activeIndex = path
            window.localStorage.setItem("activeIndex", this.activeIndex)
            this.$router.push(path)
            this.reload()
        },
        // 删除tab页后的跳转
        tabSwitch1(thisTab, type) {
            let path
            if (type == 1) {
                this.editableTabsValue = thisTab.toString()
                path = this.editableTabs[thisTab].path
            } else {
                path = this.editableTabs[this.editableTabsValue].path
            }
            this.activeIndex = path
            this.$router.push(this.activeIndex)
            window.localStorage.setItem("activeIndex", path)
            this.reload()
        },
        changeUser(e) {
            this.dialogVisible = true
            if (e == "切换到:经纪人") {
                this.e = "1"
                this.e1 = "经纪人"
            } else if (e == "切换到:商圈经理") {
                this.e = "2"
                this.e1 = "商圈经理"
            } else if (e == "切换到:商户财务") {
                this.e = "3"
                this.e1 = "商户财务"
            } else if (e == "切换到:人事专员") {
                this.e = "4"
                this.e1 = "人事专员"
            } else if (e == "切换到:店东") {
                this.e = "5"
                this.e1 = "店东"
            } else if (e == "切换到:平台管理员") {
                this.e = "6"
                this.e1 = "平台管理员"
            } else if (e == "切换到:交易中心") {
                this.e = "7"
                this.e1 = "交易中心"
            } else if (e == "切换到:超级经纪人") {
                this.e = "9"
                this.e1 = "超级经纪人"
            }
        },

        editUserType() {
            this.roleType = this.e
            window.localStorage.setItem("roleType", this.roleType)
            let that = this
            that.userList = window.localStorage.getItem("userType").split("")
            for (let i = 0; i < that.userList.length; i++) {
                if (that.e == that.userList[i]) {
                    that.userList[i] = ""
                }
            }
            for (let i = 0; i < this.userList.length; i++) {
                if (this.userList[i] == "1") {
                    this.userList[i] = "切换到:经纪人"
                } else if (this.userList[i] == "2") {
                    this.userList[i] = "切换到:商圈经理"
                } else if (this.userList[i] == "3") {
                    this.userList[i] = "切换到:商户财务"
                } else if (this.userList[i] == "4") {
                    this.userList[i] = "切换到:人事专员"
                } else if (this.userList[i] == "5") {
                    this.userList[i] = "切换到:店东"
                } else if (this.userList[i] == "6") {
                    this.userList[i] = "切换到:平台管理员"
                } else if (this.userList[i] == "7") {
                    this.userList[i] = "切换到:交易中心"
                } else if (this.userList[i] == "9") {
                    this.userList[i] = "切换到:超级经纪人"
                }
            }
            that.activeIndex = "GetStatistics"
            this.$router.push("/GetStatistics")

            that.dialogVisible = false
            window.localStorage.removeItem("editableTabs")
            window.localStorage.removeItem("activeIndex")
            this.reload()
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done()
                })
                .catch((_) => { })
        }
    }
}
</script>

<style lang="less" scoped>
.home-container {
    height: 100%;
}

.el-header {
    background-color: #df0202;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    font-family: "PingFang SC";
    color: #fff;
    font-weight: bold;
    font-size: 20px;
}

.el-header>div {
    display: flex;
    align-items: center;
}

.el-header>div span {
    margin-left: 15px;
}

.el-aside {
    background-color: #df0202;
}

.el-aside .el-menu {
    border-right: none;
}

.el-main {
    background-color: #eaedf1;
    padding: 3px 20px 20px 20px;
}

.iconfont {
    margin-right: 10px;
}

.toggle-button {
    background-color: #df0202;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
}

i {
    color: #fff !important;
}

.el-submenu__icon-arrow.el-icon-arrow-down {
    color: #fff !important;
}

// .customerService{
//     position:fixed;
//     right: 35px;
//     bottom:70px;
//     width:50px;
//     height:100px;
//     z-index:999;
//   }
</style>
<style>
.tabs-caidan .el-tabs__nav-next,
.el-tabs__nav-prev {
    line-height: 33px;
}

.tabs-caidan .el-icon-close {
    margin-right: 0px !important;
    margin-top: 10px !important;
    font-size: 14px !important;
}

.tabs-caidan .el-tabs__nav {
    padding-right: 2% !important;
}

.tabs-caidan .el-tabs__item {
    color: #000000;
    min-width: 110px;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding: 0 8px 0 10px !important;
    border-right: 12px solid transparent;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom: 30px solid #ffffff !important;
    border-left: 12px solid transparent !important;
    margin-right: -5px;
}

.tabs-caidan .el-tabs__item:hover {
    background-color: transparent;
    color: #000000;
}

.tabs-caidan .el-tabs__header {
    margin-bottom: 0px;
}

.tabs-caidan .el-tabs__header .el-tabs__item:first-child {
    color: #000;
    border-bottom: 30px solid #ffffff;
    border-top-left-radius: 4px !important;
    border-left: 0px solid transparent !important;
    padding: 0 8px 0 15px !important;
}

.tabs-caidan .el-tabs--card .el-tabs__header .el-tabs__nav .el-tabs__item :not(:first-child) {
    color: #fff;
    border-bottom: 40px solid #f15a25;
    border-left: 12px solid transparent;
}

.tabs-caidan .is-active:not(:first-child) {
    border-left: 12px solid transparent;
}

.tabs-caidan .is-active {
    color: #ffffff !important;
    border-bottom: 30px solid #df0202 !important;
}
</style>