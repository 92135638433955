import axios from "axios";
import router from "./../../router/index.js";

import url from "../../config/url.js";
function dataForEach(data) {
  let shangquan = [],
    cascaderShangquan = [],
    XinXiLaiYuan = [],
    name = "",
    resData = {
      HuXing: [],
      JianZhuJieGou: [],
      JiaoYiQuanShu: [],
      JIAOYIQUANSHU: [],
      FUYONGFANGSHI: [],
      FANGWUYONGTU: [],
      ZHUANGXIUZHUANGKUANG: [],
      XinXiLaiYuan: [],
      kehuBiaoQian: [],
      shangquan: [],
      cascaderShangquan: []
    };
  data.forEach(item => {
    item.value = item.commonMasterId;
    item.label = item.commonMasterName;
    if (item.commonMasterType == "HUXING") {
      resData.HuXing.push(item);
    } else if (item.commonMasterType == "JIANZHUJIEGOU") {
      resData.JianZhuJieGou.push(item);
    } else if (item.commonMasterType == "JIAOYIQUANSHU") {
      resData.JiaoYiQuanShu.push(item);
    } else if (item.commonMasterType == "FANGWUYONGTU") {
      item.value1 = "6-" + (resData.FANGWUYONGTU.length + 1);
      resData.FANGWUYONGTU.push(item);
    } else if (item.commonMasterType == "JIAOYIQUANSHU") {
      resData.JIAOYIQUANSHU.push(item);
    } else if (item.commonMasterType == "FUYONGFANGSHI") {
      resData.FUYONGFANGSHI.push(item);
    } else if (item.commonMasterType == "ZHUANGXIUZHUANGKUANG") {
      item.value1 = "8-" + (resData.ZHUANGXIUZHUANGKUANG.length + 1);
      resData.ZHUANGXIUZHUANGKUANG.push(item);
    } else if (item.commonMasterType == "KEHUBIAOQIAN") {
      resData.kehuBiaoQian.push(item);
    } else if (item.commonMasterType == "XINXILAIYUAN") {
      name = item.commonMasterName.split("/");
      XinXiLaiYuan.push({
        label: name[0],
        laiyuan1: [
          {
            label: name[1],
            value: item.commonMasterId
          }
        ]
      });
    } else if (item.commonMasterType == "SHANGQUAN") {
      name = item.commonMasterName.split("/");
      shangquan.push({
        name: name[0],
        includeShangQuan: [
          {
            name: name[1],
            areaIds: item.commonMasterId
          }
        ]
      });
      cascaderShangquan.push({
        value: name[0],
        label: name[0],
        children: [
          {
            label: name[1],
            value: item.commonMasterId
          }
        ]
      });
    }
  });

  if (XinXiLaiYuan.length > 0) {
    for (let x1 = 0; x1 < XinXiLaiYuan.length; x1++) {
      for (let y1 = x1 + 1; y1 < XinXiLaiYuan.length; y1++) {
        if (XinXiLaiYuan[y1].label == XinXiLaiYuan[x1].label) {
          XinXiLaiYuan[x1].laiyuan1.push(XinXiLaiYuan[y1].laiyuan1[0]);
          XinXiLaiYuan.splice(y1, 1);
          x1 = 0;
          y1 = 0;
        }
      }
      XinXiLaiYuan[x1].value = x1.toString();
    }
    resData.XinXiLaiYuan = XinXiLaiYuan;
  }

  if (shangquan.length > 0) {
    for (let x = 0; x < shangquan.length; x++) {
      for (let y = x + 1; y < shangquan.length; y++) {
        if (shangquan[y].name == shangquan[x].name) {
          shangquan[x].includeShangQuan.push(shangquan[y].includeShangQuan[0]);
          shangquan.splice(y, 1);
          x = 0;
          y = 0;
        }
      }
    }
    resData.shangquan = shangquan;
  }
  if (cascaderShangquan.length > 0) {
    for (let x = 0; x < cascaderShangquan.length; x++) {
      for (let y = x + 1; y < cascaderShangquan.length; y++) {
        if (cascaderShangquan[y].label == cascaderShangquan[x].label) {
          cascaderShangquan[x].children.push(cascaderShangquan[y].children[0]);
          cascaderShangquan.splice(y, 1);
          x = 0;
          y = 0;
        }
      }
    }
    resData.cascaderShangquan = cascaderShangquan;
  }
  return resData;
}
export default {
  orientationOptions: [
    {
      value: "0",
      label: "东"
    },
    {
      value: "1",
      label: "东南"
    },
    {
      value: "2",
      label: "南"
    },
    {
      value: "3",
      label: "西南"
    },
    {
      value: "4",
      label: "西"
    },
    {
      value: "5",
      label: "西北"
    },
    {
      value: "6",
      label: "北"
    },
    {
      value: "7",
      label: "东北"
    }
  ],
  NumberFormat(param) {
    /* 转为Number格式 */
    return (param = param != "" && param > 0 ? Number(param) : 0);
  },
  /* 参数不能为空方法 */
  noNull(obj) {
    if (obj == null || typeof obj == "undefined") return "";
    return obj;
  },
  // 小写转大写方法
  getDaXie(n) {
    if (n == '0') {
      return '零元整';
    }
    if (n == 100000) {
      return '壹拾万元整';
    } if (n == 10) {
      return '壹拾元整';
    }
    // if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n))
    // return "数据非法";
    var unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
    n += "00";
    var p = n.indexOf('.');
    if (p >= 0)
      n = n.substring(0, p) + n.substr(p + 1, 2);
    unit = unit.substr(unit.length - n.length);
    for (var i = 0; i < n.length; i++)
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
    // return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
    return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
  },
  /* 参数为非负浮点数方法 */
  numberFloat(obj) {
    var type = /^[1-9][0-9]*([\.][0-9]{1,2})?$/;
    var flag = type.test(obj);
    return flag;
  },
  /* 参数为正整数方法 */
  numberParseInt(obj) {
    var type = /^([^0][0-9]+|0)$/;
    var flag = type.test(obj);
    return flag;
  },
  /* 参数为邮箱格式方法 */
  numberEmail(obj) {
    var type = /^[-_A-Za-z0-9]+@([_A-Za-z0-9]+\.)+[A-Za-z0-9]{2,3}$/;
    var flag = type.test(obj);
    return flag;
  },
  /* 参数为手机号格式方法 */
  numberPhone(obj) {
    var type = /^1[3456789]\d{9}$/;
    var flag = type.test(obj);
    return flag;
  },
  /* 参数为金额格式方法 */
  moneyFormat(obj) {
    var type = /^\d+(?=\.{0,1}\d+$|$)/;
    var flag = type.test(obj);
    return flag;
  },
  /* 参数为身份证格式方法 */
  shenfenZheng(obj) {
    var type = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    var flag = type.test(obj);
    return flag;
  },
  // 参数为税号格式方法
  suiHao(obj) {
    if (
      /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/.test(obj)
    ) {
      return true;
    }
  },
  /* 参数只能为数字方法 */
  shuZi(obj) {
    var type = /^\d+$|^\d+[.]?\d+$/;
    var flag = type.test(obj);
    return flag;
  },
  /*时间转义方法 */
  dateTimeFormatter(date) {
    let shijian = "";
    if (typeof date != "string") {
      let getYear = date.getFullYear();
      let getMonth = date.getMonth() + 1;
      let getDate = date.getDate();
      let getHours = date.getHours();
      let getMinutes = date.getMinutes();
      let getSeconds = date.getSeconds();
      shijian =
        getYear +
        "-" +
        (getMonth < 10 ? "0" + getMonth : getMonth) +
        "-" +
        (getDate < 10 ? "0" + getDate : getDate) +
        "T" +
        (getHours < 10 ? "0" + getHours : getHours) +
        ":" +
        (getMinutes < 10 ? "0" + getMinutes : getMinutes) +
        ":" +
        (getSeconds < 10 ? "0" + getSeconds : getSeconds);
    } else {
      shijian = date.replace(" ", "T");
    }
    return shijian;
  },
  /*时间转义方法 */
  dateFormatter(date) {
    let myYear = date.getFullYear();
    let myMonth = date.getMonth() + 1;
    let myDay = date.getDate();
    let time =
      myYear +
      "-" +
      (myMonth < 10 ? "0" + myMonth : myMonth) +
      "-" +
      (myDay < 10 ? "0" + myDay : myDay);
    return time;
  },
  //   接口报错提示
  getUrlError(obj) {
    var flag = obj;
    if (obj == "Unknown") {
      flag = "未知的失败";
    } else if (obj == "AuthFailure") {
      flag = "认证失败";
    } else if (obj == "AuthSuccessButNoAccess") {
      flag = "认证成功 但没有权限调用接口";
    } else if (obj == "AuthFailureSinceHaveNoAuthMsg") {
      flag = "认证失败 因为没有fp或accessToken";
    } else if (obj == "OperationFailure") {
      flag = "业务失败";
    } else if (obj == "OtherFailure") {
      flag = "其他的失败";
    }
    return flag;
  },
  //数据去重
  getArrylist(list) {
    //用于name判断重复
    var temp = {};

    //去重后组成的新数组
    var result = [];
    //对合并数组c进行去重处理
    list.map(function (item, index) {
      if (!temp[item.commonMasterId]) {
        result.push(item);
        temp[item.commonMasterId] = true;
      }
    });
    return result;
  },
  /* 获取数据字典数据列表 */
  getShuJuZiDianList(param) {
    var response = new Promise(function (resolve, reject) {
      axios
        .get(url.GetCommonMasterList, {
          params: {
            commonMasterName: "",
            commonMasterTypes: param,
            currentPage: 1,
            pageSize: 1000
          }
        })
        .then(function (res) {
          if (res.data.msgCode == 1) {
            resolve(dataForEach(res.data.dtList));
          }
        });
    });
    return response;
  },
  // 点击导航栏新增tab页
  addTab(targetName, path) {
    let editableTabs = window.localStorage.getItem("editableTabs");
    let editableTabsValue = "0";
    let flag = true; //判断是否需要新增页面
    var tabs = editableTabs; //活跃当前tab数组
    tabs = JSON.parse(tabs);
    for (var i = 0; i < tabs.length; i++) {
      //如果存在相同的tab页 不新增tab页
      if (tabs[i].path === path) {
        console.log(tabs[i].path);
        editableTabsValue = tabs[i].name.toString(); //定位到已打开页面
        router.replace({ path: "/" + path });
        flag = false;
      }
    }
    window.localStorage.setItem("activeIndex", path);
  },
  // 删除服务器中的图片
  deleteImg(file, type) {
    let removeImgId = "";
    if (file.response == undefined) {
      console.log(file.name);
      removeImgId = file.name;
    } else {
      console.log(file.response.id);
      removeImgId = file.response.id;
    }
    const data1 = [removeImgId, type];
    var response = new Promise(function (resolve, reject) {
      axios
        .delete(url.DeleteOneFile, {
          data: data1,
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            // this.$message.error(res);
            console.log(res);
          } else {
            console.log(res.data.message);
            // this.$message.error(res.data.message);
          }
        });
    });
    return response;
  },
  // 获取小程序码
  getWeiXinGeneratingCode(type, param) {
    let page = "",
      scene = "";
    if (type == "chuZuList") {
      page = "pages/ZuFang/ZuFangDetail";
    } else if (type == "chuShowList") {
      page = "pages/ErShouFang/ErShouDetail";
    } else if (type == "xinFangList") {
      page = "pages/XinFang/XinFangDetail";
    }
    let response = new Promise(function (resolve, reject) {
      axios
        .get(url.Get_weiXinGeneratingCode, {
          params: {
            page: page,
            houseNo: param
          }
        })
        .then(function (res) {
          if (res.data.msgCode == 1) {
            let sharePic = url.xcxQrPic + res.data.imgName;
            resolve(sharePic);
          }
        })
        .catch(res => {
          reject(res.message);
        });
    });
    return response;
  }
};
