export default {
    // 表单对象
    form: {
        companyName: "×", //企业名称
        contractNo1: "×", //合同编号
        // tel1: "×", //客服热线
        // tel2: "×", //交易客服热线
        jiaFang1: "×", //甲方（出售方）
        yiFang1: "×", //乙方（购买方）
        jiaFangZ1: "×", //甲方（签章）
        yiFangZ1: "×", //乙方（签章）
        gaoZhi_Year1: "×", //本告知函签署日期-年
        gaoZhi_Month1: "×", //本告知函签署日期-月
        gaoZhi_Day1: "×", //本告知函签署日期-日

        sellerName: "×", //房屋出卖人姓名
        chuShou_YingWenName: "×", //房屋出卖人英文名
        chuShou_Sex: "×", //房屋出卖人性别
        chuShou_GuoJi: "×", //房屋出卖人国籍
        chuShou_ChuShengRiQi: "×", //房屋出卖人出生日期
        chuShou_ZhengJianName: "身份证", //房屋出卖人证件名
        sellerIdCardNo: "×", //房屋出卖人证件号码
        chuShou_Email: "×", //房屋出卖人电子邮箱
        chuShou_Address: "×", //房屋出卖人通讯地址

        sellerNameGY: "×", //房屋出卖共有权人姓名
        chuShouGY_YingWenName: "×", //房屋出卖共有权人英文名
        chuShouGY_Sex: "×", //房屋出卖共有权人性别
        chuShouGY_GuoJi: "×", //房屋出卖共有权人国籍
        chuShouGY_ChuShengRiQi: "×", //房屋出卖共有权人出生日期
        chuShouGY_ZhengJianName: "×", //房屋出卖共有权人证件名
        sellerIdCardNoGongYou: "×", //房屋出卖共有权人证件号码
        chuShouGY_Email: "×", //房屋出卖共有权人电子邮箱
        chuShouGY_Address: "×", //房屋出卖共有权人通讯地址

        chuShouDL_Name: "×", //房屋出卖人代理人姓名
        chuShouDL_YingWenName: "×", //房屋出卖人代理人英文名
        chuShouDL_Sex: "×", //房屋出卖人代理人性别
        chuShouDL_GuoJi: "×", //房屋出卖人代理人国籍
        chuShouDL_ChuShengRiQi: "×", //房屋出卖人代理人出生日期
        chuShouDL_ZhengJianName: "×", //房屋出卖人代理人证件名
        sellerIdCardNoDaiLi: "×", //房屋出卖人代理人证件号
        // sellerMobile:'x',//房屋出卖人手机号
        // sellerMobileDaiLi:'x',//出卖代理人
        chuShouDL_Email: "×", //房屋出卖人代理人电子邮箱
        chuShouDL_Address: "×", //房屋出卖人代理人通讯地址
        jiaFangZ2: "×", //甲方（签章）
        chuShou_Year: "×", //房屋出卖日期-年
        chuShou_Month: "×", //房屋出卖日期-月
        chuShou_Day: "×", //房屋出卖日期-日

        buyerName: "×", //房屋买受人姓名
        gouMai_YingWenName: "×", //房屋买受人英文名
        gouMai_Sex: "×", //房屋买受人性别
        gouMai_GuoJi: "×", //房屋买受人国籍
        gouMai_ChuShengRiQi: "×", //房屋买受人出生日期
        gouMai_ZhengJianName: "身份证", //房屋买受人证件名
        buyerIdCardNo: "×", //房屋买受人证件号码
        // buyerMobile:'',//房屋买受人手机号
        gouMai_Email: "×", //房屋买受人电子邮箱
        gouMai_Address: "×", //房屋买受人通讯地址

        buyerNameGY: "×", //房屋买受共有权人姓名
        gouMaiGY_YingWenName: "×", //房屋买受共有权人英文名
        gouMaiGY_Sex: "×", //房屋买受共有权人性别
        gouMaiGY_GuoJi: "×", //房屋买受共有权人国籍
        gouMaiGY_ChuShengRiQi: "×", //房屋买受共有权人出生日期
        gouMaiGY_ZhengJianName: "×", //房屋买受共有权人证件名
        buyerIdCardNoGongYou: "×", //房屋买受共有权人证件号码
        gouMaiGY_Email: "×", //房屋买受人电子邮箱
        gouMaiGY_Address: "×", //房屋买受人通讯地址

        gouMaiDL_Name: "×", //房屋买受人代理人姓名
        gouMaiDL_YingWenName: "×", //房屋买受人代理人英文名
        gouMaiDL_Sex: "×", //房屋买受人代理人性别
        gouMaiDL_GuoJi: "×", //房屋买受人代理人国籍
        gouMaiDL_ChuShengRiQi: "×", //房屋买受人代理人出生日期
        // buyerMobileDaiLi:'x',//房屋买受人手机号
        gouMaiDL_ZhengJianName: "×", //房屋买受人代理人证件名
        buyerIdCardNoDaiLi: "×", //房屋买受人代理人证件号码
        gouMaiDL_Email: "×", //房屋买受人代理人电子邮箱
        gouMaiDL_Address: "×", //房屋买受人代理人通讯地址
        yiFangZ2: "×", //乙方（签章）
        gouMai_Year: "×", //房屋买受日期-年
        gouMai_Month: "×", //房屋买受日期-月
        gouMai_Day: "×", //房屋买受日期-日

        chuShou1: "×", //房屋出售方
        gongYou: "×", //共有权方
        daiLi1: "×", //代理人

        gouMai1: "×", //房屋购买方
        gongMai: "×", //共同买受方
        daiLi2: "×", //代理人

        fangWu_Address1: "×", //房屋地址
        fangWu_MianJi: "×", //房屋面积

        quanShuZhengHao: "×", //权属证号
        gongYouQuanZhengHao: "×", //共有权证证号
        fw_WDY: "□", //房屋未设定抵押
        fw_YDY: "□", //房屋已设定抵押
        baoZheng_Day: "×", //保证几内日
        fw_WCZ: "□", //甲方未将该房屋出租
        fw_YCZ1: "□", //甲方已将该房屋出租，乙方为该房屋承租人
        fw_YCZ2: "□", //甲方已将该房屋出租，且承租人以放弃优先购买权
        zyyd_Y: "□", //租约约定-有
        zyyd_W: "□", //租约约定-无
        zuLinToYear: "×", //房屋租赁期结束-年
        zuLinToMonth: "×", //房屋租赁期结束-月
        zuLinToDay: "×", //房屋租赁期结束-日
        yueZu_XiaoXieYuan: "×", //月租金：人民币（小写）元
        yueZu_DaXieYuan: "×", //月租金：人民币（大写）元
        yaJin_XiaoXieYuan: "×", //押金：人民币（小写）元
        yaJin_DaXieYuan: "×", //押金：人民币（大写）元
        yifangtongyi: "□", //乙方同意继续履行租赁合同直至租赁期限届满为止
        jiafangsuoyou: "□", //交易房屋租金全部归甲方所有
        xieshangyizhi: "□", //双方协商一致
        xieShangYiZhi: "×", //协商一致

        zongFangKuan_XiaoXieYuan: "×", //房屋出售价格：人民币（小写）元
        zongFangKuan_DaXieYuan: "×", //房屋出售价格：人民币（大写）元
        fangWuJiaGe_XiaoXieYuan: "×", //房屋价格：人民币（小写）元
        fangWuJiaGe_DaXieYuan: "×", //房屋价格：人民币（大写）元
        zhuangXiuKuan_XiaoXieYuan1: "×", //房屋配套设、装修及家具家电（若有）等：人民币（小写）元
        zhuangXiuKuan_DaXieYuan1: "×", //房屋配套设、装修及家具家电（若有）等：人民币（大写）元
        dingJin_XiaoXieYuan1: "×", //定金：人民币（小写）元
        dingJin_DaXieYuan1: "×", //定金：人民币（大写）元
        dingJin_DiYiBi: "×", //第一笔定金日期
        diYiBi_XiaoXieYuan: "×", //第一笔定金：人民币（小写）元
        diYiBi_DaXieYuan: "×", //第一笔定金：人民币（大写）元
        dingJin_DiErBi: "×", //第二笔定金日期
        diErBi_XiaoXieYuan: "×", //第二笔定金：人民币（小写）元
        diErBi_DaXieYuan: "×", //第二笔定金：人民币（大写）元
        cunGuanDingJin_XiaoXieYuan1: "×", //存管定金：人民币（小写）元
        cunGuanDingJin_DaXieYuan1: "×", //存管定金1：人民币（大写）元
        cunGuanDingJin_XiaoXieYuan2: "×", //存管定金2：人民币（小写）元
        cunGuanDingJin_DaXieYuan2: "×", //存管定金2：人民币（大写）元
        shengYuDingJin_XiaoXieYuan: "×", //剩余定金：人民币（小写）元
        shengYuDingJin_DaXieYuan: "×", //剩余定金：人民币（大写）元

        quankuan: "□", //全款
        daikuan: "□", //贷款
        zuhedaikuan: "□", //组合贷款
        qianDing_Day: "×", //签订几日内
        juJueFangKuan_Day: '×', //拒绝放款几日内
        fangKuan_XiaoXieYuan1: "×", //房款：人民币（小写）元
        fangKuan_DaXieYuan1: "×", //房款：人民币（大写）元
        zhuangXiukuan_XiaoXieYuan2: "×", //装修款：人民币（小写）元
        zhuangXiukuan_DaXieYuan2: "×", //装修款：人民币（大写）元
        xianshang: "□", //线上银行资金存管
        xianxia: "□", //线下银行资金存管
        fangKuan_XiaoXieYuan2: "×", //房款-物业交割保证金：人民币（小写）元
        fangKuan_DaXieYuan2: "×", //房款-物业交割保证金：人民币（大写）元
        xianshangyinhang: "□", //线上银行资金存管
        xianxiayinhang: "□", //线下银行资金存管
        zixingxieshang: "□", //自行协商，详见补充协议

        // 2024-3-25添加
        ziransiwang: "□",//自然死亡
        feiziransiwang: "□",//非自然死亡
        sfyhk_S: "□",//有户口
        sfyhk_F: "□",//没户口
        hkcs_ZGH: "□",//户口查实
        hkcs_QT: "□",//户口查实
        jiafang_LSWX: "□",//甲方漏水协商
        yifang_LSWX: "□",//乙方漏水协商
        YF_baifenzhiwu: "□",//百分之5赔偿
        YF_qitapeichang: "□",//其他赔偿
        YFWYSS_butuihui: "□",//不退回
        YFWYSS_qitapeichang: "□",//百分之10
        YFWYSS_baifenzhiershi: "□",//其他赔偿
        JF_baifenzhiwu: "□",//甲方违约百分之5
        JF_qitapeichang: "□",//甲方其他赔偿
        JFWYSS_shuangbei: "□",//甲方违约导致的损失 双倍赔偿
        JFWYSS_baifenzhiershi: "□",//甲方违约导致的损失 百分之10
        JFWYSS_qitapeichang: "□",//甲方违约导致的损失 其他赔偿

        daiKuan_XiaoXieYuan: "×", //贷款：人民币（小写）元
        daiKuan_DaXieYuan: "×", //贷款：人民币（大写）元
        yifangzixing: "□", //乙方自行筹集剩余房价款
        yifangjixu: "□", //乙方继续向其他贷款机构申请贷款
        benhetongzhongzhi: "□", //本合同终止
        xiaDa_Day: "×", //下达几日内
        daiKuanJiGou: "×", //贷款机构
        daiKuanPiFu: "×", //贷款批复

        yisheli: "□", //已设立居住权
        weisheli: "□", //未设立居住权
        sffs_S: "□", //发生过非正常死亡事件-是
        sffs_F: "□", //发生过非正常死亡事件-否
        loushui: "□", //漏水-是
        buloushui: "□", //漏水-否
        louShuiWeiZhi: "×", //漏水位置
        xiuhao: "□", //已修好-是
        meixiuhao: "□", //已修好-否
        yueding1: "□", //签署合同当日甲方已出示维修基金发
        yueding2: "□", //签署合同当日甲方未出示维修
        yueding3: "□", //其他
        weiXiuJiJin_QT: "×", //维修基金-其他

        JFweiyuepeichang_QT: '×', // 甲方违约赔偿-其他
        YFweiyuepeichang_QT: '×', // 乙方违约赔偿-其他
        JFsunshipeichang_QT: '×', // 甲方违约造成损失赔偿-其他
        YFsunshipeichang_QT: '×', // 乙方违约造成损失赔偿-其他

        fangKuan_Day: "×", //放款后几日内
        yitong: "□", //煤气或燃气-已通
        weitong: "□", //煤气或燃气-未通
        jfq_JF: "□", //交付前煤气或燃气开通的费用承担-甲方
        jfq_YF: "□", //交付前煤气或燃气开通的费用承担-乙方
        jfh_JF: "□", //交付后煤气或燃气开通的费用承担-甲方
        jfh_YF: "□", //交付后煤气或燃气开通的费用承担-乙方

        jf_QiTaShuiFei: "×", //甲方承担的其他税、费
        yf_QiTaShuiFei: "×", //甲方承担的其他税、费

        shuifei_Day: "×", //甲乙双方


        qianShu_Day: "×", //签署后几日内
        weidiya: "□", //该房屋未设定抵押
        yidiya: "□", //该房屋已设定抵押
        xiangGuanWenJian: "×", //备齐交易相关文件
        tongGuo_Day: "×", //通过后几日内
        daCheng_Day: "×", //达成后几日内
        fangZhongJieYuanJian: "×", // 放在中介那的其他权证原件

        bukekangli1: "□", //所有权转移
        bukekangli2: "□", //转移占有之日起转移给乙方
        weiyuefangchengdan: "□", //律师费由违约方承担
        gezichengdan: "□", //律师费甲乙双方各自承担

        qianYue_Day: "×", //签约后几日内
        weiYueJin1: "×", //甲方逾期返还的违约金
        // weiYueJin2: "x", //甲方逾期不配合办理的违约金
        qtyd_Y: "□", //其他约定-有
        qtyd_W: "□", //其他约定-无

        jiaFangZ3: "×", //甲方（签章）
        jiaFangWeiTuoZ1: "×", //甲方委托代理人/法定代理人（签章）
        qianShuRiQi1: "×", //签署日期
        yiFangZ3: "×", //乙方（签章）
        yiFangWeiTuoZ1: "×", //乙方委托代理人/法定代理人（签章）
        qianShuRiQi2: "×", //签署日期

        jiaFang2: "×", //甲方（出售方）
        yiFang2: "×", //乙方（购买方）
        bingFang1: "×", //丙方（中介方）
        fangWu_Address2: "×", //房屋地址
        heTongQianDing_Year1: "×", //合同签订日期-年
        heTongQianDing_Month1: "×", //合同签订日期-月
        heTongQianDing_Day1: "×", //合同签订日期-日
        zhongJieFei_XiaoXieYuan: "×", //向丙方支付的中介费：人民币（小写）元
        zhongJieFei_DaXieYuan: "×", //向丙方支付的中介费：人民币（大写）元
        jfzjf_XiaoXieYuan: "×", //甲方承担向丙方支付的中介费：人民币（小写）元
        jfzjf_DaXieYuan: "×", //甲方承担向丙方支付的中介费：人民币（大写）元
        jfzxzf_XiaoXieYuan: "×", //甲方向丙方自行支付的中介费：人民币（小写）元
        jfzxzf_DaXieYuan: "×", //甲方向丙方自行支付的中介费：人民币（大写）元
        jfwtzf_XiaoXieYuan: "×", //甲方向丙方委托支付的中介费：人民币（小写）元
        jfwtzf_DaXieYuan: "×", //甲方向丙方委托支付的中介费：人民币（大写）元
        yfzjf_XiaoXieYuan: "×", //乙方承担向丙方支付的中介费：人民币（小写）元
        yfzjf_DaXieYuan: "×", //乙方承担向丙方支付的中介费：人民币（大写）元

        jiaFangZ4: "×", //甲方（签章）
        jiaFangWeiTuoZ2: "×", //甲方委托代理人/法定代理人（签章）
        qianShuRiQi3: "×", //签署日期
        yiFangZ4: "×", //乙方（签章）
        yiFangWeiTuoZ2: "×", //乙方委托代理人/法定代理人（签章）
        qianShuRiQi4: "×", //签署日期
        bingFangZ1: "×", //丙方（章）
        diZhi1: "×", //地址
        JJZYRYQZ1: "×", //经纪执业人员签字1
        ZGZCZSBH1: "×", //资格注册证书编号1
        JJZYRYQZ2: "×", //经纪执业人员签字2
        ZGZCZSBH2: "×", //资格注册证书编号2
        LBRQZ1: "×", //经办人签字
        LXDH1: "×", //联系电话
        qianShuRiQi5: "×", //签署日期

        // jiaFang3: "x", //甲方（出售方）
        // yiFang3: "x", //乙方（购买方）

        jiaFang4: "×", //甲方（出售方）
        yiFang4: "×", //乙方（出售方）
        bingFang2: "×",  //丙方（中介方）
        fangWu_Address3: "×", //房屋地址

        SYDK: "□", //商业贷款
        GJJDK: "□", //公积金贷款
        ZHDK: "□", //组合贷款
        BDGJJDK: "□", //部队公积金贷款
        sydk_XiaoXieYuan: "×", //乙方申请商业贷款金额：人民币（小写）元
        sydk_DaXieYuan: "×", //乙方申请商业贷款金额：人民币（大写）元
        gjjdk_XiaoXieYuan: "×", //乙方申请公积金贷款金额：人民币（小写）元
        gjjdk_DaXieYuan: "×", //乙方申请公积金贷款金额：人民币（大写）元
        bzfwf_XiaoXieYuan: "×", //保障服务费：人民币（小写）元
        bzfwf_DaXieYuan: "×", //保障服务费：人民币（大写）元

        jiaFangZ6: "×", //甲方（签章）
        jiaFangWeiTuoZ3: "×", //甲方委托代理人/法定代理人（签章）
        qianShuRiQi6: "×", //签署日期
        yiFangZ6: "×", //乙方（签章）
        yiFangWeiTuoZ3: "×", //乙方委托代理人/法定代理人（签章）
        qianShuRiQi7: "×", //签署日期
        bingFangZ2: "×", //丙方（章）
        diZhi2: "×", //地址
        JJZYRYQZ3: "×", //经纪执业人员签字3
        ZGZCZSBH3: "×", //资格注册证书编号3
        JJZYRYQZ4: "×", //经纪执业人员签字4
        ZGZCZSBH4: "×", //资格注册证书编号4
        LBRQZ2: "×", //经办人签字
        LXDH2: "×", //联系电话
        qianShuRiQi8: "×", //签署日期


        jf_WTR: "×", //甲方（出售方）委托人
        yf_WTR: "×", //乙方（购买方）委托人
        STR: "×", //受托人
        JJF: '×', // 居间方
        cmr_XM: "×",//房屋出卖人姓名
        cmr_ZJMC: "身份证",//房屋出卖人证件名称
        cmr_ZJHM: "×",//房屋出卖人证件号码
        cmr_GYQR: "×",//房屋出卖人共有权人
        cmr_GYQRZJMC: "×",//房屋出卖人共有权人证件名称
        cmr_GYQRZJHM: "×",//房屋出卖人共有权人证件号码
        msr_XM: "×",//房屋买受人姓名
        msr_ZJMC: "身份证",//房屋买受人证件名称
        msr_ZJHM: "×",//房屋买受人证件号码
        msr_GYGMR: "×",//房屋买受人共有权人
        msr_GYGMRZJMC: "×",//房屋买受人共有权人证件名称
        msr_GYGMRZJHM: "×",//房屋买受人共有权人证件号码
        JYFWDZ: "×", //交易房屋地址
        CJJ: "×", //成交价

        jiafangZ8: "×", //甲方（签章）
        yiFangZ8: "×", //乙方（签章）
        qianShu_Year3: "×", //签署日期-年
        qianShu_Month3: "×", //签署日期-月
        qianShu_Day3: "×", //签署日期-日
        checkedDingZhuanYong: [],  //定转佣
        checkedJiBenQingKuang: [], //房屋基本情况
        checkedFangWuQuanShu: [], //房屋权属
        checkedJiaFangTongYi: [], //甲方同意
        checkedFangMenYaoShi: [], //房门钥匙
        checkedFangZhongJie: [], // 放在中介那 那些权属原件

        checkedJiaJaFangShuiFei: [], //甲方税费
        checkedYiFangShuiFei: [], //乙方税费
        SWFSRadio: '', // 死亡方式
        SFYHKRadio: '',  // 是否有户口
        DYQKRadio: "", //房屋抵押情况
        ZLQKRadio: "", //房屋租赁情况
        ZYYDRadio: "", //房屋租约约定
        CLFSRadio: "", //处理方式
        QKODKRadio: "", //全款or贷款or组合贷款
        XSOXXRadio: "", //线上or线下
        BZJSXYRadio: "", //保证金三选一
        DKJJFSRadio: "", //贷款解决方式
        JZQRadio: "", //居住权
        SFFSRadio: "", //是否发生
        SFLSRadio: "", //是否漏水
        SFXHRadio: "", //是否修好
        WXYDRadio: "", //修好约定
        MQRadio: "", //煤气或燃气
        JFQMQFRadio: "", //交付前煤气费
        JFHMQFRadio: "", //交付后煤气费
        FWDYRadio: "", //房屋抵押
        BKKLRadio: "", //不可抗力
        LSFRadio: "", //律师费
        QTYDRadio: "", //其他约定
        DKFSRadio: "", //贷款方式
        bcxysm: "",//补充协议说明
        hkcs_QiTaChaShi: '×', // 户口查实其他
        // checkedHuKouChaShi:[], //户口查实
        HKCSRadio: '', //户口查实
        quanShuDengJiHou_Day: '×', //权属登记后几日内
        LSXSFSRadio: '', // 漏水协商方式
        JFWYZRRadio: '', //甲方违约责任
        JFWYSSRadio: '', // 甲方违约导致的损失
        YFWYZRRadio: '', // 乙方违约责任
        YFWYSSRadio: '', // 乙方违约导致的损失
    },
    formCheck: {
        checkedDingZhuanYong: [], // 定转佣
        checkedJiBenQingKuang: [], //房屋基本情况
        checkedFangWuQuanShu: [], //房屋权属
        SWFSRadio: '', // 死亡方式
        SFYHKRadio: '',  // 是否有户口
        DYQKRadio: "", //房屋抵押情况
        ZLQKRadio: "", //房屋租赁情况
        ZYYDRadio: "", //房屋租约约定
        CLFSRadio: "", //处理方式
        checkedJiaFangTongYi: [], //甲方同意
        QKODKRadio: "", //全款or贷款or组合贷款
        XSOXXRadio: "", //线上or线下
        BZJSXYRadio: "", //保证金三选一
        DKJJFSRadio: "", //贷款解决方式
        JZQRadio: "", //居住权
        SFFSRadio: "", //是否发生
        SFLSRadio: "", //是否漏水
        SFXHRadio: "", //是否修好
        WXYDRadio: "", //修好约定
        MQRadio: "", //煤气或燃气
        JFQMQFRadio: "", //交付前煤气费
        JFHMQFRadio: "", //交付后煤气费
        checkedFangMenYaoShi: [], //房门钥匙
        checkedFangZhongJie: [], // 放在中介那 那些权属原件

        checkedJiaJaFangShuiFei: [], //甲方税费
        checkedYiFangShuiFei: [], //乙方税费
        FWDYRadio: "", //房屋抵押
        BKKLRadio: "", //不可抗力
        LSFRadio: "", //律师费
        QTYDRadio: "", //其他约定
        DKFSRadio: "", //贷款方式
        HKCSRadio: '', //户口查实
        // checkedHuKouChaShi:[], //户口查实
        LSXSFSRadio: '', // 漏水协商方式
        JFWYZRRadio: '', // 甲方违约责任
        JFWYSSRadio: '', // 甲方违约导致的损失
        YFWYZRRadio: '', // 乙方违约责任
        YFWYSSRadio: '', // 乙方违约导致的损失
    },
    JiBenQingKuangOptions: [
        // 房屋基本情况
        { name: "供水", id: "gongshui" },
        { name: "供电", id: "gongdian" },
        { name: "供暖", id: "gongnuan" },
        { name: "供煤气或燃气设施", id: "gongmeiqi" }
    ],
    JiBenQingKuangList: [
        "gongshui",
        "gongdian",
        "gongnuan",
        "gongmeiqi"
    ],
    FangWuQuanShuOptions: [
        { name: "国有产权", id: "guoyouchanquan" }
    ],
    FangWuQuanShuList: [
        "guoyouchanquan"
    ],
    JiaFangTongYiOptions: [
        { name: "甲方同意", id: "tongyi1" },
        { name: "甲方同意", id: "tongyi2" },
        { name: "甲方同意", id: "tongyi3" }
    ],
    JiaFangTongYiList: [
        "tongyi1",
        "tongyi2",
        "tongyi3"
    ],
    FangMenYaoShiOptions: [
        { name: "房门钥匙", id: "yaoshi" },
        { name: "门卡", id: "menka" },
        { name: "开门密码", id: "mima" },
    ],
    FangMenYaoShiList: [
        "yaoshi",
        "menka",
        "mima"
    ],
    yuanJianOptions: [
        { name: '房屋所有权证原件', id: 'fangWuSuoYouQuanYuanJian' },
        { name: '相关权属证明原件', id: 'xiangGuanQuanShuYuanJian' },
    ],
    FangZhongJieList: [
        "fangWuSuoYouQuanYuanJian",
        "xiangGuanQuanShuYuanJian",
    ],
    JiaFangShuiFeiOptions: [
        { name: "契税", id: "jf_QS" },
        { name: "增值税及附加", id: "jf_ZZS" },
        { name: "个人所得税", id: "jf_GRSDS" },
        { name: "交易费", id: "jf_JYF" },
        { name: "土地增值税", id: "jf_TDZHS" },
        { name: "土地出让/收益金", id: "jf_TDCR" },
        { name: "产权证工本费", id: "jf_CQZGBF" },
        { name: "印花税", id: "jf_YHS" },
        { name: "房地产买卖契约公证费", id: "jf_GZF" },
        { name: "重置评估费", id: "jf_CZPGF" },
        { name: "其他：", id: "jf_QT" },
    ],
    JiaFangShuiFeiList: [
        "jf_QS",
        "jf_ZZS",
        "jf_GRSDS",
        "jf_JYF",
        "jf_TDZHS",
        "jf_TDCR",
        "jf_CQZGBF",
        "jf_YHS",
        "jf_GZF",
        "jf_CZPGF",
        "jf_QT"
    ],
    YiFangShuiFeiOptions: [
        { name: "契税", id: "yf_QS" },
        { name: "增值税及附加", id: "yf_ZZS" },
        { name: "个人所得税", id: "yf_GRSDS" },
        { name: "交易费", id: "yf_JYF" },
        { name: "土地增值税", id: "yf_TDZHS" },
        { name: "土地出让/收益金", id: "yf_TDCR" },
        { name: "产权证工本费", id: "yf_CQZGBF" },
        { name: "印花税", id: "yf_YHS" },
        { name: "房地产买卖契约公证费", id: "yf_GZF" },
        { name: "重置评估费", id: "yf_CZPGF" },
        { name: "其他：", id: "yf_QT" },
    ],
    YiFangShuiFeiList: [
        "yf_QS",
        "yf_ZZS",
        "yf_GRSDS",
        "yf_JYF",
        "yf_TDZHS",
        "yf_TDCR",
        "yf_CQZGBF",
        "yf_YHS",
        "yf_GZF",
        "yf_CZPGF",
        "yf_QT"
    ],

    DingZhuanYongOption: [
        { name: '线上银行资金存管', id: 'dzy_XSYH' },
        { name: '线下银行资金存管', id: 'dzy_XXYH' },
        { name: '自行支付的方式支付中介方', id: 'dzy_ZX' }
    ],
    DingZhuanYongList: ['dzy_XSYH', 'dzy_XXYH', 'dzy_ZX'],

}