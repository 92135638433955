<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <el-button size="mini" @click="goBack" class="el-button1" type="primary" style="float: right">返回</el-button>
            <!-- <el-button size="mini" class="el-button3" type="primary" @click="cuiQianButton">催签</el-button> -->
            <el-button v-if="(roleType == '2' || roleType == '5' || roleType == '6') &&
                (information.contractStatusName == '电子签章完成' ||
                    information.contractStatusName == '付款完成' ||
                    information.contractStatusName == '过户完成' ||
                    information.contractStatusName == '交易完结') && isOnLine != 0
                " size="mini" class="el-button2" type="primary" @click="downLoad()">下载合同</el-button>
            <el-button v-if="(roleType == '1' || roleType == '5' || roleType == '6') &&
                information.contractStatusName == '电子签章完成' && form.WeiShouYingFuAmount == 0
                " size="mini" @click="fenchengDialogVisible = true" class="el-button2"
                type="primary">付款完成确认</el-button>
            <!-- <el-button size="mini" @click="fenchengDialogVisible = true" class="el-button2" type="primary">付款完成确认</el-button> -->
            <el-button v-if="(roleType == '1' || roleType == '5' || roleType == '6') &&
                information.contractStatusName == '电子签章完成'
                " size="mini" class="el-button2" type="primary" @click="jumpToInsert()">收款</el-button>
            <!-- <el-button size="mini" class="el-button2" type="primary" @click="jumpToInsert()">收款</el-button> -->
            <el-button v-if="(roleType == '7' || roleType == '5' || roleType == '6') &&
                information.contractStatusName == '付款完成' &&
                information.tradeTypeName == '买卖'
                " size="mini" @click="zhiyinDialogVisible = true" class="el-button2" type="primary">过户完成确认</el-button>
            <el-button v-if="(roleType == '7' || roleType == '5' || roleType == '6') &&
                information.contractStatusName == '过户完成' && isOnLine != 0
                " size="mini" @click="zhiyinDialogVisible = true" class="el-button2" type="primary">交易完结确认</el-button>
            <!-- <el-button size="mini" @click="zhiyinDialogVisible = true" class="el-button2" type="primary">交易完结确认</el-button> -->
            <el-button v-if="(roleType == '7' || roleType == '5' || roleType == '6') &&
                information.contractStatusName == '付款完成' &&
                information.tradeTypeName == '租赁' && isOnLine != 0
                " size="mini" @click="zhiyinDialogVisible = true" class="el-button2" type="primary">交易完结确认</el-button>

            <el-button v-if="(roleType == '7' || roleType == '5' || roleType == '6') &&
                (information.contractStatusName == '过户完成' || information.contractStatusName == '付款完成') && isOnLine == 0
                " size="mini" @click="zhiyinDialogVisible = true" class="el-button2"
                type="primary">线下合同完结确认</el-button>

            <el-dialog class="zhiyinDialog" title="上传材料图片" :visible.sync="zhiyinDialogVisible" width="70%"
                :before-close="zhiyinDialogClose">
                <div
                    v-if="(information.tradeTypeName == '买卖' && information.contractStatusName == '过户完成') || (information.tradeTypeName == '租赁' && information.contractStatusName == '付款完成')">
                    上传房屋交接单 / 物业交接单凭证:</div>
                <el-upload :file-list="picture" :action="UploadPics" list-type="picture-card" :on-progress="fileChange"
                    :on-success="successpic" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview"
                    style="width: 75.5%">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" append-to-body>
                    <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
                <span slot="footer" class="dialog-footer">
                    <!-- <el-button v-if="information.contractStatusName == '电子签章完成'" type="primary" @click="PaymentComplete()">确 定</el-button> -->
                    <el-button
                        v-if="information.tradeTypeName == '买卖' && information.contractStatusName == '付款完成' && isOnLine != 0"
                        type="primary" @click="OwnerComplete()">确 定</el-button>
                    <el-button
                        v-if="((information.tradeTypeName == '买卖' && information.contractStatusName == '过户完成') || (information.tradeTypeName == '租赁' && information.contractStatusName == '付款完成')) && isOnLine != 0"
                        type="primary" @click="FlowComplete()">确 定</el-button>
                    <el-button
                        v-if="((information.tradeTypeName == '买卖' && information.contractStatusName == '过户完成') || (information.tradeTypeName == '租赁' && information.contractStatusName == '付款完成')) && isOnLine == 0"
                        type="primary" @click="OfflineAdd()">确 定</el-button>
                    <el-button class="el-button3" type="primary" @click="zhiyinDialogVisible = false">取消</el-button>
                </span>
            </el-dialog>


        </el-card>

        <el-card class="qianyue-xinxi">
            <div class="h1-title" style="margin: 0px auto; height: 40px">
                <div class="title-div">合同基本信息<span v-if="form.isDingZhuanYong == '1'" style="color:red;">(定转佣)</span></div>
            </div>
            <div class="hetong-xinxi margin-top0">
                <!-- <img
          src="../../../public/img/logo.jpg"
          alt
          style="width: 170px; height: 130px; margin-top: 15px; float: left"
        /> -->
                <!-- <div style="width: 83%; margin-left: 3%; float: left"> -->
                <div style="width: 100%">
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">合同编号:</span>
                            <span class="labelValue">{{ information.contractNo }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">交易类型:</span>
                            <span class="labelValue">{{ information.tradeTypeName }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">协议状态:</span>
                            <span class="labelValue">{{
                                information.contractStatusName
                            }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">房源编号:</span>
                            <span class="labelValue">{{ information.houseNo }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">房源价格:</span>
                            <span v-if="information.tradeTypeName == '买卖'" class="labelValue">{{
                                information.contractPrice != ""
                                ? information.contractPrice + "元"
                                : ""
                            }}</span>
                            <span v-if="information.tradeTypeName == '租赁'" class="labelValue">{{
                                information.contractPrice != ""
                                ? information.contractPrice + "元/月"
                                : ""
                            }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">产权地址:</span>
                            <span class="labelValue">{{
                                information.contractHouseAddress
                            }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">业主姓名:</span>
                            <span class="labelValue">{{ information.sellerName + "\xa0" + '电话:' + information.sellerMobile
                            }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">客户姓名:</span>
                            <span class="labelValue">{{ information.buyerName + "\xa0" + '电话:' +
                                information.buyerMobile }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">创建时间:</span>
                            <span class="labelValue">{{ information.createTime }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">卖家签署时间:</span>
                            <span class="labelValue">{{ eSignSellerSignTime1 }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">买家签署时间:</span>
                            <span class="labelValue">{{ eSignBuyerSignTime1 }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">店铺的商圈经理签署时间:</span>
                            <span class="labelValue">{{ eSignShopManagerSignTime1 }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <!-- <el-col :span="8" class="mr20">
              <span class="titleLabel">签约时间:</span>
              <span class="labelValue">{{ information.updateTime }}</span>
            </el-col> -->
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">签约店组:</span>
                            <span class="labelValue">{{ information.shopName }}</span>
                        </el-col>
                        <el-col :span="8" class="mr20">
                            <span class="titleLabel">签约专员:</span>
                            <span class="labelValue">{{ information.contractUserName }}</span>
                        </el-col>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="24" class="mr20">
                            <span class="titleLabel">签约模式:</span>
                            <span class="labelValue">签约中心签约</span>
                        </el-col>
                    </el-row> -->
                    <el-row>
                        <span class="titleLabel">过户证明和交易完结证明:</span>

                    </el-row>
                    <el-row>
                        <el-image style="width: 125px; height: 100px;margin:0 10px 0 0 " v-for="url in qianyueImageList"
                            :key="url" :src="url" :preview-src-list="qianyueImageList">
                        </el-image>
                    </el-row>
                </div>
            </div>
        </el-card>

        <el-card class="qianyue-xinxi">
            <el-steps v-if="information.tradeTypeName == '买卖'" class="" :active="active" finish-status="success"
                style="line-height: 0px">
                <el-step title="创建合同完成" :description="information.createTime"></el-step>
                <el-step title="合同审核完成" :description="confirmTime1"></el-step>
                <el-step title="合同上传完成" :description="uploadCompleteTime1"></el-step>
                <el-step title="电子签章完成" :description="signCompleteTime1"></el-step>
                <el-step :title="titleName" :description="paymentCompleteTime1"></el-step>
                <el-step title="过户完成" :description="ownerCompleteTime1"></el-step>
                <el-step title="交易完结" :description="flowCompleteTime1"></el-step>
            </el-steps>

            <el-steps v-if="information.tradeTypeName == '租赁'" class="" :active="active" finish-status="success"
                style="line-height: 0px">
                <el-step title="创建合同完成" :description="information.createTime"></el-step>
                <el-step title="合同审核完成" :description="confirmTime1"></el-step>
                <el-step title="合同上传完成" :description="uploadCompleteTime1"></el-step>
                <el-step title="电子签章完成" :description="signCompleteTime1"></el-step>
                <el-step :title="titleName" :description="paymentCompleteTime1"></el-step>
                <el-step title="交易完结" :description="flowCompleteTime1"></el-step>
            </el-steps>
            <!-- <div class="caozuojilu" @click="caozuojilu">查看付款相关记录</div> -->
        </el-card>
        <el-card class="qianyue-xinxi" v-if="dialogcaozuojilu">
            <el-timeline reverse>
                <el-timeline-item v-for="item in caozuoList" :key="item.key" :timestamp="item.updateTime" placement="top">
                    <span v-if="item.moneyType != '业绩分成'" class="jiaoyipingzheng">{{ item.createPersonName }}收{{
                        item.moneyType }}{{ item.shiShouShiFuAmount }}元</span>
                    <span v-else>{{ item.remark }}</span>
                    <span v-if="roleType == 1 && item.isFuiouReceived == 1" class="shangchuan"
                        @click="toupdataimg(item.financialId)">点击上传交易凭证</span>
                    <span v-if="item.receriverType == 'Pos机'">
                        <span style="    margin-left: 10px;"
                            v-if="item.isFuiouReceived == 1 && item.moneyType != '业绩分成'">POS机已收款</span>
                        <span style="    margin-left: 10px;"
                            v-if="item.isFuiouReceived == 0 && item.moneyType != '业绩分成'">POS机未收款</span>
                    </span>
                    <br>
                    <div v-for="url in item.piclist" :key="url" style="display: inline-block; margin:0 10px;">
                        <el-image style="width: 100px; height: 100px;" :src="url" :preview-src-list="item.piclist">
                        </el-image>
                    </div>
                </el-timeline-item>
            </el-timeline>
        </el-card>
        <!-- 新增收款 -->
        <el-dialog title="新增收款" :visible.sync="dialogVisible1" width="70%">
            <el-form :model="form" :disabled="form.WeiShouYingFuAmount == 0" ref="form" :rules="caiwuRules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="总应收款：" label-width="20%" required>
                            <el-input v-model="form.YingShouYingFuAmount" disabled
                                oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%;">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总未收款：" label-width="20%" required>
                            <el-input v-model="form.WeiShouYingFuAmount" disabled oninput="value=value.replace(/[^\d]/g,'')"
                                style="width: 60%;">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="付款方式：" label-width="20%" prop="receriverType">
                            <el-select v-model="form.receriverType" placeholder="请选择付款方式" style="width: 60%">
                                <el-option label="Pos机" value="Pos机"></el-option>
                                <el-option label="微信收款" value="微信收款"></el-option>
                                <el-option label="公户收款" value="公户收款"></el-option>
                                <el-option label="现金收款" value="现金收款"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-show="form.receriverType == 'Pos机'">
                        <el-form-item label="POS机编号：" label-width="20%" prop="term">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入POS机编号" v-model="form.term"
                                style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item v-if="information.tradeTypeName == '买卖'" label="收款方：" label-width="20%"
                            prop="shoukuantab">
                            <el-radio @change='changeshoukuan()' v-model="form.shoukuantab" label="first">买方</el-radio>
                            <el-radio @change='changeshoukuan()' v-model="form.shoukuantab" label="second">卖方</el-radio>
                        </el-form-item>
                        <el-form-item v-if="information.tradeTypeName == '租赁'" label="收款方：" label-width="20%"
                            prop="shoukuantab">
                            <el-radio @change='changeshoukuan()' v-model="form.shoukuantab" label="first">出租方</el-radio>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="POS机编号：" label-width="20%" prop="term">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入POS机编号" v-model="form.term"
                                style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>

                <el-row v-if="information.tradeTypeName == '买卖'">
                    <el-col :span="12" v-if="form.shoukuantab == 'first'">
                        <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
                            <el-checkbox-group @change="fuwufeiyingshou()" v-model="form.MoneyType">
                                <el-checkbox label="定金"></el-checkbox>
                                <el-checkbox label="乙方服务费"></el-checkbox>
                                <el-checkbox label="保障服务费"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.shoukuantab == 'second'">
                        <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
                            <el-checkbox-group v-model="form.MoneyType">
                                <el-checkbox label="甲方服务费"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="当前应收款：" label-width="20%">
                            <el-input disabled v-model="form.dangqianyingshou" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="information.tradeTypeName == '租赁'">
                    <el-col :span="12" v-if="form.shoukuantab == 'first'">
                        <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
                            <el-checkbox-group v-model="form.MoneyType">
                                <el-checkbox label="出租方服务费"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12" v-if="form.shoukuantab=='second'">
                        <el-form-item label="资金类型：" label-width="20%" prop="MoneyType">
                            <el-checkbox-group v-model="form.MoneyType">
                                <el-checkbox label="甲方服务费">服务费</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="当前应收款：" label-width="20%">
                            <el-input disabled v-model="form.dangqianyingshou" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('定金') != -1">
                        <el-form-item label="定金：" label-width="20%" prop="dingJinAmount">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入定金实收款金额"
                                v-model="form.dingJinAmount" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('乙方服务费') != -1 && form.shoukuantab == 'first'">
                        <el-form-item label="买方服务费：" label-width="20%" prop="yiFangFuWuFeiAmount">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入服务费实收款金额"
                                v-model="form.yiFangFuWuFeiAmount" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('甲方服务费') != -1 && form.shoukuantab == 'second'">
                        <el-form-item label="卖方服务费：" label-width="20%" prop="jiaFangFuWuFeiAmount">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入服务费实收款金额"
                                v-model="form.jiaFangFuWuFeiAmount" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('保障服务费') != -1">
                        <el-form-item label="保障服务费：" label-width="20%" prop="baoZhangFuWuFeiAmount">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入保障服务费实收款金额"
                                v-model="form.baoZhangFuWuFeiAmount" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('出租方服务费') != -1">
                        <el-form-item label="出租方服务费：" label-width="20%" prop="jiaFangFuWuFeiAmount">
                            <el-input oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入保障服务费实收款金额"
                                v-model="form.jiaFangFuWuFeiAmount" style="width: 60%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.MoneyType.indexOf('甲方服务费') != -1 && form.shoukuantab == 'second'">
                        <el-form-item label="是否定专佣：" label-width="20%" prop="jiaFangFuWuFeiAmount">
                            <div v-if="form.isDingZhuanYong == '1'">是</div>
                            <div v-else>否</div>
                            <!-- <el-radio @change='changeshoukuan()' v-model="form.isDingZhuanYong" label="0">否</el-radio>
                            <el-radio @change='changeshoukuan()' v-model="form.isDingZhuanYong" label="1">是</el-radio> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注:" label-width="10%">
                            <el-input style="width:82.5%" v-model="form.Remark" type="textarea" :rows="5"
                                placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item style="margin-top:10px;">
                            <el-button size="mini" style="margin-right:10px" type="primary"
                                @click="saveafter('form', 'add')">确定</el-button>
                            <el-button size="mini" type="warning" @click="dialogVisible1 = false">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table ref="ruzhangTable" :data="jiluList" tooltip-effect="dark" :header-cell-style="{
                'text-align': 'center',
                color: '#333',
                'font-size': '14px',
            }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column label="交易编号" prop="financialNo" min-width="95px"></el-table-column>
                <el-table-column label="POS机是否收款" prop="isFuiouReceived" min-width="100px"></el-table-column>
                <el-table-column label="应收款" prop="yingShouYingFuAmount"></el-table-column>
                <el-table-column label="实收款" prop="shiShouShiFuAmount"></el-table-column>
                <el-table-column label="资金类型" prop="moneyType"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作" width="150px;">
                    <template slot-scope="scope" class="right">
                        <el-button v-if="scope.row.isFuiouReceived == '否'" @click.native.stop="handleDelete(scope.row)"
                            type="text" size="mini" style="color: red">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 客源分成 -->
        <el-dialog class="zhiyinDialog" title="客源销售业绩分成" :visible.sync="fenchengDialogVisible" width="70%"
            :before-close="zhiyinDialogClose">
            <el-form :model="fukuanoverForm" ref="fukuanoverForm" label-width="100px" class="demo-dynamic">
                <el-form-item prop="totalShare" label="总分成金额" style="width:90%">
                    <el-input disabled v-model="fukuanoverForm.totalShare" style="width:27%"></el-input>
                </el-form-item>
                <el-row v-for="(item, index) in fukuanoverForm.jingjirens" :key="item.key">
                    <el-col :span="7">
                        <el-form-item prop="name" :label="'经纪人' + (index + 1) + ':'">
                            <el-button size="mini" class="buttonInput button_tanchu" @click="clickUser(index)"
                                style="vertical-align: top;width: 100%;height: 22px;line-height:0px;text-align: left;">
                                <span>{{ item.name }}</span>
                            </el-button>
                            <!-- <el-input v-model="item.name"></el-input> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="proportion" label="比例:">
                            <el-input-number v-model="item.proportion" style="width:90%; margin-right:5px;"
                                @blur="havemoney(item.proportion, index)" :controls="false"
                                :precision="2"></el-input-number>
                            <!-- <el-input oninput="value=value.replace(/[^0-9.]/g,'')"  @blur="havemoney(item.proportion,index)" v-model="item.proportion"></el-input> -->
                            <span>%</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="money" label="金额:">
                            <el-input disabled v-model="item.money"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-button style="margin-left:10%; line-height: 5px; padding: 11px 20px;" v-if="index == 0"
                            @click="addjingjiren">新增经纪人</el-button>
                        <el-button style="margin-left: 10%; line-height: 5px; padding: 11px 20px;" v-else
                            @click.prevent="removejingjiren(item)">删除</el-button>
                    </el-col>
                </el-row>
                <el-row style="margin-bottom:25px;">
                    <el-col :span="24">
                        <el-form-item label="上传交易证明:" label-width="10%">
                            <el-upload :file-list="picture" :action="UploadPics" list-type="picture-card"
                                :on-progress="fileChange" :on-success="successpic" accept="image/*" :headers="MyHeader"
                                :on-preview="handlePictureCardPreview" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible" append-to-body>
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('fukuanoverForm')">确 定</el-button>
                    <el-button @click="fenchengDialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
            <!-- <span slot="footer" class="dialog-footer">
                    <el-button v-if="information.contractStatusName == '付款完成'" type="primary" @click="OwnerComplete()">确 定</el-button>
                    <el-button v-if="(information.tradeTypeName=='租赁'&&information.contractStatusName == '付款完成')" type="primary" @click="FlowComplete()">确 定</el-button>
                    <el-button class="el-button3" type="primary" @click="fenchengDialogVisible = false">取消</el-button>
                </span> -->
        </el-dialog>
        <el-dialog title="经纪人列表" :visible.sync="jingjirenDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <TongXunLuList :isfencheng='true' :fenchengindex='fenchengindex' :isQianyueOpen='true'
                @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>
        <el-dialog class="zhiyinDialog" title="上传交易凭证" :visible.sync="updataimgdialog" width="70%"
            :before-close="updataimgClose">
            <el-upload :limit='10' :file-list="picture" :action="UploadPics" list-type="picture-card"
                :on-progress="fileChange" :on-success="successpic" accept="image/*" :headers="MyHeader"
                :on-preview="handlePictureCardPreview" style="width: 75.5%">
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="AddFileIds()">确 定</el-button>
                <el-button class="el-button3" type="primary" @click="updataimgdialog = false">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TongXunLuList from "../TongXunLu/TongXunLuList.vue"
export default {
    inject: ["reload"],
    components: {
        TongXunLuList
    },
    watch: {},
    data() {
        return {
            contractsPicSrc: this.$url.contractsPicSrc,
            jiaoyiPics: [],
            caozuoList: [],
            jiluList: [],
            updataimgdialog: false,
            fenchengindex: 0,
            jingjirenDialog: false,
            fukuanoverForm: {
                totalShare: "100",
                jingjirens: [
                    {
                        proportion: "",
                        name: "",
                        money: "",
                        userId: "",
                        userLevel: ""
                    }
                ]
                // email: ""
            },
            dialogcaozuojilu: true,
            userid: localStorage.getItem("userId"),
            form: {
                isDingZhuanYong: "0",
                tjiaFangFuWuFeiAmount: 0, //买方服务费暂存
                tyiFangFuWuFeiAmount: 0, //卖方服务费暂存
                tbaoZhangFuWuFeiAmount: 0, //保障服务费暂存
                tdingJinAmount: 0, //定金暂存
                dangqianyingshou: 0, //当前应收款
                shoukuantab: "first",
                contractNo: "请选择合同", //合同编号
                FinancialType: "0", //财务类型
                FinancialName: "", //财务类型名
                MoneyType: [], //资金类型
                YingShouYingFuAmount: "", //应收款
                WeiShouYingFuAmount: "", //未收款
                ShiShouShiFuAmount: "", //已收款
                jiaFangFuWuFeiAmount: 0, //买方服务费
                yiFangFuWuFeiAmount: 0, //卖方服务费
                baoZhangFuWuFeiAmount: 0, //保障服务费
                dingJinAmount: 0, //定金
                receriverType: '',
                term: "62433747",
                FinancialDate: "1970-01-01T00:00:00", //入账时间
                // FinancialDate: "", //入账时间
                ContractId: "", //合同ID （非必填）
                Remark: "", //备注（非必填）
                AccountantUserId: "", //（只有在【平台】、【店东】权限下是必传）
                contractPrice: "", //总价
                wsyiFangFuWuFeiAmount: "", //买方服务费
                wsjiaFangFuWuFeiAmount: "", //卖方服务费
                wsdingJinAmount: "", //定金
                wsbaoZhangFuWuFeiAmount: "", //保障服务费
                ShiFuYinHang: "", //银行卡号
                ShiFuFaPiao: "" //发票单号
            },
            props: { multiple: true },
            financialId: "",
            financialNo: "",
            caiwuRules: {
                ShiFuYinHang: [
                    {
                        required: true,
                        message: "请输入银行卡号",
                        trigger: "blur"
                    }
                ],
                ShiFuFaPiao: [
                    {
                        required: true,
                        message: "请输入发票单号",
                        trigger: "blur"
                    }
                ],
                shoukuantab: [
                    {
                        required: true,
                        message: "请选择收款方",
                        trigger: "change"
                    }
                ],
                MoneyType: [
                    {
                        required: true,
                        message: "请选择资金类型",
                        trigger: "blur"
                    }
                ],
                YingShouYingFuAmount: [
                    {
                        required: true,
                        message: "请输入应付款金额",
                        trigger: "blur"
                    }
                ],
                WeiShouYingFuAmount: [
                    {
                        required: true,
                        message: "请输入未付款金额",
                        trigger: "blur"
                    }
                ],
                ShiShouShiFuAmount: [
                    {
                        required: true,
                        message: "请输入已收款金额",
                        trigger: "blur"
                    }
                ],
                jiaFangFuWuFeiAmount: [
                    {
                        required: true,
                        message: "请输入买方服务费",
                        trigger: "blur"
                    }
                ],
                yiFangFuWuFeiAmount: [
                    {
                        required: true,
                        message: "请输入卖方服务费",
                        trigger: "blur"
                    }
                ],
                baoZhangFuWuFeiAmount: [
                    {
                        required: true,
                        message: "请输入保障服务费",
                        trigger: "blur"
                    }
                ],
                dingJinAmount: [
                    {
                        required: true,
                        message: "请输入定金",
                        trigger: "blur"
                    }
                ],
                receriverType: [
                    {
                        required: true,
                        message: "请选择付款方式",
                        trigger: "change"
                    }
                ],
                term: [
                    {
                        required: true,
                        message: "请输入POS机编号",
                        trigger: "blur"
                    }
                ],

                FinancialDate: [
                    {
                        required: true,
                        message: "请选择入账时间",
                        trigger: "blur"
                    }
                ]
            },
            dialogVisible1: false,
            roleType: localStorage.getItem("roleType"),
            state: this.$route.query.state,
            contractId: this.$route.query.contractId,
            name: "",
            active: 0,
            // picture: [],
            MyHeader: { currentRole: "", accessToken: "", fp: "" },
            loading: true,
            titleName: "",
            zhiyinDialogVisible: false,
            fenchengDialogVisible: false,
            dialogImageUrl: "",
            dialogVisible: false,
            UploadPics: this.$url.UploadContracts,
            qianyueImage: this.$url.qianyueImage,
            qianyueImageList: [],
            picture: [],
            Pics: [],
            paymentFileIds: [],
            ContractId: "",
            confirmTime1: "", //审核完成时间
            eSignBuyerSignTime1: "", //买家签署时间
            eSignSellerSignTime1: "", //卖家签约时间
            eSignShopManagerSignTime1: "", //店铺的商圈经理签约时间
            uploadCompleteTime1: "", //合同编辑完成，并上传e签宝的时间
            signCompleteTime1: "", //三方签约完成时间
            paymentCompleteTime1: "", //付款完成时间
            ownerCompleteTime1: "", //过户完成时间
            flowCompleteTime1: "", //交易流程完成时间
            eSignFileUrl1: "", //下载合同
            isOnLine: "",
            information: {
                //合同基本信息
                contractNo: "", //合同编号
                tradeType: "", //交易类型
                tradeTypeName: "", //交易类型名称
                contractPrice: "", //合同价格
                keHuXingMing: "", //客户姓名
                yeZhuXingMing: "", //业主姓名
                houseNo: "", //房源编号
                shopName: "", //签约店组
                contractUserName: "", //签约专员
                signDate: "", //签约时间
                contractHouseAddress: "", //产权地址
                contractStatus: "", //协议状态
                contractStatusName: "", //协议状态名称
                createTime: "", //创建时间
                confirmTime: "", //审核完成时间
                eSignBuyerSignTime: "", //买家签署时间
                eSignSellerSignTime: "", //卖家签约时间
                eSignShopManagerSignTime: "", //店铺的商圈经理签约时间
                uploadCompleteTime: "", //合同编辑完成，并上传e签宝的时间
                signCompleteTime: "", //三方签约完成时间
                paymentCompleteTime: "", //付款完成时间
                ownerCompleteTime: "", //过户完成时间
                flowCompleteTime: "" //交易流程完成时间
            } //合同基本信息
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.name = "签约(合同)详情"
        this.getContractDetail()
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }

        this.form.ContractId = this.contractId
    },
    methods: {
        //删除交易记录
        handleDelete(index) {
            var data1 = [index.financialId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.DeleteOneContractFinancial, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => { })
        },
        //当前类型应收款
        fuwufeiyingshou() {
            let checkboxList = [
                "甲方服务费",
                "乙方服务费",
                "保障服务费",
                "定金"
            ]
            let checkList = this.form.MoneyType
            this.form.MoneyType = checkboxList.filter((item) =>
                checkList.includes(item)
            )
            console.log(this.form.MoneyType, "资金类型")
            var djmoney = 0
            var fwfmoney = 0
            var bzfwf = 0
            this.form.dingJinAmount = 0
            this.form.yiFangFuWuFeiAmount = 0
            this.form.baoZhangFuWuFeiAmount = 0
            if (this.form.MoneyType.indexOf("定金") != -1) {
                this.form.dingJinAmount = this.form.tdingJinAmount
                djmoney = this.form.wsdingJinAmount
            }
            if (this.form.MoneyType.indexOf("乙方服务费") != -1) {
                this.form.yiFangFuWuFeiAmount = this.form.tyiFangFuWuFeiAmount
                fwfmoney = this.form.wsyiFangFuWuFeiAmount
            }
            if (this.form.MoneyType.indexOf("保障服务费") != -1) {
                this.form.baoZhangFuWuFeiAmount = this.form.tbaoZhangFuWuFeiAmount
                bzfwf = this.form.wsbaoZhangFuWuFeiAmount
            }
            this.form.dangqianyingshou = djmoney + fwfmoney + bzfwf
        },
        // 根据比例获取分成金额
        havemoney(money, index) {
            var that = this
            that.fukuanoverForm.jingjirens[index].money =
                Math.floor(
                    that.fukuanoverForm.totalShare * (money / 100) * 100
                ) / 100
        },
        // 关闭交易凭证弹层
        updataimgClose() {
            this.updataimgdialog = false
        },
        // 弹出交易凭证弹层
        toupdataimg(val) {
            this.financialId = val
            this.updataimgdialog = true
        },
        // 选择经纪人弹窗
        clickUser(index) {
            this.fenchengindex = index
            this.jingjirenDialog = true
        },
        // 选中经纪人返回值
        colseJingJiRenDialog(row, fenchengindex) {
            this.fukuanoverForm.jingjirens[fenchengindex].name = row.userName
            this.fukuanoverForm.jingjirens[fenchengindex].userId = row.userId
            this.fukuanoverForm.jingjirens[fenchengindex].userLevel = row.userLevel
            this.jingjirenDialog = false
        },
        // 关闭经纪人弹窗
        handleClose1() {
            this.jingjirenDialog = false
        },
        getContractFinancialList() {
            this.loading = true
            // let data = {
            //     contractId: this.contractId
            // }
            this.$http
                .get(this.$url.GetContractFinancialList, {
                    params: {
                        contractId: this.contractId //客源id
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        if (res.data.dtDocRecords.length > 0) {
                            res.data.dtDocRecords.forEach(function (i) {
                                if (i.isFuiouReceived == 0) {
                                    i.isFuiouReceived = "否"
                                } else {
                                    i.isFuiouReceived = "是"
                                }
                            })
                            this.jiluList = res.data.dtDocRecords
                        } else {
                            this.jiluList = []
                        }
                        this.loading = false
                        this.total = res.data.maxRecords
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
        },
        // 提交客源分成
        submitForm(formName) {
            var that = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var all = 0
                    var namerule = true
                    for (
                        let i = 0;
                        i < that.fukuanoverForm.jingjirens.length;
                        i++
                    ) {
                        all += Number(
                            that.fukuanoverForm.jingjirens[i].proportion
                        )
                        if (that.fukuanoverForm.jingjirens[i].name == "") {
                            namerule = false
                            break
                        }
                    }
                    if (namerule) {
                        if (all == 100) {
                            // alert("ok!")
                            const data = {
                                ContractId: that.ContractId,
                                assignmentJson: JSON.stringify(
                                    that.fukuanoverForm.jingjirens
                                )
                            }
                            that.$http
                                .post(
                                    that.$url.AddPerformanceAssignment,
                                    data,
                                    {
                                        headers: {
                                            "Content-Type":
                                                "application/json;charset=UTF-8"
                                        }
                                    }
                                )
                                .then((res) => {
                                    if (res.data.msgCode == 1) {
                                        that.$message.success("上传成功")
                                        that.fenchengDialogVisible = false
                                        that.loading = false
                                        that.PaymentComplete()
                                    } else {
                                        that.loading = false
                                        that.$message.error(res.data.message)
                                    }
                                })
                                .catch((res) => {
                                    this.$message.error("请求错误")
                                })
                        } else {
                            this.$message.error("请确认分成比例和为100%")
                        }
                    } else {
                        this.$message.error("请选择经纪人")
                    }
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        },
        //上传交易凭证
        AddFileIds() {
            let that = this
            that.loading = true
            if (that.Pics.length == 0) {
                this.$message.error("请上传交易证明")
                return
            }
            const data = {
                financialId: that.financialId,
                attachmentFileIds: that.Pics.join(",")
            }
            that.$http
                .post(that.$url.AddFileIdsToFinancialId, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("上传成功")
                        that.zhiyinDialogVisible = false
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        //   删除客源输入框
        removejingjiren(item) {
            var index = this.fukuanoverForm.jingjirens.indexOf(item)
            if (index !== -1) {
                this.fukuanoverForm.jingjirens.splice(index, 1)
            }
        },
        //   增加客源输入框
        addjingjiren() {
            this.fukuanoverForm.jingjirens.push({
                proportion: "",
                name: "",
                money: "",
                userLevel: ""
            })
        },
        //   选择收款方
        changeshoukuan(tab, event) {
            if (this.information.tradeTypeName == "买卖") {
                if (this.form.shoukuantab == "second") {
                    this.form.baoZhangFuWuFeiAmount = 0
                    this.form.dingJinAmount = 0
                    this.form.yiFangFuWuFeiAmount = 0
                    this.form.dangqianyingshou = this.form.wsjiaFangFuWuFeiAmount
                    this.form.jiaFangFuWuFeiAmount = this.form.tjiaFangFuWuFeiAmount
                    this.form.MoneyType = ["甲方服务费"]
                } else {
                    this.form.dangqianyingshou = 0
                    this.form.jiaFangFuWuFeiAmount = 0
                    this.form.MoneyType = []
                }
            } else {
                if (this.form.shoukuantab == "second") {
                    this.form.yiFangFuWuFeiAmount = 0
                    this.form.dangqianyingshou = this.form.wsjiaFangFuWuFeiAmount
                    this.form.jiaFangFuWuFeiAmount = this.form.tjiaFangFuWuFeiAmount
                    this.form.MoneyType = ["出租方服务费"]
                }
            }
        },
        // 操作记录弹窗
        // caozuojilu() {
        //     this.dialogcaozuojilu = !this.dialogcaozuojilu
        // },
        openHetongDialog() {
            this.hetongDialog = true
        },
        jumpToInsert() {
            this.dialogVisible1 = true
            this.getContractDetail()
            this.getContractFinancialList()
        },
        zhiyinDialogClose(done) {
            done()
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        successpic(response, file) {
            console.log(file)
            this.Pics.push(response.id)
            // this.personalForm.pictureId = response.id
        },
        fileChange() {
            this.disabled = true
        },
        PaymentComplete() {
            let that = this
            that.loading = true
            if (that.Pics.length == 0) {
                this.$message.error("请上传交易证明")
                return
            }
            const data = {
                ContractId: that.ContractId,
                paymentFileIds: that.Pics.join(",")
            }
            that.$http
                .post(that.$url.PaymentComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("上传成功")
                        that.zhiyinDialogVisible = false
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        OwnerComplete() {
            let that = this
            that.loading = true
            if (that.Pics.length == 0) {
                this.$message.error("请上传过户证明")
                return
            }
            const data = {
                ContractId: that.ContractId,
                ownerFileIds: that.Pics.join(","),
                tradeType: that.information.tradeType
            }
            that.$http
                .post(that.$url.OwnerComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("上传成功")
                        that.zhiyinDialogVisible = false
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        FlowComplete() {
            let that = this
            that.loading = true
            if (that.Pics.length == 0) {
                this.$message.error("请上传交易完结证明")
                return
            }
            const data = {
                ContractId: that.ContractId,
                ownerFileIds: that.Pics.join(","),
                tradeType: that.information.tradeType
            }
            that.$http
                .post(that.$url.FlowComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("确认成功")
                        that.zhiyinDialogVisible = false
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        downLoad() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            let second = date.getSeconds();
            let time1 = new Date(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second)
            let time2 = new Date(this.signCompleteTime1.replace(/\-/g, "/"))
            let d = parseInt(time1 - time2) / 1000 / 60 / 60
            if (d > 1) {
                return this.$message.error("请用手机短信查看")
            } else {
                let url = this.eSignFileUrl1
                window.location = url
                this.reload()
            }
        },
        async getContractDetail() {
            let that = this
            that.loading = true
            await that.$http
                .get(that.$url.GetContractDetail, {
                    params: {
                        contractId: that.contractId //客源id
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            let data = res.data.dtList[0]
                            if (data.ownerFileIds) {
                                var a = data.ownerFileIds.split(',')
                                a.forEach(function (item) {
                                    that.qianyueImageList.push(that.qianyueImage + item)
                                })
                            }
                            that.fukuanoverForm.totalShare =
                                Math.floor(
                                    (data.yiFangFuWuFeiAmount +
                                        data.jiaFangFuWuFeiAmount +
                                        data.baoZhangFuWuFeiAmount) *
                                    0.85 *
                                    0.7 *
                                    100
                                ) / 100
                            var weishou = 0
                            if (res.data.dtPaidSummary[0]) {
                                let weishoudata = res.data.dtPaidSummary[0]
                                if (weishoudata) {
                                    weishou =
                                        weishoudata.yiFangFuWuFeiAmount +
                                        weishoudata.jiaFangFuWuFeiAmount +
                                        weishoudata.baoZhangFuWuFeiAmount +
                                        weishoudata.dingJinAmount
                                }
                            }

                            res.data.dtDocRecords.forEach(function (i) {
                                if (i.attachmentFileIds != null) {
                                    i.jiaoyiPics = i.attachmentFileIds.split(
                                        ","
                                    )
                                    var picurl = ""
                                    i.piclist = []
                                    i.jiaoyiPics.forEach(function (m) {
                                        picurl = that.contractsPicSrc + m
                                        i.piclist.push(picurl)
                                    })
                                    console.log("qaz", i.piclist)
                                }
                            })
                            that.caozuoList = res.data.dtDocRecords
                            that.isOnLine = data.isOnLine
                            console.log("线下", that.isOnLine)
                            that.ContractId = data.contractId
                            if (data.tradeType == "1") {
                                this.form.isDingZhuanYong = data.isDingZhuanYong
                                if (
                                    data.eSignBuyerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignBuyerSignTime1 =
                                        data.eSignBuyerSignTime
                                } else {
                                    this.eSignBuyerSignTime1 = ""
                                }
                                if (
                                    data.eSignSellerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignSellerSignTime1 =
                                        data.eSignSellerSignTime
                                } else {
                                    this.eSignSellerSignTime1 = ""
                                }
                                if (
                                    data.eSignShopManagerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignShopManagerSignTime1 =
                                        data.eSignShopManagerSignTime
                                } else {
                                    this.eSignShopManagerSignTime1 = ""
                                }
                                data.tradeTypeName = "买卖"
                                that.form.YingShouYingFuAmount =
                                    data.yiFangFuWuFeiAmount +
                                    data.jiaFangFuWuFeiAmount +
                                    data.baoZhangFuWuFeiAmount +
                                    data.dingJinAmount
                                this.form.tyiFangFuWuFeiAmount =
                                    data.yiFangFuWuFeiAmount
                                this.form.tbaoZhangFuWuFeiAmount =
                                    data.baoZhangFuWuFeiAmount
                                this.form.tdingJinAmount = data.dingJinAmount
                                this.form.tjiaFangFuWuFeiAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.changeshoukuan()
                                this.form.WeiShouYingFuAmount =
                                    this.form.YingShouYingFuAmount - weishou
                                this.form.wsyiFangFuWuFeiAmount =
                                    data.yiFangFuWuFeiAmount
                                this.form.wsjiaFangFuWuFeiAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.form.wsbaoZhangFuWuFeiAmount =
                                    data.baoZhangFuWuFeiAmount
                                this.form.wsdingJinAmount = data.dingJinAmount
                                if (data.contractStatus == "10") {
                                    data.contractStatusName = "创建合同完成"
                                    this.active = 1
                                    this.titleName = "付款中"
                                } else if (data.contractStatus == "20") {
                                    data.contractStatusName = "合同审核完成"
                                    this.active = 2
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                } else if (data.contractStatus == "30") {
                                    data.contractStatusName = "合同上传完成"
                                    this.active = 3
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                } else if (data.contractStatus == "40") {
                                    data.contractStatusName = "电子签章完成"
                                    this.active = 4
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                } else if (data.contractStatus == "50") {
                                    data.contractStatusName = "付款完成"
                                    this.active = 5
                                    this.titleName = "付款完成"
                                    this.confirmTime1 = data.confirmTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.paymentCompleteTime1 =
                                        data.paymentCompleteTime
                                } else if (data.contractStatus == "60") {
                                    data.contractStatusName = "过户完成"
                                    this.active = 6
                                    this.titleName = "付款完成"
                                    this.confirmTime1 = data.confirmTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.paymentCompleteTime1 =
                                        data.paymentCompleteTime
                                    this.ownerCompleteTime1 =
                                        data.ownerCompleteTime
                                } else if (data.contractStatus == "70") {
                                    data.contractStatusName = "交易完结"
                                    this.active = 7
                                    this.titleName = "付款完成"
                                    this.confirmTime1 = data.confirmTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.paymentCompleteTime1 =
                                        data.paymentCompleteTime
                                    this.ownerCompleteTime1 =
                                        data.ownerCompleteTime
                                    this.flowCompleteTime1 =
                                        data.flowCompleteTime
                                } else if (data.contractStatus == "90") {
                                    data.contractStatusName = "合同已解约"
                                }
                            } else {
                                data.tradeTypeName = "租赁"
                                this.form.MoneyType = ["出租方服务费"]
                                if (
                                    data.eSignBuyerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignBuyerSignTime1 =
                                        data.eSignBuyerSignTime
                                } else {
                                    this.eSignBuyerSignTime1 = ""
                                }
                                if (
                                    data.eSignSellerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignSellerSignTime1 =
                                        data.eSignSellerSignTime
                                } else {
                                    this.eSignSellerSignTime1 = ""
                                }
                                if (
                                    data.eSignShopManagerSignTime.slice(0, 4) !=
                                    "0001"
                                ) {
                                    this.eSignShopManagerSignTime1 =
                                        data.eSignShopManagerSignTime
                                } else {
                                    this.eSignShopManagerSignTime1 = ""
                                }
                                this.form.YingShouYingFuAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.form.tjiaFangFuWuFeiAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.form.wsjiaFangFuWuFeiAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.form.WeiShouYingFuAmount =
                                    this.form.YingShouYingFuAmount - weishou
                                this.form.jiaFangFuWuFeiAmount =
                                    data.jiaFangFuWuFeiAmount
                                this.form.dangqianyingshou = this.form.wsjiaFangFuWuFeiAmount
                                // this.form.wsbaoZhangFuWuFeiAmount =
                                //     data.baoZhangFuWuFeiAmount
                                // this.form.wsdingJinAmount = data.dingJinAmount
                                if (data.contractStatus == "10") {
                                    data.contractStatusName = "创建合同完成"
                                    this.active = 1
                                    this.titleName = "付款中"
                                } else if (data.contractStatus == "20") {
                                    data.contractStatusName = "合同审核完成"
                                    this.active = 2
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                } else if (data.contractStatus == "30") {
                                    data.contractStatusName = "合同上传完成"
                                    this.active = 3
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                } else if (data.contractStatus == "40") {
                                    data.contractStatusName = "电子签章完成"
                                    this.active = 4
                                    this.titleName = "付款中"
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                } else if (data.contractStatus == "50") {
                                    data.contractStatusName = "付款完成"
                                    this.active = 5
                                    this.titleName = "付款完成"
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                    this.paymentCompleteTime1 =
                                        data.paymentCompleteTime
                                } else if (data.contractStatus == "70") {
                                    data.contractStatusName = "交易完结"
                                    this.active = 6
                                    this.titleName = "付款完成"
                                    this.confirmTime1 = data.confirmTime
                                    this.uploadCompleteTime1 =
                                        data.uploadCompleteTime
                                    this.signCompleteTime1 =
                                        data.signCompleteTime
                                    this.paymentCompleteTime1 =
                                        data.paymentCompleteTime
                                    this.flowCompleteTime1 =
                                        data.flowCompleteTime
                                } else if (data.contractStatus == "90") {
                                    data.contractStatusName = "合同已解约"
                                }
                            }

                            this.eSignFileUrl1 = data.eSignFileUrl
                            that.information = Object.assign(
                                that.information,
                                data
                            )
                        }
                        // that.getShuJuZiDianList()
                        that.loading = false
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        saveafter(formName, type) {
            this.$confirm(
                "请确认打开POS机并选择云秘收银模块进行支付",
                "温馨提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.onSave(formName, type)
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    })
                })
        },
        onSave(formName, type) {
            const that = this
            if (type == "add") {
                that.$refs[formName].validate(async (valid) => {
                    console.log("this.ruleForm", that.form)
                    // if (typeof that.form.FinancialDate == "string") {
                    //   that.form.FinancialDate =
                    //     that.$commonJs.dateTimeFormatter(
                    //         that.form.FinancialDate
                    //     )
                    // }
                    if (valid) {
                        that.loading = true
                        that.form.ShiShouShiFuAmount =
                            Number(that.form.yiFangFuWuFeiAmount) +
                            Number(that.form.baoZhangFuWuFeiAmount) +
                            Number(that.form.dingJinAmount) +
                            Number(that.form.jiaFangFuWuFeiAmount)
                        let param = {
                            FinancialType: that.form.FinancialType,
                            MoneyType: that.form.MoneyType.toString(),
                            YingShouYingFuAmount:
                                that.form.YingShouYingFuAmount,
                            WeiShouYingFuAmount: this.form.WeiShouYingFuAmount,
                            ShiShouShiFuAmount: Number(
                                that.form.ShiShouShiFuAmount
                            ),
                            jiaFangFuWuFeiAmount: Number(
                                that.form.jiaFangFuWuFeiAmount
                            ),
                            yiFangFuWuFeiAmount: Number(
                                that.form.yiFangFuWuFeiAmount
                            ),
                            baoZhangFuWuFeiAmount: Number(
                                that.form.baoZhangFuWuFeiAmount
                            ),
                            dingJinAmount: Number(that.form.dingJinAmount),
                            FinancialDate: that.form.FinancialDate,
                            contractId: that.form.ContractId,
                            Remark: that.form.Remark,
                            AccountantUserId: that.userid,
                            receriverType: that.form.receriverType
                        }
                        console.log(param, "参数")
                        that.$http
                            .post(that.$url.ContractInsertFinancial, param, {
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                that.loading = false
                                if (res.data.msgCode == 1) {
                                    that.$message.success("新增成功！")
                                    that.dialogVisible1 = false
                                    // that.reload();
                                    that.getContractDetail()
                                    that.financialId = res.data.financialId
                                    that.financialNo = res.data.financialNo
                                    if (that.form.receriverType == 'Pos机') {
                                        that.getInsertFuiouOrder()
                                    }

                                } else {
                                    that.$message.error(res.data.message)
                                }
                            })
                            .catch((res) => {
                                that.loading = false
                                that.disabled = false
                                this.$message.error("请求错误")
                            })
                    }
                })
            }
        },
        getInsertFuiouOrder() {
            console.log("asdf", this.financialId, "zxcv", this.financialNo)
            let that = this
            that.loading = true
            let param = {
                financialNo: that.financialNo,
                // financialNo: "00000001",
                // term_sn: "62433747"
                term_sn: that.form.term
            }
            that.$http
                .post(that.$url.InsertFuiouOrder, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        // that.$message.success("请让客户使用POS机付款")
                    }
                })
                .catch((res) => {
                    that.loading = false
                    that.$message.error(res.data.message)
                })
        },
        goBack() {
            this.$router.go(-1)
        },
        OfflineAdd() {
            let that = this
            that.loading = true
            if (that.Pics.length == 0) {
                this.$message.error("请上传所有材料")
                return
            }
            const data = {
                ContractId: that.ContractId,
                ownerFileIds: that.Pics.join(","),
                tradeType: that.information.tradeType
            }
            that.$http
                .post(that.$url.OfflineFlowComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.$message.success("确认成功！")
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        },
        cuiQianButton() {
            const data = {
                contractId: '5b59a19788c145648006e066ef8d5403',
            }
            this.$http
                .post(this.$url.RushSignESign, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.$message.success("催签成功！")
                        this.loading = false
                        // this.reload()
                    } else {
                        this.loading = false
                        this.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    this.$message.error("请求错误")
                })
        }
    }
}
</script>

<style scoped>
.shangchuan {
    color: #df0202;
    margin-left: 15px;
    font-weight: 600;
    cursor: pointer;
}

.jiaoyipingzheng {
    /* font-size: 18px; */
    font-weight: 600;
}

.disabled .el-upload--picture-card {
    display: none;
}

.el-card {
    margin-top: 15px;
}

.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}

.xin-css {
    background-color: rgb(18, 104, 192);
    color: #fff;
    padding: 1px 7px;
    border-radius: 15px 15px 15px 0;
    font-size: 4px;
    font-weight: 600;
    margin-left: -25px;
    position: absolute;
    height: 15px;
    line-height: 15px;
    margin-top: -5px;
}

.steps {
    margin: 20px 0px;
}

.steps .el-step__title,
.steps .el-step__description {
    text-align: center;
}

.caozuojilu {
    width: 100%;
    text-align: center;
    background: #f1f1f1;
    margin-top: 20px;
    cursor: pointer;
}

.title-div {
    float: left;
    font-size: 20px;
    font-weight: bold;
    color: #606266;
}
</style>
<style scoped lang="less">
/deep/.el-timeline-item__timestamp.is-top {
    margin-bottom: 0 !important;
}

/deep/.el-tabs__active-bar {
    background: transparent;
}

/deep/ .el-tabs__item {
    padding: 0 15px !important;
    border-radius: 10px 10px 0 0;
    height: 25px;
    line-height: 25px;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    padding: 0px;
}

// /deep/.el-timeline-item__tail {
//     top: 15px !important;
// }
// /deep/.el-timeline-item__node--normal {
//     top: 15px !important;
// }
.qiugou-tabs {
    font-size: 14px;

    /deep/ .el-card {
        line-height: 30px;
    }

    /deep/ .el-tabs__nav>.is-active {
        background: transparent !important;
        color: #df0202 !important;
    }

    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }

    /deep/ .el-tabs__header {
        padding-left: 20px;
        margin: 0px;
    }

    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }

    /deep/ .el-step.is-vertical .el-step__line {
        height: 20px;
        top: 23px;
        left: 6px;
        width: 1px;
    }

    /deep/ .el-step.is-vertical .el-step__head {
        width: 13px;
        display: none !important;
    }

    /deep/ .el-step__icon {
        background: #df0202;
        width: 13px;
        height: 13px;
        border: 2px solid;
        border-color: #e6baa8;
    }

    /deep/ .el-step__icon-inner {
        display: none;
    }

    /deep/ .el-step.is-vertical .el-step__title {
        padding-bottom: 0px;
    }

    /deep/ .el-step__title.is-wait,
    /deep/ .el-step__description {
        color: #000;
        font-size: 14px;
    }

    /deep/ .el-card__body {
        margin: 5px 20px;
        padding: 0px !important;
    }

    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }

    /deep/ .el-card {
        border: none;
    }

    /deep/ .el-tabs__nav-wrap {
        position: static;
    }

    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }

    /deep/.el-card__body {
        margin: 0 0 0 20 px !important;
    }
}

.steps {

    .el-step__title,
    .el-step__description {
        color: #000;
        font-size: 14px;
    }
}

.hetong-div {
    width: 9%;
    float: left;
    margin-right: 1%;

    .hetong-img {
        width: 130px;
        height: 170px;
        margin-top: 15px;
        float: left;
    }
}

.el-button2 {
    margin: 5px !important;
    float: right;
    background-color: #4f7be2;
    border-color: #4f7be2;
}

.el-button2:hover {
    margin: 5px !important;
    float: right;
    color: #409eff;
    background-color: #ecf5ff;
    border-color: #c6e2ff;
}

.el-button2:focus {
    background-color: #4f7be2;
    border-color: #4f7be2;
}

.el-button3 {
    // margin: 5px !important;
    // float: right;
    background-color: #4f7be2;
    border-color: #4f7be2;
}

.el-button3:hover {
    // margin: 5px !important;
    // float: right;
    color: #409eff;
    background-color: #ecf5ff;
    border-color: #c6e2ff;
}
</style>