<template>
  <div>
    <!-- 推荐人 -->
    <el-dialog
      title="推荐经纪人"
      :visible.sync="hezuorenDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <TongXunLuList
        :isQianyueOpen="true"
        @colseJingJiRenDialog="colseJingJiRenDialog"
      ></TongXunLuList>
    </el-dialog>

    <!-- 推荐详情 -->
    <el-dialog
      class="xuqiuDialog"
      title="推荐详情"
      :visible.sync="isTuijianKeyuan"
      width="30%"
      :before-close="tuijianKeyuanDialogClose"
    >
      <el-row>
        <el-col :span="24" style="margin-bottom: 15px">
          <span style="color: rgb(158, 158, 158)">客源：</span
          ><span style="color: #000">{{ dtCustomer.customerName }}</span>
        </el-col>
        <el-col :span="24" style="margin-bottom: 15px">
          <span style="color: rgb(158, 158, 158)"
            ><span style="color: red; margin-right: 3px">*</span>经纪人：</span
          >
          <span
            @click="hezuorenDialog = true"
            style="cursor: pointer; color: red"
            >{{ hezuoren }}</span
          >
        </el-col>
        <!-- <el-col :span="24" style="margin-bottom: 15px">
          <span style="color: rgb(158, 158, 158)">承诺总业绩的百分比：</span
          ><span style="color: #000">3%</span>
        </el-col> -->
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="warning" @click="cancelTuijianKeyuan"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveTuijianKeyuan"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <div class="div-tabs">
      <div class="tabs-title">
        客源基本信息

        <span
          class="xinzengXuQiu"
          style="z-index: 111; right: 150px; cursor: pointer"
          v-if="roleType == 6 && dtCustomer.isDeleteESign == 1"
          @click="deleteEQB()"
          >删除此人e签宝信息</span
        >
        <span
          class="xinzengXuQiu"
          style="z-index: 111; right: 0px; cursor: pointer"
          v-if="(roleType == 5||roleType == 6) && isUpdate && dtCustomer.type == 'detail' && type != '3'"
          @click="JiBenXinXi('update')"
          >修改</span
        >
        <span
          class="xinzengXuQiu"
          style="z-index: 111; right: 50px; cursor: pointer"
          v-if="
            isUpdate &&
              isRecommend == true &&
              dtCustomer.type == 'detail' &&
              dtCustomer.yiTuijian == false
          "
          @click="clickTuijianKeyuan(dtCustomer)"
          >推荐给经纪人</span
        >
      </div>
      <el-card style="margin: 0 !important; border: none">
        <div v-if="dtCustomer.type == 'detail'">
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">客户姓名:</span>
              <span class="labelValue">{{ dtCustomer.customerName }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">性别:</span>
              <span class="labelValue">{{ dtCustomer.genderText }}</span>
            </el-col>
          </el-row>
          <el-row v-if="isRole">
            <el-col :span="12">
              <span class="titleLabel">手机号:</span>
              <span class="labelValue">{{ dtCustomer.tel1 }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">其他手机号:</span>
              <span class="labelValue">{{ dtCustomer.tel2 }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">意愿等级:</span>
              <span class="labelValue">{{ dtCustomer.customerScore }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">信息来源:</span>
              <span class="labelValue">{{ dtCustomer.xinXiLaiYuan }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">客户标签:</span>
              <span class="labelValue">{{ dtCustomer.keHuBiaoQian }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">客户年龄:</span>
              <span class="labelValue">{{ dtCustomer.ageRange }}</span>
            </el-col>
          </el-row>
          <el-row v-if="isRole && type != '3'">
            <el-col :span="12">
              <span class="titleLabel">身份证号:</span>
              <span class="labelValue">{{ dtCustomer.idCardNo }}</span>
            </el-col>

            <el-col :span="12">
              <span class="titleLabel">微信:</span>
              <span class="labelValue">{{ dtCustomer.wechatNo }}</span>
            </el-col>
          </el-row>
          <el-row v-if="isRole">
            <el-col :span="12">
              <span class="titleLabel">邮箱:</span>
              <span class="labelValue">{{ dtCustomer.email }}</span>
            </el-col>
          </el-row>
        </div>

        <el-form
          v-if="dtCustomer.type == 'update'"
          ref="dtCustomerDetail"
          :model="dtCustomerDetail"
          :rules="dtCustomerDetailRules"
          label-position="right"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="20%" label="姓名:" prop="customerName">
                <el-input
                  style="width: 60%"
                  placeholder="请输入姓名"
                  v-model="dtCustomerDetail.customerName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="20%" label="性别:" prop="gender">
                <el-radio-group v-model="dtCustomerDetail.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isRole">
            <el-col :span="12">
              <el-form-item label-width="20%" label="手机号:" prop="tel1">
                <el-input
                  placeholder="请输入手机号"
                  :disabled="dtCustomer.tel1 != ''"
                  v-model="dtCustomerDetail.tel1"
                  maxlength="11"
                  style="width: 60%"
                  class="shoujihaoSelect input-with-select"
                >
                </el-input>
                <div>
                  <i
                    class="el-icon-warning"
                    style="color: red; margin-right: 5px"
                  ></i
                  >联系方式不可更改，且会作为核查真带看的重要依据，请认真填写
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="20%" label="其他手机号:" prop="tel2">
                <el-input
                  placeholder="请输入其他手机号"
                  :disabled="dtCustomer.tel2 != ''"
                  v-model="dtCustomerDetail.tel2"
                  maxlength="11"
                  style="width: 60%"
                  class="shoujihaoSelect input-with-select"
                >
                </el-input>
                <div>
                  <i
                    class="el-icon-warning"
                    style="color: red; margin-right: 5px"
                  ></i
                  >联系方式不可更改，且会作为核查真带看的重要依据，请认真填写
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="意愿等级:"
                label-width="20%"
                prop="customerScore"
              >
                <el-select
                  v-model="dtCustomerDetail.customerScore"
                  placeholder="请选择意愿等级"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in customerScoreOptions"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="信息来源:" label-width="20%" required>
                <div class="xuqiuForm-item">
                  <el-form-item prop="laiyuan">
                    <el-select
                      v-model="dtCustomerDetail.laiyuan"
                      @change="changeLaiyuan"
                      placeholder="请选择信息来源"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in laiyuanOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <span style="margin: 0 4px">-</span>
                  <el-form-item prop="xinXiLaiYuanId">
                    <el-select
                      v-model="dtCustomerDetail.xinXiLaiYuanId"
                      placeholder="请选择信息来源"
                      style="width: 100%"
                    >
                      <span v-if="dtCustomerDetail.laiyuan">
                        <el-option
                          v-for="item in laiyuanOptions[
                            dtCustomerDetail.laiyuan
                          ].laiyuan1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </span>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label-width="20%"
                label="客户标签:"
                prop="keHuBiaoQianId"
              >
                <el-select
                  v-model="dtCustomerDetail.keHuBiaoQianId"
                  placeholder="请选择客户标签"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in biaoqianOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户年龄:" label-width="20%" prop="ageRange">
                <el-select
                  v-model="dtCustomerDetail.ageRange"
                  placeholder="请选择客户年龄"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in kehuNianLingOptions"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isRole">
            <el-col :span="12">
              <el-form-item label="身份证号:" label-width="20%" prop="idCardNo">
                <el-input
                  style="width: 60%"
                  placeholder="请输入身份证号"
                  v-model="dtCustomerDetail.idCardNo"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="微信" label-width="20%" prop="wechatNo">
                <el-input
                  style="width: 60%"
                  placeholder="请输入微信"
                  v-model="dtCustomerDetail.wechatNo"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isRole">
            <el-col :span="12">
              <el-form-item label="邮箱" label-width="20%" prop="email">
                <el-input
                  style="width: 60%"
                  placeholder="请输入邮箱"
                  v-model="dtCustomerDetail.email"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item class="xiayibu">
            <el-button size="mini" type="warning" @click="JiBenXinXi('detail')"
              >取消</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="jiBenXinXiSave('dtCustomerDetail')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="div-tabs keyuanXuQiu">
      <div class="tabs-title">客源需求</div>
      <div
        class="xinzengXuQiu addXuqiu"
        style="cursor: pointer"
        v-if="isUpdate && type != '3'"
        @click="saveXuqiu"
      >
        新增需求
      </div>
      <el-tabs
        class="qiugou-tabs"
        style="top: 0px; background-color: #fff"
        v-for="(item, i) in xuqiuList"
        :key="item.value"
      >
        <div
          class="hetong-xinxi"
          style="width: 100%; display: block; margin-top: 20px"
        >
          <span
            style="padding-left: 20px; color: #9e9e9e"
            v-if="item.xuqiuType == ''"
            >更新时间：{{ item.createTime }}</span
          >
          <span
            class="xinzengXuQiu"
            style="cursor: pointer"
            v-if="item.xuqiuType == '' && isUpdate && type != '3'"
            @click="updateXuqiu(item, 'delete', i)"
            >删除</span
          >
          <span
            class="xinzengXuQiu"
            style="cursor: pointer"
            v-if="item.xuqiuType == '' && isUpdate && type != '3'"
            @click="updateXuqiu(item, 'update', i)"
            >修改</span
          >
        </div>
        <div>
          <el-card v-show="item.xuqiuType == ''">
            <el-row>
              <el-col :span="12" v-if="ruleForm[i].fangWuYongTuId == '买二手'">
                <span class="titleLabel">购房目的：</span>
                <span class="labelValue">{{ ruleForm[i].gouFangMuDi }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">房屋用途：</span>
                <span class="labelValue">{{ ruleForm[i].fangWuYongTuId }}</span>
              </el-col>
              <el-col :span="12" v-if="ruleForm[i].fangWuYongTuId == '租赁'">
                <span class="titleLabel">是否整租:</span>
                <span class="labelValue">{{ ruleForm[i].isZhengZu }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <span class="titleLabel">意向价位：</span>
                <span class="labelValue"
                  >{{ ruleForm[i].priceFrom
                  }}{{
                    ruleForm[i].fangWuYongTuId == "买二手" ? "万元" : "元"
                  }}-{{ ruleForm[i].priceTo
                  }}{{
                    ruleForm[i].fangWuYongTuId == "买二手" ? "万元" : "元"
                  }}</span
                >
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">需求等级：</span>
                <span class="labelValue">{{
                  ruleForm[i].requirementLevel
                }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <span class="titleLabel">面积：</span>
                <span class="labelValue">{{
                  ruleForm[i].squareFrom + "㎡-" + ruleForm[i].SquareTo + "㎡"
                }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">居室：</span>
                <span class="labelValue">{{
                  ruleForm[i].roomCountFrom +
                    "居" +
                    "-" +
                    ruleForm[i].roomCountTo +
                    "居"
                }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <span class="titleLabel">城市：</span>
                <span class="labelValue">{{ ruleForm[i].city }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">意向商圈：</span>
                <span class="labelValue">{{ ruleForm[i].areaNames }}</span>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <span class="titleLabel">装修状况：</span>
                <span class="labelValue">{{
                  ruleForm[i].zhuangXiuZhuangKuang
                }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">朝向：</span>
                <span class="labelValue">{{
                  ruleForm[i].orientationsLabel.length > 0
                    ? ruleForm[i].orientationsLabel.toString()
                    : ""
                }}</span>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <span class="titleLabel">楼层：</span>
                <span class="labelValue">{{
                  ruleForm[i].louCengFrom + "层-" + ruleForm[i].louCengTo + "层"
                }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">楼龄：</span>
                <span class="labelValue">{{
                  ruleForm[i].houseAgeFrom +
                    "年-" +
                    ruleForm[i].houseAgeTo +
                    "年"
                }}</span>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <span class="titleLabel">贷款首付/全款可付：</span>
                <span class="labelValue">{{
                  ruleForm[i].ShouFuFrom +
                    "万元-" +
                    ruleForm[i].ShouFuTo +
                    "万元"
                }}</span>
              </el-col>
              <el-col :span="12">
                <span class="titleLabel">付款方式：</span>
                <span class="labelValue">{{ ruleForm[i].FuKuanFangShi }}</span>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm[i].fangWuYongTuId != '买二手'">
              <el-col :span="12">
                <span class="titleLabel">付款方式：</span>
                <span class="labelValue">{{ ruleForm[i].FuKuanFangShi }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col
                :span="12"
                v-if="
                  ruleForm[i].fangWuYongTuId == '买二手' &&
                    ruleForm[i].FuKuanFangShi != '全款'
                "
              >
                <span class="titleLabel">月供：</span>
                <span class="labelValue">{{
                  ruleForm[i].YueGongFrom + "元-" + ruleForm[i].YueGongTo + "元"
                }}</span>
              </el-col>
            </el-row>
          </el-card>

          <el-card
            v-show="item.xuqiuType != ''"
            style="margin-top: 0px !important"
          >
            <el-form
              :ref="'ruleFormDetail' + [i]"
              :model="ruleFormDetail[i]"
              :rules="rules"
              label-position="right"
            >
              <el-row>
                <el-col
                  :span="12"
                  v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                >
                  <el-form-item
                    label-width="20%"
                    label="购房目的:"
                    prop="gouFangMuDi"
                  >
                    <el-select
                      v-model="ruleFormDetail[i].gouFangMuDi"
                      :placeholder="
                        ruleFormDetail[i].fangWuYongTuId == '买二手'
                          ? '请选择购房目的'
                          : '请选择租房目的'
                      "
                      style="width: 60%"
                    >
                      <el-option
                        v-for="item in gouFangMuDiOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="房屋用途:"
                    prop="fangWuYongTuId"
                  >
                    <el-select
                      v-model="ruleFormDetail[i].fangWuYongTuId"
                      :disabled="ruleFormDetail[i].id != 'add'"
                      placeholder="请选择房屋用途"
                      style="width: 60%"
                    >
                      <el-option
                        v-for="item in fangwuyongtuOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  :span="12"
                  v-if="ruleFormDetail[i].fangWuYongTuId == '租赁'"
                >
                  <el-form-item
                    label-width=" 20%"
                    label="是否整租:"
                    prop="isZhengZu"
                  >
                    <el-radio-group v-model="ruleFormDetail[i].isZhengZu">
                      <el-radio label="是"></el-radio>
                      <el-radio label="否"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="意向价位:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="priceFrom">
                        <el-input
                          v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>99999)value=99999"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].priceFrom"
                        ></el-input>
                        <el-input
                          v-if="ruleFormDetail[i].fangWuYongTuId == '租赁'"
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>999999999)value=999999999"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].priceFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="priceTo">
                        <el-input
                          v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                          style="width: 100%"
                          placeholder="请输入最大值(最大99999)"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>99999)value=99999"
                          :min="ruleFormDetail[i].priceFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].priceTo"
                        ></el-input>
                        <el-input
                          v-if="ruleFormDetail[i].fangWuYongTuId == '租赁'"
                          style="width: 100%"
                          placeholder="请输入最大值(最大999999999)"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>999999999)value=999999999"
                          :min="ruleFormDetail[i].priceFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].priceTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">{{
                        ruleFormDetail[i].fangWuYongTuId == "买二手"
                          ? "万元"
                          : "元"
                      }}</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="需求等级:"
                    label-width="20%"
                    prop="requirementLevel"
                  >
                    <el-select
                      v-model="ruleFormDetail[i].requirementLevel"
                      placeholder="请选择需求等级"
                      style="width: 60%"
                    >
                      <el-option
                        v-for="item in requirementLevelOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="面积:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="squareFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>99999)value=99999"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].squareFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="SquareTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最大值(最大99999)"
                          class="padding-r0"
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>99999)value=99999"
                          :min="ruleFormDetail[i].squareFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].SquareTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">平</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="居室:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="roomCountFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入居室(整数)"
                          class="padding-r0"
                          oninput="if(value>=9)value=9"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].roomCountFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="roomCountTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入居室(整数，最大99999)"
                          class="padding-r0"
                          oninput="if(value>=9)value=9"
                          :min="ruleFormDetail[i].roomCountFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].roomCountTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">居</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="城市:" prop="city">
                    <el-input
                      style="width: 60%"
                      placeholder="请输入城市"
                      maxlength="16"
                      v-model="ruleFormDetail[i].city"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="意向商圈:"
                    prop="shangquan"
                  >
                    <el-cascader
                      v-model="ruleFormDetail[i].shangquan"
                      :show-all-levels="false"
                      :options="cascaderShangquanOptions"
                      :props="shangquanProps"
                      @change="shangquanChange(ruleFormDetail[i].shangquan, i)"
                      clearable
                      style="width: 60%"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="装修状况:"
                    prop="zhuangXiuZhuangKuangId"
                  >
                    <el-select
                      v-model="ruleFormDetail[i].zhuangXiuZhuangKuangId"
                      style="width: 60%"
                      placeholder="请选择装修状况"
                    >
                      <el-option
                        v-for="item in zhuangxiuOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="朝向:"
                    prop="orientations"
                  >
                    <span class="redStar" style="margin-left: -11%">*</span>
                    <el-checkbox-group
                      style="margin-top: -30px"
                      v-model="ruleFormDetail[i].orientationsLabel"
                      @change="
                        handleCheckedChaoxiang(
                          ruleFormDetail[i].orientationsLabel,
                          i
                        )
                      "
                    >
                      <el-checkbox
                        v-for="orientations in orientationOptions"
                        :label="orientations.label"
                        :key="orientations.label"
                        >{{ orientations.label }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
                <el-col :span="12">
                  <el-form-item label-width="20%" label="楼层:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="louCengFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          oninput="if(value>120)value=120"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].louCengFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="louCengTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最大值(最大120)"
                          class="padding-r0"
                          oninput="if(value>120)value=120"
                          :min="ruleFormDetail[i].louCengFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].louCengTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">层</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="房龄:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="houseAgeFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          oninput="if(value>120)value=120"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].houseAgeFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="houseAgeTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最大值(最大120)"
                          class="padding-r0"
                          oninput="if(value>120)value=120"
                          :min="ruleFormDetail[i].houseAgeFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].houseAgeTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">年</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="贷款首付/全款可付:"
                    required
                  >
                    <div class="xuqiuForm-item">
                      <el-form-item prop="ShouFuFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          oninput="if(value>999)value=999"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].ShouFuFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="ShouFuTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最大值(最大999)"
                          class="padding-r0"
                          oninput="if(value>999)value=999"
                          :min="ruleFormDetail[i].ShouFuFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].ShouFuTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">万元</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="付款方式:"
                    prop="FuKuanFangShi"
                  >
                    <el-select
                      v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                      v-model="ruleFormDetail[i].FuKuanFangShi"
                      placeholder="请选择付款方式"
                      style="width: 60%"
                    >
                      <el-option
                        v-for="item in qiuGouFuKuanOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleFormDetail[i].fangWuYongTuId != '买二手'">
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="付款方式:"
                    prop="FuKuanFangShi"
                  >
                    <el-select
                      v-model="ruleFormDetail[i].FuKuanFangShi"
                      placeholder="请选择付款方式"
                      style="width: 60%"
                    >
                      <el-option
                        v-for="item in qiuZuFuKuanOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col
                  :span="12"
                  v-if="
                    ruleFormDetail[i].fangWuYongTuId == '买二手' &&
                      ruleFormDetail[i].FuKuanFangShi != '全款'
                  "
                >
                  <el-form-item label-width="20%" label="月供:" required>
                    <div class="xuqiuForm-item">
                      <el-form-item prop="YueGongFrom">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最小值"
                          class="padding-r0"
                          oninput="if(value>99999)value=99999"
                          min="0"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].YueGongFrom"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">-</span>
                      <el-form-item prop="YueGongTo">
                        <el-input
                          style="width: 100%"
                          placeholder="请输入最大值(最大99999)"
                          class="padding-r0"
                          oninput="if(value>99999)value=99999"
                          :min="ruleFormDetail[i].YueGongFrom"
                          type="number"
                          @blur.native.capture="ruleFormDetailIndex(i)"
                          v-model="ruleFormDetail[i].YueGongTo"
                        ></el-input>
                      </el-form-item>
                      <span style="margin: 0 4px">元</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label-width="20%">
                <el-button
                  size="mini"
                  type="warning"
                  @click="updateXuqiu(item, 'cancel', i)"
                  >取消</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="onUpdate('ruleFormDetail' + [i], i)"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import KeYuanJs from "./KeYuan.js";
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue";

export default {
  inject: ["reload"],
  components: {
    TongXunLuList
  },
  data() {
    var inputPhone = (rule, value, callback) => {
      if (this.dtCustomerDetail.tel1 == "") {
        callback(new Error("请输入手机号"));
      } else if (this.dtCustomerDetail.tel2 == this.dtCustomerDetail.tel1) {
        callback(new Error("两次输入手机号一致!"));
      } else if (!this.$commonJs.numberPhone(this.dtCustomerDetail.tel1)) {
        callback(new Error("格式不符，请填写正确的手机号!"));
      } else {
        callback();
      }
    };
    var inputPhone2 = (rule, value, callback) => {
      if (
        this.dtCustomerDetail.tel2 != "" &&
        this.dtCustomerDetail.tel1 != "" &&
        this.dtCustomerDetail.tel2 == this.dtCustomerDetail.tel1
      ) {
        callback(new Error("两次输入手机号一致!"));
      } else if (
        this.dtCustomerDetail.tel2 != "" &&
        !this.$commonJs.numberPhone(this.dtCustomerDetail.tel2)
      ) {
        callback(new Error("格式不符，请填写正确的手机号!"));
      } else {
        callback();
      }
    };
    var inputEmail = (rule, value, callback) => {
      if (
        this.dtCustomerDetail.email != "" &&
        !this.$commonJs.numberEmail(this.dtCustomerDetail.email)
      ) {
        callback(new Error("格式不符，请填写正确的邮箱!"));
      } else {
        callback();
      }
    };
    var inputIdCardNo = (rule, value, callback) => {
      if (
        this.dtCustomerDetail.idCardNo != "" &&
        !this.$commonJs.shenfenZheng(this.dtCustomerDetail.idCardNo)
      ) {
        callback(new Error("身份证号格式不正确，请填写正确的身份证号!"));
      } else {
        callback();
      }
    };
    var NumberFormat = (param1, param2) => {
      let that = this;
      that.ruleFormDetail[that.formDetailIndex][
        param1
      ] = that.$commonJs.NumberFormat(
        that.ruleFormDetail[that.formDetailIndex][param1]
      );
      that.ruleFormDetail[that.formDetailIndex][
        param2
      ] = that.$commonJs.NumberFormat(
        that.ruleFormDetail[that.formDetailIndex][param2]
      );
    };
    var inputPrice1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("priceFrom", "priceTo");
      if (that.ruleFormDetail[that.formDetailIndex].priceTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "priceTo"
        );
      }
      callback();
    };
    var inputPrice2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("priceFrom", "priceTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].priceTo <
        that.ruleFormDetail[that.formDetailIndex].priceFrom
      ) {
        callback(new Error("最大金额需大于最小金额!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].priceTo == 0) {
        callback(new Error("请输入金额!"));
      } else {
        callback();
      }
    };
    var inputSquare1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("squareFrom", "SquareTo");
      if (that.ruleFormDetail[that.formDetailIndex].SquareTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "SquareTo"
        );
      }
      callback();
    };
    var inputSquare2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("squareFrom", "SquareTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].SquareTo <
        that.ruleFormDetail[that.formDetailIndex].squareFrom
      ) {
        callback(new Error("最大面积需大于最小面积!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].SquareTo == 0) {
        callback(new Error("请输入面积!"));
      } else {
        callback();
      }
    };
    var inputRoomCount1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("roomCountFrom", "roomCountTo");
      if (that.ruleFormDetail[that.formDetailIndex].roomCountTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "roomCountTo"
        );
      }
      callback();
    };
    var inputRoomCount2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("roomCountFrom", "roomCountTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].roomCountTo <
        that.ruleFormDetail[that.formDetailIndex].roomCountFrom
      ) {
        callback(new Error("最多居室需大于最少居室!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].roomCountTo == 0) {
        callback(new Error("请输入居室!"));
      } else {
        callback();
      }
    };
    var inputShouFu1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("ShouFuFrom", "ShouFuTo");
      if (that.ruleFormDetail[that.formDetailIndex].ShouFuTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "ShouFuTo"
        );
      }
      callback();
    };
    var inputShouFu2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("ShouFuFrom", "ShouFuTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].ShouFuTo <
        that.ruleFormDetail[that.formDetailIndex].ShouFuFrom
      ) {
        callback(new Error("最多首付需多于最少首付!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].ShouFuTo == 0) {
        callback(new Error("请输入首付!"));
      } else {
        callback();
      }
    };
    var inputYueGong1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("YueGongFrom", "YueGongTo");
      if (that.ruleFormDetail[that.formDetailIndex].YueGongTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "YueGongTo"
        );
      }
      callback();
    };
    var inputYueGong2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("YueGongFrom", "YueGongTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].YueGongTo <
        that.ruleFormDetail[that.formDetailIndex].YueGongFrom
      ) {
        callback(new Error("最多月供需大于最少月供!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].YueGongTo == 0) {
        callback(new Error("请输入月供!"));
      } else {
        callback();
      }
    };
    var inputLouCeng1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("louCengFrom", "louCengTo");
      if (that.ruleFormDetail[that.formDetailIndex].louCengTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "louCengTo"
        );
      }
      callback();
    };
    var inputLouCeng2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("louCengFrom", "louCengTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].louCengTo <
        that.ruleFormDetail[that.formDetailIndex].louCengFrom
      ) {
        callback(new Error("最高楼层需高于最低楼层!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].louCengTo == 0) {
        callback(new Error("请输入楼层!"));
      } else {
        callback();
      }
    };
    var inputHouseAge1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("houseAgeFrom", "houseAgeTo");
      if (that.ruleFormDetail[that.formDetailIndex].houseAgeTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "houseAgeTo"
        );
      }
      callback();
    };
    var inputHouseAge2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("houseAgeFrom", "houseAgeTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].houseAgeTo <
        that.ruleFormDetail[that.formDetailIndex].houseAgeFrom
      ) {
        callback(new Error("最大房龄需大于最小房龄!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].houseAgeTo == 0) {
        callback(new Error("请输入房龄!"));
      } else {
        callback();
      }
    };

    return {
      roleType: window.localStorage.getItem("roleType"),
      state: this.$route.query.state, //1求购 0是求租
      loading: true,
      dtCustomerDetailRules: {
        customerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change"
          }
        ],
        tel1: [
          {
            required: true,
            validator: inputPhone,
            trigger: "blur"
          }
        ],
        tel2: [
          {
            required: false,
            validator: inputPhone2,
            trigger: "blur"
          }
        ],
        customerScore: [
          {
            required: true,
            message: "请选择意愿等级",
            trigger: "change"
          }
        ],
        laiyuan: [
          {
            required: true,
            message: "请选择信息来源",
            trigger: "change"
          }
        ],
        xinXiLaiYuanId: [
          {
            required: true,
            message: "请选择信息来源",
            trigger: "change"
          }
        ],
        keHuBiaoQianId: [
          {
            required: true,
            message: "请选择客户标签",
            trigger: "change"
          }
        ],
        idCardNo: [
          {
            required: false,
            validator: inputIdCardNo,
            trigger: "blur"
          }
        ],
        email: [
          {
            required: false,
            validator: inputEmail,
            trigger: "blur"
          }
        ]
      },
      customerScoreOptions: KeYuanJs.customerScoreOptions,
      kehuNianLingOptions: KeYuanJs.kehuNianLingOptions,
      disabled: false,
      formDetailIndex: 0,
      rules: {
        gouFangMuDi: [
          {
            required: true,
            message: "请选择购房目的",
            trigger: "change"
          }
        ],
        priceFrom: [
          {
            required: true,
            validator: inputPrice1,
            trigger: "blur"
          }
        ],
        priceTo: [
          {
            required: true,
            validator: inputPrice2,
            trigger: "blur"
          }
        ],
        squareFrom: [
          {
            required: true,
            validator: inputSquare1,
            trigger: "blur"
          }
        ],
        SquareTo: [
          {
            required: true,
            validator: inputSquare2,
            trigger: "blur"
          }
        ],
        roomCountFrom: [
          {
            required: true,
            validator: inputRoomCount1,
            trigger: "blur"
          }
        ],
        roomCountTo: [
          {
            required: true,
            validator: inputRoomCount2,
            trigger: "blur"
          }
        ],
        city: [
          {
            required: true,
            message: "请输入城市",
            trigger: "blur"
          }
        ],
        shangquan: [
          {
            required: true,
            message: "请选择意向商圈",
            trigger: "change"
          }
        ],
        fangWuYongTuId: [
          {
            required: true,
            message: "请选择房屋用途",
            trigger: "change"
          }
        ],
        FuKuanFangShi: [
          {
            required: true,
            message: "请选择付款方式",
            trigger: "change"
          }
        ],
        ShouFuFrom: [
          {
            required: true,
            validator: inputShouFu1,
            trigger: "blur"
          }
        ],
        ShouFuTo: [
          {
            required: true,
            validator: inputShouFu2,
            trigger: "blur"
          }
        ],
        YueGongFrom: [
          {
            required: true,
            validator: inputYueGong1,
            trigger: "blur"
          }
        ],
        YueGongTo: [
          {
            required: true,
            validator: inputYueGong2,
            trigger: "blur"
          }
        ],
        zhuangXiuZhuangKuangId: [
          {
            required: true,
            message: "请选择装修状况",
            trigger: "change"
          }
        ],
        orientationsLabel: [
          {
            required: true,
            message: "请选择朝向",
            trigger: "change"
          }
        ],
        louCengFrom: [
          {
            required: true,
            validator: inputLouCeng1,
            trigger: "blur"
          }
        ],
        louCengTo: [
          {
            required: true,
            validator: inputLouCeng2,
            trigger: "blur"
          }
        ],
        houseAgeFrom: [
          {
            required: true,
            validator: inputHouseAge1,
            trigger: "blur"
          }
        ],
        houseAgeTo: [
          {
            required: true,
            validator: inputHouseAge2,
            trigger: "blur"
          }
        ],
        isZhengZu: [
          {
            required: true,
            message: "请选择是否整租",
            trigger: "change"
          }
        ],
        requirementLevel: [
          {
            required: true,
            message: "请选择需求等级",
            trigger: "change"
          }
        ]
      },
      gouFangMuDiOptions: KeYuanJs.gouFangMuDiOptions,
      shangquan: [],
      shangquanParams: [],
      shangquanProps: { multiple: true, checkStrictly: true },
      qiuZuFuKuanOptions: KeYuanJs.qiuZuFuKuanOptions,
      qiuGouFuKuanOptions: KeYuanJs.qiuGouFuKuanOptions,
      orientations: [0, 0, 0, 0, 0, 0, 0, 0],
      orientationOptions: this.$commonJs.orientationOptions,
      requirementLevelOptions: KeYuanJs.requirementLevelOptions,
      xuqiuDialogVisible: false,
      xuqiuProps: { multiple: true },
      xuqiuGouOptions: KeYuanJs.xuqiuGouOptions,
      xuqiuZuOptions: KeYuanJs.xuqiuZuOptions,
      xuqiuValue: [],
      isTuijianKeyuan: false,
      hezuoren: "请点击此处选择经纪人", //推荐经纪人
      RecommendCustomerId: "", //经纪人id
      hezuorenDialog: false,
      xuqiuType: "",
      index: 0,
      ruleFormDetail: []
    };
  },
  props: {
    customerId: {
      //客源id
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    dtCustomer: {
      //客源详情信息
      type: Object,
      default: {}
    },
    dtCustomerDetail: {
      //客源修改信息
      type: Object,
      default: {}
    },
    xuqiuList: {
      type: Array,
      default: []
    },
    ruleForm: {
      type: Array,
      default: []
    },
    ruleFormParam: {
      type: Array,
      default: []
    },
    isRecommend: {
      //是否可以推荐给经纪人
      type: Boolean,
      default: false
    },
    laiyuanOptions: {
      //来源
      type: Array,
      default: []
    },
    biaoqianOptions: {
      //标签
      type: Array,
      default: []
    },
    fangwuyongtuOptions: {
      //房屋用途
      type: Array,
      default: []
    },
    zhuangxiuOptions: {
      //装修状况
      type: Array,
      default: []
    },
    cascaderShangquanOptions: {
      //商圈
      type: Array,
      default: []
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isRole: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.ruleFormDetail = JSON.parse(JSON.stringify(this.ruleFormParam));
  },
  methods: {
    //选中后关闭经纪人弹层
    colseJingJiRenDialog(jingJiRenData) {
      this.hezuoren = jingJiRenData.userName;
      this.RecommendCustomerId = jingJiRenData.userId;
      this.hezuorenDialog = false;
    },
    ruleFormDetailIndex(i) {
      this.formDetailIndex = i;
    },
    JiBenXinXi(type) {
      this.dtCustomer.type = type;
      this.dtCustomerDetail = Object.assign(
        this.dtCustomerDetail,
        this.dtCustomer
      );
    },
    changeLaiyuan() {
      this.dtCustomerDetail.xinXiLaiYuanId = "";
    },
    jiBenXinXiSave() {
      let that = this;
      that.$refs.dtCustomerDetail.validate(async valid => {
        console.log("this.dtCustomerDetail", that.dtCustomerDetail);
        if (valid) {
          that.loading = true;
          const data = {
            customerId: that.customerId,
            customerName: that.dtCustomerDetail.customerName,
            gender: that.dtCustomerDetail.gender,
            tel1: that.dtCustomerDetail.tel1,
            tel2: that.dtCustomerDetail.tel2,
            customerScore: that.dtCustomerDetail.customerScore,
            xinXiLaiYuanId: that.dtCustomerDetail.xinXiLaiYuanId,
            idCardNo: that.dtCustomerDetail.idCardNo,
            wechatNo: that.dtCustomerDetail.wechatNo,
            email: that.dtCustomerDetail.email,
            ageRange: that.dtCustomerDetail.ageRange,
            keHuBiaoQianId: that.dtCustomerDetail.keHuBiaoQianId //客户标签,
          };
          that.$http
            .put(that.$url.UpdateCustomer, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              }
            })
            .then(res => {
              if (res.data.msgCode == 1) {
                if (that.state == "1") {
                  that.$message.success("求购客户信息修改成功！");
                } else {
                  that.$message.success("求租客户信息修改成功！");
                }
                that.$emit("GetCustomerDetailRequest");
                that.dtCustomer.type = "detail";
              } else {
                that.$message.error(res.data.message);
              }
              that.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    async deleteEQB() {
      let that = this;
      const data = [that.customerId];
      console.log(data);
      const { data: res } = await this.$http.delete(
        this.$url.DeleteCustomerESign,
        {
          data: data
        }
      );

      if (res.msgCode == 1) {
        that.$message.success("删除成功！");
        that.$emit("GetCustomerDetailRequest");
        that.dtCustomer.type = "detail";
      }
    },
    updateXuqiu(item, type, i) {
      let that = this;
      that.formDetailIndex = i;
      if (type != "delete") {
        for (let index = 0; index < that.xuqiuList.length; index++) {
          const element = that.xuqiuList[index];
          if (item.id == element.id && i == index) {
            if (type == "update") {
              element.xuqiuType = item.id;
              that.ruleFormDetail = JSON.parse(JSON.stringify(that.xuqiuList));
            } else if (type == "cancel") {
              element.xuqiuType = "";
            }
          }
        }
      } else {
        if (item.id != "add") {
          this.$confirm("确定要删除该需求吗？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(async () => {
              that.loading = true;
              this.$http
                .delete(this.$url.DeleteRequirement, {
                  data: [item.id]
                })
                .then(res => {
                  that.loading = false;
                  if (res.data.msgCode == 1) {
                    that.$message.success("需求删除成功！");
                    that.xuqiuList.splice(i, 1);
                  } else {
                    that.$message.error(res.data.message);
                  }
                });
              done();
            })
            .catch(_ => {});
        } else {
          that.xuqiuList.splice(i, 1);
        }
      }
      if (type == "update") {
        let activeIndex = window.localStorage.getItem("activeIndex");
        if (activeIndex == "KeYuanList") {
          let checkboxList = [];
          checkboxList = document.querySelectorAll(
            ".el-cascader-menu:nth-child(1) .el-checkbox"
          );
          for (let index = 0; index < checkboxList.length; index++) {
            checkboxList[index].style.display = "none";
          }
        }
      }
      that.$forceUpdate();
    },
    handleCheckedChaoxiang(val, i) {
      let that = this;
      let orientationsLabel = that.ruleFormDetail[i].orientationsLabel;
      that.orientations = [0, 0, 0, 0, 0, 0, 0, 0];
      orientationsLabel.forEach((item, x) => {
        if (item == "东") {
          that.orientations[0] = 1;
        }
        if (item == "东南") {
          that.orientations[1] = 1;
        }
        if (item == "南") {
          that.orientations[2] = 1;
        }
        if (item == "西南") {
          that.orientations[3] = 1;
        }
        if (item == "西") {
          that.orientations[4] = 1;
        }
        if (item == "西北") {
          that.orientations[5] = 1;
        }
        if (item == "北") {
          that.orientations[6] = 1;
        }
        if (item == "东北") {
          that.orientations[7] = 1;
        }
      });
      that.ruleFormDetail[i].orientations = that.orientations;
    },
    shangquanChange(value, i) {
      let that = this;
      if (value.length > 3) {
        that.$message.info("最多只能选三项");
        that.$nextTick(() => {
          that.xuqiuList[i].shangquan = that.shangquan;
        });
      } else {
        value.forEach(item => {
          that.shangquanParams.push(item[1]);
        });
        that.shangquan = value;
      }
    },
    saveXuqiu() {
      let that = this;
      let addXuqiuList = {
        requirementName: "",
        id: "add",
        xuqiuType: "add",
        yiTuijian: false,
        gouFangMuDi: "",
        priceFrom: 0,
        priceTo: 0,
        squareFrom: 0,
        SquareTo: 0,
        roomCountFrom: 0,
        roomCountTo: 0,
        city: "大连",
        shangquan: [],
        fangWuYongTuId: "买二手",
        FuKuanFangShi: "",
        isZhengZu: "",
        ShouFuFrom: 0,
        ShouFuTo: 0,
        YueGongFrom: 0,
        YueGongTo: 0,
        zhuangXiuZhuangKuang: "",
        orientations: [],
        orientationsLabel: [],
        louCengFrom: 0,
        louCengTo: 0,
        houseAgeFrom: 0,
        houseAgeTo: 0,
        requirementLevel: "",
        genJinRequirement: ""
      };
      that.xuqiuList.push(JSON.parse(JSON.stringify(addXuqiuList)));
      that.ruleForm = that.xuqiuList;
      that.ruleFormDetail = that.xuqiuList;
      that.xuqiuValue = [];
      let activeIndex = window.localStorage.getItem("activeIndex");
      that.$nextTick(() => {
        if (activeIndex == "KeYuanList") {
          let checkboxList = [];
          checkboxList = document.querySelectorAll(
            ".el-cascader-menu:nth-child(1) .el-checkbox"
          );
          for (let index = 0; index < checkboxList.length; index++) {
            checkboxList[index].style.display = "none";
          }
        }
      });
      that.xuqiuDialogVisible = false;
    },
    onUpdate(ruleFormDetail, i) {
      //修改需求
      let that = this,
        url = "",
        method = "";
      that.shangquanParams = [];
      that.index = i; //需求下标
      console.log(this.ruleFormDetail);
      this.$refs[ruleFormDetail][0].validate(async valid => {
        if (valid) {
          that.loading = true;
          that.ruleFormDetail[i].shangquan.forEach(item => {
            that.shangquanParams.push(item[1]);
          });
          const data = {
            customerId: that.customerId,
            squareFrom: that.ruleFormDetail[i].squareFrom,
            SquareTo: that.ruleFormDetail[i].SquareTo,
            roomCountFrom: that.ruleFormDetail[i].roomCountFrom,
            roomCountTo: that.ruleFormDetail[i].roomCountTo,
            city: that.ruleFormDetail[i].city,
            areaIds: that.shangquanParams.toString(),
            fangWuYongTuId: that.ruleFormDetail[i].fangWuYongTuId,
            requirementLevel: that.ruleFormDetail[i].requirementLevel,
            FuKuanFangShi: that.ruleFormDetail[i].FuKuanFangShi,
            requirementName: that.ruleFormDetail[i].fangWuYongTuId
          };
          if (that.ruleFormDetail[i].fangWuYongTuId == "买二手") {
            //求购需求
            data.gouFangMuDi = that.ruleFormDetail[i].gouFangMuDi;
            data.priceFrom = that.ruleFormDetail[i].priceFrom * 10000;
            data.priceTo = that.ruleFormDetail[i].priceTo * 10000;
            data.ShouFuFrom = that.ruleFormDetail[i].ShouFuFrom * 10000;
            data.ShouFuTo = that.ruleFormDetail[i].ShouFuTo * 10000;
            if (typeof that.ruleFormDetail[i].orientations == "object") {
              data.orientations = that.ruleFormDetail[i].orientations.join("");
            } else {
              data.orientations = that.ruleFormDetail[
                i
              ].orientations.toString();
            }

            data.louCengFrom = that.ruleFormDetail[i].louCengFrom;
            data.louCengTo = that.ruleFormDetail[i].louCengTo;
            data.houseAgeFrom = that.ruleFormDetail[i].houseAgeFrom;
            data.houseAgeTo = that.ruleFormDetail[i].houseAgeTo;
            data.YueGongFrom = that.ruleFormDetail[i].YueGongFrom;
            data.YueGongTo = that.ruleFormDetail[i].YueGongTo;
            data.zhuangXiuZhuangKuangId =
              that.ruleFormDetail[i].zhuangXiuZhuangKuangId;
            data.isCustomerToBuy = "1";
            if (that.ruleFormDetail[i].requirementToBuyId) {
              data.requirementToBuyId =
                that.ruleFormDetail[i].requirementToBuyId;
              url = that.$url.UpdateRequirementToBuy;
              method = "put";
            } else {
              url = that.$url.InsertRequirementToBuy;
              method = "post";
            }
          } else {
            //求租需求
            data.isCustomerToBuy = "0";
            data.priceFrom = that.ruleFormDetail[i].priceFrom;
            data.priceTo = that.ruleFormDetail[i].priceTo;
            data.isZhengZu = that.ruleFormDetail[i].isZhengZu;
            if (that.ruleFormDetail[i].requirementToRentId) {
              //求租需求修改
              data.requirementToRentId =
                that.ruleFormDetail[i].requirementToRentId;
              url = that.$url.UpdateRequirementToRent;
              method = "put";
            } else {
              url = that.$url.InsertRequirementToRent;
              method = "post";
            }
          }

          that.$http[method](url, data, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          }).then(res => {
            that.loading = false;
            if (res.data.msgCode == 1) {
              let message = "";
              if (
                that.ruleFormDetail[i].requirementToBuyId ||
                that.ruleFormDetail[i].requirementToRentId
              ) {
                message = "需求修改成功！";
              } else {
                message = "需求新增成功！";
              }
              that.$message.success(message);
              this.$router.push({
                path: "KeYuanList",
                query: {
                  state: this.state
                }
              });
              that.ruleFormDetail[that.index].xuqiuType = "";
              that.$emit("GetCustomerDetailRequest");
            } else {
              that.$message.error(res.data.message);
            }
          });
        }
      });
    },

    clickTuijianKeyuan(dtCustomer) {
      let that = this;
      if (that.dtCustomer.yiTuijian == true) {
        this.$message({
          type: "error",
          message: "该客源已有合作经纪人！"
        });
      } else if (that.dtCustomer.yiTuijian == false) {
        that.isTuijianKeyuan = true;
      }
    },
    cancelTuijianKeyuan() {
      this.isTuijianKeyuan = false;
      this.hezuoren = "请点击此处选择经纪人";
    },
    saveTuijianKeyuan() {
      let that = this;
      if (that.hezuoren != "请点击此处选择经纪人") {
        that.loading = true;
        const data = {
          customerId: that.customerId,
          maintenanceUserId: that.RecommendCustomerId
        };
        that.$http
          .post(that.$url.RecommendToUser, data, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          })
          .then(res => {
            that.loading = false;
            if (res.data.msgCode == 1) {
              that.$message.success("推荐成功！");

              that.dtCustomer.yiTuijian = true;
            } else {
              that.$message.error(res.data.message);
            }
            that.isTuijianKeyuan = false;
            that.hezuoren = "请点击此处选择经纪人";
          });
      } else {
        that.$message({
          type: "error",
          message: "请选择经纪人!"
        });
      }
    },
    tuijianKeyuanDialogClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script>

<style lang="less" scoped>
.padding-r0 /deep/ input {
  padding-right: 0px !important;
}
.el-card {
  margin-top: 15px;
}
.el-textarea__inner {
  border: 1px solid #cccccc;
  color: black;
  font-weight: bold;
}
.xinzengXuQiu {
  color: #df0202 !important;
  padding-right: 20px;
  border-radius: 3px;
  float: right;
  font-weight: 600;
  margin-top: 3px;
  font-size: 14px;
}
.addXuqiu {
  margin-top: -28px;
  z-index: 1111;
  position: absolute;
  right: 0px;
}
.xuqiuForm-item {
  display: flex;
  /deep/ .el-form-item {
    width: 29%;
    margin-bottom: 0px !important;
  }
  /deep/ .el-form-item__content {
    margin-left: 0px;
  }
}
.div-tabs {
  background-color: #fff;
  margin-top: 15px;
}
.tabs-title {
  padding: 10px 20px;
  font-weight: 600;
}

.xuqiuDialog /deep/ .el-dialog__body {
  padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
  right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
  padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
  margin: 2px 0 2px 3px !important;
}
/deep/.xuqiuDialog .el-tabs {
  margin-top: -5px !important;
  .el-tabs__header {
    border-bottom: none !important;
    margin: 0px;
    .el-tabs__nav {
      color: #000 !important;
      border: none !important;
    }
    .el-tabs__nav > .is-active {
      color: rgb(238, 103, 22) !important;
      background: transparent !important;
    }
    .el-tabs__item {
      border-left: none !important;
    }
  }
  .el-tabs__content {
    margin-left: 14px !important;
  }
}
</style>
