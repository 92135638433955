<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <span style="float:right;">
                <el-button size="mini" class="el-button1" type="primary" @click="goBack">返回</el-button>
            </span>
        </el-card>
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="23%">
            <!-- <el-card>
                <h4 style="margin-top:0px;margin-bottom:0px;">看房信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="房屋状况" label-width="23%" prop="fangwuzhaungkuang">
                            <el-input placeholder="请输入房屋状况" style="width:60%;" v-model="form.fangwuzhaungkuang"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="常规看房时间:" label-width="23%" prop="changguishijian">
                            <el-date-picker v-model="form.changguishijian" value-format="yyyy-MM-dd" style="width:60%;" type="date" placeholder="请输入常规看房时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card> -->
            <el-card v-if="type == 1">
                <h4 style="margin-top:0px;margin-bottom:0px;">房屋信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="16">
                        <el-form-item label="房屋设施:" label-width="17%" prop="sheshi">
                            <el-checkbox-group v-model="checkedsheshi">
                                <el-checkbox :label="item.name" v-for="(item,index) in sheshiList" :key="index">{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="12">
                        <el-form-item label="家具" label-width="23%" prop="jiaju">
                            <el-input placeholder="请输入现有家具" style="width:60%;" v-model="form.jiaju"></el-input><span style="margin-left:7px;color:red;">如需输入多个内容，以'/'分隔</span>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="12">
                        <el-form-item label="房屋设施" label-width="23%" prop="sheshi">
                            <el-input placeholder="请输入房屋设施" style="width:60%;" v-model="form.sheshi"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="12">
                        <el-form-item label="装修状况:" label-width="23%" prop="zhuangxiuzhuangkuang">
                            <el-select v-model="form.zhuangxiuzhuangkuang" style="width: 60%" placeholder="请选择装修状况">
                                <el-option v-for="item in zhuangxiuOptions" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="是否首次出租:" label-width="23%" prop="shoucichuzu">
                            <el-select v-model="form.shoucichuzu" style="width: 60%" placeholder="请选择是否首次出租">
                                <el-option v-for="item in shoucichuzuList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="格局是否有变动:" label-width="23%" prop="biandong">
                            <el-select v-model="form.biandong" style="width: 60%" placeholder="请选择房屋格局是否有变动">
                                <el-option v-for="item in biandongList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车位:" label-width="23%" prop="houseChewei">
                            <el-select v-model="form.houseChewei" style="width: 60%" placeholder="请选择房屋格局是否有变动">
                                <el-option v-for="item in houseCheweiList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-card>
            <el-card v-if="type == 1">
                <h4 style="margin-top:0px;margin-bottom:0px;">交易信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="业主是否承担佣金:" label-width="23%" prop="yezhuyajin">
                            <el-select v-model="form.yezhuyajin" style="width: 60%" placeholder="请选择业主是否承担佣金">
                                <el-option v-for="item in yezhuyajinList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="租期要求:" label-width="23%" prop="zuqiyaoqiu">
                            <el-input placeholder="请输入租期要求" style="width:60%;" v-model="form.zuqiyaoqiu"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="付款方式:" label-width="23%" prop="fukuanfangshi">
                            <el-select v-model="form.fukuanfangshi" style="width: 60%" placeholder="请选择付款方式">
                                <el-option v-for="item in fukuanfangshiList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="业主底价:" label-width="23%" prop="yezhudijia">
                            <el-input placeholder="请输入业主底价" style="width:60%;" class="padding-r0" type="number" min="0" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999999)value=999999999" @blur="yezhudijiaBlur" v-model="form.yezhudijia"></el-input><span style="margin-left:7px;">元</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card>
                <h4 style="margin-top:0px;margin-bottom:0px;">价格信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="是否唯一:" label-width="23%" prop="shifouweiyi">
                            <el-select v-model="form.shifouweiyi" style="width: 60%" placeholder="请选择是否唯一">
                                <el-option v-for="item in shifouweiyiList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="付佣方式:" label-width="23%" prop="fukuanyaoqiu">
                            <el-select v-model="form.fukuanyaoqiu" style="width: 60%" placeholder="请选择付佣方式">
                                <el-option v-for="item in fukuanyaoqiuList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="是否限购:" label-width="23%" prop="shifouxiangou">
                            <el-select v-model="form.shifouxiangou" style="width: 60%" placeholder="请选择栋座">
                                <el-option v-for="item in shifouxiangouList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="原购价格:" label-width="23%" prop="yuangoujiage">
                            <el-input placeholder="请输入原购价格" style="width:60%;" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999999)value=999999999" v-model="form.yuangoujiage"></el-input><span style="margin-left:7px;">元</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="12">
                        <el-form-item label="过户指导价" label-width="23%" prop="zhidaojia">
                            <el-input placeholder="请输入指导价" style="width:60%;" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999999)value=999999999" v-model="form.zhidaojia"></el-input><span style="margin-left:7px;">元</span>
                        </el-form-item>
                    </el-col> -->
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="是否提供原始税票:" label-width="23%" prop="yuanshishuipiao">
                            <el-select v-model="form.yuanshishuipiao" style="width: 60%" placeholder="请选择是否提供原始税票">
                                <el-option v-for="item in yuanshishuipiaoList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card>
                <h4 style="margin-top:0px;margin-bottom:0px;">业主信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="销售原因:" label-width="23%" prop="xiaoshouyuanyin">
                            <el-input placeholder="请输入销售原因" style="width:60%;" v-model="form.xiaoshouyuanyin"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col v-if="type == 1" :span="12">
                        <el-form-item label="房屋现状:" label-width="23%" prop="fangzhuangxiankuang">
                            <el-select v-model="form.fangzhuangxiankuang" style="width: 60%" placeholder="请选择房屋现状">
                                <el-option v-for="item in fangzhuangxiankuangList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="户口情况:" label-width="23%" prop="hukou">
                            <el-input placeholder="请输入户口情况" style="width:60%;" v-model="form.hukou"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 1">
                    <el-col :span="12">
                        <el-form-item label="可入住时间:" label-width="23%" prop="ruzhushijian">
                            <el-date-picker v-model="form.ruzhushijian" value-format="yyyy-MM-dd" style="width:60%;" type="date" placeholder="请输入可入住时间">
                            </el-date-picker>

                            <!-- <el-input placeholder="请输入可入住时间" style="width:60%;" v-model="form.ruzhushijian"></el-input> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="租客要求:" label-width="23%" prop="zukeyaoqiu">
                            <el-input placeholder="请输入租客要求" style="width:60%;" v-model="form.zukeyaoqiu"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 1">
                    <el-col :span="12">
                        <el-form-item label="出租原因:" label-width="23%" prop="chuzuyuanyin">
                            <el-input placeholder="出租原因" style="width:60%;" v-model="form.chuzuyuanyin"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="特殊要求:" label-width="23%" prop="teshuyaoqiu">
                            <el-input placeholder="请输入特殊要求" style="width:60%;" v-model="form.teshuyaoqiu"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 1">
                    <el-col :span="12">
                        <el-form-item label="联系人是否产权人:" label-width="23%" prop="chanquanren">
                            <el-select v-model="form.chanquanren" style="width: 60%" placeholder="请选择联系人是否是产权人">
                                <el-option v-for="item in chanquanrenList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="租赁方式:" label-width="23%" prop="zulin">
                            <el-select v-model="form.zulin" style="width: 60%" placeholder="请选择租赁方式">
                                <el-option v-for="item in zulinList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label="抵押情况:" label-width="23%" prop="diya">
                            <el-input placeholder="请输入抵押情况" style="width:60%;" v-model="form.diya"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否随时可签:" label-width="23%" prop="keqian">
                            <el-select v-model="form.keqian" style="width: 60%" placeholder="请选择是否随时可签">
                                <el-option v-for="item in keqianList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label="产权是否共有:" label-width="23%" prop="chanquan">
                            <el-select v-model="form.chanquan" style="width: 60%" placeholder="请选择产权是否共有">
                                <el-option v-for="item in chanquanList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人是否为业主:" label-width="23%" prop="lianxiren">
                            <el-select v-model="form.lianxiren" style="width: 60%" placeholder="请选择联系人是否为业主">
                                <el-option v-for="item in lianxirenList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label="期望出售周期:" label-width="23%" prop="zhouqi">
                            <el-input placeholder="请输入期望出售周期" style="width:60%;" v-model="form.zhouqi"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="婚姻状况:" label-width="23%" prop="hunyin">
                            <el-select v-model="form.hunyin" style="width: 60%" placeholder="请选择婚姻状况">
                                <el-option v-for="item in hunyinList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label="是否合同房:" label-width="23%" prop="hetong">
                            <el-select v-model="form.hetong" style="width: 60%" placeholder="请选择是否合同房">
                                <el-option v-for="item in hetongList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="是否限售:" label-width="23%" prop="xianshou">
                            <el-select v-model="form.xianshou" style="width: 60%" placeholder="请选择是否限售">
                                <el-option v-for="item in xianshouList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label="房源等级:" label-width="23%" prop="fangyuandengji">
                            <el-select v-model="form.fangyuandengji" style="width: 60%" placeholder="请选择房源等级">
                                <el-option v-for="item in dengjiList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card v-if="type == 1">
                <h4 style="margin-top:0px;margin-bottom:0px;">其他信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="房源等级:" label-width="23%" prop="fangyuandengji">
                            <el-select v-model="form.fangyuandengji" style="width: 60%" placeholder="请选择房源等级">
                                <el-option v-for="item in dengjiList" :key="item.value" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="挂牌时间:" label-width="23%" prop="guapaishijian">
                            <el-date-picker v-model="form.guapaishijian" value-format="yyyy-MM-dd" style="width:60%;" type="date" placeholder="请选择挂牌时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="信息来源:" label-width="23%">
                            <div class="xuqiuForm-item">
                                <el-form-item prop="laiyuan1">
                                    <el-select v-model="form.laiyuan1" @change="changeLaiyuan" placeholder="请选择信息来源" style="width: 100%">
                                        <el-option v-for="item in xinxilaiyuanOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <span style="margin:0 4px">-</span>
                                <el-form-item prop="laiyuan2">
                                    <el-select v-model="form.laiyuan2" placeholder="请选择信息来源" style="width: 100%">
                                        <span v-if="form.laiyuan1">
                                            <el-option v-for="item in xinxilaiyuanOptions[form.laiyuan1].laiyuan1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </span>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="备注:" label-width="23%">
                            <el-input placeholder="请输入备注" style="width:60%;" v-model="form.beizhu"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 1">
                    <el-col :span="12">
                        <el-form-item label-width="23%">
                            <el-button size="mini" type="primary" @click="onSubmit('form')">确定</el-button>
                            <el-button size="mini" @click="cancel">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card v-if="type == 2">
                <h4 style="margin-top:0px;margin-bottom:0px;">特色信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="12">
                        <el-form-item label="产权面积:" label-width="23%" prop="chanquanmianji">
                            <el-input placeholder="请输入产权面积" style="width:60%;" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="form.chanquanmianji"></el-input><span style="margin-left:7px;">㎡</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否满N:" label-width="23%" prop="manN">
                            <!-- <el-input placeholder="请输入是否满N" style="width:60%;" v-model="form.manN"></el-input> -->
                            <el-select v-model="form.manN" style="width: 60%" placeholder="请选择是否满N">
                                <el-option v-for="item in manNList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有无车位:" label-width="23%" prop="chewei">
                            <el-select v-model="form.chewei" style="width: 60%" placeholder="请选择有无车位">
                                <el-option v-for="item in cheweiList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="学区名额:" label-width="23%" prop="xuequ">
                            <el-select v-model="form.xuequ" style="width: 60%" placeholder="请选择学区名额">
                                <el-option v-for="item in xuequList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="12">
                        <el-form-item label="装修状况" label-width="23%" prop="zhuangxiu">
                            <el-select v-model="form.zhuangxiu" style="width: 60%" placeholder="请选择装修状况">
                                <el-option v-for="item in zhuangxiuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="交房时间:" label-width="23%" prop="jiaofang">
                            <el-date-picker v-model="form.jiaofang" value-format="yyyy-MM-dd" style="width:60%;" type="date" placeholder="请选择交房时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="房间格局:" label-width="23%" prop="geju">
                            <el-input placeholder="请输入房间格局" style="width:60%;" v-model="form.geju"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="装修日期:" label-width="23%" prop="zhaungxiuriqi">
                            <el-date-picker v-model="form.zhaungxiuriqi" value-format="yyyy-MM-dd" style="width:60%;" type="date" placeholder="请选择装修日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="赠送面积:" label-width="23%" prop="zengsong">
                            <el-input placeholder="请输入赠送面积" style="width:60%;" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="form.zengsong"></el-input><span style="margin-left:7px;">㎡</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="装修成本:" label-width="23%" prop="zhaungxiuchengben">
                            <el-input placeholder="请输入装修成本" style="width:60%;" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999999 )value=999999999" v-model="form.zhaungxiuchengben"></el-input><span style="margin-left:7px;">元</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="改造状况:" label-width="23%" prop="gaizao">
                            <el-input placeholder="请输入改造状况" style="width:60%;" v-model="form.gaizao"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="12">
                        <el-form-item label="交易权属" label-width="23%" prop="quanshu">
                            <el-select :disabled="flag" v-model="form.quanshu" placeholder="请选择交易权属" style="width: 60%; margin: 0 7px 0 0">
                                <el-option v-for="item in quanshuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="12">
                        <el-form-item label="房屋用途" label-width="23%" prop="yongtu">
                            <el-select :disabled="flag" v-model="form.yongtu" placeholder="请输入房屋用途" style="width: 60%; margin: 0 7px 0 0">
                                <el-option v-for="item in yongtuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row>
                    <el-col v-if="type == 2" :span="12">
                        <el-form-item label="赠送详情:" label-width="23%" prop="zengsongxiangqing">
                            <el-input placeholder="请输入赠送详情" style="width:60%;" v-model="form.zengsongxiangqing"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="type == 2">
                    <el-col :span="12">
                        <el-form-item label-width="23%">
                            <el-button size="mini" type="primary" @click="onSubmit('form')">确定</el-button>
                            <el-button size="mini" @click="cancel">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            // maintenanceUserId: "",
            houseId: this.$route.query.houseId,
            type: this.$route.query.type,
            name: "",
            flag: false,
            form: {
                // fangwuzhaungkuang: "",
                shifouweiyi: "",
                // changguishijian: "",
                shifouxiangou: "",
                yuangoujiage: "",
                fukuanyaoqiu: "",
                zhidaojia: "",
                yuanshishuipiao: "",
                xiaoshouyuanyin: "",
                hukou: "",
                diya: "",
                keqian: "",
                chanquan: "",
                lianxiren: "",
                zhouqi: "",
                hunyin: "",
                hetong: "",
                xianshou: "",
                chanquanmianji: "",
                manN: "",
                chewei: "",
                xuequ: "",
                zhuangxiu: "",
                jiaofang: "",
                geju: "",
                zhaungxiuriqi: "",
                zengsong: "",
                zhaungxiuchengben: "",
                quanshu: "",
                // yongtu: "",
                gaizao: "",
                zengsongxiangqing: "",
                maintenanceInfo: [],
                jiaju: "",
                sheshi: "",
                jiadian: "",
                // zhuangxiuzhuangkuang: "",
                biandong: "",
                houseChewei: "",
                shoucichuzu: "",
                yezhuyajin: "",
                zuqiyaoqiu: "",
                fukuanfangshi: "",
                yezhudijia: 0,
                // fangzhuangxiankuang: "",
                ruzhushijian: "",
                zukeyaoqiu: "",
                chuzuyuanyin: "",
                teshuyaoqiu: "",
                chanquanren: "",
                // zulin: "",
                fangyuandengji: "",
                // laiyuan1: "",
                // laiyuan2: "",
                guapaishijian: "",
                beizhu: ""
            },
            rules: {
                // fangwuzhaungkuang: [
                //     {
                //         required: true,
                //         message: "请输入房屋状况",
                //         trigger: "blur"
                //     }
                // ],
                // zulin: [
                //     {
                //         required: true,
                //         message: "请选择租赁方式",
                //         trigger: "change"
                //     }
                // ],
                chanquanren: [
                    {
                        required: true,
                        message: "请选择联系人是否为产权人",
                        trigger: "change"
                    }
                ],
                guapaishijian: [
                    {
                        required: true,
                        message: "请选择挂牌时间",
                        trigger: "change"
                    }
                ],
                fangyuandengji: [
                    {
                        required: true,
                        message: "请选择房源等级",
                        trigger: "change"
                    }
                ],
                teshuyaoqiu: [
                    {
                        required: true,
                        message: "请输入特殊要求",
                        trigger: "blur"
                    }
                ],
                chuzuyuanyin: [
                    {
                        required: true,
                        message: "请输入出租原因",
                        trigger: "blur"
                    }
                ],
                zukeyaoqiu: [
                    {
                        required: true,
                        message: "请输入租客要求",
                        trigger: "blur"
                    }
                ],
                ruzhushijian: [
                    {
                        required: true,
                        message: "请输入入住时间",
                        trigger: "blur"
                    }
                ],
                yezhudijia: [
                    {
                        required: true,
                        message: "请输入业主底价",
                        trigger: "blur"
                    }
                ],
                // fangzhuangxiankuang: [
                //     {
                //         required: true,
                //         message: "请选择房状现况",
                //         trigger: "change"
                //     }
                // ],
                fukuanfangshi: [
                    {
                        required: true,
                        message: "请选择付款方式",
                        trigger: "change"
                    }
                ],
                zuqiyaoqiu: [
                    {
                        required: true,
                        message: "请输入租期要求",
                        trigger: "blur"
                    }
                ],
                yezhuyajin: [
                    {
                        required: true,
                        message: "请选择业主是否承担佣金",
                        trigger: "change"
                    }
                ],
                shoucichuzu: [
                    {
                        required: true,
                        message: "请选择是否首次出租",
                        trigger: "change"
                    }
                ],
                houseChewei: [
                    {
                        required: true,
                        message: "请选择是否有车位",
                        trigger: "change"
                    }
                ],
                biandong: [
                    {
                        required: true,
                        message: "请选择房屋格局是否有变动",
                        trigger: "change"
                    }
                ],
                // zhuangxiuzhuangkuang: [
                //     {
                //         required: true,
                //         message: "请选择装修状况",
                //         trigger: "change"
                //     }
                // ],
                jiadian: [
                    {
                        required: true,
                        message: "请输入家电",
                        trigger: "blur"
                    }
                ],
                sheshi: [
                    {
                        required: true,
                        message: "请输入设施",
                        trigger: "blur"
                    }
                ],
                jiaju: [
                    {
                        required: true,
                        message: "请输入家具",
                        trigger: "blur"
                    }
                ],
                shifouweiyi: [
                    {
                        required: true,
                        message: "请选择唯一性",
                        trigger: "blur"
                    }
                ],
                // changguishijian: [
                //     {
                //         required: true,
                //         message: "请输入常规看房时间",
                //         trigger: "blur"
                //     }
                // ],
                shifouxiangou: [
                    {
                        required: true,
                        message: "请选择是否限购",
                        trigger: "blur"
                    }
                ],
                yuangoujiage: [
                    {
                        required: true,
                        message: "请输入原购价格",
                        trigger: "blur"
                    }
                ],
                fukuanyaoqiu: [
                    {
                        required: true,
                        message: "请选择付款要求",
                        trigger: "blur"
                    }
                ],
                zhidaojia: [
                    {
                        required: true,
                        message: "请输入指导价",
                        trigger: "blur"
                    }
                ],
                yuanshishuipiao: [
                    {
                        required: true,
                        message: "请选择是否提供原始税票",
                        trigger: "blur"
                    }
                ],
                xiaoshouyuanyin: [
                    {
                        required: true,
                        message: "请输入销售原因",
                        trigger: "blur"
                    }
                ],
                hukou: [
                    {
                        required: true,
                        message: "请输入户口情况",
                        trigger: "blur"
                    }
                ],
                diya: [
                    {
                        required: true,
                        message: "请输入抵押情况",
                        trigger: "blur"
                    }
                ],
                keqian: [
                    {
                        required: true,
                        message: "请选择是否随时可签",
                        trigger: "blur"
                    }
                ],
                chanquan: [
                    {
                        required: true,
                        message: "请选择产权是否共有",
                        trigger: "blur"
                    }
                ],
                lianxiren: [
                    {
                        required: true,
                        message: "请选择联系人是否为业主",
                        trigger: "blur"
                    }
                ],
                zhouqi: [
                    {
                        required: true,
                        message: "请输入期望出售周期",
                        trigger: "blur"
                    }
                ],
                hunyin: [
                    {
                        required: true,
                        message: "请选择婚姻状况",
                        trigger: "blur"
                    }
                ],
                hetong: [
                    {
                        required: true,
                        message: "请选择是否合同房",
                        trigger: "blur"
                    }
                ],
                xianshou: [
                    {
                        required: true,
                        message: "请选择是否限售",
                        trigger: "blur"
                    }
                ],
                chanquanmianji: [
                    {
                        required: true,
                        message: "请输入产权面积",
                        trigger: "blur"
                    }
                ],
                manN: [
                    {
                        required: true,
                        message: "请选择是否满N",
                        trigger: "blur"
                    }
                ],
                chewei: [
                    {
                        required: true,
                        message: "请选择有无车位",
                        trigger: "blur"
                    }
                ],
                xuequ: [
                    {
                        required: true,
                        message: "请选择学区名额",
                        trigger: "change"
                    }
                ],
                // zhuangxiu: [
                //     {
                //         required: true,
                //         message: "请输入装修状况",
                //         trigger: "change"
                //     }
                // ],
                jiaofang: [
                    {
                        required: true,
                        message: "请选择交房日期",
                        trigger: "blur"
                    }
                ],
                geju: [
                    {
                        required: true,
                        message: "请输入房间格局",
                        trigger: "blur"
                    }
                ],
                zhaungxiuriqi: [
                    {
                        required: true,
                        message: "请输入装修日期",
                        trigger: "blur"
                    }
                ],
                zengsong: [
                    {
                        required: true,
                        message: "请输入赠送面积",
                        trigger: "blur"
                    }
                ],
                zhaungxiuchengben: [
                    {
                        required: true,
                        message: "请输入装修成本",
                        trigger: "blur"
                    }
                ],
                quanshu: [
                    {
                        required: true,
                        message: "请输入交易权属",
                        trigger: "change"
                    }
                ],
                // yongtu: [
                //     {
                //         required: true,
                //         message: "请输入房屋用途",
                //         trigger: "change"
                //     }
                // ],
                gaizao: [
                    {
                        required: true,
                        message: "请输入改造状况",
                        trigger: "blur"
                    }
                ],
                zengsongxiangqing: [
                    {
                        required: true,
                        message: "请输入赠送详情",
                        trigger: "blur"
                    }
                ]
            },
            // xinxilaiyuanOptions: [],
            shifouweiyiList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            fukuanfangshiList: [
                {
                    value: "0",
                    label: "押一付一"
                },
                {
                    value: "1",
                    label: "押一付二"
                },
                {
                    value: "2",
                    label: "押一付三"
                }
            ],
            yezhuyajinList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            biandongList: [
                {
                    value: "0",
                    label: "有变动"
                },
                {
                    value: "1",
                    label: "无变动"
                }
            ],
            shoucichuzuList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            houseCheweiList: [
                {
                    value: "0",
                    label: "有车位"
                },
                {
                    value: "1",
                    label: "无车位"
                }
            ],
            dengjiList: [
                {
                    value: "0",
                    label: "A"
                },
                {
                    value: "1",
                    label: "B"
                },
                {
                    value: "2",
                    label: "C"
                }
            ],
            shifouxiangouList: [
                {
                    value: "0",
                    label: "限购"
                },
                {
                    value: "1",
                    label: "不限购"
                }
            ],
            fukuanyaoqiuList: [],
            // zulinList: [
            //     {
            //         value: "0",
            //         label: "整租"
            //     },
            //     {
            //         value: "1",
            //         label: "合租"
            //     }
            // ],
            chanquanrenList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            keqianList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            chanquanList: [
                {
                    value: "0",
                    label: "共有产权"
                },
                {
                    value: "1",
                    label: "非共有产权"
                }
            ],
            // fangzhuangxiankuangList: [
            //     {
            //         value: "4-1",
            //         label: "自住"
            //     },
            //     {
            //         value: "4-2",
            //         label: "空置"
            //     },
            //     {
            //         value: "4-3",
            //         label: "出租中"
            //     },
            //     {
            //         value: "4-4",
            //         label: "经营中"
            //     }
            // ],
            lianxirenList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            chanquanren: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            hunyinList: [
                {
                    value: "0",
                    label: "已婚"
                },
                {
                    value: "1",
                    label: "未婚"
                }
            ],
            hetong: [
                {
                    value: "0",
                    label: "合同房"
                },
                {
                    value: "1",
                    label: "非合同房"
                }
            ],
            xianshouList: [
                {
                    value: "0",
                    label: "限售"
                },
                {
                    value: "1",
                    label: "非限售"
                }
            ],
            manNList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            cheweiList: [
                {
                    value: "0",
                    label: "有车位"
                },
                {
                    value: "1",
                    label: "无车位"
                }
            ],
            xuequList: [
                {
                    value: "0",
                    label: "有学区名额"
                },
                {
                    value: "1",
                    label: "无学区名额"
                }
            ],
            quanshuOptions: [], //交易权属
            yongtuOptions: [], //房屋用途
            // zhuangxiuOptions: [], //装修状况
            yuanshishuipiaoList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            hetongList: [
                {
                    value: "0",
                    label: "是"
                },
                {
                    value: "1",
                    label: "否"
                }
            ],
            checkedsheshi: [],
            sheshiList: [
                { name: "电视" },
                { name: "冰箱" },
                { name: "洗衣机" },
                { name: "热水器" },
                { name: "床" },
                { name: "暖气" },
                { name: "宽带" },
                { name: "衣柜" },
                { name: "天然气" }
            ]
        }
    },
    created() {
        // alert(this.houseId)
        this.name = "维护信息"
        // this.maintenanceUserId = localStorage.getItem("userId")
        this.getShuJuZiDianList()
    },
    methods: {
        yezhudijiaBlur() {
            this.form.yezhudijia = Number(this.form.yezhudijia)
        },
        GetHouseDetail() {
            console.log("type", this.type)
            var that = this
            this.$http
                .get(this.$url.GetHouseDetail, {
                    params: {
                        houseId: this.houseId
                    }
                })
                .then(res => {
                    if (res.data.msgCode == 1) {
                        let data = res.data.dtList[0]
                        let data1 = JSON.parse(data.maintenanceInfo)
                        that.form = Object.assign(that.form, data1)
                        // if (
                        //     that.form.laiyuan2 != "" &&
                        //     that.xinxilaiyuanOptions.length > 0
                        // ) {
                        //     that.xinxilaiyuanOptions.forEach((item, i) => {
                        //         item.laiyuan1.forEach((item1, v) => {
                        //             if (item1.value == that.form.laiyuan2) {
                        //                 that.form.laiyuan1 = item.value
                        //                 that.form.laiyuan2 = item1.value
                        //             }
                        //         })
                        //     })
                        // }
                        if (that.form.sheshi) {
                            that.checkedsheshi = that.form.sheshi.split(",")
                        }
                        console.log("that.form", that.form)
                    }
                })
        },
        // changeLaiyuan() {
        //     this.form.laiyuan2 = ""
        // },
        getShuJuZiDianList() {
            let that = this
            that.$commonJs
                .getShuJuZiDianList("JIAOYIQUANSHU,FANGWUYONGTU,FUYONGFANGSHI")
                .then(data => {
                    this.quanshuOptions = data.JiaoYiQuanShu
                    this.yongtuOptions = data.FANGWUYONGTU
                    // this.zhuangxiuOptions = data.ZHUANGXIUZHUANGKUANG
                    this.fukuanyaoqiuList = data.FUYONGFANGSHI
                    // this.xinxilaiyuanOptions = data.XinXiLaiYuan
                    this.GetHouseDetail()
                })
        },
        goBack() {
            this.$router.go(-1)
        },
        async onSubmit(formName) {
            this.form.sheshi = this.checkedsheshi.toString()

            this.$refs[formName].validate(async valid => {
                if (valid) {
                    if (this.type == 1) {
                        const weiHuInFo = {
                            jiaju: this.form.jiaju,
                            sheshi: this.form.sheshi,
                            jiadian: this.form.jiadian,
                            // zhuangxiuzhuangkuang: this.form
                            //     .zhuangxiuzhuangkuang,
                            biandong: this.form.biandong,
                            houseChewei: this.form.houseChewei,
                            shoucichuzu: this.form.shoucichuzu,
                            yezhuyajin: this.form.yezhuyajin,
                            zuqiyaoqiu: this.form.zuqiyaoqiu,
                            fukuanfangshi: this.form.fukuanfangshi,
                            yezhudijia: this.form.yezhudijia,
                            // fangzhuangxiankuang: this.form.fangzhuangxiankuang,
                            ruzhushijian: this.form.ruzhushijian,
                            zukeyaoqiu: this.form.zukeyaoqiu,
                            chuzuyuanyin: this.form.chuzuyuanyin,
                            teshuyaoqiu: this.form.teshuyaoqiu,
                            chanquanren: this.form.chanquanren,
                            // zulin: this.form.zulin,
                            fangyuandengji: this.form.fangyuandengji,
                            laiyuan2: this.form.laiyuan2,
                            guapaishijian: this.form.guapaishijian,
                            beizhu: this.form.beizhu,
                            // changguishijian: this.form.changguishijian,
                            shifouweiyi: this.form.shifouweiyi,
                            fukuanyaoqiu: this.form.fukuanyaoqiu,
                            zhidaojia: this.form.zhidaojia,
                            hukou: this.form.hukou,
                            diya: this.form.diya,
                            keqian: this.form.keqian,
                            chanquan: this.form.chanquan,
                            lianxiren: this.form.lianxiren,
                            zhouqi: this.form.zhouqi,
                            hunyin: this.form.hunyin,
                            hetong: this.form.hetong,
                            chanquanmianji: this.form.chanquanmianji,
                            manN: this.form.manN,
                            chewei: this.form.chewei,
                            xuequ: this.form.xuequ,
                            jiaofang: this.form.jiaofang,
                            geju: this.form.geju,
                            zhaungxiuriqi: this.form.zhaungxiuriqi,
                            zhaungxiuchengben: this.form.zhaungxiuchengben,
                            gaizao: this.form.gaizao
                        }
                        let maintenanceInfo = JSON.stringify(weiHuInFo)
                        const data = {
                            houseId: this.houseId,
                            // maintenanceUserId: this.maintenanceUserId,
                            maintenanceInfo: maintenanceInfo
                        }
                        this.$http
                            .put(this.$url.UpdateHouseMaintenance, data)
                            .then(res => {
                                if (res.data.msgCode == 1) {
                                    this.$message.success("修改维护信息成功")
                                    this.$router.go(-1)
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            })
                        console.log(data)
                    } else if (this.type == 2) {
                        const weiHuInFo = {
                            fangyuandengji: this.form.fangyuandengji,
                            // changguishijian: this.form.changguishijian,
                            shifouweiyi: this.form.shifouweiyi,
                            fukuanyaoqiu: this.form.fukuanyaoqiu,
                            shifouxiangou: this.form.shifouxiangou,
                            yuangoujiage: this.form.yuangoujiage,
                            zhidaojia: this.form.zhidaojia,
                            yuanshishuipiao: this.form.yuanshishuipiao,
                            xiaoshouyuanyin: this.form.xiaoshouyuanyin,
                            hukou: this.form.hukou,
                            diya: this.form.diya,
                            keqian: this.form.keqian,
                            chanquan: this.form.chanquan,
                            lianxiren: this.form.lianxiren,
                            zhouqi: this.form.zhouqi,
                            hunyin: this.form.hunyin,
                            hetong: this.form.hetong,
                            xianshou: this.form.xianshou,
                            chanquanmianji: this.form.chanquanmianji,
                            manN: this.form.manN,
                            chewei: this.form.chewei,
                            xuequ: this.form.xuequ,
                            jiaofang: this.form.jiaofang,
                            geju: this.form.geju,
                            zhaungxiuriqi: this.form.zhaungxiuriqi,
                            zengsong: this.form.zengsong,
                            zhaungxiuchengben: this.form.zhaungxiuchengben,
                            gaizao: this.form.gaizao,
                            zengsongxiangqing: this.form.zengsongxiangqing
                        }
                        let maintenanceInfo = JSON.stringify(weiHuInFo)
                        const data = {
                            houseId: this.houseId,
                            // maintenanceUserId: this.maintenanceUserId,
                            maintenanceInfo: maintenanceInfo
                        }
                        this.$http
                            .put(this.$url.UpdateHouseMaintenance, data)
                            .then(res => {
                                if (res.data.msgCode == 1) {
                                    this.$message.success("修改维护信息成功")
                                    this.$router.go(-1)
                                } else {
                                    this.$message.success(res.data.message)
                                }
                            })
                        console.log(data)
                    }
                } else {
                }
            })
        },
        cancel() {
            this.$router.go(-1)
        }
    }
}
</script>
 <style lang="less" scoped>
.xuqiuForm-item {
    display: flex;
    /deep/ .el-form-item {
        width: 29%;
        margin-bottom: 0px !important;
    }
    /deep/ .el-form-item__content {
        margin-left: 0px;
    }
}
.padding-r0 /deep/ input {
    padding-right: 0px !important;
}
</style>