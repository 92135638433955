<template>
    <div>
        <el-card>
            <el-tabs type="card" v-model="activeName" @tab-click="tabChange">
                <el-tab-pane label="本周排行" name="first">
                </el-tab-pane>
                <el-tab-pane label="本月排行" name="second">

                    <!-- <div style="width: 100%; min-height: 500px" id="chart1" ref="chart1"></div> -->
                    <!-- <div style="width: 100%; min-height: 500px" id="chart2" ref="chart2"></div> -->
                </el-tab-pane>
                <el-tab-pane label="本季度排行" name="third">

                    <!-- <div style="width: 100%; min-height: 500px" id="chart1" ref="chart1"></div> -->
                    <!-- <div style="width: 100%; min-height: 500px" id="chart3" ref="chart3"></div> -->
                </el-tab-pane>
            </el-tabs>

            <div style="width: 100%; min-height: 500px" id="chart1" ref="chart1"></div>

        </el-card>
    </div>
</template>

<script>
let Echarts = require("echarts") //基础实例 注意不要使用import
require("echarts/lib/chart/bar") //按需引入 bar = 柱状图\
export default {
    data() {
        return {
            roleType: "",
            chart1: null,
            ranking: "1",
            actual: "0",
            reality: "0",
            activeName: "first",
            echartxdata1: [],
            echartydata1: [],
            timeform: "",
            timeto: ""
        }
    },
    created() {
        var that = this
        this.roleType = localStorage.getItem("roleType")
        this.timeform = that.$timejs.getWeekStartDate()
        this.timeto = that.$timejs.getWeekEndDate()
        /* 获取列表 */
        this.getArticleList()
    },
    methods: {
        /* 获取echars列表 */
        async getArticleList() {
            var that = this
            const data = {
                dateFrom: that.timeform,
                dateTo: that.timeto
            }
            const { data: res } = await this.$http.get(
                this.$url.GetPerformanceStatistics,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                that.echartydata1 = []
                that.echartxdata1 = []
                res.dtNotice.forEach((element) => {
                    that.echartydata1.push(element.perfomanceEachOne)
                    that.echartxdata1.push(element.userName)
                })
                console.log(that.echartydata1, "111")
                console.log(that.echartxdata1, "322")
                that.init(that.echartxdata1, that.echartydata1)
            } else {
                that.$message.success(res.message)
            }
        },
        init(xdata, ydata) {
            //2.初始化
            this.chart1 = Echarts.init(this.$refs.chart1)
            //3.配置数据
            let option1 = {
                title: {
                    subtext: "销售量"
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        align: "center",
                        verticalAlign: "bottom",
                        lineHeight: -20
                    },
                    data: xdata
                }, //X轴
                // xAxis: {
                //     type: "category",
                //     data: xdata
                // }, //X轴
                yAxis: { type: "value" }, //Y轴
                series: [
                    {
                        data: ydata,
                        type: "bar"
                    }
                ] //配置项
            }
            // 4.传入数据
            this.chart1.setOption(option1)
        },

        handleClick(tab, event) {
            this.activeName = tab.name
        },
        tabChange(tab) {
            if (tab.name == "first") {
                this.getArticleList()
            } else if (tab.name == "second") {
                this.timeform = this.$timejs.getMonthStartDate()
                this.timeto = this.$timejs.getMonthEndDate()
                this.getArticleList()
            } else if (tab.name == "third") {
                this.timeform = this.$timejs.getQuarterStartDate()
                this.timeto = this.$timejs.getQuarterEndDate()
                this.getArticleList()
            }
        }
    }
}
</script>

<style lang="less" scoped>
</style>
