<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/TiChengBiList' }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%" style="margin-left: 3%;">
                <el-row v-if="areaName=='录入'">

                    <el-col :span="12">
                        <span class="redStar" style="margin-left:6.5px" :style="{ color: this.redStar ?   'transparent':'tomato'}">*</span>
                        <el-form-item label="录入人提成比:" label-width="20%" prop="proportion" style="margin-top:-35px">
                            <el-input @keyup.native='keyupEvent' type="number" placeholder="请输入录入人提成比" style="width: 80%" v-model="proportion" :disabled="flag"></el-input>
                            <span style="margin-left: 7px">%</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="areaName=='维护'">

                    <el-col :span="12">
                        <span class="redStar" style="margin-left:6.5px" :style="{ color: this.redStar ?   'transparent':'tomato'}">*</span>
                        <el-form-item style="margin-top:-35px" label="维护人提成比:" label-width="20%" prop='proportion'>
                            <el-input @keyup.native='keyupEvent' type="number" placeholder="请输入维护人提成比 " style="width: 80%" v-model="proportion" :disabled="flag"></el-input>
                            <span style="margin-left: 7px">%</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="areaName=='实勘'">

                    <el-col :span="12">
                        <span class="redStar" style="margin-left:6.5px" :style="{ color: this.redStar ?   'transparent':'tomato'}">*</span>
                        <el-form-item style="margin-top:-35px" label="实勘人提成比:" label-width="20%" prop="proportion">
                            <el-input @keyup.native='keyupEvent' type="number" placeholder="请输入实勘人提成比" style="width: 80%" v-model="proportion" :disabled="flag"></el-input>
                            <span style="margin-left: 7px">%</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="areaName=='钥匙'">

                    <el-col :span="12">
                        <span class="redStar" style="margin-left:6.5px" :style="{ color: this.redStar ?   'transparent':'tomato'}">*</span>
                        <el-form-item style="margin-top:-35px" label="钥匙人提成比:" label-width="20%" prop="proportion">
                            <el-input @keyup.native='keyupEvent' placeholder="请输入钥匙人提成比 " style="width: 80%" v-model="proportion" :disabled="flag"></el-input>
                            <span style="margin-left: 7px">%</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="areaName=='证件'">

                    <el-col :span="12">
                        <span class="redStar" style="margin-left:6.5px" :style="{ color: this.redStar ?   'transparent':'tomato'}">*</span>
                        <el-form-item style="margin-top:-35px" label="证件人提成比:" label-width="20%" prop="proportion">
                            <el-input @keyup.native='keyupEvent' placeholder="请输入证件人提成比" style="width: 80%" v-model="proportion" :disabled="flag"></el-input>
                            <span style="margin-left: 7px">%</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="" label-width="20%">
                            <span class="el-icon-warning-outline" style="color:red;"><span style="margin-left:10px;">客户端提成比总和为30%</span></span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%" style="margin-top:12px">
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>

                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],

    watch: {
        value(val) {
            this.content = this.value
        },
        proportion(val) {
            if (val == "") {
                this.redStar = true
                this.rules.proportion = [
                    {
                        required: true,
                        message: "请输入" + this.areaName + "人提成比",
                        trigger: "blur"
                    }
                ]
            } else {
                this.redStar = false
                this.rules.proportion = [
                    {
                        required: false,
                        message: "请输入" + this.areaName + "人提成比",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    data() {
        return {
            num: this.$route.query.num,
            commonMasterId: this.$route.query.commonMasterId,
            areaName: this.$route.query.areaName,
            currentPage: this.$route.query.currentPage,
            pageSize: this.$route.query.pageSize,
            name: "",
            flag: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            form: {
                luruCommission: "",
                weihuCommission: "",
                shikanCommission: "",
                yaoshiCommission: "",
                zhengjianCommission: "",
                VIPCommission: "",
                proportion: "",
                commonMasterId: ""
            },
            proportion: "",
            merchantName: "",
            rules: {
                proportion: [
                    {
                        required: true,
                        message: "请输入" + this.areaName + "人提成比",
                        trigger: "blur"
                    }
                ]
            },
            redStar: true,
            dianpulist: [],
            noticeOptions: [],
            daQuInclude: "",
            shopName: "",
            merchantId: "",
            flags: false,
            roleType: localStorage.getItem("roleType")
        }
    },
    created() {
        // if (this.roleType == "6") {
        //     this.getMerchantList()
        // }
        // this.getArticleDetail(this.commonMasterId)

        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "提成比设置新增"
            this.form = {}
        } else if (this.num == 1) {
            this.name = "提成比设置详情"
            this.flag = true
            this.flags = true
            this.getArticleDetail(this.commonMasterId)
        } else if (this.num == 2) {
            this.name = "提成比设置修改"
            // this.flags = true
            this.getArticleDetail(this.commonMasterId)
        }
    },
    methods: {
        keyupEvent(e) {
            e.target.value = e.target.value.replace(/[^\d.]/g, "")
            e.target.value = e.target.value.replace(/\.{2,}/g, ".")
            e.target.value = e.target.value.replace(/^\./g, "0.")
            e.target.value = e.target.value.replace(
                /^\d*\.\d*\./g,
                e.target.value.substring(0, e.target.value.length - 1)
            )
            e.target.value = e.target.value.replace(/^0[^\.]+/g, "0")
            e.target.value = e.target.value.replace(
                /^(\d+)\.(\d\d).*$/,
                "$1.$2"
            )
            if (e.target.value > 30 || e.target.value < 0) {
                e.target.value = 30
            }
        },

        // 新增

        // 修改
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.proportion = this.areaName + "/" + this.proportion
                    const data = {
                        CommonMasterType: "TICHENGBILI",
                        commonMasterId: this.commonMasterId,
                        commonMasterName: this.proportion,
                        commonMasterType: this.commonMasterType
                    }

                    const { data: res } = await this.$http.put(
                        this.$url.UpdateCommonMaster,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        // this.$message.success("修改文章信息成功")
                        this.$router.push({ path: "/TiChengBiList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // 获取提成比详情
        async getArticleDetail(id) {
            const { data: res } = await this.$http.get(
                this.$url.GetCommonMasterDetail,
                {
                    params: {
                        commonMasterId: id
                    }
                }
            )
            if (res.msgCode == 1) {
                this.form = res.dtList[0]
                this.proportion = this.form.commonMasterName.split("/")[1]
            } else {
                this.$message.success(res.message)
            }
        },
        // 取消
        cancel() {
            this.$router.push({ path: "/TiChengBiList" })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader-panel {
    .el-cascader-menu:nth-child(1) {
        .el-checkbox {
            display: block !important;
        }
    }
}
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
// .ql-container .ql-editor {
//     max-height: 25em;
// }
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.notice-pad .el-form-item__label {
    padding-top: 5px;
}
// 去掉多选框的横向滚动条
.el-scrollbar__wrap {
    overflow-x: hidden !important;
}
.noticePadding {
    margin-top: 6px;
}
</style>