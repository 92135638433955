<template>
    <div class="login_container">
        <div class="login_box">
            <!-- 头像区域 -->
            <div class="avatar_box">
                <img src="../../../public/img/logoIndex.jpg" alt="" />
            </div>
            <!-- 登录表单区域 -->
            <el-form v-loading="loading" ref="loginFormRef" :rules="loginFormRules" :model="loginForm" label-width="0px"
                class="login_form">
                <!-- 用户名 -->
                <el-form-item prop="mobile">
                    <el-input v-model="loginForm.mobile" prefix-icon="el-icon-user-solid" maxlength="11"
                        placeholder="请输入手机号"></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" prefix-icon="el-icon-lock"
                        :type="flag1 ? 'password' : 'text'" placeholder="请输入密码">
                        <i slot="suffix" :class="[flag1 ? 'el-icon-minus' : 'el-icon-view']"
                            style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="flag1 = !flag1" />
                    </el-input>
                </el-form-item>
                <!-- 验证码 -->
                <el-form-item style="width: 250px; float: left; margin-bottom: 10px">
                    <el-input v-model="inputInfo" prefix-icon="el-icon-lock" maxlength="4" type="text"
                        placeholder="请输入验证码" @keyup.enter.native="login"></el-input>
                </el-form-item>
                <el-form-item style="
            width: 200px;
            float: right;
            margin-bottom: 10px;
            margin-top: 2px;
          ">
                    <SIdentify :identifyCode="identifyCode" style="float: left"></SIdentify>
                    <el-link :underline="false" style="float: left" @click="changeCode">看不清换一张</el-link>
                </el-form-item>
                <!-- 按钮区域 -->
                <el-form-item class="btns">
                    <el-button size="mini" id="btn" type="primary" v-preventReClick @click="login">登录</el-button>
                    <el-button size="mini" type="info" @click="resetLoginForm">重置</el-button>
                    <el-link style="margin-left: 5px" type="warning" @click="jumpToRegister">找回密码</el-link>
                </el-form-item>
            </el-form>
            <el-form style="margin-top: 80%">
                <div class="btns" style="cursor:pointer" @click="openNewTab('https://beian.miit.gov.cn/')">
                    辽ICP备2021007155号-1
                </div>
            </el-form>
        </div>
        <el-dialog :visible.sync="tablepetbreed" :before-close="tablepetbreedClose" direction="rtl" size="50%"
            style="z-index: 99999">
            <!-- <el-card>
                <el-form ref="resetFormRef" :model="resetForm" :rules="resetFormRules" label-position="right" label-width="20%">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="手机号:" label-width="25%" prop="mobile">
                                <el-input placeholder="请输入手机号" style="width: 80%" v-model="resetForm.mobile" maxlength="11"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="短信验证码:" label-width="25%" prop="verificationCode">
                                <el-input v-model="resetForm.verificationCode" placeholder="请输入短信验证码" style="width: 40%; float: left"></el-input>
                                <el-button size="mini" v-if="!sendMsgDisabled" style="margin-left: 20px" type="primary" @click="sendMsg">获取验证码</el-button>
                                <el-button size="mini" v-if="sendMsgDisabled" style="margin-left: 20px" type="primary" @click="sendMsg">{{ codeTime + "秒后重试" }}</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="新密码:" label-width="25%" prop="password">
                                <el-input placeholder="请输入密码" style="width: 80%" v-model="resetForm.password" :type="flag2 ? 'password' : 'text'">
                                    <i slot="suffix" :class="[flag2 ? 'el-icon-minus' : 'el-icon-view']" style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="flag2 = !flag2" />
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="确认密码:" label-width="25%" prop="password1">
                                <el-input placeholder="请确认密码" style="width: 80%" v-model="resetForm.password1" :type="flag3 ? 'password' : 'text'">
                                    <i slot="suffix" :class="[flag3 ? 'el-icon-minus' : 'el-icon-view']" style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="flag3 = !flag3" />
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="验证码:" label-width="25%" prop="inputInfo1">
                                <el-input v-model="resetForm.inputInfo1" type="text" placeholder="请输入验证码" style="width: 40%; float: left"></el-input>
                                <el-link :underline="false" style="margin-left: 20px" @click="changeCode1">看不清换一张</el-link>
                                <VIdentify :identifyCode="identifyCode1" style="float: left; margin-left: 20px"></VIdentify>
                            </el-form-item>
                            <el-form-item style="width: 200px;float: right;margin-bottom: 10px;margin-top: 2px;">
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col>
                            <el-form-item label-width="42%">
                                <el-button size="mini" type="primary" @click="onUpdate('resetFormRef')">确定</el-button>
                                <el-button size="mini" @click="goBack">取消</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card> -->
            <div class="el-icon-warning" style="color: red;font-size: 20px;">请联系店东（商户管理员）重置为初始密码</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tablepetbreed = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SIdentify from "../Home/Identify" //登陆的验证码canvas
// import VIdentify from "../Home/Identify1" //找回密码的验证码canvas
import md5 from "js-md5" //md5加密
import Fingerprint2 from "fingerprintjs2" //浏览器指纹获取
import commonJs from "../../assets/js/common.js" //共通js方法
import preventReClick from "../Home/preventReClick" //防多次点击，重复提交
export default {
    components: {
        SIdentify: SIdentify //登陆的验证码canvas
        // VIdentify: VIdentify //找回密码的验证码canvas
    },
    data() {
        var inputPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"))
                this.loading = false
            } else if (!commonJs.numberPhone(value)) {
                callback(new Error("格式不符，请填写正确的手机号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        return {
            flag1: true, //登陆密码是否显示（true为不显示，false为显示）
            flag2: true, //找回密码是否显示（true为不显示，false为显示）
            flag3: true, //确认密码是否显示（true为不显示，false为显示）
            loading: false, //是否加载loading动画（true为加载，false为不加载）
            tablepetbreed: false, //是否显示找回密码弹出层（true为显示，false为不显示）
            staffName: "", //用户名
            inputInfo: "", //登陆的验证码
            verificationCode: "", // 短信验证码
            codeTime: 59, // 发送验证码倒计时
            sendMsgDisabled: false, // 是否显示获取验证码按钮
            identifyCode: "", // 登陆验证码初始值
            identifyCodes: "1234567890", // 登陆验证码的随机取值范围
            identifyCode1: "", // 找回密码验证码初始值
            identifyCodes1: "1234567890", // 找回密码验证码的随机取值范围

            // 登录表单的数据绑定对象
            loginForm: {
                mobile: "",
                password: "",
                fp: ""
            },
            // 登陆表单的验证规则对象
            loginFormRules: {
                mobile: [{ validator: inputPhone, trigger: "blur" }],
                password: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: "blur"
                    },
                    {
                        min: 6,
                        max: 15,
                        message: "长度在 6 到 15 个字符",
                        trigger: "blur"
                    }
                ]
            },

            // 找回密码表单的数据绑定对象
            resetForm: {
                mobile: "",
                password: "",
                password1: ""
            },
            // 找回密码表单的验证规则对象
            resetFormRules: {
                mobile: [{ validator: inputPhone, trigger: "blur" }],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    {
                        min: 6,
                        max: 15,
                        message: "长度在 6 到 15 个字符",
                        trigger: "blur"
                    }
                ],
                password1: [
                    { required: true, message: "请确认密码", trigger: "blur" },
                    {
                        min: 6,
                        max: 15,
                        message: "长度在 6 到 15 个字符",
                        trigger: "blur"
                    }
                ],
                verificationCode: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur"
                    }
                ],
                inputInfo1: [
                    { required: true, message: "请输入验证码", trigger: "blur" }
                ]
            }
        }
    },
    created() {
        // 获取浏览器指纹
        this.getFp()
    },
    mounted() {
        this.identifyCode = ""
        this.makeCode(this.identifyCodes, 4)
        this.identifyCode1 = ""
        this.makeCode1(this.identifyCodes1, 4)
    },
    methods: {
        //浏览器指纹
        getFp() {
            var _this = this
            Fingerprint2.get(function (components) {
                const values = components.map(function (component, index) {
                    if (index === 0) {
                        // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, "")
                    }
                    return component.value
                })
                // 生成最终id murmur
                var murmur = Fingerprint2.x64hash128(values.join(""), 31)
                //console.log('浏览器指纹码：' + murmur)
                _this.loginForm.fp = murmur
                // console.log('浏览器指纹码：'+_this.loginForm.fp)
                window.localStorage.setItem("fp", _this.loginForm.fp)
            })
            // this.loginForm.fp = murmur
            // console.log(this.loginForm.fp)
        },
        // 重置登陆输入框
        resetLoginForm() {
            this.$refs.loginFormRef.resetFields()
            this.inputInfo = ""
            this.identifyCode = ""
            this.makeCode(this.identifyCodes, 4)
        },
        tablepetbreedClose(done) {
            done()
        },
        // 登录
        login() {
            this.loading = true
            this.$refs.loginFormRef.validate(async valid => {
                if (!valid) return
                const data = {
                    mobile: this.loginForm.mobile,
                    password: md5(this.loginForm.password),
                    fp: this.loginForm.fp
                }
                if (this.inputInfo === "") {
                    this.loading = false
                    return this.$message.error("请输入验证码")
                } else if (this.identifyCode !== this.inputInfo) {
                    this.loading = false
                    this.$message.error("验证码不正确，请重新输入")
                    this.loading = false
                    return this.changeCode()
                }
                this.$http
                    .post(this.$url.login(data.mobile, data.password, data.fp))
                    .then(res => {
                        if (res.data.success === "true") {
                            this.loading = false
                            this.$message.success("登录成功")
                            this.staffName = res.data.dataTableAuth[0].userName
                            window.localStorage.setItem(
                                "accessToken",
                                res.data.accessToken
                            )
                            window.localStorage.setItem(
                                "staffName",
                                this.staffName
                            )
                            console.log(res.data.dataTableAuth[0].userType)
                            window.localStorage.setItem(
                                "userType",
                                res.data.dataTableAuth[0].userType
                            )
                            window.localStorage.setItem(
                                "merchantId", //商户Id
                                res.data.dataTableAuth[0].merchantId
                            )
                            window.localStorage.setItem(
                                "userId",
                                res.data.dataTableAuth[0].userId
                            )
                            window.localStorage.setItem(
                                "pictureId",
                                res.data.dataTableAuth[0].pictureId
                            )
                            window.localStorage.setItem(
                                "shopId",
                                res.data.dataTableAuth[0].shopId
                            )
                            window.localStorage.setItem(
                                "mobile",
                                res.data.dataTableAuth[0].mobile1
                            )
                            window.localStorage.setItem(
                                "userLevel",
                                res.data.dataTableAuth[0].userLevel
                            )
                            window.localStorage.setItem(
                                "integral",
                                res.data.dataTableAuth[0].integral
                            )
                            window.localStorage.removeItem("editableTabs")
                            window.localStorage.removeItem("activeIndex")
                            this.$router.push("/GetStatistics")
                        } else {
                            this.loading = false
                            this.$message.error(res.data.message)
                        }
                    })
                    .catch(res => {
                        this.loading = false
                        this.$message.error(res.data.message)
                    })
            })
        },
        // 获取短信验证码
        sendMsg() {
            var that = this
            if (that.resetForm.mobile != "") {
                const data = {
                    mobile: that.resetForm.mobile
                }
                that.$http
                    .get(this.$url.GetVerificationCode, {
                        params: {
                            mobile: encodeURIComponent(that.resetForm.mobile)
                        }
                    })
                    .then(function (res) {
                        that.sendMsgDisabled = true
                        let codeInterval = window.setInterval(function () {
                            that.codeTime--
                            if (that.codeTime <= 0) {
                                that.sendMsgDisabled = false
                                that.codeTime = 59
                                window.clearInterval(codeInterval)
                            }
                        }, 1000)
                    })
            } else {
                that.$message.error("请先输入手机号!")
            }
        },
        // 确定找回密码
        onUpdate() {
            this.$refs.resetFormRef.validate(async valid => {
                if (valid) {
                    const data = {
                        mobile: this.resetForm.mobile,
                        password: md5(this.resetForm.password),
                        verificationCode: this.resetForm.verificationCode
                    }
                    if (this.resetForm.password != this.resetForm.password1) {
                        return this.$message.error(
                            "两次输入密码不同，请重新输入"
                        )
                    } else if (this.resetForm.inputInfo1 === "") {
                        this.loading = false
                        return this.$message.error("请输入验证码")
                    } else if (
                        this.identifyCode1 !== this.resetForm.inputInfo1
                    ) {
                        this.loading = false
                        this.$message.error("验证码不正确，请重新输入")
                        this.loading = false
                        return this.changeCode()
                    } else {
                        this.$http
                            .post(
                                this.$url.ResetPassword(
                                    data.mobile,
                                    data.password,
                                    data.verificationCode
                                )
                            )
                            .then(res => {
                                if (res.data.success === "true") {
                                    this.loading = false
                                    this.$message.success("密码重置成功")
                                    this.tablepetbreed = false
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            })
                    }
                }
            })
        },
        // 验证码生成一个随机整数（登陆）
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 刷新验证码（登陆）
        changeCode() {
            this.identifyCode = ""
            this.makeCode(this.identifyCodes, 4)
        },
        // 随机生成验证码字符串（登陆）
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[
                    this.randomNum(0, this.identifyCodes.length)
                ]
            }
        },
        // 验证码生成一个随机整数（找回密码）
        randomNum1(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },

        // 刷新验证码（找回密码）
        changeCode1() {
            this.identifyCode1 = ""
            this.makeCode1(this.identifyCodes1, 4)
        },

        // 随机生成验证码字符串（找回密码）
        makeCode1(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode1 += this.identifyCodes1[
                    this.randomNum1(0, this.identifyCodes1.length)
                ]
            }
        },
        //找回密码弹出层方法
        jumpToRegister() {
            this.tablepetbreed = true
        },
        // 关闭找回密码弹出层
        goBack() {
            this.tablepetbreed = false
        },
        openNewTab(url) {
            window.open(url, '_blank');

        }
    }
}
</script>

<style lang="less" scoped>
.login_container {
    background-color: #df0202;
    height: 100%;
}

.login_box {
    width: 500px;
    height: 340px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
}

.login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.btns {
    width: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.el-card__body {
    text-align: center !important;
}

.el-col-12 {
    width: 100% !important;
}
</style>
