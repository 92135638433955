<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <span style="float:right;">
                <el-button size="mini" class="el-button1" type="primary" @click="goBack">返回</el-button>
            </span>
        </el-card>
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
            <el-card>
                <h4 style="margin-top:0px;margin-bottom:0px;">证件信息</h4>
                <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
                <el-row style="margin-top: 15px;">
                    <el-col :span="24">
                        <el-form-item label="上传证件图片" label-width="10%">
                            <el-upload :file-list="picture" :action="UploadPics" :on-progress="fileChange" :limit="20" list-type="picture-card" :on-success="success" accept="image/*" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-error="error" :on-remove="handleBeforeRemove" style="width: 75.5%">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="" label-width="20%">
                            <el-button type="primary" size="mini" :disabled="disabled" @click="onSubmit">确认</el-button>
                            <el-button type="warning" size="mini" @click="cancel">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            num: this.$route.query.num,
            houseId: this.$route.query.houseId,
            name: "",
            UploadPics: "",
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            form: {},
            certificatePics: [],
            rules: {},
            disabled: false,
            picIds: [],
            MyHeader: {
                currentRole: "",
                accessToken: "",
                fp: ""
            },
            removeType: ""
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken") //获取accessToken
        this.MyHeader.fp = localStorage.getItem("fp") //浏览器指纹fp
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        this.name = "证件信息"
        // if (this.$route.query.type == 1) {
        //     // 出租
        //     this.removeType = "houses"
        //     this.UploadPics = this.$url.UploadHouses
        // } else {
        //     // 出售
        //     this.removeType = "houses"
        //     this.UploadPics = this.$url.UploadHouses
        // }
        this.removeType = "houses"
        this.UploadPics = this.$url.UploadHouses
        console.log(this.removeType)
        this.GetHouseDetail()
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        //图片上传成功
        success(response, file) {
            const size = file.size / 1024 / 1024 < 10
            if (response.msgCode == "1") {
                if (!size) {
                    this.$message.error("上传图片大小不能超过 10MB")
                    return
                }
                this.disabled = false
                this.certificatePics.push(response.id)
                this.$message.success("上传图片成功")
                // this.certificatePics = this.picIds.join(",")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        fileChange() {
            this.disabled = true
        },
        //图片上传id
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        //图片上传失败
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        //图片上传删除
        handleBeforeRemove(file, fileList) {
            var mm = ""
            console.log(this.type)

            if (file.response == undefined) {
                mm = file.url.slice(42)
                // return this.$confirm(
                //     "此操作将永久删除该数据, 是否继续?",
                //     "提示",
                //     {
                //         confirmButtonText: "确定",
                //         cancelButtonText: "取消",
                //         type: "warning"
                //     }
                // )
                //     .then(() => {

                console.log(file)
                mm = file.name
                let ids = new Set(this.certificatePics)
                ids.delete(mm)
                console.log(ids)
                this.certificatePics = Array.from(ids)
                this.isMax = false

                // })
                // .catch((err) => {
                // this.certificatePics = []
                // this.GetHouseDetail()
                // })
            } else {
                mm = file.response.id
                let ids = new Set(this.certificatePics)
                ids.delete(mm)
                console.log(ids)
                this.certificatePics = Array.from(ids)
            }
            this.$commonJs.deleteImg(file, this.removeType)
        },
        onSubmit() {
            const data = {
                houseId: this.houseId,
                businessType: "CERTIFICATES",
                certificatePics: this.certificatePics.join(",")
            }
            this.$http.put(this.$url.UpdateHouseShiKan, data).then((res) => {
                if (res.data.msgCode == 1) {
                    this.$message.success("证件信息修改成功!")
                    this.$router.go(-1)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        GetHouseDetail() {
            var that = this
            this.$http
                .get(this.$url.GetHouseDetail, {
                    params: {
                        houseId: this.houseId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        if (
                            res.data.dtList[0].certificatePics != "" &&
                            res.data.dtList[0].certificatePics != null
                        ) {
                            let picture =
                                res.data.dtList[0].certificatePics.split(",")
                            that.certificatePics = picture
                            console.log(picture)
                            // 出租证件
                            // if (this.$route.query.type == 1) {
                            //     picture.forEach((item) => {
                            //         that.picture.push({
                            //             name: item,
                            //             url: that.$url.houses + item
                            //         })
                            //     })
                            //     console.log("出租证件显示")
                            // } else {
                                picture.forEach((item) => {
                                    that.picture.push({
                                        name: item,
                                        url: that.$url.houses + item
                                    })
                                })
                                console.log(that.picture[0].url)
                                console.log("出售证件显示")
                            // }
                        }
                    }
                })
        },
        cancel() {
            this.$router.go(-1)
        }
    }
}
</script>