<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <!-- <router-link :to="{ path: '/LouPanZiDian1Detail1' }"> -->
            <el-button @click="cancel" size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            <!-- </router-link> -->
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="30%">
                <el-row>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="工程楼号:" prop="gongChengLouHao">
                            <el-input :disabled="flag" v-model="form.gongChengLouHao" type="input" style="width: 80%" :rows="1" placeholder="请输入工程楼号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="坐落地址:" label-width="30%" prop="louDongAddress">
                            <el-input :disabled="flag" v-model="form.louDongAddress" type="input" style="width: 80%" :rows="1" placeholder="请输入坐落地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                        <el-form-item label="层数:" label-width="30%" prop="floorCount">
                            <el-input :disabled="flag" v-model="form.floorCount" type="input" style="width: 80%" :rows="1" placeholder="请输入层数"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>

                <div class="logtime">
                    <el-col :span="6">
                        <el-form-item v-if="this.num2 != 0" label="创建时间:" label-width="30%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item v-if="this.num2 != 0" label="更新时间:" label-width="30%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item v-if="this.num2 != 0" label="创建人:" label-width="30%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item v-if="this.num2 != 0" label="更新人:" label-width="30%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="7.5%">
                    <el-button v-if="num2==2" @click="addDanYuan" type="primary" style="height:30px;line-height:0">添加单元</el-button>
                    <el-button size="mini" type="primary" v-if="num2 == 0" @click="onSubmit('form')">新增楼栋</el-button>
                    <el-button size="mini" type="primary" v-if="this.num2 == 2" @click="onUpdate('form')">更新楼栋</el-button>
                </el-form-item>
            </el-form>
        </el-card>`

        <el-tabs v-model="danyuanname" @tab-click="handleClick" class="loupanTabs" style="height: 40px;position: relative;top: 0px;margin-top: 10px;background-color: #fff;">
            <el-tab-pane :name="item" :label="item" v-for="(item,i) in menhuKey" :key="i">
                <el-card>
                    <div style="margin-top: 15px;display: flex;margin-left: 35px;">
                        <span style="width: 400px">添加楼层: <el-input type="input" v-model="addLouCeng" style="width: 210px;margin-left: 7px;" :rows="1" placeholder="请输入要添加的楼层数（如2）"></el-input><span style="margin: 0px 4px;">层</span>
                        </span>
                        <span style="width: 400px">添加门户: <el-input type="input" v-model="addMenHu" style="width: 210px;margin-left: 7px;" :rows="1" placeholder="请输入要添加的门户数（如2）"></el-input><span style="margin: 0px 4px;">户</span>
                        </span>
                        <el-button @click="addLouCengMenHu" type="primary" style="height:30px;line-height:0">添加</el-button>
                    </div>

                    <el-table ref="multipleTable" v-loading="loading" :data="loupanList" row-key="index" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                        <el-table-column prop="louceng" width="100" style="text-align:center !important">
                            <template slot-scope="scope">
                                <span>{{scope.row.louceng}}</span>
                            </template>
                        </el-table-column>
                        <template v-for="(item,i) in huShu">
                            <el-table-column :key="i" min-width="150">
                                <template slot-scope="scope" style="position: relative;">
                                    <el-input :disabled="flag" @blur="blur(loupanList[scope.$index]['menhu'+i],scope.row.louceng,i)" @input="input" v-model="loupanList[scope.$index]['menhu'+i]" type="input" style="width: 80%;color:green;" :rows="1" v-if="loupanList[scope.$index]['chushouType'+i]=='未售'"></el-input>
                                    <el-input :disabled="flag" @blur="blur(loupanList[scope.$index]['menhu'+i],scope.row.louceng,i)" @input="input" v-model="loupanList[scope.$index]['menhu'+i]" type="input" style="width: 80%;color:green" :rows="1" v-if="loupanList[scope.$index]['chushouType'+i]!='未售'"></el-input>
                                </template>
                            </el-table-column>
                        </template>
                        <!-- <el-table-column label="操作" width="85" style="text-align:center !important" v-if="num2==2">
                            <template class="right" slot-scope="scope">
                                <el-button v-if="flag!=true&&loupanList[scope.$index].menhu.length>0" @click="deleteLouCeng(loupanList[scope.$index],scope.row.louceng,scope.$index,'row')" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                            </template>
                        </el-table-column> -->
                        <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                            <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        </div>
                    </el-table>

                    <!-- 分页区域 -->
                    <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination> -->
                </el-card>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
//引入地图
// import mapSelect from "../mapSelect"

export default {
    name: "index",
    inject: ["reload"],
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            time: "", //看房时间
            addressSelect: "",
            num1: this.$route.query.num1,
            num2: this.$route.query.num2,
            louPanId: this.$route.query.louPanId,
            louDongId: this.$route.query.louDongId,
            louPanNo: this.$route.query.louPanNo,
            name: "",
            mm: "",
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: false,
            diseaseIds: [],
            id: "",
            // 表单
            form: {
                gongChengLouHao: "", //工程楼号
                louDongAddress: "", //坐落地址
                floorCount: "", //总层数
                updatePersonName: "", //更新人
                updateTime: "", //更新时间
                createPersonName: "", //创建人
                createTime: "" //创建时间
            },
            // 表单验证
            rules: {
                floorCount: [
                    {
                        required: true,
                        message: "请输入总层数",
                        trigger: "blur"
                    }
                ],
                louDongAddress: [
                    {
                        required: true,
                        message: "请输入坐落地址",
                        trigger: "blur"
                    }
                ],
                gongChengLouHao: [
                    {
                        required: true,
                        message: "请输入工程楼号",
                        trigger: "blur"
                    }
                ]
            },
            // 详情下方列表
            currentPage: 1,
            pageSize: 10,
            total: 0,
            loupanList: [],
            menhuKey: [],
            danyuanname: "1单元",
            louDongInfo: [],
            yuanHuShu: 0, //原户数
            huShu: 0, //户数
            addLouCeng: 0,
            addMenHu: 0,
            listLength:0
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num2 == 0) {
            this.loading = false
            this.name = "楼盘字典楼栋新增"
            this.form = {}
        } else if (this.num2 == 1) {
            this.name = "楼盘字典楼栋详情"
            this.flag = true
            this.isMax = true
            this.getloudongDetail()
        } else if (this.num2 == 2) {
            this.name = "楼盘字典楼栋修改"
            this.getloudongDetail()
        }
    },
    methods: {
        // 输入框刷新
        input() {
            this.$forceUpdate()
        },
        // 输入框失去焦点
        blur(e, louceng, menhuIndex) {
            this.updateLouCeng(e, louceng, menhuIndex) //e：输入的值 louceng：第几层 menhuIndex：门户下标
        },
        // 添加楼层和门户
        addLouCengMenHu() {
            let danyuanname = this.danyuanname.split("单元")[0],
                loupan = {},
                gongChengLouHao = Object.keys(this.louDongInfo)

            for (let x = 0; x < this.addLouCeng; x++) {
                let item = {
                        louceng: "",
                        index: "",
                        menhu: [],
                        chushouType: []
                    },
                    addLouDongInfo = {}

                for (let y = 1; y <= this.huShu; y++) {
                    item.menhu.push(
                        danyuanname +
                            "-" +
                            (this.listLength + 1) +
                            "-" +
                            y
                    )
                    item.chushouType.push("未售")

                    addLouDongInfo[
                        danyuanname +
                            "-" +
                            (this.listLength + 1) +
                            "-" +
                            y
                    ] = "未售"
                }
                item.louceng = this.listLength + 1 + "层"
                item.index = this.loupanList.length
                this.loupanList.push(item)
                this.listLength++
                this.louDongInfo[gongChengLouHao][this.danyuanname][
                    this.listLength + "层"
                ] = addLouDongInfo
            }

            for (let x1 = 0; x1 < this.addMenHu; x1++) {
                for (let y1 = 0; y1 < this.loupanList.length; y1++) {
                    let addLouDongInfo1 = {}

                    loupan = this.loupanList[y1]
                    loupan.menhu.push(
                        danyuanname +
                            "-" +
                            (loupan.index + 1) +
                            "-" +
                            (loupan.menhu.length + 1)
                    )
                    loupan.chushouType.push("未售")
                    loupan.menhu.forEach((item1, i) => {
                        if (item1 != undefined) {
                            loupan["menhu" + i] = item1.toString()
                        }
                    })
                    loupan.chushouType.forEach((item1, i) => {
                        if (item1 != undefined) {
                            loupan["chushouType" + i] = item1.toString()
                        }
                    })
                    this.huShu =
                        this.huShu < loupan.menhu.length
                            ? loupan.menhu.length
                            : this.huShu

                    addLouDongInfo1[
                        danyuanname +
                            "-" +
                            (loupan.index + 1) +
                            "-" +
                            loupan.menhu.length
                    ] = "未售"
                    this.louDongInfo[gongChengLouHao][this.danyuanname][
                        loupan.index + 1 + "层"
                    ] = Object.assign(
                        this.louDongInfo[gongChengLouHao][this.danyuanname][
                            loupan.index + 1 + "层"
                        ],
                        addLouDongInfo1
                    )
                }
            }
        },
        // 楼栋批量添加（右边添加按钮）
        addDanYuan() {
            let gongChengLouHao = Object.keys(this.louDongInfo)
            let menhuKey = this.menhuKey.length + 1
            this.menhuKey.push(menhuKey + "单元")
            this.louDongInfo[gongChengLouHao][menhuKey + "单元"] = {}
            for (let x = 1; x <= this.form.floorCount; x++) {
                let addLouDongInfo = {}
                addLouDongInfo[x + "层"] = {}
                for (let y = 1; y <= this.yuanHuShu; y++) {
                    addLouDongInfo[x + "层"][menhuKey + "-" + x + "-" + y] =
                        "未售"
                    this.louDongInfo[gongChengLouHao][menhuKey + "单元"] =
                        Object.assign(
                            this.louDongInfo[gongChengLouHao][
                                menhuKey + "单元"
                            ],
                            addLouDongInfo
                        )
                }
            }
        },
        // 修改门牌号
        updateLouCeng(e, louceng, menhuIndex) {
            //e：输入的值 louceng：第几层 menhuIndex：门户下标
            let that = this
            var keyMap = {
                name: e,
                value: "未出售"
            }
            that.louDongInfo1.forEach((item) => {
                let key = Object.keys(item)
                let val = Object.values(item)
                key.forEach((key1, keyIndex) => {
                    if (key1 == louceng) {
                        val.forEach((element, valIndex) => {
                            let key = Object.keys(element)
                            if (keyIndex == valIndex && e != key[menhuIndex]) {
                                var newKey = keyMap["name"]
                                var newVal = keyMap["value"]
                                if (Object.keys(val[valIndex]).length > 0) {
                                    if (
                                        Object.keys(val[valIndex]).length <
                                        that.huShu
                                    ) {
                                        // val[valIndex][newKey] =
                                        //     val[valIndex][key[menhuIndex]]
                                        // val[valIndex] = { newKey: "未售" }
                                        val[valIndex][newKey] = "未售"
                                        delete val[valIndex][key[menhuIndex]]
                                    } else {
                                        val[valIndex][newKey] =
                                            val[valIndex][key[menhuIndex]]
                                        delete element[key[menhuIndex]]
                                    }
                                } else {
                                    val[valIndex][newKey] = "未售"
                                }
                            }
                        })
                    }
                })
            })
            this.loupanList = []
            this.louDongFunction(3)
        },
        // 删除门牌号
        // deleteLouCeng(danyuanname, louceng, i, type) {
        //     let that = this
        //     that.louDongInfo1.forEach(item => {
        //         let item1 = Object.values(item)
        //         let element = Object.keys(item1[i])
        //         for (let key of element) {
        //             if (type == "one" && key.indexOf(danyuanname) != -1) {
        //                 delete that.louDongInfo1[0][louceng][danyuanname]
        //                 break
        //             } else if (
        //                 type == "row" &&
        //                 danyuanname.menhu.indexOf(element)
        //             ) {
        //                 danyuanname.menhu.forEach((menhuElement, index) => {
        //                     delete that.louDongInfo1[0][louceng][menhuElement]
        //                 })
        //             }
        //         }
        //     })
        //     for (let item of that.loupanList) {
        //         if (
        //             type == "one" &&
        //             Object.values(item).indexOf(danyuanname) != -1
        //         ) {
        //             let index = Object.values(item).indexOf(danyuanname)
        //             item[Object.keys(item)[index]] = ""
        //             break
        //         } else if (type == "row" && danyuanname.menhu.length != 0) {
        //             if (danyuanname.louceng == item.louceng) {
        //                 item.menhu.forEach((menhuElement, index) => {
        //                     that.loupanList[i]["menhu" + index] = ""
        //                 })
        //                 that.loupanList[i].menhu = []

        //                 break
        //             }
        //         }
        //     }
        //     this.$forceUpdate()
        // },
        handleClick(tab, event) {
            this.danyuanname = tab.label
            this.loupanList = []
            this.louDongFunction(2)
        },
        louDongFunction(type) {
            let that = this
            that.$nextTick(() => {
                that.louDongInfo1 = Array(
                    that.louDongInfo[Object.keys(that.louDongInfo)][
                        that.danyuanname
                    ]
                )
                that.louDongInfo1.forEach((element) => {
                    element = Array(element)
                    element.forEach((item) => {
                        let louceng = Object.keys(item)
                        that.listLength = louceng.length;
                        console.log(louceng)
                        let values = Object.values(item)
                        for (let i = 0; i < values.length; i++) {
                            let chushouType = Object.values(values[i])
                            let menhu = Object.keys(values[i])
                            // if (menhu.length > that.menhu.length) {
                            //     that.menhu = menhu
                            // }
                            if (type == 1) {
                                that.yuanHuShu = //原户数
                                    that.yuanHuShu < menhu.length
                                        ? menhu.length
                                        : that.yuanHuShu
                            }
                            if (type == 2) {
                                that.huShu = menhu.length
                            }
                            if (type == 3 || type == 1) {
                                that.huShu =
                                    that.huShu < menhu.length
                                        ? menhu.length
                                        : that.huShu
                            }

                            that.loupanList.push({
                                louceng: louceng[i],
                                menhu: menhu,
                                chushouType: chushouType,
                                index: i
                            })
                        }
                        for (let j = 0; j < louceng.length; j++) {
                            if(louceng[j].indexOf('-')!=-1){
                                that.listLength--
                            }
                        }
                        
                    })
                })
                console.log(that.listLength)
                that.loupanList.forEach((item11) => {
                    item11.menhu.forEach((item1, i) => {
                        if (item1 != undefined) {
                            item11["menhu" + i] = item1.toString()
                        }
                    })
                    item11.chushouType.forEach((item1, i) => {
                        if (item1 != undefined) {
                            item11["chushouType" + i] = item1.toString()
                        }
                    })
                })
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 获取楼栋详情
        async getloudongDetail() {
            let that = this
            await that.$http
                .get(that.$url.GetLouDongDetail, {
                    params: {
                        louDongId: that.louDongId
                    }
                })
                .then((res) => {
                    let that = this
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            that.form = res.data.dtList[0]

                            that.louDongInfo = JSON.parse(
                                res.data.dtList[0].louDongInfo
                            )
                            that.menhuKey = Object.keys(
                                that.louDongInfo[Object.keys(that.louDongInfo)]
                            )
                            that.louDongFunction(1)
                        }
                    } else {
                        that.$message.success(res.message)
                    }
                })
        },
        // 楼栋新增
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.chaoxiangOptions = this.checkedchaoxiang
                        .toString()
                        .replace(/,/g, "")
                    const data = {
                        louPanId: this.louPanId,
                        louDongAddress: this.form.louDongAddress,
                        gongChengLouHao: this.form.gongChengLouHao,
                        floorCount: this.form.floorCount,
                        louDongInfo: this.louDongInfo
                    }
                    this.$http.post(this.$url.InsertUser, data).then((res) => {
                        if (res.msgCode == 1) {
                            this.$message.success("新增楼栋成功")
                            this.$router.push({ path: "/LouPanZiDian1List" })
                        } else {
                            this.$message.success(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        // 楼栋更新
        async onUpdate(formName) {
            let that = this
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        louPanId: that.louPanId,
                        louDongId: that.louDongId,
                        louDongAddress: that.form.louDongAddress,
                        gongChengLouHao: that.form.gongChengLouHao,
                        floorCount: parseInt(that.form.floorCount),
                        louDongInfo: JSON.stringify(that.louDongInfo)
                    }
                    that.$http
                        .put(that.$url.UpdateLouDong, data)
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                that.$message.success("修改楼栋成功")
                                that.$router.push({
                                    name: "LouPanZiDian1Detail1",
                                    query: {
                                        louPanId: that.louPanId,
                                        num1: that.num1,
                                        state: 2
                                    }
                                })
                            } else {
                                that.$message.success(res.data.message)
                            }
                        })
                } else {
                    return false
                }
            })
        },

        // 返回上一页
        cancel() {
            this.$router.push({
                name: "LouPanZiDian1Detail1",
                query: {
                    louPanId: this.louPanId,
                    louPanNo: this.louPanNo,
                    num1: this.num1,
                    state: 2
                }
            })
        },

        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        }
    }
}
</script>

<style scoped>
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less" scoped>
/deep/ .el-main {
    overflow: hidden !important;
}
/deep/ .el-table__header-wrapper {
    display: none !important;
}
.loupanTabs {
    font-size: 14px;
    /deep/ .el-input__inner {
        text-align: center;
    }
    /deep/ .el-table__body td:nth-child(1) {
        text-align: center !important;
    }
    /deep/ .el-table__body td:nth-child(2) {
        text-align: center !important;
    }
    /deep/ .el-table__body td {
        text-align: center !important;
    }
    /deep/ .is-scrollable {
        padding: 0 !important;
        width: 96% !important;
    }
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        padding-left: 35px;
        margin: 0px;
        width: 98%;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-card__body {
        margin: 5px 25px;
        padding: 0;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-tabs__nav-next,
    /deep/ .el-tabs__nav-prev {
        line-height: 39px;
        margin: 0px 10px;
    }
    /deep/ .el-icon-close {
        margin-right: 0px !important;
        margin-top: 10px !important;
        font-size: 14px !important;
    }
    /deep/ .el-tabs__nav {
        padding-right: 2% !important;
        overflow: hidden;
    }
}
</style>