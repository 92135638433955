<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <!-- <router-link :to="{ path: '/DianPuList' }"> -->
            <el-button size="mini" class="el-button1" type="warning" style="float: right" @click="cancel">返回</el-button>
            <!-- </router-link> -->
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row style="margin-top: 3px;height: auto; margin-left:54px">
                    <el-col :span="20" v-if="num1!=1">
                        <el-form-item label="商圈:" label-width="11.8%">
                            <span class="redStar" style="margin-left: -57px;">*</span>
                            <el-tabs v-model="list" style="margin-left:0%; margin-top:-29px" type="card" @tab-click="handleClick">
                                <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :label="item.name">
                                    <el-checkbox-group :max="3" style="font-size: 14px;float:left;margin:5px;" v-model="checkedAreaType">
                                        <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{item1.name}}</el-checkbox>
                                    </el-checkbox-group>
                                </el-tab-pane>
                            </el-tabs>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20" v-if="num1==1">
                        <el-form-item label="所属商圈:" label-width="11.8%">{{ form.areaNames }}</el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row style="margin-top: 3px;height: auto; margin-left:54px">
                    <el-col :span="20">
                        <el-form-item label="商圈:" label-width="7.5%">
                            <el-tabs v-model="list" style="margin-left:0%;" type="card" @tab-click="handleClick">
                                <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :label="item.name">
                                    <el-checkbox-group :max="3" style="font-size: 14px;float:left;margin:5px;" v-model="form.areaIds">
                                        <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{item1.name}}</el-checkbox>
                                    </el-checkbox-group>
                                </el-tab-pane>
                            </el-tabs>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <!-- <el-row>
                    <el-col :span="12" v-if="roleType==5">
                        <el-form-item label="商户:" label-width="20%" prop="merchantId">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.merchantId"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row v-if="roleType==6 &&  isShowShangHu!=1">
                    <el-col :span="12">
                        <el-form-item label="商户:" label-width="27%" prop="merchantId">
                            <el-select :disabled="flag" v-model="form.merchantId" placeholder="请选择商户" style="width: 75.5%">
                                <el-option v-for="item in shopArr" :label="item.merchantName" :key="item.merchantId" :value="item.merchantId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="店铺:" label-width="20%" prop="shopName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.shopName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-col :span="12" v-if="roleType==6 &&  isShowShangHu==1"> -->
                <!-- <el-form-item label="商户:" label-width="27%" prop="merchantId">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="isMerchantId"></el-input> -->
                <!-- <el-select :disabled="flag" v-model="form.merchantId" placeholder="请选择商户" style="width: 75.5%">
                                <el-option v-for="item in shopArr" :label="item.merchantName" :key="item.merchantId" :value="item.merchantId"></el-option>
                            </el-select> -->
                <!-- </el-form-item> -->
                <!-- </el-col> -->

                <!-- </el-row>
                <el-row> -->
                <el-row v-if="roleType==6 &&  isShowShangHu==1">
                    <el-col :span="12">
                        <el-form-item label="店铺:" label-width="27%" prop="shopName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.shopName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税号:" label-width="20%" prop="companyTaxNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyTaxNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人名:" label-width="27%" prop="bossName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossName"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="企业名称:" label-width="20%" prop="companyName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人身份证号:" label-width="27%" prop="bossIdCardNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossIdCardNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="法人手机号:" label-width="20%" prop="bossMobile">
                            <el-input :disabled="flag" style="width: 75.5%" :maxlength="11" v-model="form.bossMobile"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="法人银行名称:" label-width="27%" prop="bossBankName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossBankName"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="法人银行卡号 :" label-width="20%" prop="bossBankNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.bossBankNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="企业开户行名称:" label-width="27%" prop="companyBankName">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyBankName"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12">
                        <el-form-item label="开户行账号:" label-width="20%" prop="companyBankNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyBankNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="isShowShangHu!=1">
                        <el-form-item label="税号:" label-width="27%" prop="companyTaxNo">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.companyTaxNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- </el-row>
                <el-row> -->
                    <el-col :span="12" v-if="num1== 1">
                        <el-form-item label="是否可用:" label-width="20%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 45%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="营业执照:" label-width="27%" prop="businessLicensePicId">
                            <span class="redStar" style="margin-left: -87px;">*</span>
                            <el-upload :class="{isHide:hide}" :disabled="flag" style="margin-top: -22px;" :action="UploadPics" :limit="1" :on-exceed='pictureLength' :file-list='picture' list-type="picture-card" :on-success="pictureSuccess" :headers="MyHeader" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="27%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="27%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="13.5%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import commonJs from "../../assets/js/common.js" //共通js方法
export default {
    inject: ["reload"],
    data() {
        var inputPhone = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入法人手机号"))
                this.loading = false
            } else if (!commonJs.numberPhone(value)) {
                callback(new Error("格式不符，请填写正确的手机号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        var IdcardNo = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入法人身份证号"))
            } else if (!commonJs.shenfenZheng(value)) {
                callback(new Error("格式不符，请填写正确的身份证号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        var TaxNo = (rule, value, callback) => {
            if (value === undefined) {
                callback(new Error("请输入税号"))
            } else if (!commonJs.suiHao(value)) {
                callback(new Error("格式不符，请填写正确的税号!"))
                this.loading = false
            } else {
                callback()
            }
        }
        // 银行卡号
        var bankAccountValid = (rule, value, callback) => {
            const strBin =
                "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99"
            if (!value) {
                return callback(new Error("收款账户不能为空"))
            } else if (!Number.isInteger(+value)) {
                callback(new Error("银行卡号必须全为数字"))
            } else if (value.trim().length < 12 || value.trim().length > 19) {
                callback(new Error("银行卡号长度必须在12到19之间"))
            } else if (strBin.indexOf(value.substring(0, 2)) === -1) {
                callback(new Error("银行卡号开头6位不符合规范"))
            } else {
                callback()
            }
        }
        return {
            hide: false,
            num1: "",
            daQuInclude: [],
            areaIds: "",
            list: "",

            num: this.$route.query.num,
            shopId: this.$route.query.shopId,
            name: "",
            flag: false,
            MyHeader: { currentRole: "", accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            isShowShangHu: this.$route.query.isShowShangHu,
            isMerchantId: this.$route.query.isMerchantId,
            form: {
                shopName: "",
                enabled: "0",
                merchantId: "",
                merchantName: "",
                // 法人名
                bossName: "",
                // 企业名称
                companyName: "",
                // 税号
                companyTaxNo: "",
                // 法人身份证号
                bossIdCardNo: "",
                // 法人手机号
                bossMobile: "",
                // 银行账户
                bossBankName: "",
                // 法人银行卡号
                bossBankNo: "",
                //  开户行名称
                companyBankName: "",
                // 开户行账号
                companyBankNo: "",
                // 营业执照
                businessLicensePicId: ""
            },
            merchantName: "",
            merchantId: "",
            roleType: "",
            diseaseId: [],
            currentPage: 1,
            pageSize: 10,
            rules: {
                shopName: [
                    {
                        required: true,
                        message: "请选择店铺",
                        trigger: "blur"
                    }
                ],
                merchantName: [
                    {
                        required: true,
                        message: "请输入商户",
                        trigger: "blur"
                    }
                ],
                merchantId: [
                    {
                        required: true,
                        message: "请输入商户",
                        trigger: "blur"
                    }
                ],
                // 法人名
                bossName: [
                    {
                        required: true,
                        message: "请输入法人名",
                        trigger: "blur"
                    }
                ],
                // 企业名称
                companyName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur"
                    }
                ],
                // 税号
                companyTaxNo: [
                    {
                        required: true,
                        validator: TaxNo,
                        trigger: "blur"
                    }
                ],
                // 法人身份证号
                bossIdCardNo: [
                    {
                        required: true,
                        validator: IdcardNo,
                        trigger: "blur"
                    }
                ],
                // 法人手机号
                bossMobile: [
                    {
                        required: true,
                        validator: inputPhone,
                        trigger: "blur"
                    }
                ],
                // 法人银行名称
                bossBankName: [
                    {
                        required: true,
                        message: "请输入法人银行名称",
                        trigger: "blur"
                    }
                ],
                // 法人银行卡号
                bossBankNo: [
                    {
                        required: true,
                        validator: bankAccountValid,
                        trigger: "blur"
                    }
                ],
                //  开户行名称
                companyBankName: [
                    {
                        required: true,
                        message: "请输入企业开户行名称",
                        trigger: "blur"
                    }
                ],
                // 开户行账号
                companyBankNo: [
                    {
                        required: true,
                        message: "请输入开户行账号",
                        trigger: "blur"
                    }
                ]
                // 营业执照
                // businessLicensePicId: [
                //     {
                //         required: true,
                //         message: "请上传营业执照",
                //         trigger: "blur"
                //     }
                // ]
            },
            regionList1: [
                {
                    name: "",
                    includeShangQuan: [{ name: "", areaIds: "" }]
                }
            ],
            checkedAreaType: [], //商圈选中
            shopArr: [],
            UploadPics: this.$url.UploadOthers,
            dialogImageUrl: "",
            dialogVisible: false,
            picture: [
                // {
                //     url:
                //         this.$url.othersPicSrc +
                //         "3912541d455e4f218d4e4610066b8781.jfif"
                // }
            ],
            pictureId: "",
            labelWidth: ""
        }
    },
    watch: {
        "form.bossIdCardNo": {
            handler(val) {
                console.log(val === undefined)
            }
        }
    },
    created() {
        this.merchantId = localStorage.getItem("merchantId")
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        if (this.roleType === "6") {
            this.getMerchantList()
        }

        this.$commonJs.getShuJuZiDianList("SHANGQUAN").then((data) => {
            this.regionList1 = data.shangquan
            // console.log(this.regionList1)
        })
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "店铺新增"
            this.form = {}
        } else if (this.num == 1) {
            this.name = "店铺详情"
            this.flag = true
            this.num1 = 1

            this.getShopDetail()
        } else if (this.num == 2) {
            this.name = "店铺修改"
            this.getShopDetail()
        }
        if (this.isShowShangHu == 1) {
            this.labelWidth = "27%"
        } else {
            this.labelWidth = "20%"
        }
        console.log(this.isShowShangHu != 1)
    },
    methods: {
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.isShowShangHu == 1) {
                        const data = {
                            shopName: this.form.shopName,
                            areaIds: this.checkedAreaType.join(","), //商圈id
                            merchantId: this.isMerchantId,
                            companyName: this.form.companyName,
                            companyTaxNo: this.form.companyTaxNo,
                            companyBankNo: this.form.companyBankNo,
                            companyBankName: this.form.companyBankName,
                            bossName: this.form.bossName,
                            bossIdCardNo: this.form.bossIdCardNo,
                            bossMobile: this.form.bossMobile,
                            bossBankNo: this.form.bossBankNo,
                            bossBankName: this.form.bossBankName,
                            businessLicensePicId: this.pictureId
                        }
                        console.log(this.checkedAreaType)
                        console.log(this.checkedAreaType.join(","))
                        if (this.checkedAreaType.join(",") === "") {
                            return this.$message.error("请选择所属商圈")
                        }
                        if (this.form.businessLicensePicId == "") {
                            return this.$message.error("请上传营业执照")
                        }
                        const { data: res } = await this.$http.post(
                            this.$url.InsertShop,
                            data
                        )
                        if (res.msgCode == 1) {
                            this.$router.go(-1)
                        } else {
                            this.$message.success(res.message)
                        }
                    } else {
                        const data = {
                            shopName: this.form.shopName,
                            areaIds: this.checkedAreaType.join(","), //商圈id
                            merchantId: this.form.merchantId,
                            companyName: this.form.companyName,
                            companyTaxNo: this.form.companyTaxNo,
                            companyBankNo: this.form.companyBankNo,
                            companyBankName: this.form.companyBankName,
                            bossName: this.form.bossName,
                            bossIdCardNo: this.form.bossIdCardNo,
                            bossMobile: this.form.bossMobile,
                            bossBankNo: this.form.bossBankNo,
                            bossBankName: this.form.bossBankName,
                            businessLicensePicId: this.pictureId
                        }
                        console.log(this.checkedAreaType)
                        console.log(this.checkedAreaType.join(","))
                        if (this.checkedAreaType.join(",") === "") {
                            return this.$message.error("请选择所属商圈")
                        }
                        if (this.form.businessLicensePicId == "") {
                            return this.$message.error("请上传营业执照")
                        }
                        const { data: res } = await this.$http.post(
                            this.$url.InsertShop,
                            data
                        )
                        if (res.msgCode == 1) {
                            this.$router.go(-1)
                        } else {
                            this.$message.success(res.message)
                        }
                    }
                }
            })
        },
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                var that = this
                if (valid) {
                    const data = {}
                    if (this.roleType === "6") {
                        ;(data.shopId = that.shopId),
                            (data.shopName = that.form.shopName),
                            (data.areaIds = that.checkedAreaType.join(",")), //商圈id
                            (data.merchantId = that.form.merchantId)
                        ;(data.companyName = this.form.companyName),
                            (data.companyTaxNo = this.form.companyTaxNo),
                            (data.companyBankNo = this.form.companyBankNo),
                            (data.companyBankName = this.form.companyBankName),
                            (data.bossName = this.form.bossName),
                            (data.bossIdCardNo = this.form.bossIdCardNo),
                            (data.bossMobile = this.form.bossMobile),
                            (data.bossBankNo = this.form.bossBankNo),
                            (data.bossBankName = this.form.bossBankName),
                            (data.businessLicensePicId = this.pictureId)
                    }
                    if (this.roleType === "5") {
                        ;(data.shopId = that.shopId),
                            (data.shopName = that.form.shopName),
                            (data.areaIds = that.checkedAreaType.join(",")) //商圈id
                    }
                    if (this.checkedAreaType.join(",") === "") {
                        return that.$message.error("请选择所属商圈")
                    }
                    if (this.pictureId == "") {
                        return this.$message.error("请上传营业执照")
                    }
                    that.$http.put(that.$url.UpdateShop, data).then((res) => {
                        if (res.data.msgCode == 1) {
                            // that.$message.success("修改店铺成功")
                            // that.$router.push({
                            //     path: "/DianPuList"
                            // })
                            that.$router.go(-1)
                        } else {
                            that.$message.success(res.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        async getShopDetail() {
            this.$http
                .get(this.$url.GetShopDetail, {
                    params: {
                        shopId: this.shopId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        this.checkedAreaType =
                            res.data.dtList[0].areaIds.split(",")
                        // var url = {}

                        if (
                            this.form.businessLicensePicId != null &&
                            this.form.businessLicensePicId != ""
                        ) {
                            console.log(this.form.businessLicensePicId)
                            this.picture.push({
                                name: this.form.businessLicensePicId,
                                url:
                                    this.$url.othersPicSrc +
                                    this.form.businessLicensePicId
                            })
                            this.pictureId = this.form.businessLicensePicId
                            this.hide = true
                        }

                        // this.$message.success("获取详情成功")
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        // 获取商户id
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取列表成功")
                this.shopArr = res.dtList
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        cancel() {
            // this.$router.push({ path: "/DianPuList" })
            this.$router.go(-1)
        },
        handleClick(tab, event) {
            // this.daQuInclude = []
        },
        // 上传图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleRemove(file, fileList) {
            this.pictureId = ""
            this.hide = false
            console.log(file)
            // this.$confirm(`确认移除 ？`).then(() => {
            this.$commonJs.deleteImg(file, "others")
            // })
        },
        pictureSuccess(file) {
            this.pictureId = file.id
            this.hide = true
        },
        pictureLength(file) {
            this.$message.success("最多只能上传一张图片!")
        }
    }
}
</script>

<style scoped>
.fileMargin {
    margin-top: -36px;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less" scoped>
/deep/ .isHide .el-upload--picture-card {
    display: none;
}
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
.el-upload-list {
    width: 20%;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}
// /deep/.business {
//     border: 0 !important;
//     height: 21px !important;
//     line-height: 23px !important;
// }
/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
    // z-index: 1111;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
.business {
    background: #fff;
    // border: 1px solid #ccc;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    margin-left: 2% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/ .el-tabs__nav .is-top {
    margin-left: 5px;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>