<template>
  <div>
    <el-card class="search">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="ZiXunList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
        <el-table-column label="客户姓名" prop="customerName"></el-table-column>
        <el-table-column label="手机号" prop="mobile"></el-table-column>

        <el-table-column label="咨询内容" prop="message"></el-table-column>
        <el-table-column label="是否联系客户">
          <template slot-scope="scope">
            <span v-if="scope.row.contacted == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="roleType == '6'">
          <template slot-scope="scope" class="right">
            <el-button
              @click.native.stop="deleteZiXun(scope.row)"
              type="text"
              size="mini"
              class="el-icon-delete caozuo"
              style="color: #f00"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
          <img
            style="width: 200px;margin-top: 50px;"
            src="../../../public/img/noList.png"
          />
          <p style="font-size: 14px;margin: 0;line-height: 30px;"></p>
        </div>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="ZiXunList.length"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ZiXunList: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
      roleType: ""
    };
  },
  created() {
    this.getZiXunList();
    this.roleType = window.localStorage.getItem("roleType");
    console.log(this.roleType);
  },
  methods: {
    async getZiXunList() {
      const { data: res } = await this.$http.get(
        this.$url.GetcustomerEnquiryList,
        {
          params: {
            currentPage: this.currentPage,
            pageSize: this.pageSize
          }
        }
      );

      if (res.msgCode == 1) {
        this.ZiXunList = res.dtList;
        console.log(this.ZiXunList);
      }
    },
    async deleteZiXun(row) {
      console.log(row);
      const data = [row.customerEnquiryId];
      console.log(data);
      const { data: res } = await this.$http.delete(
        this.$url.DeleteCustomerEnquiry,
        {
          data: data
        }
      );

      if (res.msgCode == 1) {
        // console.log(res)
        this.getZiXunList();
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getZiXunList();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getZiXunList();
    }
  }
};
</script>
<style lang="less" scoped></style>
