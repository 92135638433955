<template>
    <div>
        <el-dialog class="zhiyinDialog" title="在线签约新手指引" :visible.sync="zhiyinDialogVisible" width="38%"
            :before-close="zhiyinDialogClose">
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="zhiyinDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-tabs type="border-card" class="tabs-qianyueList"
            style="height: 25px;position: relative;top: 0px;margin-top: 6px;" v-model="editableTabsValue"
            @tab-click="handleClickTab">
            <el-tab-pane :key="item.name" v-for="(item) in editableTabs" :label="item.title"
                :name="item.name"></el-tab-pane>
        </el-tabs>
        <!-- 中间卡片视图区域 -->
        <el-card id="card" class="search" style="height: auto;margin-top: 11px !important;">
            <el-row class="searchrow">
                <el-col :span="6">
                    <div class="titleLabel width95px">合同编号：</div>
                    <el-input v-model="search.contractNo" placeholder="合同编号" class="media-width"></el-input>
                </el-col>
                <el-col :span="6">
                    <div class="titleLabel width95px">交易类型：</div>
                    <el-select v-model="search.tradeType" placeholder="请选择交易类型" class="media-width">
                        <el-option v-for="item in jiaoyiOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <div class="titleLabel width95px">合同状态：</div>
                    <el-select v-model="search.contractStatus" placeholder="请选择合同状态" class="media-width">
                        <el-option v-for="item in contractOptions" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <div class="titleLabel width95px">房源编号：</div>
                    <el-input v-model="search.houseNo" placeholder="请输入房源编号" class="media-width"></el-input>
                </el-col>
            </el-row>
            <el-row class="searchrow">
                <el-col :span="6">
                    <div class="titleLabel width95px">客户姓名：</div>
                    <el-button size="mini" class="media-width" @click="openKeyuanDialog"
                        style="height:30px;vertical-align: middle;text-align: left;">
                        <span v-if="customerName == '请选择客户'" style="color:#c0c4cc">{{ customerName }}</span>
                        <span v-if="customerName != '请选择客户'">{{ customerName }}</span>
                    </el-button>
                </el-col>
                <el-col v-if="roleType != 1" :span="6">
                    <div class="titleLabel width95px">签约店组：</div>
                    <el-button size="mini" class="media-width" @click="openDianPuDialog"
                        style="height:30px;vertical-align: middle;text-align: left;">
                        <span v-if="shopName == '请选择签约店组'" style="color:#c0c4cc">{{ shopName }}</span>
                        <span v-if="shopName != '请选择签约店组'">{{ shopName }}</span>
                    </el-button>
                </el-col>
                <el-col :span="12">
                    <div class="titleLabel width95px">创建时间：</div>
                    <el-date-picker class="media-datepickerWidth" style="height: 30px;" v-model="createTime"
                        format="yyyy - MM - dd" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false"></el-date-picker>
                </el-col>
            </el-row>
            <el-row v-if="roleType != 1">
                <el-col :span="6">
                    <div class="titleLabel width95px">签约经纪人：</div>
                    <el-button size="mini" class="media-width" @click="openJingJiRenDialog"
                        style="height:30px;vertical-align: middle;text-align: left;">
                        <span v-if="contractUserName == '请选择签约经纪人'" style="color:#c0c4cc">{{ contractUserName }}</span>
                        <span v-if="contractUserName != '请选择签约经纪人'">{{ contractUserName }}</span>
                    </el-button>
                </el-col>
            </el-row>
            <el-row class="searchrow" id="button">
                <span style="float: right;padding-right: 22px;">
                    <el-button size="mini" style="margin-left: 20px" type="primary" @click="searchList">查询</el-button>
                    <el-button size="mini" @click="reset" type="warning">重置</el-button>
                </span>
                <el-button size="mini" v-if="roleType == 1 || roleType == 2 || roleType == 5 || roleType == 6"
                    @click="edit('', '0')" type="primary" icon="el-icon-plus"
                    style="border: none;margin-left: 25px;">创建合同</el-button>
                <el-button size="mini" v-if="roleType == 1 || roleType == 2 || roleType == 5 || roleType == 6"
                    @click="editOffline('', '0')" type="primary" icon="el-icon-plus"
                    style="border: none;margin-left: 25px;">创建线下合同</el-button>
            </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-card>
            <span style="font-size: 1.17em;float: left;font-weight: bold;">合同列表</span>
            <!-- <span v-if="!isCaiWu" style="color: red;font-size: 1.17em;float: right;font-weight: bold;cursor:pointer;" @click="zhiyinDialogVisible=true">在线签约新手指引></span> -->
            <el-table ref="multipleTable" @row-click="xzCaiWu" v-loading="loading" :data="contractList"
                tooltip-effect="dark" :header-cell-style="{
                    'text-align': 'center',
                    color: '#333',
                    'font-size': '14px',
                }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                <!-- <el-table-column type="selection" width="40"></el-table-column> -->
                <el-table-column label="合同编号" prop="contractNo" width="200">
                    <template slot-scope="scope">
                        <el-button class="tableButSpan" v-if="!isCaiWu" @click="edit(scope.row, '1')" type="text"
                            size="mini" style="font-size: 16px; font-weight: bold; color: rgb(0, 0, 0);">{{
                                scope.row.contractNo
                            }}</el-button>
                        <el-button class="tableButSpan" v-if="isCaiWu == true" type="text" size="mini"
                            style="font-size: 16px; font-weight: bold; color: rgb(0, 0, 0);">{{ scope.row.contractNo
                            }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="合同类型" prop="isOnLine">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isOnLine == '0'">线下合同</span>
                        <span v-if="scope.row.isOnLine == '1'">线上合同</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易类型" prop="tradeType">
                    <template slot-scope="scope">
                        <span>{{ scope.row.tradeType }}</span>
                        <el-tooltip content="定转佣" placement="top-start">
                            <img v-if="scope.row.isDingZhuanYong == '1'"
                                style="width:20px;height:20px;position:absolute;padding-top: 1px;padding-left:10px;"
                                src="../../../public/img/dingzhuanyong.png" />
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column label="合同状态" prop="contractStatus"></el-table-column>
                <el-table-column label="房源编号/物业地址" prop="houseNo" width="200">
                    <template slot-scope="scope">
                        <!-- <el-tooltip class="item" effect="dark" :content="scope.row.houseNo" placement="top"> -->
                        <span style="color: red; white-space: nowrap;">{{ scope.row.houseNo }}</span>
                        <!-- </el-tooltip>
                        <br /> -->
                        <span class="more">{{ scope.row.louPanName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="客户姓名" prop="customerName">
                    <template slot-scope="scope">
                        <span>{{ scope.row.customerName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="签约店组" prop="shopName" width="180"></el-table-column>
                <el-table-column label="签约专员" prop="contractUserName">
                    <!-- <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.contractUserName" placement="top">
                            <span style="color: #1268c0;">{{scope.row.contractUserName}}</span>
                        </el-tooltip>
                    </template> -->
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作"
                    v-if="(roleType == 1 || roleType == 2 || roleType == 3 || roleType == 5 || roleType == 6 || roleType == 7) && !isCaiWu">
                    <template slot-scope="scope" class="right">
                        <el-button @click.native.stop="edit(scope.row, '2')"
                            v-if="scope.row.contractStatus != '合同已解约' && (scope.row.contractStatus == '创建合同完成' || scope.row.contractStatus == '合同审核完成' || scope.row.contractStatus == '合同上传完成') && scope.row.isOnLine == 1 && roleType != 3 && roleType != 7"
                            type="text" size="mini" class="el-icon-edit" style="color: #1890ff">修改</el-button>
                        <el-button @click.native.stop="edit(scope.row, '2')"
                            v-if="scope.row.contractStatus != '合同已解约' && (scope.row.contractStatus == '电子签章完成' || scope.row.contractStatus == '付款完成' || scope.row.contractStatus == '过户完成' || scope.row.contractStatus == '交易完结') && scope.row.isOnLine == 1"
                            type="text" size="mini" class="el-icon-view" style="color: #1890ff">查看</el-button>
                        <el-button @click.native.stop="handleDelete(scope.row)"
                            v-if="roleType == 6 && scope.row.contractStatus != '合同已解约' && (scope.row.contractStatus == '创建合同完成' || scope.row.contractStatus == '合同审核完成' || scope.row.contractStatus == '合同上传完成') && scope.row.isOnLine == 1"
                            type="text" size="mini" class="el-icon-document-delete" style="color: #1890ff">解约</el-button>
                        <!-- <el-button
                        ||scope.row.contractStatus!='创建合同完成'||scope.row.contractStatus!='合同审核完成'||scope.row.contractStatus!='合同上传完成'||scope.row.contractStatus!='电子签章完成'
                @click.native.stop="handleDelete(scope.row)"
                type="text"
                 size="mini" 
                class="el-icon-delete"
                style="color: #da0909"
              >删除</el-button>-->
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="search.currentPage" :page-sizes="[10, 20, 50]" :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </el-card>

        <!-- 店铺 -->
        <el-dialog title="店铺" append-to-body :visible.sync="jingJiRenDialog" direction="rtl" width="70%" top="4%"
            class="xuqiuDialog">
            <TongXunLuList :isQianyueOpen='true' @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>

        <!-- 带看房源 -->
        <el-dialog title="带看客源" append-to-body :visible.sync="keyuanDialog" direction="rtl" width="70%" top="4%"
            class="xuqiuDialog">
            <KeYuanList :fangyuanState='search.tradeType' :isGenJin='true' @colseKeyuanDialog="colseKeyuanDialog">
            </KeYuanList>
        </el-dialog>

        <!-- 店铺 -->
        <el-dialog title="店铺" append-to-body :visible.sync="dianpuDialog" direction="rtl" width="70%" top="4%"
            class="xuqiuDialog">
            <DianPuList :isQianyueOpen='true' @colseDianPuDialog="colseDianPuDialog"></DianPuList>
        </el-dialog>
    </div>
</template>

<script>
import DianPuList from "../../components/DianPu/DianPuList.vue"
import KeYuanList from "../../components/KeYuan/KeYuanList.vue"
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue"
import QianYueJs from "./QianYue.js"

export default {
    name: "GetQianYueList",
    inject: ["reload"],
    components: {
        TongXunLuList,
        DianPuList,
        KeYuanList
    },
    data() {
        return {
            roleType: localStorage.getItem("roleType"),
            // pickerOptions: {
            //     disabledDate(time) {
            //         return time.getTime() < Date.now() - 3600 * 1000 * 24 * 91
            //     }
            // },
            showAll: false,
            loading: false,
            total: 0,
            date: ["", ""],
            createTime: ["", ""],
            jingJiRenDialog: false,
            contractUserName: "请选择签约经纪人",
            keyuanDialog: false,
            customerName: "请选择客户",
            dianpuDialog: false,
            shopName: "请选择签约店组",
            search: {
                contractNo: "",
                houseNo: "",
                userId: "", // 签约经纪人
                contractStatus: "", //合同状态
                customerName: "",
                shopName: "",
                tradeType: "", //交易类型 (买卖或者租赁)
                createTimeFrom: "",
                createTimeTo: "",
                currentPage: 1,
                pageSize: 10
            },
            contractList: [],
            articleTitle: "",
            enabled: "",
            jiaoyiOptions: QianYueJs.jiaoyiOptions,
            contractOptions: QianYueJs.contractOptions,
            editableTabsValue: "1",
            editableTabs: QianYueJs.editableTabs,
            tabIndex: 2,
            zhiyinDialogVisible: false,
            qianYueParams: JSON.parse(window.localStorage.getItem("qianYueParams")),
        }
    },
    props: {
        isCaiWu: {
            type: Boolean,
            default: false
        },
        tradeType: {
            type: String,
            default: ""
        }
    },
    created() {
        if (this.qianYueParams) {
            this.search = this.qianYueParams
        }
        const createTimeFrom = new Date()
        const createTimeTo = new Date()
        createTimeFrom.setTime(createTimeFrom.getTime() - 3600 * 1000 * 24 * 90)
        this.date[0] = this.$commonJs
            .dateTimeFormatter(createTimeFrom)
            .split("T")[0]
        this.date[1] = this.$commonJs
            .dateTimeFormatter(createTimeTo)
            .split("T")[0]
        this.createTime = this.date
        this.search.createTimeFrom = this.createTime[0]
        this.search.createTimeTo = this.createTime[1] + " 23:59:59"
        this.getContractList()
    },
    watch: {
        createTime(minDate) {
            if (minDate != null) {
                this.search.createTimeFrom = minDate[0]
                this.search.createTimeTo = minDate[1] + " 23:59:59"
            } else {
                this.search.createTimeFrom = ""
                this.search.createTimeTo = ""
            }
        }
    },
    computed: {
        word: function () {
            if (this.showAll == false) {
                //对文字进行处理
                return "收起搜索"
            } else {
                return "展开搜索"
            }
        }
    },
    mounted() {
        /**
         * 收起搜索
         */
        // this.$nextTick(function() {
        //     this.closeSearch()
        // })
    },
    methods: {
        handleClickTab(tab, event) { },
        // 查询
        searchList() {
            this.getContractList()
        },
        edit(row, state) {
            let name = ""
            if (state == 0 || state == 2) {
                name = "AddQianYue"
            } else {
                name = "QianYueDetail"
            }
            this.$router.push({
                name: name,
                query: {
                    contractId: row.contractId,
                    state: state
                }
            })
        },
        editOffline(row, state) {
            this.$router.push({
                name: 'AddQianYueOffline',
                query: {
                    contractId: row.contractId,
                    state: state
                }
            })
        },
        zhiyinDialogClose(done) {
            done()
        },
        xzCaiWu(row) {
            if (this.isCaiWu) {
                this.$emit("colseHetongDialog", row)
            }
        },
        terminate(row) {
            this.$confirm("是否确定解约此合同？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    // this.financialId = row.financialId
                    // let param = {
                    //     financialId: this.financialId
                    // }
                    // this.$http
                    //     .post(this.$url.ConfirmFinancial, param, {
                    //         headers: {
                    //             "Content-Type": "application/json;charset=UTF-8"
                    //         }
                    //     })
                    //     .then((res) => {
                    //         if (res.data.msgCode == 1) {
                    //             this.$message.success("审批成功")
                    //             this.reload()
                    //         } else {
                    //             this.$message.error("审批失败")
                    //         }
                    //     })
                })
                .catch((err) => err)
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getContractList() {
            this.loading = true
            await this.$http
                .get(this.$url.GetContractList, {
                    params: this.search
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            res.data.dtList.map((item) => {
                                item.tradeType =
                                    item.tradeType == "1" ? "买卖" : "租赁"
                                if (item.contractStatus == "10") {
                                    item.contractStatus = "创建合同完成"
                                } else if (item.contractStatus == "20") {
                                    item.contractStatus = "合同审核完成"
                                } else if (item.contractStatus == "30") {
                                    item.contractStatus = "合同上传完成"
                                } else if (item.contractStatus == "40") {
                                    item.contractStatus = "电子签章完成"
                                } else if (item.contractStatus == "50") {
                                    item.contractStatus = "付款完成"
                                } else if (item.contractStatus == "60") {
                                    item.contractStatus = "过户完成"
                                } else if (item.contractStatus == "70") {
                                    item.contractStatus = "交易完结"
                                } else if (item.contractStatus == "90") {
                                    item.contractStatus = "合同已解约"
                                }
                                // if(item.tradeType == "1"){
                                //     item.tradeType = "买卖";
                                //     if (item.contractStatus == "10") {
                                //     item.contractStatus = "创建合同完成"
                                //     } else if (item.contractStatus == "20") {
                                //         item.contractStatus = "合同上传完成"
                                //     } else if (item.contractStatus == "30") {
                                //         item.contractStatus = "电子签章完成"
                                //     } else if (item.contractStatus == "40") {
                                //         item.contractStatus = "付款完成"
                                //     } else if (item.contractStatus == "50") {
                                //         item.contractStatus = "过户完成"
                                //     } else if (item.contractStatus == "60") {
                                //         item.contractStatus = "交易完结"
                                //     }
                                // } else if (item.tradeType == "0") {
                                //     item.tradeType = "租赁";
                                //     if (item.contractStatus == "10") {
                                //     item.contractStatus = "创建合同完成"
                                //     } else if (item.contractStatus == "20") {
                                //         item.contractStatus = "合同上传完成"
                                //     } else if (item.contractStatus == "30") {
                                //         item.contractStatus = "电子签章完成"
                                //     } else if (item.contractStatus == "40") {
                                //         item.contractStatus = "付款完成"
                                //     } else if (item.contractStatus == "50") {
                                //         item.contractStatus = "交易完结"
                                //     }
                                // }
                            })
                            this.contractList = res.data.dtList
                        } else {
                            this.contractList = []
                        }
                        this.loading = false
                        this.total = res.data.maxRecords
                        localStorage.setItem("qianYueParams", JSON.stringify(this.search));
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.search.pageSize = newSize
            this.getContractList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.search.currentPage = newPage
            this.getContractList()
        },
        //重置
        reset() {
            this.customerName = "请选择客户"
            this.shopName = "请选择签约店组"
            this.contractUserName = "请选择签约经纪人"
            this.search = {
                contractNo: "",
                houseNo: "",
                userId: "", // 签约经纪人
                contractStatus: "", //合同状态
                customerName: "",
                shopName: "",
                tradeType: "", //交易类型 (买卖或者租赁)
                createTimeFrom: "",
                createTimeTo: "",
                currentPage: 1,
                pageSize: 10
            }
            this.createTime = this.date
            this.search.createTimeFrom = this.date[0]
            this.search.createTimeTo = this.date[1] + " 23:59:59"
            this.getContractList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.contractId]
            this.$confirm("此操作将解约该合同, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteContract, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "解约成功!"
                                })
                                this.getContractList()
                                // this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => err)
        },
        // 删除数据
        // async handleDelete1() {
        //     var data1 = this.multipleSelection
        //     var data2 = []
        //     if (this.multipleSelection == undefined) {
        //         this.$confirm("请勾选数据", "提示", {
        //             type: "warning"
        //         })
        //     } else {
        //         this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        //             confirmButtonText: "确定",
        //             cancelButtonText: "取消",
        //             type: "warning"
        //         })
        //             .then(() => {
        //                 for (var i = 0; i < data1.length; i++) {
        //                     data2.push(data1[i].articleId)
        //                 }
        //                 const { data: res } = this.$http.delete(
        //                     this.$url.deleteArticle,
        //                     {
        //                         data: data2,
        //                         headers: {
        //                             "Content-Type":
        //                                 "application/json;charset=UTF-8"
        //                         }
        //                     }
        //                 )
        //                 if (res.data.msgCode == 1) {
        //                     this.$message({
        //                         type: "success",
        //                         message: "删除成功!"
        //                     })
        //                     this.reload()
        //                 } else {
        //                     this.$message.success(res.message)
        //                 }
        //             })
        //             .catch(err => {
        //                 this.$message({
        //                     type: "error",
        //                     message: err
        //                 })
        //             })
        //     }
        // },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "InsertArticle",
                    query: {
                        articleId: aa[0].articleId,
                        state: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        },

        openJingJiRenDialog() {
            this.jingJiRenDialog = true
        },
        colseJingJiRenDialog(jingJiRenData) {
            this.contractUserName = jingJiRenData.userName
            this.search.userId = jingJiRenData.userId
            this.jingJiRenDialog = false
        },

        openKeyuanDialog() {
            this.keyuanDialog = true
        },
        colseKeyuanDialog(keyuanData) {
            this.customerName = keyuanData.customerName
            this.search.customerName = keyuanData.customerName
            this.keyuanDialog = false
        },

        openDianPuDialog() {
            this.dianpuDialog = true
        },
        colseDianPuDialog(dianPuData) {
            this.shopName = dianPuData.shopName
            this.search.shopName = dianPuData.shopName
            this.dianpuDialog = false
        }
    }
}
</script>

<style lang="less" scoped>
.xuqiuDialog /deep/ .el-dialog__body {
    padding: 10px 20px;
}

.el-card {
    margin-top: 15px;
}

.more {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.search {
    /deep/ .el-card__body {
        padding-bottom: 0 !important;
    }

    .searchrow {
        margin: 8px 0;
    }

    .width95px {
        width: 95px;
    }
}

.zhiyinDialog {

    // /deep/ .el-dialog__body {
    //     padding: 0px 20px;
    //     color: #000;
    //     .hegui-div {
    //         margin-bottom: 10px;
    //     }
    // }
    /deep/ .el-dialog__header {
        border-bottom: 1px solid #ddd;
    }
}</style>
