<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ name }}</span>
      <span style="float: right">
        <el-button v-if="num != 0" @click="handleCommand('weihu')" size="mini" class="el-button1" type="primary">维护</el-button>
        <el-button v-if="num != 0" @click="handleCommand('shikan')" size="mini" class="el-button1" type="primary">实勘</el-button>
        <el-button v-if="num != 0" @click="handleCommand('yaoshi')" size="mini" class="el-button1" type="primary">钥匙</el-button>
        <el-button v-if="num != 0" @click="handleCommand('zhengjian')" size="mini" class="el-button1" type="primary">证件</el-button>
        <el-button
          v-if="num == 1 && ((roleType == 1 && (userid == weihurenId || (localShopId == shopId && enabled == '0'))) || (roleType == 2 && canShopEdit == 1) || (roleType == 5 && canMerchantEdit == 1) || roleType == 6 || roleType == 9)"
          @click="handleCommand('yezhu')"
          size="mini"
          class="el-button1"
          type="primary"
          >业主信息</el-button
        >
        <el-button
          v-if="(num == 1 && roleType == 1 && userid == weihurenId && enabled == '1') || (num == 1 && roleType == 2 && canShopEdit == 1 && enabled == '1') || (num == 1 && roleType == 5 && canMerchantEdit == 1 && enabled == '1') || (num == 1 && roleType == 6 && enabled == '1')"
          @click="handleGetXiuGai()"
          size="mini"
          style="margin-right: 10px"
          class="el-button1"
          type="warning"
          >修改</el-button
        >
        <el-button v-if="num != 0" size="mini" style="margin-right: 10px" class="el-button1" type="warning" @click="DaYindialogVisible = true">打印</el-button>
        <el-button v-if="ruleForm.menPaiHao != '' && num != 0" size="mini" style="margin-right: 10px" class="el-button1" type="warning" @click="addGenJin">新增跟进</el-button>
        <el-button size="mini" style="margin-left: 5px" class="el-button1" type="warning" @click="goBack()">返回</el-button>
      </span>
    </el-card>

    <el-card v-if="num != 0">
      <el-row
        type="flex"
        justify="end"
        style="
          height: 40px;
          margin-top: 5px;
          margin-bottom: 5px;
          margin-right: 10px;
        "
      >
        <h1 style="margin: 0; position: absolute; left: 5px">
          {{ ruleForm.louPanName + ruleForm.houseNo }}
          <img v-if="ruleForm.isJiShou == true" style="width:20px;height:20px;padding-right:10px" src="../../../public/img/ji.png" />
        </h1>
        <div v-if="roleType == 2 || roleType == 5 || roleType == 6">
          <el-button v-if="ruleForm.isChengJiaoToSell == 1" size="mini" type="primary" @click="chengjiao()">设置为未成交</el-button>
        </div>
        <div v-if="((roleType == 2 && canShopEdit == 1) || (roleType == 2 && isShenHe == 1) || (roleType == 5 && canMerchantEdit == 1) || (roleType == 5 && isShenHe == 1) || roleType == 6) && isConfirmed != 1">
          <el-button size="mini" type="primary" @click="reject()">审核不通过</el-button>
          <el-button size="mini" type="primary" @click="examine()">审核通过</el-button>
          <el-button size="mini" type="warning" @click="goBack()">取消</el-button>
        </div>
      </el-row>
    </el-card>

    <nav v-if="num == 1" style="position: fixed; right: 30px; top: 300px; z-index: 999">
      <el-button class="nav1" :class="[jumpType == 'shikanxinxi' ? 'nav1-active' : '']" type="warning" @click="jump('shikanxinxi')">实勘</el-button>
      <el-button v-if="ruleForm.maintenanceInfo != null" class="nav1" :class="[jumpType == 'weihuxinxi' ? 'nav1-active' : '']" type="warning" @click="jump('weihuxinxi')">维护</el-button>
      <el-button class="nav1" :class="[jumpType == 'jichuxinxi' ? 'nav1-active' : '']" type="warning" @click="jump('jichuxinxi')">基础</el-button>
      <el-button class="nav1" :class="[jumpType == 'jingjirendianping' ? 'nav1-active' : '']" type="warning" @click="jump('jingjirendianping')">点评</el-button>
      <el-button class="nav1" :class="[jumpType == 'yaoshixinxi' ? 'nav1-active' : '']" type="warning" @click="jump('yaoshixinxi')">钥匙</el-button>
      <el-button class="nav1" :class="[jumpType == 'zhengjianxinxi' ? 'nav1-active' : '']" type="warning" @click="jump('zhengjianxinxi')">证件</el-button>
      <el-button class="nav1" :class="[jumpType == 'jueserenxinxi' ? 'nav1-active' : '']" type="warning" @click="jump('jueserenxinxi')">角色</el-button>
      <el-button class="nav1" @click="openAddress" size="mini" type="warning">地址</el-button>
    </nav>
    <HaiBaoList style="display: none" v-if="isDaYin" :tupian="dayinUrl" :huxingList="ruleForm.huxingList" :ruleForm="ruleForm" ref="HaiBaoList"></HaiBaoList>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="right" label-width="20%">
      <el-card v-if="num == '1'">
        <el-row>
          <el-col :span="8">
            <el-carousel
              style="
                width: 100%;
                height: 18vw;
                margin-top: 15px;
                text-align: center;
              "
            >
              <div v-if="allShiKanPics.length > 0">
                <el-carousel-item v-for="item in allShiKanPics" :key="item">
                  <el-image style="width: 80%; height: 17.5vw; margin: 0 10px" :src="item"> </el-image>
                </el-carousel-item>
              </div>
              <img v-if="allShiKanPics.length == 0" src="../../../public/img/zanwutupian.jpg" style="width: 80%; height: 17.5vw" />
            </el-carousel>
          </el-col>
          <el-col :span="16">
            <div style="margin-left: 10px">
              <h1
                style="
                  margin-top: 0px;
                  color: #df0202;
                  font-size: 28px;
                  padding: 1.5vw 0px 0.5vw;
                "
                class="title"
              >
                <span @mouseenter="priceShow" class="contant"> {{ ruleForm.priceToSell }}万</span>

                <!-- <span class="contant"></span> -->
                <span class="contantHouseNo">{{ ruleForm.houseNo }}</span>
              </h1>
              <hr
                style="
                  height: 1px;
                  border: none;
                  backgroundcolor: #ccc;
                  margin-top: 0px;
                  margin-bottom: 0px;
                "
              />
              <el-row style="padding: 0.3vw 0">
                <el-col style="text-align: center; line-height: 2.1vw" :span="5">
                  <span style="font-weight: 600">单价</span><br />
                  <span>{{ ruleForm.unitPrice / 10000 }}万/平</span>
                </el-col>
                <el-col style="text-align: center; line-height: 2.1vw" :span="5">
                  <span style="font-weight: 600">户型</span><br />
                  <span>{{ ruleForm.huxing }}</span>
                </el-col>
                <el-col style="text-align: center; line-height: 2.1vw" :span="5">
                  <span style="font-weight: 600">面积</span><br />
                  <span>{{ ruleForm.square }}㎡</span>
                </el-col>
                <el-col style="text-align: center; line-height: 2.1vw" :span="5">
                  <span style="font-weight: 600">朝向</span><br />
                  <span>{{ ruleForm.orientations }}</span>
                </el-col>
                <el-col style="text-align: center; line-height: 2.1vw" :span="4">
                  <span style="font-weight: 600">楼层</span><br />
                  <span>
                    <!-- 不能查看楼层 -->
                    <span v-if="!ruleForm.louceng1" style="color: red; cursor: pointer">{{ ruleForm.louceng }}</span>
                    <!-- 可以查看楼层 -->
                    <span v-if="ruleForm.louceng1 && !loucengtop" @click="loucengtop = true" style="color: red; cursor: pointer">{{ ruleForm.louceng1 }}</span>
                    <span v-if="ruleForm.louceng1 && loucengtop" @click="loucengtop = false" style="color: red; cursor: pointer">{{ ruleForm.louceng }}</span>
                    <span>/{{ ruleForm.floorCount }}</span>
                  </span>
                </el-col>
              </el-row>
              <hr
                style="
                  height: 1px;
                  border: none;
                  backgroundcolor: #ccc;
                  margin-top: 0px;
                  margin-bottom: 0px;
                "
              />
              <el-row style="margin-top: 1vw">
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="最近可看房时间:" label-width="35%">
                    <span>{{ ruleForm.time == null ? '暂无信息' : ruleForm.time }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="挂牌时间:" label-width="35%">
                    <span>{{ ruleForm.createTime }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="房源等级:" label-width="35%">
                    <span>{{ ruleForm.maintenanceInfo == null ? '暂无信息' : ruleForm.maintenanceInfo.fangyuandengji }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="装修状况:" label-width="35%">
                    <span>{{ zhuangxiuContant }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="信息来源:" label-width="35%">
                    <span>{{ ruleForm.laiyuanName == '' ? '暂无信息' : ruleForm.laiyuanName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="维护人:" label-width="35%">
                    <span style="color: red">{{ userName1 }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="钥匙信息:" label-width="35%">
                    <span @click="jump('yaoshixinxi')" style="color: red; cursor: pointer">查看</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="weihu" style="height: 1.8vw; line-height: 1.5vw">
                  <el-form-item label="证件信息:" label-width="35%">
                    <span @click="jump('zhengjianxinxi')" style="color: red; cursor: pointer">查看</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '1'" id="shikanxinxi">
        <h4 style="margin-top: 0px; margin-bottom: 0px">实勘信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <div class="demo-image__preview">
              <el-form-item label="户型图:" label-width="11.5%">
                <div v-if="ruleForm.huxingList.length > 0">
                  <el-image style="width: 125px; height: 100px; margin: 0 10px" v-for="url in ruleForm.huxingList" :key="url" :src="url" :preview-src-list="ruleForm.huxingList"> </el-image>
                </div>
                <img v-if="ruleForm.huxingList.length == 0" src="../../../public/img/zanwutupian.jpg" style="width: 125px; height: 100px; margin: 0 10px" />
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <div class="demo-image__preview">
              <el-form-item label="实勘图:" label-width="11.5%">
                <div v-if="shiKanPics.length > 0">
                  <el-image style="width: 125px; height: 100px; margin: 0 10px" v-for="url in shiKanPics" :key="url" :src="url" :preview-src-list="shiKanPics"> </el-image>
                </div>
                <img v-if="shiKanPics.length == 0" src="../../../public/img/zanwutupian.jpg" style="width: 125px; height: 100px; margin: 0 10px" />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num != '1'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">填写物业信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="楼盘名称:" label-width="20%" prop="louPanName">
              <el-button
                size="mini"
                class="buttonInput button_tanchu"
                :class="[num != '0' ? 'disabledButton' : '']"
                @click="loupanDialog = true"
                :disabled="num != '0'"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span v-if="louPanName == '请选择楼盘'" style="color: #c0c4cc">{{ louPanName }}</span>
                <span v-if="louPanName != '请选择楼盘'">{{ louPanName }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼栋:" label-width="20%" prop="louDongId">
              <el-select :disabled="num != '0'" no-data-text="请先选择楼盘" v-model="ruleForm.louDongId" style="width: 60%" @change="changeLouDong" placeholder="请选择栋座">
                <el-option v-for="item in dongzuoList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="单元:" label-width="20%" prop="danyuan">
              <el-select :disabled="num != '0'" no-data-text="请先选择楼栋" v-model="ruleForm.danyuan" style="width: 60%" @change="changeDanYuan" placeholder="请选择单元">
                <el-option v-for="(item, i) in danyuanArray" :key="i" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼层:" label-width="20%" prop="louceng">
              <el-select :disabled="num != '0'" no-data-text="请先选择单元" v-model="ruleForm.louceng" style="width: 60%" @change="changeLouCeng" placeholder="请选择楼层">
                <el-option v-for="item in loucengList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="门牌:" label-width="20%" prop="menPaiHao">
              <el-select :disabled="num != '0'" no-data-text="请先选择楼层" v-model="ruleForm.menPaiHao" style="width: 60%" placeholder="请选择门牌号">
                <el-option v-for="item in menpaiArray" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="10%">
              <span class="el-icon-warning-outline" style="color: #ccc"
                >注:如果找不到要录入的房屋地址,可点击<span
                  style="
                    color: red;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                  @click="applyInsert"
                  >"新建地址"</span
                >进行申请</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="num == 0">
            <el-button size="mini" type="primary" style="margin-left: 86px" @click="inspect">检查重复</el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num != '1'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">填写业主信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="业主姓名" label-width="20%" prop="name">
              <el-input placeholder="请输入业主姓名" maxlength="4" :disabled="yezhudetal" v-model="ruleForm.name" style="width: 60%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信" label-width="20%">
              <el-input placeholder="请输入业主微信号" :disabled="yezhudetal" v-model="ruleForm.weChatNo" style="width: 60%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号码" label-width="20%" prop="tel1">
              <!-- <el-input placeholder="请输入手机号码" :disabled="yezhudetal" v-model="ruleForm.tel1" @blur="GetCheckTelExists(ruleForm.tel1,'tel1Check')" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%;"></el-input> -->
              <el-input placeholder="请输入手机号码" :disabled="yezhudetal" v-model="ruleForm.tel1" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%"></el-input>
              <span class="el-icon-circle-plus-outline" v-if="show == false" @click="show = true" style="margin-left: 10px; color: red; font-size: 18px"></span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="身份证号" label-width="20%" prop="idCardNo">
              <el-input placeholder="请输入身份证号" :disabled="yezhudetal" v-model="ruleForm.idCardNo" maxlength="18" style="width: 60%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="show == true">
          <el-col :span="12">
            <el-form-item label="其他手机" label-width="20%" prop="tel2">
              <!-- <el-input placeholder="请输入其他手机号" :disabled="yezhudetal" v-model="ruleForm.tel2" @blur="GetCheckTelExists(ruleForm.tel2,'tel2Check')" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%;"></el-input> -->
              <el-input placeholder="请输入其他手机号" :disabled="yezhudetal" v-model="ruleForm.tel2" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%"></el-input>
              <span class="el-icon-remove-outline" @click="show = false" style="margin-left: 10px; color: red; font-size: 18px"></span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card v-if="num != '1'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">填写房源信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="12" class="margin-left12">
            <el-form-item label="户型:" prop="huxing">
              <el-input :disabled="flag" type="input" style="width: 60%" placeholder="请输入室户型(例：1-1-1-1 / 室-厅-卫-厨)" v-model="ruleForm.huxing"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item style="margin: 0px 0 10px" label="面积:" prop="square">
              <el-input :disabled="flag" type="input" style="width: 60%" :rows="1" placeholder="请输入面积" v-model="ruleForm.square" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999"></el-input>
              <span style="margin-left: 7px">m²</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="margin-left12">
            <el-form-item style="margin: 0px 0 10px" label="朝向:" label-width="10%" prop="checkedchaoxiang">
              <el-checkbox-group v-model="ruleForm.checkedchaoxiang" @change="handleCheckedchaoxiang">
                <el-checkbox v-for="chaoxiang in chaoxiangOptions" :label="chaoxiang.value" :key="chaoxiang.value" :disabled="flag">{{ chaoxiang.label }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item style="margin: 0px 0 10px" label-width="20%" label="售价:" prop="priceToSell">
              <el-input :disabled="flag" type="input" style="width: 60%" :rows="1" placeholder="请输入出售价格" v-model="ruleForm.priceToSell" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999"></el-input>
              <span style="margin-left: 7px">万元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="看房时间:" label-width="20%" prop="time">
              <el-select v-model="ruleForm.time" style="width: 60%" placeholder="请选择看房时间">
                <el-option v-for="item in kanfangshijianList" :key="item.value" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="信息来源:" label-width="20%" required>
              <div style="display: flex" class="xuqiuForm-item">
                <el-form-item prop="laiyuan1">
                  <el-select :disabled="flag" v-model="ruleForm.laiyuan1" @change="changeLaiyuan" placeholder="请选择信息来源" style="width: 100%">
                    <el-option v-for="item in xinxilaiyuanOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <span style="margin: 0 4px">-</span>
                <el-form-item prop="laiyuan2">
                  <el-select :disabled="flag" v-model="ruleForm.laiyuan2" placeholder="请选择信息来源" style="width: 100%">
                    <span v-if="ruleForm.laiyuan1">
                      <el-option v-for="item in xinxilaiyuanOptions[ruleForm.laiyuan1].laiyuan1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </span>
                  </el-select>
                </el-form-item>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房龄:" label-width="20%" prop="houseAge"> <el-input maxlength="4" v-model="ruleForm.houseAge" placeholder="请输入房龄  (例：2000)" style="width: 60%"></el-input><span style="margin-left: 7px">年</span> </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="房屋状况" label-width="20%" prop="fangZhuangXianKuang">
              <el-select :disabled="flag" v-model="ruleForm.fangZhuangXianKuang" placeholder="请选择房屋状况" style="width: 60%; margin: 0 7px 0 0">
                <el-option style="width: 60%" v-for="item in fnagwuzhaungkuangOptions" :key="item.value" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="建筑结构" label-width="20%" prop="jianzhujiegou">
              <el-select v-model="ruleForm.jianzhujiegou" style="width: 60%" placeholder="请选择建筑结构">
                <el-option v-for="item in jianzhujiegouOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
                        <el-form-item label="房屋用途" label-width="20%" prop="yongtu">
                            <el-select :disabled="flag" v-model="ruleForm.yongtu" placeholder="请选择房屋用途" style="width: 60%; margin: 0 7px 0 0">
                                <el-option v-for="item in fangwuyongtuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="装修状况" label-width="20%" prop="zhuangxiu">
              <el-select v-model="ruleForm.zhuangxiu" style="width: 60%" placeholder="请选择装修状况">
                <el-option v-for="item in zhuangxiuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交易权属" label-width="20%" prop="quanshu">
              <el-select :disabled="flag" v-model="ruleForm.quanshu" placeholder="请选择交易权属" style="width: 60%; margin: 0 7px 0 0">
                <el-option v-for="item in jiaoyiquanshuOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="证件状态" label-width="20%" prop="zhengJianZhuangTai">
              <el-select :disabled="flag" v-model="ruleForm.zhengJianZhuangTai" placeholder="请选择证件状态" style="width: 60%; margin: 0 7px 0 0">
                <el-option v-for="item in zhengJianZhuangTaiList" :key="item.value" :label="item.label" :value="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="roleType == 2 || roleType == 5 || roleType == 6">
            <el-form-item label="是否急售" label-width="20%">
              <el-checkbox v-model="ruleForm.isJiShou"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" label-width="20%">
              <span class="el-icon-warning-outline" style="color: red"><span style="margin-left: 10px">请务必再次核对面积、价格等信息是否真实准确！</span></span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="ruleForm.menPaiHao != '' && num == '2'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">跟进信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <div v-if="genJinList.length != 0">
          <el-table
            :data="genJinList"
            border
            style="width: 100%"
            v-loading="loading"
            ref="daikanList"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px'
            }"
            :cell-style="{ 'text-align': 'center' }"
            stripe
          >
            <el-table-column label="跟进内容" prop="genJinInfo"></el-table-column>
            <el-table-column label="跟进时间" prop="genJinTime"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button @click="modifyGenJin(scope.row)" type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                <el-button @click="removeFangYuan(scope.row)" type="text" size="mini" class="el-icon-delete caozuo" style="color: #f00">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="margin-right: 80px" @size-change="genJinHandleSizeChange" @current-change="genJinHandleCurrentChange" :current-page="genJinCurrentPage" :page-sizes="[10, 20, 50]" :page-size="genJinPageSize" layout="total, sizes, prev, pager, next, jumper" :total="genJintotal">
          </el-pagination>
        </div>
        <span v-if="genJinList.length == 0" style="margin-left: 10px; font-size: 14px">暂无跟进信息</span>
      </el-card>
      <el-card v-if="num == '1' && ruleForm.maintenanceInfo != null" id="weihuxinxi" style="padding-bottom: 20px">
        <h4 style="margin-top: 0px; margin-bottom: 0px">维护信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="价格信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="12" class="weihu">
            <el-form-item label="是否唯一:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.shifouweiyi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="付款要求:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.fukuanyaoqiu }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否限购:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.shifouxiangou }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="原购价格:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.yuangoujiage }}元</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="过户指导价:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zhidaojia }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否提供原始税票:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.yuanshishuipiao }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="业主信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="12" class="weihu">
            <el-form-item label="业主姓名:" label-width="23%">
              <span v-if="showName == false" style="color: red; cursor: pointer" @click="showName = true">查看</span>
              <span v-if="showName == true" style="color: red; cursor: pointer" @click="showName = false">{{ ruleForm.customerName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="业主电话:" label-width="23%">
              <span v-if="showTelphone == false" style="color: red; cursor: pointer" @click="showTelphone = true">查看</span>
              <span v-if="showTelphone == true" style="color: red; cursor: pointer" @click="showTelphone = false">{{ ruleForm.tel1 }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="销售原因:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.xiaoshouyuanyin }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="户口情况:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.hukou }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="抵押情况:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.diya }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否随时可签:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.keqian }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="产权是否共有:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.chanquan }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="联系人是否为业主:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.lianxiren }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="期望出售周期:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zhouqi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="婚姻状况:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.hunyin }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否合同房:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.hetong }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否限售:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.xianshou }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="特色信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="12" class="weihu">
            <el-form-item label="产权面积:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.chanquanmianji }}㎡</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="是否满N:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.manN }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="有无车位:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.chewei }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="学区名额:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.xuequ }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="交房时间:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.jiaofang }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="房间格局:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.geju }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="装修日期:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zhaungxiuriqi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="赠送面积:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zengsong }}㎡</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="装修成本:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zhaungxiuchengben }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="weihu">
            <el-form-item label="改造状况:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.gaizao }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="weihu">
            <el-form-item label="赠送详情:" label-width="23%">
              <span>{{ ruleForm.maintenanceInfo.zengsongxiangqing }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '1'" id="jichuxinxi" style="padding-bottom: 20px">
        <h4 style="margin-top: 0px; margin-bottom: 0px">基础信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="小区信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="6" class="weihu">
            <el-form-item label="所在城区:" label-width="46%">
              <span>{{ ruleForm.suozaichengqu.join(',') }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="所属商圈:" label-width="46%">
              <span>{{ ruleForm.suoshushangquan.join(',') }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="物业费:" label-width="46%">
              <span>{{ ruleForm.wuYeFei }} 元/月</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="房源信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="6" class="weihu">
            <el-form-item label="楼盘名:" label-width="46%">
              <el-tooltip class="item" effect="dark" :content="ruleForm.louPanName" placement="top">
                <span
                  style="
                    display: inline-block;
                    overflow: hidden;
                    width: 120px;
                    height: 30px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  >{{ ruleForm.louPanName }}</span
                >
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="楼栋号:" label-width="46%">
              <span>{{ ruleForm.gongChengLouHao }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="楼层:" label-width="46%">
              <span v-if="showLouCeng == false" style="color: red">--</span>
              <span v-else style="color: red">{{ ruleForm.louCeng }}/{{ ruleForm.floorCount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="门牌号:" label-width="46%">
              <span v-if="showMenPai == false" style="color: red">--</span>
              <span v-else style="color: red">{{ ruleForm.menPaiHao }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="weihu">
            <el-form-item label="朝向:" label-width="46%">
              <span>{{ ruleForm.orientations }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="面积:" label-width="46%">
              <span>{{ ruleForm.square }}平</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="价格:" label-width="46%">
              <span>{{ ruleForm.priceToLet }}元/月</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="房龄:" label-width="46%">
              <span v-if="num == 2">{{ ruleForm.houseAge }}</span>
              <span v-if="num == 1">{{ ruleForm.convertHouseAge }}年</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="建筑信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="6" class="weihu">
            <el-form-item label="交易权属:" label-width="46%">
              <span>{{ ruleForm.jiaoYiQuanShuName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="建筑结构:" label-width="46%">
              <span>{{ ruleForm.jianZhuJieGou }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="产权年限:" label-width="46%">
              <span>{{ ruleForm.chanQuanNianXian }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="梯户比例:" label-width="46%">
              <span>{{ ruleForm.tiHuBiLi }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="weihu">
            <el-form-item label="建筑年代:" label-width="46%">
              <span>{{ ruleForm.jianZhuNianDai }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="事件信息:" label-width="46%">
              <span>{{ ruleForm.shiJianXinXi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="嫌恶设施:" label-width="46%">
              <span>{{ ruleForm.xianWuSheShi }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px">
          <el-col :span="12" class="weihu">
            <el-form-item label="生活信息" class="redItem" label-width="23%"> </el-form-item>
          </el-col>
        </el-row>
        <el-row class="XinXimarginTop">
          <el-col :span="6" class="weihu">
            <el-form-item label="供暖类型:" label-width="46%">
              <span>{{ ruleForm.gongNuanType }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="用电类型:" label-width="46%">
              <span>{{ ruleForm.yongDianType }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="车位比例:" label-width="46%">
              <span>{{ ruleForm.cheWeiBiLi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="停车服务费:" label-width="46%">
              <span>{{ ruleForm.tingCheFuWuFei }} 元/月</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="weihu">
            <el-form-item label="供暖费用:" label-width="46%">
              <span>{{ ruleForm.gongNuanFei }} 元/月</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="用水类型:" label-width="46%">
              <span>{{ ruleForm.yongShuiType }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="地上车位数:" label-width="46%">
              <span>{{ ruleForm.diShangCheWeiShu }} 个</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="是否有电梯:" label-width="46%">
              <span>{{ ruleForm.dianTi }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="weihu">
            <el-form-item label="是否有燃气:" label-width="46%">
              <span>{{ ruleForm.ranQi }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="燃气费:" label-width="46%">
              <span>{{ ruleForm.ranQiFei }} 元/月</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="地下车位数:" label-width="46%">
              <span>{{ ruleForm.diXiaCheWeiShu }} 个</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="是否有热水:" label-width="46%">
              <span>{{ ruleForm.reShui }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="weihu">
            <el-form-item label="热水费:" label-width="46%">
              <span>{{ ruleForm.reShuiFei }} 元/月</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="小区幼儿园:" label-width="46%">
              <span>{{ ruleForm.youErYuan }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="是否有中水:" label-width="46%">
              <span>{{ ruleForm.zhongShui }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="weihu">
            <el-form-item label="中水费:" label-width="46%">
              <span>{{ ruleForm.zhongShuiFei }} 元/月</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '2'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">经纪人点评</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="经纪人点评:" label-width="10%" prop="createrRemark">
              <el-input v-model="ruleForm.createrRemark" type="textarea" :rows="5" placeholder="经纪人可以从核心卖点,小区介绍,户型介绍,周边配套,交通出行和税费分析几个方面进行点评"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '0'">
        <h4 style="margin-top:0px;margin-bottom:0px;">经纪人点评</h4>
        <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:0px;" />
        <el-row style="margin-top:10px;">
          <el-col :span="24">
            <el-form-item label="房屋现状:" label-width="10%" prop="fangWuXianZhuang">
              <el-input style="width: 40%" v-model="ruleForm.fangWuXianZhuang" type="text" placeholder="请输入房屋现状"></el-input>
            </el-form-item>
            <el-form-item label="是否唯一:" label-width="10%" prop="isWeiYi">
              <el-radio v-model="ruleForm.isWeiYi" label="是">是</el-radio>
              <el-radio v-model="ruleForm.isWeiYi" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="售房原因:" label-width="10%" prop="shouFangYuanYin">
              <el-input style="width: 40%" v-model="ruleForm.shouFangYuanYin" type="text" placeholder="请输入售房原因"></el-input>
            </el-form-item>
            <el-form-item label="户口情况:" label-width="10%" prop="HukoQingKuang">
              <el-input style="width: 40%" v-model="ruleForm.HukoQingKuang" type="text" placeholder="请输入户口情况"></el-input>
            </el-form-item>
            <el-form-item label="抵押情况:" label-width="10%" prop="diYa">
              <el-input style="width: 40%" v-model="ruleForm.diYa" type="text" placeholder="请输入抵押情况   "></el-input>
            </el-form-item>
            <el-form-item label="是否随时可签:" label-width="10%" prop="isKeQian">
              <el-radio v-model="ruleForm.isKeQian" label="是">是</el-radio>
              <el-radio v-model="ruleForm.isKeQian" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="产权是否共有:" label-width="10%" prop="isGongYou">
              <el-radio v-model="ruleForm.isGongYou" label="是">是</el-radio>
              <el-radio v-model="ruleForm.isGongYou" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="联系人是否为业主:" label-width="10%" prop="isYeZhu">
              <el-radio v-model="ruleForm.isYeZhu" label="是">是</el-radio>
              <el-radio v-model="ruleForm.isYeZhu" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="是否认可中介费:" label-width="10%" prop="zhongjiefei">
              <el-radio v-model="ruleForm.zhongjiefei" label="是">是</el-radio>
              <el-radio v-model="ruleForm.zhongjiefei" label="否">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '1'" id="jingjirendianping">
        <h4 style="margin-top: 0px; margin-bottom: 0px">经纪人点评</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="经纪人点评:" label-width="10%">
              <el-input v-model="ruleForm.createrRemark" readonly type="textarea" :rows="5"></el-input>
              <!-- <span>{{ ruleForm.createrRemark }}</span> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num != '1'">
        <h4 style="margin-top: 0px; margin-bottom: 0px">指定维护人</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="指定维护人:" label-width="20%" prop="maintenanceUserId">
              <el-button v-if="num != 2" size="mini" class="buttonInput button_tanchu" @click="clickUser(1)" :disabled="flag" style="width: 60%;height: 22px;line-height: 0px;text-align: left;">
                <span
                  :style="{
                    color: userName1 == '请选择指定维护人' ? '#c0c4cc' : '#666'
                  }"
                  >{{ userName1 }}</span
                >
              </el-button>
              <el-button v-if="num == 2" size="mini" class="buttonInput button_tanchu" @click="clickUser(1)" disabled style="background-color: #F5F7FA; border-color: #E4E7ED; color: #ccc;width: 60%;height: 22px;line-height:0px;text-align: left;">
                <span style="color:#c0c4cc">{{ userName1 }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col v-if="roleType != 1" :span="12">
            <el-form-item label="指定录入人:" label-width="20%" prop="createPerson">
              <el-button
                v-if="num != 2"
                size="mini"
                class="buttonInput button_tanchu"
                @click="clickUser(2)"
                :disabled="num == 2"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span
                  :style="{
                    color: userName2 == '请选择指定录入人' ? '#c0c4cc' : '#666'
                  }"
                  >{{ userName2 }}</span
                >
              </el-button>
              <el-button
                v-else
                size="mini"
                class="buttonInput button_tanchu"
                @click="clickUser(2)"
                :disabled="num == 2"
                style="
                  background-color: #f5f7fa;
                  border-color: #e4e7ed;
                  color: #ccc;
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span style="color: #c0c4cc">{{ userName2 }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-row v-if="num != '1'" style="margin: 20px 10px">
        <el-col :span="12">
          <el-form-item label-width="10%;">
            <el-button size="mini" type="primary" style="margin-right: 10px" :disabled="saveDisabled" v-if="num == 0" @click="onSave('ruleForm', 'add')">新增</el-button>
            <el-button size="mini" type="primary" style="margin-right: 10px" :disabled="saveDisabled" v-if="this.num == 2" @click="onSave('ruleForm', 'undate')">确定</el-button>
            <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-card v-if="num == '1'" id="yaoshixinxi">
        <h4 style="margin-top: 0px; margin-bottom: 0px">钥匙信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="" label-width="2.5%">
              <div v-if="keyPic.length > 0">
                <el-image style="width: 125px; height: 100px; margin: 0 10px" v-for="url in keyPic" :key="url" :src="url" :preview-src-list="keyPic"> </el-image>
              </div>
              <img v-if="keyPic.length == 0" src="../../../public/img/zanwutupian.jpg" style="width: 125px; height: 100px" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == '1'" id="zhengjianxinxi">
        <h4 style="margin-top: 0px; margin-bottom: 0px">证件信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-form-item label="" label-width="2.5%">
              <div v-if="certificatePics.length > 0">
                <el-image style="width: 125px; height: 100px; margin: 0 10px" v-for="url in certificatePics" :key="url" :src="url" :preview-src-list="certificatePics"> </el-image>
              </div>
              <img v-if="certificatePics.length == 0" src="../../../public/img/zanwutupian.jpg" style="width: 125px; height: 100px" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-if="num == 1">
        <h4 style="margin-top: 0px; margin-bottom: 0px">带看信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <div v-if="daikanList.length != 0">
          <el-table
            :data="daikanList"
            border
            style="width: 100%"
            v-loading="loading"
            ref="daikanList"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px'
            }"
            :cell-style="{ 'text-align': 'center' }"
            stripe
          >
            <el-table-column label="经纪人" prop="userName"></el-table-column>
            <el-table-column label="带看时间" prop="daiKanTime"></el-table-column>
          </el-table>
          <el-pagination style="margin-right: 80px" @size-change="daiKanHandleSizeChange" @current-change="daiKanHandleCurrentChange" :current-page="daiKanCurrentPage" :page-sizes="[10, 20, 50]" :page-size="daiKanPageSize" layout="total, sizes, prev, pager, next, jumper" :total="daikantotal">
          </el-pagination>
        </div>
        <span v-if="daikanList.length == 0" style="margin-left: 10px; font-size: 14px">暂无带看信息</span>
      </el-card>

      <el-card v-if="num == 1 && ruleForm.menPaiHao != ''">
        <h4 style="margin-top: 0px; margin-bottom: 0px">跟进信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <div v-if="genJinList.length != 0">
          <el-table
            :data="genJinList"
            border
            style="width: 100%"
            v-loading="loading"
            ref="daikanList"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px'
            }"
            :cell-style="{ 'text-align': 'center' }"
            stripe
          >
            <el-table-column label="经纪人" prop="genJinRenName"></el-table-column>
            <el-table-column label="跟进内容" prop="genJinInfo"></el-table-column>
            <el-table-column label="跟进时间" prop="genJinTime"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
          </el-table>
          <el-pagination style="margin-right: 80px" @size-change="genJinHandleSizeChange" @current-change="genJinHandleCurrentChange" :current-page="genJinCurrentPage" :page-sizes="[10, 20, 50]" :page-size="genJinPageSize" layout="total, sizes, prev, pager, next, jumper" :total="genJintotal">
          </el-pagination>
        </div>
        <span v-if="genJinList.length == 0" style="margin-left: 10px; font-size: 14px">暂无跟进信息</span>
      </el-card>

      <el-card>
        <h4 style="margin-top: 0px; margin-bottom: 0px">浏览房源记录</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <div v-if="liuLanList.length != 0">
          <el-table
            :data="liuLanList"
            border
            style="width: 100%"
            v-loading="loading"
            ref="daikanList"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px'
            }"
            :cell-style="{ 'text-align': 'center' }"
            stripe
          >
            <el-table-column label="经纪人" prop="userName"></el-table-column>

            <el-table-column label="浏览时间" prop="createTime"></el-table-column>
          </el-table>
          <el-pagination style="margin-right: 80px" @size-change="liuLanHandleSizeChange" @current-change="liuLanHandleCurrentChange" :current-page="genJinCurrentPage" :page-sizes="[10, 20, 50]" :page-size="genJinPageSize" layout="total, sizes, prev, pager, next, jumper" :total="liuLantotal">
          </el-pagination>
        </div>
        <span v-if="liuLanList.length == 0" style="margin-left: 10px; font-size: 14px">暂无跟进信息</span>
      </el-card>

      <el-card v-if="num == '1'" id="jueserenxinxi">
        <h4 style="margin-top: 0px; margin-bottom: 0px">角色人信息</h4>
        <hr
          style="
            height: 1px;
            border: none;
            backgroundcolor: #ccc;
            margin-top: 0px;
            margin-bottom: 0px;
          "
        />
        <el-col
          style="
            margin-top: 10px;
            display: inline-block;
            float: none;
            vertical-align: top;
          "
          :span="8"
          v-for="(item, index) in ruleForm.jueserenData"
          :key="index"
        >
          <div class="jueseren" v-if="item.role != ''" style="height: 102px">
            <img
              :src="item.jueserenPic"
              style="
                width: 100px;
                height: 100px;
                float: left;
                margin-right: 10px;
              "
            />
            <div style="height: 100px">
              <div class="juese">{{ item.role }}</div>
              <div class="labelValue" style="font-size: 12px; line-height: 22px">
                {{ item.name }}
              </div>
              <div
                class="labelValue"
                style="
                  font-size: 12px;
                  line-height: 22px;
                  display: flex;
                  width: 50%;
                "
              >
                电话:{{ item.phone }}
              </div>
              <div
                class="labelValue"
                style="
                  font-size: 12px;
                  line-height: 22px;
                  display: flex;
                  width: 50%;
                "
              >
                门店:{{ item.mendian }}
              </div>
              <!-- <div class="labelValue" style="font-size: 12px;line-height: 22px;color:red">{{item.role}}预估成交业绩</div> -->
            </div>
            <div class="company">
              <span style="color: white; font-weight: bold">{{ item.zhongjieName }}</span>
            </div>
          </div>
          <!-- <div v-if="item.role" class="labelValue" style="font-size: 12px; line-height: 22px; color: #ccc">
                        已验真房源翻录,30天内带回录入人
                    </div> -->

          <div v-else class="keyMen" style="padding-top: 25px; height: 102px">
            <div style="padding-top: 3px">
              <i :class="item.icon" style="font-size: 40px"></i>
            </div>
            <div style="display: inline-block">{{ item.roleType }}</div>
          </div>
        </el-col>
      </el-card>
    </el-form>
    <el-dialog title="楼盘" :visible.sync="loupanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
      <el-card>
        <el-row>
          <el-col>
            <p>
              <span class="el-form-item__label" style="line-height: 40px">楼盘名称：</span>
              <el-input v-model="louPanNameDialog" placeholder="请输入楼盘名称" style="width: 50%"></el-input>
              <span style="float: right">
                <el-button size="mini" type="primary" style="margin-right: 10px" @click="SearchLoupan">查询</el-button>
                <el-button size="mini" type="warning" @click="ResetLoupan">重置</el-button>
              </span>
            </p>
          </el-col>
        </el-row>
      </el-card>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        @row-click="loupanDialogDetails"
        :data="loupanList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column label="楼盘编号" prop="louPanNo"></el-table-column>
        <el-table-column label="楼盘名称" prop="louPanName"></el-table-column>
        <el-table-column label="所属商圈" prop="areaName">
          <template slot-scope="scope">
            {{ scope.row.areaNames }}
          </template>
        </el-table-column>
        <el-table-column label="项目地址" prop="louPanAddress"></el-table-column>
        <el-table-column label="开发单位" prop="kaiFaDanWei"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="loupanDialogSizeChange" @current-change="loupanDialogCurrentChange" :current-page="loupanDialogCurrentPage" :page-sizes="[10, 20, 50]" :page-size="loupanDialogPageSize" layout="total, sizes, prev, pager, next, jumper" :total="loupanDialogTotal"> </el-pagination>
    </el-dialog>
    <el-dialog :visible.sync="weihurenDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
      <el-card>
        <el-row>
          <el-col>
            <p>
              <span class="el-form-item__label" style="line-height: 40px">经纪人名称：</span>
              <el-input v-model="userName" placeholder="请输入经纪人名称" style="width: 50%"></el-input>
              <span style="float: right">
                <el-button size="mini" type="primary" style="margin-right: 10px" @click="getUserList">查询</el-button>
                <el-button size="mini" type="warning" @click="ResetWeihuren">重置</el-button>
              </span>
            </p>
          </el-col>
        </el-row>
      </el-card>
      <el-table
        ref="multipleTable"
        v-loading="weihurenDialogLoading"
        :data="weihurenDialogList"
        @row-click="weihurenDialogDetails"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column label="用户名" prop="userName">
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column label="性别" prop="gender"></el-table-column>
        <el-table-column label="手机号" prop="mobile1"></el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-row>
        <el-pagination
          style="width: 70%; float: right"
          @size-change="weihurenDialogSizeChange"
          @current-change="weihurenDialogCurrentChange"
          :current-page="userCurrentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="userPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="weihurenDialogTotal"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 新建地址 -->
    <el-dialog title="申请新建地址" :visible.sync="shenqingRequirementVisible" width="50%" :before-close="shenqingRequirementClose">
      <el-form ref="GenJinRuleForm" :model="shenqingForm" :rules="shenqingRules" label-position="right">
        <el-form-item label="楼盘名称" label-width="13%" prop="loupanName">
          <el-input v-model="shenqingForm.loupanName" style="width: 95%" type="text" placeholder="请输入楼盘名称"></el-input>
        </el-form-item>
        <el-form-item label="具体地址" label-width="13%" prop="address">
          <el-input v-model="shenqingForm.address" style="width: 95%" type="text" placeholder="请输入具体地址"></el-input>
        </el-form-item>
        <el-form-item class="shenqing">
          <el-button type="primary" size="mini" :disabled="GenJinDisabled" @click="saveShenQing">申请</el-button>
          <el-button type="warning" size="mini" @click="cancelGenJin">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="调价记录" :visible.sync="priceDialog" direction="rtl" width="50%" top="10%">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="priceList"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
        style="height:400px"
      >
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="操作人" prop="userName"></el-table-column>
        <el-table-column label="操作时间" prop="createTime"> </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- <el-pagination @size-change="priceDialogSizeChange" @current-change="priceDialogCurrentChange" :current-page="priceDialogCurrentPage" :page-sizes="[10, 20, 50]" :page-size="priceDialogPageSize" layout="total, sizes, prev, pager, next, jumper" :total="priceDialogTotal">
            </el-pagination> -->
    </el-dialog>

    <el-dialog title="选择图片" :visible.sync="DaYindialogVisible" width="48%">
      <el-image style="width: 125px; height: 100px; margin: 0 10px" v-for="url in shiKanPics" :key="url" :src="url" @click="DaYin(url)"> </el-image>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="DaYin">确 定</el-button>
      </span> -->
    </el-dialog>
    <div v-if="GenJindialogVisible">
      <FangYuanGenJinJiLuList :isXiuGaiGen="isXiuGaiGen" :xiuGaiList="xiuGaiList" :isFYGenJin="true" @isGJreload="isGJreload" :houseId="houseId"></FangYuanGenJinJiLuList>
    </div>
  </div>
</template>
<script>
import FangYuanGenJinJiLuList from '../../components/FangYuanGenJinJiLu/FangYuanGenJinJiLuList.vue'
import HaiBaoList from '../../components/HaiBao/HaiBaoList.vue'
import chuzuJs from '../ChuZu/chuzu.js'
export default {
  components: {
    FangYuanGenJinJiLuList,
    HaiBaoList
  },
  inject: ['reload'],
  props: {
    /*编辑器的内容*/
    value: null,
    /*图片大小*/
    maxSize: {
      type: Number,
      default: 4000 //kb
    }
  },
  mounted() {},
  watch: {
    value(val) {
      this.content = this.value
    },
    louPanName(val) {
      this.ruleForm.louPanName = val
    }
  },
  data() {
    return {
      GenJindialogVisible: false,
      isShenHe: this.$route.query.isShenHe,
      keting: this.$route.query.keting,
      yezhudetal: true,
      isOpen: false,
      staffName: localStorage.getItem('staffName'),
      GenJinDisabled: false,
      shenqingForm: {
        loupanName: '',
        address: ''
      },
      shenqingRequirementVisible: false,
      shenqingRules: {
        loupanName: [
          {
            required: true,
            message: '请输入楼盘名称',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入具体地址',
            trigger: 'blur'
          }
        ]
      },
      enabled: this.$route.query.enabled,
      daikanList: [],
      loupanList: [],
      priceList: [],
      hideUpload: true,
      show: false,
      canShopEdit: this.$route.query.canShopEdit,
      canMerchantEdit: this.$route.query.canMerchantEdit,
      canMaintenanceEdit: this.$route.query.canMaintenanceEdit,
      num: this.$route.query.num,
      houseId: this.$route.query.houseId,
      isConfirmed: this.$route.query.isConfirmed,
      serverUrl: this.$url.UploadHousestosell,
      name: '',
      mm: '',
      flag: false,
      picture: [],
      shiKanPics: [],
      allShiKanPics: [],
      keyPic: [],
      certificatePics: [],
      dialogVisible: false,
      dialogImageUrl: '',
      isMax: false,
      MyHeader: { accessToken: '', fp: '' },
      loading: true,
      loupanDialogPageSize: 10,
      loupanDialogCurrentPage: 1,
      loupanDialog: false,
      loupanDialogTotal: 0,
      priceDialogPageSize: 10,
      priceDialogCurrentPage: 1,
      priceDialog: false,
      priceDialogTotal: 0,
      ruleForm: {
        isJiShou: '', // 急售
        priceToSell: '',
        louDongId: '',
        louDongId: '',
        chuzufangshi: '',
        louceng: '',
        louceng1: '',
        menPaiHao: '',
        weChatNo: '',
        yaoshi: '',
        huxing: '',
        square: '',
        fangZhuangXianKuang: '',
        checkedchaoxiang: [],
        priceToLet: '',
        time: '',
        laiyuan1: '',
        laiyuan2: '',
        laiyuanName: '',
        name: '',
        tel1: '',
        tel2: '',
        idCardNo: '',
        danyuan: '',
        louPanName: '',
        createrRemark: '',
        // weihurenName: "",
        houseAge: '',
        // yongtu: "",
        zhuangxiu: '',
        quanshu: '',
        jianzhujiegou: '',
        zhengJianZhuangTai: '',
        suozaichengqu: [],
        suoshushangquan: [],
        jueserenData: [],
        houseNo: '',
        wuYeFei: '',
        maintenanceInfo: [],
        shiKanInfo: [],
        huxingList: [],
        maintenanceUserId: '',
        createPerson: '',
        fangWuXianZhuang: '',
        isWeiYi: '是',
        shouFangYuanYin: '',
        HukoQingKuang: '',
        diYa: '',
        isKeQian: '是',
        isGongYou: '是',
        isYeZhu: '是',
        zhongjiefei: '是'
      },
      form1content: '',
      Ftype: this.$route.query.Ftype,
      rules: chuzuJs.rules,
      louPanName: '请选择楼盘',
      louPanNameDialog: '',
      zhengJianZhuangTaiList: chuzuJs.zhengJianZhuangTaiList,
      weihurenDialogName: '',
      weihurenDialogLoading: false,
      weihurenDialogList: chuzuJs.weihurenDialogList,
      weihurenDialog: false,
      weihurenDialogTotal: 0,
      dongzuoList: [],
      danyuanArray: [],
      loucengList: [],
      loucengArray: [],
      menpaiArray: [],
      chaoxiangOptions: chuzuJs.chaoxiangOptions,
      huxingOptions: [],
      xinxilaiyuanOptions: [],
      fnagwuzhaungkuangOptions: chuzuJs.fnagwuzhaungkuangOptions,
      kanfangshijianList: chuzuJs.kanfangshijianList,
      jumpType: '',
      checkedchaoxiang: '00000000',
      louPanId: this.$route.query.louPanId,
      louDongInfo: [],
      userName: '',
      userName1: '请选择指定维护人',
      userName2: '请选择指定录入人',
      userCurrentPage: 1,
      userPageSize: 10,
      jiaoyiquanshuOptions: [],
      jianzhujiegouOptions: [],
      // fangwuyongtuOptions: [],
      zhuangxiuOptions: [], //装修状况
      roleType: '',
      fukuanyaoqiuList: [],
      weihurenId: '',
      weihurenId1: '',
      shikanrenId: '',
      showName: false,
      zhuangxiuContant: '',
      showTelphone: false,
      showLouCeng: false,
      showMenPai: false,
      huXingTitle: '',
      tel1Check: 0,
      tel2Check: 0,
      saveDisabled: false,
      weihutype: '',
      userid: localStorage.getItem('userId'),
      localShopId: localStorage.getItem('shopId'),
      shopId: this.$route.query.shopId,
      loucengtop: false,
      currentPage: 1,
      editableTabs: [],
      daiKanCurrentPage: 1,
      daiKanPageSize: 10,
      genJinCurrentPage: 1,
      genJinPageSize: 10,
      liuLanCurrentPage: 1,
      liuLanPageSize: 10,
      priceCurrentPage: 1,
      pricePageSize: 20,
      genJinList: [],
      liuLanList: [],
      isXiuGaiGen: '',
      xiuGaiList: {},
      daikantotal: 0,
      genJintotal: 0,
      liuLantotal: 0,
      isDaYin: false,
      DaYindialogVisible: false,
      dayinUrl: '',
      isChengJiaoToSell: ''
    }
  },
  created() {
    this.editableTabs = window.localStorage.getItem('editableTabs')
    this.activeIndex = window.localStorage.getItem('activeIndex')
    if (this.$route.query.isOnUrgentSale == true || this.$route.query.isOnUrgentSale == 'true') {
      this.ruleForm.isJiShou = true
    } else {
      this.ruleForm.isJiShou = false
    }

    if (this.editableTabs) {
      this.editableTabs = JSON.parse(this.editableTabs)
      this.editableTabs.forEach(item => {
        if (this.activeIndex == item.path) {
          this.activeIndex = this.activeIndex
          this.editableTabsValue = item.name.toString()
        }
      })
    }
    this.MyHeader.accessToken = localStorage.getItem('accessToken')
    this.MyHeader.fp = localStorage.getItem('fp')
    this.roleType = localStorage.getItem('roleType')
    this.weihurenId1 = localStorage.getItem('userId')
    if (this.roleType == 1) {
      this.userName1 = this.staffName
      this.ruleForm.maintenanceUserId = this.weihurenId1
    } else {
      this.userName1 = '请选择指定维护人'
    }
    if (this.num == 0 || (this.roleType == 1 && this.canMaintenanceEdit == 1) || (this.roleType == 5 && this.canMerchantEdit == 1) || this.roleType == 6) {
      this.yezhudetal = false
    } else {
      this.yezhudetal = true
    }
    this.getloupanList()
    this.getShuJuZiDianList()
    this.getUserList()
    if (this.num != 0) {
      this.getHouseDetailDaiKanList()
      this.getHouseDetailGenJinList()
      this.GetHouseDetailPriceRecordList()
      this.GetHouseDetailBrowsingHistoryList()
    }
  },
  methods: {
    // 跳转修改
    handleGetXiuGai() {
      // if (this.isOnUrgentSale == "1") {
      //     this.JiShou = true;
      //   } else {
      //     this.JiShou = false;
      //   }
      this.$router.push({
        name: 'ChuShouDetail',
        query: {
          isOnUrgentSale: this.ruleForm.isJiShou,
          canShopEdit: this.canShopEdit,
          canMaintenanceEdit: this.canMaintenanceEdit,
          canMerchantEdit: this.canMerchantEdit,
          louPanId: this.louPanId,
          houseId: this.houseId,
          num: 2,
          state: 3
        }
      })
      location.reload()
    },
    // 带看记录列表
    async getHouseDetailDaiKanList() {
      const data = {
        houseId: this.houseId,
        currentPage: this.daiKanCurrentPage,
        pageSize: this.daiKanPageSize
      }
      const { data: res } = await this.$http.get(this.$url.GetHouseDetailDaiKan, {
        params: data
      })
      if (res.msgCode == 1) {
        this.daikanList = res.dtList
        this.daikantotal = res.maxRecords
        // console.log(res.dtList)
      }
    },
    isGJreload(val) {
      if (val) {
        this.reload()
      }
      console.log(val)
    },
    // 跟进记录列表
    async getHouseDetailGenJinList() {
      const data = {
        houseId: this.houseId,
        currentPage: this.genJinCurrentPage,
        pageSize: this.genJinPageSize
      }
      const { data: res } = await this.$http.get(this.$url.GetHouseDetailGenJin, {
        params: data
      })
      if (res.msgCode == 1) {
        console.log(res)
        this.genJinList = res.result
        this.genJintotal = res.maxRecords
      }
    },
    // 浏览房源记录列表
    async GetHouseDetailBrowsingHistoryList() {
      const data = {
        houseId: this.houseId,
        currentPage: this.liuLanCurrentPage,
        pageSize: this.liuLanPageSize
      }
      const { data: res } = await this.$http.get(this.$url.GetHouseDetailBrowsingHistory, {
        params: data
      })
      if (res.msgCode == 1) {
        console.log(res)
        this.liuLanList = res.dtList
        this.liuLantotal = res.maxRecords
      }
    },
    // 调价记录
    async GetHouseDetailPriceRecordList() {
      const data = {
        houseId: this.houseId,
        currentPage: this.priceCurrentPage,
        pageSize: this.pricePageSize
      }
      const { data: res } = await this.$http.get(this.$url.GetHouseDetailPriceRecord, {
        params: data
      })
      if (res.msgCode == 1) {
        console.log(res)
        this.priceList = res.dtList
        // this.genJinList = res.result
        // this.genJintotal = res.maxRecords
      }
    },
    // 打印
    DaYin(url) {
      this.dayinUrl = url
      this.isDaYin = true
      this.DaYindialogVisible = false
      setTimeout(() => {
        document.getElementById('DaYin').click()
        this.isDaYin = false
      }, 1000)
      // this.$router.push({
      //     name: "HaiBaoList",
      //     query: {
      //         ruleForm: JSON.stringify(this.ruleForm)
      //     }
      // })
    },

    // 新增跟进
    addGenJin() {
      this.GenJindialogVisible = true
    },
    modifyGenJin(row) {
      this.GenJindialogVisible = true
      this.isXiuGaiGen = '1'
      this.xiuGaiList = row
      // console.log(row)
    },

    // 删除
    async removeFangYuan(index) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const data1 = [index.genJinId]
          const { data: res } = await this.$http.delete(this.$url.DeleteGenJin, {
            data: data1,
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          this.loading = false
          if (res.msgCode == 1) {
            this.getHouseDetailGenJinList()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => err)
    },

    handleClose() {
      this.GenJindialogVisible = false
    },
    daiKanHandleSizeChange(newSize) {
      this.daiKanPageSize = newSize
      this.getHouseDetailDaiKanList()
    },
    daiKanHandleCurrentChange(newPage) {
      this.daiKanCurrentPage = newPage
      this.getHouseDetailDaiKanList()
    },

    genJinHandleSizeChange(newSize) {
      console.log(newSize)
      this.genJinPageSize = newSize
      this.getHouseDetailGenJinList()
    },
    genJinHandleCurrentChange(newPage) {
      console.log(newPage)
      this.genJinCurrentPage = newPage
      this.getHouseDetailGenJinList()
    },
    //浏览房源记录
    liuLanHandleSizeChange(newSize) {
      console.log(newSize)
      this.liuLanPageSize = newSize
      this.GetHouseDetailBrowsingHistoryList()
    },
    liuLanHandleCurrentChange(newPage) {
      console.log(newPage)
      this.liuLanCurrentPage = newPage
      this.GetHouseDetailBrowsingHistoryList()
    },
    cancelGenJin() {
      this.shenqingRequirementVisible = false
      this.shenqingForm.loupanName = ''
      this.shenqingForm.address = ''
    },
    saveShenQing() {
      let that = this
      that.shenqingRequirementVisible = false
      // that.$refs.GenJinRuleForm.validate(async (valid) => {
      //     if (valid) {
      //         that.GenJinDisabled = true
      //         const data = {
      //             userId: that.GenJinForm.agentId,
      //             RequirementId: that.GenJinForm.genJinRequirementId,
      //             customerName: that.GenJinForm.customerName,
      //             customerId: that.GenJinForm.customerId,
      //             genJinTime: that.GenJinForm.genJinTime.replace(
      //                 " ",
      //                 "T"
      //             ),
      //             genJinInfo: that.GenJinForm.genJinInfo
      //         }
      //         if (this.GenJinType == "add") {
      //             url = that.$url.InsertGenJin
      //             method = "post"
      //         } else {
      //             url = that.$url.UpdateGenJin
      //             data.genJinId = that.GenJinForm.genJinId
      //             method = "put"
      //         }
      //         that.$http[method](url, data, {
      //             headers: {
      //                 "Content-Type": "application/json;charset=UTF-8"
      //             }
      //         }).then((res) => {
      //             if (res.data.msgCode == 1) {
      //                 that.dialogFormVisible = false
      //                 if (that.GenJinType == "add") {
      //                     that.$message.success("跟进信息新增成功！")
      //                 } else {
      //                     that.$message.success("跟进信息修改成功！")
      //                 }
      //                 that.GenJinDisabled = false
      //                 that.GetGenJinList()
      //             } else {
      //                 that.GenJinDisabled = false
      //                 that.$message.error(res.data.message)
      //             }
      //         })
      //     } else {
      //         return false
      //     }
      // })
    },
    shenqingRequirementClose() {
      this.shenqingRequirementVisible = false
    },
    //房源详情
    GetHouseDetail() {
      var that = this
      this.$http
        .get(this.$url.GetHouseDetail, {
          params: {
            houseId: this.houseId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.loading = false
            let data = res.data.dtList[0]
            that.ruleForm = Object.assign(that.ruleForm, data)
            console.log(that.ruleForm, '房源')
            if (that.ruleForm.idCardNo == null) {
              that.ruleForm.idCardNo = ''
            }
            that.ruleForm.huxing = that.ruleForm.huXingId
            that.ruleForm.priceToSell = that.ruleForm.priceToSell / 10000
            that.ruleForm.quanshu = that.ruleForm.jiaoYiQuanShuId
            that.checkedchaoxiang = that.ruleForm.orientations
            that.ruleForm.time = that.ruleForm.kanFangShiJian
            that.ruleForm.zhuangxiu = that.ruleForm.zhuangXiuZhuangKuangId
            that.ruleForm.jianzhujiegou = that.ruleForm.jianZhuJieGouId
            // that.ruleForm.yongtu = that.ruleForm.fangWuYongTuId
            let menPaiHao = that.ruleForm.menPaiHao.split('-')
            that.ruleForm.danyuan = menPaiHao[0] + '单元' //单元
            that.zhuangxiuOptions.forEach(i => {
              if (i.commonMasterId == that.ruleForm.zhuangXiuZhuangKuangId) {
                that.zhuangxiuContant = i.commonMasterName
              }
            })

            if (that.ruleForm.louCeng == '高' || that.ruleForm.louCeng == '中' || that.ruleForm.louCeng == '低') {
              that.ruleForm.louceng = that.ruleForm.louCeng + '楼层'
            } else {
              if (that.ruleForm.louCeng <= that.ruleForm.floorCount / 3) {
                that.ruleForm.louceng1 = '低楼层'
              } else if (that.ruleForm.louCeng >= that.ruleForm.floorCount / 3) {
                that.ruleForm.louceng1 = '高楼层'
              } else if (that.ruleForm.louCeng < that.ruleForm.floorCount / 3 && that.ruleForm.louceng > that.ruleForm.floorCount / 3) {
                that.ruleForm.louceng1 = '中楼层'
              }

              that.ruleForm.louceng = that.ruleForm.louCeng + '层'
            }
            that.louPanName = that.ruleForm.louPanName
            if (that.ruleForm.isZhengZu == '1') {
              that.ruleForm.chuzufangshi = '1'
            } else {
              that.ruleForm.chuzufangshi = '0'
            }

            if (that.num == 1 && that.ruleForm.maintenanceInfo != null) {
              if (that.ruleForm.maintenanceInfo.length != 0) {
                that.ruleForm.maintenanceInfo = JSON.parse(that.ruleForm.maintenanceInfo)
              }
              that.fukuanyaoqiuList.forEach(item => {
                if (item.value == that.ruleForm.maintenanceInfo.fukuanyaoqiu) {
                  that.ruleForm.maintenanceInfo.fukuanyaoqiu = item.label
                }
              })
              that.ruleForm.maintenanceInfo.manN = that.ruleForm.maintenanceInfo.manN == 0 ? '是' : that.ruleForm.maintenanceInfo.manN == 1 ? '否' : ''
              if (that.ruleForm.maintenanceInfo.shifouweiyi == 0) {
                that.ruleForm.maintenanceInfo.shifouweiyi = '是'
              } else if (that.ruleForm.maintenanceInfo.shifouweiyi == 1) {
                that.ruleForm.maintenanceInfo.shifouweiyi = '否'
              }
              if (that.ruleForm.maintenanceInfo.keqian == 0) {
                that.ruleForm.maintenanceInfo.keqian = '是'
              } else if (that.ruleForm.maintenanceInfo.keqian == 1) {
                that.ruleForm.maintenanceInfo.keqian = '否'
              }
              if (that.ruleForm.maintenanceInfo.chanquan == 0) {
                that.ruleForm.maintenanceInfo.chanquan = '是'
              } else if (that.ruleForm.maintenanceInfo.chanquan == 1) {
                that.ruleForm.maintenanceInfo.chanquan = '否'
              }
              if (that.ruleForm.maintenanceInfo.lianxiren == 0) {
                that.ruleForm.maintenanceInfo.lianxiren = '是'
              } else if (that.ruleForm.maintenanceInfo.lianxiren == 1) {
                that.ruleForm.maintenanceInfo.lianxiren = '否'
              }
              if (that.ruleForm.maintenanceInfo.hunyin == 0) {
                that.ruleForm.maintenanceInfo.hunyin = '已婚'
              } else if (that.ruleForm.maintenanceInfo.hunyin == 1) {
                that.ruleForm.maintenanceInfo.hunyin = '未婚'
              }
              if (that.ruleForm.maintenanceInfo.hetong == 0) {
                that.ruleForm.maintenanceInfo.hetong = '是'
              } else if (that.ruleForm.maintenanceInfo.hetong == 1) {
                that.ruleForm.maintenanceInfo.hetong = '否'
              }
              if (that.ruleForm.maintenanceInfo.chewei == 0) {
                that.ruleForm.maintenanceInfo.chewei = '有'
              } else if (that.ruleForm.maintenanceInfo.chewei == 1) {
                that.ruleForm.maintenanceInfo.chewei = '无'
              }
              if (that.ruleForm.maintenanceInfo.xuequ == 0) {
                that.ruleForm.maintenanceInfo.xuequ = '有'
              } else if (that.ruleForm.maintenanceInfo.xuequ == 1) {
                that.ruleForm.maintenanceInfo.xuequ = '无'
              }
              if (that.ruleForm.maintenanceInfo.yuanshishuipiao == '0') {
                that.ruleForm.maintenanceInfo.yuanshishuipiao = '是'
              } else if (that.ruleForm.maintenanceInfo.yuanshishuipiao == '1') {
                that.ruleForm.maintenanceInfo.yuanshishuipiao = '否'
              }
              if (that.ruleForm.maintenanceInfo.xianshou == '0') {
                that.ruleForm.maintenanceInfo.xianshou = '是'
              } else if (that.ruleForm.maintenanceInfo.xianshou == '1') {
                that.ruleForm.maintenanceInfo.xianshou = '否'
              }
            }
            that.userName1 = that.ruleForm.maintenanceUserName
            that.userName2 = that.ruleForm.createPersonName
            that.weihurenId = that.ruleForm.maintenanceUserId
            // that.shopId = that.ruleForm.shopId;
            let role = that.ruleForm.createPersonName == null ? '' : '录入人'
            // let boyPicture = this.$url.boylImg
            // let girlPicture = this.$url.girlImg
            let boyPicture = require('../../../public/img/boy.png')
            let girlPicture = require('../../../public/img/girl.png')
            let pic = ''
            let ShopName = ''
            if (that.ruleForm.createPersonName != null) {
              ShopName = that.ruleForm.createrUserShopName
              if (that.ruleForm.createrUserPictureId == null || that.ruleForm.createrUserPictureId == '') {
                if (that.ruleForm.createrUserGender == '1') {
                  // 男
                  pic = boyPicture
                }
                if (that.ruleForm.createrUserGender == '2') {
                  // 女
                  pic = girlPicture
                }
              } else {
                pic = this.$url.avatarsPicSrc + that.ruleForm.createrUserPictureId
              }
            }
            that.ruleForm.jueserenData.push({
              name: that.ruleForm.createPersonName,
              lururenId: that.createPersonId,
              phone: that.ruleForm.createrUserMobile,
              mendian: ShopName,
              role: role,
              roleType: '录入人',
              message: '24小时内仅钥匙人可以操作',
              icon: 'iconfont icon-lururenyuan',
              jueserenPic: pic,
              zhongjieName: ShopName
            })
            that.weihurenId = that.ruleForm.maintenanceUserId
            let role1 = that.ruleForm.maintenanceUserId == null ? '' : '维护人'
            let pic1 = ''
            let ShopName1 = ''
            if (that.ruleForm.maintenanceUserName != null) {
              ShopName1 = that.ruleForm.maintenanceUserShopName
              if (that.ruleForm.maintenanceUserPictureId == null || that.ruleForm.maintenanceUserPictureId == '') {
                if (that.ruleForm.maintenanceUserGender == '1') {
                  // 男
                  pic1 = boyPicture
                }
                if (that.ruleForm.maintenanceUserGender == '2') {
                  // 女
                  pic1 = girlPicture
                }
              } else {
                pic1 = this.$url.avatarsPicSrc + that.ruleForm.maintenanceUserPictureId
                console.log(this.$url.avatarsPicSrc + that.ruleForm.maintenanceUserPictureId, '图片')
              }
            }
            that.ruleForm.jueserenData.push({
              name: that.ruleForm.maintenanceUserName,
              weihurenId: that.ruleForm.maintenanceUserId,
              phone: that.ruleForm.maintenanceUserMobile,
              mendian: ShopName1,
              role: role1,
              roleType: '维护人',
              message: '24小时内仅维护人可以操作',
              icon: 'iconfont icon-weihurenyuan',
              jueserenPic: pic1,
              zhongjieName: ShopName1
            })
            that.shikanrenId = that.ruleForm.shiKanUserId
            let role2 = that.ruleForm.shiKanUserId == null ? '' : '实勘人'
            let pic2 = ''
            let ShopName2 = ''
            if (that.ruleForm.shiKanUserName != null) {
              ShopName2 = that.ruleForm.shiKanUserShopName
              if (that.ruleForm.shiKanUserPictureId == null || that.ruleForm.shiKanUserPictureId == '') {
                if (that.ruleForm.shiKanUserGender == '1') {
                  // 男
                  pic2 = boyPicture
                }
                if (that.ruleForm.shiKanUserGender == '2') {
                  // 女
                  pic2 = girlPicture
                }
              } else {
                pic2 = this.$url.avatarsPicSrc + that.ruleForm.shiKanUserPictureId
              }
            }
            that.ruleForm.jueserenData.push({
              name: that.ruleForm.shiKanUserName,
              shikanrenId: that.ruleForm.shiKanUserId,
              phone: that.ruleForm.shiKanUserMobile,
              mendian: ShopName2,
              role: role2,
              roleType: '实勘人',
              message: '24小时内仅实勘人可以操作',
              icon: 'iconfont icon-shikanren',
              jueserenPic: pic2,
              zhongjieName: ShopName2
            })
            that.yaoshirenId = that.ruleForm.keyUserId
            let role3 = that.ruleForm.keyUserId == null ? '' : '钥匙人'
            let pic3 = ''
            let ShopName3 = ''
            if (that.ruleForm.keyUserName != null) {
              ShopName3 = that.ruleForm.keyUserShopName
              if (that.ruleForm.keyUserPictureId == null || that.ruleForm.keyUserPictureId == '') {
                if (that.ruleForm.keyUserGender == '1') {
                  // 男
                  pic3 = boyPicture
                }
                if (that.ruleForm.keyUserGender == '2') {
                  // 女
                  pic3 = girlPicture
                }
              } else {
                pic3 = this.$url.avatarsPicSrc + that.ruleForm.keyUserPictureId
              }
            }
            that.ruleForm.jueserenData.push({
              name: that.ruleForm.keyUserName,
              yaoshirenId: that.ruleForm.keyUserId,
              phone: that.ruleForm.keyUserMobile,
              mendian: ShopName3,
              role: role3,
              roleType: '钥匙人',
              message: '24小时内仅钥匙人可以操作',
              icon: 'iconfont icon-icon_yuechiren',
              jueserenPic: pic3,
              zhongjieName: ShopName3
            })
            that.zhengjianrenId = that.ruleForm.certificatesUserId
            let role4 = that.ruleForm.certificatesUserId == null ? '' : '证件人'
            let pic4 = ''
            let ShopName4 = ''
            if (that.ruleForm.certificatesUserName != null) {
              ShopName4 = that.ruleForm.certificateUserShopName
              if (that.ruleForm.certificatesUserPictureId == null || that.ruleForm.certificatesUserPictureId == '') {
                if (that.ruleForm.certificatesUserGender == '1') {
                  // 男
                  pic4 = boyPicture
                }
                if (that.ruleForm.certificatesUserGender == '2') {
                  // 女
                  pic4 = girlPicture
                }
              } else {
                pic4 = this.$url.avatarsPicSrc + that.ruleForm.certificatesUserPictureId
              }
            }
            that.ruleForm.jueserenData.push({
              name: that.ruleForm.certificatesUserName,
              zhengjianrenId: that.ruleForm.certificatesUserId,
              phone: that.ruleForm.certificatesUserMobile,
              mendian: ShopName4,
              role: role4,
              roleType: '证件人',
              message: '24小时内仅证件人可以操作',
              icon: 'iconfont icon-zhengjianren',
              jueserenPic: pic4,
              zhongjieName: ShopName4
            })
            // that.viprenId = that.ruleForm.vipUserId
            // let role5 = that.ruleForm.vipUserId == null ? "" : "VIP"
            // let pic5 = ""
            // let ShopName5 = ""
            // if (that.ruleForm.vipUserName != null) {
            //     ShopName5 = that.ruleForm.vipUserShopName
            //     if (that.ruleForm.vipUserPictureId == null) {
            //         if (that.ruleForm.vipUserGender == "1") {
            //             // 男
            //             pic5 =  boyPicture
            //         }
            //         if (that.ruleForm.vipUserGender == "2") {
            //             // 女
            //             pic5 =  girlPicture
            //         }
            //     } else {
            //         pic5 =
            //             this.$url.avatarsPicSrc +
            //             that.ruleForm.vipUserPictureId
            //     }
            // }
            // that.ruleForm.jueserenData.push({
            //     name: that.ruleForm.vipUserName,
            //     viprenId: that.ruleForm.vipUserId,
            //     phone: that.ruleForm.vipUserMobile,
            //     mendian: ShopName5,
            //     role: role5,
            //     roleType: "VIP",
            //     message: "24小时内仅VIP可以操作",
            //     icon: "iconfont icon-vip",
            //     jueserenPic: pic5,
            //     zhongjieName: ShopName5
            // })
            // console.log(that.ruleForm.jueserenData)
            that.ruleForm.name = that.ruleForm.customerName
            if (that.ruleForm.shiKanInfo != {} && that.ruleForm.shiKanInfo != null) {
              that.ruleForm.shiKanInfo = JSON.parse(that.ruleForm.shiKanInfo)
              if (that.ruleForm.shiKanInfo.huxingtu != '') {
                that.ruleForm.huxingList.push(that.$url.houses + that.ruleForm.shiKanInfo.huxingtu)
              }
              let shiKanPics = [],
                allShiKanPics = []
              that.shiKanPics == []
              allShiKanPics = Object.values(that.ruleForm.shiKanInfo)
              shiKanPics = that.ruleForm.shiKanInfo
              shiKanPics.huxingtu = ''
              shiKanPics = Object.values(shiKanPics)
              allShiKanPics.forEach(item => {
                if (item != '') {
                  if (item.indexOf(',') != -1) {
                    item = item.split(',')
                    item.forEach(pic => {
                      that.allShiKanPics.push(that.$url.houses + pic)
                    })
                  } else {
                    that.allShiKanPics.push(that.$url.houses + item)
                  }
                }
              })
              shiKanPics.forEach(item => {
                if (item != '') {
                  if (item.indexOf(',') != -1) {
                    item = item.split(',')
                    item.forEach(pic => {
                      that.shiKanPics.push(that.$url.houses + pic)
                    })
                  } else {
                    that.shiKanPics.push(that.$url.houses + item)
                  }
                }
              })
            }

            that.ruleForm.name = that.ruleForm.customerName
            if (that.ruleForm.keyPic != '' && that.ruleForm.keyPic != null) {
              let keyPic = that.ruleForm.keyPic.split(',')
              keyPic.forEach(item => {
                that.keyPic.push(that.$url.houses + item)
              })
            }
            if (that.ruleForm.certificatePics != '' && that.ruleForm.certificatePics != null) {
              let certificatePics = that.ruleForm.certificatePics.split(',')
              certificatePics.forEach(item => {
                that.certificatePics.push(that.$url.houses + item)
              })
            }
            if (that.ruleForm.orientations != undefined && that.ruleForm.orientations != null) {
              let list1 = that.ruleForm.orientations.split('')
              let chaoxiangList1 = []
              list1.forEach(function(item, i) {
                if (i == 0 && item == '1') {
                  chaoxiangList1.push('1')
                }
                if (i == 1 && item == '1') {
                  chaoxiangList1.push('2')
                }
                if (i == 2 && item == '1') {
                  chaoxiangList1.push('3')
                }
                if (i == 3 && item == '1') {
                  chaoxiangList1.push('4')
                }
                if (i == 4 && item == '1') {
                  chaoxiangList1.push('5')
                }
                if (i == 5 && item == '1') {
                  chaoxiangList1.push('6')
                }
                if (i == 6 && item == '1') {
                  chaoxiangList1.push('7')
                }
                if (i == 7 && item == '1') {
                  chaoxiangList1.push('8')
                }
              })
              that.ruleForm.checkedchaoxiang = chaoxiangList1
              let chaoxiangList = []
              chaoxiangList1.forEach(function(item, i) {
                if (item == '1') {
                  chaoxiangList.push('东')
                }
                if (item == '2') {
                  chaoxiangList.push('东南')
                }
                if (item == '3') {
                  chaoxiangList.push('南')
                }
                if (item == '4') {
                  chaoxiangList.push('西南')
                }
                if (item == '5') {
                  chaoxiangList.push('西')
                }
                if (item == '6') {
                  chaoxiangList.push('西北')
                }
                if (item == '7') {
                  chaoxiangList.push('北')
                }
                if (item == '8') {
                  chaoxiangList.push('东北')
                }
              })
              that.ruleForm.orientations = chaoxiangList.join('/')
            }
            if (that.ruleForm.xinXiLaiYuanId != '' && that.xinxilaiyuanOptions.length > 0) {
              that.xinxilaiyuanOptions.forEach((item, i) => {
                item.laiyuan1.forEach((item1, v) => {
                  if (item1.value == that.ruleForm.xinXiLaiYuanId) {
                    that.ruleForm.laiyuan1 = item.value
                    that.ruleForm.laiyuan2 = item1.value
                    that.ruleForm.laiyuanName = item1.label
                  }
                })
              })
            }
            that.getloudongList(that.louPanId)
            console.log('res.data', res.data)
          }
        })
    },
    // 查询列表
    async getloupanList() {
      await this.$http
        .get(this.$url.GetLouPanList, {
          params: {
            louPanNo: this.louPanNo, //楼盘编号
            louPanName: this.louPanNameDialog, //楼盘名称
            areaIds: '', //商圈id
            louPanAddress: '', //楼盘地址
            kaiFaDanWei: '', //开发单位
            isConfirmed: '',
            isPublic: '',
            currentPage: this.loupanDialogCurrentPage,
            pageSize: this.loupanDialogPageSize
          }
        })
        .then(res => {
          var that = this
          if (res.data.msgCode == 1) {
            this.loupanList = res.data.dtList
            this.loupanList.forEach(item => {
              if (item.commonMasterName != null) {
                item.chengqu = item.commonMasterName.split('/')[0]
                item.areaName = item.commonMasterName.split('/')[1]
              }
              if (item.isConfirmed == 1) {
                item.isConfirmed = '是'
              } else {
                item.isConfirmed = '否'
              }
            })
            this.loupanDialogTotal = res.data.maxRecords
          } else {
            that.$message.success(res.data.message)
          }
        })
    },
    loupanDialogDetails(row) {
      let that = this
      that.louPanName = row.louPanName
      that.louPanId = row.louPanId
      setTimeout(function() {
        that.$refs['ruleForm'].clearValidate(['louPanName']) //消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30)
      that.loupanDialog = false
      that.ruleForm.louDongId = ''
      that.ruleForm.danyuan = ''
      that.ruleForm.louceng = ''
      that.ruleForm.menPaiHao = ''
      that.menpaiArray = []
      that.danyuanArray = []
      that.loucengList = []
      that.getloudongList(row.louPanId)
    },
    // 楼栋列表
    async getloudongList(e) {
      var that = this
      that.dongzuoList = []
      that.$http
        .get(this.$url.GetLouDongList, {
          params: {
            louPanId: e, //楼盘编号
            currentPage: this.currentPage,
            pageSize: 99
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            res.data.dtList.forEach((item, i) => {
              that.dongzuoList.push({
                value: item.louDongId,
                label: item.gongChengLouHao
              })
            })
            console.log(that.dongzuoList)
            if (this.ruleForm.louDongId) {
              that.getloudongDetail()
            }
          } else {
            that.$message.success(res.data.message)
          }
        })
    },
    //楼栋详情
    async getloudongDetail() {
      let that = this,
        danyuanHao = '',
        index = 0
      that.danyuanArray = []
      that.loucengArray = []
      that.menpaiArray = []
      await this.$http
        .get(this.$url.GetLouDongDetail, {
          params: {
            louDongId: this.ruleForm.louDongId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            if (res.data.dtList.length > 0) {
              that.louDongInfo = Object.values(
                //楼栋数据
                JSON.parse(res.data.dtList[0].louDongInfo)
              )[0]
              that.louDongFunction()
            }
          } else {
            this.$message.success(res.data.message)
          }
        })
    },
    changeDanYuan(e) {
      let that = this
      that.ruleForm.louceng = ''
      that.ruleForm.menPaiHao = ''
      that.menpaiArray = []
      this.louDongFunction()
    },
    changeLouCeng(e) {
      let that = this
      that.ruleForm.menPaiHao = ''
      that.menpaiArray = []
      this.louDongFunction()
    },
    louDongFunction() {
      let that = this
      that.danyuanArray = Object.keys(that.louDongInfo)
      that.loucengArray = Object.values(that.louDongInfo)
      that.loucengArray.forEach((item, index) => {
        if (that.ruleForm.danyuan.split('单元')[0] - 1 == index) {
          that.loucengList = Object.keys(item)
          if (that.ruleForm.louceng != '') {
            that.menpaiArray = Object.keys(item[that.ruleForm.louceng])
          }
        }
      })
    },
    getShuJuZiDianList() {
      let that = this
      that.$commonJs.getShuJuZiDianList('HUXING,XINXILAIYUAN,JIAOYIQUANSHU,JIANZHUJIEGOU,ZHUANGXIUZHUANGKUANG,FUYONGFANGSHI').then(data => {
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
          this.loading = false
          this.name = '出售新增'
          this.form = {}
        } else if (this.num == 1) {
          this.name = '出售详情'
          this.flag = true
          this.isMax = true
          this.GetHouseDetail()
        } else if (this.num == 2) {
          this.name = '出售修改'
          this.GetHouseDetail()
        }
        this.huxingOptions = data.HuXing
        this.xinxilaiyuanOptions = data.XinXiLaiYuan
        this.jiaoyiquanshuOptions = data.JiaoYiQuanShu
        this.jianzhujiegouOptions = data.JianZhuJieGou
        // this.fangwuyongtuOptions = data.FANGWUYONGTU
        this.zhuangxiuOptions = data.ZHUANGXIUZHUANGKUANG
        this.fukuanyaoqiuList = data.FUYONGFANGSHI
        if (that.louPanId) {
          this.getloupanDetail()
        }
      })
    },
    // GetCheckTelExists(tel, type) {
    //     let that = this
    //     var telRule = /^1[3456789]\d{9}$/
    //     if (tel != "" && telRule.test(tel)) {
    //         const data = {
    //             tel: tel
    //         }
    //         this.$http
    //             .get(this.$url.GetCheckTelExists, {
    //                 params: {
    //                     tel: tel
    //                 }
    //             })
    //             .then((res) => {
    //                 if (res.data.msgCode == 1) {
    //                     if (type == "tel1Check") {
    //                         if (res.data.exist == 1) {
    //                             this.tel1Check = 1
    //                             this.$message.error("此手机号已存在！")
    //                         } else {
    //                             this.tel1Check = 0
    //                         }
    //                     } else if (type == "tel2Check") {
    //                         if (res.data.exist == 1) {
    //                             this.tel2Check = 1
    //                             this.$message.error("此手机号已存在！")
    //                         } else {
    //                             this.tel2Check = 0
    //                         }
    //                     }
    //                     console.log(res.data)
    //                 } else {
    //                     this.$message.error(res.data.message)
    //                 }
    //             })
    //     }
    // },
    // 楼盘字典详情
    async getloupanDetail() {
      var that = this
      await that.$http
        .get(that.$url.GetLouPanDetail, {
          params: {
            louPanId: that.louPanId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            let data = JSON.parse(res.data.dtList[0].louPanInfo)
            that.ruleForm = Object.assign(that.ruleForm, data)
            if (that.checkedchaoxiang != undefined && that.checkedchaoxiang != null) {
              let Array = that.checkedchaoxiang.split('/')
              Array.forEach(function(item, i) {
                if (item == '东') {
                  that.ruleForm.checkedchaoxiang.push('1')
                }
                if (item == '东南') {
                  that.ruleForm.checkedchaoxiang.push('2')
                }
                if (item == '南') {
                  that.ruleForm.checkedchaoxiang.push('3')
                }
                if (item == '西南') {
                  that.ruleForm.checkedchaoxiang.push('4')
                }
                if (item == '西') {
                  that.ruleForm.checkedchaoxiang.push('5')
                }
                if (item == '西北') {
                  that.ruleForm.checkedchaoxiang.push('6')
                }
                if (item == '北') {
                  that.ruleForm.checkedchaoxiang.push('7')
                }
                if (item == '东北') {
                  that.ruleForm.checkedchaoxiang.push('8')
                }
              })
            }
            let first = res.data.dtList[0].areaNames.split(',')
            console.log(first)
            let second = []
            first.forEach((item, i) => {
              second.push(item.split('/'))
            })
            console.log(second)
            second.forEach((item, i) => {
              that.ruleForm.suozaichengqu.push(item[0])
              that.ruleForm.suoshushangquan.push(item[1])
            })
            that.unique(that.ruleForm.suozaichengqu)
            if (that.ruleForm.dianTi == '0') {
              that.ruleForm.dianTi = '否'
            } else if (that.ruleForm.dianTi == '1') {
              that.ruleForm.dianTi = '是'
            }
            if (that.ruleForm.ranQi == '0') {
              that.ruleForm.ranQi = '否'
            } else if (that.ruleForm.ranQi == '1') {
              that.ruleForm.ranQi = '是'
            }
            if (that.ruleForm.reShui == '0') {
              that.ruleForm.reShui = '否'
            } else if (that.ruleForm.reShui == '1') {
              that.ruleForm.reShui = '是'
            }
            if (that.ruleForm.zhongShui == '0') {
              that.ruleForm.zhongShui = '否'
            } else if (that.ruleForm.zhongShui == '1') {
              that.ruleForm.zhongShui = '是'
            }
            if (that.ruleForm.youErYuan == '0') {
              that.ruleForm.youErYuan = '没有'
            } else if (that.ruleForm.youErYuan == '1') {
              that.ruleForm.youErYuan = '有'
            }
            for (let i = 0; i < that.jiaoyiquanshuOptions.length; i++) {
              if (that.jiaoyiquanshuOptions[i].value == that.ruleForm.jiaoYiQuanShu) {
                that.ruleForm.jiaoYiQuanShu = that.jiaoyiquanshuOptions[i].label
              }
            }
            for (let i = 0; i < that.jianzhujiegouOptions.length; i++) {
              if (that.jianzhujiegouOptions[i].value == that.ruleForm.jianZhuJieGou) {
                that.ruleForm.jianZhuJieGou = that.jianzhujiegouOptions[i].label
              }
            }
            // for (
            //     let i = 0;
            //     i < that.fangwuyongtuOptions.length;
            //     i++
            // ) {
            //     if (
            //         that.fangwuyongtuOptions[i].value ==
            //         that.ruleForm.fangWuYongTu
            //     ) {
            //         that.ruleForm.fangWuYongTu =
            //             that.fangwuyongtuOptions[i].label
            //     }
            // }
            console.log(that.ruleForm)
          } else {
            console.log(res.data.message)
          }
        })
    },
    changeLouDong(e) {
      this.ruleForm.danyuan = ''
      this.danyuanArray = []
      this.loucengList = []
      this.menpaiArray = []
      this.ruleForm.louceng = ''
      this.ruleForm.menPaiHao = ''
      this.ruleForm.louDongId = e
      this.ruleForm.louDongId = e
      this.getloudongDetail()
    },
    unique(arr) {
      this.ruleForm.suozaichengqu = Array.from(new Set(arr))
    },
    // 朝向多选选中
    handleCheckedchaoxiang(val) {
      let that = this
      if (val.indexOf('1') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[0] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[0] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('2') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[1] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[1] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('3') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[2] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[2] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('4') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[3] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[3] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('5') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[4] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[4] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('6') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[5] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[5] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('7') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[6] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[6] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      if (val.indexOf('8') != -1) {
        const Array = that.checkedchaoxiang.split('')
        Array[7] = '1'
        that.checkedchaoxiang = Array.join('')
      } else {
        const Array = that.checkedchaoxiang.split('')
        Array[7] = '0'
        that.checkedchaoxiang = Array.join('')
      }
      console.log(that.checkedchaoxiang)
    },
    getUserList() {
      let that = this
      that.weihurenDialogLoading = true
      that.$http
        .get(that.$url.GetJingJiRenList, {
          params: {
            isHomePage: '0',
            userName: that.userName,
            // merchantId: localStorage.getItem("merchantId"),
            currentPage: encodeURIComponent(that.userCurrentPage),
            pageSize: encodeURIComponent(that.userPageSize)
          }
        })
        .then(function(res) {
          if (res.data.msgCode == 1) {
            that.weihurenDialogLoading = false
            that.weihurenDialogList = res.data.dtList
            that.loading = false
            that.total = res.data.maxRecords
            for (var i = 0; i < res.data.dtList.length; i++) {
              if (res.data.dtList[i].gender === '1') {
                that.weihurenDialogList[i].gender = '男'
              } else if (res.data.dtList[i].gender === '2') {
                that.weihurenDialogList[i].gender = '女'
              }

              if (res.data.dtList[i].userType === '1') {
                that.weihurenDialogList[i].userType = '经纪人'
              } else if (res.data.dtList[i].userType === '2') {
                that.weihurenDialogList[i].userType = '商圈经理'
              } else if (res.data.dtList[i].userType === '3') {
                that.weihurenDialogList[i].userType = '商户财务'
              } else if (res.data.dtList[i].userType === '4') {
                that.weihurenDialogList[i].userType = '人事专员,'
              } else if (res.data.dtList[i].userType === '5') {
                that.weihurenDialogList[i].userType = '店东'
              } else if (res.data.dtList[i].userType === '6') {
                that.weihurenDialogList[i].userType = '平台管理员'
              } else if (res.data.dtList[i].userType === '7') {
                that.weihurenDialogList[i].userType = '交易中心'
              }

              if (res.data.dtList[i].enabled === '1') {
                that.weihurenDialogList[i].enabled = '是'
              } else if (res.data.dtList[i].enabled === '0') {
                that.weihurenDialogList[i].enabled = '否'
              }
            }
            that.weihurenDialogTotal = res.data.maxRecords
          } else {
            that.weihurenDialogLoading = false
            var message = that.$commonJs.getUrlError(res.data.message)
            this.$message.error(message)
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //选择指定维护人
    clickUser(type) {
      this.weihurenDialog = true
      this.weihutype = type
      this.userName = ''
      // if (type == 1) {
      //     this.userName1 = ""
      // } else {
      //     this.userName2 = ""
      // }
    },
    //重置维护人
    ResetWeihuren() {
      this.userName = ''
      this.userName1 = ''
      this.getUserList()
    },
    async onSave(formName, type) {
      let that = this
      if (type == 'add') {
        that.$refs[formName].validate(async valid => {
          if (that.num == '0') {
            if (valid) {
              that.saveDisabled = true
              that.form1content =
                '房屋现状:' +
                this.ruleForm.fangWuXianZhuang +
                ', \n' +
                '是否唯一:' +
                this.ruleForm.isWeiYi +
                ', \n' +
                '售房原因:' +
                this.ruleForm.shouFangYuanYin +
                ', \n' +
                '户口情况:' +
                this.ruleForm.HukoQingKuang +
                ', \n' +
                '抵押情况:' +
                this.ruleForm.diYa +
                ', \n' +
                '是否随时可签:' +
                this.ruleForm.isKeQian +
                ', \n' +
                '产权是否共有:' +
                this.ruleForm.isGongYou +
                ', \n' +
                '联系人是否为业主:' +
                this.ruleForm.isYeZhu +
                ', \n' +
                '是否认可中介费:' +
                this.ruleForm.zhongjiefei
            } else {
              that.form1content = that.ruleForm.createrRemark
            }
            console.log('this.ruleForm', that.ruleForm)
            if (that.tel1Check == 1 || that.tel2Check == 1) {
              that.$message.error('此手机号已存在！')
              that.saveDisabled = false
              return false
            }
            if (that.ruleForm.isJiShou) {
              that.ruleForm.isJiShou = '1'
            } else {
              that.ruleForm.isJiShou = '0'
            }
            const data = {
              IsOnUrgentSale: that.ruleForm.isJiShou,
              createPerson: that.ruleForm.createPerson,
              isZhengZu: that.ruleForm.chuzufangshi,
              houseAge: that.ruleForm.houseAge,
              zhuangXiuZhuangKuangId: that.ruleForm.zhuangxiu,
              fangZhuangXianKuang: that.ruleForm.fangZhuangXianKuang,
              // FangWuYongTuId: that.ruleForm.yongtu,
              louPanId: that.louPanId,
              louDongId: that.ruleForm.louDongId,
              // danyuan: that.ruleForm.danyuan,
              louCeng: that.ruleForm.louceng.split('层')[0] * 1,
              menPaiHao: that.ruleForm.menPaiHao,
              CustomerName: that.ruleForm.name,
              WechatNo: that.ruleForm.weChatNo,
              Tel1: that.ruleForm.tel1,
              Tel2: that.ruleForm.tel2,
              idCardNo: that.ruleForm.idCardNo,
              huXingId: that.ruleForm.huxing,
              square: that.ruleForm.square * 1,
              price: that.ruleForm.priceToSell * 10000,
              KanFangShiJian: that.ruleForm.time,
              xinXiLaiYuanId: that.ruleForm.laiyuan2,
              // laiyuan1: that.ruleForm.laiyuan1,
              // createrRemark: that.ruleForm.createrRemark,
              createrRemark: that.form1content,
              orientations: that.checkedchaoxiang,
              jiaoYiQuanShuId: that.ruleForm.quanshu,
              jianZhuJieGouId: that.ruleForm.jianzhujiegou,
              zhengJianZhuangTai: that.ruleForm.zhengJianZhuangTai,
              IsHouseToSell: '1',
              MaintenanceUserId: that.ruleForm.maintenanceUserId
            }
            that.$http
              .post(that.$url.InsertHouse, data, {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
                }
              })
              .then(res => {
                that.saveDisabled = false
                if (res.data.msgCode == 1) {
                  that.$message.success('新增出售房源成功')
                  that.$router.push({ path: '/ChuShouList' })
                } else {
                  that.$message.error(res.data.message)
                }
              })
          } else {
            that.saveDisabled = false
            return false
          }
        })
      } else if (type == 'undate') {
        that.$refs[formName].validate(async valid => {
          console.log('this.ruleForm', that.ruleForm)
          if (valid) {
            that.saveDisabled = true
            console.log('that.ruleForm.houseToSellId', that.ruleForm.houseToSellId)
            if (that.ruleForm.isJiShou == true) {
              that.ruleForm.isJiShou = '1'
            } else {
              that.ruleForm.isJiShou = '0'
            }
            const data = {
              IsOnUrgentSale: that.ruleForm.isJiShou,
              createPerson: that.ruleForm.createPerson,
              houseId: that.houseId,
              louPanId: that.louPanId,
              louDongId: that.ruleForm.louDongId,
              square: that.ruleForm.square * 1,
              louCeng: that.ruleForm.louceng.split('层')[0] * 1,
              houseAge: that.ruleForm.houseAge,
              huXingId: that.ruleForm.huxing,
              fangZhuangXianKuang: that.ruleForm.fangZhuangXianKuang,
              // FangWuYongTuId: that.ruleForm.yongtu,
              zhengJianZhuangTai: that.ruleForm.zhengJianZhuangTai,
              menPaiHao: that.ruleForm.menPaiHao,
              orientations: that.checkedchaoxiang,
              jiaoYiQuanShuId: that.ruleForm.quanshu,
              xinXiLaiYuanId: that.ruleForm.laiyuan2,
              KanFangShiJian: that.ruleForm.time,
              zhuangXiuZhuangKuangId: that.ruleForm.zhuangxiu,
              createrRemark: that.ruleForm.createrRemark,
              JianZhuJieGouId: that.ruleForm.jianzhujiegou,
              price: that.ruleForm.priceToSell * 10000,
              IsHouseToSell: '1',
              houseToSellId: that.ruleForm.houseToSellId,
              MaintenanceUserId: that.ruleForm.maintenanceUserId,
              CustomerName: that.ruleForm.name,
              WechatNo: that.ruleForm.weChatNo,
              Tel1: that.ruleForm.tel1,
              Tel2: that.ruleForm.tel2,
              idCardNo: that.ruleForm.idCardNo
              // customerId: that.ruleForm.customerId
            }
            that.$http
              .put(that.$url.UpdateHouse, data, {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
                }
              })
              .then(res => {
                that.saveDisabled = false
                if (res.data.msgCode == 1) {
                  that.$message.success('修改出售房源成功')
                  that.$router.push({ path: '/ChuShouList' })
                } else {
                  that.$message.error(res.data.message)
                }
              })
          } else {
            that.saveDisabled = false
            return false
          }
        })
      }
    },
    SearchLoupan() {
      this.louPanNo = '' //楼盘编号
      this.louPanName = this.louPanNameDialog //楼盘名称
      this.areaIds = '' //商圈id
      this.louPanAddress = '' //楼盘地址
      this.kaiFaDanWei = '' //开发单位
      this.isConfirmed = ''
      this.isPublic = ''
      this.loupanDialogCurrentPage = 1
      this.loupanDialogPageSize = 10
      this.getloupanList()
    },
    ResetLoupan() {
      this.louPanNo = '' //楼盘编号
      this.louPanName = '' //楼盘名称
      this.louPanNameDialog = ''
      this.areaIds = '' //商圈id
      this.louPanAddress = '' //楼盘地址
      this.kaiFaDanWei = '' //开发单位
      this.isConfirmed = ''
      this.isPublic = ''
      this.loupanDialogPageSize = 1
      this.loupanDialogPageSize = 10
      this.getloupanList()
    },
    cancel() {
      this.$router.go(-1)
    },
    changeLaiyuan() {
      this.ruleForm.laiyuan2 = ''
      this.ruleForm.laiyuanName = ''
    },
    loupanDialogSizeChange(newSize) {
      this.loupanDialogPageSize = newSize
      this.getloupanList()
    },
    /* 监听页码值改变的事件 */
    loupanDialogCurrentChange(newPage) {
      this.loupanDialogCurrentPage = newPage
      this.getloupanList()
    },
    weihurenDialogDetails(row) {
      console.log(row)
      let that = this
      that.userName = row.userName
      if (this.weihutype == 1) {
        that.userName1 = row.userName
        that.ruleForm.maintenanceUserId = row.userId
      } else if (this.weihutype == 2) {
        that.userName2 = row.userName
        that.ruleForm.createPerson = row.userId
      }
      setTimeout(function() {
        that.$refs.ruleForm.clearValidate(['maintenanceUserId']) // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
      }, 30)
      that.weihurenDialog = false
    },
    weihurenDialogSizeChange(newSize) {
      this.userPageSize = newSize
      this.getUserList()
    },
    /* 监听页码值改变的事件 */
    weihurenDialogCurrentChange(newPage) {
      this.userCurrentPage = newPage
      this.getUserList()
    },
    goBack() {
      if (this.Ftype == 'fangyuan') {
        this.addTab('房源共享池', 'FangYuanGongXiangChiList')
        this.$router.push('FangYuanGongXiangChiList')
      } else if (this.Ftype == 'fenpei') {
        this.addTab('分配房源', 'FenPeiZiYuanList')
        this.$router.push('FenPeiZiYuanList')
      } else if (this.Ftype == 'jiaoyi') {
        this.addTab('成交记录', 'JiaoYiJiLuList')
        this.$router.push('JiaoYiJiLuList')
      } else {
        this.addTab('二手房出售', 'ChuShouList')
        this.$router.push('ChuShouList')
      }
    },
    // 点击导航栏新增tab页
    addTab(targetName, path) {
      let flag = true //判断是否需要新增页面
      var tabs = this.editableTabs //活跃当前tab数组
      for (var i = 0; i < tabs.length; i++) {
        //如果存在相同的tab页 不新增tab页
        if (tabs[i].path === path) {
          this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
          this.$router.push(path)
          flag = false
        }
      }
      // 需要新增页面
      if (flag) {
        let newTabName = this.editableTabs.length.toString()
        this.editableTabs.push({
          title: targetName,
          name: newTabName,
          path: path
        })
        this.editableTabsValue = newTabName
        window.localStorage.setItem('editableTabs', JSON.stringify(this.editableTabs))
      }
      this.reload()
      window.localStorage.setItem('activeIndex', path)
    },
    //图片上传成功
    success(response) {
      if (response.msgCode == '1') {
        this.picIds.push(response.id)
        this.$message.success('上传图片成功')
      } else if (response.msgCode !== '1') {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },
    //图片上传id
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //图片上传失败
    error: function(err, file, fileList) {
      this.$message.error('上传失败')
      if (fileList.length >= 1) {
        this.isMax = true
      } else {
        this.isMax = false
      }
    },
    //图片上传删除
    handleBeforeRemove(file, fileList) {
      var mm = ''
      if (this.type == 'update' && file.response == undefined) {
        mm = file.url.slice(42)
        return this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var data = {
            fileId: mm,
            moduleName: 'pet'
          }
          this.$http.delete(this.$url.deleteOneFile(mm, 'pet'), {
            data
          })
          this.$message.success('删除成功!')
          let ids = new Set(this.picIds)
          ids.delete(mm)
          this.picIds = Array.from(ids)
          this.isMax = false
        })
      } else {
        mm = file.response.id
        let ids = new Set(this.picIds)
        ids.delete(mm)
        this.picIds = Array.from(ids)
      }
    },
    handleCommand(e) {
      let name = ''
      if (e == 'weihu') {
        name = 'WeiHuDetail'
      } else if (e == 'shikan') {
        name = 'ShiKanDetail'
      } else if (e == 'yaoshi') {
        name = 'FangYuanYaoShiDetail'
      } else if (e == 'zhengjian') {
        name = 'ZhengJianDetail'
      } else if (e == 'yezhu') {
        name = 'YeZhuXinXi'
      }
      if (name == 'WeiHuDetail' && ((this.weihurenId == this.weihurenId1 && this.roleType == 1) || this.roleType == 6 || (this.roleType == 5 && this.canMerchantEdit == 1))) {
        this.router(name)
      } else if (name == 'ShiKanDetail' && (this.shikanrenId == null || this.shikanrenId == this.weihurenId1 || this.roleType == 6 || (this.roleType == 5 && this.canMerchantEdit == 1))) {
        this.router(name)
      } else if (name == 'FangYuanYaoShiDetail' && (this.yaoshirenId == null || this.yaoshirenId == this.weihurenId1 || this.roleType == 6 || (this.roleType == 5 && this.canMerchantEdit == 1))) {
        this.router(name)
      } else if (name == 'ZhengJianDetail' && (this.zhengjianrenId == null || this.zhengjianrenId == this.weihurenId1 || this.roleType == 6 || (this.roleType == 5 && this.canMerchantEdit == 1))) {
        this.router(name)
      } else if (name == 'YeZhuXinXi') {
        this.router(name)
      } else {
        this.$message.error('当前用户没有此权限!')
        return false
      }
    },
    router(page) {
      this.$router.push({
        name: page,
        query: {
          houseId: this.houseId,
          type: 2
        }
      })
    },
    applyInsert() {
      if (this.roleType == 1) {
        this.shenqingRequirementVisible = true
      } else if (this.roleType == 6) {
        this.$router.push({
          name: 'LouPanZiDian1Detail1',
          query: {
            louPanId: 0,
            num1: 0,
            state: 1
          }
        })
      }
    },
    jump(domId) {
      this.jumpType = domId
      // 当前窗口正中心位置到指定dom位置的距离
      //页面滚动了的距离
      let height = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      //指定dom到页面顶端的距离
      let dom = document.getElementById(domId)
      let domHeight = dom.offsetTop

      //滚动距离计算
      var S = Number(height) - Number(domHeight)

      //判断上滚还是下滚
      if (S < 0) {
        //下滚
        S = Math.abs(S)
        window.scrollBy({ top: S, behavior: 'smooth' })
      } else if (S == 0) {
        //不滚
        window.scrollBy({ top: 0, behavior: 'smooth' })
      } else {
        //上滚
        S = -S
        window.scrollBy({ top: S, behavior: 'smooth' })
      }
    },
    examine() {
      //审核
      const data = {
        HouseId: this.houseId,
        IsHouseToSell: '1'
      }
      this.$http
        .post(this.$url.ConfirmHouse, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.$message.success('审核通过!')
            this.$router.go(-1)
          } else {
            this.$message.error(res.data.message)
          }
        })
    },
    reject() {
      //审核不通过
      this.$confirm('审核不通过会删除此房源，是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          HouseId: this.houseId,
          IsHouseToSell: '1'
        }
        this.$http
          .post(this.$url.NotConfirmHouse, data, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(res => {
            if (res.data.msgCode == 1) {
              this.$message.success('房源已删除!')
              this.$router.go(-1)
            } else {
              this.$message.error(res.data.message)
            }
          })
      })
    },
    openAddress() {
      if (this.ruleForm.menPaiHao == '') {
        this.$message.error('您没有权限，请联系维护人！')
        return false
      }
      this.showLouCeng = !this.showLouCeng
      this.showMenPai = !this.showMenPai
      console.log(this.showLouCeng)
    },
    // 检查重复
    inspect() {
      const YanZheng = ['menPaiHao', 'louceng', 'danyuan', 'louDongId', 'louPanName']
      for (var i = 0; i < YanZheng.length; i++) {
        this.$refs.ruleForm.validateField(YanZheng[i], async valid => {
          if (!valid) {
            if (i == 0) {
              const data = {
                IsHouseToSell: '1',
                LouPanId: this.louPanId,
                LouDongId: this.ruleForm.louDongId,
                LouCeng: this.ruleForm.louceng.split('层')[0] * 1,
                MenPaiHao: this.ruleForm.menPaiHao
              }
              const { data: res } = await this.$http.post(this.$url.CheckHouseExist, data)
              if (res.msgCode == 1) {
                this.$confirm(res.message, {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
              } else {
                this.$confirm(res.message, {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                  .then()
                  .catch(err => {
                    console.log(err)
                  })
              }
              return false
            }
          }
        })
      }
    },
    priceShow() {
      this.priceDialog = true
    },
    //  pricehide() {
    //   this.priceDialog = false;
    // }
    priceDialogSizeChange(newSize) {
      this.priceDialogPageSize = newSize
      this.GetHouseDetailPriceRecordList()
    },
    /* 监听页码值改变的事件 */
    priceDialogCurrentChange(newPage) {
      this.priceDialogCurrentPage = newPage
      this.GetHouseDetailPriceRecordList()
    },
    chengjiao() {
      var that = this
      const data = {
        houseId: that.houseId,
        IsHouseToSell: '1',
        houseToSellId: that.ruleForm.houseToSellId
      }
      that.$http
        .put(that.$url.UpdateHouseIsChengJiao, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.$message.success('设置成功')
            that.$router.push({ path: '/ChuShouList' })
          } else {
            that.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped lang="less">
.xuqiuDialog /deep/ .el-dialog__body {
  padding: 10px 20px;
}

.xuqiuForm-item {
  display: flex;

  /deep/ .el-form-item {
    width: 29%;
    margin-bottom: 0px !important;
  }

  /deep/ .el-form-item__content {
    margin-left: 0px;
  }
}

/deep/ .el-carousel__indicator--horizontal {
  display: none !important;
}

.disabled .el-upload--picture-card {
  display: none;
}

.el-card {
  margin-top: 10px;
}

.el-textarea__inner {
  border: 1px solid #cccccc;
  color: black;
  font-weight: bold;
}

.juese {
  margin: 0;
  width: 12%;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  float: right;
  margin-right: 34%;
  background-color: #df0202;
  color: white;
  text-align: center;
}

.company {
  margin: 0;
  width: 100px;
  height: 22px;
  position: relative;
  bottom: 21px;
  line-height: 22px;
  font-size: 12px;
  background-color: rgba(0, 255, 136, 0.3);
  color: white;
  text-align: center;
}

.jueseren {
  display: flow-root;
  height: 100px;
}

.weihu {
  height: 25px;
  line-height: 20px;
  font-size: 14px;
  margin-top: 0px;
}

.XinXimarginTop {
  margin-top: 5px;
}

/deep/.redItem .el-form-item__label {
  color: #df0202;
  font-size: 15px !important;
  font-weight: bold;
}

.hide .el-upload--picture-card {
  display: none;
}

.keyMen {
  width: 290px;
  margin-bottom: 10px;
  border-radius: 10px;
  height: 140px;
  font-size: 14px;
  background: #eee;
  text-align: center;
  // line-height: 50px;
  color: #ccc;
}

.maodian {
  padding-bottom: 0px;
  padding-top: 10px;
}

.nav1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 30px;
  margin: 10px 0;
}

.nav1-active {
  background: #ffc1c1;
  border-color: #ffd3d3;
  color: #df0202;
}

/deep/ .el-carousel__container {
  position: relative;
  height: 18vw !important;
}

.contantHouseNo {
  font-size: 14px;
  margin-left: 10px;
  color: #000;
}

.el-button + .el-button {
  margin-left: 0px;
}

.el-button1 {
  margin-left: 5px !important;
}

.shenqing,
.el-button--primary,
.el-button--primary:focus {
  margin-right: 10px;
}
</style>
