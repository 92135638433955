export default {
  // 表单对象
  form: {
    companyName: "x", //企业名称
    companyMobile: "x", //企业联系电话
    contractNo: "x", //合同编号
    // 甲方
    jiaName: "x", //甲方出租方姓名
    jiaIdCardNo: "x", //甲方出租方证件号码
    jiaMobile: "x", // 甲方出租方联系电话
    // 甲方代理
    jiaDLName: "x", //甲方出租方代理人姓名
    jiaDLIdCardNo: "x", //甲方出租方代理人证件号码
    jiaDLMobile: "x", // 甲方出租方代理人联系电话
    // 乙方
    yiName: "x", //乙方出租方姓名
    yiIdCardNo: "x", //乙方出租方证件号码
    yiMobile: "x", // 乙方出租方联系电话
    // 乙方代理
    yiDLName: "x", //乙方出租方代理人姓名
    yiDLIdCardNo: "x", //乙方出租方代理人证件号码
    yiDLMobile: "x", // 乙方出租方代理人联系电话
    parkingLotType:"",//车位类型
    parkingLot: "x", //车位or车库
    parkingLotNo:"x",//车位编号

    // 第一条 停车位详情
    fir_xiaoquName: "", //小区名称
    fir_area: "x", //建筑面积
    fir_carName: "x", //停放车辆型号及牌照

    // 第二条 租赁期限
    sec_zuLinFromYear: "x", //初始日期 年
    sec_zuLinFromMonth: "x", //初始日期 月
    sec_zuLinFromDay: "x", //初始日期 日
    sec_zuLinToYear: "x", //截止日期 年
    sec_zuLinToMonth: "x", //截止日期 月
    sec_zuLinToDay: "x", //截止日期 日
    sec_monthNumber: "x", //租赁的月份
    sec_jiaofuYear1: "x", //交付日期 年
    sec_jiaofuMonth1: "x", //交付日期 月
    sec_jiaofuDay1: "x", //交付日期 日

    // 第三条 租金、押金及其支付方式
    // 1.
    thi_zujinType: "", //租金方式
    thi_tiqianDay: "x", //到期前几天支付下期租金
    thi_yuqiDay: "x", //逾期几天后
    // 2.
    thi_tiaojian: "x", //日期或条件
    thi_zujinMonth: "x", //几个月租金
    thi_zujin_xiaoxie: "x", //人民币租金小写
    thi_zujin_daxie: "x", //人民币租金大写
    // 3.
    thi_payType: "", //支付方式
    thi_kaihuName: "x", //开户人
    thi_zhangHao: "x", //账号
    thi_kaiHuHang: "x", //开户行

    // 第四条 其他费用
    // 1.
    fou_zhiFuFang: "x", //支付方 向丙方支付中介费
    fou_zhongjieFei_xiaoxie: "x", //中介费小写
    fou_zhongjieFei_daxie: "x", //中介费大写
    // 2.
    fou_chengDanFang: "x", //承担方
    // 3.
    fou_jiaoFeiFang: "x", //缴费方


    // 签约
    qianShu_Year: "x", // 签署日期-年
    qianShu_Month: "x", // 签署日期-月
    qianShu_Day: "x", // 签署日期-日
        qianshu_jjrName:"x",// 经纪人姓名
    qianshu_jjrMobile:"x",// 经纪人手机号

    // 单选的值
    zjFuKuanFangShi_Month: "□", //租金的付款方式-月
    zjFuKuanFangShi_JiFu: "□", //租金的付款方式-季付
    zjFuKuanFangShi_BanNianFu: "□", //租金的付款方式-半年付
    zjFuKuanFangShi_Year: "□", //租金的付款方式-年付

    zjFuKuanFangShi_YinHangZhuangZhang: "□", //租金的付款方式-银行转账
    zjFuKuanFangShi_XianJin: "□", //租金的付款方式-现金

    diShangCheWei: "□", //地上车位
    diXiaCheWei: "□" //地下车位

  },
  formCheck: {
    thi_zujinType: "", //租金方式
    thi_payType: "", //支付方式
    parkingLotType: "", //停车位类型
  },
};
