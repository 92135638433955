<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <el-button size="mini" class="el-button1" type="warning" style="float: right" @click="cancelCommon">返回</el-button>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%" style="margin-left: 3%;">
                <el-row>
                    <el-col :span="12">
                        <el-form-item v-if="num == 2 || num == 0" label="公告类别:" label-width="20%" prop="noticeType" style="margin-top: 0px;">
                            <el-select :disabled="flag" v-model="form.noticeType" placeholder="请选择公告类别" style="width: 80%">
                                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="num == 1" label="公告类别:" label-width="20%" prop="noticeType">{{ form.noticeType }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item v-if="num == 2 || num == 0" label="公告标题:" label-width="10%" prop="articleTitle">
                            <el-input :disabled="flag" style="width:  35.6%" v-model="form.articleTitle" placeholder="请输入公告标题"></el-input>
                        </el-form-item>
                        <el-form-item v-if="num == 1" label="公告标题:" label-width="10%" prop="articleTitle">{{ form.articleTitle }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item v-if="num == 2 || num == 0" label="公告内容:" label-width="10%" prop="articleContent">
                            <el-input :disabled="flag" style="width: 90%" type="textarea" :rows="20" placeholder="请输入内容" v-model="form.articleContent">
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="num == 1" label="公告内容:" label-width="10%" prop="articleContent" style="white-space: pre-line;">{{form.articleContent}}</el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="this.roleType== '6' || this.roleType == '5' || roleType == '2'">
                    <!-- 发送群组 -->
                    <el-col :span="24" style="">
                        <el-form-item label="发送群组:" prop="notice" label-width="10%" class="notice-pad">
                            <el-select :disabled="isMax" v-if="this.roleType==6" @change="handle" v-model="form.notice" multiple :placeholder="placeholder" class="noticePadding" style="width: 36%">
                                <el-option v-for="item in noticeOptions" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" multiple>
                                </el-option>
                            </el-select>
                            <el-select :disabled="isMax" v-if="this.roleType==5" @change="handle" v-model="form.notice" multiple :placeholder="placeholder" class="noticePadding" style="width: 36%">
                                <el-option v-for="item in noticeOptions" :key="item.shopId" :label="item.shopName" :value="item.shopId" multiple>
                                </el-option>
                            </el-select>
                            <!-- <el-cascader collapse-tags style="margin-top: 6px;width:35.7%" ref="cascader" @change="handleChange" v-model="form.notice" :options="treeData" :props="props" clearable></el-cascader> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%" style="margin-top:12px">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],

    watch: {
        value(val) {
            this.articleContent = this.value
        },
        enabled(val) {
            if (val == "1") {
                this.isShow = false
            } else {
                this.isShow = true
            }
        }
    },
    data() {
        return {
            placeholder: "请选择通知群组（可多选）",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            current: this.$route.query.current,
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            name: "",
            mm: "",
            options: [
                {
                    value: "通知所有人",
                    label: "通知所有人",
                    disabled: false
                },
                {
                    value: "zhinan",
                    label: "指南",
                    disabled: false,
                    children: [
                        {
                            value: "shejiyuanze",
                            label: "设计原则",
                            children: [
                                {
                                    value: "yizhi",
                                    label: "一致"
                                },
                                {
                                    value: "fankui",
                                    label: "反馈"
                                },
                                {
                                    value: "xiaolv",
                                    label: "效率"
                                },
                                {
                                    value: "kekong",
                                    label: "可控"
                                }
                            ]
                        }
                    ]
                },
                {
                    value: "9",
                    label: "指南",
                    disabled: false
                }
            ],
            options1: [
                {
                    value: "重大事项",
                    label: "重大事项"
                },
                {
                    value: "交易提示",
                    label: "交易提示"
                },
                {
                    value: "一般公告",
                    label: "一般公告"
                }
            ],
            flag: false,
            isMax: false,
            loading: true,
            form: {
                articleTitle: "",
                articleContent: "",
                noticeType: "",
                weight: "",
                enabled: "",
                // 通知群组
                notice: []
            },
            merchantName: "",
            enabled: "",
            isShow: false,
            currentPage: 1,
            pageSize: 10,
            treeData: [],
            rules: {
                articleTitle: [
                    {
                        required: true,
                        message: "请输入文章标题",
                        trigger: "blur"
                    }
                ],
                articleContent: [
                    {
                        required: true,
                        message: "请选输入文章内容",
                        trigger: "blur"
                    }
                ],
                notice: [
                    {
                        required: true,
                        message: "请选择发送群组",
                        trigger: "blur"
                    }
                ],
                noticeType: [
                    {
                        required: true,
                        message: "请选择公告类别",
                        trigger: "change"
                    }
                ]
            },
            noticeOptions: [
                {
                    value: "1",
                    label: "A店"
                },
                {
                    value: "2",
                    label: "B店"
                },
                {
                    value: "3",
                    label: "C店"
                },
                {
                    value: "4",
                    label: "D店"
                },
                {
                    value: "5",
                    label: "F店"
                }
            ],
            daQuInclude: "",
            shopName: "",
            merchantId: "",
            notice: "",
            roleType: ""
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "公告新增"
            this.form = {}
            if (this.roleType == "6") {
                this.GetArticleDisplayGroup(1)
            }
            if (this.roleType == "5") {
                this.GetArticleDisplayGroup(0)
            }
        } else if (this.num == 1) {
            this.name = "公告详情"
            this.flag = true
            this.isMax = true
            this.getArticleDetail()
            // this.getMerchantList()
        } else if (this.num == 2) {
            this.name = "公告修改"
            this.isMax = true
            this.getArticleDetail()
        }
    },
    methods: {
        handle() {
            console.log(this.form.notice.join(","))
        },
        // 获取商户列表
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.noticeOptions = res.dtList
                // this.$message.success("获取列表成功")
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        async GetArticleDisplayGroup(ID, type) {
            var that = this
            const { data: res } = await this.$http.get(
                this.$url.GetArticleDisplayGroup,
                {
                    params: {
                        isPlatfrom: ID
                    }
                }
            )
            if (res.msgCode == 1) {
                that.noticeOptions = res.dtList
                if (type == "xq") {
                    that.notice.forEach(function (i) {
                        that.noticeOptions.forEach(function (n) {
                            if (ID == 1) {
                                if (i == n.merchantId) {
                                    that.form.notice.push(n.merchantName)
                                }
                            } else {
                                if (i == n.shopId) {
                                    that.form.notice.push(n.shopName)
                                }
                            }
                        })
                    })
                    that.placeholder = ""
                }
            }
        },
        // 获取店铺列表
        async getDianPuList() {
            const data = {
                areaIds: this.daQuInclude, //商圈id
                shopName: this.shopName,
                merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("成功")
                this.noticeOptions = res.dtList
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {}
                    if (this.roleType == 5) {
                        ;(data.ArticleTitle = this.form.articleTitle),
                            (data.NoticeType = this.form.noticeType),
                            (data.articleType = "GONGGAOGUANLI"),
                            (data.articleContent = this.form.articleContent),
                            (data.ShopIds = this.form.notice.join(","))
                    }
                    if (this.roleType == 6) {
                        ;(data.ArticleTitle = this.form.articleTitle),
                            (data.NoticeType = this.form.noticeType),
                            (data.articleType = "GONGGAOGUANLI"),
                            (data.articleContent = this.form.articleContent),
                            (data.MerchantIds = this.form.notice.join(","))
                    }
                    if (this.form.noticeType == undefined) {
                        return this.$message.error("请选择公告类别！")
                    }
                    if (this.form.notice == "") {
                        return this.$message.error("请选择通知群组！")
                    }
                    const { data: res } = await this.$http.post(
                        this.$url.InsertArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$router.push({
                            name: "GongGaoList"
                        })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        NoticeType: this.form.noticeType,
                        ArticleId: this.articleId,
                        articleType: "GONGGAOGUANLI",
                        ArticleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$router.push({ path: "/GongGaoList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async getArticleDetail() {
            this.$http
                .get(this.$url.GetArticleDetail, {
                    params: {
                        articleId: this.articleId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        this.form.notice = []
                        this.notice = res.data.dtList[0].displayGroup.split(",")
                        var ID = res.data.dtList[0].isPlatfrom
                        this.GetArticleDisplayGroup(ID, "xq")
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        cancelCommon() {
            if (this.current == "1") {
                return this.$router.push({
                    path: "/GetStatistics",
                    query: {
                        activeName1: 0
                    }
                })
            }
            this.$router.push({ path: "/GongGaoList" })
        },
        cancel() {
            this.$router.push({ path: "/GongGaoList" })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader-panel {
    .el-cascader-menu:nth-child(1) {
        .el-checkbox {
            display: block !important;
        }
    }
}
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
// .ql-container .ql-editor {
//     max-height: 25em;
// }
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
.notice-pad .el-form-item__label {
    padding-top: 5px;
}
// 去掉多选框的横向滚动条
.el-scrollbar__wrap {
    overflow-x: hidden !important;
}
.noticePadding {
    margin-top: 6px;
}
</style>