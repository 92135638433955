<template>
  <div id="shouju">
    <el-card class="search" style="width:60%;margin:0 auto;">
      <div ref="pdf" style="padding-top:30px">
        <div class="title-body">
          <div class="title-text">
            收款收据
          </div>
        </div>
        <div style="margin-top:30px">
          <!-- 日期 -->
          <div style="margin-left:60px">
            <span> 日期：</span>
            <input
              type="text"
              class="bor-none"
              style="width:8%"
              v-model="year"
            />
            <span> 年</span>
            <input
              type="text"
              class="bor-none"
              style="width:5%"
              v-model="month"
            />
            <span> 月</span>
            <input
              type="text"
              class="bor-none"
              style="width:5%"
              v-model="day"
            />
            <span> 日</span>
          </div>
          <!-- 表格 -->
          <table style="width:90%;border:1px solid;margin:0 auto;padding:20px">
            <!-- 第一行 -->
            <div class="div-body d-flex" style="margin-left:80px;">
              <div class="d-flex">
                <div>今收到</div>
                <!-- <div class="bor-btm"> -->
                <input
                  type="text"
                  class="bor-none bor-btm"
                  v-model="form.customerName"
                />
                <!-- </div> -->
              </div>
              <div class="d-flex" style="flex:1;">
                <div class="text-c" style="width:120px;">身份证号</div>
                <input
                  type="text"
                  class="bor-none bor-btm"
                  style="width:100%;"
                  v-model="form.idCardNo"
                />
              </div>
            </div>
            <!-- 第二行 -->
            <div class="div-body d-flex" style="margin-left:30px;">
              <div class="d-flex">
                <div>交来（</div>
                <!-- <el-checkbox-group v-model="checkList1" style="display:inline">
                  <el-checkbox label="买卖"></el-checkbox>
                  <el-checkbox label="租赁"></el-checkbox>
                </el-checkbox-group> -->
                <div>
                  <el-radio v-model="form.tradeType" label="1">买卖</el-radio>
                  <el-radio v-model="form.tradeType" label="0">租赁</el-radio>
                </div>

                <div>）</div>
              </div>
              <div class="d-flex" style="flex:1;">
                <div class="text-c" style="padding-left:3px;width:60px;">
                  位于
                </div>
                <input
                  type="text"
                  class="bor-none bor-btm"
                  style="width:100%;"
                  v-model="form.contractHouseAddress"
                />
              </div>
            </div>
            <!-- 第三行 -->
            <div class="div-body" style="margin-left:30px;display: flex;">
              <div>
                <el-checkbox-group v-model="checkList2" style="display:inline">
                  <el-checkbox label="定金"></el-checkbox>
                  <el-checkbox label="租金"></el-checkbox>
                  <el-checkbox label="押金"></el-checkbox>
                  <el-checkbox label="服务佣金"></el-checkbox>
                  <el-checkbox label="中介费"></el-checkbox>
                  <el-checkbox label="其他"></el-checkbox>
                </el-checkbox-group>
                <span style="padding-left:3px">：</span>
              </div>
              <div class="d-flex" style="flex:1;">
                <input
                  type="text"
                  class="bor-none bor-btm"
                  style="width:100%"
                  v-model="checkValue2"
                />
              </div>
            </div>
            <!-- 第四行 -->
            <div class="div-body d-flex" style="margin-left:30px;">
              <div>人民币(大写)</div>
              <div
                class="d-flex four-input bor-btm"
                style="margin-left:8px;flex:1;"
              >
                <!-- <input type="text" />
                <div>佰</div>
                <input type="text" />
                <div>拾</div>
                <input type="text" />
                <div>万</div>
                <input type="text" />
                <div>仟</div>
                <input type="text" />
                <div>佰</div>
                <input type="text" />
                <div>拾</div>
                <input type="text" />
                <div>元整</div> -->
                <div style="margin: 0 auto;">
                  {{yingShouYingFuAmountDX}}
                </div>
              </div>
              <div class="d-flex">
                <div style="margin-left:30px">￥</div>
                <div class="bor-btm">
                  <input
                    type="text"
                    class="bor-none"
                    style="width:100%"
                    v-model="form.yingShouYingFuAmount"
                    @change="getDX(form.yingShouYingFuAmount)"
                  />
                </div>
              </div>
            </div>
            <!-- 第五行 -->
            <div class="div-body d-flex" style="margin-left:30px;">
              <div class="d-flex">
                <div>付款方式：</div>
                <el-checkbox-group v-model="checkList3" style="display:inline">
                  <el-checkbox label="现金"></el-checkbox>
                  <el-checkbox label="刷卡"></el-checkbox>
                  <el-checkbox label="转账"></el-checkbox>
                  <el-checkbox label="微信"></el-checkbox>
                  <el-checkbox label="其他"></el-checkbox>
                </el-checkbox-group>
                <span style="padding-left:3px">：</span>
              </div>
              <div class="d-flex" style="flex:1">
                <input
                  type="text"
                  class="bor-none bor-btm"
                  style="width:100%;"
                  v-model="checkValue3"
                />
              </div>
            </div>
            <!-- 第六行 -->
            <div class="div-body d-flex" style="margin-left:30px;">
              <div style="width:60px">备注：</div>
              <input
                type="text"
                class="bor-none bor-btm"
                style="width:100%;"
                v-model="form.remark"
              />
            </div>
            <!-- 第七行 -->
            <div style="margin-left:30px;">
              <input type="text" class="bor-none bor-btm" style="width:100%;" />
            </div>
          </table>
          <img
            src="../../../public/img/shouju-chapter.png"
            style="width:150px;height:150px;position: absolute;
    right: 22%;
    margin-top: 10px;"
          />
          <!-- 尾部 -->
          <div
            class="d-flex text-c"
            style="padding: 70px 0 50px;justify-content: space-around;"
          >
            <div>
              <span style="padding-left:3px">开票人：</span>
              <!-- <input type="text" style="border:none;" /> -->
            </div>
            <div>
              <span style="padding-left:3px">收款人：</span>
              <!-- <input type="text" style="border:none;" /> -->
            </div>
            <div>
              <span style="padding-left:3px">经理：</span>
              <!-- <input type="text" style="border:none; width:50%" /> -->
            </div>
            <div>
              <span style="padding-left:3px">(单位盖章)</span>
              <!-- <input type="text" style="border:none;" /> -->
            </div>
          </div>
        </div>
      </div>
      <el-button @click="examine">导出</el-button>
    </el-card>
  </div>
</template>
<script>
import { downloadPDF } from "../../util/country-data-master/pdf.js";
export default {
  data() {
    return {
      financialId: this.$route.query.financialId,
      form: [],
      checkList1: [],
      checkList2: [],
      checkValue2: "",
      checkList3: [],
      checkValue3: "",
      year: "",
      month: "",
      day: "",
      yingShouYingFuAmountDX:''
    };
  },
  created() {
    this.getFinancialReceiptDetail();
  },
  methods: {
    handleExport() {
      downloadPDF(this.$refs.pdf);
    },
    async getFinancialReceiptDetail() {
      let that = this;
      that.loading = true;
      await that.$http
        .get(that.$url.GetFinancialReceipt, {
          params: {
            financialId: that.financialId //客源id
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            if (res.data.dtList.length > 0) {
              that.form = res.data.dtList[0];

              // if (that.form.tradeType == 0) {
              //   that.checkList1.push("租赁");
              // } else if (that.form.tradeType == 1) {
              //   that.checkList1.push("买卖");
              // }
              var moneyType = that.form.moneyType.split(",");
              moneyType.forEach(function(item) {
                if (
                  item != "定金" &&
                  item != "租金" &&
                  item != "押金" &&
                  item != "服务佣金" &&
                  item != "中介费"
                ) {
                  that.checkValue2 += item + ",";
                } else {
                  that.checkList2.push(item);
                }
              });
              that.checkValue2 = that.checkValue2.substring(
                0,
                that.checkValue2.length - 1
              );
              that.year = that.form.currentDate.split("-")[0];
              that.month = that.form.currentDate.split("-")[1];
              that.day = that.form.currentDate.split("-")[2];
              this.smallToBig(that.form.yingShouYingFuAmount);
              that.loading = false;
            } else {
              that.loading = false;
              that.$message.error(res.data.message);
            }
          }
        });
    },
    examine() {
      // if (this.checkValue2 == "") {
      //   var moneyType = this.checkList2.toString();
      // } else {
      //   var moneyType = this.checkList2.toString() + "," + this.checkValue2;
      // }
      if (this.checkList2.length != 0) {
        if (this.checkValue2 == "") {
          var moneyType = this.checkList2.toString();
        } else {
          var moneyType = this.checkList2.toString() + "," + this.checkValue2;
        }
      } else {
        if (this.checkValue2 == "") {
          var moneyType = "";
        } else {
          var moneyType = this.checkValue2;
        }
      }

      if (this.checkList3.length != 0) {
        if (this.checkValue3 == "") {
          var paymentType = this.checkList3.toString();
        } else {
          var paymentType = this.checkList3.toString() + "," + this.checkValue3;
        }
      } else {
        if (this.checkValue3 == "") {
          var paymentType = "";
        } else {
          var paymentType = this.checkValue3;
        }
      }

      // if (this.checkValue3 == "") {
      //   var paymentType = this.checkList3.toString();
      // } else {
      //   var paymentType = this.checkList3.toString() + "," + this.checkValue3;
      // }

      console.log(moneyType);
      console.log(paymentType);
      //  checkValue2
      const data = {
        financialId: this.financialId,
        name: this.form.customerName,
        idCardNo: this.form.idCardNo,
        tradeType: this.form.tradeType,
        address: this.form.contractHouseAddress,
        moneyType: moneyType,
        amount: this.form.yingShouYingFuAmount,
        paymentType: paymentType,
        remark: this.form.remark
      };
      this.$http
        .post(this.$url.InsertFinancialReceipt, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            this.handleExport();
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    smallToBig(money) {
      //  将数字金额转换为大写金额
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整数完以后的单位
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      var integerNum; //金额整数部分
      var decimalNum; //金额小数部分
      //输出的中文金额字符串
      var chineseStr = "";
      var parts; //分离金额后用的数组，预定义
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "超出最大处理数字";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }

      //四舍五入保留两位小数,转换为字符串
      money = Math.round(money * 100).toString();
      integerNum = money.substr(0, money.length - 2);
      decimalNum = money.substr(money.length - 2);

      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "" || /^0*$/.test(decimalNum)) {
        chineseStr += cnInteger;
      }
      this.yingShouYingFuAmountDX = chineseStr;
    },
    getDX(number) {
      this.smallToBig(number);
    }
  }
};
</script>
<style scoped lang="less">
#shouju {
  margin-top: 50px;
  .title-body {
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    // border-bottom: 3px double black;
    // width: 20%;
    // margin: 0px auto;
    //  letter-spacing: 30px;
    //  padding-left: 30px;
  }
  .title-text {
    border-bottom: 3px double black;
    width: 30%;
    margin: 0px auto;
    letter-spacing: 30px;
    padding-left: 30px;
  }
  input {
    // height: 30px !important;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
  }
  .el-checkbox {
    margin: 0 10px;
  }
  .el-checkbox__inner::after {
    border: 1px solid #000 !important;
  }
  .div-body {
    // margin: 10px 0;
    // font-size: 17px;
  }
  .d-flex {
    display: flex;
    // font-size: 17px;
  }
  .bor-none {
    border: none;
  }
  .bor-btm {
    border-bottom: 1px solid;
  }
  .text-c {
    text-align: center;
  }
  .four-input > input {
    border: none;
    width: 9%;
  }
  /deep/ input[type="radio"]:checked::after {
    content: "✓";
    font-size: 14px;
    margin-top: 2px;
    font-weight: bold;
    background-color: gray;
    border-color: gray;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: gray !important;
    border-color: gray;
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner,
  .el-radio__input.is-indeterminate .el-radio__inner {
    background-color: gray !important;
    border-color: gray;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #000;
  }
}
</style>
