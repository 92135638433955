import url from "../../config/url.js";
import axios from "axios";
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
export default {
  jiaoyiOptions: [
    {
      value: "",
      label: "全部"
    },
    {
      value: "1",
      label: "买卖"
    },
    {
      value: "0",
      label: "租赁"
    }
  ],
  editableTabs: [
    {
      title: "合同列表",
      name: "1"
    }
    // {
    //   title: "线下签约审核列表",
    //   name: "2"
    // },
    // {
    //   title: "线上签约审核列表",
    //   name: "3"
    // },
    // {
    //   title: "买卖变更审核列表",
    //   name: "4"
    // }
  ],
  contractOptions: [
    {
      value: "10",
      label: "创建合同完成"
    },
    {
      value: "20",
      label: "合同审核完成"
    },
    {
      value: "30",
      label: "合同上传完成"
    },
    {
      value: "40",
      label: "电子签章完成"
    },
    {
      value: "50",
      label: "付款完成"
    },
    {
      value: "60",
      label: "过户完成"
    },
    {
      value: "70",
      label: "交易完结"
    },
    {
      value: "90",
      label: "合同已解约"
    }
  ],
  // httpRequest(blob, contractId) {
  //   let accessToken = localStorage.getItem("accessToken");
  //   let roleType = localStorage.getItem("roleType");
  //   let fp = localStorage.getItem("fp");
  //   let url1 = url.PostTestBase64; // 接收上传文件的后台地址
  //   let xmlHttpResquest = new XMLHttpRequest();
  //   let a = new FileReader();
  //   a.onload = function (e) {
  //     console.log(e);
  //     let fileIds = { 'fileIds': e.target.result.toString() }
  //     xmlHttpResquest.open("POST", url1, true);
  //     xmlHttpResquest.setRequestHeader("accessToken", accessToken);
  //     xmlHttpResquest.setRequestHeader("roleType", roleType);
  //     xmlHttpResquest.setRequestHeader("fp", fp);
  //     // xmlHttpResquest.responseType = "blob";
  //     xmlHttpResquest.send({ 'base64File': e.target.result });
  //     xmlHttpResquest.onload = function (oEvent) {
  //       if (xmlHttpResquest.status != 200) {
  //         return false;
  //       } else if (xmlHttpResquest.readyState == 4) {
  //       }
  //     };
  //   };
  //   a.readAsDataURL(blob);
  // },
  // httpRequest(blob, contractId,type) {
  //   let that = this, params = {},url1 = ''
  //   if (type==1) {
  //     url1 = url.TransferFileToESign; // 接收上传文件的后台地址   
  //   } else if (type==2) {
  //     url1 = url.TransferFileToESign; // 接收上传文件的后台地址   
  //   }
  //   const loading = Loading.service({
  //     lock: true,
  //     text: 'Loading',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'});
  //   let a = new FileReader();
  //   a.onload = function (e) {
  //     params = { 'base64File': e.target.result.split('base64,')[1],'contractId':contractId };
  //     axios.post(url1, params, {
  //       headers: {
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //     })
  //       .then((res) => {
  //         if (res.data.msgCode == 1) {
  //           setTimeout(function(){
  //             Message.success({
  //               message: "签约基本信息保存成功！"
  //             });
  //             loading.close();
  //             window.localStorage.setItem("showBtn",true)
  //             // return true
  //           },3000)
  //         } else {
  //           Message.error({
  //             message: res.data.message,
  //             duration: 5000 
  //           });
  //           loading.close();
  //           // window.localStorage.setItem("showBtn",false)
  //           // return true
  //         }
  //       })
  //       .catch((res) => {
  //         Message.error("请求错误");
  //       });
  //   };
  //   a.readAsDataURL(blob);
  // }
};
