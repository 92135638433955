<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col :span="6" style="margin: 20px 0 0 0;">
                        <span style="font-size: 14px;width: 28%; display: inline-block;text-align: right;">经纪人姓名：</span>
                        <el-input v-model="userName" placeholder="请输入经纪人姓名" class="media-width"></el-input>
                    </el-col>
                    <el-col :span="6" style="margin: 20px 0 0 0;">
                        <span style="font-size: 14px;width: 28%; display: inline-block;text-align: right;">结算月：</span>
                        <el-date-picker v-model="yyyyMM" value-format="yyyy-MM" type="month" placeholder="请选择工资月份" class="media-width" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="6" style="margin: 20px 0 0 0 ;" v-if="roleType == 6">
                        <span style="font-size: 14px;width: 28%; display: inline-block;text-align: right;">商户：</span>
                        <el-input @focus="merchantDialogTrue" placeholder="请选择商户" v-model="chickMerchantName" class="media-width"></el-input>
                    </el-col>
                    <el-col :span="6" style="margin: 20px 0 0 0;" v-if="roleType == 6 || roleType == 5 || roleType == 3 || roleType == 4">
                        <span style=" font-size: 14px;width: 28%; display: inline-block;text-align: right;">店铺：</span>
                        <el-input @focus="shopDialogTrue" placeholder="请选择店铺" readonly="readonly" v-model="chickShopName" class="media-width"></el-input>
                    </el-col>
                    <!-- <el-col :span="6" style="margin: 20px 0 0 0;">
                        <span style=" font-size: 14px;margin-left: 15px;">业绩类型：</span>
                        <el-select class="media-width" v-model="performanceType" placeholder="请选择业绩类型">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col> -->
                    <el-col :span="6" style="margin: 20px 0 0 0;">
                        <span style="width: 28%; display: inline-block; font-size: 14px;text-align: right;">范围：</span>
                        <el-select class="media-width" v-model="isMyself" placeholder="请选择查看范围">
                            <el-option v-for="item in optionsmy" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>

                    <el-col :span="6" style="margin: 20px 0 0 0;">
                        <span style="width: 28%; display: inline-block;font-size: 14px;text-align: right;">只看有效经纪人：</span>
                        <el-select class="media-width" v-model="isUserEnabled" placeholder="请选择只看有效经纪人">
                            <el-option v-for="item in optionsjingjiren" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6" style="margin: 20px 0 0 0; float:right;">
                        <span style="float: right;">
                            <el-button v-if="roleType == 6|| roleType == 4" size="mini" type="primary" @click="insert">线下合同新增</el-button>
                            <el-button size="mini" type="primary" @click="cgetArticleList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <!-- <el-table-column label=""" prop="yyyyMM"></el-table-column> -->
                    <el-table-column label="结算月" prop="yyyyMM">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="xiangqing(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.yyyyMM }}</el-button>
                        </template>
                    </el-table-column>

                    <el-table-column label="经纪人" prop="userName"></el-table-column>
                    <el-table-column label="参与合同个数" prop="contractCount"></el-table-column>
                    <el-table-column label="房源端业绩" prop="fangYuanDuanYeJi"></el-table-column>
                    <el-table-column label="客源端业绩" prop="keYuanDuanYeJi"></el-table-column>
                    <el-table-column label="其他" prop="qiTa"></el-table-column>
                    <el-table-column label="当前月总业绩" prop="dangYueZongYeJi"></el-table-column>
                    <el-table-column label="角色店组" prop="shopName"></el-table-column>
                    <!-- <el-table-column width="200px" label="商圈经理" prop="confirmDate"></el-table-column> -->
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
            <!-- 商户弹出层 -->
            <el-dialog :visible.sync="merchantDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">商户：</span>
                            <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetMerchantList">查询</el-button>
                                <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="MerchantDialogDetails" :data="operationList1" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="商户">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.merchantName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="merchantCurrentPage" :page-sizes="[10, 20, 50]" :page-size="merchantPageSize" layout="total, sizes, prev, pager, next, jumper" :total="merchantDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 店铺弹出层 -->
            <el-dialog :visible.sync="shopDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">店铺：</span>
                            <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetDianPuList">查询</el-button>
                                <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="ShopDialogDetails" :data="operationList2" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="店铺" prop="shopName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.shopName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户" prop="merchantName"></el-table-column>
                    <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="shopCurrentPage" :page-sizes="[10, 20, 50]" :page-size="shopPageSize" layout="total, sizes, prev, pager, next, jumper" :total="shopDialogTotal">
                </el-pagination>
            </el-dialog>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            isMyself: "",
            optionsmy: [
                {
                    value: "",
                    label: "全部"
                },
                {
                    value: "0",
                    label: "查看其他人"
                },
                {
                    value: "1",
                    label: "只看自己"
                }
            ],
            isUserEnabled: "",
            optionsjingjiren: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            options: [
                {
                    value: "房源端业绩",
                    label: "房源端业绩"
                },
                {
                    value: "客源端业绩",
                    label: "客源端业绩"
                },
                {
                    value: "其他",
                    label: "其他"
                }
            ],
            // performanceType: "",
            multipleSelection: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            ruleform: {
                shopId: "",
                yyyyMM: "",
                chickMerchantName: "",
                chickShopName: "",
                merchantId: ""
            },
            YeJiParams: JSON.parse(window.localStorage.getItem("YeJiParams")),
            // formyyyyMM: "",
            // formchickMerchantName: "",
            // formchickShopName: "",
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            userName: "",
            shopId: "",
            chickShopName: "",
            shopName: "",
            yyyyMM: "",
            time: "",
            roleType: "",
            merchantDialog: false,
            merchantName: "",
            chickMerchantName: "",
            shopDialog: false,
            operationList1: [],
            merchantCurrentPage: 1,
            merchantPageSize: 10,
            merchantDialogTotal: 0,
            operationList2: [],
            shopPageSize: 10,
            shopCurrentPage: 1,
            shopDialog: false,
            shopDialogTotal: 0
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        if (this.YeJiParams) {
        this.currentPage = this.YeJiParams.currentPage;
        this.pageSize = this.YeJiParams.pageSize;
        }
        /* 获取列表 */
        this.GetPerformanceList()
        // this.newTime()
    },
    methods: {
        //店铺弹出层
        shopDialogTrue() {
            if (this.roleType == 5) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 3) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 4) {
                this.shopDialog = true
                this.getDianPuList()
            }
            if (this.roleType == 6) {
                if (
                    this.chickMerchantName == "" ||
                    this.chickMerchantName == null
                ) {
                    this.$message.error("请先选择商户")
                } else {
                    this.shopDialog = true
                    this.getDianPuList()
                }
            }
        },
        // 商户弹出层
        merchantDialogTrue() {
            this.getMerchantList()
            this.merchantDialog = true
        },
        // 商户查询
        cgetMerchantList() {
            this.currentPage = 1
            this.getMerchantList()
        },
        //重置商户搜多条件
        cancelMerchantList() {
            this.merchantName = ""
            this.enabled = ""
            this.merchantCurrentPage = 1
            this.getMerchantList()
        },
        /* 获取商户列表 */
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.merchantCurrentPage,
                pageSize: this.merchantPageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList1 = res.dtList
                this.merchantDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    console.log(res.dtList[i])
                    if (res.dtList[i].enabled === "1") {
                        this.operationList1[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList1[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听商户pagesize改变的事件 */
        handleSizeChange1(newSize) {
            this.merchantPageSize = newSize
            this.getMerchantList()
        },
        /* 监听商户页码值改变的事件 */
        handleCurrentChange1(newPage) {
            this.merchantCurrentPage = newPage
            this.getMerchantList()
        },
        // 选择商户赋值
        MerchantDialogDetails(row) {
            const that = this
            that.chickMerchantName = row.merchantName
            that.merchantId = row.merchantId

            that.merchantDialog = false
        },
        // 查询店铺
        cgetDianPuList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        //重置店铺搜索条件
        cancelDianPuList() {
            this.shopName = ""
            this.enabled = ""
            this.shopCurrentPage = 1
            this.getDianPuList()
        },
        /* 获取店铺列表 */
        async getDianPuList() {
            if (this.roleType == 5) {
                const data = {
                    areaIds: "", //商圈id
                    shopName: this.shopName,
                    merchantId: localStorage.getItem("merchantId"),
                    currentPage: this.shopCurrentPage,
                    pageSize: this.shopPageSize
                }
                const { data: res } = await this.$http.get(
                    this.$url.GetShopList,
                    {
                        params: data
                    }
                )
                if (res.msgCode == 1) {
                    // this.$message.success("获取店铺成功")
                    this.operationList2 = res.dtList
                    this.shopDialogTotal = res.maxRecords
                    for (var i = 0; i < res.dtList.length; i++) {
                        if (res.dtList[i].enabled === "1") {
                            this.operationList2[i].enabled = "是"
                        }
                        if (res.dtList[i].enabled === "0") {
                            this.operationList2[i].enabled = "否"
                        }
                    }
                } else {
                    this.$message.success(res.message)
                }
            } else {
                const data = {
                    areaIds: "", //商圈id
                    shopName: this.shopName,
                    merchantId: this.merchantId,
                    currentPage: this.shopCurrentPage,
                    pageSize: this.shopPageSize
                }
                const { data: res } = await this.$http.get(
                    this.$url.GetShopList,
                    {
                        params: data
                    }
                )
                if (res.msgCode == 1) {
                    // this.$message.success("获取店铺成功")
                    this.operationList2 = res.dtList
                    this.shopDialogTotal = res.maxRecords
                    for (var i = 0; i < res.dtList.length; i++) {
                        if (res.dtList[i].enabled === "1") {
                            this.operationList2[i].enabled = "是"
                        }
                        if (res.dtList[i].enabled === "0") {
                            this.operationList2[i].enabled = "否"
                        }
                    }
                } else {
                    this.$message.success(res.message)
                }
            }
        },
        /* 监听店铺pagesize改变的事件 */
        handleSizeChange2(newSize) {
            this.shopPageSize = newSize
            this.getDianPuList()
        },
        /* 监听店铺页码值改变的事件 */
        handleCurrentChange2(newPage) {
            this.shopCurrentPage = newPage
            this.getDianPuList()
        },
        // 选择店铺赋值
        ShopDialogDetails(row) {
            const that = this
            that.chickShopName = row.shopName
            that.shopId = row.shopId
            that.shopDialog = false
        },

        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.GetPerformanceList()
        },
        // 详情
        xiangqing(row) {
            this.$router.push({
                name: "YeJiDetail",
                query: {
                    userId: row.userId,
                    yyyyMM: row.yyyyMM,
                    num: 1
                }
            })
        },
        insert(){
            this.$router.push({
                name: "YeJiAdd",
                query: {
                    num: 0,
                    label: '入账管理'
                }
            })
        },
        /* 获取列表 */
        async GetPerformanceList() {
            const data = {
                isMyself: this.isMyself,
                isUserEnabled: this.isUserEnabled,
                // performanceType: this.performanceType,
                userName: this.userName,
                shopId: this.shopId,
                yyyyMM: this.yyyyMM.replace("-", ""),
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            localStorage.setItem("YeJiParams", JSON.stringify(data));
            const { data: res } = await this.$http.get(
                this.$url.GetPerformanceList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                res.dtList.forEach((element) => {
                    if (element.isConfirmed == "0") {
                        element.isConfirmed = "否"
                    } else {
                        element.isConfirmed = "是"
                    }
                })

                this.operationList = res.dtList

                this.loading = false
                this.total = res.maxRecords
            } else {
                this.loading = false
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetPerformanceList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetPerformanceList()
        },
        //重置
        cancelbreedList() {
            this.isMyself = ""
            this.isUserEnabled = ""
            // this.performanceType = ""
            this.userName = ""
            this.shopId = ""
            this.yyyyMM = ""
            this.chickMerchantName = ""
            this.chickShopName = ""
            this.currentPage = 1
            this.GetPerformanceList()
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
