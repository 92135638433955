<template>
    <div>
        <el-card class="search">
            <el-row>
                <span class="title">线索级别：</span>
                <div style="font-size: 14px;" v-for="item in xiansuoJibie" :key="item" class="xuanxiang2"
                    :class="{ active: isXiansuoJibie === item }" @click="searchLeixin(item, 'xiansuoJibie')">
                    {{ item }}
                </div>
            </el-row>
            <el-row>
                <span class="title">业务类型：</span>
                <div style="font-size: 14px;" v-for="item in yewuLeixing" :key="item" class="xuanxiang2"
                    :class="{ active: isYewuLeixing === item }" @click="searchLeixin(item, 'yewuLeixing')">
                    {{ item }}
                </div>
            </el-row>
            <el-row>
                <span class="title">线索来源：</span>
                <div style="font-size: 14px;" v-for="item in xiansuoLaiyuan" :key="item" class="xuanxiang2"
                    :class="{ active: isXiansuoLaiyuan === item }" @click="searchLeixin(item, 'xiansuoLaiyuan')">
                    {{ item }}
                </div>
            </el-row>
            <el-row>
                <span class="title">意向区域：</span>
                <div style="font-size: 14px;" v-for="item in kexuanQuyu" :key="item" class="xuanxiang2"
                    :class="{ active: isKexuanQuyu === item }" @click="searchLeixin(item, 'kexuanQuyu')">
                    {{ item }}
                </div>
            </el-row>
            <el-row style="margin-top: 10px">
                <el-col>
                    <el-button size="mini" @click="Insert()" type="primary" icon="el-icon-plus" style="border: none">新增
                    </el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-card>
            <!-- <div style="padding-left: 10px">
                已为您找到{{ operationList.length }}个房源
            </div> -->
            <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="线索名称" prop="xiansuoMC">
                    <template slot-scope="scope">
                        <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini"
                            style="color: #1268c0;text-decoration: underline">
                            {{ scope.row.xiansuoMC }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="线索级别" prop="xiansuoJB"></el-table-column>
                <el-table-column label="业务类型" prop="yewuLX"></el-table-column>
                <el-table-column label="线索来源" prop="xiansuoLY"></el-table-column>
                <el-table-column label="意向区域" prop="yixiangQY"></el-table-column>
                <el-table-column label="最近维护时间" prop="createTime"></el-table-column>
                <!-- <el-table-column label="操作">
                    <template slot-scope="scope" class="right">
                        <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                        <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                    </template>
                </el-table-column> -->
                <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                    <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px;margin: 0;line-height: 30px;"></p>
                </div>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [
                {
                    xiansuoMC: "王富贵想买二居室",
                    xiansuoJB: "A",
                    yewuLX: "求购",
                    xiansuoLY: "店内资料上传",
                    yixiangQY: "中山",
                    createTime: "2021-01-01 12:30:50"
                },
                {
                    xiansuoMC: "嬴政想买三层别墅",
                    xiansuoJB: "B",
                    yewuLX: "求租",
                    xiansuoLY: "核销房源业主资料",
                    yixiangQY: "旅顺口",
                    createTime: "2021-02-01 12:30:50"
                },
                {
                    xiansuoMC: "威震天想卖二居室",
                    xiansuoJB: "C",
                    yewuLX: "出售",
                    xiansuoLY: "二手成交房源",
                    yixiangQY: "沙河口",
                    createTime: "2021-01-01 12:30:50"
                }
            ],
            articleTitle: "",
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            xiansuoJibie: [
                "不限",
                "A[精准线索]",
                "B[优质线索]",
                "C[普通线索]",
                "D[不展示线索]",
                "E[未知线索]"
            ],
            yewuLeixing: ["不限", "求购", "求租", "出售", "出租", "其他"],
            xiansuoLaiyuan: [
                "不限",
                "店内资料上传",
                "核销房源业主资料",
                "二手成交房源",
                "租赁成交房源",
                "贷款计算器小程序",
                "购房资质小程序"
            ],
            kexuanQuyu: [
                "不限",
                "中山",
                "庄河",
                "开发区",
                "旅顺口",
                "普兰店",
                "沙河口",
                "瓦房店",
                "甘井子",
                "西岗",
                "金州",
                "长海",
                "高新园区"
            ],
            isXiansuoJibie: "不限",
            isYewuLeixing: "不限",
            isXiansuoLaiyuan: "不限",
            isKexuanQuyu: "不限"
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
    },
    methods: {
        searchLeixin(item, type) {
            if (type == "xiansuoJibie") {
                this.isXiansuoJibie = item
            } else if (type == "yewuLeixing") {
                this.isYewuLeixing = item
            } else if (type == "xiansuoLaiyuan") {
                this.isXiansuoLaiyuan = item
            } else if (type == "kexuanQuyu") {
                this.isKexuanQuyu = item
            }
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        Insert() {
            this.$router.push({
                name: "XianSuoDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.$router.push({
                name: "XianSuoDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getArticleList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    } else if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.getArticleList()
        },
        async getList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode) {
                this.operationList = res.dtList
                this.total = res.maxRecords
                //   for (var i = 0; i < res.dtList.length; i++) {
                //     if (res.dtList[i].enabled === '1') {
                //       this.operationList[i].enabled = '是'
                //     } else if (res.dtList[i].enabled === '2') {
                //       this.operationList[i].enabled = '否'
                //     }
                //   }
            } else {
                this.$message.success(res.message)
            }
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.deleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then(res => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.GetPetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch(err => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                        const { data: res } = this.$http.delete(
                            this.$url.deleteArticle,
                            {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.reload()
                        } else {
                            this.$message.success(res.message)
                        }
                    })
                    .catch(err => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "XianSuoDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "InsertArticle",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.title {
    font-size: 14px;
}
</style>
