<template>
    <div>
        <el-card class="search">
            <el-row class="mabot8">
                <el-col>
                    <span class="title">带看房源：</span>
                    <el-button size="mini" class="buttonInput button_tanchu" @click="openFangyuanDialog('searchDaiKan')" style="margin:0 5px;width: 20%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="louPanName=='请选择带看房源'" style="color:#c0c4cc">{{ louPanName }}</span>
                        <span v-if="louPanName!='请选择带看房源'">{{ louPanName }}</span>
                    </el-button>
                    <!-- <span class="title" style="margin-left: 15px;">带看人：</span>
                    <el-select v-model="daikanR" placeholder="请选择带看人" style="margin:0 5px;width: 20%">
                        <el-option v-for="item in DaiKanManList" :key="item.createPerson" :label="item.userName" :value="item.createPerson"></el-option>
                    </el-select> -->
                    <span style="float: right;">
                        <el-button size="mini" style="margin-left: 20px;" type="primary" @click="searchDaikanJiLu">查询</el-button>
                        <el-button size="mini" @click="resetDaikanJiLu" type="warning">重置</el-button>
                    </span>
                </el-col>
            </el-row>
            <el-row v-if="isUpdate">
                <el-col>
                    <el-button size="mini" @click="updateDaiKan('','add','新增带看记录')" type="primary" icon="el-icon-plus">新增</el-button>
                    <el-button size="mini" @click="deleteDaiKan('','duoGe')" type="warning" icon="el-icon-delete">删除</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-card>
            <el-table ref="multipleTable" @selection-change="daiKanSelection" :data="daiKanList" tooltip-effect="dark" style="width:auto" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column label="带看房源" prop="louPanName">
                    <template slot-scope="scope">
                        <span>{{scope.row.louPanName}}</span><br><span style="color:red">{{scope.row.houseNo}}</span>
                    </template>

                </el-table-column>
                <!-- <el-table-column label="客户姓名" prop="customerName"></el-table-column> -->
                <el-table-column label="带看人" prop="daiKanRenName"></el-table-column>
                <el-table-column label="面积(平方米)" prop="square"></el-table-column>
                <el-table-column label="户型" prop="huXingId"></el-table-column>
                <el-table-column label="意向价格">
                    <template slot-scope="scope">
                        <span v-if="scope.row.daiKanType.indexOf('买')">{{scope.row.buyerPriceFrom}}元 - {{scope.row.buyerPriceTo}}元</span>
                        <span v-if="!scope.row.daiKanType.indexOf('买')">{{scope.row.buyerPriceFrom/10000}}万元 - {{scope.row.buyerPriceTo/10000}}万元</span>
                    </template>
                </el-table-column>
                <el-table-column label="房源总价">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.daiKanType.indexOf('买')">{{ towNumber(scope.row.sellerPrice/10000) }}万元</span>
                        <span v-if="scope.row.daiKanType.indexOf('买')">{{scope.row.sellerPrice}}元 / 月</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="单价(万元/平方米)" prop="unitPrice"></el-table-column> -->
                <el-table-column label="带看类型" prop="daiKanType"></el-table-column>
                <el-table-column label="带看时间" prop="daiKanTime"></el-table-column>
                <el-table-column label="带看总结" prop="daiKanZongJie"></el-table-column>
                <!-- <el-table-column label="带看反馈" prop="daiKanFanKui"></el-table-column> -->
                <el-table-column label="操作" width="165px;" v-if="isUpdate">
                    <template slot-scope="scope" class="right">
                        <el-button @click.native.stop="updateDaiKan(scope.row,'update','修改带看记录')" type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                        <el-button @click.native.stop="deleteDaiKan(scope.row,'oneGe')" type="text" size="mini" class="el-icon-delete caozuo" style="color: #da0909">删除</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>
            <el-row>
                <el-pagination style="width: 70%;float: right;margin: 15px 15px;" @size-change="daiKanSizeChange" @current-change="daiKanCurrentChange" :current-page="daiKanCurrentPage" :page-sizes="[5, 10, 20]" :page-size="daiKanPageSize" layout="total, sizes, prev, pager, next, jumper" :total="daiKanListTotal"></el-pagination>
            </el-row>
        </el-card>

        <!-- 带看房源 -->
        <el-dialog title="带看房源" :visible.sync="fangyuanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <el-tabs v-model="fangyuanTabPane" @tab-click="handleClickTab" class="qiugou-tabs" style="position: relative;top: 0px;margin-bottom:20px;background-color: #fff;">
                <el-tab-pane name="出售房源" label="出售房源">
                </el-tab-pane>
                <el-tab-pane name="出租房源" label="出租房源">
                </el-tab-pane>
            </el-tabs>
            <KeYuanChuShouList :daiKanType='daiKanType' :dkfylist='dkfylist' :state='state' :isKeyuanDaikan='true' @colseFangyuanDialog="colseFangyuanDialog"></KeYuanChuShouList>
        </el-dialog>

        <!-- 带看房源 -->
        <el-dialog title="带看客源" :visible.sync="keyuanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <KeYuanList :fangyuanState='state' :isGenJin='true' @colseKeyuanDialog="colseKeyuanDialog"></KeYuanList>
        </el-dialog>
        <!-- 经纪人列表 -->
        <el-dialog title="经纪人列表" :visible.sync="jingjirenDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <TongXunLuList :isQianyueOpen='true' @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>
        <!-- 带看记录信息 -->
        <el-dialog :title="daiKanDialogTitle" :visible.sync="daiKanDialogVisible" :before-close="colseDaiKanDialog" width="50%" class="xuqiuDialog">
            <el-form v-loading="daiKanDialogLoading" ref="daiKanRuleForm" :model="daiKanForm" :rules="DaiKanRules" label-position="right">
                <el-form-item label="带看房源" label-width="12%" prop="houseId">
                    <el-button size="mini" class="buttonInput button_tanchu" @click="openFangyuanDialog('addDaiKan')" style="width: 95%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="daiKanForm.louPanName=='请选择带看房源'" style="color:#c0c4cc">{{ daiKanForm.louPanName }}</span>
                        <span v-if="daiKanForm.louPanName!='请选择带看房源'">{{ daiKanForm.louPanName }}</span>
                    </el-button>
                </el-form-item>
                <el-form-item v-if="roleType == '5' || roleType == '6'" label="经纪人" label-width="12%" prop="agentId">
                    <el-button :disabled='disabled' size="mini" class="buttonInput button_tanchu" @click="openjingjirenDialog()" style="width: 95%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="daiKanForm.agentName=='请选择经纪人'" style="color:#c0c4cc">{{ daiKanForm.agentName }}</span>
                        <span v-if="daiKanForm.agentName!='请选择经纪人'">{{ daiKanForm.agentName }}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="客户" label-width="12%" prop="customerId">
                    <el-button size="mini" class="buttonInput button_tanchu" @click="openKeyuanDialog()" style="width: 95%;height: 22px;line-height:0px;text-align: left;">
                        <span v-if="daiKanForm.customerName=='请选择客户'" style="color:#c0c4cc">{{ daiKanForm.customerName }}</span>
                        <span v-if="daiKanForm.customerName!='请选择客户'">{{ daiKanForm.customerName }}</span>
                    </el-button>
                </el-form-item>
                <!-- <el-form-item label="带看人" label-width="12%" prop="daikanR">
                    <el-select v-model="daiKanForm.daikanR" placeholder="请选择带看人" style="margin:0 5px;width: 95%">
                        <el-option v-for="item in DaiKanManList" :key="item.createPerson" :label="item.userName" :value="item.createPerson"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="带看类型" label-width="12%" prop="daiKanType" style="height: 35px;">
                    <el-select v-if="state=='1'" v-model="daiKanForm.daiKanType" placeholder="请选择带看类型" style="width: 95%">
                        <el-option v-for="item in daiKanLXGouOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>

                    <el-select v-if="state=='0'" v-model="daiKanForm.daiKanType" placeholder="请选择带看类型" style="width: 95%">
                        <el-option v-for="item in daiKanLXZuOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="带看时间" label-width="12%" prop="daiKanTime" style="height: 35px;">
                    <el-date-picker style="width: 95%;" v-model="daiKanForm.daiKanTime" type="datetime" placeholder="请选择带看日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="意向价格" label-width="12%">
                    <span class="redStar" style="margin-left: -85px">*</span>
                    <el-row style="margin-top: -28px;height:50px">
                        <el-col :span="8">
                            <el-form-item style="display: inline-block;">
                                <el-input placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="daiKanForm.BuyerPriceFrom" style="width: 290px;margin-left: -47px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <span style="margin-left: 87px;">-</span>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="BuyerPriceTo" style="display: inline-block;">
                                <el-input placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckPrice" v-model="daiKanForm.BuyerPriceTo" style="width: 290px;margin-left: -30px;"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" style="text-align:right">
                            <span v-if="state=='0'">元</span>
                            <span v-if="state=='1'">万元</span>
                        </el-col>
                    </el-row>
                </el-form-item>
                <!-- <el-form-item label="卖方价格" label-width="12%" prop="sellerPrice">
                    <el-input style="width: 95%;margin-right:4px;" class="padding-r0" v-model="daiKanForm.sellerPrice" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999999)value=999999999" placeholder="请输入卖方价格" type="number" min="0" autocomplete="off"></el-input>元
                </el-form-item> -->
                <!-- <el-form-item label="带看反馈" label-width="12%" prop="daiKanFanKui">
                    <el-input v-model="daiKanForm.daiKanFanKui" style="width: 95%" :rows="5" type="textarea" placeholder="请输入带看反馈" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="带看总结" label-width="12%" prop="daiKanZongJie">
                    <el-input v-model="daiKanForm.daiKanZongJie" style="width: 95%" :rows="5" type="textarea" placeholder="请输入带看总结" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="dialog-footer">
                    <el-button type="warning" size="mini" @click="colseDaiKanDialog">取消</el-button>
                    <el-button type="primary" size="mini" :disabled="daiKanDisabled" @click="saveDaiKanyes">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import ChuShouList from "../../components/ChuShou/ChuShouList.vue"
import ChuZuList from "../../components/ChuZu/ChuZuList.vue"
import KeYuanList from "../../components/KeYuan/KeYuanList.vue"
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue"
import KeYuanChuShouList from "../../components/KeYuan/KeYuanChuShouList.vue"

export default {
    name: "GetStaffList",
    inject: ["reload"],
    components: {
        ChuShouList,
        ChuZuList,
        KeYuanList,
        TongXunLuList,
        KeYuanChuShouList
    },
    data() {
        var inputPrice2 = (rule, value, callback) => {
            let that = this
            if (typeof that.daiKanForm.BuyerPriceFrom == "string") {
                that.daiKanForm.BuyerPriceFrom = Number(
                    that.daiKanForm.BuyerPriceFrom
                )
            }
            if (typeof that.daiKanForm.BuyerPriceTo == "string") {
                that.daiKanForm.BuyerPriceTo = Number(
                    that.daiKanForm.BuyerPriceTo
                )
            }
            console.log(
                that.daiKanForm.BuyerPriceFrom > that.daiKanForm.BuyerPriceTo
            )
            if (that.daiKanForm.BuyerPriceFrom > that.daiKanForm.BuyerPriceTo) {
                callback(new Error("最大金额需大于最小金额!"))
                let temp = ""
                temp = this.daiKanForm.BuyerPriceFrom
                this.daiKanForm.BuyerPriceFrom = this.daiKanForm.BuyerPriceTo
                this.daiKanForm.BuyerPriceTo = temp
            } else if (that.daiKanForm.BuyerPriceTo == 0) {
                callback(new Error("请输入金额!"))
            } else {
                callback()
            }
        }
        return {
            dkhouseid: [],
            dkfylist: [],
            roleType: localStorage.getItem("roleType"),
            disabled: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            loading: false,
            isUpdate: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            DaiKanManList: [],
            fangyuanTabPane: "出售房源",
            state: "1", //1出售，2出租
            daikanR: "",
            daiKanLX: "",
            daiKanLXGouOptions: [
                {
                    value: "0",
                    label: "买卖住宅带看"
                },
                {
                    value: "1",
                    label: "买卖别墅带看"
                },
                {
                    value: "2",
                    label: "买卖商铺带看"
                },
                {
                    value: "3",
                    label: "买卖写字楼带看"
                }
            ],
            daiKanLXZuOptions: [
                {
                    value: "0",
                    label: "租赁住宅带看"
                },
                {
                    value: "1",
                    label: "租赁别墅带看"
                },
                {
                    value: "2",
                    label: "租赁商铺带看"
                },
                {
                    value: "3",
                    label: "租赁写字楼带看"
                }
            ],
            houseId: "",

            louPanName: "请选择带看房源",
            fangyuanDialog: false,
            keyuanDialog: false,
            daiKanDialogTitle: "",
            daiKanDialogLoading: false,
            daiKanDialogVisible: false,
            daiKanDisabled: false,
            daikanFangyuanType: "",
            daiKanType: "",
            daiKanPageSize: 10,
            daiKanCurrentPage: 1,
            daiKanListTotal: 0,
            daiKanList: [],
            checkedDaiKanIdList: [],
            jingjirenDialog: false,
            daiKanForm: {
                daikanId: "",
                customerId: "",
                customerName: "请选择客户",
                houseId: "",
                louPanName: "请选择带看房源",
                agentId: "",
                agentName: "请选择经纪人",
                daiKanType: "",
                daiKanTime: "",
                buyerPrice: "",
                sellerPrice: "",
                // daiKanFanKui: "",
                daiKanZongJie: "",
                houseNo: "",
                BuyerPriceFrom: 0,
                BuyerPriceTo: 0
            },
            DaiKanRules: {
                houseId: [
                    {
                        required: true,
                        message: "请选择带看房源",
                        trigger: "change"
                    }
                ],
                agentId: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "change"
                    }
                ],
                customerId: [
                    {
                        required: true,
                        message: "请选择客户",
                        trigger: "change"
                    }
                ],
                daiKanType: [
                    {
                        required: true,
                        message: "请选择带看类型",
                        trigger: "change"
                    }
                ],
                daiKanTime: [
                    {
                        required: true,
                        message: "请选择带看时间",
                        trigger: "change"
                    }
                ],
                buyerPrice: [
                    {
                        required: true,
                        message: "请输入买方价格",
                        trigger: "blur"
                    }
                ],
                BuyerPriceTo: [
                    {
                        required: true,
                        validator: inputPrice2,
                        trigger: "blur"
                    }
                ],
                // daiKanFanKui: [
                //     {
                //         required: true,
                //         message: "请输入带看反馈",
                //         trigger: "blur"
                //     }
                // ],
                daiKanZongJie: [
                    {
                        required: true,
                        message: "请输入带看总结",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        if (this.roleType == 1 || this.roleType == 6) {
            this.isUpdate = true //是否可以修改
        }
        /* 获取带看列表 */
        this.getDaiKanList()
    },

    methods: {
        // 保留两位小数
        towNumber(val) {
            return val.toFixed(2)
        },
        //选中后关闭经纪人弹层
        colseJingJiRenDialog(jingJiRenData) {
            this.daiKanForm.agentName = jingJiRenData.userName
            this.daiKanForm.agentId = jingJiRenData.userId
            this.jingjirenDialog = false
        },
        //经纪人弹层
        openjingjirenDialog() {
            this.jingjirenDialog = true
        },
        openFangyuanDialog(type) {
            this.daikanFangyuanType = type
            this.fangyuanDialog = true
            this.dkfylist = []
        },
        CheckPrice() {
            if (this.daiKanForm.BuyerPriceFrom > this.daiKanForm.BuyerPriceTo) {
                this.$message.error("最小价格不能大于最大价格!")
                let temp = ""
                temp = this.daiKanForm.BuyerPriceFrom
                this.daiKanForm.BuyerPriceFrom = this.daiKanForm.BuyerPriceTo
                this.daiKanForm.BuyerPriceTo = temp
                // this.getHouseList()
            }
        },
        // colseFangyuanDialog(fangyuanData) {
        //     if (this.daikanFangyuanType == "searchDaiKan") {
        //         this.houseId = fangyuanData.houseId
        //         this.louPanName = fangyuanData.louPanName
        //     } else if (this.daikanFangyuanType == "addDaiKan") {
        //         this.daiKanForm.houseId = fangyuanData.houseId
        //         this.daiKanForm.louPanName = fangyuanData.louPanName
        //     }
        //     this.fangyuanDialog = false
        // },
        colseFangyuanDialog(fangyuanData, type) {
            console.log(fangyuanData)
            console.log(type)
            if (type == 2) {
                if (this.daikanFangyuanType == "searchDaiKan") {
                    fangyuanData.forEach(function (i) {
                        this.houseId.push(i.houseId)
                        this.louPanName.push(i.louPanName)
                    })
                } else if (this.daikanFangyuanType == "addDaiKan") {
                    var dkhouseid = []
                    var dkname = []
                    var dkHouseNo = []
                    fangyuanData.forEach(function (i) {
                        dkhouseid.push(i.houseId)

                        dkname.push(
                            "\xa0\xa0" + i.louPanName + "\xa0\xa0" + i.houseNo
                        )
                    })
                    this.dkhouseid = dkhouseid

                    this.daiKanForm.louPanName = dkname.toString() + "\xa0"
                    console.log(this.daiKanForm.louPanName)
                }
            }
            this.dkfylist = fangyuanData
            this.fangyuanDialog = false
        },
        openKeyuanDialog() {
            this.keyuanDialog = true
        },
        colseKeyuanDialog(keyuanData) {
            console.log(keyuanData)
            this.daiKanForm.customerId = keyuanData.customerId
            this.daiKanForm.customerName = keyuanData.customerName
            if (this.state == "1") {
                this.daiKanForm.BuyerPriceFrom = keyuanData.priceFrom / 10000
                this.daiKanForm.BuyerPriceTo = keyuanData.priceTo / 10000
            } else {
                this.daiKanForm.BuyerPriceFrom = keyuanData.priceFrom
                this.daiKanForm.BuyerPriceTo = keyuanData.priceTo
            }

            // this.daiKanForm.customerName
            // this.GetDaiKanMan()

            this.keyuanDialog = false
        },
        /* 获取带看人列表 */
        GetDaiKanMan() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetDaiKanMan, {
                    params: {
                        customerId: that.daiKanForm.customerId
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            that.DaiKanManList = res.data.dtList
                        } else {
                            that.DaiKanManList = []
                        }
                    } else {
                        that.$message.error(res.message)
                    }
                })
        },
        /* 获取日志列表 */
        getDaiKanList() {
            let that = this
            that.daiKanList = []
            that.loading = true
            const params = {
                IsHouseToSell: that.state,
                customerId: that.customerId,
                userId: that.daikanR,
                houseId: that.houseId,
                currentPage: that.daiKanCurrentPage,
                pageSize: that.daiKanPageSize
            }
            that.$http
                .get(that.$url.GetDaiKanList, {
                    params: params
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            res.data.dtList.forEach((item) => {
                                item.checked = []
                                if (that.state == "1") {
                                    // item.buyerPrice = item.buyerPrice / 10000
                                    // item.sellerPrice = item.sellerPrice / 10000
                                }
                                // item.oneKeting = ""
                                // if (item.shiKanInfo != null) {
                                //     if (
                                //         JSON.parse(
                                //             item.shiKanInfo
                                //         ).keting.split(",")[0] != null
                                //     ) {
                                //         item.oneKeting =
                                //             that.$url.picSrc +
                                //             JSON.parse(
                                //                 item.shiKanInfo
                                //             ).keting.split(",")[0]
                                //     }
                                // }
                            })
                            that.daiKanList = res.data.dtList
                        } else {
                            that.daiKanList = []
                        }
                        that.daiKanListTotal = res.data.maxRecords
                    } else {
                        that.$message.error(res.message)
                    }
                })
        },
        searchDaikanJiLu() {
            this.getDaiKanList()
        },
        resetDaikanJiLu() {
            this.houseId = ""
            this.louPanName = "请选择带看房源"
            this.daikanR = ""
            this.getDaiKanList()
        },
        daiKanSizeChange(newSize) {
            this.daiKanPageSize = newSize
            this.getDaiKanList()
        },
        daiKanCurrentChange(newPage) {
            this.daiKanCurrentPage = newPage
            this.getDaiKanList()
        },
        daiKanSelection(val) {
            if (val.length > 0) {
                val.forEach((item) => {
                    this.checkedDaiKanIdList.push(item.daikanId)
                })
            } else {
                this.checkedDaiKanIdList = []
            }
        },
        updateDaiKan(item, type, title) {
            console.log(item)
            this.daiKanDialogVisible = true
            this.daiKanDialogTitle = title
            this.daiKanType = type
            if (item != "") {
                this.disabled = true
                this.daiKanForm = Object.assign(this.daiKanForm, item)
                this.daiKanForm.agentName = this.daiKanForm.daiKanRenName
                this.daiKanForm.agentId = item.userId
                this.daiKanForm.customerName = this.daiKanForm.customerName
                this.daiKanForm.BuyerPriceFrom = item.buyerPriceFrom
                this.daiKanForm.BuyerPriceTo = item.buyerPriceTo
                console.log(item.BuyerPriceFrom)
                if (this.daiKanForm.daiKanType.indexOf("买卖") != -1) {
                    this.state = "1"
                    this.fangyuanTabPane = "出售房源"
                    this.daiKanForm.BuyerPriceFrom = item.buyerPriceFrom / 10000
                    this.daiKanForm.BuyerPriceTo = item.buyerPriceTo / 10000
                } else if (this.daiKanForm.daiKanType.indexOf("租赁") != -1) {
                    this.state = "0"
                    this.fangyuanTabPane = "出租房源"
                }
            } else {
                this.disabled = false
                this.daiKanForm.agentId = ""
                this.daiKanForm.agentName = "请选择经纪人"
                this.daiKanForm.daikanId = ""
                this.daiKanForm.houseId = ""
                this.daiKanForm.louPanName = "请选择带看房源"
                this.daiKanForm.customerId = ""
                this.daiKanForm.customerName = "请选择客户"
                this.daiKanForm.daiKanType = ""
                this.daiKanForm.daiKanTime = ""
                this.daiKanForm.buyerPrice = ""
                this.daiKanForm.BuyerPriceFrom = 0
                this.daiKanForm.BuyerPriceTo = 0

                // this.daiKanForm.sellerPrice = ""
                // this.daiKanForm.daiKanFanKui = ""
                this.daiKanForm.daiKanZongJie = ""
            }
        },
        colseDaiKanDialog() {
            this.daiKanDialogVisible = false
            this.daiKanForm.agentId = ""
            this.daiKanForm.agentName = "请选择经纪人"
            this.daiKanForm.daikanId = ""
            this.daiKanForm.houseId = ""
            this.daiKanForm.louPanName = "请选择带看房源"
            this.daiKanForm.customerId = ""
            this.daiKanForm.customerName = "请选择客户"
            this.daiKanForm.daiKanType = ""
            this.daiKanForm.daiKanTime = ""
            this.daiKanForm.buyerPrice = ""
            this.daiKanForm.BuyerPriceFrom = 0
            this.daiKanForm.BuyerPriceTo = 0
            this.daiKanForm.daiKanZongJie = ""
            this.$refs.daiKanRuleForm.clearValidate()
        },
        saveDaiKanyes() {
            var that = this
            if (this.dkhouseid.length == 0) {
                that.saveDaiKan()
                return
            } else {
                for (var i = 0; i < that.dkhouseid.length; i++) {
                    that.daiKanForm.houseId = that.dkhouseid[i].toString()
                    console.log(that.dkhouseid[i])
                    that.saveDaiKan()
                }
            }
        },
        saveDaiKan() {
            let that = this,
                url = "",
                method = ""
            that.$refs.daiKanRuleForm.validate(async (valid) => {
                if (valid) {
                    that.daiKanDialogLoading = true
                    that.daiKanDisabled = true
                    if (that.state == "1") {
                        that.daiKanForm.BuyerPriceFrom =
                            that.daiKanForm.BuyerPriceFrom * 10000
                        that.daiKanForm.BuyerPriceTo =
                            that.daiKanForm.BuyerPriceTo * 10000
                    } else {
                        that.daiKanForm.BuyerPriceFrom =
                            that.daiKanForm.BuyerPriceFrom
                        that.daiKanForm.BuyerPriceTo =
                            that.daiKanForm.BuyerPriceTo
                    }
                    if (typeof that.daiKanForm.BuyerPriceFrom == "string") {
                        that.daiKanForm.BuyerPriceFrom = Number(
                            that.daiKanForm.BuyerPriceFrom
                        )
                    }
                    if (typeof that.daiKanForm.BuyerPriceTo == "string") {
                        that.daiKanForm.BuyerPriceTo = Number(
                            that.daiKanForm.BuyerPriceTo
                        )
                    }

                    const data = {
                        userId: that.daiKanForm.agentId,
                        houseId: that.daiKanForm.houseId,
                        customerId: that.daiKanForm.customerId,
                        customerName: that.daiKanForm.customerName,
                        daiKanTime: that.daiKanForm.daiKanTime.replace(
                            " ",
                            "T"
                        ),
                        daiKanType: that.daiKanForm.daiKanType,
                        BuyerPriceFrom: that.daiKanForm.BuyerPriceFrom,
                        BuyerPriceTo: that.daiKanForm.BuyerPriceTo,
                        daiKanZongJie: that.daiKanForm.daiKanZongJie
                    }
                    if (this.daiKanType == "add") {
                        url = that.$url.InsertDaiKan
                        method = "post"
                    } else {
                        console.log(this.state)
                        url = that.$url.UpdateDaiKan
                        data.daikanId = that.daiKanForm.daikanId
                        method = "put"
                    }
                    that.$http[method](url, data, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                if (that.daiKanType == "add") {
                                    that.$message.success("带看记录新增成功！")
                                } else {
                                    that.$message.success("带看记录修改成功！")
                                }
                                that.daiKanDialogLoading = false
                                that.daiKanDialogVisible = false
                                that.daiKanDisabled = false

                                that.getDaiKanList()
                            } else {
                                that.daiKanDialogLoading = false
                                that.daiKanDisabled = false
                                that.$message.error(res.message)
                            }
                        })
                        .catch((res) => {
                            that.daiKanDialogLoading = false
                            that.daiKanDisabled = false
                            that.$message.error(res.message)
                        })
                } else {
                    that.daiKanDisabled = false
                    return false
                }
            })
        },
        deleteDaiKan(item, type) {
            let that = this,
                genJinId = []
            if (type == "oneGe") {
                genJinId.push(item.daikanId)
            } else {
                if (that.checkedDaiKanIdList == 0) {
                    that.$message.warning("请选择要删除的带看记录！")
                    return false
                } else {
                    genJinId = that.checkedDaiKanIdList
                }
            }
            this.$confirm("确认要删除吗？")
                .then((_) => {
                    that.loading = true
                    this.$http
                        .delete(this.$url.DeleteDaiKan, {
                            data: genJinId,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            that.loading = false
                            if (res.data.msgCode == 1) {
                                that.$message.success("带看记录删除成功！")
                                that.getDaiKanList()
                            } else {
                                that.$message.error(res.message)
                            }
                        })
                    done()
                })
                .catch((_) => {})
        },
        handleClickTab(tab, event) {
            this.fangyuanTabPane = tab.label
            if (tab.label == "出售房源") {
                this.state = "1"
            } else if (tab.label == "出租房源") {
                this.state = "0"
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.xuqiuDialog /deep/ .el-dialog__body {
    padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
    right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
    padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
    margin: 2px 0 2px 3px !important;
}
.qiugou-tabs {
    font-size: 14px;
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        margin: 0px;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-card__body {
        margin: 5px 25px;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }
}
</style>
