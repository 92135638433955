<template>
  <div>
    <el-card class="search">
      <el-row>
        <el-col :span="6">
          <span class="title">客户姓名：</span>
          <el-input
            placeholder="请输入客户姓名"
            v-model="search.customerName"
            class="media-width"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <span class="title">手机号：</span>
          <el-input
            maxlength="11"
            placeholder="请输入手机号"
            v-model="search.tel"
            class="media-width"
          ></el-input>
        </el-col>
        <!-- <el-col :span="5">
          <span class="title">维护情况：</span>
          <el-input
            placeholder="请输入维护情况"
            v-model="search.weiHuQingKuang"
            class="media-width"
          ></el-input>
        </el-col> -->
        <el-col :span="6">
          <span class="title">经纪人：</span>
          <el-input
            placeholder="请输入客户姓名"
            v-model="search.UserName"
            class="media-width"
          ></el-input>
        </el-col>
        <!-- <el-col :span="5" style="margin-top: 20px;" v-if="roleType == 6">
          <span class="title">商户：</span>
          <el-input
            @focus="merchantDialogTrue"
            placeholder="请选择商户"
            v-model="MerchantName"
            class="media-width"
          ></el-input>
        </el-col>
        <el-col :span="5" style="margin-top: 20px;" v-if="roleType == 6 || roleType == 5">
          <span class="title">店铺：</span>
          <el-input
          @focus="shopDialogTrue"
            placeholder="请选择店铺"
            readonly="readonly"
            v-model="ShopName"
            class="media-width"
          ></el-input>
        </el-col> -->
        <el-col :span="6" style="float: right">
          <el-button
            type="warning"
            size="mini"
            style="margin-left: 5px; float: right"
            @click="resetCustomerList"
            >重置</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 15px; float: right"
            @click="searchCustomerList"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" style="margin-top: 20px;" v-if="roleType == 6">
          <span class="title">商户：</span>
          <el-input
            @focus="merchantDialogTrue"
            placeholder="请选择商户"
            v-model="MerchantName"
            class="media-width"
          ></el-input>
          <!-- @input="changeInput" -->
        </el-col>
        <el-col :span="6" style="margin-top: 20px;" v-if="roleType == 6 || roleType == 5">
          <span class="title">店铺：</span>
          <el-input
          @focus="shopDialogTrue"
            placeholder="请选择店铺"
            readonly="readonly"
            v-model="ShopName"
            class="media-width"
          ></el-input>
        </el-col>
      </el-row>
      
      <hr id="hrOne" style="height: 1px; border: none; backgroundcolor: #eee;margin-top: 0px;" />
      <el-tabs
        v-model="tabPane"
        @tab-click="handleClickTab"
        class="qiugou-tabs"
        style="position: relative; top: 0px; background-color: #fff"
      >
        <el-tab-pane
          name="求购"
          label="求购"
          v-if="fangyuanState == '' || fangyuanState == '1'"
        >
          <el-card style="margin: 0 !important; border: none"> </el-card>
        </el-tab-pane>
        <el-tab-pane
          name="求租"
          label="求租"
          v-if="fangyuanState == '' || fangyuanState == '0'"
        >
          <el-card style="margin: 0 !important; border: none"> </el-card>
        </el-tab-pane>
      </el-tabs>
      <el-row>
        <span class="title" style="width: 70px; display: inline-block"
          >范围：</span
        >
        <div
          v-for="item in fanweiList"
          :key="item.value"
          :value="item.value"
          effect="dark"
          class="xuanxiang2"
          :class="{ active: isFanwei === item.label }"
          @click="searchLeixin(item, 'fanwei')"
        >
          {{ item.label }}
        </div>
      </el-row>
      <el-row>
        <span class="title">意愿等级：</span>
        <div
          style="font-size: 14px"
          v-for="item in yiyuanDengJi"
          :key="item"
          class="xuanxiang2"
          :class="{ active: requirementLevel === item }"
          @click="searchLeixin(item, 'yiyuanDengJi')"
        >
          {{ item }}
        </div>
      </el-row>
      <el-row v-show="show == true">
        <span class="title">客户进度：</span>
        <div
          style="font-size: 14px"
          v-for="item in kehuJinDu"
          :key="item"
          class="xuanxiang2"
          :class="{ active: isKehuJinDu === item }"
          @click="searchLeixin(item, 'kehuJinDu')"
        >
          {{ item }}
        </div>
      </el-row>
      <el-row v-show="show == true" style="margin-top: 3px">
        <span class="title" style="float: left">客户标签：</span>
        <div
          style="font-size: 14px; margin-left: 0.7%"
          v-for="item in kehuBiaoQian"
          :key="item.commonMasterId"
          class="xuanxiang2"
          :class="{ active: iskehuBiaoQian === item.commonMasterId }"
          @click="searchLeixin(item.commonMasterId, 'kehuBiaoQian')"
        >
          {{ item.commonMasterName }}
        </div>
      </el-row>
      <el-row v-show="show == true" style="margin-top: 3px">
        <span class="title">意向价格：</span>
        <div
          style="font-size: 14px"
          v-for="item in yixiangJiaGe"
          :key="item"
          class="xuanxiang2"
          :class="{ active: isYixiangJiaGe === item }"
          @click="searchLeixin(item, 'yixiangJiaGe')"
        >
          {{ item }}
        </div>
        <span style="margin-left: 20px; font-size: 14px">
          <el-input
            onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
            oninput="if(value>99999)value=99999"
            placeholder="最小"
            class="padding-r0"
            style="width: 90px"
            min="0"
            type="number"
            v-model="priceFrom"
          ></el-input>
          -
          <el-input
            onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
            oninput="if(value>99999)value=99999"
            placeholder="最大"
            class="padding-r0"
            style="width: 90px"
            min="0"
            type="number"
            v-model="priceTo"
          ></el-input>
          万
          <el-button
            v-if="priceFrom > 0 || priceTo > 0"
            type="primary"
            size="mini"
            style="margin-left: 5px"
            @click="searchBlur('price')"
            >确定</el-button
          >
        </span>
      </el-row>
      <el-row v-show="show == true" style="margin-top: 3px">
        <span class="title">意向面积：</span>
        <div
          style="font-size: 14px"
          v-for="item in yixiangMianJi"
          :key="item"
          class="xuanxiang2"
          :class="{ active: isYixiangMianJi === item }"
          @click="searchLeixin(item, 'yixiangMianJi')"
        >
          {{ item }}
        </div>
        <span style="margin-left: 20px; font-size: 14px">
          <el-input
            placeholder="最小"
            class="padding-r0"
            style="width: 90px"
            min="0"
            type="number"
            onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
            oninput="if(value>99999)value=99999"
            v-model="squareFrom"
          ></el-input>
          -
          <el-input
            placeholder="最大"
            class="padding-r0"
            style="width: 90px"
            min="0"
            type="number"
            onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
            oninput="if(value>99999)value=99999"
            v-model="squareTo"
          ></el-input>
          平
          <el-button
            v-if="squareFrom > 0 || squareTo > 0"
            type="primary"
            size="mini"
            style="margin-left: 5px"
            @click="searchBlur('square')"
            >确定</el-button
          >
        </span>
      </el-row>
      <el-row v-show="show == true" style="margin-top: 3px">
        <span class="title">意向居室：</span>
        <div
          style="font-size: 14px"
          v-for="item in yixiangJuShi"
          :key="item"
          class="xuanxiang2"
          :class="{ active: isYixiangJuShi === item }"
          @click="searchLeixin(item, 'yixiangJuShi')"
        >
          {{ item }}
        </div>
      </el-row>
      <el-row v-show="show == true" style="margin-top: 3px">
        <span class="title">合作状态：</span>
        <div
          style="font-size: 14px"
          v-for="item in hezuoZhuangTai"
          :key="item.value"
          class="xuanxiang2"
          :class="{ active: isHezuoZhuangTai === item.text }"
          @click="searchLeixin(item, 'hezuoZhuangTai')"
        >
          {{ item.text }}
        </div>
      </el-row>
      <el-row v-if="!isGenJin && roleType == '1'">
        <el-col>
          <el-button
            size="mini"
            @click="jumpToInsert()"
            type="primary"
            icon="el-icon-plus"
            style="border: none"
            >新增</el-button
          >
        </el-col>
      </el-row>
       <!-- 商户弹出层 -->
            <el-dialog :visible.sync="merchantDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">商户：</span>
                            <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetMerchantList">查询</el-button>
                                <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                        <el-col>
                            <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                            <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="MerchantDialogDetails" :data="operationList1" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="商户">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.merchantName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="merchantCurrentPage" :page-sizes="[10, 20, 50]" :page-size="merchantPageSize" layout="total, sizes, prev, pager, next, jumper" :total="merchantDialogTotal">
                </el-pagination>
            </el-dialog>
            <!-- 店铺弹出层 -->
            <el-dialog :visible.sync="shopDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
                <el-card class="search">
                    <el-row>
                        <el-col>
                            <span class="title">店铺：</span>
                            <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
                            <span style="float: right;">
                                <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetDianPuList">查询</el-button>
                                <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px" v-if="roleType==6">
                        <el-col>
                            <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                            <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 底部内容区域 -->
                <el-table ref="multipleTable" @row-click="ShopDialogDetails" :data="operationList2" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column label="店铺" prop="shopName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.shopName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户" prop="merchantName"></el-table-column>
                    <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="shopCurrentPage" :page-sizes="[10, 20, 50]" :page-size="shopPageSize" layout="total, sizes, prev, pager, next, jumper" :total="shopDialogTotal">
                </el-pagination>
            </el-dialog>
    </el-card>
    <!-- 底部内容区域 -->
    <el-card>
      <el-table
        ref="multipleTable"
        @sort-change="sortChange"
        @row-click="xzKeYuan"
        v-loading="loading"
        :data="CustomerList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px',
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
        stripe
      >
        <el-table-column label="姓名" width="130;" prop="customerName">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-button
                v-if="isGenJin"
                style="width: 92%"
                class="tableButSpan el-rate-css"
                type="text"
                size="mini"
              >
                <div
                  style="
                    font-size: 14px;
                    color: #000;
                    font-weight: bold;
                    text-align: center;
                  "
                >
                  {{ scope.row.customerName }}
                </div>
              </el-button>
              <el-button
                v-else
                style="width: 92%"
                class="tableButSpan el-rate-css"
                @click="jumpToDetail(scope.row, 2, 1)"
                type="text"
                size="mini"
              >
                <div
                  style="
                    font-size: 14px;
                    color: #000;
                    font-weight: bold;
                    text-align: center;
                  "
                >
                  {{ scope.row.customerName }}
                </div>
              </el-button>
              <el-tooltip
                v-if="
                  (scope.row.weiGenJinShiChang >= 12 &&
                  scope.row.weiDaiKanShiChang == null) ||
                  (scope.row.weiGenJinShiChang == null &&
                  scope.row.weiDaiKanShiChang >= 12) ||
                  (scope.row.weiGenJinShiChang >= 12 &&
                  scope.row.weiDaiKanShiChang >= 12)
                "
                class="item"
                effect="dark"
                :content="'已超过十二天未联系此客源'"
                placement="right"
              >
                <div
                  class="el-icon-warning"
                  style="
                    color: red;
                    position: absolute;
                    right: 10px;
                    height: 33px;
                    line-height: 33px;
                    cursor: pointer;
                    z-index: 999;
                  "
                ></div>
              </el-tooltip>
              <el-tooltip
                v-if="
                  scope.row.weiGenJinShiChang == null &&
                  scope.row.weiDaiKanShiChang == null
                "
                class="item"
                effect="dark"
                :content="'此客源未带看，未跟进'"
                placement="right"
              >
                <div
                  class="el-icon-warning"
                  style="
                    color: #ffab3c;
                    position: absolute;
                    right: 10px;
                    height: 33px;
                    line-height: 33px;
                    cursor: pointer;
                    z-index: 999;
                  "
                ></div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="意愿等级"
          width="80px;"
          prop="customerScore"
        ></el-table-column>
        <el-table-column
        v-if="roleType == 6"
          label="商铺名称"
          width="100px;"
          prop="merchantName"
        ></el-table-column>
        <el-table-column
          label="店铺名称"
          width="100px;"
          prop="shopName"
        ></el-table-column>
        <el-table-column
          label="客户标签"
          width="100px;"
          prop="commonMasterName"
        ></el-table-column>
        <el-table-column
          label="委托时间"
          prop="createTime"
          width="100px;"
        ></el-table-column>
        <el-table-column
          label="维护情况"
          prop="weiHuQingKuang"
          width="90px;"
        ></el-table-column>
        <el-table-column
          label="最近带看时间"
          prop="lastDaiKanTime"
          width="105px;"
        ></el-table-column>
        <el-table-column label="跟进信息" prop="genJinInfo"></el-table-column>
        <el-table-column
          label="客户经纪人"
          prop="customerUser"
          width="100px;"
        ></el-table-column>
        <el-table-column label="合作经纪人" prop="partnerName" width="100px;">
          <template slot-scope="scope">
            <div>{{ scope.row.partnerName }}</div>
            <el-button
              type="text"
              size="mini"
              class="font-class-red"
              @click="cancelRecommend(scope.row)"
              v-if="scope.row.partner == 1 && !isGenJin"
              >取消合作</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="详细需求"
          width="240px;"
          style="text-align: left"
          prop="xiangXiXuQiu"
        ></el-table-column>
        <el-table-column label="操作" width="180px;" v-if="!isGenJin">
          <template slot-scope="scope" class="right">
            <!-- <el-button
              @click.native.stop="jumpToDetail(scope.row, 2, 2)"
              v-if="(roleType == 1 ||roleType == 5 || roleType == 6)&&scope.row.enabled=='1'"
              type="text"
              size="mini"
              class="el-icon-edit caozuo"
              style="color: #1890ff"
              >修改</el-button
            > -->
            <el-button
              v-if="scope.row.enabled=='1'"
              type="text"
              size="mini"
              class="el-icon-lock caozuo"
              style="color: rgb(144, 147, 153)"
              @click="WuXiaoKeYuan(scope.row)"
              >无效</el-button
            >
            <!-- <el-button
              @click.native.stop="deletekeyuan(scope.row, 2, 2)"
              v-if="(roleType == 1 ||roleType == 5 || roleType == 6)&&scope.row.enabled=='1'"
              type="text"
              size="mini"
              class="el-icon-edit caozuo"
              style="color: red"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
          <img
            style="width: 200px; margin-top: 50px"
            src="../../../public/img/noList.png"
          />
          <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
        </div>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import KeYuanJs from "./KeYuan.js";

export default {
  name: "GetQiuzuQiugouList",
  inject: ["reload"],
  data() {
    return {
      state: this.$route.query.state,
      tabPane: "求购",
      loading: false,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      CustomerList: [], //客户列表
      show: true,
      fanweiList: KeYuanJs.fanweiList,
      isFanwei: "不限",
      yiyuanDengJi: ["不限", "一级", "二级", "三级"],
      // kehuFenJi: ["不限", "好客"],
      kehuJinDu: [
        "不限",
        "未带看",
        "首看",
        "复看",
        "三看及以上",
        "已交定金",
        "已签约",
        "已过户",
      ],
      kehuBiaoQian: [{ commonMasterId: "", commonMasterName: "不限" }],
      yixiangJiaGe: [
        "不限",
        "80万以下",
        "80-100万",
        "100-120万",
        "120-150万",
        "150-200万",
        "200-250万",
        "250-300万",
        "300万以上",
      ],
      yixiangMianJi: [
        "不限",
        "50平以下",
        "50-70平",
        "70-90平",
        "90-110平",
        "110-130平",
        "130-150平",
        "150-200平",
        "200平以上",
      ],
      yixiangJuShi: ["不限", "1居", "2居", "3居", "4居", "5居", "5居以上"],
      hezuoZhuangTai: [
        { text: "不限", value: "" },
        { text: "我发起合作", value: "发起" },
        { text: "我接受合作", value: "接受" },
      ],
      requirementLevel: "不限",
      isKehuJinDu: "不限",
      iskehuBiaoQian: "",
      checkedKeHuBiaoQian: [], //客户标签查询
      sanxiangKeHuBiaoQian: [], //最多三项客户标签
      isYixiangJiaGe: "不限",
      isYixiangMianJi: "不限",
      isYixiangJuShi: "不限",
      isHezuoZhuangTai: "不限",
      search: {
        //查询条件
        isToBuy: "1", //1求购 0是求租
        customerName: "",
        enabled: "",
        requirementLevel: "",
        keHuJinDu: "",
        keHuBiaoQianIds: "",
        priceFrom: 0,
        priceTo: 0,
        squareFrom: 0,
        squareTo: 0,
        roomCount: 0,
        heZuoZhuangTai: "",
        weiHuQingKuang: "",
        tel: "",
        sortBy: "",
        UserName: "",
        ShopId: "",
        MerchantId:'',
      },
      shopName: "",
      ShopName: "",
      shopId: "",
      shopPageSize: 10,
      shopCurrentPage: 1,
      shopDialog: false,
      shopDialogTotal: 0,
      operationList2: [],
      merchantName: "",
      MerchantName: "",
      merchantId:'',
      merchantPageSize: 10,
      merchantCurrentPage: 1,
      merchantDialogTotal: 0,
      operationList1: [],
      merchantDialog: false,
      priceFrom: "",
      priceTo: "",
      squareFrom: "",
      squareTo: "",
      roleType:'',
      CustomerParams: JSON.parse(window.localStorage.getItem("CustomerParams"))
      // hr1:document.getElementById('hr')
    };
  },
  props: {
    /* 是否为客源带看 */
    isGenJin: {
      type: Boolean,
      default: false,
    },
    fangyuanState: {
      type: String,
      default: "",
    },
  },
  created() {
    this.roleType = localStorage.getItem("roleType")
    /* 获取所有的日志列表 */
    if (this.state == "1") {
      this.search.isToBuy = "1";
      this.tabPane = "求购";
    } else if (this.state == "0") {
      this.search.isToBuy = "0";
      this.tabPane = "求租";
    }
    if (this.fangyuanState == "1") {
      this.search.isToBuy = "1";
      this.tabPane = "求购";
    } else if (this.fangyuanState == "0") {
      this.search.isToBuy = "0";
      this.tabPane = "求租";
    }
    if (this.CustomerParams) {
      this.currentPage = this.CustomerParams.currentPage;
      this.pageSize = this.CustomerParams.pageSize;
    }
    this.GetCustomerList();
    this.getShuJuZiDianList();
    if (this.roleType == 6) {
      this.getMerchantList()
    }
    if (this.roleType == 6 || this.roleType == 5) {
      this.getDianPuList()
    }
   
  },
  mounted(){
    this.getStyle()
    // if (this.roleType == 5) {
    //   document.getElementById('dianpu').style.letterSpacing = 9 + 'px'
    // }
  },
  watch: {
     "MerchantName" :function MerchantName(val){
         this.ShopName = ''
      },
    checkedKeHuBiaoQian(val) {
      if (val.length > 0) {
        if (val.length > 3) {
          this.$message.info("最多只能选三项");
          this.checkedKeHuBiaoQian = this.sanxiangKeHuBiaoQian;
          return false;
        } else {
          this.sanxiangKeHuBiaoQian = val;
        }
        this.iskehuBiaoQian = "";
      } else {
        this.iskehuBiaoQian = "不限";
      }
      this.GetCustomerList();
    },
    fangyuanState(newVal, oldVal) {
      let that = this;
      if (that.fangyuanState != "") {
        if (that.fangyuanState == "1") {
          that.search.isToBuy = "1";
          that.tabPane = "求购";
        } else if (that.fangyuanState == "0") {
          that.search.isToBuy = "0";
          that.tabPane = "求租";
        }
        that.GetCustomerList();
        that.getShuJuZiDianList();
      }
    },
  },
  methods: {
    getStyle() {
      let that = this
      if (that.roleType == 6 || that.roleType == 5) {
        document.getElementById('hrOne').style.marginTop = 45 + "px"
      }
    },
    shopDialogTrue() {
      if (this.roleType == 5) {
        this.shopDialog = true
                this.getDianPuList()
      }
      if (this.roleType == 6) {
         if (this.MerchantName == "" || this.MerchantName == null) {
                this.$message.error("请先选择商户")
            } else {
                this.shopDialog = true
                this.getDianPuList()
            }
      }
           
        },
    handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log(this.multipleSelection);
        },
     // 商户弹出层
    merchantDialogTrue() {
      this.merchantDialog = true
    },
    // 商户查询
    cgetMerchantList() {
      this.currentPage = 1
      this.getMerchantList()
    },
    //重置商户搜多条件
    cancelMerchantList() {
            this.merchantName = ""
            this.enabled = ""
            this.merchantCurrentPage = 1
            this.getMerchantList()
    },
    handleSelectionChange(val) {
            this.multipleSelection = val
            const multipleSelection1 = val
            this.multipleSelection2 = []
            for (let i = 0; i < multipleSelection1.length; i++) {
                console.log(multipleSelection1[i].name)
                this.multipleSelection2.push(multipleSelection1[i].name)
            }
        },
                /* 获取商户列表 */
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.merchantCurrentPage,
                pageSize: this.merchantPageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList1 = res.dtList
                this.merchantDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    console.log(res.dtList[i])
                    if (res.dtList[i].enabled === "1") {
                        this.operationList1[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList1[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听商户pagesize改变的事件 */
        handleSizeChange1(newSize) {
            this.merchantPageSize = newSize
            this.getMerchantList()
        },
        /* 监听商户页码值改变的事件 */
        handleCurrentChange1(newPage) {
            this.merchantCurrentPage = newPage
            this.getMerchantList()
        },
    // 选择商户赋值
    MerchantDialogDetails(row) {
            const that = this
            that.MerchantName = row.merchantName
            that.search.MerchantId = row.merchantId
            that.merchantId = row.merchantId
            that.search.ShopId = ''
            that.ShopName = ''
            that.merchantDialog = false
            this.getDianPuList()
        },
        //重置店铺搜索条件
        cancelDianPuList() {
            this.shopName = ""
            this.enabled = ""
            this.shopCurrentPage = 1
            this.getDianPuList()
        },
        // 选择店铺赋值
        ShopDialogDetails(row) {
            const that = this
            that.ShopName = row.shopName
            that.search.ShopId = row.shopId
            that.shopId = row.shopId
            that.shopDialog = false
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
            const multipleSelection1 = val
            this.multipleSelection2 = []
            for (let i = 0; i < multipleSelection1.length; i++) {
                console.log(multipleSelection1[i].name)
                this.multipleSelection2.push(multipleSelection1[i].name)
            }
        },
        /* 监听店铺pagesize改变的事件 */
        handleSizeChange2(newSize) {
            this.shopPageSize = newSize
            this.getDianPuList()
        },
        /* 监听店铺页码值改变的事件 */
        handleCurrentChange2(newPage) {
            this.shopCurrentPage = newPage
            this.getDianPuList()
        },
                // 查询店铺
        cgetDianPuList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        /* 获取店铺列表 */
        async getDianPuList() {
          if (this.roleType == 5) {
            const data = {
                areaIds: "", //商圈id
                shopName: this.shopName,
                merchantId: localStorage.getItem("merchantId"),
                currentPage: this.shopCurrentPage,
                pageSize: this.shopPageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("获取店铺成功")
                this.operationList2 = res.dtList
                this.shopDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList2[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList2[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
          } else {
            const data = {
                areaIds: "", //商圈id
                shopName: this.shopName,
                merchantId: this.merchantId,
                currentPage: this.shopCurrentPage,
                pageSize: this.shopPageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("获取店铺成功")
                this.operationList2 = res.dtList
                this.shopDialogTotal = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList2[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList2[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
          }
            
        },
    getShuJuZiDianList() {
      /* 获取客户标签列表 */
      let that = this;
      this.$commonJs.getShuJuZiDianList("KEHUBIAOQIAN").then((data) => {
        that.kehuBiaoQian = that.kehuBiaoQian.concat(data.kehuBiaoQian);
      });
    },
    handleClickTab(tab, event) {
      this.tabPane = tab.label;
      if (tab.label == "求购") {
        this.search.isToBuy = "1";
        this.priceFrom = "";
        this.priceTo = "";
        this.squareFrom = "";
        this.squareTo = "";
      } else if (tab.label == "求租") {
        this.search.isToBuy = "0";
        this.priceFrom = "";
        this.priceTo = "";
        this.squareFrom = "";
        this.squareTo = "";
      }
      this.GetCustomerList();
    },
    searchLeixin(item, type) {
      if (type == "fanwei") {
        this.isFanwei = item.label;
        this.search.enabled = item.value;
      } else if (type == "yiyuanDengJi") {
        this.requirementLevel = item;
        if (item == "不限") {
          this.search.requirementLevel = "";
        } else {
          this.search.requirementLevel = item;
        }
      } else if (type == "kehuJinDu") {
        console.log(item,"0000000000000000")
        this.isKehuJinDu = item;
        this.search.keHuJinDu = item;
      } else if (type == "kehuBiaoQian") {
        this.iskehuBiaoQian = item;
        this.search.KeHuBiaoQianId = item;
      } else if (type == "yixiangJiaGe") {
        this.isYixiangJiaGe = item;
        this.priceFrom = "";
        this.priceTo = "";
        this.search.priceFrom = 0;
        this.search.priceTo = 0;
        if (item.indexOf("以下") != -1) {
          this.search.priceFrom = 0;
          this.search.priceTo = this.$commonJs.NumberFormat(
            item.split("万以下")[0] * 10000
          );
          this.priceTo = this.$commonJs.NumberFormat(item.split("万以下")[0]);
          this.priceFrom = 0;
        } else if (item.indexOf("以上") != -1) {
          this.search.priceFrom = this.$commonJs.NumberFormat(
            item.split("万以上")[0] * 10000
          );
          this.search.priceTo = 999 * 10000;
          this.priceTo = 999;

          this.priceFrom = this.$commonJs.NumberFormat(item.split("万以上")[0]);
        } else if (item.indexOf("-") != -1) {
          item = item.split("万")[0];
          this.search.priceFrom = this.$commonJs.NumberFormat(
            item.split("-")[0] * 10000
          );
          this.search.priceTo = this.$commonJs.NumberFormat(
            item.split("-")[1] * 10000
          );
          this.priceTo = this.$commonJs.NumberFormat(item.split("-")[1]);

          this.priceFrom = this.$commonJs.NumberFormat(item.split("-")[0]);
        }
      } else if (type == "yixiangMianJi") {
        this.isYixiangMianJi = item;
        this.squareFrom = "";
        this.squareTo = "";
        this.search.squareFrom = 0;
        this.search.squareTo = 0;
        if (item.indexOf("以下") != -1) {
          this.search.squareFrom = 0;
          this.search.squareTo = this.$commonJs.NumberFormat(
            item.split("平以下")[0]
          );
          this.squareFrom = 0;
          this.squareTo = this.$commonJs.NumberFormat(item.split("平以下")[0]);
        } else if (item.indexOf("以上") != -1) {
          this.search.squareFrom = this.$commonJs.NumberFormat(
            item.split("平以上")[0]
          );
          this.search.squareTo = 999;
          this.squareFrom = this.$commonJs.NumberFormat(
            item.split("平以上")[0]
          );
          this.squareTo = 999;
        } else if (item.indexOf("-") != -1) {
          item = item.split("平")[0];
          this.search.squareFrom = this.$commonJs.NumberFormat(
            item.split("-")[0]
          );
          this.search.squareTo = this.$commonJs.NumberFormat(
            item.split("-")[1]
          );
          this.squareFrom = this.$commonJs.NumberFormat(item.split("-")[0]);
          this.squareTo = this.$commonJs.NumberFormat(item.split("-")[1]);
        }
      } else if (type == "yixiangJuShi") {
        this.isYixiangJuShi = item;
        if (item == "5居以上") {
          this.search.roomCount = 6;
        } else if (item == "不限") {
          this.search.roomCount = 0;
        } else {
          this.search.roomCount = Number(item.split("居")[0]);
        }
      } else if (type == "hezuoZhuangTai") {
        this.isHezuoZhuangTai = item.text;
        this.search.heZuoZhuangTai = item.value;
      }
      this.GetCustomerList();
    },
    searchBlur(type) {
      if (type == "price") {
        this.isYixiangJiaGe = "";
        this.priceFrom = this.$commonJs.NumberFormat(this.priceFrom);
        this.priceTo = this.$commonJs.NumberFormat(this.priceTo);
        if (this.priceFrom > this.priceTo) {
          [this.priceFrom, this.priceTo] = [this.priceTo, this.priceFrom];
        }
        this.search.priceFrom = this.priceFrom * 10000;
        this.search.priceTo = this.priceTo * 10000;
      } else if (type == "square") {
        this.isYixiangMianJi = "";
        this.squareFrom = this.$commonJs.NumberFormat(this.squareFrom);
        this.squareTo = this.$commonJs.NumberFormat(this.squareTo);
        if (this.squareFrom > this.squareTo) {
          [this.squareFrom, this.squareTo] = [this.squareTo, this.squareFrom];
        }
        this.search.squareFrom = this.squareFrom;
        this.search.squareTo = this.squareTo;
      }
      this.GetCustomerList();
    },
    // 查询
    searchCustomerList() {
      this.currentPage = 1;
      this.GetCustomerList();
    },
    resetCustomerList() {
      this.ShopName = ''
      this.MerchantName = ''
      this.search.ShopId = ''
      this.isFanwei = "不限";
      this.requirementLevel = "不限";
      this.isKehuJinDu = "不限";
      this.iskehuBiaoQian = "不限";
      this.isYixiangJiaGe = "不限";
      this.isYixiangMianJi = "不限";
      this.isYixiangJuShi = "不限";
      this.isHezuoZhuangTai = "不限";
      this.checkedKeHuBiaoQian = [];
      this.currentPage = 1;
      this.pageSize = 10;
      this.search = {
        isToBuy: this.search.isToBuy, //1求购 0是求租
        customerName: "",
        enabled: "",
        requirementLevel: "",
        keHuJinDu: "",
        keHuBiaoQianIds: "",
        priceFrom: 0,
        priceTo: 0,
        squareFrom: 0,
        squareTo: 0,
        roomCount: 0,
        heZuoZhuangTai: "",
        weiHuQingKuang: "",
        tel: "",
        sortBy: "",
      };
      this.priceFrom = "";
      this.priceTo = "";
      this.squareFrom = "";
      this.squareTo = "";
    },
    jumpToInsert() {
      this.$router.push({
        name: "AddKeYuan",
        query: {
          num: 0,
          state: this.search.isToBuy,
        },
      });
    },
    jumpToDetail(row, num, type) {
      this.$router.push({
        name: "KeYuanDetail",
        query: {
          customerId: row.customerId,
          num: num,
          state: this.search.isToBuy,
          self: row.self,
          recommend: row.recommend,
          type: type,
        },
      });
    },
    /* 获取列表 */
    async GetCustomerList() {
      let that = this;
      that.loading = true;
      that.search.sortBy = that.sortBy;
      that.search.currentPage = that.currentPage;
      that.search.pageSize = that.pageSize;
      const data = that.search;
      localStorage.setItem("CustomerParams", JSON.stringify(data));
      that.$http
        .post(that.$url.GetCustomerList, data)
        .then((res) => {
          that.loading = false;
          if (res.data.msgCode == 1) {
            that.CustomerList = res.data.dtList;
            that.total = res.data.maxRecords;
          } else {
            that.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          that.$message.error(res.data.message);
        });
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.GetCustomerList();
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.GetCustomerList();
    },
    //列表排序
    sortChange(column) {
      if (column.order == "ascending") {
        if (column.prop == "yiYuanDengJi") {
          this.sortBy = "c.yiYuanDengJi ASC";
        } else if (column.prop == "weiTuoShiJian") {
          this.sortBy = "c.weiTuoShiJian ASC";
        } else if (column.prop == "weiHuQingKuang") {
          this.sortBy = "c.weiHuQingKuang ASC";
        } else if (column.prop == "daiKanShiJian") {
          this.sortBy = "c.daiKanShiJian ASC";
        }
      } else if (column.order == "descending") {
        if (column.prop == "yiYuanDengJi") {
          this.sortBy = "c.yiYuanDengJi DESC";
        } else if (column.prop == "weiTuoShiJian") {
          this.sortBy = "c.weiTuoShiJian DESC";
        } else if (column.prop == "weiHuQingKuang") {
          this.sortBy = "c.weiHuQingKuang DESC";
        } else if (column.prop == "daiKanShiJian") {
          this.sortBy = "c.daiKanShiJian DESC";
        }
      }
      this.GetCustomerList();
    },
    xzKeYuan(row) {
      if (this.isGenJin) {
        row.state = this.search.isToBuy;
        console.log(row);
        this.$emit("colseKeyuanDialog", row);
      }
    },
    cancelRecommend(row) {
      let that = this;
      this.$confirm("确认要取消合作经纪人吗？")
        .then((_) => {
          that.loading = true;
          const data = {
            customerId: row.customerId,
          };
          that.$http
            .post(that.$url.CancelRecommend, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              that.loading = false;
              if (res.data.msgCode == 1) {
                that.$message.success("取消成功！");
                this.GetCustomerList();
              } else {
                that.$message.error(res.data.message);
              }
            });
        })
        .catch((_) => {});
    },
    // 无效客源
    WuXiaoKeYuan(row) {
      this.$confirm("要将该客源设置为无效?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row);
          const data = {
            CustomerId: row.customerId,
            UserId: row.userId,
            Enabled: "0",
          };
          const { data: res } = this.$http
            .put(this.$url.UpdateCustomerEnabled, data)
            .then((res) => {
              if (res.data.msgCode == 1) {
                this.$message.success("无效客源成功！");
                this.GetCustomerList();
              } else {
                this.$message.error(res.data.message);
              }
            });
        })
        .catch((err) => err);
    },
    // 删除客源
    async deletekeyuan(row) {
      console.log(row);
      const data = [row.customerId];
      console.log(data);
      const { data: res } = await this.$http.delete(
        this.$url.DeleteCustomer,
        {
          data: data
        }
      );

      if (res.msgCode == 1) {
        // console.log(res)
        this.GetCustomerList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.padding-r0 /deep/ input {
  padding-right: 0px !important;
}
.el-card {
  margin-top: 15px;
}
.title {
  font-size: 14px;
  width: 73px;
  display: inline-block;
  text-align: right;  
}
.caozuo {
  margin-left: 3px !important;
}
.font-class-red {
  color: red !important;
}
.qiugou-tabs {
  font-size: 14px;
  /deep/ .el-card {
    line-height: 30px;
  }
  /deep/ .el-tabs__nav > .is-active {
    background: transparent !important;
    color: #df0202 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #df0202;
  }
  /deep/ .el-tabs__header {
    margin: 0px;
  }
  /deep/ .el-tabs__active-bar,
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-card__body {
    margin: 5px 25px;
  }
  /deep/ .el-tabs__item:hover {
    background-color: transparent;
  }
  /deep/ .el-card {
    border: none;
  }
  /deep/ .el-tabs__nav-wrap {
    position: static;
  }
  /deep/ .el-form-item__label {
    text-align: right;
    font-size: 14px;
    letter-spacing: 1px;
    width: 100px;
    line-height: 30px;
  }
}
</style>
<style lang="less">
.el-rate-css {
  .el-rate {
    text-align: left;
  }
  .el-rate__icon {
    margin-right: 0px !important;
  }
}
</style>
