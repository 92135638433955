<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
    </el-breadcrumb> -->
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/GongZuoRiBaoList' }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row v-if="num == '1'">
                    <el-col :span="24">
                        <el-form-item label="汇报人:" label-width="10%" prop="userName">
                            <el-input :disabled="flag" placeholder="请输入工作总结" style="width: 40%" v-model="form.userName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作总结:" label-width="10%" prop="summary">
                            <el-input :readonly="flag" type="textarea" :rows="20" placeholder="请输入工作总结" style="width: 40%" v-model="form.summary"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="日报时间:" label-width="10%" prop="reportDate">
                            <el-date-picker :disabled="flag" v-model="form.reportDate" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 40%"> </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div> -->
                <el-form-item label-width="10%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],

    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },

            num: this.$route.query.num,
            dailyReportId: this.$route.query.dailyReportId,
            name: "",
            flag: false,
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            id: "",
            form: {
                summary: "",
                userName: "",
                reportDate: ""
            },
            currentPage: 1,
            pageSize: 100,
            rules: {
                summary: [
                    {
                        required: true,
                        message: "请输入工作总结",
                        trigger: "blur"
                    }
                ],
                reportDate: [
                    {
                        required: true,
                        message: "请选择日报时间",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        console.log(this.dailyReportId)
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")

        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "工作日报新增"
            this.form = {}
        } else if (this.num == 1) {
            this.name = "工作日报详情"
            this.flag = true
            this.isMax = true
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "工作日报修改"
            this.getArticleDetail()
        }
    },
    methods: {
        async getArticleDetail() {
            // const data = {

            // }
            const { data: res } = await this.$http.get(
                this.$url.GetDailyReportDetail,
                {
                    params: {
                        dailyReportId: this.dailyReportId
                    }
                }
            )
            if (res.msgCode == 1) {
                this.form = res.dtList[0]
            } else {
                this.$message.success(res.message)
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log(this.multipleSelection);
        },

        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        Summary: this.form.summary,
                        userName: this.form.userName,
                        ReportDate: this.form.reportDate
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.InsertDailyReport,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        // this.$message.success("新增日报信息成功")
                        this.$router.push({ path: "/GongZuoRiBaoList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        DailyReportId: this.dailyReportId,
                        Summary: this.form.summary,
                        ReportDate: this.form.reportDate
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateDailyReport,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$router.push({ path: "/GongZuoRiBaoList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },

        cancel() {
            this.$router.push({ path: "/GongZuoRiBaoList" })
        },
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].reportId)
                        }
                        this.$http
                            .delete(this.$url.deleteReport, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        handleDelete(index) {
            var data1 = [index.reportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.deleteReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {})
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>