<template>
    <div>
        <!-- 推荐人 -->
        <el-dialog title="推荐经纪人" :visible.sync="hezuorenDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <TongXunLuList :isQianyueOpen="true" @colseJingJiRenDialog="colseJingJiRenDialog"></TongXunLuList>
        </el-dialog>

        <!-- 跟进需求信息 -->
        <el-dialog class="xuqiuDialog" :title="genJinDialogTitle" :visible.sync="genJinDialogVisible" :before-close="genJinBeforeClose">
            <el-form ref="GenJinRuleForm" :model="genJinForm" :rules="genJinRules" label-position="right">
                <el-form-item v-if="roleType == '5' || roleType == '6'" label="经纪人" label-width="15%" prop="agentId">
                    <el-button :disabled="disabled" size="mini" class="buttonInput button_tanchu" @click="openjingjirenDialog(1)" style="width: 95%; height: 22px; line-height: 0px; text-align: left">
                        <span v-if="genJinForm.agentName == '请选择经纪人'" style="color: #c0c4cc">{{ genJinForm.agentName }}</span>
                        <span v-if="genJinForm.agentName != '请选择经纪人'">{{
              genJinForm.agentName
            }}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="跟进日期" label-width="15%" prop="genJinTime">
                    <el-date-picker style="width: 95%" v-model="genJinForm.genJinTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择跟进日期" :picker-options="pickerOptions" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="跟进内容" label-width="15%" prop="genJinInfo">
                    <el-input style="width: 95%" v-model="genJinForm.genJinInfo" :rows="5" type="textarea" placeholder="请输入跟进内容" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="dialog-footer">
                    <el-button type="warning" size="mini" @click="cancelGenJin">取消</el-button>
                    <el-button type="primary" size="mini" :disabled="genJinDisabled" @click="saveGenJin">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!-- 跟进需求 -->
        <el-dialog class="xuqiuDialog" title="跟进需求" :visible.sync="genJinRequirementVisible" width="50%" :before-close="GenJinRequirementClose">
            <div v-for="(item, i) in xuqiuList" :key="item.value">
                <el-tabs v-if="item.id != 'add'" class="qiugou-tabs" style="top: 0px; background-color: #fff">
                    <el-radio v-model="genJinRequirementChecked" :label="item" :style="{ top: state == '1' ? '150px' : '80px' }" style="float: right; right: 0">(请点击按钮选择需求！)</el-radio>
                    <el-tab-pane :label="item.requirementName">
                        <div>
                            <el-card>
                                <el-row>
                                    <el-col :span="12" v-if="state == '1'">
                                        <span class="titleLabel">购房目的：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].gouFangMuDi
                    }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">房屋用途：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].fangWuYongTuId
                    }}</span>
                                    </el-col>
                                    <el-col :span="12" v-if="state == '0'">
                                        <span class="titleLabel">是否整租:</span>
                                        <span class="labelValue">{{ ruleForm[i].isZhengZu }}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <span class="titleLabel">意向价位：</span>
                                        <span class="labelValue">{{ ruleForm[i].priceFrom
                      }}{{ state == "1" ? "万元" : "元" }}-{{
                        ruleForm[i].priceTo
                      }}{{ state == "1" ? "万元" : "元" }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">需求等级：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].requirementLevel
                    }}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <span class="titleLabel">面积：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].squareFrom != "" && ruleForm[i].SquareTo != ""
                        ? ruleForm[i].squareFrom +
                          "㎡-" +
                          ruleForm[i].SquareTo +
                          "㎡"
                        : ""
                    }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">居室：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].roomCountFrom != "" &&
                      ruleForm[i].roomCountTo != ""
                        ? ruleForm[i].roomCountFrom +
                          "居" +
                          "-" +
                          ruleForm[i].roomCountTo +
                          "居"
                        : ""
                    }}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <span class="titleLabel">城市：</span>
                                        <span class="labelValue">{{ ruleForm[i].city }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">意向商圈：</span>
                                        <span class="labelValue">{{ ruleForm[i].areaNames }}</span>
                                    </el-col>
                                </el-row>
                                <el-row v-if="state == '1'">
                                    <el-col :span="12">
                                        <span class="titleLabel">首付：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].ShouFuFrom != "" && ruleForm[i].ShouFuTo != ""
                        ? ruleForm[i].ShouFuFrom +
                          "万元-" +
                          ruleForm[i].ShouFuTo +
                          "万元"
                        : ""
                    }}</span>
                                    </el-col>
                                </el-row>
                                <el-row v-if="state == '1'">
                                    <el-col :span="12">
                                        <span class="titleLabel">装修状况：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].zhuangXiuZhuangKuang
                    }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">朝向：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].orientations.length > 0
                        ? ruleForm[i].orientations.toString()
                        : ""
                    }}</span>
                                    </el-col>
                                </el-row>
                                <el-row v-if="state == '1'">
                                    <el-col :span="12">
                                        <span class="titleLabel">楼层：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].louCengFrom != "" &&
                      ruleForm[i].louCengTo != ""
                        ? ruleForm[i].louCengFrom +
                          "层-" +
                          ruleForm[i].louCengTo +
                          "层"
                        : ""
                    }}</span>
                                    </el-col>
                                    <el-col :span="12">
                                        <span class="titleLabel">楼龄：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].houseAgeFrom != "" &&
                      ruleForm[i].houseAgeTo != ""
                        ? ruleForm[i].houseAgeFrom +
                          "年-" +
                          ruleForm[i].houseAgeTo +
                          "年"
                        : ""
                    }}</span>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <span class="titleLabel">付款方式：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].FuKuanFangShi
                    }}</span>
                                    </el-col>
                                    <el-col :span="12" v-if="state == '1' && ruleForm[i].FuKuanFangShi != '全款'">
                                        <span class="titleLabel">月供：</span>
                                        <span class="labelValue">{{
                      ruleForm[i].YueGongFrom != "" &&
                      ruleForm[i].YueGongTo != ""
                        ? ruleForm[i].YueGongFrom +
                          "元-" +
                          ruleForm[i].YueGongTo +
                          "元"
                        : ""
                    }}</span>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>

        <!-- 带看房源 -->
        <el-dialog title="带看房源" :visible.sync="fangyuanDialog" direction="rtl" width="70%" top="4%" class="xuqiuDialog">
            <KeYuanChuShouList :daiKanType="daiKanType" :dkfylist="dkfylist" :state="state" :isKeyuanDaikan="true" @colseFangyuanDialog="colseFangyuanDialog"></KeYuanChuShouList>
        </el-dialog>

        <!-- 带看记录信息 -->
        <el-dialog :title="daiKanDialogTitle" :visible.sync="daiKanDialogVisible" :before-close="colseDaiKanDialog" width="50%" class="xuqiuDialog">
            <el-form ref="daiKanRuleForm" :model="daiKanForm" :rules="daiKanRules" label-position="right">
                <el-form-item v-if="roleType == '5' || roleType == '6'" label="经纪人" label-width="15%" prop="agentId">
                    <el-button :disabled="disabled" size="mini" class="buttonInput button_tanchu" @click="openjingjirenDialog(2)" style="width: 95%; height: 22px; line-height: 0px; text-align: left">
                        <span v-if="daiKanForm.agentName == '请选择经纪人'" style="color: #c0c4cc">{{ daiKanForm.agentName }}</span>
                        <span v-if="daiKanForm.agentName != '请选择经纪人'">{{daiKanForm.agentName}}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="带看房源" label-width="15%" prop="houseId">
                    <el-button size="mini" class="buttonInput button_tanchu" @click="openFangyuanDialog('addDaiKan')" style="width: 95%; height: 22px; line-height: 0px; text-align: left">
                        <span v-if="daiKanForm.louPanName == '请选择带看房源'" style="color: #c0c4cc">{{ daiKanForm.louPanName }}</span>
                        <span v-if="daiKanForm.louPanName != '请选择带看房源'">{{daiKanForm.louPanName}}</span>
                    </el-button>
                </el-form-item>
                <el-form-item label="带看类型" label-width="15%" prop="daiKanType">
                    <el-select v-if="state == '1'" v-model="daiKanForm.daiKanType" placeholder="请选择带看类型" style="width: 95%">
                        <el-option v-for="item in daiKanLXGouOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>

                    <el-select v-if="state == '0'" v-model="daiKanForm.daiKanType" placeholder="请选择带看类型" style="width: 95%">
                        <el-option v-for="item in daiKanLXZuOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="带看时间" label-width="15%" prop="daiKanTime">
                    <el-date-picker style="width: 95%" v-model="daiKanForm.daiKanTime" type="datetime" placeholder="请选择带看日期" :picker-options="pickerOptions" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="意向价格" label-width="15%">
                    <span class="redStar" style="margin-left: -85px">*</span>
                    <el-row style="margin-top: -28px; height: 50px">
                        <el-col :span="7">
                            <el-form-item style="display: inline-block">
                                <el-input placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="daiKanForm.buyerPriceFrom" style="width: 275px; margin-left: -47px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <span style="margin-left: 108px">-</span>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item prop="buyerPriceTo" style="display: inline-block">
                                <el-input placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckPrice" v-model="daiKanForm.buyerPriceTo" style="width: 275px; margin-left: -4px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" style="text-align: right">
                            <span v-if="state == '0'">元</span>
                            <span v-if="state == '1'">万元</span>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="带看总结" label-width="15%" prop="daiKanZongJie">
                    <el-input v-model="daiKanForm.daiKanZongJie" style="width: 95%" :rows="5" type="textarea" placeholder="请输入带看总结" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="dialog-footer">
                    <el-button type="warning" size="mini" @click="colseDaiKanDialog">取消</el-button>
                    <el-button type="primary" size="mini" :disabled="daiKanDisabled" @click="saveDaiKanyes">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <div v-if="isGenJinList != '3'" class="div-tabs">
            <div class="tabs-title">维护记录</div>
            <div v-if="isUpdate" class="hetong-xinxi" style="
          display: block;
          margin-top: 8px;
          position: absolute;
          z-index: 111;
          right: 0;
          cursor: pointer;
        ">
                <span v-if="jiLuName == 'genJinJiLu'" class="xinzengXuQiu" @click="deleteGenJin('', 'duoGe')">删除</span>
                <span v-if="jiLuName == 'genJinJiLu'" class="xinzengXuQiu" @click="updateGenJin('', 'add', '新增跟进记录')">新增</span>

                <span v-if="jiLuName == 'daiKanJiLu'" class="xinzengXuQiu" @click="deleteDaiKan('', 'duoGe')">删除</span>
                <span v-if="jiLuName == 'daiKanJiLu'" class="xinzengXuQiu" @click="updateDaiKan('', 'add', '新增带看记录')">新增</span>
            </div>
            <el-tabs @tab-click="handleClick" v-model="jiLuName" class="qiugou-tabs" style="position: relative; top: 0px; background-color: #fff">
                <el-tab-pane label="跟进记录" name="genJinJiLu">
                    <el-table ref="multipleTable" @selection-change="genJinJiLuSelection" :data="genJinList" tooltip-effect="dark" style="margin: 20px; width: auto" :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px',
            }" :cell-style="{ 'text-align': 'center' }" border stripe>
                        <el-table-column type="selection" width="40"></el-table-column>
                        <el-table-column label="创建人" prop="genJinRenName"></el-table-column>
                        <el-table-column label="跟进信息" prop="genJinInfo"></el-table-column>
                        <el-table-column label="跟进日期" prop="genJinTime"></el-table-column>
                        <el-table-column label="操作" width="165px;" v-if="isUpdate">
                            <template slot-scope="scope" class="right">
                                <el-button @click.native.stop="
                    updateGenJin(scope.row, 'update', '修改跟进记录')
                  " type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                                <el-button @click.native.stop="deleteGenJin(scope.row, 'oneGe')" type="text" size="mini" class="el-icon-delete caozuo" style="color: #da0909">删除</el-button>
                            </template>
                        </el-table-column>
                        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                            <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                        </div>
                    </el-table>
                    <!-- 分页区域 -->
                    <el-row>
                        <el-pagination style="width: 70%; float: right; margin: 0px 15px 15px" @size-change="genJinSizeChange" @current-change="genJinCurrentChange" :current-page="genJinCurrentPage" :page-sizes="[5, 10, 20]" :page-size="genJinPageSize" layout="total, sizes, prev, pager, next, jumper" :total="genJinListTotal"></el-pagination>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="带看记录" name="daiKanJiLu">
                    <el-card style="margin: 0 !important; border: none">
                        <div>
                            <div style="width: 100%; margin-top: 18px">
                                <span class="title">带看人 </span>
                                <el-select v-model="daikanR" placeholder="请选择带看人" style="margin: 0 5px; width: 20%">
                                    <el-option v-for="item in daiKanManList" :key="item.createPerson" :label="item.userName" :value="item.createPerson"></el-option>
                                </el-select>
                                <span class="title">带看房源 </span>
                                <el-button size="mini" class="buttonInput button_tanchu" @click="openFangyuanDialog('searchDaiKan')" style="
                    margin: 0 5px;
                    width: 20%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  ">
                                    <span v-if="louPanName == '请选择带看房源'" style="color: #c0c4cc">{{ louPanName }}</span>
                                    <span v-if="louPanName != '请选择带看房源'">{{
                    louPanName
                  }}</span>
                                </el-button>
                                <span style="float: right">
                                    <el-button size="mini" type="primary" @click="searchDaikanJiLu">搜索</el-button>
                                    <el-button size="mini" type="warning" @click="resetDaikanJiLu">重置</el-button>
                                </span>
                            </div>

                            <el-table ref="multipleTable" @selection-change="daiKanSelection" :data="daiKanList" tooltip-effect="dark" style="width: auto" :header-cell-style="{
                  'text-align': 'center',
                  color: '#333',
                  'font-size': '14px',
                }" :cell-style="{ 'text-align': 'center' }" border stripe>
                                <el-table-column type="selection" width="40"></el-table-column>
                                <el-table-column label="带看房源" prop="louPanName">
                                    <template slot-scope="scope">
                                        <span>
                                            {{ scope.row.louPanName }}
                                        </span>
                                        <br />
                                        <span style="color: red">
                                            {{ scope.row.houseNo }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="带看人" prop="createPersonName"></el-table-column>
                                <el-table-column label="面积(平方米)" prop="square"></el-table-column>
                                <el-table-column label="户型" prop="huXingId"></el-table-column>
                                <el-table-column label="意向价格">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.daiKanType.indexOf('买')">{{ scope.row.buyerPriceFrom }}元 -
                                            {{ scope.row.buyerPriceTo }}元</span>
                                        <span v-if="!scope.row.daiKanType.indexOf('买')">{{ scope.row.buyerPriceFrom / 10000 }}万元 -
                                            {{ scope.row.buyerPriceTo / 10000 }}万元</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="房源总价">
                                    <template slot-scope="scope">
                                        <span v-if="!scope.row.daiKanType.indexOf('买')">{{ towNumber(scope.row.sellerPrice / 10000) }}万元</span>
                                        <span v-if="scope.row.daiKanType.indexOf('买')">{{ scope.row.sellerPrice }}元 / 月</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="带看类型" prop="daiKanType"></el-table-column>
                                <el-table-column label="带看时间" prop="daiKanTime"></el-table-column>
                                <el-table-column label="带看总结" prop="daiKanZongJie"></el-table-column>
                                <el-table-column label="操作" width="165px;" v-if="isUpdate">
                                    <template slot-scope="scope" class="right">
                                        <el-button @click.native.stop="
                        updateDaiKan(scope.row, 'update', '修改带看记录')
                      " type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                                        <el-button @click.native.stop="deleteDaiKan(scope.row, 'oneGe')" type="text" size="mini" class="el-icon-delete caozuo" style="color: #da0909">删除</el-button>
                                    </template>
                                </el-table-column>
                                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                                </div>
                            </el-table>
                            <el-row>
                                <el-pagination style="width: 70%; float: right; margin: 15px 15px" @size-change="daiKanSizeChange" @current-change="daiKanCurrentChange" :current-page="daiKanCurrentPage" :page-sizes="[5, 10, 20]" :page-size="daiKanPageSize" layout="total, sizes, prev, pager, next, jumper" :total="daiKanListTotal"></el-pagination>
                            </el-row>
                        </div>
                    </el-card>
                </el-tab-pane>
                <el-tab-pane label="操作记录" name="caoZuoJiLu">
                    <el-card style="margin: 0 !important; border: none">
                        <el-table ref="multipleTable" @selection-change="caozuojiluSelection" :data="caozuoList" tooltip-effect="dark" style="margin: 20px; width: auto" :header-cell-style="{
                'text-align': 'center',
                color: '#333',
                'font-size': '14px',
              }" :cell-style="{ 'text-align': 'center' }" border stripe>
                            <el-table-column label="操作人" prop="userNameA"></el-table-column>
                            <el-table-column label="经纪人" prop="userNameB"></el-table-column>
                            <el-table-column label="相关客源" prop="customerName"></el-table-column>
                            <el-table-column label="相关房源" prop="houseNo">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.louPanName }}{{ scope.row.houseNo }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作类型" prop="activityType"></el-table-column>
                            <el-table-column label="操作时间" prop="createTime"></el-table-column>
                            <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                                <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                                <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                            </div>
                        </el-table>
                        <!-- 分页区域 -->
                        <el-row>
                            <el-pagination style="width: 70%; float: right; margin: 0px 15px 15px" @size-change="caozuoSizeChange" @current-change="caozuoCurrentChange" :current-page="caozuoCurrentPage" :page-sizes="[5, 10, 20]" :page-size="caozuoPageSize" layout="total, sizes, prev, pager, next, jumper" :total="caozuoListTotal"></el-pagination>
                        </el-row>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import KeYuanJs from "./KeYuan.js"
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue"
import KeYuanChuShouList from "../../components/KeYuan/KeYuanChuShouList.vue"
export default {
    inject: ["reload"],
    components: {
        TongXunLuList,
        KeYuanChuShouList
    },
    data() {
        var inputPrice2 = (rule, value, callback) => {
            let that = this
            if (typeof that.daiKanForm.buyerPriceFrom == "string") {
                that.daiKanForm.buyerPriceFrom = Number(
                    that.daiKanForm.buyerPriceFrom
                )
            }
            if (typeof that.daiKanForm.buyerPriceTo == "string") {
                that.daiKanForm.buyerPriceTo = Number(
                    that.daiKanForm.buyerPriceTo
                )
            }
            if (that.daiKanForm.buyerPriceFrom > that.daiKanForm.buyerPriceTo) {
                callback(new Error("最大金额需大于最小金额!"))
                let temp = ""
                temp = this.daiKanForm.buyerPriceFrom
                this.daiKanForm.buyerPriceFrom = this.daiKanForm.buyerPriceTo
                this.daiKanForm.buyerPriceTo = temp
            } else if (that.daiKanForm.buyerPriceTo == 0) {
                callback(new Error("请输入金额!"))
            } else {
                callback()
            }
        }
        return {
            dkhouseid: [],
            dkfylist: [],
            roleType: window.localStorage.getItem("roleType"),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            jiLuName: "genJinJiLu",
            state: this.$route.query.state, //1求购 0是求租
            loading: true,
            genJinDialogTitle: "",
            genJinDialogVisible: false,
            genJinRequirementVisible: false,
            genJinRequirementChecked: "",
            checkedGenJinIdList: [], //选中的要删除的跟进记录
            genJinList: [], //跟进记录
            genJinPageSize: 10,
            genJinCurrentPage: 1,
            genJinListTotal: 0,
            caozuoList: [], //操作记录
            caozuoPageSize: 10, //操作记录
            caozuoCurrentPage: 1, //操作记录
            caozuoListTotal: 0,
            genJinType: "", //跟进记录类型
            genJinDisabled: false,
            genJinForm: {
                genJinId: "",
                genJinRequirementId: "",
                agentId: "",
                agentName: "请选择经纪人",
                genJinTime: "",
                genJinInfo: ""
            },
            genJinRules: {
                genJinTime: [
                    {
                        required: true,
                        message: "请选择跟进日期",
                        trigger: "change"
                    }
                ],
                agentId: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "change"
                    }
                ],
                genJinRequirementId: [
                    {
                        required: true,
                        message: "请选择跟进需求",
                        trigger: "change"
                    }
                ],
                genJinInfo: [
                    {
                        required: true,
                        message: "请输入跟进内容",
                        trigger: "blur"
                    }
                ]
            },
            daikanList: [],
            disabled: false,
            daikanR: "",
            daiKanLX: "",
            daiKanLXGouOptions: KeYuanJs.daiKanLXGouOptions,
            daiKanLXZuOptions: KeYuanJs.daiKanLXZuOptions,
            houseId: "",
            louPanName: "请选择带看房源",
            fangyuanDialog: false,
            daiKanDialogTitle: "",
            daiKanDialogVisible: false,
            daiKanDisabled: false,
            daikanFangyuanType: "",
            daiKanType: "",
            daiKanPageSize: 10,
            daiKanCurrentPage: 1,
            daiKanListTotal: 0,
            daiKanList: [],
            daiKanManList: [],
            checkedDaiKanIdList: [],
            daiKanForm: {
                daikanId: "",
                customerId: "",
                houseId: "",
                louPanName: "请选择带看房源",
                agentId: "",
                agentName: "请选择经纪人",
                daiKanType: "",
                daiKanTime: "",
                buyerPrice: "",
                buyerPriceFrom: 0,
                buyerPriceTo: 0,
                daiKanZongJie: ""
            },
            daiKanRules: {
                buyerPriceTo: [
                    {
                        required: true,
                        validator: inputPrice2,
                        trigger: "blur"
                    }
                ],
                agentId: [
                    {
                        required: true,
                        message: "请选择经纪人",
                        trigger: "change"
                    }
                ],
                houseId: [
                    {
                        required: true,
                        message: "请选择带看房源",
                        trigger: "change"
                    }
                ],
                daiKanType: [
                    {
                        required: true,
                        message: "请选择带看类型",
                        trigger: "change"
                    }
                ],
                daiKanTime: [
                    {
                        required: true,
                        message: "请选择带看时间",
                        trigger: "change"
                    }
                ],
                buyerPrice: [
                    {
                        required: true,
                        message: "请输入买方价格",
                        trigger: "blur"
                    }
                ],
                daiKanZongJie: [
                    {
                        required: true,
                        message: "请输入带看总结",
                        trigger: "blur"
                    }
                ]
            },
            checkedZhuangxiu: [],
            hezuorenDialog: false,
            ren: "",
            disabled: false,
            isDaiKanData: ""
        }
    },
    props: {
        customerId: {
            //客源id
            type: String,
            default: ""
        },
        xuqiuList: {
            type: Array,
            default: []
        },
        ruleForm: {
            type: Array,
            default: []
        },
        ruleFormParam: {
            type: Array,
            default: []
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        isRole: {
            type: Boolean,
            default: false
        },
        isGenJin: {
            type: Boolean,
            default: false
        },
        isGenJinList: {
            type: String,
            default: ""
        }
    },
    created() {
        if (this.customerId != "") {
            this.getGenJinList()
            this.getDaiKanList()
            this.getDaiKanMan()
        }
    },
    watch: {
        isGenJin(val) {
            if (val && this.isGenJinList != "1") {
                this.updateGenJin("", "add", "新增跟进记录")
            }
            if (val && this.isGenJinList == "1") {
                this.updateDaiKan("", "add", "新增带看记录")
            }
        },
        genJinRequirementChecked() {
            this.genJinForm.genJinRequirementId =
                this.genJinRequirementChecked.id
            this.genJinRequirementVisible = false
        }
    },
    methods: {
        // 保留两位小数
        towNumber(val) {
            return val.toFixed(2)
        },
        CheckPrice() {
            if (this.daiKanForm.buyerPriceFrom > this.daiKanForm.buyerPriceTo) {
                this.$message.error("最小价格不能大于最大价格!")
                let temp = ""
                temp = this.daiKanForm.buyerPriceFrom
                this.daiKanForm.buyerPriceFrom = this.daiKanForm.buyerPriceTo
                this.daiKanForm.buyerPriceTo = temp
            }
        },
        //操作分页
        caozuoSizeChange(newSize) {
            this.caozuoPageSize = newSize
            this.GetActivityRecordList()
        },
        caozuoCurrentChange(newPage) {
            this.caozuoCurrentPage = newPage
            this.GetActivityRecordList()
        },
        //按钮跳转
        handleClick(tab, event) {
            if (tab.label == "操作记录") {
                this.GetActivityRecordList()
            }
            if (tab.label == "带看记录") {
                this.$emit("DaiKan", "1")
            }
            if (tab.label == "跟进记录") {
                this.$emit("DaiKan", "0")
            }
        },
        caozuojiluSelection(val) {
            this.checkedGenJinIdList = []
            if (val.length > 0) {
                val.forEach((item) => {
                    this.checkedGenJinIdList.push(item.genJinId)
                })
            }
        },
        //获取操作记录
        GetActivityRecordList() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetActivityRecordList, {
                    params: {
                        customerId: that.customerId,
                        currentPage: that.caozuoCurrentPage,
                        pageSize: that.caozuoPageSize
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            res.data.dtList.forEach((item) => {})
                            that.caozuoList = res.data.dtList
                        } else {
                            that.caozuoList = []
                        }
                        that.caozuoListTotal = Number(res.data.maxRecords)
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        //经纪人弹层
        openjingjirenDialog(type) {
            if (type == "1") {
                this.ren = "1"
            } else if (type == "2") {
                this.ren = "2"
            }
            this.hezuorenDialog = true
        },
        //选中后关闭经纪人弹层
        colseJingJiRenDialog(jingJiRenData) {
            if (this.ren == 1) {
                this.genJinForm.agentName = jingJiRenData.userName
                this.genJinForm.agentId = jingJiRenData.userId
            } else if (this.ren == 2) {
                this.daiKanForm.agentName = jingJiRenData.userName
                this.daiKanForm.agentId = jingJiRenData.userId
            }
            this.hezuorenDialog = false
        },
        /* 获取列表 */
        getGenJinList() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetGenJinList, {
                    params: {
                        isCustomerGenJin: "1",
                        customerId: that.customerId,
                        isHomePage: "0",
                        currentPage: that.genJinCurrentPage,
                        pageSize: that.genJinPageSize
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            res.data.dtList.forEach((item) => {})
                            that.genJinList = res.data.dtList
                        } else {
                            that.genJinList = []
                        }
                        that.genJinListTotal = res.data.maxRecords
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        genJinSizeChange(newSize) {
            this.genJinPageSize = newSize
            this.getGenJinList()
        },
        genJinCurrentChange(newPage) {
            this.genJinCurrentPage = newPage
            this.getGenJinList()
        },
        updateGenJin(item, type, title) {
            let that = this
            if (that.xuqiuList.length > 0) {
                that.genJinDialogTitle = title
                that.genJinType = type
                that.genJinDialogVisible = true
                if (item != "") {
                    this.disabled = true
                    for (let i = 0; that.xuqiuList.length; i++) {
                        if (
                            item.requirementId ==
                                that.xuqiuList[i].requirementToBuyId ||
                            item.requirementId ==
                                that.xuqiuList[i].requirementToRentId
                        ) {
                            item.genJinRequirementId = item.requirementId
                            that.genJinRequirementChecked = that.xuqiuList[i]
                            that.genJinForm = Object.assign(
                                that.genJinForm,
                                item
                            )
                            that.genJinForm.agentName =
                                that.genJinForm.genJinRenName
                            that.genJinForm.agentId = item.userId

                            return false
                        }
                    }
                } else {
                    this.disabled = false
                    that.genJinRequirementChecked = ""
                    that.genJinForm.genJinId = ""
                    that.genJinForm.genJinRequirementI = ""
                    that.genJinForm.genJinTime = ""
                    that.genJinForm.genJinInfo = ""
                    that.genJinForm.agentName = "请选择经纪人"
                    that.genJinForm.agentId = ""
                }
            } else {
                that.$message.warning("请添加客源需求后，再新增跟进记录！")
            }
        },
        GenJinRequirementClose() {
            this.genJinRequirementVisible = false
        },
        saveGenJin() {
            let that = this,
                url = "",
                method = ""
            that.$refs.GenJinRuleForm.validate(async (valid) => {
                if (valid) {
                    this.$emit("GenJin", false, "3")
                    that.genJinDisabled = true
                    const data = {
                        IsCustomerGenJin: "1",
                        customerId: that.customerId,
                        genJinTime: that.$commonJs.dateTimeFormatter(
                            that.genJinForm.genJinTime
                        ),
                        genJinInfo: that.genJinForm.genJinInfo,
                        userId: that.genJinForm.agentId
                    }
                    if (this.genJinType == "add") {
                        url = that.$url.InsertGenJin
                        data.requirementId = that.genJinForm.genJinRequirementId
                        method = "post"
                    } else {
                        url = that.$url.UpdateGenJin
                        data.genJinId = that.genJinForm.genJinId
                        method = "put"
                    }
                    that.$http[method](url, data, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    }).then((res) => {
                        that.genJinDisabled = false
                        if (res.data.msgCode == 1) {
                            that.genJinDialogVisible = false
                            if (that.genJinType == "add") {
                                that.$message.success("跟进信息新增成功！")
                            } else {
                                that.$message.success("跟进信息修改成功！")
                            }
                            that.getGenJinList()
                        } else {
                            that.$message.error(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        cancelGenJin() {
            this.$emit("GenJin", false, "3")
            this.genJinDialogVisible = false
            this.daiKanDialogVisible = false
            this.$refs.GenJinRuleForm.clearValidate()
        },
        genJinBeforeClose() {
            this.$emit("GenJin", false, "3")
            this.genJinDialogVisible = false
        },
        genJinJiLuSelection(val) {
            this.checkedGenJinIdList = []
            if (val.length > 0) {
                val.forEach((item) => {
                    this.checkedGenJinIdList.push(item.genJinId)
                })
            }
        },
        deleteGenJin(item, type) {
            let that = this,
                genJinId = []
            if (type == "oneGe") {
                genJinId.push(item.genJinId)
            } else {
                if (that.checkedGenJinIdList == 0) {
                    that.$message.warning("请选择要删除的跟进记录！")
                    return false
                } else {
                    genJinId = that.checkedGenJinIdList
                }
            }
            this.$confirm("确认要删除吗？")
                .then((_) => {
                    that.loading = true
                    this.$http
                        .delete(this.$url.DeleteGenJin, {
                            data: genJinId,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            that.loading = false
                            if (res.data.msgCode == 1) {
                                that.$message.success("跟进记录删除成功！")
                                that.getGenJinList()
                            } else {
                                that.$message.error(res.data.message)
                            }
                        })
                    done()
                })
                .catch((_) => {})
        },
        openFangyuanDialog(type) {
            this.daikanFangyuanType = type
            this.fangyuanDialog = true
            this.dkfylist = []
        },
        colseFangyuanDialog(fangyuanData, type) {
            var that = this
            if (type == 2) {
                if (this.daikanFangyuanType == "searchDaiKan") {
                    fangyuanData.forEach(function (i) {
                        that.houseId = i.houseId
                        that.louPanName = i.louPanName
                    })
                } else if (this.daikanFangyuanType == "addDaiKan") {
                    var dkhouseid = []
                    var dkname = []
                    var dkHoueNo = []
                    fangyuanData.forEach(function (i) {
                        dkhouseid.push(i.houseId)
                        dkname.push(
                            "\xa0\xa0\xa0" +
                                i.louPanName +
                                "\xa0\xa0\xa0" +
                                i.houseNo
                        )
                    })
                    that.dkhouseid = dkhouseid
                    that.daiKanForm.louPanName = dkname.toString()
                }
            }
            this.dkfylist = fangyuanData
            this.fangyuanDialog = false
        },
        /* 获取带看人列表 */
        getDaiKanMan() {
            let that = this
            that.loading = true
            that.$http
                .get(that.$url.GetDaiKanMan, {
                    params: {
                        customerId: that.customerId
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            that.daiKanManList = res.data.dtList
                        } else {
                            that.daiKanManList = []
                        }
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        /* 获取日志列表 */
        getDaiKanList() {
            let that = this
            that.daiKanList = []
            that.loading = true
            const params = {
                IsHouseToSell: that.state,
                customerId: that.customerId,
                userId: that.daikanR,
                houseId: that.houseId,
                currentPage: that.daiKanCurrentPage,
                pageSize: that.daiKanPageSize
            }
            that.$http
                .get(that.$url.GetDaiKanList, {
                    params: params
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            res.data.dtList.forEach((item) => {
                                if (that.state == "1") {
                                    item.buyerPrice = item.buyerPrice / 10000
                                }
                            })
                            that.daiKanList = res.data.dtList
                        } else {
                            that.daiKanList = []
                        }
                        that.daiKanListTotal = res.data.maxRecords
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        searchDaikanJiLu() {
            this.getDaiKanList()
        },
        resetDaikanJiLu() {
            this.houseId = ""
            this.louPanName = "请选择带看房源"
            this.daikanR = ""
            this.getDaiKanList()
        },
        daiKanSizeChange(newSize) {
            this.daiKanPageSize = newSize
            this.getDaiKanList()
        },
        daiKanCurrentChange(newPage) {
            this.daiKanCurrentPage = newPage
            this.getDaiKanList()
        },
        daiKanSelection(val) {
            if (val.length > 0) {
                val.forEach((item) => {
                    this.checkedDaiKanIdList.push(item.daikanId)
                })
            } else {
                this.checkedDaiKanIdList = []
            }
        },
        updateDaiKan(item, type, title) {
            this.daiKanDialogVisible = true
            this.daiKanDialogTitle = title
            this.daiKanType = type
            if (item != "") {
                this.daiKanForm = Object.assign(this.daiKanForm, item)
                if (this.state == "0") {
                    this.daiKanForm.buyerPriceFrom =
                        this.daiKanForm.buyerPriceFrom
                    this.daiKanForm.buyerPriceTo = this.daiKanForm.buyerPriceTo
                } else {
                    this.daiKanForm.buyerPriceFrom =
                        this.daiKanForm.buyerPriceFrom / 10000
                    this.daiKanForm.buyerPriceTo =
                        this.daiKanForm.buyerPriceTo / 10000
                }
            } else {
                this.daiKanForm.daikanId = ""
                this.daiKanForm.houseId = ""
                this.daiKanForm.louPanName = "请选择带看房源"
                this.daiKanForm.daiKanType = ""
                this.daiKanForm.daiKanTime = ""
                this.daiKanForm.buyerPrice = ""
                this.daiKanForm.buyerPriceFrom = this.ruleForm[0].priceFrom
                this.daiKanForm.buyerPriceTo = this.ruleForm[0].priceTo
                this.daiKanForm.daiKanZongJie = ""
            }
        },
        colseDaiKanDialog() {
            this.$emit("GenJin", false, "3")
            this.dkhouseid = []
            this.daiKanForm.daikanId = ""
            this.daiKanForm.houseId = ""
            this.daiKanForm.louPanName = "请选择带看房源"
            this.daiKanForm.daiKanType = ""
            this.daiKanForm.daiKanTime = ""
            this.daiKanForm.buyerPrice = ""
            this.daiKanForm.daiKanZongJie = ""
            this.daiKanDialogVisible = false
            this.$refs.daiKanRuleForm.clearValidate()
        },
        saveDaiKanyes() {
            var that = this
            if (that.dkhouseid.length == 0) {
                that.saveDaiKan()
            } else if (that.dkhouseid.length == 1) {
                that.daiKanForm.houseId = that.dkhouseid[0]
                that.saveDaiKan()
            } else {
                that.dkhouseid.forEach(function (i) {
                    that.daiKanForm.houseId = i.toString()
                    that.saveDaiKan()
                })
            }
        },
        saveDaiKan() {
            let that = this,
                url = "",
                method = ""
            that.$refs.daiKanRuleForm.validate(async (valid) => {
                if (valid) {
                    if (that.state == "1") {
                        that.daiKanForm.buyerPriceFrom =
                            that.daiKanForm.buyerPriceFrom * 10000
                        that.daiKanForm.buyerPriceTo =
                            that.daiKanForm.buyerPriceTo * 10000
                    } else {
                        that.daiKanForm.buyerPriceFrom =
                            that.daiKanForm.buyerPriceFrom
                        that.daiKanForm.buyerPriceTo =
                            that.daiKanForm.buyerPriceTo
                    }
                    this.$emit("GenJin", false, "3")
                    that.daiKanDisabled = true
                    if (typeof that.daiKanForm.buyerPriceFrom == "string") {
                        that.daiKanForm.buyerPriceFrom = Number(
                            that.daiKanForm.buyerPriceFrom
                        )
                    }
                    if (typeof that.daiKanForm.buyerPriceTo == "string") {
                        that.daiKanForm.buyerPriceTo = Number(
                            that.daiKanForm.buyerPriceTo
                        )
                    }
                    const data = {
                        userId: that.daiKanForm.agentId,
                        customerId: that.customerId,
                        houseId: that.daiKanForm.houseId,
                        daiKanTime: that.$commonJs.dateTimeFormatter(
                            that.daiKanForm.daiKanTime
                        ),
                        daiKanType: that.daiKanForm.daiKanType,
                        buyerPrice: that.daiKanForm.buyerPrice,
                        BuyerPriceFrom: that.daiKanForm.buyerPriceFrom,
                        BuyerPriceTo: that.daiKanForm.buyerPriceTo,
                        daiKanZongJie: that.daiKanForm.daiKanZongJie
                    }
                    if (this.daiKanType == "add") {
                        url = that.$url.InsertDaiKan
                        method = "post"
                    } else {
                        url = that.$url.UpdateDaiKan
                        data.daikanId = that.daiKanForm.daikanId
                        method = "put"
                    }
                    that.$http[method](url, data, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    }).then((res) => {
                        if (res.data.msgCode == 1) {
                            if (that.daiKanType == "add") {
                                that.$message.success("带看记录新增成功！")
                            } else {
                                that.$message.success("带看记录修改成功！")
                            }
                            that.daiKanDialogVisible = false
                            that.getDaiKanList()
                        } else {
                            that.daiKanDisabled = false
                            that.$message.error(res.data.message)
                        }
                        that.daiKanDisabled = false
                    })
                } else {
                    that.daiKanDisabled = false
                    return false
                }
            })
        },
        deleteDaiKan(item, type) {
            let that = this,
                genJinId = []
            if (type == "oneGe") {
                genJinId.push(item.daikanId)
            } else {
                if (that.checkedDaiKanIdList == 0) {
                    that.$message.warning("请选择要删除的带看记录！")
                    return false
                } else {
                    genJinId = that.checkedDaiKanIdList
                }
            }
            this.$confirm("确认要删除吗？")
                .then((_) => {
                    that.loading = true
                    this.$http
                        .delete(this.$url.DeleteDaiKan, {
                            data: genJinId,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            that.loading = false
                            if (res.data.msgCode == 1) {
                                that.$message.success("带看记录删除成功！")
                                that.getDaiKanList()
                            } else {
                                that.$message.error(res.data.message)
                            }
                        })
                    done()
                })
                .catch((_) => {})
        },
        cancel() {
            this.$router.push({
                name: "KeYuanList",
                query: {
                    state: this.state
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
.qiugou-tabs {
    /deep/ .el-step.is-vertical .el-step__line {
        height: 20px;
        top: 23px;
        left: 6px;
        width: 1px;
    }
    /deep/ .el-step.is-vertical .el-step__head {
        width: 13px;
        display: none !important;
    }
    /deep/ .el-step__icon {
        background: #df0202;
        width: 13px;
        height: 13px;
        border: 2px solid;
        border-color: #e6baa8;
    }
    /deep/ .el-step__icon-inner {
        display: none;
    }
    /deep/ .el-step.is-vertical .el-step__title {
        padding-bottom: 0px;
    }
    /deep/ .el-step__title.is-wait,
    /deep/ .el-step__description {
        color: #000;
        font-size: 14px;
    }
}
.xinzengXuQiu {
    color: #df0202 !important;
    padding-right: 20px;
    border-radius: 3px;
    float: right;
    font-weight: 600;
    margin-top: 3px;
    font-size: 14px;
}
.div-tabs {
    background-color: #fff;
    margin-top: 15px;
}
.tabs-title {
    padding: 10px 20px;
    font-weight: 600;
}

.xuqiuDialog /deep/ .el-dialog__body {
    padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
    right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
    padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
    margin: 2px 0 2px 3px !important;
}
/deep/.xuqiuDialog .el-tabs {
    margin-top: -5px !important;
    .el-tabs__header {
        border-bottom: none !important;
        margin: 0px;
        .el-tabs__nav {
            color: #000 !important;
            border: none !important;
        }
        .el-tabs__nav > .is-active {
            color: rgb(238, 103, 22) !important;
            background: transparent !important;
        }
        .el-tabs__item {
            border-left: none !important;
        }
    }
    .el-tabs__content {
        margin-left: 14px !important;
    }
}
</style>