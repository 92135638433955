<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/LouPanZiDian1List' }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="30%">
                <div class="title-header">
                    小区信息
                    <span style="float: right" v-if="num1 != 0">楼盘编号：{{ louPanNo }}</span>
                </div>

                <el-row>
                    <el-col :span="24" v-if="num1 != 1">
                        <el-form-item label="所属商圈:" label-width="7.5%">
                            <span class="redStar" style="margin-left: -7.3%">*</span>
                            <el-tabs v-model="list" style="margin-left: 0.5%; margin-top: -29px" type="card" @tab-click="handleClick">
                                <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :name="item.activeName" :label="item.name">
                                    <el-checkbox-group :max="3" style="font-size: 14px; float: left; margin: 5px" v-model="checkedAreaType">
                                        <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{ item1.name }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-tab-pane>
                            </el-tabs>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24" v-if="num1 == 1">
                        <el-form-item label="所属商圈:" label-width="7.5%">{{
              form.areaNames
            }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="楼盘名称:" prop="louPanName">
                            <el-input :disabled="flag" v-model="form.louPanName" type="input" style="width: 80%" :rows="1" placeholder="请输入楼盘名称" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="项目地址:" label-width="30%" prop="louPanAddress">
                            <el-input :disabled="flag" v-model="form.louPanAddress" type="input" style="width: 80%" :rows="1" placeholder="请输入项目地址" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="开发单位:" label-width="30%" prop="kaiFaDanWei">
                            <el-input :disabled="flag" v-model="form.kaiFaDanWei" type="input" style="width: 80%" :rows="1" placeholder="请输入开发单位" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="权重:" label-width="30%" prop="weight">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.weight" maxlength="3" type="input" style="width: 80%" :rows="1" placeholder="请输入权重" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="预售许可证:" prop="yuShouXuKeZheng">
                            <el-select :disabled="flag" v-model="form.yuShouXuKeZheng" placeholder="请选择有无预售许可证" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="土地使用证:" label-width="30%" prop="guoYouTuDiShiYongZheng">
                            <el-select :disabled="flag" v-model="form.guoYouTuDiShiYongZheng" placeholder="请选择有无国有土地使用证" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="规划许可证:" label-width="30%" prop="gongChengGuiHuaXuKeZheng">
                            <el-select :disabled="flag" v-model="form.gongChengGuiHuaXuKeZheng" placeholder="请选择有无工程规划许可证" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="总套数:" prop="zongTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.zongTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入总套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="总面积:" prop="zongMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.zongMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入总面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="可售总套数:" prop="keShouZongTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.keShouZongTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入可售总套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="可售总面积:" prop="keShouZongMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.keShouZongMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入可售总面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="住宅套数:" label-width="30%" prop="zhuZhaiTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.zhuZhaiTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入住宅套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label-width="30%" label="住宅面积:" prop="zhuZhaiMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.zhuZhaiMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入住宅面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="可售住宅套数:" label-width="30%" prop="keShouZhuZhaiTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.keShouZhuZhaiTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入可售住宅套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label-width="30%" label="可售住宅面积:" prop="keShouZhuZhaiMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.keShouZhuZhaiMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入可售住宅面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="非住宅套数:" label-width="30%" prop="feiZhuZhaiTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.feiZhuZhaiTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入非宅套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label-width="30%" label="非住宅面积:" prop="feiZhuZhaiMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.feiZhuZhaiMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入非宅面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="可售非住宅套数:" label-width="30%" prop="keShouFeiZhuZhaiTaoShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.keShouFeiZhuZhaiTaoShu" type="input" style="width: 80%" :rows="1" placeholder="请输入可售非住宅套数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label-width="30%" label="可售非住宅面积:" prop="keShouFeiZhuZhaiMianJi">
                            <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" :disabled="flag" v-model="form.keShouFeiZhuZhaiMianJi" type="input" style="width: 80%" :rows="1" placeholder="请输入可售非住宅面积" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">m²</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="title-header">建筑信息</div>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="交易权属:" label-width="30%" prop="jiaoYiQuanShu">
                            <el-select :disabled="flag" v-model="form.jiaoYiQuanShu" placeholder="请选择交易权属" style="width: 80%">
                                <el-option v-for="item in options_jiaoYiQuanShu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="建筑结构:" label-width="30%" prop="jianZhuJieGou">
                            <el-select :disabled="flag" v-model="form.jianZhuJieGou" placeholder="请选择建筑结构" style="width: 80%">
                                <el-option v-for="item in options_jianZhuJieGou" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="产权年限:" label-width="30%" prop="chanQuanNianXian">
                            <el-select :disabled="flag" v-model="form.chanQuanNianXian" placeholder="请选择产权年限" style="width: 80%">
                                <el-option v-for="item in options_chanQuanNianXian" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="建筑年代:" label-width="30%" prop="jianZhuNianDai">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.jianZhuNianDai" type="input" style="width: 80%" :rows="1" placeholder="请输入建筑年代"></el-input>
                            <span style="margin-left: 7px">年</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="房屋用途:" label-width="30%" prop="fangWuYongTu">
                            <el-select :disabled="flag" v-model="form.fangWuYongTu" placeholder="请选择房屋用途" style="width: 80%">
                                <el-option v-for="item in options_fangWuYongTu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="梯户比例:" label-width="30%" prop="tiHuBiLi">
                            <el-input :disabled="flag" v-model="form.tiHuBiLi" type="input" style="width: 80%" :rows="1" placeholder="请输入梯户比例" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="事件信息:" label-width="30%" prop="shiJianXinXi">
                            <el-input :disabled="flag" v-model="form.shiJianXinXi" type="input" style="width: 80%" :rows="1" placeholder="请输入事件信息" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="嫌恶设施:" label-width="30%" prop="xianWuSheShi">
                            <el-input :disabled="flag" v-model="form.xianWuSheShi" type="input" style="width: 80%" :rows="1" placeholder="请输入嫌恶设施" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="title-header">生活信息</div>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="供暖类型:" label-width="30%" prop="gongNuanType">
                            <el-select :disabled="flag" v-model="form.gongNuanType" placeholder="请选择供暖类型" style="width: 80%">
                                <el-option v-for="item in options_gongNuanType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="用电类型:" label-width="30%" prop="yongDianType">
                            <el-select :disabled="flag" v-model="form.yongDianType" placeholder="请选择用电类型" style="width: 80%">
                                <el-option v-for="item in options_yongDianType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="用水类型:" label-width="30%" prop="yongShuiType">
                            <el-select :disabled="flag" v-model="form.yongShuiType" placeholder="请选择用水类型" style="width: 80%">
                                <el-option v-for="item in options_yongShuiType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item style="margin: 0px 0 15px" label="物业费:" prop="wuYeFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.wuYeFei" type="input" style="width: 80%" :rows="1" placeholder="请输入物业费" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/m²</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="车位比例:" label-width="30%" prop="cheWeiBiLi">
                            <el-input :disabled="flag" v-model="form.cheWeiBiLi" type="input" style="width: 80%" :rows="1" placeholder="请输入车位比例" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="地上车位数:" label-width="30%" prop="diShangCheWeiShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.diShangCheWeiShu" type="input" style="width: 80%" :rows="1" placeholder="请输入地上车位数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="地下车位数:" label-width="30%" prop="diXiaCheWeiShu">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.diXiaCheWeiShu" type="input" style="width: 80%" :rows="1" placeholder="请输入地下车位数" @input="changeInput($event)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="停车服务费:" label-width="30%" prop="tingCheFuWuFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.tingCheFuWuFei" type="input" style="width: 80%" :rows="1" placeholder="请输入停车服务费" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/月</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="有无电梯:" label-width="30%" prop="dianTi">
                            <el-select :disabled="flag" v-model="form.dianTi" placeholder="请选择是否有电梯" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="有无燃气:" label-width="30%" prop="ranQi">
                            <el-select :disabled="flag" v-model="form.ranQi" placeholder="请选择是否有燃气" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="有无热水:" label-width="30%" prop="reShui">
                            <el-select :disabled="flag" v-model="form.reShui" placeholder="请选择是否有热水" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="有无中水:" label-width="30%" prop="zhongShui">
                            <el-select :disabled="flag" v-model="form.zhongShui" placeholder="请选择是否有中水" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="供暖费:" label-width="30%" prop="gongNuanFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.gongNuanFei" type="input" style="width: 80%" :rows="1" placeholder="请输入供暖费用" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/m²</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="燃气费:" label-width="30%" prop="ranQiFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.ranQiFei" type="input" style="width: 80%" :rows="1" placeholder="请输入燃气费" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/月</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="热水费:" label-width="30%" prop="reShuiFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.reShuiFei" type="input" style="width: 80%" :rows="1" placeholder="请输入热水费" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/吨</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="中水费:" label-width="30%" prop="zhongShuiFei">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="form.zhongShuiFei" type="input" style="width: 80%" :rows="1" placeholder="请输入中水费" @input="changeInput($event)"></el-input>
                            <span style="margin-left: 7px">元/吨</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="6">
                        <el-form-item label="有无幼儿园:" label-width="30%" prop="youErYuan">
                            <el-select :disabled="flag" v-model="form.youErYuan" placeholder="请选择是否有幼儿园" style="width: 80%">
                                <el-option v-for="item in options_youwu" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-form-item label="楼盘图片:" label-width="7.5%" prop="louPanPics">
                        <span class="redStar" style="margin-left: -87px">*</span>
                        <!-- 上传图片 -->
                        <el-upload style="margin-top: -22px" :disabled="flag" :action="UploadPics" list-type="picture-card" :file-list="picture" :on-preview="handlePictureCardPreview" :on-success="success" :headers="MyHeader" :limit="20" :on-exceed="handleExceed" :class="{ disabled: isMax }" :on-change="change" :on-error="error" :before-remove="handleBeforeRemove">
                            <i class="el-icon-plus"></i>
                        </el-upload>

                        <el-dialog :visible.sync="dialogVisible">
                            <el-image width="100%" :src="dialogImageUrl"> </el-image>
                        </el-dialog>

                        <div class="logtime">
                            <el-col :span="6">
                                <el-form-item v-if="this.num1 != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item v-if="this.num1 != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item v-if="this.num1 != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item v-if="this.num1 != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                            </el-col>
                        </div>
                    </el-form-item>
                </el-row>

                <el-row v-if="num1 != 0">
                    <el-col :span="6">
                        <el-form-item label="是否已审核:" label-width="30%" prop="">
                            <el-select :disabled="flag" v-model="isConfirmed" placeholder="请选择是否审核" style="width: 80%">
                                <el-option v-for="item in options_shifou" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否可用:" label-width="30%" prop="">
                            <el-select :disabled="flag" v-model="isPublic" placeholder="请选择是否可用" style="width: 80%">
                                <el-option v-for="item in options_shifou" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label-width="7.5%">
                    <el-button size="mini" @click="cancel" type="warning" v-if="num1 != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num1 == 0" @click="onSubmit('form')">新增楼盘</el-button>
                    <el-button size="mini" type="primary" v-if="num1 == 2" @click="onUpdate('form')">更新楼盘</el-button>
                </el-form-item>
            </el-form>
            <el-form>
                <div v-if="num1 != 0">
                    <div class="title-header">楼栋信息</div>
                    <el-row ref="dynamicItem" :inline="true" :model="dynamicItem" label-width="80px" v-for="(item, index) in dynamicItem" :key="index">
                        <el-col :span="4">
                            <el-form-item label="楼栋:" label-width="45%">
                                <el-input :disabled="flag" v-model="item.gongChengLouHaoFormatted" placeholder="例如：A[1-10]"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="单元数:" label-width="45%">
                                <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="item.danYuanCount" placeholder="例如：3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="地上层数:" label-width="45%">
                                <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="item.louCengCount" placeholder="例如：20"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="地下层数:" label-width="45%">
                                <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="item.diXiaLouCengCount" placeholder="例如：1"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="户数:" label-width="45%">
                                <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="flag" v-model="item.menPaiHaoCount" placeholder="例如：2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" v-if="num1 == 2">
                            <el-form-item>
                                <div>
                                    <el-button v-if="index == 0" @click="addItem" type="primary" style="height: 30px; line-height: 0">增加</el-button>
                                    <el-button v-if="index !== 0" @click="deleteItem(item, index)" type="danger" style="height: 30px; line-height: 0">删除</el-button>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button size="mini" type="primary" style="margin-left: 7.5%" @click="sure('form')" v-if="num1 == 2">楼栋批量新增</el-button>
                </div>
            </el-form>
        </el-card>

        <!-- 底部内容区域 -->
        <el-card v-if="num1 != 0">
            <el-row style="margin-top: 10px">
                <el-col v-if="num1 == 2">
                    <!-- <el-button size="mini" @click="louDongInsert()" type="primary" icon="el-icon-plus" style=" border: none">新增</el-button> -->
                    <!-- <el-button size="mini" 
                       @click="handleEdit"
                       type="primary"
                       icon="el-icon-edit"
                       style="background-color: rgb(14 153 219); border: none; margin-left: 10px">修改</el-button> -->
                    <!-- <el-button size="mini" @click="handleDelete1()" type="primary" icon="el-icon-delete" style="background-color:rgb(215 163 16); border: none">删除</el-button> -->
                </el-col>
            </el-row>
            <el-table ref="multipleTable" v-loading="loading" :data="louDongList" tooltip-effect="dark" :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px',
        }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                <el-table-column label="工程楼号" prop="louhao">
                    <template slot-scope="scope">
                        <el-button class="tableButSpan" @click="louDongDetail(scope.row)" type="text" size="mini" style="color: #1268c0; text-decoration: underline">{{ scope.row.gongChengLouHao }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="坐落" prop="louDongAddress"></el-table-column>
                <el-table-column label="创建人" prop="createPersonName"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作" v-if="num1 == 2">
                    <template slot-scope="scope" class="right">
                        <el-button @click.native.stop="louDongUpdate(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff">修改</el-button>
                        <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909">删除</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                </div>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>
<script>
//引入地图
// import mapSelect from "../mapSelect"
import commonJs from "../../assets/js/common.js" //共通js方法
export default {
    name: "index",
    inject: ["reload"],
    // components: {
    //     mapSelect
    // },
    watch: {
        // value(val) {
        //     this.content = this.value
        // }
    },
    data() {
        // var shuzi = (rule, value, callback) => {
        //     if (!commonJs.shuZi(value)) {
        //         callback(new Error("格式不符，只能输入数字!"))
        //         this.loading = false
        //     } else {
        //         callback()
        //     }
        // }
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            time: "", //看房时间
            addressSelect: "",
            num1: this.$route.query.num1,
            louPanId: this.$route.query.louPanId,
            louPanNo: this.$route.query.louPanNo,
            serverUrl: URL + "api/management/FileUpload/UploadPics",
            name: "",
            mm: "",
            logo: true,
            flag: false,
            flag_shangquan: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: [], //楼盘显示的图片
            isMax: false,
            MyHeader: { currentRole: "", accessToken: "", fp: "" },
            loading: false,
            loadingLouDong: false, //楼栋列表的loading
            diseaseIds: [],
            id: "",
            louPanInfo: [], //新增和更新传的json
            options_fangWuYongTu: [], //房屋用途下拉框数据
            options_jiaoYiQuanShu: [], //交易权属下拉框数据
            UploadPics: "", //图片上传接口
            AreaType: "", //用户类型
            firstPic: "", //新增和更新传的楼盘图片
            isConfirmed: "", //是否审核
            isPublic: "", //是否可用
            activeName: "first",
            // 表单
            form: {
                //小区信息
                areaNames: "", //所属商圈（详情查看）
                areaIds: "", //所属商圈
                louPanName: "", //楼盘名称
                louPanAddress: "", //项目地址
                kaiFaDanWei: "", //开发单位
                yuShouXuKeZheng: "", //预售许可证
                guoYouTuDiShiYongZheng: "", //国有土地使用证
                gongChengGuiHuaXuKeZheng: "", //工程规划许可证
                zongTaoShu: "", //总套数
                zongMianJi: "", //总面积
                keShouZongTaoShu: "", //可售总套数
                keShouZongMianJi: "", //可售总面积
                zhuZhaiTaoShu: "", //住宅套数
                zhuZhaiMianJi: "", //住宅面积
                keShouZhuZhaiTaoShu: "", //可售住宅套数
                keShouZhuZhaiMianJi: "", //可售住宅面积
                feiZhuZhaiTaoShu: "", //非住宅套数
                feiZhuZhaiMianJi: "", //非住宅面积
                keShouFeiZhuZhaiTaoShu: "", //可售非住宅套数
                keShouFeiZhuZhaiMianJi: "", //可售非住宅面积
                // 建筑信息
                jiaoYiQuanShu: "", //交易产权
                jianZhuJieGou: "", //建筑结构
                chanQuanNianXian: "", //产权年限
                jianZhuNianDai: "", //建筑年代
                fangWuYongTu: "", //房屋用途
                tiHuBiLi: "", //梯户比例
                shiJianXinXi: "", //事件信息
                xianWuSheShi: "", //嫌恶设施
                // 生活信息
                gongNuanType: "", //供暖类型
                yongDianType: "", //用电类型
                yongShuiType: "", //用水类型
                wuYeFei: "", //物业费
                cheWeiBiLi: "", //车位比例
                diShangCheWeiShu: "", //地上车位数
                diXiaCheWeiShu: "", //地下车位数
                tingCheFuWuFei: "", //停车服务费
                dianTi: "", //是否有电梯
                ranQi: "", //是否有燃气
                reShui: "", //是否有热水
                zhongShui: "", //是否有中水
                gongNuanFei: "", //供暖费用
                ranQiFei: "", //燃气费
                reShuiFei: "", //热水费
                zhongShuiFei: "", //中水费
                youErYuan: "", //幼儿园
                louPanPics: "", //楼盘图片
                weight: "" //权重
            },
            // 楼栋信息
            dynamicItem: [
                {
                    gongChengLouHaoFormatted: "",
                    danYuanCount: "",
                    louCengCount: "",
                    diXiaLouCengCount: "0",
                    menPaiHaoCount: ""
                }
            ],
            // 楼盘表单验证
            rules: {
                louPanName: [
                    {
                        required: true,
                        message: "请输入楼盘名称",
                        trigger: "blur"
                    }
                ],
                louPanAddress: [
                    {
                        required: true,
                        message: "请输入楼盘地址",
                        trigger: "blur"
                    }
                ],
                kaiFaDanWei: [
                    {
                        required: true,
                        message: "请输入开发单位",
                        trigger: "blur"
                    }
                ],
                weight: [
                    {
                        required: true,
                        message: "请输入权重",
                        trigger: "blur"
                    }
                ],
                jianZhuNianDai: [
                    {
                        required: true,
                        message: "请输入建筑年代",
                        trigger: "blur"
                    }
                ],
                yuShouXuKeZheng: [
                    {
                        required: true,
                        message: "请选择有无预售许可证",
                        trigger: "change"
                    }
                ],
                guoYouTuDiShiYongZheng: [
                    {
                        required: true,
                        message: "请选择有无国有土地使用证",
                        trigger: "change"
                    }
                ],
                gongChengGuiHuaXuKeZheng: [
                    {
                        required: true,
                        message: "请选择有无工程规划许可证",
                        trigger: "change"
                    }
                ],
                jiaoYiQuanShu: [
                    {
                        required: true,
                        message: "请选择交易权属",
                        trigger: "change"
                    }
                ],
                jianZhuJieGou: [
                    {
                        required: true,
                        message: "请选择建筑结构",
                        trigger: "change"
                    }
                ],
                chanQuanNianXian: [
                    {
                        required: true,
                        message: "请选择产权年限",
                        trigger: "change"
                    }
                ],
                fangWuYongTu: [
                    {
                        required: true,
                        message: "请选择房屋用途",
                        trigger: "change"
                    }
                ],
                gongNuanType: [
                    {
                        required: true,
                        message: "请选择供暖类型",
                        trigger: "change"
                    }
                ],
                yongDianType: [
                    {
                        required: true,
                        message: "请选择用电类型",
                        trigger: "change"
                    }
                ],
                yongShuiType: [
                    {
                        required: true,
                        message: "请选择用水类型",
                        trigger: "change"
                    }
                ],
                dianTi: [
                    {
                        required: true,
                        message: "请选择有无电梯",
                        trigger: "change"
                    }
                ],
                ranQi: [
                    {
                        required: true,
                        message: "请选择有无燃气",
                        trigger: "change"
                    }
                ],
                reShui: [
                    {
                        required: true,
                        message: "请选择有无热水",
                        trigger: "change"
                    }
                ],
                zhongShui: [
                    {
                        required: true,
                        message: "请选择有无中水",
                        trigger: "change"
                    }
                ],
                youErYuan: [
                    {
                        required: true,
                        message: "请选择有无幼儿园",
                        trigger: "change"
                    }
                ]
            },
            // 产权下拉框
            options_chanquan: [
                {
                    label: "40年",
                    value: "40年"
                },
                {
                    label: "50年",
                    value: "50年"
                },
                {
                    label: "70年",
                    value: "70年"
                }
            ],

            // 有无下拉框
            options_youwu: [
                {
                    label: "有",
                    value: 1
                },
                {
                    label: "无",
                    value: 0
                }
            ],
            // 是否下拉框
            options_shifou: [
                {
                    label: "是",
                    value: "1"
                },
                {
                    label: "否",
                    value: "0"
                }
            ],
            list: "",
            // 商圈
            regionList1: [
                {
                    name: "",
                    includeShangQuan: [{ name: "", areaIds: "" }]
                }
            ],
            checkedAreaType: [], //商圈选中
            ShangQuan: [],
            // 建筑结构
            options_jianZhuJieGou: [],
            // 建筑类型
            options_jianzhuleixing: [
                {
                    label: "民用建筑",
                    value: "民用建筑"
                },
                {
                    label: "工业建筑",
                    value: "工业建筑"
                }
            ],
            // 产权年限
            options_chanQuanNianXian: [
                {
                    label: "40年",
                    value: "40年"
                },
                {
                    label: "50年",
                    value: "50年"
                },
                {
                    label: "70年",
                    value: "70年"
                }
            ],
            // 供暖类型
            options_gongNuanType: [
                {
                    label: "集中供暖",
                    value: "集中供暖"
                },
                {
                    label: "地板辐射供暖",
                    value: "地板辐射供暖"
                },
                {
                    label: "燃气供暖",
                    value: "燃气供暖"
                }
            ],
            // 用电类型
            options_yongDianType: [
                {
                    label: "居民用电",
                    value: "居民用电"
                },
                {
                    label: "商业用电",
                    value: "商业用电"
                },
                {
                    label: "工业用电",
                    value: "工业用电"
                }
            ],
            // 用水类型
            options_yongShuiType: [
                {
                    label: "居民用水",
                    value: "居民用水"
                },
                {
                    label: "商业用水",
                    value: "商业用水"
                },
                {
                    label: "工业用水",
                    value: "工业用水"
                }
            ],

            // 详情下方楼栋列表
            currentPage: 1,
            pageSize: 10,
            total: 0,
            //楼栋列表
            louDongList: [
                {
                    gongChengLouHao: "",
                    createPersonName: "",
                    createTime: "",
                    louDongId: "",
                    louDongAddress: ""
                }
            ]
        }
    },
    created() {
        this.UploadPics = this.$url.UploadLoupan
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        // this.num = this.$route.path.split('/')[2]
        // this.petBreedId = this.$route.path.split('/')[3]
        // let num1 = this.$route.path.split('/')[3]
        this.getShuJuZiDianList()
    },
    methods: {
        // 输入框刷新
        // input() {
        //     this.$forceUpdate()
        // },
        changeInput(e){
            this.$forceUpdate(e)
        },
        // 楼盘字典详情
        async getloupanDetail() {
            var that = this
            await that.$http
                .get(that.$url.GetLouPanDetail, {
                    params: {
                        louPanId: that.louPanId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.form = JSON.parse(res.data.dtList[0].louPanInfo)
                        that.form.louPanName = res.data.dtList[0].louPanName //楼盘名称
                        that.form.louPanAddress =
                            res.data.dtList[0].louPanAddress //楼盘地址
                        that.form.kaiFaDanWei = res.data.dtList[0].kaiFaDanWei //开发单位
                        that.dialogImageUrl = res.data.dtList[0].louPanPics
                            .split(",")
                            .slice(0, -1)
                        console.log(that.dialogImageUrl == "", "22")
                        that.form.louPanPics = res.data.dtList[0].louPanPics //楼盘图片
                        that.form.areaNames = res.data.dtList[0].areaNames //所选商圈
                        if (that.dialogImageUrl != "") {
                            that.firstPic = that.dialogImageUrl + "," //楼盘图片
                        } else {
                            that.firstPic = that.dialogImageUrl //楼盘图片
                        }
                        that.isConfirmed = res.data.dtList[0].isConfirmed //是否审核
                        that.isPublic = res.data.dtList[0].isPublic //是否可用
                        that.form.weight = res.data.dtList[0].weight //权重
                        that.form.createPersonName =
                            res.data.dtList[0].createPersonName //创建人
                        that.form.createTime = res.data.dtList[0].createTime //创建时间
                        that.form.updatePersonName =
                            res.data.dtList[0].updatePersonName //更新人
                        that.form.updateTime = res.data.dtList[0].updateTime //更新时间
                        that.dialogImageUrl.forEach(function (item) {
                            var uuu = {
                                name: item,
                                url: that.$url.loupanPicSrc + item
                            }
                            // this.picture.push(uuu);
                            if (item != "" || item != null) {
                                that.picture.push(uuu)
                                that.isMax = true
                            }
                            if (that.num == 1) {
                                if (item == "" || item == null) {
                                    that.isMax = true
                                }
                            }
                        })
                        that.regionList1.forEach(function (item, i) {
                            item.includeShangQuan.forEach((item1) => {
                                if (
                                    res.data.dtList[0].areaIds.indexOf(
                                        item1.areaIds
                                    ) != -1
                                ) {
                                    that.checkedAreaType.push(item1.areaIds)
                                    // item.push({ active: "first" })
                                    item.activeName = item1.areaIds + ","
                                    that.activeName = item1.areaIds + ","
                                }
                            })
                        })
                        console.log(that.activeName)
                        console.log(that.regionList1)
                        console.log(that.checkedAreaType)
                    } else {
                        that.$message.success(res.data.message)
                    }
                })
        },
        // 楼盘字典新增
        async onSubmit(formName) {
            var that = this
            that.$refs[formName].validate(async (valid) => {
                if (valid) {
                    var louPanInfo1 = {
                        yuShouXuKeZheng: that.form.yuShouXuKeZheng, //预售许可证
                        guoYouTuDiShiYongZheng:
                            that.form.guoYouTuDiShiYongZheng, //国有土地使用证
                        gongChengGuiHuaXuKeZheng:
                            that.form.gongChengGuiHuaXuKeZheng, //工程规划许可证
                        zongTaoShu: that.form.zongTaoShu, //总套数
                        zongMianJi: that.form.zongMianJi, //总面积
                        keShouZongTaoShu: that.form.keShouZongTaoShu, //可售总套数
                        keShouZongMianJi: that.form.keShouZongMianJi, //可售总面积
                        zhuZhaiTaoShu: that.form.zhuZhaiTaoShu, //住宅总套数
                        zhuZhaiMianJi: that.form.zhuZhaiMianJi, //住宅总面积
                        keShouZhuZhaiTaoShu: that.form.keShouZhuZhaiTaoShu, //可售住宅总套数
                        keShouZhuZhaiMianJi: that.form.keShouZhuZhaiMianJi, //可售住宅总面积
                        feiZhuZhaiTaoShu: that.form.feiZhuZhaiTaoShu, //非住宅套数
                        feiZhuZhaiMianJi: that.form.feiZhuZhaiMianJi, //非住宅面积
                        keShouFeiZhuZhaiTaoShu:
                            that.form.keShouFeiZhuZhaiTaoShu, //可售非住宅套数
                        keShouFeiZhuZhaiMianJi:
                            that.form.keShouFeiZhuZhaiMianJi, //可售非住宅面积
                        jiaoYiQuanShu: that.form.jiaoYiQuanShu, //交易权属
                        jianZhuJieGou: that.form.jianZhuJieGou, //建筑结构
                        chanQuanNianXian: that.form.chanQuanNianXian, //产权年限
                        jianZhuNianDai: that.form.jianZhuNianDai, //建筑年代
                        fangWuYongTu: that.form.fangWuYongTu, //房屋用途
                        tiHuBiLi: that.form.tiHuBiLi, //梯户比例
                        shiJianXinXi: that.form.shiJianXinXi, //事件信息
                        xianWuSheShi: that.form.xianWuSheShi, //嫌恶设施
                        gongNuanType: that.form.gongNuanType, //供暖类型
                        yongDianType: that.form.yongDianType, //用电类型
                        yongShuiType: that.form.yongShuiType, //用水类型
                        wuYeFei: that.form.wuYeFei, //物业费
                        cheWeiBiLi: that.form.cheWeiBiLi, //车位比例
                        diShangCheWeiShu: that.form.diShangCheWeiShu, //地上车位数
                        diXiaCheWeiShu: that.form.diXiaCheWeiShu, //地下车位数
                        tingCheFuWuFei: that.form.tingCheFuWuFei, //停车服务费
                        dianTi: that.form.dianTi, //有无电梯
                        ranQi: that.form.ranQi, //有无燃气
                        reShui: that.form.reShui, //有无热水
                        zhongShui: that.form.zhongShui, //有无中水
                        gongNuanFei: that.form.gongNuanFei, //供暖费
                        ranQiFei: that.form.ranQiFei, //燃气费
                        reShuiFei: that.form.reShuiFei, //热水费
                        zhongShuiFei: that.form.zhongShuiFei, //中水费
                        youErYuan: that.form.youErYuan //幼儿园
                    }
                    var louPanInfo = JSON.stringify(louPanInfo1)
                    const data = {
                        // articleTitle: that.form.articleTitle,
                        areaIds: that.checkedAreaType.join(","), //商圈id
                        louPanName: that.form.louPanName,
                        louPanAddress: that.form.louPanAddress,
                        kaiFaDanWei: that.form.kaiFaDanWei,
                        louPanInfo: louPanInfo,
                        louPanPics: that.firstPic,
                        isConfirmed: that.fisConfirmed,
                        isPublic: that.isPublic,
                        weight: that.form.weight
                    }
                    if (that.checkedAreaType.join(",") === "") {
                        that.loading = false
                        return that.$message.error("请选择所属商圈")
                    }
                    if (that.form.jianZhuNianDai != undefined) {
                        if (!commonJs.shuZi(that.form.jianZhuNianDai)) {
                            that.loading = false
                            return that.$message.error(
                                "建筑年代格式不符，只能输入数字!"
                            )
                        }
                    }

                    if (that.firstPic === "") {
                        that.loading = false
                        return that.$message.error("请上传楼盘图片")
                    }
                    that.$http
                        .post(that.$url.InsertLouPan, data)
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                that.$message.success("新增楼盘字典成功")
                                that.$router.push({
                                    path: "/LouPanZiDian1List"
                                })
                            } else {
                                that.$message.success(res.data.message)
                            }
                        })
                } else {
                    return false
                }
            })
        },
        // 楼盘字典更新
        async onUpdate(formName) {
            var that = this
            that.$refs[formName].validate(async (valid) => {
                if (valid) {
                    var louPanInfo1 = {
                        yuShouXuKeZheng: that.form.yuShouXuKeZheng, //预售许可证
                        guoYouTuDiShiYongZheng:
                            that.form.guoYouTuDiShiYongZheng, //国有土地使用证
                        gongChengGuiHuaXuKeZheng:
                            that.form.gongChengGuiHuaXuKeZheng, //工程规划许可证
                        zongTaoShu: that.form.zongTaoShu, //总套数
                        zongMianJi: that.form.zongMianJi, //总面积
                        keShouZongTaoShu: that.form.keShouZongTaoShu, //可售总套数
                        keShouZongMianJi: that.form.keShouZongMianJi, //可售总面积
                        zhuZhaiTaoShu: that.form.zhuZhaiTaoShu, //住宅总套数
                        zhuZhaiMianJi: that.form.zhuZhaiMianJi, //住宅总面积
                        keShouZhuZhaiTaoShu: that.form.keShouZhuZhaiTaoShu, //可售住宅总套数
                        keShouZhuZhaiMianJi: that.form.keShouZhuZhaiMianJi, //可售住宅总面积
                        feiZhuZhaiTaoShu: that.form.feiZhuZhaiTaoShu, //非住宅套数
                        feiZhuZhaiMianJi: that.form.feiZhuZhaiMianJi, //非住宅面积
                        keShouFeiZhuZhaiTaoShu:
                            that.form.keShouFeiZhuZhaiTaoShu, //可售非住宅套数
                        keShouFeiZhuZhaiMianJi:
                            that.form.keShouFeiZhuZhaiMianJi, //可售非住宅面积
                        jiaoYiQuanShu: that.form.jiaoYiQuanShu, //交易权属
                        jianZhuJieGou: that.form.jianZhuJieGou, //建筑结构
                        chanQuanNianXian: that.form.chanQuanNianXian, //产权年限
                        jianZhuNianDai: that.form.jianZhuNianDai, //建筑年代
                        fangWuYongTu: that.form.fangWuYongTu, //房屋用途
                        tiHuBiLi: that.form.tiHuBiLi, //梯户比例
                        shiJianXinXi: that.form.shiJianXinXi, //事件信息
                        xianWuSheShi: that.form.xianWuSheShi, //嫌恶设施
                        gongNuanType: that.form.gongNuanType, //供暖类型
                        yongDianType: that.form.yongDianType, //用电类型
                        yongShuiType: that.form.yongShuiType, //用水类型
                        wuYeFei: that.form.wuYeFei, //物业费
                        cheWeiBiLi: that.form.cheWeiBiLi, //车位比例
                        diShangCheWeiShu: that.form.diShangCheWeiShu, //地上车位数
                        diXiaCheWeiShu: that.form.diXiaCheWeiShu, //地下车位数
                        tingCheFuWuFei: that.form.tingCheFuWuFei, //停车服务费
                        dianTi: that.form.dianTi, //有无电梯
                        ranQi: that.form.ranQi, //有无燃气
                        reShui: that.form.reShui, //有无热水
                        zhongShui: that.form.zhongShui, //有无中水
                        gongNuanFei: that.form.gongNuanFei, //供暖费
                        ranQiFei: that.form.ranQiFei, //燃气费
                        reShuiFei: that.form.reShuiFei, //热水费
                        zhongShuiFei: that.form.zhongShuiFei, //中水费
                        youErYuan: that.form.youErYuan //幼儿园
                    }
                    var louPanInfo = JSON.stringify(louPanInfo1)
                    const data = {
                        louPanId: that.louPanId,
                        areaIds: that.checkedAreaType.join(","), //商圈id
                        louPanName: that.form.louPanName,
                        louPanAddress: that.form.louPanAddress,
                        kaiFaDanWei: that.form.kaiFaDanWei,
                        louPanInfo: louPanInfo,
                        louPanPics: that.firstPic,
                        isConfirmed: that.isConfirmed,
                        isPublic: that.isPublic,
                        weight: that.form.weight
                    }
                    if (that.checkedAreaType.join(",") === "") {
                        that.loading = false
                        return that.$message.error("请选择所属商圈")
                    }
                    // console.log(diseaseIds)
                    that.$http.put(that.$url.UpdateLouPan, data).then((res) => {
                        if (res.data.msgCode == 1) {
                            that.$message.success("修改楼盘字典成功")
                            that.$router.push({ path: "/LouPanZiDian1List" })
                        } else {
                            that.$message.success(res.data.message)
                        }
                    })
                } else {
                    return false
                }
            })
        },
        // 图片上传成功回调
        success(response) {
            this.firstPic += response.id + ","
            console.log(this.firstPic)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            // this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        // 图片上传失败回调
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        // 删除图片
        handleBeforeRemove(file, fileList) {
            var that = this
            console.log(file.url.split("/"))
            var mm = file.url.split("/")
            that.mm = mm[mm.length - 1]
            // var mm = file.url.slice(47)
            // that.mm = mm
            // alert(this.mm)
            console.log(that.mm, "mm")
            return that
                .$confirm(`确定移除 ？`)
                .then(() => {
                    // this.$message.success("已移除");
                    var data = {
                        fileGuid: that.mm,
                        moduleName: "louPan"
                    }
                    // this.$http.delete(
                    //     this.$url.deleteOneFile(mm, 'louPan')
                    // )
                    // this.$http.delete(this.$url.deleteOneFile(mm, "louPan"), {
                    //     data
                    // })
                    // if (res.data.msgCode == 1) {
                    //     this.$message.success("删除成功!")
                    //     this.isMax = false

                    // } else {
                    //     this.$message.success(res.message)
                    // }
                    that.firstPic = that.firstPic.replace(that.mm + ",", "")
                    console.log(that.firstPic)
                    this.$commonJs.deleteImg(file, "loupan")
                    // that.firstPic.remove(mm + ",")
                })
                .catch((err) => {
                    that.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        // 返回上一页
        cancel() {
            this.$router.push({ path: "/LouPanZiDian1List" })
        },
        // 楼栋批量删除（暂时不用）
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].louDongId)
                        }
                        this.$http
                            .delete(this.$url.DeleteLouDong, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        // 楼栋单个删除
        handleDelete(index) {
            var data1 = [index.louDongId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.DeleteLouDong, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getloudongListQingKong()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {})
        },
        // 楼栋批量添加（右边添加按钮）
        addItem() {
            this.dynamicItem.push({
                gongChengLouHaoFormatted: "",
                danYuanCount: "",
                louCengCount: "",
                diXiaLouCengCount: "0",
                menPaiHaoCount: ""
            })
        },
        // 楼栋批量新增
        sure(form) {
            var that = this
            that.dynamicItem.forEach(function (item) {
                that.loadingLouDong = true
                const data = {
                    louPanId: that.louPanId,
                    gongChengLouHaoFormatted: item.gongChengLouHaoFormatted,
                    danYuanCount: item.danYuanCount,
                    louCengCount: item.louCengCount,
                    diXiaLouCengCount: item.diXiaLouCengCount,
                    menPaiHaoCount: item.menPaiHaoCount
                }
                if (item.gongChengLouHaoFormatted == "") {
                    that.$message.error("楼栋新增需填写楼栋")
                    return
                } else if (item.danYuanCount == "") {
                    that.$message.error("楼栋新增需填写单元数")
                    return
                } else if (item.louCengCount == "") {
                    that.$message.error("楼栋新增需填写地上楼层数")
                    return
                } else if (item.louCengCount > 120) {
                    that.$message.error("地上楼层数不能大于120")
                    return
                } else if (item.diXiaLouCengCount == "") {
                    that.$message.error("楼栋新增需填写地下楼层数")
                    return
                } else if (item.diXiaLouCengCount > 10) {
                    that.$message.error("地下楼层数不能大于10")
                    return
                } else if (item.menPaiHaoCount == "") {
                    that.$message.error("楼栋新增需填写每层几户")
                    return
                }

                that.$http
                    .post(that.$url.InsertLouDongBatch, data)
                    .then((res) => {
                        if (res.data.msgCode == 1) {
                            that.$message.success("楼栋新增成功！")
                            that.getloudongListQingKong()
                            // that.getloudongList()
                        } else {
                            that.$message.success(res.message)
                        }
                    })
            })
            that.loadingLouDong = false
            // that.$message.success("楼栋新增成功！")
        },
        // 楼栋删除（右边删除按钮）
        deleteItem(item, index) {
            this.dynamicItem.splice(index, 1)
            console.log(this.dynamicItem, "删除")
        },

        // 用户类型多选选中
        handleCheckedAreaType(value) {
            if (value.length > 3) {
                this.$message.info("最多只能选三项")
                return
            } else {
                this.checkedAreaType = value
            }
            console.log(this.checkedAreaType)
        },

        //    <!-- 楼栋信息 -->
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getloudongList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getloudongList()
        },
        getloudongListQingKong() {
            this.currentPage = 1
            this.getloudongList()
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 查询楼栋列表
        async getloudongList() {
            await this.$http
                .get(this.$url.GetLouDongList, {
                    params: {
                        louPanId: this.louPanId, //楼盘编号
                        currentPage: this.currentPage,
                        pageSize: this.pageSize
                    }
                })
                .then((res) => {
                    var that = this
                    if (res.data.msgCode == 1) {
                        this.louDongList = res.data.dtList
                        this.total = res.data.maxRecords
                    } else {
                        that.$message.success(res.message)
                    }
                })
        },
        // 新增
        louDongInsert() {
            this.$router.push({
                name: "LouPanZiDian1Detail2",
                query: {
                    louPanId: this.louPanId,
                    num1: this.num1,
                    num2: 0,
                    state: 1
                }
            })
        },
        // 详情
        louDongDetail(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.drow = row.louDongId
            this.$router.push({
                name: "LouPanZiDian1Detail2",
                query: {
                    louPanId: this.louPanId,
                    louDongId: this.drow,
                    louPanNo: this.louPanNo,
                    num1: this.num1,
                    num2: 1,
                    state: 2
                }
            })
        },
        louDongUpdate(index, row) {
            this.drow = row.louDongId
            this.$router.push({
                name: "LouPanZiDian1Detail2",
                query: {
                    louPanId: this.louPanId,
                    louDongId: this.drow,
                    louPanNo: this.louPanNo,
                    num1: this.num1,
                    num2: 2,
                    state: 3
                }
            })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        getShuJuZiDianList() {
            /* 获取交易权属列表 */
            let that = this
            let quyu = [],
                name = ""
            let buxian = {
                value: "0",
                label: "不限"
            }
            this.$commonJs
                .getShuJuZiDianList(
                    "JIAOYIQUANSHU,JIANZHUJIEGOU,HUXING,FANGWUYONGTU,ZHUANGXIUZHUANGKUANG,SHANGQUAN"
                )
                .then((data) => {
                    that.HuXing = data.HuXing
                    that.options_jianZhuJieGou = data.JianZhuJieGou
                    that.options_jiaoYiQuanShu = data.JiaoYiQuanShu
                    that.options_fangWuYongTu = data.FANGWUYONGTU
                    that.regionList1 = data.shangquan
                    console.log(that.regionList1)
                    // 0 新增 1 详情 2 修改
                    if (this.num1 == 0) {
                        this.loading = false
                        this.name = "楼盘字典新增"
                        // this.flag4 = false;
                    } else if (this.num1 == 1) {
                        this.name = "楼盘字典详情"
                        this.flag = true
                        this.flag_shangquan = true
                        this.isMax = true
                        //  document.getElementsByClassName(".ql-toolbar").styte("none")
                        this.getloupanDetail()
                        this.getloudongList()
                    } else if (this.num1 == 2) {
                        this.name = "楼盘字典修改"
                        this.getloupanDetail()
                        this.getloudongList()
                    }
                    // that.getloupanDetail()
                })
        },
        handleClick(tab, event) {
            // this.checkedAreaType = []
        }
    }
}
</script>
<style lang="less" scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}
// /deep/.business {
//     border: 0 !important;
//     height: 21px !important;
//     line-height: 23px !important;
// }
/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
    // z-index: 1111;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
.business {
    background: #fff;
    // border: 1px solid #ccc;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    // margin-left: 2% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/ .el-tabs__nav .is-top {
    margin-left: 5px;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
.weituolaiyuan .el-form-item__content {
    margin-left: 0px !important;
}
</style>