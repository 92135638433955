<template>
    <div class="qianyue">
        <el-card class="intanle">
            <span v-if="state == '2'" class="h1">签约(合同)修改</span>
            <span v-if="state == '0'" class="h1">签约(合同)新增</span>
            <el-button style="float: right" size="mini" @click="goBack" class="el-button" type="primary">返回</el-button>
            <el-button v-if="contractStatus == '10'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="draft()">保存草稿</el-button>
            <!-- <el-button v-if="contractStatus=='10'&&roleType==1" style="float: right" class="el-button1" type="warning" size="mini" @click="apply()">提交申请</el-button> -->
            <el-button v-if="contractStatus == '10' && roleType != 1" style="float: right" class="el-button1"
                type="warning" size="mini" @click="applyPass()">审核通过</el-button>
            <!-- <el-button v-if="contractStatus=='20'" style="float: right" class="el-button1" type="warning" size="mini" @click="onSave(1)">保存合同</el-button> -->
            <el-button v-if="contractStatus == '20'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="save()">保存合同</el-button>
            <el-button v-if="contractStatus == '30'" style="float: right" class="el-button1" size="mini" type="warning"
                @click="Submit()">电子签章</el-button>
            <el-button v-if="roleType == 2 || roleType == 5 || roleType == 6" style="float: right" class="el-button1"
                size="mini" type="warning" @click="onSave(2)">下载合同</el-button>
        </el-card>
        <el-backtop></el-backtop>
        <!-- <div id="printTest"> -->
        <el-card class="body">
            <div class="header">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <h3 class="text_align_r">
                <span>合同编号：</span><input :disabled="flag" class="inputW_150 input_borderBottom_ccc"
                    v-model="form.contractNo1" />
            </h3>

            <div class="quote_info clearfix">
                <h1 class="h3_title">大连市存量房屋</h1>
                <h1 class="h3_title">买卖合同</h1>

                <h2 class="h2_title">签约提示</h2>
                <p>
                    <span>第一条 请您确认，在签署合同前，</span><span
                        class="font_weight">您已仔细阅读过合同条款并予以理解和接受</span><span>，同时我公司经办人员已向您告知与交易房屋及合同签署有关的信息。</span>
                </p>
                <p>
                    <span>第二条 请您核查一式三份合同的内容（包括手工填写的部分）应</span>完全一致。
                </p>
                <p>
                    <span>第三条 在您签署的合同中，除需手工填写的地方以外，其他部分均</span><span
                        class="font_weight">不得随意进行手写补充和修改，</span><span>否则将会影响合同或条款的有效性；如您确实需要对合同中条款进行补充或修改的，请您及其他签署方在补充或修改处签字或盖章后，方为生效。</span>
                </p>
                <p>
                    <span>第四条 在您向我司支付中介代理费或服务费时，请</span><span
                        class="font_weight">向我司经办人员索取由我司盖印章的专用收据；</span><span>如该经办人员不能提供上述专用收据的，</span><span
                        class="font_weight">请您不要付款，</span><span>必要时，请与您签约的店面经理进行确认。</span>
                </p>
                <p>
                    <span>第五条 在填写相关合同文本时，如遇</span><span
                        class="font_weight">“□”</span><span>等需要选择的内容，</span><span>请务必作出明确的勾选，以划“☑”方式选定您选择的事项；对于实际情况未发生、或双方当事人不做约定、或该条不适用时，应当划“×”，以示删除。</span>
                </p>
                <p>
                    第六条
                    为了保障您的资金安全，请您在向我司支付中介代理费及服务费时，优先选择如下方式：
                </p>
                <p>
                    Ø
                    POS机刷卡——您可以使用本人或亲朋的银行储蓄卡或信用卡刷卡（本地卡、异地卡均可），刷卡后请持卡人在POS小票上亲笔签字
                </p>
                <p>
                    Ø
                    银行转账——通过银行柜台以现金（或转账）的形式将代理费及服务费直接汇入我司指定账户内
                </p>
            </div>
            <div class="quote_info clearfix border_000 box">
                <p>
                    如您对我们的服务有任何的问题、意见或者建议，请您拨打我们的服务热线：
                </p>
                <br />
                <div>
                    <span>客服热线:
                        <!-- <input
              class="inputW_150 input_borderBottom_000"
              v-model="form.tel1"
              :maxlength="13"
            /> -->
                        <span class="inputW_150 input_borderBottom_000">
                            <!-- 13998591951 -->
                            13889606990
                        </span>
                        （售后问题）
                    </span><br />
                    <span>交易客服热线:
                        <!-- <input
              class="inputW_150 input_borderBottom_000"
              v-model="form.tel2"
              :maxlength="17"
            /> -->
                        <span class="inputW_150 input_borderBottom_000">
                            <!-- 13842887662 -->
                            13940925982
                        </span>
                        （交易问题）
                    </span>
                </div>
            </div>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">交易资金支付风险告知函</h2>
                <h3 class="font_weight500">
                    甲方（出售方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.jiaFang1" />
                </h3>
                <h3 class="font_weight500">
                    乙方（购买方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yiFang1" />
                </h3>

                <h3 class="font_weight500">
                    为了保障交易资金安全，避免在交易过程中出现资金风险，导致您产生经济损失，现中介方温馨提示如下：
                </h3>

                <h3 class="font_weight500">
                    第一条 房款、定金、保证金等资金安全告知：
                </h3>
                <p class="text-indent">
                    甲乙双方交易该上述资金时，请转入甲乙双方指定的银行监管账户或我司指定账户，请勿将上述项资金给到员工个人账户；
                </p>

                <h3 class="font_weight500">
                    第二条 中介费、保障服务费等资金安全告知：
                </h3>
                <p class="text-indent">
                    为了保障您的资金安全，请您在向我司支付中介代理费及服务费时，请务必向我司指定账户转账，请优先选择POS机刷卡、银行转账和网银汇款的方式，并向我司经办人员索取由公司加盖印章的专用收据，若经办人不能提供上述专用收据的，请您不要付款！请勿将该项资金转给个人账户、非中介方对公账户；禁止现金支付，请您务必谨慎及遵守。
                </p>
                <p class="text-indent">
                    现甲乙双方明确知晓上述风险提示，如在上述费用支付或收取方式外选择其他方式支付或收取款项，导致产生经济损失的，可能面临的风险包括但不限于：房屋出售方无法取得全部房款、房屋购买方无法取得房屋所有权、房屋购买方无法追回已支付的房款等，双方自愿承担一切风险及后果。
                </p>

                <h3 class="font_weight500">
                    甲方（签章）：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.jiaFangZ1" />
                </h3>
                <h3 class="font_weight500">
                    乙方（签章）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yiFangZ1" />
                </h3>
                <div class="margin_top_20 font_weight500">
                    本告知函签署日期：<span class="font_weight600"><input :disabled="flag"
                            class="inputW_80px input_borderBottom_000" v-model="form.gaoZhi_Year1"
                            :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                            v-model="form.gaoZhi_Month1" :maxlength="2" @blur="rulesDate(1, 'gaoZhi_Month1')" />月<input
                            :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.gaoZhi_Day1"
                            :maxlength="2" @blur="rulesDate(2, 'gaoZhi_Day1')" />日</span>
                </div>
            </div>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix">
                <h2 class="h2_title margin_top_50">交易方留存信息表</h2>
                <h3>房屋出卖人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chuShou_ChuShengRiQi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerIdCardNo"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShou_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3>房屋出卖共有权人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerNameGY" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerIdCardNoGongYou"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouGY_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3>房屋出卖代理人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_Name" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chuShouDL_ChuShengRiQi"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerIdCardNoDaiLi"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuShouDL_Address" />
                    </li>
                </div>
            </div>
            <h3 class="quote_info margin_top_20">
                <p>
                    卖方确认，本页所列信息适用于本合订本全部文件，包括《大连市存量房屋买卖合同》及本次交易涉及的所有文本。如信息发生变化，需及时书面告知对方及我司。
                </p>
                <div>
                    <span>甲方（签章）:</span><input :disabled="flag" class="inputW_150 input_borderBottom_000"
                        v-model="form.jiaFangZ2" /><br />
                    <span class="font_weight600"><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                            v-model="form.chuShou_Year" :maxlength="4" />年<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.chuShou_Month" :maxlength="2"
                            @blur="rulesDate(3, 'chuShou_Month')" />月<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.chuShou_Day" :maxlength="2"
                            @blur="rulesDate(4, 'chuShou_Day')" />日</span>
                </div>
            </h3>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix margin_top_100">
                <h3>房屋买受人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.gouMai_ChuShengRiQi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerIdCardNo"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMai_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3>房屋买受共有权人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerNameGY" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.gouMaiGY_ChuShengRiQi"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerIdCardNoGongYou"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiGY_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3>房屋买受代理人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_Name" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.gouMaiDL_ChuShengRiQi"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_ZhengJianName" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerIdCardNoDaiLi"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">电子邮箱</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_Email"
                            @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.gouMaiDL_Address" />
                    </li>
                </div>
            </div>
            <h3 class="quote_info margin_top_20">
                <p>
                    买方确认，本页所列信息适用于本合订本全部文件，包括《大连市存量房屋买卖合同》及本次交易涉及的所有文本，如信息发生变化，需及时书面告知对方及我司。
                </p>
                <div>
                    <span>乙方（签章）:</span><input :disabled="flag" class="inputW_150 input_borderBottom_000"
                        v-model="form.yiFangZ2" /><br />
                    <span class="font_weight600"><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                            v-model="form.gouMai_Year" :maxlength="4" />年<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.gouMai_Month" :maxlength="2"
                            @blur="rulesDate(5, 'gouMai_Month')" />月<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.gouMai_Day" :maxlength="2"
                            @blur="rulesDate(6, 'gouMai_Day')" />日</span>
                </div>
            </h3>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>

            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">大连市存量房屋买卖合同</h2>
                <h3 class="font_weight500">
                    房屋出售方（以下简称甲方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.chuShou1" />
                </h3>
                <h3 class="font_weight500">
                    共有权方：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.gongYou" />
                </h3>
                <h3 class="font_weight500">
                    代理人：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.daiLi1" />
                </h3>
                <br />
                <h3 class="font_weight500">
                    房屋购买方（以下简称乙方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.gouMai1" />
                </h3>
                <h3 class="font_weight500">
                    共同买受方：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.gongMai" />
                </h3>
                <h3 class="font_weight500">
                    代理人：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.daiLi2" />
                </h3>

                <h3 class="font_weight500 text-indent">
                    根据《中华人民共和国民法典》《中华人民共和国城市房地产管理法》《大连市城市房地产转让管理办法》及其他有关法律、法规的规定，甲方和乙方在平等、自愿、公平、协商一致的基础上就存量房屋买卖事宜达成如下协议：
                </h3>

                <h3 class="font_weight500">第一条 房屋基本情况</h3>
                <p class="text-indent">
                    甲方所售房屋（以下简称该房屋）位于大连市<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.fangWu_Address1" />的房屋，建筑面积约<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.fangWu_MianJi"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>99999)value=99999" />平方米（房屋具体面积与地址以房屋所有权证/不动产权证上的记载为准）。
                    房屋内有<label v-for="item in JiBenQingKuangOptions" :label="item.name" :key="item.id"><input
                            :disabled="flag" :value="item.id" type="checkbox"
                            v-model="formCheck.checkedJiBenQingKuang" />{{
                item.name }} </label>。
                </p>

                <h3 class="font_weight500">第二条 房屋权属情况</h3>
                <p>
                    （一）该房屋权属为<label v-for="item in FangWuQuanShuOptions" :label="item.name" :key="item.id"><input
                            :disabled="flag" :value="item.id" type="checkbox"
                            v-model="formCheck.checkedFangWuQuanShu" />{{
                item.name }}</label>
                    （房屋用途及类别以不动产登记信息为准），权属证号：<input :disabled="flag" class="inputW_30 input_borderBottom_000"
                        v-model="form.quanShuZhengHao" />,共有权证证号为：<input :disabled="flag"
                        class="inputW_30 input_borderBottom_000" v-model="form.gongYouQuanZhengHao" />。
                </p>
                <p>（二）该房屋的抵押情况为：</p>
                <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.DYQKRadio" label="1">该房屋未设定抵押；
                </el-radio><br />
                <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.DYQKRadio" label="2">
                    该房屋已设定抵押<!--， 提前还款产生的利息和费用，由甲方自行承担-->。</el-radio>
                <!-- <p>
                    甲方自行还清欠款，保证于<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.baoZheng_Day" />日内将产权证原件交付中介方代为保管。
                </p> -->
                <p>(三) 该房屋的租赁情况为：</p>
                <p class="text-indent-12">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZLQKRadio" label="1">甲方未将该房屋出租；
                    </el-radio>
                </p>
                <p class="text-indent-12">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZLQKRadio" label="2">
                        甲方已将该房屋出租，乙方为该房屋承租人；</el-radio>
                </p>
                <p class="text-indent-12">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZLQKRadio" label="3">
                        甲方已将该房屋出租，且承租人以放弃优先购买权；如因承租人原因导致本合同无法履行，则甲方应按照本合同第九条约定之标准承担违约责任。</el-radio>
                </p>
                <p class="text-indent">
                    关于房屋租约的约定：
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.ZYYDRadio" label="1">有</el-radio>
                    </span>
                    &nbsp;
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.ZYYDRadio" label="2">无</el-radio>
                    </span>
                </p>
                <p class="text-indent">
                    交易房屋的租赁期限至<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.zuLinToYear" :maxlength="4" />年<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.zuLinToMonth" :maxlength="2"
                        @blur="rulesDate(7, 'zuLinToMonth')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.zuLinToDay" :maxlength="2"
                        @blur="rulesDate(8, 'zuLinToDay')" />日止， 月租金为人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.yueZu_XiaoXieYuan"
                        @blur="moneyRule('yueZu_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.yueZu_DaXieYuan" />，押金为人民币（小写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000" v-model="form.yaJin_XiaoXieYuan"
                        @blur="moneyRule('yaJin_XiaoXieYuan')" />元，（大写）
                    <input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.yaJin_DaXieYuan" />；就房屋租金及押金等租赁相关事宜，甲乙双方一致同意按照以下方式处理：
                </p>
                <p class="text-indent-12">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.CLFSRadio" label="1">
                        <span>乙方同意继续履行租赁合同直至租赁期限届满为止；</span>
                    </el-radio>
                    交房前租金归甲方所有，交房后租金归乙方所有；若甲方事先已收取部分租金及押金的，应在交房后五个工作日内归还乙方，租赁期满后由乙方负责与租户办理交接手续。
                </p>
                <p class="text-indent-12">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.CLFSRadio" label="2">
                        交易房屋租金全部归甲方所有；</el-radio>
                    由甲方负责与租户办理物业交接和押金退还手续；甲方承诺在交房前腾退租户，并自行承担由此产生的所有费用（包括且不限于租赁合同约定的违约责任、押金），按照本协议约定按时交付房屋。
                </p>
                <p class="text-indent-12">
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.CLFSRadio" label="3">
                            双方协商一致：</el-radio>
                    </span><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.xieShangYiZhi" />。
                </p>

                <h3 class="font_weight500">第三条 成交方式</h3>
                <p class="text-indent">
                    甲方与乙方通过<span class="border_bottom">{{ form.companyName }}</span>中介介绍成交。
                </p>

                <h3 class="font_weight500">第四条 成交价格、付款方式及资金划转方式</h3>
                <p>
                    （一）甲乙双方同意房屋出售价格为人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999" v-model="form.zongFangKuan_XiaoXieYuan"
                        @blur="moneyRule('zongFangKuan_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.zongFangKuan_DaXieYuan" />。此价格包含房屋价格、该房屋配套设施、房屋装饰装修及家具家电（若有）等作为合计；（该笔款项以下简称“总房款”）。
                </p>
                <p>
                    1.其中该房屋价格为人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.fangWuJiaGe_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('fangWuJiaGe_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.fangWuJiaGe_DaXieYuan" />。
                </p>
                <p>
                    2.该房屋配套设施、装修及家具家电（若有）等作为人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.zhuangXiuKuan_XiaoXieYuan1"
                        @blur="moneyRule('zhuangXiuKuan_XiaoXieYuan1')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.zhuangXiuKuan_DaXieYuan1" />（该笔款项以下简称为“配套设施价款”）。
                </p>
                <p>
                    （二）定金人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.dingJin_XiaoXieYuan1"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('dingJin_XiaoXieYuan1')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.dingJin_DaXieYuan1" />，于甲乙双方交接房款时冲抵房款。
                </p>
                <p>
                    1.乙方于<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.dingJin_DiYiBi" />将第一笔定金（定转佣）人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.diYiBi_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('diYiBi_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.diYiBi_DaXieYuan" />作为甲方中介费，按照如下方式支付：于过户完成后，以
                    <label v-for="item in DingZhuanYongOption" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedDingZhuanYong" />{{ item.name }}
                    </label>。
                </p>
                <p>
                    2.乙方于<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.dingJin_DiErBi" />将第二笔定金人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.diErBi_XiaoXieYuan"
                        @blur="moneyRule('diErBi_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.diErBi_DaXieYuan" />以自行支付的方式支付中介方，由中介方代为保管。
                </p>
                <p>
                    乙方将本款所述定金存入中介方专用账户时，即视为乙方已经实际完成定金支付义务，发生定金效力，甲方无异议。
                </p>
                <p>
                    3.甲方同意存管在{{
                form.companyName
            }}指定账户的定金划转条件如下：<br />
                    <input :disabled="flag" :value="JiaFangTongYiOptions[0].id" type="checkbox"
                        v-model="formCheck.checkedJiaFangTongYi" />{{ JiaFangTongYiOptions[0].name }}，存管在{{
                form.companyName
            }}指定账户的定金人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.cunGuanDingJin_XiaoXieYuan1"
                        @blur="moneyRule('cunGuanDingJin_XiaoXieYuan1')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.cunGuanDingJin_DaXieYuan1" />作为甲方中介费。<br />
                    <input :disabled="flag" :value="JiaFangTongYiOptions[1].id" type="checkbox"
                        v-model="formCheck.checkedJiaFangTongYi" />{{ JiaFangTongYiOptions[1].name }}，存管在{{
                form.companyName
            }}的指定账户定金人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.cunGuanDingJin_XiaoXieYuan2"
                        @blur="moneyRule('cunGuanDingJin_XiaoXieYuan2')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.cunGuanDingJin_DaXieYuan2" />在满足户口相关条件后，该笔定金将按照甲乙双方与{{
                form.companyName
            }}签署的协议之约定划转至甲方支付账户或退回至乙方原付款银行账户，甲乙双方另有约定的除外。<br />
                    <input :disabled="flag" :value="JiaFangTongYiOptions[2].id" type="checkbox"
                        v-model="formCheck.checkedJiaFangTongYi" />{{
                JiaFangTongYiOptions[2].name
            }}，最后剩余定金人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.shengYuDingJin_XiaoXieYuan"
                        @blur="moneyRule('shengYuDingJin_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.shengYuDingJin_DaXieYuan" />按照甲乙双方与{{
                form.companyName
            }}签署的协议之约定划转至甲方支付账户或退回至乙方原付款银行账户。
                </p>
                <p class="font_weight">
                    甲乙双方共同确认，若甲乙双方选择资金存管方式支付定金，则乙方将本款所述定金存入资金存管专用账户时，即视为乙方已经实际完成定金支付义务，同时视为甲方已经收到定金，发生定金效力，甲乙双方无异议。</p>
                <p>（三）定金外房款的付款方式</p>
                <p>
                    乙方的付款方式
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.QKODKRadio" label="1">全款
                        </el-radio>
                    </span>&nbsp;
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.QKODKRadio" label="2">贷款
                        </el-radio>
                    </span>
                    <!-- <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.QKODKRadio" label="3">组合贷款
                        </el-radio>
                    </span> -->
                    ，关于购房款（即：总房款减去定金后的剩余款项）的约定和具体支付方式的约定为：
                </p>
                <p>
                    1.购房款/首付款及配套设施价款（若选全款，则此条款对应为购房款；若选贷款，则此条款对应为首付款；若合同第四条第一项内约定有配套设施价款，则此条款除全款或首付款外还包含配套设施价款）
                </p>
                <p class="text-indent">
                    乙方于合同签订<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.qianDing_Day" />日内将购房款/首付款人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.fangKuan_XiaoXieYuan1"
                        @blur="moneyRule('fangKuan_XiaoXieYuan1')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.fangKuan_DaXieYuan1" />
                    （不包含前期支付全部定金）及配套设施价款人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.zhuangXiukuan_XiaoXieYuan2"
                        @blur="moneyRule('zhuangXiukuan_XiaoXieYuan2')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.zhuangXiukuan_DaXieYuan2" />以
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.XSOXXRadio" label="1">线上银行资金存管</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.XSOXXRadio" label="2">线下银行资金存管</el-radio>
                    </span>的方式支付甲方；
                </p>
                <p class="text-indent">
                    甲乙双方同意，将购房款/首付款其中人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.fangKuan_XiaoXieYuan2" @blur="moneyRule('fangKuan_XiaoXieYuan2')" />元，（大写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.fangKuan_DaXieYuan2" />元作为物业交割保证金（以下三选一）；
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BZJSXYRadio" label="1">线上银行资金存管
                    </el-radio>
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BZJSXYRadio" label="2">线下银行资金存管
                    </el-radio>
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BZJSXYRadio" label="3">
                        自行协商，详见补充协议</el-radio>
                </p>
                <p class="text-indent">
                    在满足户口相关条件后，户口迁出保证金将按照甲乙双方与第三方存管机构签署的协议或《户口交割协议》之约定划转至甲方支付账户或退回乙方银行账户，甲乙双方另有约定的除外。
                </p>
                <!-- <p class="text-indent">
                    在满足物业交割相关条件后，该笔保证金将按照甲乙双方与第三方存管机构签署的协议或《物业交割协议》之约定划至甲方支付账户或退回至乙方银行账户，甲乙双方另有约定的除外。
                </p> -->
                <p class="text-indent">
                    名词解释:资金存管，是指在房屋交易过程中，将房屋交易资金存放到第三方存管机构中存管，待交易成功后，再将资金解冻至卖方账户，若交易无法达成，资金退回买方。
                </p>
                <p class="text-indent">
                    线上资金存管：“线上银行资金存管”置囊团与银行联合推出一站式线上化办理的资金存管服务，房屋交易资金存放在银行专设的银行账户。
                </p>
                <p>2.贷款</p>
                <p class="text-indent">
                    (1) 乙方申请贷款方式为：<span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="1">商业贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="2">公积金贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="3">组合贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="4">部队公积金贷款</el-radio>
                    </span>；拟贷款金额为人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999" v-model="form.daiKuan_XiaoXieYuan"
                        @blur="moneyRule('daiKuan_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.daiKuan_DaXieYuan" />。
                    <!-- 为保证交易安全及操作便利，贷款银行由甲乙双方共同委托中介方指定，乙方具体贷款额度、贷款年限及贷款利率以银行（或公积金管理中心）最终审核为准；并且乙方必须提供真实有效完整的相关材料和信息，乙方因自身原因未获得贷款机构批准的（包括贷款未获得批准和未按照前述拟贷款金额足额批准的），双方同意按照以下 -->
                    该部分款项由贷款机构按其规定直接支付甲方，支付时间以贷款机构实际放款为准。
                    <!-- <span class="font_weight">哪种方式</span>解决： -->
                </p>
                <p class="text-indent">
                    (2)
                    为保证交易安全及操作便利，贷款银行由甲乙双方共同委托中介方指定，乙方具体贷款额度、贷款年限及贷款利率以银行（或公积金管理中心）最终审核为准；并且乙方必须提供真实有效完整的相关材料和信息，乙方因自身原因未获得贷款机构批准的（包括贷款未获得批准和未按照前述拟贷款金额足额批准的），双方同意按照以下
                    <span class="font_weight">哪种方式</span>解决：
                </p>
                <p class="text-indent">
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKJJFSRadio" label="1">乙方自行</el-radio>
                    </span>筹集剩余房价款，以现金形式支付给甲方；并于乙方在首次贷款未获得批准或未按照前述拟贷款金额足额批准通知下达之日起<input :disabled="flag"
                        class="inputW_80px border_bottom" v-model="form.xiaDa_Day" />日内以现金方式支付给甲方，甲乙双方另有约定的除外。
                </p>
                <p class="text-indent">
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKJJFSRadio" label="2">乙方继续</el-radio>
                    </span>向其他贷款机构申请贷款，乙方自行负担其间已发生的及要产生的各项费用；甲方同意乙方在<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000"
                        v-model="form.daiKuanJiGou" />日内继续申请其他贷款机构贷款，买受人至迟于<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000"
                        v-model="form.daiKuanPiFu" />日内获得贷款批复。未获得贷款机构批准，则本合同终止，乙方承担违约责任，以及承担在申办贷款过程中发生的各项费用。
                </p>
                <p class="text-indent">
                    <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                        v-model="formCheck.DKJJFSRadio" label="3">本合同终止</el-radio>
                    ，乙方支付的定金和房价款应如数返还，甲乙双方互不承担违约责任，乙方承担在申办贷款过程中发生的各项费用。
                </p>
                <p class="text-indent font_weight">
                    (3)
                    若乙方拟贷款审批通过且甲乙双方办理完毕权属转移登记后，因乙方原因包括但不限于征信等原因，贷款机构拒绝放款（包括未足额发放和未发放）导致甲方无法获得相应购房款，甲乙双方同意按照以下方式处理：乙方须在贷款银行拒绝放款后
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.juJueFangKuan_Day" />日内将相应购房款以现金方式自行补足支付给甲方，并赔偿甲方因此产生的实际损失。
                </p>
                <p>
                    （四）房款支付时间：甲乙双方在交接房屋时交接全部房款，甲乙双方另有约定的除外。
                </p>

                <h3 class="font_weight500">第五条 房屋产权及具体情况的承诺</h3>
                <p>（一）甲方确认该房屋：</p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JZQRadio" label="1">已设立居住权
                    </el-radio>，甲方需于过户前办理完毕注销登记，否则每逾期一日甲方应向乙方支付该房屋总房款的1‰作为违约金，直至甲方办理完毕居住权销户登记之日为止。
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JZQRadio" label="2">未设立居住权
                    </el-radio>，且甲方承诺签署该合同后不得在该房屋设立居住权，否则视为甲方根本性违约，乙方有权解除房屋买卖合同关系（但需书面告知甲方），由甲方承担违约责任。
                </p>
                <p>
                    （二）甲方承诺此房屋签约前无查封,并须在签约后<input :disabled="flag" class="inputW_80px border_bottom"
                        v-model="form.qianYue_Day" />个工作日内,将相关政府部门出具的《自然人房屋信息查询》提交至中介方;若甲方未在约定时间内提供《自然人房屋信息查询》
                    ,交易过程中发现该房屋签前已查封,则甲方需承担合同第九条违约责任;若甲方在约定时间内提供《自然人房屋信息查询》
                    ,但显示该房屋在签约前已查封,则甲乙双方同意解除本合同,三方免责并共同签署解除协议,关于乙方已支付定金及房款按如下方式解决:
                </p>
                <p class="text-indent">
                    1.如定金、首付款等房款已支付甲方的,则甲方须在合同解除后3个工作日内将乙方已支付的房款无息全额返还给乙方,如甲方逾期返还的,每逾期一日甲方需要承担逾期违约金每日<input
                        :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.weiYueJin1"
                        @blur="moneyRule('weiYueJin1')" />元,直至房款返还日为止;
                </p>
                <p class="text-indent">
                    2.如房款通过线上银行资金存管的方式支付甲方的,则甲方须在合同解除后1个工作日内配合乙方将已托管的房款解冻全额返还给乙方,如甲方逾期不配合办理的,每逾期一日甲方需要承担逾期违约金每日万分之五,直至房款返还日为止。
                </p>
                <p>
                    （三）甲方应当保证该房屋没有产权纠纷，未被限制转让。甲方承诺其配偶和该房屋的共有权人均知晓并同意本次房屋交易，认可本合同及其他相关文件的约定，且无他人对该房屋享有优先购买权；乙方承诺其配偶和该房屋的共同购房人均知晓并同意本次房屋交易，且认可本合同及其他相关文件的约定。如因一方违反前述承诺，导致该房屋不能办理产权登记或发生债权债务纠纷的，由违约方承担违约责任。本条款为独立条款，即使本合同及其他文件因一方违反前述承诺，依法被变更、撤销、解除、终止或认定无效的，本条约定依然有效，守约方仍可依据本条款追究违反承诺方的法律责任。
                </p>
                <p>
                    （四）甲方确认，该房屋在其持有或近亲属持有期间，在房屋本体结构内<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFFSRadio" label="1">是
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.SFFSRadio" label="2">否</el-radio>
                    </span>否发生过非正常死亡事件。
                </p>
                <p class="text-indent">
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.SWFSRadio" label="1">自然死亡事件</el-radio>
                    </span>（指符合生命和疾病自然发展规律，没有暴力干预而发生的死亡）。
                </p>
                <p class="text-indent">
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.SWFSRadio" label="2">非自然死亡事件</el-radio>
                    </span>（包括但不限于自杀、他杀、从该房屋内坠出死亡、意外死亡等）。
                </p>
                <p>
                    如甲方在签署本合同前就前述事项隐瞒真实情况的，三方确认：因甲方之欺诈行为，使得乙方和中介方在违背真实意思的基础上订立本合同，乙方有权依法要求撤销房屋买卖合同及相关协议。
                </p>
                <p>甲方另应承担如下责任：</p>
                <p class="text-indent">
                    1.甲方应当在乙方提出请求后十个工作日内返还乙方全部房款；未如期返还的，甲方应当按日计算向乙方支付房屋总房款万分之五的滞纳金。
                </p>
                <p class="text-indent">
                    2.甲方应当赔偿乙方及中介方因此所受的损失，包括但不限于：就已收取的房款按照银行同期贷款利率赔偿乙方利息损失、赔偿乙方为购房支出的税费、贷款费用等实际支出、乙方已向中介方缴纳的全部费用、房屋价格上涨的差价损失，乙方实际支出的装修、物业、使用费用等。
                </p>
                <p>
                    （五）签约当日，甲乙双方未到房屋所在地的户籍管理机关查实户口，关于户口问题约定如下：
                </p>
                <p class="text-indent">
                    1、甲方于签约当日就交易房屋地址下户口问题披露如下：
                </p>
                <p class="text-indent">
                    <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                        v-model="formCheck.SFYHKRadio" label="1">有户口</el-radio>
                </p>
                <p class="text-indent">
                    <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                        v-model="formCheck.SFYHKRadio" label="2">无户口</el-radio>
                </p>
                <p class="text-indent">
                    2、该房屋地址下户口查实问题，甲乙双方约定如下处理方式：
                </p>

                <p class="text-indent">
                    <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                        v-model="formCheck.HKCSRadio" label="1">在过户前，甲乙双方共同到房屋所在地的户籍管理机关查实户口</el-radio>
                </p>
                <p class="text-indent">
                    <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                        v-model="formCheck.HKCSRadio" label="2">其他</el-radio>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.hkcs_QiTaChaShi" />
                </p>

                <p class="text-indent">
                    3、如交易房屋地址下存有甲方相关户口或者历史遗留户口（以下简称“原有户口”），关于户口迁出问题，甲乙双方约定按照如下方式处理：甲方应当在权属转移登记之日起
                    <input :disabled="flag" class="inputW_80px border_bottom" v-model="form.quanShuDengJiHou_Day" />
                    个工作日内将原有户口迁出，保证房屋交付时该房屋地址下无任何户口存在，乙方及乙方相关人员户口除外；逾期未迁出则按照第九条约定承担违约责任。
                </p>
                <p class="text-indent">
                    4、中介方已就户口问题明确告知甲乙双方相关交易风险，包括但不限于历史遗留户口无法迁出、影响落户、涉嫌合同欺诈等风险，甲乙双方知晓并认可，并自愿承担风险，继续签署本合同；
                </p>
                <p>
                    （六）甲方确认该房屋主体结构内（不含公共区域：如公共管道、外墙、屋顶等）<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFLSRadio" label="1">是
                        </el-radio>
                    </span>&nbsp;
                    <span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.SFLSRadio" label="2">否</el-radio>
                    </span>存在过漏水（“漏水”指房屋顶部、墙面、室内管道或地面发生的渗水漏水现象）的情况，<span class="font_weight">漏水位置为<input
                            :disabled="flag" class="inputW_80px input_borderBottom_000"
                            v-model="form.louShuiWeiZhi" />，甲方承诺该位置
                        <span>
                            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFXHRadio" label="1">
                                <span class="font_weight">是</span>
                            </el-radio>
                        </span>&nbsp;
                        <span>
                            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFXHRadio" label="2">
                                <span class="font_weight">否</span>
                            </el-radio>
                        </span>已经维修好。
                    </span>若该房屋主体结构内存在漏水情况，乙双方协商一致按照如下方式处理：
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.LSXSFSRadio" label="1">
                        甲方应当在交付房屋之前完成修复,</el-radio>
                    并承担全部维修费用以及因为漏水给其他第三方造成的损失赔偿责任。如甲方未予修复，乙方在房屋交付后自行维修的， 甲方应承担全部维修费用；
                    因维修房屋影响乙方使用的，甲方应当赔付乙方因此所受的实际损失。但乙方使用不当，导致该房屋主体结构内发生漏水的不在本条款所约定之范围内。
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.LSXSFSRadio"
                        label="2">乙方自行维修,</el-radio>
                    由乙方自行承担全部维修费用，但是交接前因房屋漏水给其他第三方造成的损失仍由甲方承担相应责任。
                </p>
                </p>
                <p>（七）关于房屋公共维修基金甲乙双方协商一致，约定如下（三选一）：</p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.WXYDRadio" label="1">
                        签署合同当日甲方已出示</el-radio>维修基金发票或无需维修基金发票可直接办理基金过户的，甲方将维修基金无偿赠送乙方。
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.WXYDRadio" label="2">
                        签署合同当日甲方未出示</el-radio>维修基金发票，若甲方有维修基金发票或无需维修基金发票可直接办理维修基金过户的，则维修基金随房赠送，若出现以下情况之一的维修基金不赠送：
                </p>
                <p>1.甲方未缴纳维修基金；</p>
                <p>2.维修基金发票丢失、无法调档且维修基金无法过户。</p>
                <p>
                    注：如维修基金未缴纳或发票丢失影响本次房屋交易过户，则需要由甲方补缴，如不影响本次房屋交易过户则无需甲方补缴，甲乙双方知晓并认可；
                </p>
                <p>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.WXYDRadio" label="3">其他：
                        </el-radio>
                    </span><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.weiXiuJiJin_QT" />
                </p>
                <p>
                    （八）甲方应当保证已如事实陈述该房屋权属状况，附属设施设备、装修装饰情况和相关关系，补充协议及物业交割单所列的该房屋附属设施设备及装饰装修材料随同房屋一并转让给乙方，乙方对甲方出售的该房屋具体情况充分了解，自愿买受该房屋。甲方应当保证自本合同签订之日起至该房屋验收交接完成，对各项房屋附属设施设备及其装饰装修保持良好的状况。
                </p>
                <p>（九）乙方确认并承诺：</p>
                <p class="text-indent">
                    1、乙方及其代理人已经在甲方及中介方陪同下实地勘验房屋，并已对本次交易房屋的具体情况已经充分了解，包括但不限于房屋的地理位置、房屋自然情况、室内非承重结构改造及装饰装修情况、产权登记信息、周围环境等与房屋交易相关的一切事项，自愿以签约现状购买交易房屋。
                </p>
                <p class="text-indent">2、乙方及乙方配偶及其他共同买受人均知晓并认可本次房屋交易签署的相关合同约定，本次房屋交易提交的一切材料均真实、合法、有效。</p>
                <p class="text-indent">
                    3、乙方已详细知晓当下房地产交易的相关政策，中介方已经明确提醒需要具备购房资格事宜，本人承诺具备购房资格事宜，若因无购房资格导致交易无法履行，视为乙方违约，按照合同约定承担相应违约责任。</p>

                <h3 class="font_weight500">第六条 权属转移登记</h3>
                <p>
                    （一）乙方应于本合同签署后<input :disabled="flag" class="inputW_80px border_bottom"
                        v-model="form.qianShu_Day" />日内，备齐过户及贷款相关文件并办理贷款审核（若有）等相关手续，甲方需配合乙方办理贷款审核，若因甲方不配合造成乙方无法贷款，则甲方应按照第九条（一）承担违约责任。
                </p>
                <p>（二）甲方备件时间</p>
                <p>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.FWDYRadio" label="1">
                            该房屋未设定抵押</el-radio>
                    </span>：甲方在<!--签署本合同当日应将房屋所有权证原件相关权属证明原件放于中介方处并在--><input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.xiangGuanWenJian" />日内备齐交易相关文件；
                </p>
                <p>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.FWDYRadio" label="2">
                            该房屋已设定抵押</el-radio>
                    </span>：甲方自行还清贷款，提前还款产生的利息和费用，由甲方承担。
                </p>
                <p class="text-indent">
                    乙方申请商业贷款或组合贷款时:甲方须在乙方首付款资金监管或担保完成且商业贷款审批后
                    <input :disabled="flag" class="inputW_80px border_bottom" v-model="form.tongGuo_Day" />
                    个工作日内办理还款赎证等后续相关手续,包括但不限于注销抵押(若有)、申报税费等,双方另有约定的除外；
                </p>
                <p>（三）签约当日，甲方应将
                    <label v-for="item in yuanJianOptions" :label="item.name" :key="item.id">
                        <input :disabled="flag" :value="item.id" type="checkbox"
                            v-model="formCheck.checkedFangZhongJie" />{{ item.name }}
                    </label>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.fangZhongJieYuanJian" /> 放于中介方处；
                </p>
                <p>
                    (四)在甲方办理完毕还款赎证手续(如有)
                    ,且确认产权无抵押、查封、限制交易等情形,且贷款机构出具同意贷款通知(如乙方按揭贷款的)
                    ,且乙方将合同约定的在递交不动产权属转移登记申请资料前需缴纳款项支付完毕,上述条件全部达成后<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000"
                        v-model="form.daCheng_Day" />工作日内由甲乙双方向相关政府部门或机构缴纳各项税、费,并共同向房屋权属登记部门申请办理不动产权属转移登记手续，乙方应及时领取新产权证，签署相应借款合同（如有）等后续相关交易手续。
                </p>
                <p>
                    <!-- 甲乙双方承诺,上述手续办理过程中需要双方共同到场的,甲乙双方均保证如期到场办理。 -->
                    甲乙双方承诺,上述手续办理过程中需要到场的,甲乙双方均保证如期到场并配合办理。
                </p>

                <h3 class="font_weight500">第七条 税、费相关规定</h3>
                <p>
                    （一）甲乙双方协商一致，约定因房屋交易所发生的税费，<span class="font_weight">由甲方承担的税、费为：</span><label
                        v-for="item in JiaFangShuiFeiOptions" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedJiaJaFangShuiFei" />{{ item.name
                        }}</label><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.jf_QiTaShuiFei" />。
                    <span
                        class="font_weight">若因甲方原因（包括但不限于甲方承诺不实、隐瞒自有房屋状况或以家庭为单位的房屋所有状况）导致约定税种与实际缴纳不一致且拒不缴纳因此产生的其他税费，导致本合同无法继续履行的，由甲方承担违约责任。</span>
                </p>
                <p>
                    （二）甲乙双方协商一致，约定因房屋交易所发生的税费，<span class="font_weight">由乙方承担的税、费为：</span><label
                        v-for="item in YiFangShuiFeiOptions" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedYiFangShuiFei" />{{ item.name
                        }}</label><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.yf_QiTaShuiFei" />。
                </p>
                <p>
                    <span
                        class="font_weight">上述相关费用均按国家相关部门实际征收税种、税率标准为准；如在实际交易履行过程中遇有税费增收的，增收的税费仍由合同约定的承担方承担；遇有未尽说明或新增税种的，须依据国家有关规定确定承担税费的合同当事人。</span>甲乙双方<input
                        :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.shuifei_Day" />日应按照国家规定标准，如实缴纳所需税费，如房屋评估价格高于房屋成交价格，则需按评估价格标准缴纳相关税费；办理房屋权属过户等相关等相关业务产生的税（费）项、税（费）率、税（费）额均以税务部门、房地产交易市场等相关部门出具的单据为准，中介方提供的购房预算仅供参考，不作为缴纳上述相关费用的依据。
                </p>

                <h3 class="font_weight500">第八条 房屋交付</h3>
                <p>
                    （一）甲方须在乙方新不动产权证出证后 <input :disabled="flag" class="inputW_80px border_bottom"
                        v-model="form.fangKuan_Day" />
                    个工作日内负责将房屋以签约现状交付给乙方使用同时交付配套设施，包括但不限于车位、车库、储藏室、地下室、阁楼等（若有）。房屋交付时，甲方须保证该房屋地址下无任何户口存在，且将房屋的相关费用全部结清。
                    中介方协助甲乙双方办理该房屋所发生的水、电、煤气、暖气、有线电视、物业管理等过户手续及相关费用的交接事宜，上述相关项目如有历史欠费，均有甲方负责结清。如甲方已预交部分费用，乙方应将房屋交付后的费用向甲方结清，特殊约定除外。
                    <span class="font_weight">处于出租状态的房屋无法正常交接的，需由甲乙双方另行对交接事宜进行约定。</span>
                </p>
                <p>
                    （二）甲方随房交付给乙方的物品详见《随房交付物品协议》，乙方对随房交付物品的外观、质量、使用年限、是否存在瑕疵、质量为题等详见《随房交付物品协议》。
                </p>
                <p>
                    （三）该房屋以签约现状售于乙方，乙方同意按现状购买该房屋，故乙方签约后不得以房屋状况为由拒绝交易。
                </p>
                <p>
                    1.甲方与乙方共同对该房屋附属设备、装饰装修、相关物品清单等具体情况进行验收，记录水、电、燃气表的读数，甲乙双方在房屋附属设施设备、装饰装修、相关物品清单上签字；
                </p>
                <p>
                    2.煤气或燃气<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.MQRadio" label="1">已通
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.MQRadio" label="2">未通
                        </el-radio>
                    </span>，交付前煤气或燃气开通的费用承担为<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFQMQFRadio" label="1">甲方
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFQMQFRadio" label="2">乙方
                        </el-radio>
                    </span>，交付后煤气或燃气开通的费用承担为<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFHMQFRadio" label="1">甲方
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFHMQFRadio" label="2">乙方
                        </el-radio>
                    </span>
                </p>
                <p>
                    3.移交该<label v-for="item in FangMenYaoShiOptions" :label="item.name" :key="item.id"><input
                            :disabled="flag" :value="item.id" type="checkbox"
                            v-model="formCheck.checkedFangMenYaoShi" />{{
                item.name }}</label>
                </p>


                <h3 class="font_weight500">第九条 违约责任</h3>
                <p>（一）甲方违约责任:</p>
                <p class="text-indent">
                    <!-- 1.甲方未按照本合同条款及各项附件等约定履行的,每逾期一个自然日需向乙方支付总房款万分之五的赔偿金并应继续履行。 -->
                    1.甲方未按照本合同条款及各项附件等约定履行的,每逾期一个自然日需按照如下标准向乙方支付赔偿金并应继续履行。
                </p>
                <p style="text-indent: 15px;">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFWYZRRadio"
                        label="1">总房款万分之五</el-radio>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFWYZRRadio"
                            label="2">其他：</el-radio></span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.JFweiyuepeichang_QT" />
                </p>
                <p class="text-indent">
                    2.甲方发生下列情形之一的,视为甲方根本违约,乙方有权以书面通知方式解除本合同;甲方应自本合同解除之日起十个工作日内无息退还乙方已支付的房价款,并承担违约责任,乙方有权要求甲方双倍返还乙方已支付定金或向乙方支付总房款的20%作为违约赔偿金。如合同约定违约金不足以弥补乙方损失的,乙方有权向甲方主张因甲方的违约行为导致的全部损失。
                </p>
                <p>
                    (1)甲方末按照本合同条款及各项附件等约定履行的,逾期超过十个工作日的;
                </p>
                <p>
                    (2)甲方提供的该房屋所有权证,原购房合同等相关产权证明手续不真实、不完整、无效,导致乙方无法取得房屋所有权的;
                </p>
                <p>(3)甲方签订本合同后又将该房屋租赁给第三方的;</p>
                <p>(4)该房屋签后被查封或限制转让,导致乙方无法取得该房屋所有权的;</p>
                <p>(5)甲方将该房屋出售给第三人的。</p>
                <p>(6)甲方拒绝继续出售该房屋的。</p>
                <p>如若甲方出现以上情形之一的，乙方有权要求甲方按照如下标准向乙方支付违约赔偿金。如合同约定违约金不足以弥补乙方损失的，乙方有权向甲方主张因甲方的违约行为导致的全部损失。</p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFWYSSRadio"
                            label="1">双倍返还乙方已支付定金</el-radio></span>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFWYSSRadio"
                            label="2">总房款的20%</el-radio></span>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFWYSSRadio"
                            label="3">其他：</el-radio></span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.JFsunshipeichang_QT" />
                </p>
                <p>(二)乙方违约责任:</p>
                <!-- <p class="text-indent">
                    1.乙方未按照本合同条款及各项附件等约定履行的,每逾期一个自然日需向甲方支付总房款万分之五的赔偿金并应继续履行。
                </p> -->
                <p class="text-indent">
                    1.乙方未按照本合同条款及各项附件等约定履行的,每逾期一个自然日需按照如下标准向甲方赔偿金并应继续履行。
                </p>
                <p style="text-indent: 15px;">
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFWYZRRadio"
                        label="1">总房款万分之五</el-radio>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFWYZRRadio"
                            label="2">其他：</el-radio></span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.YFweiyuepeichang_QT" />
                </p>
                <p class="text-indent">
                    2.乙方发生下列情形之一的,视为乙方根本违约,甲方有权以书面通知的方式解除本合同;乙方承担违约责任甲方有权不予退还乙方已支付的定金或有权要求乙方支付总房款的20%作为违约赔偿金。合同约定违约金不足以弥补甲方损失的,甲方有权向乙方主张因乙方的违约行为导致的全部损失。
                </p>
                <p>
                    (1)乙方未按照本合同条款及各项附件等约定履行的,逾期超过十个工作日的;
                </p>
                <p>
                    (2)乙方提供的证件等购房所需的资料不完整、不真实、无效或不具备该房屋的购买资质的,导致无法办理房屋所有权转移登记手续的;
                </p>
                <p>(3)乙方拒绝购买该房屋的。</p>
                <p>如若乙方出现以上情形之一的，甲方有权要求乙方按照如下标准向甲方支付违约赔偿金。如合同约定违约金不足以弥补甲方损失的，甲方有权向乙方主张因乙方的违约行为导致的全部损失。</p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFWYSSRadio"
                            label="1">不予退还乙方已支付定金</el-radio></span>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFWYSSRadio"
                            label="2">总房款的20%</el-radio></span>
                </p>
                <p style="text-indent: 15px;">
                    <span><el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFWYSSRadio"
                            label="3">其他：</el-radio></span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.YFsunshipeichang_QT" />
                </p>


                <p>
                    <span
                        class="font_weight">3.如房屋总房款包含房屋价格、该房屋配套设施、房屋装饰装修及家具家电(若有)等,乙方认可该部分与房屋交易为不可分割关系,乙方在签署本合同后不得拒绝接受家具、家电装饰装修及配套设施设备或要求该部分降低价格,否则甲方有权视为乙方违约。</span>
                </p>
                <p>
                    (三)合同签署后因甲乙双方原因导致本合同无法履行,致使中介方不能继续履行义务,中介方不承担责任,且中介方有权收取本合同约定的中介代理费用;违约方或过错方应承担本次交易的中介代理费,若本次交易的中介代理费由守约方缴纳,则守约方有权向违约方予以追缴。(若本条与中介方服务承诺内容不一致时,以中介方服务承诺内容为准)。
                </p>
                <p>
                    (四)甲乙双方因履行合同而产生争议或纠纷时,双方均同意由中介方代为保管手续文件,直至甲乙双方达成已付款项及手续文件的书面处理意见或人民法院已做出生效判决、裁定,中介方将根据甲乙双方的一致书面意见或人民法院的相关法律文书处理手续文件。甲乙双方不得单方到中介方处要求中介方处置代为保管的文件。
                </p>

                <h3 class="font_weight500">第十条 不可抗力</h3>
                <!-- <p>
                    因不可抗力不能按照约定履行本合同的,根据不可抗力的影响,部分或全部免除责任,但因不可抗力不能按照约定履行合同的一方当事人应当及时告知另一方当事人,并自不可抗力事件结束之日起30日内向另一方当事人提供证明。上述房屋风险责任自该房屋
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BKKLRadio" label="1">所有权转移
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BKKLRadio" label="2">
                            转移占有之日起转移</el-radio>
                    </span>给乙方。
                </p> -->
                <p>
                    因不可抗力不能按照约定履行本合同的,根据不可抗力的影响,部分或全部免除责任,但因不可抗力不能按照约定履行合同的一方当事人应当及时告知另一方当事人,并自不可抗力事件结束之日起30日内向另一方当事人提供证明。
                    如因不可抗力导致合同不能履行或不能实现合同目的，各方可无责解除本合同。不可抗力事件包括但不限于自然灾害、政府行为、社会异常事件等不能预见、不能避免且不能克服的客观情况。
                </p>
                <P>
                    若发生上述情形,房屋风险责任自该房屋
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BKKLRadio" label="1">所有权转移
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.BKKLRadio" label="2">
                            转移占有之日起转移</el-radio>
                    </span>给乙方。
                </P>

                <h3 class="font_weight500">第十一条 争议解决方式</h3>
                <!-- <p> (一)本合同项下发生的争议,由双方协商解决;协商不成的,依法向房屋所在地人民法院起诉。 </p> -->
                <p>本合同项下发生的争议,由双方协商解决;协商不成的,依法向房屋所在地人民法院起诉。 </p>
                <!-- <p>(二)若甲乙双方因本合同产生诉讼,则产生的律师费承担方式选择如下:</p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.LSFRadio" label="1">
                        律师费由违约方承担(请在起诉状中增加本诉求)</el-radio>
                </p>
                <p>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.LSFRadio" label="2">律师费甲乙双方各自承担
                    </el-radio>
                </p> -->

                <h3 class="font_weight500">
                    第十二条
                    本合同自双方签字（盖章）或按指印之日起生效,双方可以根据具体情况对本合同中未约定、约定不明或不适用的内容签订书面补充协议进行变更或补充。对本合同的解除,应当采用书面形式,本合同附件及补充协议具有同等法律效力。
                </h3>

                <h3 class="font_weight500">
                    第十三条
                    本合同一式三份,具有同等法律效力,其中甲方一份;乙方一份,中介成交的房地产经纪机构一份;双方办理转移登记时,应向房屋权属登记部门提交主合同一份。
                </h3>

                <h3 class="font_weight500">第十四条 补充条款</h3>

                <p>
                    (二)若本合同内容与网上签约合同(网签合同)内容不一致的,以本合同为准。
                </p>
                <p>
                    (三)其他约定:<span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.QTYDRadio" label="1">有
                        </el-radio>
                    </span><span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.QTYDRadio" label="2">无
                        </el-radio>
                    </span>
                    <!-- <span class="font_weight">1.甲乙双方协商一致,若因疫情原因导致一方当地或双方地限制人员流动包括不限于到异地需要隔离等情形,无法到房屋交易手续当地参加相关交易手续的,双方均同意延期至一方当地或双方当地不限制流动,不限制隔离情形后3个工作日内继续办理手续,此期间双方互不追充因此延期的违约责任。</span> -->
                </p>
            </div>

            <div class="quote_info clearfix font_weight500">
                <div class="box1">（以下无正文）</div>
                <h3 class="font_weight500">
                    甲方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangZ3" />
                </h3>
                <h3 class="font_weight500">
                    甲方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangWeiTuoZ1" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi1" />
                </h3>
                <br />
                <h3 class="font_weight500">
                    乙方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangZ3" />
                </h3>
                <h3 class="font_weight500">
                    乙方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangWeiTuoZ1" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi2" />
                </h3>
            </div>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">中介服务合同</h2>
                <h3 class="font_weight500">
                    甲方（出售方）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFang2" />
                </h3>
                <h3 class="font_weight500">
                    乙方（购买方）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFang2" />
                </h3>
                <h3 class="font_weight500">
                    丙方（中介方）：<input :disabled="flag" class="inputW_23 input_borderBottom_000" v-model="form.bingFang1"
                        @blur="fun" />
                </h3>
                <p class="text-indent">
                    鉴于甲、乙双方在丙方的中介服务下就坐落于<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.fangWu_Address2" />的房屋，于<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.heTongQianDing_Year1"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.heTongQianDing_Month1" :maxlength="2"
                        @blur="rulesDate(9, 'heTongQianDing_Month1')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.heTongQianDing_Day1" :maxlength="2"
                        @blur="rulesDate(10, 'heTongQianDing_Day1')" />日签订了《大连市存量房屋买卖合同》（以下简称“《买卖合同》”）。甲、乙、丙三方在友好协商的基础上，订立本中介服务合同，以兹共同遵守。
                </p>

                <h3 class="font_weight500">第一条 中介服务</h3>
                <p>
                    （一）甲、乙双方同意，共同委托丙方作为交易中介人，丙方提供的中介服务包括：1.提供房屋买卖市场行情咨询；2.寻找、提供并发布房源、客源信息；3.引领购买方实地看房；4.协助查看房屋权属证明文件；5.协助交易双方协商房屋买卖合同的相关条款内容；6.促成交易双方签署合法有效的买卖合同。
                </p>
                <p>
                    （二）甲、乙双方签订《买卖合同》时，中介行为完成，甲、乙双方应向丙方支付本合同约定的中介费。
                </p>

                <h3 class="font_weight500">第二条 中介费</h3>
                <p class="text-indent">
                    甲、乙双方签订《买卖合同》时，视为丙方中介行为完成，即丙方中介成功，甲、乙双方应向丙方支付中介费，即应向丙方支付的中介费为人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.zhongJieFei_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('zhongJieFei_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.zhongJieFei_DaXieYuan" />；
                </p>
                <p class="text-indent">
                    其中：由甲方承担人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.jfzjf_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('jfzjf_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.jfzjf_DaXieYuan" />，甲方同意，在本合同签订当日向丙方自行支付人民币（小写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.jfzxzf_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('jfzxzf_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.jfzxzf_DaXieYuan" />；在乙方通过支付存管定金划转至甲方支付账户当日，甲方委托第三方存管机构自甲方支付账户向丙方支付人民币（小写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000" v-model="form.jfwtzf_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('jfwtzf_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000"
                        v-model="form.jfwtzf_DaXieYuan" />，（因任何原因该部分中介费未能成功支付至丙方的，甲方应在前述情形发生之日起三日内将该部分中介费直接自行支付至丙方）
                </p>
                <p class="text-indent">
                    由乙方承担人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.yfzjf_XiaoXieYuan"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        @blur="moneyRule('yfzjf_XiaoXieYuan')" />元，（大写）<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.yfzjf_DaXieYuan" />，并应于本合同签订当日向丙方支付。
                </p>

                <h3 class="font_weight500">第三条 权利义务</h3>
                <p>
                    （一）甲方和乙方应积极配合丙方的中介活动，按照丙方的要求提交房屋买卖所需的一切证书、证件及材料，并依照本合同的约定，及时足额向丙方支付中介费；甲、乙双方应保证其提交的资料及签名真实、合法、有效。
                </p>
                <p>
                    （二）在甲方收到全部购房款前，甲乙双方均同意由丙方保管过户至乙方名下的权属登记受理通知单、契税完税凭证、银行批准贷款文件（批贷函）、《不动产权证书》、他项权利证等相关资料原件，并同意丙方留存复印件，且甲乙双方均应积极配合完成。在甲方收到全部购房款且房屋交易履行完毕后十日内，甲乙双方应领取其名下所属的材料原件，如未及时领取的则由未领取方承担相关责任。
                </p>
                <!-- <p>
                    （三）为更好的提供服务，甲、乙双方同意并授权丙方及第三方将买卖合同的主体信息、房屋信息、签约信息、资金信息等同步给甲、乙双方提供资金转/托、存管服务的服务方或担保方；如乙方未贷款购房的，甲、乙双方同意并授权丙方及第三方将签署信息同步给评估机构、贷款机构等相关方。同时，甲、乙双方同意，为其提供资金划转/托、存管服务的服务方或担保方、评估机构、贷款机构等相关方可将相关资金存管办理结果、担保服务办理结果、资金划转情况、评估结果信息、贷款结果信息等同步给丙方及第三方，丙方及第三方有权接收。
                </p> -->
                <p>
                    （三）为更好的提供服务，甲、乙双方同意并授权丙方及第三方将买卖合同的主体信息、房屋信息、签约信息、资金信息等同步给甲、乙双方提供资金转/托、存管服务的服务方或担保方；如乙方为贷款购房的，甲、乙双方同意并授权丙方及第三方将前述信息同步给评估机构、贷款机构等相关方。同时，甲、乙双方同意，为其提供资金划转/托、存管服务的服务方或担保方、评估机构、贷款机构等相关方可将相关资金存管办理结果、担保服务办理结果、资金划转情况、评估结果信息、贷款结果信息等同步给丙方及第三方，丙方及第三方有权接收。
                </p>
                <p>
                    （四）甲、乙双方知晓并同意，协议中所涉及的签后服务，并丙方委托第三方协助办理，因办理签后服务所需要的全部资料、信息（包括但不限于签署买卖合同的主体信息、买卖合同的签署信息、房屋交易资金架构信息）
                </p>
                <p>
                    （五）丙方应遵守相关法律规定，尽职提供中介服务；丙方不得在交易中提供虚假信息或故意隐瞒真实情况。
                </p>

                <h3 class="font_weight500">第四条 违约责任</h3>
                <p>
                    （一）甲、乙双方利用丙方所提供信息、条件、机会等，私自或者另行通过其他中介方签署《买卖合同》，甲乙双方应当承担违约责任；丙方有权要求甲乙双方支付本合同约定的全部中介费。
                </p>
                <p>
                    （二）甲、乙双方或乙方延迟向丙方支付本协议第二条约定的中介费，该延迟方除应向丙方支付中介费外，还应就其未支付的部分，按照每日1%的标准支付违约金。
                </p>
                <p>
                    （三）在中介服务中，丙方因工作疏漏、遗失甲、乙双方相关文件及资料，丙方承担补办手续费并给与相应的经济补偿、丙方违反本合同第三条约定的，丙方应承担相应的违约责任；<span
                        class="font_weight">甲方或乙方违反本合同第三条约定，导致丙方不能继续履行义务的，丙方不承担责任，且丙方有权收取本合同约定的中介费用，无需退还，</span>如支付费用方为守约方，可向违约方追偿该笔费用。
                </p>
                <!-- <p>
                    （四）甲、乙双方中任何一方拖延或拒绝配合不办交易中各种手续，造成另一方损失，由拖延或拒绝配合一方承担赔偿责任；各方不得擅自单方解除或变更本协议，本合同另有约定的除外。
                </p> -->
                <p>
                    （四）甲、乙双方中任何一方拖延或拒绝配合补办交易中各种手续，造成另一方损失，由拖延或拒绝配合一方承担赔偿责任；各方不得擅自单方解除或变更本协议，本合同另有约定的除外。
                </p>

                <h3 class="font_weight500">第五条 争议解决</h3>
                <p>
                    与本合同相关的任何争议，三方应协商解决；协商不成的，任何一方均可向房屋所在地的人民法院提起诉讼。
                </p>

                <h3 class="font_weight500">第六条 其他</h3>
                <p>
                    本合同一式三份，三方各执一份，自各方签署盖章或按指印之日起生效。各方同意，未经本人签字或加盖公章的任何书面承诺、口头承诺、条款的变更等行为均不产生法律约束力。
                </p>
            </div>

            <div class="quote_info clearfix font_weight500">
                <p>（以下无正文）</p>
                <p>（以下为《中介服务合同》的签字页）</p>
                <h3 class="font_weight500">
                    甲方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangZ4" />
                </h3>
                <h3 class="font_weight500">
                    甲方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangWeiTuoZ2" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi3" />
                </h3>
                <br />
                <h3 class="font_weight500">
                    乙方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangZ4" />
                </h3>
                <h3 class="font_weight500">
                    乙方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangWeiTuoZ2" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi4" />
                </h3>
                <br />
                <p>
                    丙方（章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.bingFangZ1" />
                </p>
                <p>
                    地址：<input :disabled="flag" class="inputW_150px input_borderBottom_000" v-model="form.diZhi1" />
                </p>
                <br />
                <p>
                    经纪执业人员签字1：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.JJZYRYQZ1" /><span class="margin_left_100">资格注册证书编号1：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.ZGZCZSBH1" /></span>
                </p>
                <p>
                    经纪执业人员签字2：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.JJZYRYQZ2" /><span class="margin_left_100">资格注册证书编号2：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.ZGZCZSBH2" /></span>
                </p>
                <p>
                    经办人签字：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.LBRQZ1" /><span class="margin_left_100">联系电话：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.LXDH1" :maxlength="11"
                            @blur="rulesMobile(1)" /></span>
                </p>
                <p>
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi5" />
                </p>
            </div>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">保障服务合同</h2>
                <p>
                    甲方（出售方）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFang4" />
                </p>
                <p>
                    乙方（购买方）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFang4" />
                </p>
                <p>
                    丙方（中介方）：<input :disabled="flag" class="inputW_23 input_borderBottom_000" v-model="form.bingFang2" />
                </p>
                <p class="text-indent">
                    鉴于甲、乙双方在丙方的中介服务下就坐落于<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.fangWu_Address3" />之房屋的交易签署了《大连市存量房屋买卖合同》（以下简称“《买卖合同》”），现甲、乙双方就委托丙方办理后续交易、贷款事宜订立本合同。
                </p>

                <h3 class="font_weight500">第一条 服务内容</h3>
                <!-- <p>
                    本合同约定的房地产交易保障服务是指丙方本着诚实守信、尽职尽责的原则，在甲、乙双方房地产交易过程中，为保障交易流程的顺利进行，负责办理交易方所需的各项服务、及时督促交易方履行应尽业务，并协调处理交易履行中产生的争议或纠纷。
                </p> -->
                <p>本合同约定的房地产交易保障服务是指丙方本着诚实守信、尽职尽责的原则，在甲、乙双方房地产交易过程中，为保障交易流程的顺利进行，负责办理交易方所需的各项服务、及时督促交易方履行应尽义务，并协调处理交易履行中产生的争议或纠纷。
                </p>
                <p class="text-indent">具体服务内容如下：</p>
                <p>（一）代为保管与交易相关的产权资料及其他重要材料；</p>
                <p>（二）协助办理房地产报税手续；</p>
                <p>（三）协助办理房地产权属转移登记手续；</p>
                <p>（四）对个人资信状况进行预审查，合理推荐贷款银行；</p>
                <p>
                    （五）办理贷款银行或公积金管理中心按揭贷款手续，及时跟进审批进度，跟进贷款发放进度；
                </p>
                <p>（六）办理领取房屋所有权证，不动产权证；</p>
                <p>（七）协助办理房屋交易资金监管服务；</p>
                <p>（八）协助办理公证手续；</p>
                <p>（九）协助办理房屋评估手续；</p>
                <p>（十）协助办理产权信息变更；</p>
                <p>（十一）协助办理调取档案手续；</p>
                <p>（十二）协助发送履约催告函件或告知函件；</p>
                <p>（十三）协助处理交易履行中产生的争议或纠纷；</p>
                <!-- <p>（十四）及时催促甲、乙双方按约定履行合同义务。</p> -->
                <p>（十四）及时督促甲、乙双方按约定履行合同义务。</p>

                <h3 class="font_weight500">第二条 收费标准</h3>
                <p>
                    （一）乙方申请贷款方式为：<span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="1">商业贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="2">公积金贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="3">组合贷款</el-radio>
                    </span><span>
                        <el-radio :disabled="flag" style="text-indent: 0" class="font_weight500"
                            v-model="formCheck.DKFSRadio" label="4">部队公积金贷款</el-radio>
                    </span>；
                </p>
                <p>
                    （二）乙方申请商业贷款金额人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.sydk_XiaoXieYuan" @blur="moneyRule('sydk_XiaoXieYuan')" />元，（大写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000" v-model="form.sydk_DaXieYuan" />；
                </p>
                <p class="text-indent-44">
                    乙方申请公积金贷款金额人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.gjjdk_XiaoXieYuan" @blur="moneyRule('gjjdk_XiaoXieYuan')" />元，（大写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000" v-model="form.gjjdk_DaXieYuan" />；
                </p>
                <p>
                    （三）保障服务费人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.bzfwf_XiaoXieYuan" @blur="moneyRule('bzfwf_XiaoXieYuan')" />元整，（大写）<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.bzfwf_DaXieYuan" />，乙方须在本合同签订之日支付丙方；办理贷款所需的费用由乙方自行承担，甲乙双方另有约定除外。
                </p>
                <p>
                    （四）如在本合同履行过程中遇到相关部门收费调整，按收费部门规定由乙方自行承担。
                </p>
                <p class="text-indent">
                    备注：以上款项不包含公积金管理中心收取的贷款相关费用。
                </p>

                <h3 class="font_weight500">第三条 权利义务</h3>
                <p>
                    （一）甲、乙双方应遵守本合同的约定、政府相关部门和贷款银行或公积金管理中心的相关规定，积极办理各项手续，并及时向丙方提交所需的全部资料，包括但不限于身份证及户口证明、婚姻证明、房屋权属证明、收入证明等，资料提交方应保证其提供的资料真实、合法、有效；
                </p>
                <p>
                    （二）服务过程中，甲、乙双方应按照贷款银行或公积金管理中心或政府相关部门要求到场办理相关手续；
                </p>
                <p>
                    （三）甲乙双方应向丙方留有有效的联系地址、联系电话、电子邮箱，以便丙方可以及时、畅通地联系甲乙双方。如遇有联系地址、联系电话、电子邮箱变更或失效的，甲乙双方应及时书面通知丙方。丙方的书面通知以甲乙双方在《房地产买卖合同》中填写的联系地址寄出挂号信或快递后三日即视为送达。
                </p>

                <h3 class="font_weight500">第四条 违约责任</h3>
                <p>
                    （一）甲、乙双方如未按照本合同约定履行义务，导致合同履行延迟或无法履行的，违约方应承担违约责任，并赔偿守约方的损失；
                </p>
                <p>
                    （二）甲、乙双方未按本合同约定支付服务费，除应足额向丙方支付其欠缴的服务费外，还需对未支付部分，按照每日万分之一的标准支付违约金；
                </p>
                <p>
                    （三）甲、乙双方中任何一方违反买卖合同导致本合同无法履行的，违约方应向守约方承担违约责任，并赔偿守约方已支付的费用。丙方已收取的服务费不予退还；
                </p>
                <p>
                    （四）在服务过程中，丙方因工作疏漏，遗失甲、乙双方相关文件及资料，丙方承担补办手续费并给予相应的经济补偿。甲、乙双方中任何一方拖延或拒绝配合补办交易中各项手续，造成另一方损失，由拖延或拒绝配合一方承担赔偿责任。
                </p>

                <h3 class="font_weight500">第五条 争议解决</h3>
                <p class="text-indent">
                    与本合同相关的任何争议，三方应协商解决；协商不成的，任何一方均可向房屋所在地的人民法院提起诉讼。
                </p>

                <h3 class="font_weight500">第六条 其他</h3>
                <p class="text-indent">
                    本合同一式三份，三方各执一份，自各方签署盖章或按指印之日起生效。
                </p>
            </div>
            <div class="quote_info clearfix font_weight500">
                <p>（以下无正文）</p>
                <p>（以下为《中介服务合同》的签字页）</p>
                <h3 class="font_weight500">
                    甲方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangZ6" />
                </h3>
                <h3 class="font_weight500">
                    甲方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiaFangWeiTuoZ3" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi6" />
                </h3>
                <br />
                <h3 class="font_weight500">
                    乙方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangZ6" />
                </h3>
                <h3 class="font_weight500">
                    乙方委托代理人/法定代理人（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangWeiTuoZ3" />
                </h3>
                <h3 class="font_weight500">
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi7" />
                </h3>
                <br />
                <p>
                    丙方（章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.bingFangZ2" />
                </p>
                <p>
                    地址：<input :disabled="flag" class="inputW_150px input_borderBottom_000" v-model="form.diZhi2" />
                </p>
                <br />
                <p>
                    经纪执业人员签字1：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.JJZYRYQZ3" /><span class="margin_left_100">资格注册证书编号1：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.ZGZCZSBH3" /></span>
                </p>
                <p>
                    经纪执业人员签字2：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.JJZYRYQZ4" /><span class="margin_left_100">资格注册证书编号2：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.ZGZCZSBH4" /></span>
                </p>
                <p>
                    经办人签字：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.LBRQZ2" /><span class="margin_left_100">联系电话：<input :disabled="flag"
                            class="inputW_150px input_borderBottom_000" v-model="form.LXDH2" :maxlength="11"
                            @blur="rulesMobile(2)" /></span>
                </p>
                <p>
                    签署日期：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.qianShuRiQi8" />
                </p>
                <br />
                <p>
                    <span class="font_weight">附：定金收付收据，由房屋出售人在实际收到定金时签署。</span>
                </p>
            </div>

            <div class="header margin_top_150">
                <img src="../../../../public/img/logo4.jpg" />
            </div>
            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">网上签约授权委托书</h2>
                <p>
                    甲方（出售方）委托人：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jf_WTR" />
                </p>
                <p>
                    乙方（购买方）委托人：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yf_WTR" />
                </p>
                <p>
                    受托人：<input :disabled="flag" class="inputW_150px input_borderBottom_000" v-model="form.STR" />
                </p>
                <p>
                    第一条
                    委托事项：委托人作为交易房产的买卖双方当事人，现委托受托人作为我们的合法代理人协助我们办理交易房产的存量房屋买卖合同网上签约手续。
                </p>
                <p>
                    第二条 委托期限：自本授权委托书签署之日起至委托事项办理完结之日止。
                </p>
                <p>第三条 委托网上签约内容如下：</p>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_100 text-c">房屋出卖人信息</li>
                </div>
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_XM" />
                    </li>
                    <li class="width_10">证件名称</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_ZJMC" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_ZJHM" @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">共有权人</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_GYQR" />
                    </li>
                    <li class="width_10">证件名称</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_GYQRZJMC" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.cmr_GYQRZJHM"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_100 text-c">房屋买受人信息</li>
                </div>
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_XM" />
                    </li>
                    <li class="width_10">证件名称</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_ZJMC" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_ZJHM" @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">共有购买人</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_GYGMR" />
                    </li>
                    <li class="width_10">证件名称</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_GYGMRZJMC" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.msr_GYGMRZJHM"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_100 text-c">交易房屋信息</li>
                </div>
                <div class="display_flex">
                    <li class="width_10">交易房屋地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.JYFWDZ" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">成交价</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.CJJ" @blur="moneyRule('CJJ')" />
                    </li>
                </div>
            </div>
            <br />
            <div class="quote_info clearfix font_weight500">
                <p>
                    甲方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiafangZ8" />
                </p>
                <p>
                    乙方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangZ8" />
                </p>
                <p>
                    本委托书签署日期：<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.qianShu_Year3" :maxlength="4" />年<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month3" :maxlength="2"
                        @blur="rulesDate(11, 'qianShu_Month3')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Day3" :maxlength="2"
                        @blur="rulesDate(12, 'qianShu_Day3')" />日
                </p>
            </div>

            <div class="quote_info clearfix font_weight500">
                <!-- <h2 class="h2_title margin_top_150">补充协议说明</h2> -->
                <h2 class="h2_title margin_top_150">补充协议</h2>
                <span>经买卖双方协商一致<el-input type="textarea" v-model="form.bcxysm" :rows="4"></el-input></span>
                等事宜。
            </div>

            <div class="quote_info clearfix font_weight500">

                <p>
                    甲方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.jiafangZ8" />
                </p>
                <p>
                    乙方（签章）：<input :disabled="flag" class="inputW_150px input_borderBottom_000"
                        v-model="form.yiFangZ8" />
                </p>
                <p>
                    居间方：<input :disabled="flag" class="inputW_150px input_borderBottom_000" v-model="form.JJF" />
                </p>
            </div>

        </el-card>
        <!-- </div> -->
    </div>
</template>
<script>
import Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import JsZipUtils from "jszip-utils"
import FileSaver from "file-saver"
import NewMaiMaiTemplate from "./NewMaiMaiTemplate.js"
import QianYueJs from "../QianYue"
import url from "../../../config/url.js"
import { Loading } from "element-ui"
import axios from "axios"
import { Message } from "element-ui"

export default {
    inject: ["reload"],
    data() {
        return {
            loading: false,
            disabled: true,
            flag: false,
            // contractId: this.$route.query.contractId,
            userId: localStorage.getItem("userId"),
            form: NewMaiMaiTemplate.form,
            formCheck: NewMaiMaiTemplate.formCheck,
            JiBenQingKuangOptions: NewMaiMaiTemplate.JiBenQingKuangOptions,
            JiBenQingKuangList: NewMaiMaiTemplate.JiBenQingKuangList,
            FangWuQuanShuOptions: NewMaiMaiTemplate.FangWuQuanShuOptions,
            FangWuQuanShuList: NewMaiMaiTemplate.FangWuQuanShuList,
            JiaFangTongYiOptions: NewMaiMaiTemplate.JiaFangTongYiOptions,
            JiaFangTongYiList: NewMaiMaiTemplate.JiaFangTongYiList,
            FangMenYaoShiOptions: NewMaiMaiTemplate.FangMenYaoShiOptions,
            yuanJianOptions: NewMaiMaiTemplate.yuanJianOptions,
            FangMenYaoShiList: NewMaiMaiTemplate.FangMenYaoShiList,
            FangZhongJieList: NewMaiMaiTemplate.FangZhongJieList,
            JiaFangShuiFeiOptions: NewMaiMaiTemplate.JiaFangShuiFeiOptions,
            JiaFangShuiFeiList: NewMaiMaiTemplate.JiaFangShuiFeiList,
            YiFangShuiFeiOptions: NewMaiMaiTemplate.YiFangShuiFeiOptions,
            YiFangShuiFeiList: NewMaiMaiTemplate.YiFangShuiFeiList,

            DingZhuanYongOption: NewMaiMaiTemplate.DingZhuanYongOption,
            DingZhuanYongList: NewMaiMaiTemplate.DingZhuanYongList,
            fileList: [],
            MyHeader: {
                currentRole: "",
                accessToken: "",
                fp: ""
            }
        }
    },
    props: {
        formData: {
            type: Object,
            default: {}
        },
        form1: {
            type: Object,
            default: {}
        },
        contractId: {
            type: String,
            default: ""
        },
        state: {
            type: String,
            default: ""
        },
        contractStatus: {
            type: String,
            default: ""
        }
    },
    created() {
        if (this.contractStatus >= "20") {
            this.flag = true
        }
        this.MyHeader.accessToken = localStorage.getItem("accessToken") //获取accessToken
        this.MyHeader.fp = localStorage.getItem("fp") //浏览器指纹fp
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        console.log("this.formData", this.formData)
        var d = new Date()
        this.form.gaoZhi_Year1 = this.form.chuShou_Year = this.form.gouMai_Year = this.form.heTongQianDing_Year1 = this.form.qianShu_Year2 = this.form.qianShu_Year3 = d.getFullYear()
        this.form.gaoZhi_Month1 = this.form.chuShou_Month = this.form.gouMai_Month = this.form.heTongQianDing_Month1 = this.form.qianShu_Month2 = this.form.qianShu_Month3 =
            d.getMonth() + 1
        this.form.gaoZhi_Day1 = this.form.chuShou_Day = this.form.gouMai_Day = this.form.heTongQianDing_Day1 = this.form.qianShu_Day2 = this.form.qianShu_Day3 = d.getDate()
        this.form.qianShuRiQi1 = this.form.qianShuRiQi2 = this.form.qianShuRiQi3 = this.form.qianShuRiQi4 = this.form.qianShuRiQi5 = this.form.qianShuRiQi6 = this.form.qianShuRiQi7 = this.form.qianShuRiQi8 =
            d.getFullYear() +
            "年" +
            (d.getMonth() + 1) +
            "月" +
            d.getDate() +
            "日"
        this.form.contractNo1 = this.formData.contractNo
        // this.form.contractNo2 = this.formData.contractNo;
        this.form.jiaFang1 = this.formData.sellerName
        this.form.sellerName = this.formData.sellerName
        this.form.chuShou1 = this.formData.sellerName
        this.form.jiaFang2 = this.formData.sellerName
        // this.form.jiaFang3 = this.formData.sellerName;
        this.form.jiaFang4 = this.formData.sellerName
        // this.form.chuShouRen = this.formData.sellerName;
        this.form.jf_WTR = this.formData.sellerName
        this.form.cmr_XM = this.formData.sellerName
        this.form.yiFang1 = this.formData.buyerName
        this.form.buyerName = this.formData.buyerName
        this.form.gouMai1 = this.formData.buyerName
        this.form.yiFang2 = this.formData.buyerName
        // this.form.yiFang3 = this.formData.buyerName;
        this.form.yiFang4 = this.formData.buyerName
        // this.form.maiShouRen = this.formData.buyerName;
        this.form.yf_WTR = this.formData.buyerName
        this.form.msr_XM = this.formData.buyerName
        this.form.fangWu_Address1 = this.formData.contractHouseAddress
        this.form.fangWu_Address2 = this.formData.contractHouseAddress
        this.form.fangWu_Address3 = this.formData.contractHouseAddress
        this.form.JYFWDZ = this.formData.contractHouseAddress
        // this.form.quanShuZhengHao = this.formData.houseNo;
        // this.form.quanShu = this.formData.quanShu
        // this.form.fangWuYongTu = this.formData.fangWuYongTu
        this.form.fangWu_MianJi = this.formData.square
        // this.form.chuShou_Address = this.formData.contractHouseAddress;
        this.form.sellerIdCardNo = this.formData.sellerIdCardNo
        this.form.cmr_ZJHM = this.formData.sellerIdCardNo
        // this.form.sellerMobile = this.formData.sellerMobile;
        this.form.buyerIdCardNo = this.formData.buyerIdCardNo
        this.form.msr_ZJHM = this.formData.buyerIdCardNo
        // this.form.buyerMobile = this.formData.buyerMobile;
        this.form.companyName = this.formData.companyName
        this.form.bingFang1 = this.formData.companyName
        this.form.bingFang2 = this.formData.companyName
        if (parseInt(this.form.sellerIdCardNo.slice(-2, -1)) % 2 == 1) {
            this.form.chuShou_Sex = "男"
        } else {
            this.form.chuShou_Sex = "女"
        }
        if (parseInt(this.form.buyerIdCardNo.slice(-2, -1)) % 2 == 1) {
            this.form.gouMai_Sex = "男"
        } else {
            this.form.gouMai_Sex = "女"
        }
        this.form.chuShou_ChuShengRiQi = this.form.sellerIdCardNo
            .slice(6, 14)
            .replace(/(.{4})(.{2})/, "$1.$2.")
        this.form.gouMai_ChuShengRiQi = this.form.buyerIdCardNo
            .slice(6, 14)
            .replace(/(.{4})(.{2})/, "$1.$2.")
        if (JSON.stringify(this.form1) != "{}") {
            this.form = this.form1

            this.formCheck.checkedDingZhuanYong = this.form.checkedDingZhuanYong

            this.formCheck.checkedJiBenQingKuang = this.form.checkedJiBenQingKuang
            this.formCheck.checkedFangWuQuanShu = this.form.checkedFangWuQuanShu
            this.formCheck.checkedJiaFangTongYi = this.form.checkedJiaFangTongYi
            this.formCheck.checkedFangMenYaoShi = this.form.checkedFangMenYaoShi
            this.formCheck.checkedFangZhongJie = this.form.checkedFangZhongJie
            this.formCheck.checkedJiaJaFangShuiFei = this.form.checkedJiaJaFangShuiFei
            this.formCheck.checkedYiFangShuiFei = this.form.checkedYiFangShuiFei

            this.formCheck.SWFSRadio = this.form.SWFSRadio
            this.formCheck.SFYHKRadio = this.form.SFYHKRadio
            this.formCheck.HKCSRadio = this.form.HKCSRadio
            this.formCheck.DYQKRadio = this.form.DYQKRadio
            this.formCheck.ZLQKRadio = this.form.ZLQKRadio
            this.formCheck.ZYYDRadio = this.form.ZYYDRadio
            this.formCheck.CLFSRadio = this.form.CLFSRadio
            this.formCheck.QKODKRadio = this.form.QKODKRadio
            this.formCheck.XSOXXRadio = this.form.XSOXXRadio
            this.formCheck.BZJSXYRadio = this.form.BZJSXYRadio
            this.formCheck.DKJJFSRadio = this.form.DKJJFSRadio
            this.formCheck.JZQRadio = this.form.JZQRadio
            this.formCheck.SFFSRadio = this.form.SFFSRadio
            this.formCheck.SFLSRadio = this.form.SFLSRadio
            this.formCheck.SFXHRadio = this.form.SFXHRadio
            this.formCheck.WXYDRadio = this.form.WXYDRadio
            this.formCheck.MQRadio = this.form.MQRadio
            this.formCheck.JFQMQFRadio = this.form.JFQMQFRadio
            this.formCheck.JFHMQFRadio = this.form.JFHMQFRadio
            this.formCheck.FWDYRadio = this.form.FWDYRadio
            this.formCheck.BKKLRadio = this.form.BKKLRadio
            this.formCheck.LSFRadio = this.form.LSFRadio
            this.formCheck.QTYDRadio = this.form.QTYDRadio
            this.formCheck.DKFSRadio = this.form.DKFSRadio
            this.formCheck.LSXSFSRadio = this.form.LSXSFSRadio
            this.formCheck.JFWYZRRadio = this.form.JFWYZRRadio
            this.formCheck.JFWYSSRadio = this.form.JFWYSSRadio
            this.formCheck.YFWYSSRadio = this.form.YFWYSSRadio
            this.formCheck.YFWYZRRadio = this.form.YFWYZRRadio
        }
    },
    methods: {
        onSetp(type) {
            this.$emit("onSetp", type)
        },
        goBack() {
            this.$router.go(-1)
        },
        concatData(a, b, checkedParams) {
            let data = []
            const arr = [...a, ...b]
            data = arr.filter((item) => {
                return !(a.includes(item) && b.includes(item))
            })
            data.forEach((element) => {
                checkedParams[element] = "□"
            })
            b.forEach((element) => {
                checkedParams[element] = "☑"
            })
            return checkedParams
        },
        async onSave(type) {
            let _this = this
            var get = QianYueJs.httpRequest
            // for (var i in _this.form) {
            //     if (_this.form[i] === "") {
            //         _this.$message.error("请完善数据")
            //         return false
            //     }
            // }
            // for (var i in _this.formCheck) {
            //     if (_this.formCheck[i].length === 0) {
            //         _this.$message.error("请至少选择一项")
            //         return false
            //     }
            // }
            _this.loadingFunction()
            JsZipUtils.getBinaryContent(
                "升级第二版买卖合同.docx",
                function (error, content) {
                    if (error) {
                        throw error
                    }
                    const zip = new PizZip(content)

                    const doc = new Docxtemplater(zip, {
                        paragraphLoop: true,
                        linebreaks: true
                    })
                    let checkedJiBenQingKuang = {},
                        checkedFangWuQuanShu = {},
                        checkedJiaFangTongYi = {},
                        checkedFangMenYaoShi = {},
                        checkedFangZhongJie = {},
                        checkedJiaJaFangShuiFei = {},
                        checkedYiFangShuiFei = {},

                        checkedDingZhuanYong = {}

                    // 定转佣
                    checkedDingZhuanYong = _this.concatData(
                        _this.DingZhuanYongList,
                        _this.formCheck.checkedDingZhuanYong,
                        {}
                    )

                    // 基本情况
                    checkedJiBenQingKuang = _this.concatData(
                        _this.JiBenQingKuangList,
                        _this.formCheck.checkedJiBenQingKuang,
                        {}
                    )

                    // 房屋权属
                    checkedFangWuQuanShu = _this.concatData(
                        _this.FangWuQuanShuList,
                        _this.formCheck.checkedFangWuQuanShu,
                        {}
                    )

                    //甲方同意
                    checkedJiaFangTongYi = _this.concatData(
                        _this.JiaFangTongYiList,
                        _this.formCheck.checkedJiaFangTongYi,
                        {}
                    )

                    // 房门钥匙
                    checkedFangMenYaoShi = _this.concatData(
                        _this.FangMenYaoShiList,
                        _this.formCheck.checkedFangMenYaoShi,
                        {}
                    )

                    // 放在中介那 那些权属原件
                    checkedFangZhongJie = _this.concatData(
                        _this.FangZhongJieList,
                        _this.formCheck.checkedFangZhongJie,
                        {}
                    )

                    //甲方税费
                    checkedJiaJaFangShuiFei = _this.concatData(
                        _this.JiaFangShuiFeiList,
                        _this.formCheck.checkedJiaJaFangShuiFei,
                        {}
                    )

                    //乙方税费
                    checkedYiFangShuiFei = _this.concatData(
                        _this.YiFangShuiFeiList,
                        _this.formCheck.checkedYiFangShuiFei,
                        {}
                    )

                    // 抵押情况
                    if (_this.formCheck.DYQKRadio == "1") {
                        _this.form.fw_WDY = "☑"
                    } else if (_this.formCheck.DYQKRadio == "2") {
                        _this.form.fw_YDY = "☑"
                    } else {
                        _this.form.fw_WDY = "□"
                        _this.form.fw_YDY = "□"
                    }

                    // 租赁情况
                    if (_this.formCheck.ZLQKRadio == "1") {
                        _this.form.fw_WCZ = "☑"
                    } else if (_this.formCheck.ZLQKRadio == "2") {
                        _this.form.fw_YCZ1 = "☑"
                    } else if (_this.formCheck.ZLQKRadio == "3") {
                        _this.form.fw_YCZ2 = "☑"
                    } else {
                        _this.form.fw_WCZ = "□"
                        _this.form.fw_YCZ1 = "□"
                        _this.form.fw_YCZ2 = "□"
                    }

                    // 租约约定
                    if (_this.formCheck.ZYYDRadio == "1") {
                        _this.form.zyyd_Y = "☑"
                    } else if (_this.formCheck.ZYYDRadio == "2") {
                        _this.form.zyyd_W = "☑"
                    } else {
                        _this.form.zyyd_Y = "□"
                        _this.form.zyyd_W = "□"
                    }

                    // 处理方式
                    if (_this.formCheck.CLFSRadio == "1") {
                        _this.form.yifangtongyi = "☑"
                    } else if (_this.formCheck.CLFSRadio == "2") {
                        _this.form.jiafangsuoyou = "☑"
                    } else if (_this.formCheck.CLFSRadio == "3") {
                        _this.form.xieshangyizhi = "☑"
                    } else {
                        _this.form.yifangtongyi = "□"
                        _this.form.jiafangsuoyou = "□"
                        _this.form.xieshangyizhi = "□"
                    }

                    // 全款or贷款or组合贷款
                    if (_this.formCheck.QKODKRadio == "1") {
                        _this.form.quankuan = "☑"
                    } else if (_this.formCheck.QKODKRadio == "2") {
                        _this.form.daikuan = "☑"
                    } else if (_this.formCheck.QKODKRadio == "3") {
                        _this.form.zuhedaikuan = "☑"
                    } else {
                        _this.form.quankuan = "□"
                        _this.form.daikuan = "□"
                        _this.form.zuhedaikuan = "□"
                    }

                    // 线上or线下
                    if (_this.formCheck.XSOXXRadio == "1") {
                        _this.form.xianshang = "☑"
                    } else if (_this.formCheck.XSOXXRadio == "2") {
                        _this.form.xianxia = "☑"
                    } else {
                        _this.form.xianshang = "□"
                        _this.form.xianxia = "□"
                    }

                    // 保证金三选一
                    if (_this.formCheck.BZJSXYRadio == "1") {
                        _this.form.xianshangyinhang = "☑"
                    } else if (_this.formCheck.BZJSXYRadio == "2") {
                        _this.form.xianxiayinhang = "☑"
                    } else if (_this.formCheck.BZJSXYRadio == "3") {
                        _this.form.zixingxieshang = "☑"
                    } else {
                        _this.form.xianshangyinhang = "□"
                        _this.form.xianxiayinhang = "□"
                        _this.form.zixingxieshang = "□"
                    }

                    // 死亡方式
                    if (_this.formCheck.SWFSRadio == '1') {
                        _this.form.ziransiwang = '☑'
                    } else if (_this.formCheck.SWFSRadio == '2') {
                        _this.form.feiziransiwang = '☑'
                    } else {
                        _this.form.ziransiwang = '□'
                        _this.form.feiziransiwang = '□'
                    }

                    // 是否有户口
                    if (_this.formCheck.SFYHKRadio == '1') {
                        _this.form.sfyhk_S = '☑'
                    } else if (_this.formCheck.SFYHKRadio == '2') {
                        _this.form.sfyhk_F = '☑'
                    } else {
                        _this.form.sfyhk_S = '□'
                        _this.form.sfyhk_F = '□'
                    }

                    // 户口查实
                    if (_this.formCheck.HKCSRadio == '1') {
                        _this.form.hkcs_ZGH = '☑'
                    } else if (_this.formCheck.HKCSRadio == '2') {
                        _this.form.hkcs_QT = '☑'
                    } else {
                        _this.form.hkcs_ZGH = '□'
                        _this.form.hkcs_QT = '□'
                    }

                    // 贷款解决方式
                    if (_this.formCheck.DKJJFSRadio == "1") {
                        _this.form.yifangzixing = "☑"
                    } else if (_this.formCheck.DKJJFSRadio == "2") {
                        _this.form.yifangjixu = "☑"
                    } else if (_this.formCheck.DKJJFSRadio == "3") {
                        _this.form.benhetongzhongzhi = "☑"
                    } else {
                        _this.form.yifangzixing = "□"
                        _this.form.yifangjixu = "□"
                        _this.form.benhetongzhongzhi = "□"
                    }

                    // 居住权
                    if (_this.formCheck.JZQRadio == "1") {
                        _this.form.yisheli = "☑"
                    } else if (_this.formCheck.JZQRadio == "2") {
                        _this.form.weisheli = "☑"
                    } else {
                        _this.form.yisheli = "□"
                        _this.form.weisheli = "□"
                    }

                    // 是否发生
                    if (_this.formCheck.SFFSRadio == "1") {
                        _this.form.sffs_S = "☑"
                    } else if (_this.formCheck.SFFSRadio == "2") {
                        _this.form.sffs_F = "☑"
                    } else {
                        _this.form.sffs_S = "□"
                        _this.form.sffs_F = "□"
                    }

                    // 是否漏水
                    if (_this.formCheck.SFLSRadio == "1") {
                        _this.form.loushui = "☑"
                    } else if (_this.formCheck.SFLSRadio == "2") {
                        _this.form.buloushui = "☑"
                    } else {
                        _this.form.loushui = "□"
                        _this.form.buloushui = "□"
                    }

                    // 漏水协商方式
                    if (_this.formCheck.LSXSFSRadio == "1") {
                        _this.form.jiafang_LSWX = "☑"
                    } else if (_this.formCheck.LSXSFSRadio == "2") {
                        _this.form.yifang_LSWX = "☑"
                    } else {
                        _this.form.jiafang_LSWX = "□"
                        _this.form.yifang_LSWX = "□"
                    }

                    // 是否修好
                    if (_this.formCheck.SFXHRadio == "1") {
                        _this.form.xiuhao = "☑"
                    } else if (_this.formCheck.SFXHRadio == "2") {
                        _this.form.meixiuhao = "☑"
                    } else {
                        _this.form.xiuhao = "□"
                        _this.form.meixiuhao = "□"
                    }

                    // 乙方违约责任
                    if (_this.formCheck.YFWYZRRadio == "1") {
                        _this.form.YF_baifenzhiwu = "☑"
                    } else if (_this.formCheck.YFWYZRRadio == "2") {
                        _this.form.YF_qitapeichang = "☑"
                    } else {
                        _this.form.YF_baifenzhiwu = "□"
                        _this.form.YF_qitapeichang = "□"
                    }

                    // 乙方违约导致的损失
                    if (_this.formCheck.YFWYSSRadio == "1") {
                        _this.form.YFWYSS_butuihui = "☑"
                    } else if (_this.formCheck.YFWYSSRadio == "2") {
                        _this.form.YFWYSS_baifenzhiershi = "☑"
                    } else if (_this.formCheck.YFWYSSRadio == "3") {
                        _this.form.YFWYSS_qitapeichang = "☑"
                    } else {
                        _this.form.YFWYSS_butuihui = "□"
                        _this.form.YFWYSS_baifenzhiershi = "□"
                        _this.form.YFWYSS_qitapeichang = "□"
                    }

                    // 甲方违约责任
                    if (_this.formCheck.JFWYZRRadio == "1") {
                        _this.form.JF_baifenzhiwu = "☑"
                    } else if (_this.formCheck.JFWYZRRadio == "2") {
                        _this.form.JF_qitapeichang = "☑"
                    } else {
                        _this.form.JF_baifenzhiwu = "□"
                        _this.form.JF_qitapeichang = "□"
                    }

                    // 甲方违约导致的损失
                    if (_this.formCheck.JFWYSSRadio == "1") {
                        _this.form.JFWYSS_shuangbei = "☑"
                    } else if (_this.formCheck.JFWYSSRadio == "2") {
                        _this.form.JFWYSS_baifenzhiershi = "☑"
                    } else if (_this.formCheck.JFWYSSRadio == "3") {
                        _this.form.JFWYSS_qitapeichang = "☑"
                    } else {
                        _this.form.JFWYSS_shuangbei = "□"
                        _this.form.JFWYSS_baifenzhiershi = "□"
                        _this.form.JFWYSS_qitapeichang = "□"
                    }

                    // 修好约定
                    if (_this.formCheck.WXYDRadio == "1") {
                        _this.form.yueding1 = "☑"
                    } else if (_this.formCheck.WXYDRadio == "2") {
                        _this.form.yueding2 = "☑"
                    } else if (_this.formCheck.WXYDRadio == "3") {
                        _this.form.yueding3 = "☑"
                    } else {
                        _this.form.yueding1 = "□"
                        _this.form.yueding2 = "□"
                        _this.form.yueding3 = "□"
                    }

                    // 煤气
                    if (_this.formCheck.MQRadio == "1") {
                        _this.form.yitong = "☑"
                    } else if (_this.formCheck.MQRadio == "2") {
                        _this.form.weitong = "☑"
                    } else {
                        _this.form.yitong = "□"
                        _this.form.weitong = "□"
                    }

                    // 交付前煤气费
                    if (_this.formCheck.JFQMQFRadio == "1") {
                        _this.form.jfq_JF = "☑"
                    } else if (_this.formCheck.JFQMQFRadio == "2") {
                        _this.form.jfq_YF = "☑"
                    } else {
                        _this.form.jfq_JF = "□"
                        _this.form.jfq_YF = "□"
                    }

                    // 交付后煤气费
                    if (_this.formCheck.JFHMQFRadio == "1") {
                        _this.form.jfh_JF = "☑"
                    } else if (_this.formCheck.JFHMQFRadio == "2") {
                        _this.form.jfh_YF = "☑"
                    } else {
                        _this.form.jfh_JF = "□"
                        _this.form.jfh_YF = "□"
                    }

                    // 房屋抵押
                    if (_this.formCheck.FWDYRadio == "1") {
                        _this.form.weidiya = "☑"
                    } else if (_this.formCheck.FWDYRadio == "2") {
                        _this.form.yidiya = "☑"
                    } else {
                        _this.form.weidiya = "□"
                        _this.form.yidiya = "□"
                    }

                    // 不可抗力
                    if (_this.formCheck.BKKLRadio == "1") {
                        _this.form.bukekangli1 = "☑"
                    } else if (_this.formCheck.BKKLRadio == "2") {
                        _this.form.bukekangli2 = "☑"
                    } else {
                        _this.form.bukekangli1 = "□"
                        _this.form.bukekangli2 = "□"
                    }

                    // 律师费
                    if (_this.formCheck.LSFRadio == "1") {
                        _this.form.weiyuefangchengdan = "☑"
                    } else if (_this.formCheck.LSFRadio == "2") {
                        _this.form.gezichengdan = "☑"
                    } else {
                        _this.form.weiyuefangchengdan = "□"
                        _this.form.gezichengdan = "□"
                    }

                    // 其他约定
                    if (_this.formCheck.QTYDRadio == "1") {
                        _this.form.qtyd_Y = "☑"
                    } else if (_this.formCheck.QTYDRadio == "2") {
                        _this.form.qtyd_W = "☑"
                    } else {
                        _this.form.qtyd_Y = "□"
                        _this.form.qtyd_W = "□"
                    }

                    // 贷款方式
                    if (_this.formCheck.DKFSRadio == "1") {
                        _this.form.SYDK = "☑"
                    } else if (_this.formCheck.DKFSRadio == "2") {
                        _this.form.GJJDK = "☑"
                    } else if (_this.formCheck.DKFSRadio == "3") {
                        _this.form.ZHDK = "☑"
                    } else if (_this.formCheck.DKFSRadio == "4") {
                        _this.form.BDGJJDK = "☑"
                    } else {
                        _this.form.SYDK = "□"
                        _this.form.GJJDK = "□"
                        _this.form.ZHDK = "□"
                        _this.form.BDGJJDK = "□"
                    }

                    doc.setData({
                        ..._this.form,
                        ...checkedJiBenQingKuang,
                        ...checkedFangWuQuanShu,
                        ...checkedJiaFangTongYi,
                        ...checkedFangMenYaoShi,
                        ...checkedFangZhongJie,
                        ...checkedJiaJaFangShuiFei,
                        ...checkedYiFangShuiFei,

                        ...checkedDingZhuanYong,
                    })

                    try {
                        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                        doc.render()
                    } catch (error) {
                        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                        function replaceErrors(key, value) {
                            if (value instanceof Error) {
                                return Object.getOwnPropertyNames(value).reduce(
                                    function (error, key) {
                                        error[key] = value[key]
                                        return error
                                    },
                                    {}
                                )
                            }
                            return value
                        }
                        console.log(
                            JSON.stringify({ error: error }, replaceErrors)
                        )

                        if (
                            error.properties &&
                            error.properties.errors instanceof Array
                        ) {
                            const errorMessages = error.properties.errors
                                .map(function (error) {
                                    return error.properties.explanation
                                })
                                .join("\n")
                        }
                        throw error
                    }
                    const blob = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    })
                    // Output the document using Data-URI
                    // saveAs(blob, "房屋买卖协议.docx");
                    if (type == 2) {
                        _this.loading.close()
                        // _this.save()
                        saveAs(blob, "房屋买卖协议.docx")
                    } else if (type == 1) {
                        _this.httpRequest(blob, _this.contractId, type)
                        // _this.save()
                        _this.loading.close()
                    }
                }
            )
        },
        httpRequest(blob, contractId, type) {
            let that = this,
                params = {},
                url1 = url.TransferFileToESign
            const loading = Loading.service({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            let a = new FileReader()
            a.onload = function (e) {
                params = {
                    base64File: e.target.result.split("base64,")[1],
                    contractId: contractId
                }
                axios
                    .post(url1, params, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    .then((res) => {
                        if (res.data.msgCode == 1) {
                            setTimeout(function () {
                                Message.success({
                                    message: "签约基本信息保存成功！"
                                })
                                loading.close()
                                that.reload()
                                // that.Submit()
                                // return true
                            }, 3000)
                        } else {
                            Message.error({
                                message: res.data.message,
                                duration: 5000
                            })
                            loading.close()
                            // return true
                        }
                    })
                    .catch((res) => {
                        Message.error("请求错误")
                    })
            }
            a.readAsDataURL(blob)
        },
        applyPass() {
            let that = this
            // that.draft();
            that.loading = true
            const data = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.ConfirmComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
        },
        draft() {
            let _this = this,
                param = {}
            if (_this.form.zongFangKuan_XiaoXieYuan == "×") {
                return _this.$message.error("请填写总房款金额")
            }
            if (_this.form.dingJin_XiaoXieYuan1 == "×") {
                return _this.$message.error("请填写定金金额")
            }
            if (_this.form.jfzjf_XiaoXieYuan == "×") {
                return _this.$message.error("请填写甲方服务费金额")
            }
            if (_this.form.yfzjf_XiaoXieYuan == "×") {
                return _this.$message.error("请填写乙方服务费金额")
            }
            _this.form.checkedDingZhuanYong =
                _this.formCheck.checkedDingZhuanYong

            _this.form.checkedJiBenQingKuang =
                _this.formCheck.checkedJiBenQingKuang
            _this.form.checkedFangWuQuanShu =
                _this.formCheck.checkedFangWuQuanShu
            _this.form.checkedJiaFangTongYi =
                _this.formCheck.checkedJiaFangTongYi
            _this.form.checkedFangMenYaoShi =
                _this.formCheck.checkedFangMenYaoShi
            _this.form.checkedFangZhongJie =
                _this.formCheck.checkedFangZhongJie
            _this.form.checkedJiaJaFangShuiFei =
                _this.formCheck.checkedJiaJaFangShuiFei
            _this.form.checkedYiFangShuiFei =
                _this.formCheck.checkedYiFangShuiFei

            _this.form.SWFSRadio = _this.formCheck.SWFSRadio
            _this.form.SFYHKRadio = _this.formCheck.SFYHKRadio
            _this.form.HKCSRadio = _this.formCheck.HKCSRadio
            _this.form.DYQKRadio = _this.formCheck.DYQKRadio
            _this.form.ZLQKRadio = _this.formCheck.ZLQKRadio
            _this.form.ZYYDRadio = _this.formCheck.ZYYDRadio
            _this.form.CLFSRadio = _this.formCheck.CLFSRadio
            _this.form.QKODKRadio = _this.formCheck.QKODKRadio
            _this.form.XSOXXRadio = _this.formCheck.XSOXXRadio
            _this.form.BZJSXYRadio = _this.formCheck.BZJSXYRadio
            _this.form.DKJJFSRadio = _this.formCheck.DKJJFSRadio
            _this.form.JZQRadio = _this.formCheck.JZQRadio
            _this.form.SFFSRadio = _this.formCheck.SFFSRadio
            _this.form.SFLSRadio = _this.formCheck.SFLSRadio
            _this.form.SFXHRadio = _this.formCheck.SFXHRadio
            _this.form.WXYDRadio = _this.formCheck.WXYDRadio
            _this.form.MQRadio = _this.formCheck.MQRadio
            _this.form.JFQMQFRadio = _this.formCheck.JFQMQFRadio
            _this.form.JFHMQFRadio = _this.formCheck.JFHMQFRadio
            _this.form.FWDYRadio = _this.formCheck.FWDYRadio
            _this.form.BKKLRadio = _this.formCheck.BKKLRadio
            _this.form.LSFRadio = _this.formCheck.LSFRadio
            _this.form.QTYDRadio = _this.formCheck.QTYDRadio
            _this.form.DKFSRadio = _this.formCheck.DKFSRadio
            _this.form.LSXSFSRadio = _this.formCheck.LSXSFSRadio
            _this.form.JFWYZRRadio = _this.formCheck.JFWYZRRadio
            _this.form.JFWYSSRadio = _this.formCheck.JFWYSSRadio
            _this.form.YFWYSSRadio = _this.formCheck.YFWYSSRadio
            _this.form.YFWYZRRadio = _this.formCheck.YFWYZRRadio
            param = {
                ContractId: _this.contractId,
                ContractInfo: JSON.stringify(_this.form)
            }
            _this.$http
                .put(_this.$url.UpdateContractDraft, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        _this.$message.success("签约基本信息保存成功！")
                        _this.loading.close()
                    } else {
                        _this.loading.close()
                        _this.$message.error(res.data.message)
                    }
                })
        },
        save() {
            let _this = this,
                param = {}
            _this.disabled = true
            let DingZhuanYong = ""
            if (_this.formCheck.checkedJiaFangTongYi.indexOf("tongyi1") != -1) {
                DingZhuanYong = "1"
            } else {
                DingZhuanYong = "0"
            }
            if (_this.form.bzfwf_XiaoXieYuan == "×") {
                _this.form.bzfwf_XiaoXieYuan = 0
            }
            if (_this.form.zongFangKuan_XiaoXieYuan == "×") {
                return _this.$message.error("请填写总房款金额")
            }
            if (_this.form.dingJin_XiaoXieYuan1 == "×") {
                return _this.$message.error("请填写定金金额")
            }
            if (_this.form.jfzjf_XiaoXieYuan == "×") {
                return _this.$message.error("请填写甲方服务费金额")
            }
            if (_this.form.yfzjf_XiaoXieYuan == "×") {
                return _this.$message.error("请填写乙方服务费金额")
            }
            param = {
                dingJinAmount: _this.form.dingJin_XiaoXieYuan1,
                jiaFangFuWuFeiAmount: Number(_this.form.jfzjf_XiaoXieYuan),
                yiFangFuWuFeiAmount: _this.form.yfzjf_XiaoXieYuan,
                baoZhangFuWuFeiAmount: _this.form.bzfwf_XiaoXieYuan,
                isDingZhuanYong: DingZhuanYong,
                userId: _this.formData.userId,
                contractId: _this.contractId,
                shopId: _this.formData.shopId,
                merchantId: _this.formData.merchantId,
                houseId: _this.formData.houseId,
                customerId: _this.formData.customerId,
                tradeType: _this.formData.tradeType,
                type: _this.formData.type,
                contractHouseAddress: _this.formData.contractHouseAddress,
                contractPrice: Number(_this.form.zongFangKuan_XiaoXieYuan)
                // fileId1: _this.form.fileId1.split(".")[0]
                // contractInfo: JSON.stringify(_this.form)
            }
            console.log(param, "8786465451")
            _this.$http
                .put(_this.$url.UpdateContract, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    _this.disabled = false
                    if (res.data.msgCode == 1) {
                        // _this.$message.success("签约基本信息保存成功！");
                        _this.onSave(1)
                        _this.loading.close()
                        if (!_this.contractId) {
                            this.$router.go(-1)
                        }
                    } else {
                        _this.loading.close()
                        _this.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    _this.disabled = false
                    _this.loading.close()
                    this.$message.error("请求错误")
                })
        },
        // 邮箱校验
        onlyRules() {
            if (
                this.form.chuShou_Email != "×" &&
                this.form.chuShou_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuShou_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuShou_Email = ""
                return
            }
            if (
                this.form.chuShouGY_Email != "×" &&
                this.form.chuShouGY_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuShouGY_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuShouGY_Email = ""
                return
            }
            if (
                this.form.chuShouDL_Email != "×" &&
                this.form.chuShouDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuShouDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuShouDL_Email = ""
                return
            }
            if (
                this.form.gouMai_Email != "×" &&
                this.form.gouMai_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.gouMai_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.gouMai_Email = ""
                return
            }
            if (
                this.form.gouMaiGY_Email != "×" &&
                this.form.gouMaiGY_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.gouMaiGY_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.gouMaiGY_Email = ""
                return
            }
            if (
                this.form.gouMaiDL_Email != "×" &&
                this.form.gouMaiDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.gouMaiDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.gouMaiDL_Email = ""
                return
            }
        },
        rulesDate(num, param) {
            if (this.form[param] !== "") {
                this.form[param] = parseInt(this.form[param])
                if (
                    num % 2 == 1 &&
                    (this.form[param] > 12 || this.form[param] < 1)
                ) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的月份")
                } else if (this.form[param] > 31 || this.form[param] < 1) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的日期")
                }
            }
        },
        rulesIdNo() {
            // 证件号码
            if (
                this.form.sellerIdCardNo != "×" &&
                !this.$commonJs.shenfenZheng(this.form.sellerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.sellerIdCardNo = ""
                return
            }
            if (
                this.form.sellerIdCardNoGongYou != "×" &&
                !this.$commonJs.shenfenZheng(this.form.sellerIdCardNoGongYou)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.sellerIdCardNoGouYou = ""
                return
            }
            if (
                this.form.sellerIdCardNoDaiLi != "×" &&
                !this.$commonJs.shenfenZheng(this.form.sellerIdCardNoDaiLi)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.sellerIdCardNoDaiLi = ""
                return
            }
            if (
                this.form.buyerIdCardNo != "×" &&
                !this.$commonJs.shenfenZheng(this.form.buyerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.buyerIdCardNo = ""
                return
            }
            if (
                this.form.buyerIdCardNoGongYou != "×" &&
                !this.$commonJs.shenfenZheng(this.form.buyerIdCardNoGongYou)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.buyerIdCardNoGongYou = ""
                return
            }
            if (
                this.form.buyerIdCardNoDaiLi != "×" &&
                !this.$commonJs.shenfenZheng(this.form.buyerIdCardNoDaiLi)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.buyerIdCardNoDaiLi = ""
                return
            }
            if (
                this.form.cmr_ZJHM != "×" &&
                !this.$commonJs.shenfenZheng(this.form.cmr_ZJHM)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.cmr_ZJHM = ""
                return
            }
            if (
                this.form.cmr_GYQRZJHM != "×" &&
                !this.$commonJs.shenfenZheng(this.form.cmr_GYQRZJHM)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.cmr_GYQRZJHM = ""
                return
            }
            if (
                this.form.msr_ZJHM != "×" &&
                !this.$commonJs.shenfenZheng(this.form.msr_ZJHM)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.msr_ZJHM = ""
                return
            }
            if (
                this.form.msr_GYGMRZJHM != "×" &&
                !this.$commonJs.shenfenZheng(this.form.msr_GYGMRZJHM)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.msr_GYGMRZJHM = ""
                return
            }
        },
        // numberTel(obj) {
        //     var type = /^[04]\d{2,3}-?\d{3}-?\d{4,5}$/
        //     var flag = type.test(obj)
        //     return flag
        // },
        // rulesTel(val) {
        //     if (val === 1 && !this.numberTel(this.form.tel1)) {
        //         this.form.tel1 = ""
        //         this.$message.error("请输入正确的号码")
        //     }
        //     if (val === 2 && !this.numberTel(this.form.tel2)) {
        //         this.form.tel2 = ""
        //         this.$message.error("请输入正确的号码")
        //     }
        // },
        fun() {
            this.form.bingFang2 = this.form.bingFang1
        },
        rulesMobile(val) {
            if (val === 1 && !this.$commonJs.numberPhone(this.form.LXDH1)) {
                this.form.LXDH1 = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (val === 2 && !this.$commonJs.numberPhone(this.form.LXDH2)) {
                this.form.LXDH2 = ""
                this.$message.error("请输入正确的手机号码")
            }
        },
        moneyRule(param) {
            if (
                this.form[param] != "×" &&
                !this.$commonJs.moneyFormat(this.form[param] * 1)
            ) {
                this.form[param] = "×"
                this.$message.error("请输入正确金额")
            }
            if (
                param == "yueZu_XiaoXieYuan" &&
                this.form.yueZu_XiaoXieYuan != "×"
            ) {
                if (this.form.yueZu_XiaoXieYuan != "") {
                    this.form.yueZu_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.yueZu_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.yueZu_XiaoXieYuan = "×"
                    this.form.yueZu_DaXieYuan = "×"
                }
            }
            if (
                param == "yaJin_XiaoXieYuan" &&
                this.form.yaJin_XiaoXieYuan != "×"
            ) {
                if (this.form.yaJin_XiaoXieYuan != "") {
                    this.form.yaJin_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.yaJin_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.yaJin_DaXieYuan = "×"
                    this.form.yaJin_XiaoXieYuan = "×"
                }
            }
            if (
                param == "zongFangKuan_XiaoXieYuan" &&
                this.form.zongFangKuan_XiaoXieYuan != "×"
            ) {
                if (this.form.zongFangKuan_XiaoXieYuan != "") {
                    this.form.zongFangKuan_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.zongFangKuan_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.zongFangKuan_DaXieYuan = "×"
                    this.form.zongFangKuan_XiaoXieYuan = "×"
                }
            }
            if (
                param == "fangWuJiaGe_XiaoXieYuan" &&
                this.form.fangWuJiaGe_XiaoXieYuan != "×"
            ) {
                if (this.form.fangWuJiaGe_XiaoXieYuan != "") {
                    this.form.fangWuJiaGe_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.fangWuJiaGe_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.fangWuJiaGe_DaXieYuan = "×"
                    this.form.fangWuJiaGe_XiaoXieYuan = "×"
                }
            }
            if (
                param == "zhuangXiuKuan_XiaoXieYuan1" &&
                this.form.zhuangXiuKuan_XiaoXieYuan1 != "×"
            ) {
                if (this.form.zhuangXiuKuan_XiaoXieYuan1 != "") {
                    this.form.zhuangXiuKuan_DaXieYuan1 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.zhuangXiuKuan_XiaoXieYuan1 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.zhuangXiuKuan_DaXieYuan1 = "×"
                    this.form.zhuangXiuKuan_XiaoXieYuan1 = "×"
                }
            }
            if (
                param == "dingJin_XiaoXieYuan1" &&
                this.form.dingJin_XiaoXieYuan1 != "×"
            ) {
                if (this.form.dingJin_XiaoXieYuan1 != "") {
                    this.form.dingJin_DaXieYuan1 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.dingJin_XiaoXieYuan1 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.dingJin_DaXieYuan1 = "×"
                    this.form.dingJin_XiaoXieYuan1 = "×"
                }
            }
            if (
                param == "diYiBi_XiaoXieYuan" &&
                this.form.diYiBi_XiaoXieYuan != "×"
            ) {
                if (this.form.diYiBi_XiaoXieYuan != "") {
                    this.form.diYiBi_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.diYiBi_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.diYiBi_DaXieYuan = "×"
                    this.form.diYiBi_XiaoXieYuan = "×"
                }
            }
            if (
                param == "diErBi_XiaoXieYuan" &&
                this.form.diErBi_XiaoXieYuan != "×"
            ) {
                if (this.form.diErBi_XiaoXieYuan != "") {
                    this.form.diErBi_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.diErBi_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.diErBi_DaXieYuan = "×"
                    this.form.diErBi_XiaoXieYuan = "×"
                }
            }
            if (
                param == "cunGuanDingJin_XiaoXieYuan1" &&
                this.form.cunGuanDingJin_XiaoXieYuan1 != "×"
            ) {
                if (this.form.cunGuanDingJin_XiaoXieYuan1 != "") {
                    this.form.cunGuanDingJin_DaXieYuan1 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.cunGuanDingJin_XiaoXieYuan1 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.cunGuanDingJin_DaXieYuan1 = "×"
                    this.form.cunGuanDingJin_XiaoXieYuan1 = "×"
                }
            }
            if (
                param == "cunGuanDingJin_XiaoXieYuan2" &&
                this.form.cunGuanDingJin_XiaoXieYuan2 != "×"
            ) {
                if (this.form.cunGuanDingJin_XiaoXieYuan2 != "") {
                    this.form.cunGuanDingJin_DaXieYuan2 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.cunGuanDingJin_XiaoXieYuan2 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.cunGuanDingJin_DaXieYuan2 = "×"
                    this.form.cunGuanDingJin_XiaoXieYuan2 = "×"
                }
            }
            if (
                param == "shengYuDingJin_XiaoXieYuan" &&
                this.form.shengYuDingJin_XiaoXieYuan != "×"
            ) {
                if (this.form.shengYuDingJin_XiaoXieYuan != "") {
                    this.form.shengYuDingJin_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.shengYuDingJin_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.shengYuDingJin_DaXieYuan = "×"
                    this.form.shengYuDingJin_XiaoXieYuan = "×"
                }
            }
            if (
                param == "fangKuan_XiaoXieYuan1" &&
                this.form.fangKuan_XiaoXieYuan1 != "×"
            ) {
                if (this.form.fangKuan_XiaoXieYuan1 != "") {
                    this.form.fangKuan_DaXieYuan1 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.fangKuan_XiaoXieYuan1 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.fangKuan_DaXieYuan1 = "×"
                    this.form.fangKuan_XiaoXieYuan1 = "×"
                }
            }
            if (
                param == "zhuangXiukuan_XiaoXieYuan2" &&
                this.form.zhuangXiukuan_XiaoXieYuan2 != "×"
            ) {
                if (this.form.zhuangXiukuan_XiaoXieYuan2 != "") {
                    this.form.zhuangXiukuan_DaXieYuan2 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.zhuangXiukuan_XiaoXieYuan2 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.zhuangXiukuan_DaXieYuan2 = "×"
                    this.form.zhuangXiukuan_XiaoXieYuan2 = "×"
                }
            }
            if (
                param == "fangKuan_XiaoXieYuan2" &&
                this.form.fangKuan_XiaoXieYuan2 != "×"
            ) {
                if (this.form.fangKuan_XiaoXieYuan2 != "×") {
                    this.form.fangKuan_DaXieYuan2 = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.fangKuan_XiaoXieYuan2 = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.fangKuan_DaXieYuan2 = "×"
                    this.form.fangKuan_XiaoXieYuan2 = "×"
                }
            }
            if (
                param == "daiKuan_XiaoXieYuan" &&
                this.form.daiKuan_XiaoXieYuan != "×"
            ) {
                if (this.form.daiKuan_XiaoXieYuan != "") {
                    this.form.daiKuan_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.daiKuan_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.daiKuan_DaXieYuan = "×"
                    this.form.daiKuan_XiaoXieYuan = "×"
                }
            }
            if (
                param == "zhongJieFei_XiaoXieYuan" &&
                this.form.zhongJieFei_XiaoXieYuan != "×"
            ) {
                if (this.form.zhongJieFei_XiaoXieYuan != "×") {
                    this.form.zhongJieFei_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.zhongJieFei_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.zhongJieFei_DaXieYuan = "×"
                    this.form.zhongJieFei_XiaoXieYuan = "×"
                }
            }
            if (
                param == "jfzjf_XiaoXieYuan" &&
                this.form.jfzjf_XiaoXieYuan != "×"
            ) {
                if (this.form.jfzjf_XiaoXieYuan != "") {
                    this.form.jfzjf_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.jfzjf_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.jfzjf_DaXieYuan = "×"
                    this.form.jfzjf_XiaoXieYuan = "×"
                }
            }
            if (
                param == "jfzxzf_XiaoXieYuan" &&
                this.form.jfzxzf_XiaoXieYuan != "×"
            ) {
                if (this.form.jfzxzf_XiaoXieYuan != "") {
                    this.form.jfzxzf_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.jfzxzf_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.jfzxzf_DaXieYuan = "×"
                    this.form.jfzxzf_XiaoXieYuan = "×"
                }
            }
            if (
                param == "jfwtzf_XiaoXieYuan" &&
                this.form.jfwtzf_XiaoXieYuan != "×"
            ) {
                if (this.form.jfwtzf_XiaoXieYuan != "") {
                    this.form.jfwtzf_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.jfwtzf_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.jfwtzf_DaXieYuan = "×"
                    this.form.jfwtzf_XiaoXieYuan = "×"
                }
            }
            if (
                param == "yfzjf_XiaoXieYuan" &&
                this.form.yfzjf_XiaoXieYuan != "×"
            ) {
                if (this.form.yfzjf_XiaoXieYuan != "") {
                    this.form.yfzjf_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.yfzjf_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.yfzjf_DaXieYuan = "×"
                    this.form.yfzjf_XiaoXieYuan = "×"
                }
            }
            if (
                param == "sydk_XiaoXieYuan" &&
                this.form.sydk_XiaoXieYuan != "×"
            ) {
                if (this.form.sydk_XiaoXieYuan != "") {
                    this.form.sydk_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.sydk_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.sydk_DaXieYuan = "×"
                    this.form.sydk_XiaoXieYuan = "×"
                }
            }
            if (
                param == "gjjdk_XiaoXieYuan" &&
                this.form.gjjdk_XiaoXieYuan != "×"
            ) {
                if (this.form.gjjdk_XiaoXieYuan != "") {
                    this.form.gjjdk_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.gjjdk_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.gjjdk_DaXieYuan = "×"
                    this.form.gjjdk_XiaoXieYuan = "×"
                }
            }
            if (
                param == "bzfwf_XiaoXieYuan" &&
                this.form.bzfwf_XiaoXieYuan != "×"
            ) {
                if (this.form.bzfwf_XiaoXieYuan != "") {
                    this.form.bzfwf_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.bzfwf_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.bzfwf_DaXieYuan = "×"
                    this.form.bzfwf_XiaoXieYuan = "×"
                }
            }
        },

        loadingFunction() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
        },
        beforeUpload(file) {
            this.loadingFunction()
        },
        Submit(type) {
            let that = this
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            that.disabled = true
            // this.onSave(2);
            let param = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.FlowOneStepESign, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((result) => {
                    that.disabled = false
                    that.loading.close()
                    if (result.data.msgCode == 1) {
                        that.$message.success("合同提交成功！")
                        that.$router.go(-1)
                    } else {
                        that.$message.error(result.data.message)
                    }
                })
                .catch((result) => {
                    that.loading.close()
                    that.disabled = false
                    that.$message.error("请求错误")
                })
        }
        // handleSuccess(res, file) {
        //   if (res.msgCode == "1") {
        //     let that = this;
        //     that.disabled = true;
        //     let param = {
        //       userId: that.contractId,
        //     };
        //     that.$http
        //       .post(that.$url.FlowOneStepESign, param, {
        //         headers: {
        //           "Content-Type": "application/json;charset=UTF-8",
        //         },
        //       })
        //       .then((result) => {
        //         that.disabled = false;
        //         that.loading.close();
        //         if (result.data.msgCode == 1) {
        //           that.$message.success("合同提交成功！");
        //           that.$router.go(-1);
        //         } else {
        //           that.$message.error(result.data.message);
        //         }
        //       })
        //       .catch((result) => {
        //         that.loading.close();
        //         that.disabled = false;
        //         that.$message.error("请求错误");
        //       });
        //   } else {
        //     that.loading.close();
        //     this.$message.error("合同提交失败");
        //   }
        // },
        // handleRemove(file, fileList) {
        //   this.fileList = [];
        // },
        // handleExceed(files, fileList) {
        //   this.$message.warning("当前限制选择1个文件，请删除当前文件后，重新上传!");
        // },
        // beforeRemove(file, fileList) {
        //   return this.$confirm(`确定移除 ${file.name}？`);
        // },
    }
}
</script>
<style scoped>
input[type="checkbox"] {
    vertical-align: sub !important;
}
</style>
<style lang="less" scoped>
.body {
    width: 100%;
    padding: 1% 4%;
    font-size: 12px;
    font-family: "宋体";
    list-style: none;
    box-sizing: border-box;

    .font_weight {
        font-weight: 600;
    }

    .text_align_l {
        text-align: left;
    }

    .text_align_r {
        text-align: right;
    }

    .border_bottom {
        border-bottom: 1px solid #000;
    }

    .header_title {
        color: #b3b3b3;
        font-weight: 400;
        line-height: 25px;
        border-bottom: 1px solid #ccc;
    }

    .header {
        height: 70px;
        border-bottom: 1px solid #ccc;

        img {
            display: block;
            float: left;
            width: 90px;
        }

        h3 {
            float: right;
            line-height: 90px;
            color: #b3b3b3;
            font-weight: 400;
        }
    }

    input {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        font-size: 16px;
        color: red;
        text-align: center;
        height: 20px !important;
        vertical-align: text-bottom;
    }

    .input_borderBottom_ccc {
        border-bottom: 1px solid #ccc;
    }

    .input_borderBottom_000 {
        border-bottom: 1px solid #000;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
    }

    /deep/ .el-radio__input+.el-radio__label,
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #000 !important;
    }

    /deep/ .el-checkbox__inner {
        border: 1px solid #000 !important;
    }

    /deep/ .el-input__inner {
        border: 0 !important;
        font-size: 16px;
        color: red;
        text-align: center;
    }

    .text-c {
        text-align: center;
    }

    .text-indent {
        text-indent: 35px;
    }

    .text-indent-12 {
        text-indent: 12px;
    }

    .text-indent-44 {
        text-indent: 44px;
    }

    .borderN {
        border-bottom: none;
    }

    .border0 {
        border: 0;
    }

    .border_000 {
        border: 1px solid #000;
    }

    .inputW_50px {
        width: 50px;
    }

    .inputW_80px {
        width: 80px;
    }

    .inputW_150 {
        width: 150px;
    }

    .inputW_150 {
        width: 150px;
    }

    .inputW_15 {
        width: 15%;
    }

    .inputW_30 {
        width: 30%;
    }

    .inputW_23 {
        width: 23%;
    }

    .inputW_40 {
        width: 40%;
    }

    .margin_top_150 {
        margin-top: 150px !important;
    }

    .margin_top_100 {
        margin-top: 100px !important;
    }

    .margin_top_50 {
        margin-top: 50px !important;
    }

    .margin_top_20 {
        margin-top: 20px !important;
    }

    .margin_top_10 {
        margin-top: 10px !important;
    }

    .margin_left_100 {
        margin-left: 100px;
    }

    // .padding_left_100 {
    //     text-indent: 35px;
    // }
    .font_weight500 {
        font-weight: 500;
    }

    .font_weight600 {
        font-weight: 600;
    }

    .float_left {
        float: left;
    }

    .float_right {
        float: right;
    }

    // 清除浮动
    .clearfix:after {
        content: "";
        height: 0;
        line-height: 0;
        display: block;
        clear: both;
        visibility: hidden;
    }

    .clearfix {
        zoom: 1;
    }

    .el-card {
        line-height: 25px !important;
    }

    p {
        margin: 0;
    }

    .quote_info {
        font-size: 15px;
        color: #000;
        width: 80%;
        margin: 0 auto;

        .h3_title {
            float: left;
            width: 100%;
            font-size: 30px;
            letter-spacing: 1px;
            line-height: 100px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }

        .h2_title {
            float: left;
            width: 100%;
            font-size: 25px;
            letter-spacing: 1px;
            line-height: 50px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }
    }

    .box {
        width: 520px;
        height: 200px;
        padding: 5px 20px;

        div {
            width: 300px;
            margin: 0 auto;
        }
    }

    .box1 {
        width: 150px;
        margin: 0 auto;
    }

    ul,
    li {
        list-style: none;
    }

    li {
        border: 1px solid #000;
        line-height: 35px;
    }

    .width_10 {
        width: 10%;
        text-align: center;
        font-weight: 600;
    }

    .width_20 {
        width: 16.3%;
        text-align: center;
        font-weight: 600;
    }

    .width_90 {
        width: 90%;
        text-align: center;
        font-weight: 600;
    }

    .width_98 {
        width: 98%;
    }

    .width_100 {
        width: 100%;
        font-weight: 600;
    }

    .width_full {
        width: 100%;
    }

    .display_flex {
        display: flex;
    }
}

.nav1 {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 40px;
    text-align: center;
    margin: 10px 0 !important;
}

.nav2 {
    margin-left: 0 !important;
}

/deep/ .el-upload-list {
    display: contents;

    .el-upload-list__item-name {
        width: 140px;
    }
}

.el-button {
    margin: 5px;
}

/deep/.el-radio__inner {
    border: 1px solid #df0202;
}

/deep/.el-radio__inner:hover {
    border: 1px solid #0262df;
}

/deep/input[type="checkbox"] {
    position: relative;
    border: 1px solid #df0202 !important;
    border-radius: 3px;
    width: 13px;
    height: 13px !important;
    top: -1px;
    -webkit-appearance: none;
}

/deep/input[type="checkbox"]:disabled {
    cursor: not-allowed;
    border-color: rgba(153, 153, 153, 0.3) !important;
    background-color: rgb(241, 241, 241);
}

/deep/input[type="checkbox"]:hover {
    border: 1px solid #0262df !important;
}

/deep/input[type="checkbox"]:hover:disabled {
    border: 1px solid #dbdbdb !important;
}

/deep/input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 13px;
    margin-top: 0px !important;
    font-weight: bold;
    background-color: #ee6716;
    top: -1px;
    position: absolute;
    left: -1px;
    width: 13px;
    height: 13px !important;
}

/deep/ input:disabled {
    background-color: #fff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #ee6716;
}
</style>
