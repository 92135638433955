<template>
    <div>
        <el-card class="search">
            <el-row>
                <el-col :span="6">
                    <span class="title">楼盘名称：</span>
                    <el-input class="media-width" placeholder="请输入房源名称" v-model="newHouseName"></el-input>
                    <!-- <el-cascader class="media-width" filterable v-model="newHouseName" :options="options" :props="{ checkStrictly: true }" @change="handleChange"></el-cascader> -->
                </el-col>
                <el-button type="warning" size="mini" style="margin-left:5px;float:right;" @click="resetHouseList">重置</el-button>
                <el-button type="primary" size="mini" style="float:right;" @click="getHouseList">查询</el-button>

            </el-row>
            <hr style="height:1px;border:none;backgroundColor:#eee;" />
            <el-row v-if="roleType == 6">
                <span class="title">范围:</span>
                <div v-for="item in item1" :key="item.label" :value="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive1 === item.value }" @click="changeClass(item.label,item.value,'fanwei')">
                    {{ item.label }}
                </div>
            </el-row>
            <el-row style="margin-top: 3px;height: auto;">
                <el-col :span="0.9">
                    <span class="title">商圈:</span>
                </el-col>
                <el-col :span="23" style="margin-left: 3px !important;">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane name="不限" label="不限" style="margin-left: 0.5% !important;">
                            <span slot="label">不限</span>
                        </el-tab-pane>
                        <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :name="item.name" :label="item.name">
                            <el-checkbox-group :max="3" style="font-size: 14px;float:left;margin:5px;" v-model="daQuInclude">
                                <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{item1.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>

            </el-row>
            <el-row style="margin-top: 3px;">
                <span class="title">价格:</span>

                <div v-for="item in item8" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive3 === item.value }" @click="changeClass(item.label,item.value,'price')">
                    {{ item.label }}
                </div>
                <span style="margin-left:0.5%;font-size:14px">
                    <el-input maxlength="6" placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999)value=999999" v-model="priceFrom" style="width:70px;"></el-input>
                    -
                    <el-input maxlength="6" placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>999999)value=999999" @blur="CheckPrice" v-model="priceTo" style="width:70px;"></el-input>
                    元/㎡
                </span>
            </el-row>
            <el-row style="margin-top: 3px;">
                <span class="title">售卖类型:</span>
                <div v-for="item in shouMaiLeiXingOptions" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive7=== item.label }" @click="changeClass(item.label,item.value,'shoumai')">
                    {{ item.label }}
                </div>
            </el-row>
            <el-row>
                <span class="title">交易权属:</span>
                <div style="font-size: 14px;margin-left:0.8%;" class="xuanxiang2" :class="{ active: isActive12 === '不限' }" @click="changeClass('不限','','jiaoyi')">不限</div>
                <div v-for="item in JiaoYiQuanShu" :key="item.label" effect="dark" class="xuanxiang2" :class="{ active: isActive12 === item.value }" @click="changeClass(item.label,item.value,'jiaoyi')">
                    {{ item.label }}
                </div>
            </el-row>
            <el-row>
                <span class="title">审核状态:</span>
                <div v-for="item in ShenHe" :key="item.label" :value="item.label" effect="dark" class="xuanxiang2" :class="{ active: isActiveShenHe === item.value }" @click="changeClass(item.label,item.value,'shenhe')">
                    {{ item.label }}
                </div>
            </el-row>
            <el-row style="margin-top: 10px">
                <el-col>
                    <el-button size="mini" @click="openDetail('',0)" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                    <!-- <el-button v-if="roleType == 6" size="mini" @click="handleDelete('','tow')" type="warning" icon="el-icon-delete" style="border: none">删除</el-button> -->
                </el-col>
            </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-card>
            <div style="padding-left: 10px">
                已为您找到{{ total }}个房源
            </div>
            <el-table @sort-change="sortChange" @row-click="xzFangYuan" @cell-click="changeLouCeng" ref="multipleTable" v-loading="loading" :data="HouseList" :cell-class-name="tableCellClassName" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe class="matop0">
                <!-- <el-table-column type="selection" width="40"></el-table-column> -->
                <el-table-column label="楼盘名称" width="170px" prop="newHouseName">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="scope.row.newHouseName" placement="top">
                            <el-button class="tableButSpan" @click="openDetail(scope.row,1,0)" type="text" size="mini" style="font-size:16px;font-weight:bold;color: #000;">{{ scope.row.newHouseName }}</el-button>
                        </el-tooltip>
                        <div v-if="scope.row.enabled == '1'">
                            <span>
                                <el-popover placement="bottom" trigger="manual" v-model="scope.row.visible">
                                    <img v-if="sharePic!=''" :src="sharePic">
                                    <img src="../../../public/img/xiaochengxu.png" slot="reference" @click="clickWeiXinGeneratingCode(scope.row)" style="margin-left: 5px;vertical-align: text-top;width: 20px;height:20px;cursor: pointer;" />
                                </el-popover>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="楼盘编号" prop="newHouseNo">
                    <template slot-scope="scope">
                        <span style="display:block">{{scope.row.newHouseNo}}</span>
                        <span style="font-weight:100;font-size:13px;color:red;margin-left:5px">{{scope.row.isConfirmed==='1'?'已审核':'未审核'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="商圈" prop="areaNames"></el-table-column>
                <el-table-column label="地址" prop="newHouseAddress">
                    <template slot-scope="scope">
                        <el-button class="tableButSpan" type="text" size="mini" style="color: #000;cursor:text ;">{{scope.row.newHouseAddress}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="代理方式" prop="daiLiFangShi"></el-table-column>
                <el-table-column label="开盘时间" prop="kaiPanShiJian"></el-table-column>
                <el-table-column label="户型" prop="huXingInfo"></el-table-column>
                <el-table-column label="面积" prop="squareInfo"></el-table-column>
                <el-table-column label="价格" sortable prop="price">
                    <template slot-scope="scope">
                        <span>{{scope.row.price}}元/㎡</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px" v-if="roleType ==1||roleType == 2||roleType == 5||roleType == 6">
                    <template slot-scope="scope" class="right">
                        <el-button v-if="(roleType ==1&&scope.row.canMaintenanceEdit ==1)||(roleType ==2&&scope.row.canShopEdit==1)||(roleType ==5&&scope.row.canMerchantEdit==1)||roleType ==6" @click.native.stop="openDetail(scope.row,2,0)" type="text" size="mini" class="el-icon-edit caozuo" style="color: #1890ff">修改</el-button>
                        <el-button v-if="roleType == 6 && scope.row.isConfirmed !=1" @click.native.stop="openDetail(scope.row,1,1)" type="text" size="mini" class="el-icon-edit caozuo" style="color: hotpink;">审核</el-button>
                        <!-- <el-button v-if="((roleType ==2&&scope.row.canShopEdit==1)||(roleType ==5&&scope.row.canMerchantEdit==1)|| roleType == 6) && scope.row.isConfirmed !=1" @click.native.stop="openDetail(scope.row,1,1)" type="text" size="mini" class="el-icon-edit caozuo" style="color: hotpink;">审核</el-button> -->
                        <el-button @click.native.stop="guanzhu(scope.row)" type="text" size="mini" class="el-icon-view" style="color: rgb(19, 177, 19)">{{scope.row.favourite == '0'?'关注':'已关注'}}</el-button>
                        <el-button v-if="roleType ==6" @click.native.stop="wuXiaoFangYuan(scope.row)" type="text" size="mini" class="el-icon-lock caozuo" style="color: #909399">{{scope.row.enabled == '0'?'有效':'无效'}}</el-button>
                        <!-- <el-button v-if="(roleType ==1&&scope.row.canMaintenanceEdit ==1)||(roleType ==2&&scope.row.canShopEdit==1)||(roleType ==5&&scope.row.canMerchantEdit==1)||roleType ==6" @click.native.stop="wuXiaoFangYuan(scope.row)" type="text" size="mini" class="el-icon-lock caozuo" style="color: #909399">{{scope.row.enabled == '0'?'有效':'无效'}}</el-button> -->
                        <!-- <el-button v-if="roleType ==6" @click.native.stop="handleDelete(scope.row,'one')" type="text" size="mini" class="el-icon-delete caozuo" style="color: #da0909">删除</el-button> -->
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 100px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </el-card>
    </div>
</template>

<script>
import varData from "../ChuZu/varData"
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            userId: window.localStorage.getItem("userId"),
            roleType: "",
            // 商圈区域多选框数据
            activeName: "不限",
            newHouseName: "",
            daQuInclude: [],
            regionList1: [
                {
                    name: "",
                    includeShangQuan: [{ name: "", areaIds: "" }]
                }
            ],
            loading: false,
            HouseList: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            list1: [],
            item8: [],
            item1: [
                {
                    label: "不限",
                    value: ""
                },
                {
                    label: "无效房源",
                    value: "0"
                }
            ],
            ShenHe: [
                {
                    label: "不限",
                    value: ""
                },
                {
                    label: "审核已通过",
                    value: "0"
                },
                {
                    label: "未审核",
                    value: "1"
                }
            ],
            shouMaiLeiXingOptions: [],
            shouMaiLeiXing: "",
            isActiveShenHe: "",
            isActive1: "",
            isActive3: "0,0",
            isActive7: "不限",
            isActive12: "不限",

            jiaoYiQuanShuId: "",
            JiaoYiQuanShu: [],
            priceFrom: 0,
            priceTo: 0,
            sortBy: "",
            cengshu: "",
            multipleSelection: [],
            favourite: "",
            IsEnabled: "",
            editableTabs: [],
            IsConfirmed: "", //筛选审核
            newFangParams: JSON.parse(
                window.localStorage.getItem("newFangParams")
            ),
            sharePic: "" //小程序码
        }
    },
    props: {
        /* 是否为客源带看 */
        isKeyuanDaikan: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        daQuInclude(val) {
            if (val.length >= 3) {
                this.$message.info("最多只能选三项")
            }
            if (val.length > 0) {
                this.getHouseList()
            }
        }
    },
    created() {
        this.editableTabs = window.localStorage.getItem("editableTabs")
        this.activeIndex = window.localStorage.getItem("activeIndex")
        if (this.editableTabs) {
            this.editableTabs = JSON.parse(this.editableTabs)
            this.editableTabs.forEach((item) => {
                if (this.activeIndex == item.path) {
                    this.activeIndex = this.activeIndex
                    this.editableTabsValue = item.name.toString()
                }
            })
        }
        this.roleType = localStorage.getItem("roleType")
        if (this.newFangParams) {
            this.newHouseName = this.newFangParams.newHouseName
            this.IsEnabled = this.newFangParams.IsEnabled
            this.isActive1 = this.newFangParams.IsEnabled
            this.daQuInclude = this.newFangParams.areaIds.split(",")
            this.priceFrom = this.newFangParams.priceFrom / 10000
            this.priceTo = this.newFangParams.priceTo / 10000
            this.isActive3 = this.priceFrom + "," + this.priceTo
            this.shouMaiLeiXing = this.newFangParams.shouMaiLeiXing
            this.isActive7 =
                this.newFangParams.shouMaiLeiXing == ""
                    ? "不限"
                    : this.newFangParams.shouMaiLeiXing
            this.jiaoYiQuanShuId = this.newFangParams.jiaoYiQuanShuId
            this.isActive12 =
                this.newFangParams.jiaoYiQuanShuId == ""
                    ? "不限"
                    : this.newFangParams.jiaoYiQuanShuId
            this.IsConfirmed = this.newFangParams.IsConfirmed
            this.isActiveShenHe = this.newFangParams.IsConfirmed
            this.sortBy = this.newFangParams.sortBy
            this.currentPage = this.newFangParams.currentPage
            this.pageSize = this.newFangParams.pageSize
        }
        if (localStorage.getItem("newFangActiveName")) {
            this.activeName = localStorage.getItem("newFangActiveName")
        }

        this.getShuJuZiDianList()
        this.getHouseList()
    },
    mounted() {
        var varData1 = varData.Function()
        this.item8 = varData1.item8
        this.shouMaiLeiXingOptions = varData1.shouMaiLeiXingOptions
    },
    methods: {
        clickWeiXinGeneratingCode(row) {
            let that = this
            that.sharePic = ""
            that.$commonJs
                .getWeiXinGeneratingCode("xinFangList", row.newHouseNo)
                .then((data) => {
                    that.HouseList.forEach((item) => {
                        if (
                            row.newHouseNo == item.newHouseNo &&
                            item.visible != true
                        ) {
                            item.visible = true
                        } else {
                            item.visible = false
                        }
                    })
                    that.sharePic = data
                })
        },
        getShuJuZiDianList() {
            /* 获取数据字典 */
            let that = this
            this.$commonJs
                .getShuJuZiDianList("JIAOYIQUANSHU,SHANGQUAN")
                .then((data) => {
                    that.JiaoYiQuanShu = data.JiaoYiQuanShu
                    that.regionList1 = data.shangquan
                })
        },
        xzFangYuan(row) {
            //回调父组件
            if (this.isKeyuanDaikan) {
                console.log(row)
                this.$emit("colseFangyuanDialog", row)
            }
        },
        getHouseList() {
            //新房列表
            var that = this
            that.loading = true
            const params = {
                IsConfirmed: this.IsConfirmed,
                IsEnabled: this.IsEnabled,
                newHouseName: that.newHouseName,
                areaIds: that.daQuInclude.join(","),
                priceFrom: that.priceFrom,
                priceTo: that.priceTo,
                shouMaiLeiXing: that.shouMaiLeiXing,
                jiaoYiQuanShuId: that.jiaoYiQuanShuId,
                sortBy: that.sortBy,
                currentPage: that.currentPage,
                pageSize: that.pageSize
            }
            localStorage.setItem("newFangParams", JSON.stringify(params))
            localStorage.setItem("newFangActiveName", that.activeName)

            that.$http.post(that.$url.NewHouseList, params).then((res) => {
                if (res.data.msgCode == 1) {
                    let list = res.data.dtList
                    for (var i = 0; i < list.length; i++) {
                        list[i].visible = false
                    }
                    this.HouseList = list
                    this.total = res.data.maxRecords
                }
                that.loading = false
            })
        },
        tableCellClassName({ row, column, rowIndex, columnIndex }) {
            //注意这里是解构
            //利用单元格的 className 的回调方法，给行列索引赋值
            row.index = rowIndex
            column.index = columnIndex
        },
        changeLouCeng(row, column, cell, event) {
            let that = this
            if (column.index == 6) {
                if (
                    Array(that.cengshu).indexOf("低") != -1 ||
                    Array(that.cengshu).indexOf("中") != -1 ||
                    Array(that.cengshu).indexOf("高") != -1
                ) {
                    if (
                        that.list1[row.index] <=
                        that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = that.list1[row.index]
                    } else if (
                        that.list1[row.index] >=
                        that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = that.list1[row.index]
                    } else if (
                        that.list1[row.index] >
                            that.HouseList[row.index].floorCount / 3 &&
                        that.list1[row.index] <
                            that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = that.list1[row.index]
                    }
                    row.louCeng = that.cengshu
                } else {
                    if (
                        that.list1[row.index] <=
                        that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = "低"
                    } else if (
                        that.list1[row.index] >=
                        that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = "高"
                    } else if (
                        that.list1[row.index] >
                            that.HouseList[row.index].floorCount / 3 &&
                        that.list1[row.index] <
                            that.HouseList[row.index].floorCount / 3
                    ) {
                        that.cengshu = "中"
                    }
                    row.louCeng = that.cengshu
                }
            }
        },
        resetHouseList() {
            let that = this
            this.activeName = "不限"
            this.newHouseName = ""
            this.daQuInclude = []
            this.priceFrom = 0
            this.priceTo = 0
            this.shouMaiLeiXing = ""
            this.jiaoYiQuanShuId = ""
            this.IsConfirmed = ""
            this.currentPage = 1
            this.pageSize = 10
            this.changeClass("不限", "", "")
        },
        //列表排序
        sortChange(column) {
            if (column.order == "ascending") {
                if (column.prop == "price") {
                    this.sortBy = "nh.price ASC"
                }
            } else if (column.order == "descending") {
                if (column.prop == "price") {
                    this.sortBy = "nh.price DESC"
                }
            }
            this.getHouseList()
        },
        handleClick(tab, event) {
            this.daQuInclude = []

            if (event.currentTarget.innerText == "不限") {
                this.getHouseList()
            }
        },
        CheckPrice() {
            if (parseInt(this.priceFrom) > parseInt(this.priceTo)) {
                // this.$message.error("最小价格不能大于最大价格!")
                let temp = ""
                temp = this.priceFrom
                this.priceFrom = this.priceTo
                this.priceTo = temp
            }
            this.getHouseList()
        },
        changeClass(label, value, type) {
            if (type == "fanwei") {
                if (label == "不限") {
                    this.IsEnabled = ""
                } else {
                    this.IsEnabled = value
                }
                this.isActive1 = value
            } else if (type == "price") {
                if (value == "") value = "0,0"
                this.isActive3 = value
                let list = value.split(",")
                this.priceFrom = list[0]
                this.priceTo = list[1]
            } else if (type == "shoumai") {
                if (label == "不限") {
                    this.shouMaiLeiXing = ""
                } else {
                    this.shouMaiLeiXing = label
                }
                this.isActive7 = label
            } else if (type == "jiaoyi") {
                this.isActive12 = value
                this.jiaoYiQuanShuId = value
                if(value==""){
                    this.isActive12 = "不限"
                }
            } else if (type == "shenhe") {
                this.isActiveShenHe = value
                if (label == "不限") {
                    this.IsConfirmed = ""
                } else if (label == "审核已通过") {
                    this.IsConfirmed = "1"
                    console.log(this.IsConfirmed)
                } else {
                    this.IsConfirmed = "0"
                    console.log(this.IsConfirmed)
                }
            } else {
                this.isActive1 = ""
                this.isActive3 = "0,0"
                this.isActive7 = "不限"
                this.isActive12 = "不限"
                this.isActiveShenHe = ""
            }
            this.getHouseList()
        },
        openDetail(row, num, state) {
            if (num == 1) {
                this.addTab(
                    row.newHouseName,
                    "XinFangDetail?newHouseId=" +
                        row.newHouseId +
                        "&num=" +
                        num +
                        "&state=" +
                        state
                )
            }

            this.$router.push({
                name: "XinFangDetail",
                query: {
                    newHouseId: row.newHouseId,
                    num: num,
                    state: state
                }
            })
        },
        // 点击导航栏新增tab页
        addTab(targetName, path) {
            let flag = true //判断是否需要新增页面
            var tabs = this.editableTabs //活跃当前tab数组
            for (var i = 0; i < tabs.length; i++) {
                //如果存在相同的tab页 不新增tab页
                if (tabs[i].path === path) {
                    this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
                    this.$router.push(path)
                    flag = false
                }
            }
            // 需要新增页面
            if (flag) {
                let newTabName = this.editableTabs.length.toString()
                this.editableTabs.push({
                    title: targetName,
                    name: newTabName,
                    path: path
                })
                this.editableTabsValue = newTabName
                window.localStorage.setItem(
                    "editableTabs",
                    JSON.stringify(this.editableTabs)
                )
            }
            this.reload()
            window.localStorage.setItem("activeIndex", path)
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        }, // 删除数据
        handleDelete(row, type) {
            var data = []
            if (type == "one") {
                data.push(row.newHouseId)
            } else if (type == "tow") {
                if (this.multipleSelection.length == 0) {
                    this.$confirm("请勾选数据", "提示", {
                        type: "warning"
                    })
                    return false
                } else {
                    for (var i = 0; i < this.multipleSelection.length; i++) {
                        data.push(this.multipleSelection[i].newHouseId)
                    }
                }
            }
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true
                this.$http
                    .delete(this.$url.DeleteNewHouse, {
                        data: data,
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    .then((res) => {
                        this.loading = false
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.getHouseList()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.data.message
                            })
                        }
                    })
                    .catch((err) => {
                        this.loading = false
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            })
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getHouseList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getHouseList()
        },
        guanzhu(index) {
            var title
            if (index.favourite == "0") {
                title = "关注"
            } else {
                title = "取消关注"
            }
            this.$confirm("此操作将" + title + "该房源, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    const { data: res } = await this.$http.post(
                        this.$url.SubmitNewHouseFavorite,
                        {
                            NewHouseId: index.newHouseId
                        }
                    )
                    if (res.msgCode == 1) {
                        this.getHouseList()
                    }
                })
                .catch((err) => err)
        },
        async wuXiaoFangYuan(row) {
            //无效房源
            let title = "",
                enabled = ""
            if (row.enabled == "0") {
                title = "有效房源"
                enabled = "1"
            } else {
                title = "无效房源"
                enabled = "0"
            }
            this.$confirm("要将该房源设置为" + title + "?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(async () => {
                    await this.$http
                        .put(this.$url.UpdateNewHouseEnabled, {
                            NewHouseId: row.newHouseId,
                            Enabled: enabled
                        })
                        .then((res) => {
                            if (res.data.msgCode == "1") {
                                this.getHouseList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
.title {
    font-size: 14px;
}
.el-checkbox {
    margin-right: 10px;
}
.caozuo {
    margin-left: 3px !important;
}
.font-class-red {
    color: red !important;
}
.font-class-green {
    color: rgb(19, 177, 19) !important;
}
.font-class-blue {
    color: #1890ff !important;
}
.font-class-black {
    color: #000 !important;
}
.fontColorRed {
    color: red;
}
.fontColorBlack {
    color: black;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}

/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
.business {
    background: #fff;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    margin-left: 1.5% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0 3px;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>
<style>
.el-cascader__suggestion-panel {
    height: 204px;
}
</style>