<template>
    <div>
        <el-card class="intanle">
            <span class="h1">全部待办事项</span>
            <el-button size="mini" @click="goBack" class="el-button1" type="primary" style="float: right">返回</el-button>
        </el-card>
        <el-card>
            <el-table ref="multipleTable"  v-loading="loading" :data="toDolist" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                <el-table-column label="楼盘名称" prop="louPanName">
                    <!-- <template slot-scope="scope">
                        <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.louPanName }}</el-button>
                    </template> -->
                </el-table-column>
                <el-table-column label="楼盘编号" prop="houseNo"></el-table-column>
                <el-table-column label="户型" prop="huXingName"></el-table-column>
                <el-table-column label="楼层" prop="louceng"></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope" class="right">
                        <el-button @click="examine(scope.row)" type="text" size="small"><i class="el-icon-edit"></i>审核</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                    <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                </div>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            operationList: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            toDolist: []
        }
    },
    created() {
        this.getToDoConfirmList()
    },
    methods: {
        // 获取待办事项列表
        async getToDoConfirmList() {
            const data = {
                currentPage: 1,
                pageSize: 13
            }
            const { data: res } = await this.$http.get(
                this.$url.GetToDoConfirmList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.$message.success("成功")
                this.toDolist = res.dtList
                console.log(this.toDolist)
            }
        },
        // 审核
        examine(row) {
            console.log(row)
        },
        goBack() {
            this.$router.go(-1)
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        handleSizeChange(newSize) {
            this.pageSize = newSize
            // this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            // this.getArticleList()
        },
        xiangqing(index, row) {
            this.$router.push()
        },
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {})
                .catch((err) => {})
        }
    }
}
</script>