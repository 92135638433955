<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/GongZiList' }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工资月份：" label-width="20%" prop="yyyyMM">
                            <el-date-picker v-model="form.yyyyMM" value-format="yyyyMM" type="month" :disabled="num!=0" placeholder="请选择工资月份" style="width: 60%" class="media-width" :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经纪人:" label-width="20%" prop="userId">
                            <el-button v-if="num == 0" size="mini" class="buttonInput button_tanchu" @click="clickUser" :disabled="flag" style="width: 60%;height: 22px;line-height: 0px;text-align: left;">
                                <span :style="{color: userName1 == '请选择经纪人' ? '#c0c4cc' : '#666',}">{{ userName1 }}</span>
                            </el-button>
                            <el-button v-if="num != 0" size="mini" class="buttonInput button_tanchu" @click="clickUser" disabled style="background-color: #F5F7FA; border-color: #E4E7ED; color: #ccc;width: 60%;height: 22px;line-height:0px;text-align: left;">
                                <span style="color:#c0c4cc">{{ userName1 }}</span>
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="基本工资" label-width="20%" prop="basicSalary">
                            <el-input placeholder="请输入基本工资" :disabled="flag" v-model="form.basicSalary" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col v-if="roleType==6" :span="12">
                        <el-form-item label="商户" label-width="20%" prop="merchantId">
                            <el-input placeholder="请选择商户" disabled v-model="form.merchantname" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="店铺" label-width="20%" prop="shopId">
                            <el-input placeholder="请选择店铺" disabled v-model="form.shopname" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="绩效工资" label-width="20%" prop="performanceSalary">
                            <el-input placeholder="请输入绩效工资" :disabled="flag" v-model="form.performanceSalary" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="个税" label-width="20%" prop="personalTax">
                            <el-input placeholder="请输入个税" :disabled="flag" v-model="form.personalTax" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="罚款" label-width="20%" prop="penalty">
                            <el-input placeholder="请输入罚款" :disabled="flag" v-model="form.penalty" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="实发工资" label-width="20%" prop="remark">
                            <el-input placeholder="请输入实发工资" :disabled="flag" v-model="form.remark" style="width: 60%"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注" label-width="10%" prop="remark">
                            <el-input style="width:82.5%" :disabled="flag" v-model="form.remark" type="textarea" :rows="5" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-dialog :visible.sync="weihurenDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
            <el-card>
                <el-row>
                    <el-col>
                        <p>
                            <span class="el-form-item__label" style="line-height: 40px">经纪人名称：</span>
                            <el-input v-model="userName" placeholder="请输入经纪人名称" style="width: 50%"></el-input>
                            <span style="float: right">
                                <el-button size="mini" type="primary" style="margin-right: 10px" @click="getUserList">查询</el-button>
                                <el-button size="mini" type="warning" @click="ResetWeihuren">重置</el-button>
                            </span>
                        </p>
                    </el-col>
                </el-row>
            </el-card>
            <el-table ref="multipleTable" v-loading="weihurenDialogLoading" :data="weihurenDialogList" @row-click="weihurenDialogDetails" tooltip-effect="dark" :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px',
        }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column label="用户名" prop="userName">
                    <template slot-scope="scope">
                        {{ scope.row.userName }}
                    </template>
                </el-table-column>
                <el-table-column label="性别" prop="gender"></el-table-column>
                <el-table-column label="手机号" prop="mobile1"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-row>
                <el-pagination style="width: 70%; float: right" @size-change="weihurenDialogSizeChange" @current-change="weihurenDialogCurrentChange" :current-page="userCurrentPage" :page-sizes="[5, 10, 20]" :page-size="userPageSize" layout="total, sizes, prev, pager, next, jumper" :total="weihurenDialogTotal"></el-pagination>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            roleType: "",
            weihurenDialogTotal: 0,
            userCurrentPage: 1,
            userPageSize: 10,
            weihurenDialogList: [],
            weihurenDialogLoading: false,
            userName: "",
            weihurenDialog: false,
            userName1: "请选择经纪人",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            num: this.$route.query.num,
            salaryId: this.$route.query.salaryId,
            name: "",
            flag: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            form: {
                merchantId: "",
                shopId: "",
                userId: "",
                merchantname: "",
                shopname: "",
                username: "",
                yyyyMM: "",
                basicSalary: "",
                performanceSalary: "",
                personalTax: "",
                penalty: "",
                remark: ""
            },
            currentPage: 1,
            pageSize: 10,
            rules: {
                merchantId: [
                    { required: true, message: "请选择商户", trigger: "blur" }
                ],
                shopId: [
                    { required: true, message: "请选择店铺", trigger: "blur" }
                ],
                userId: [
                    { required: true, message: "请选择经纪人", trigger: "blur" }
                ],
                yyyyMM: [
                    {
                        required: true,
                        message: "请选择工资月份",
                        trigger: "blur"
                    }
                ],
                basicSalary: [
                    {
                        required: true,
                        message: "请输入基础工资",
                        trigger: "blur"
                    }
                ],
                performanceSalary: [
                    {
                        required: true,
                        message: "请输入绩效工资",
                        trigger: "blur"
                    }
                ],
                personalTax: [
                    { required: true, message: "请输入个税", trigger: "blur" }
                ],
                penalty: [
                    { required: true, message: "请输入罚款", trigger: "blur" }
                ]
                // remark: [
                //     {
                //         required: true,
                //         message: "请选择实发工资",
                //         trigger: "blur"
                //     }
                // ]
            }
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "工资新增"
            this.form = {}
        } else if (this.num == 1) {
            this.name = "工资详情"
            this.flag = true
            this.GetSalaryDetail()
        } else if (this.num == 2) {
            this.name = "工资修改"
            this.GetSalaryDetail()
        }
    },
    methods: {
        weihurenDialogSizeChange(newSize) {
            this.userPageSize = newSize
            this.getUserList()
        },
        /* 监听页码值改变的事件 */
        weihurenDialogCurrentChange(newPage) {
            this.userCurrentPage = newPage
            this.getUserList()
        },
        // 选中经纪人
        weihurenDialogDetails(row) {
            console.log(row)
            let that = this
            that.userName = row.userName
            that.userName1 = row.userName
            that.form.userId = row.userId
            that.form.shopId = row.shopId
            that.form.shopname = row.shopName
            that.form.merchantId = row.merchantId
            that.form.merchantname = row.merchantName
            setTimeout(function () {
                that.$refs.form.clearValidate(["userId"]) // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
            }, 30)
            that.weihurenDialog = false
        },
        // 获取经纪人列表
        getUserList() {
            let that = this
            that.weihurenDialogLoading = true
            that.$http
                .get(that.$url.GetJingJiRenList, {
                    params: {
                        isHomePage: "0",
                        userName: that.userName,
                        currentPage: encodeURIComponent(that.userCurrentPage),
                        pageSize: encodeURIComponent(that.userPageSize)
                    }
                })
                .then(function (res) {
                    if (res.data.msgCode == 1) {
                        that.weihurenDialogLoading = false
                        that.weihurenDialogList = res.data.dtList
                        that.loading = false
                        that.total = res.data.maxRecords
                        for (var i = 0; i < res.data.dtList.length; i++) {
                            if (res.data.dtList[i].gender === "1") {
                                that.weihurenDialogList[i].gender = "男"
                            } else if (res.data.dtList[i].gender === "2") {
                                that.weihurenDialogList[i].gender = "女"
                            }

                            if (res.data.dtList[i].userType === "1") {
                                that.weihurenDialogList[i].userType = "经纪人"
                            } else if (res.data.dtList[i].userType === "2") {
                                that.weihurenDialogList[i].userType = "商圈经理"
                            } else if (res.data.dtList[i].userType === "3") {
                                that.weihurenDialogList[i].userType = "商户财务"
                            } else if (res.data.dtList[i].userType === "4") {
                                that.weihurenDialogList[i].userType =
                                    "人事专员,"
                            } else if (res.data.dtList[i].userType === "5") {
                                that.weihurenDialogList[i].userType = "店东"
                            } else if (res.data.dtList[i].userType === "6") {
                                that.weihurenDialogList[i].userType =
                                    "平台管理员"
                            } else if (res.data.dtList[i].userType === "7") {
                                that.weihurenDialogList[i].userType = "交易中心"
                            }

                            if (res.data.dtList[i].enabled === "1") {
                                that.weihurenDialogList[i].enabled = "是"
                            } else if (res.data.dtList[i].enabled === "0") {
                                that.weihurenDialogList[i].enabled = "否"
                            }
                        }
                        that.weihurenDialogTotal = res.data.maxRecords
                    } else {
                        that.weihurenDialogLoading = false
                        var message = that.$commonJs.getUrlError(
                            res.data.message
                        )
                        this.$message.error(message)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //重置维经纪人
        ResetWeihuren() {
            this.userName = ""
            this.userName1 = ""
            this.getUserList()
        },
        //选择经纪人
        clickUser(type) {
            this.weihurenDialog = true
            this.weihutype = type
            this.userName = ""
            this.getUserList()
        },
        // 新增
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        merchantId: this.form.merchantId,
                        shopId: this.form.shopId,
                        userId: this.form.userId,
                        yyyyMM: this.form.yyyyMM.replace("-", ""),
                        basicSalary: this.form.basicSalary,
                        performanceSalary: this.form.performanceSalary,
                        personalTax: this.form.personalTax,
                        penalty: this.form.penalty,
                        remark: this.form.remark
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.InsertSalary,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增工资信息成功")
                        this.$router.push({ path: "/GongZiList" })
                    } else {
                        this.$message.error(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // 修改
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        salaryId: this.salaryId,
                        merchantId: this.form.merchantId,
                        shopId: this.form.shopId,
                        userId: this.form.userId,
                        yyyyMM: this.form.yyyyMM.replace("-", ""),
                        basicSalary: this.form.basicSalary,
                        performanceSalary: this.form.performanceSalary,
                        personalTax: this.form.personalTax,
                        penalty: this.form.penalty,
                        remark: this.form.remark
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateSalary,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改工资信息成功")
                        this.$router.push({ path: "/GongZiList" })
                    } else {
                        // var message = commonJs.getUrlError(res.message)
                        this.$message.error(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // 详情
        async GetSalaryDetail() {
            await this.$http
                .get(this.$url.GetSalaryDetail, {
                    params: {
                        salaryId: encodeURIComponent(this.salaryId)
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        this.userName1 = this.form.salaryUserName
                        this.form.shopname = this.form.shopName
                        this.form.merchantname = this.form.merchantName
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        //取消
        cancel() {
            this.$router.push({ path: "/GongZiList" })
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>