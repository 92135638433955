<template>
    <div class="qianyue">
        <el-card class="intanle">
            <span v-if="state == '2'" class="h1">签约(合同)修改</span>
            <span v-if="state == '0'" class="h1">签约(合同)新增</span>
            <el-button style="float: right" size="mini" @click="goBack" class="el-button" type="primary">返回</el-button>
            <el-button v-if="contractStatus == '10'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="draft()">保存草稿</el-button>
            <!-- <el-button v-if="contractStatus=='10'" style="float: right" class="el-button1" type="warning" size="mini" @click="apply()">提交申请</el-button> -->
            <el-button v-if="contractStatus == '10' && roleType != 1" style="float: right" class="el-button1" type="warning"
                size="mini" @click="applyPass()">审核通过</el-button>
            <el-button v-if="contractStatus == '20'" style="float: right" class="el-button1" type="warning" size="mini"
                @click="save()">保存合同</el-button>
            <el-button v-if="contractStatus == '30'" style="float: right" class="el-button1" size="mini" type="warning"
                @click="Submit()">电子签章</el-button>
            <el-button v-if="roleType == 2 || roleType == 5 || roleType == 6" style="float: right" class="el-button1" size="mini"
                type="warning" @click="onSave(2)">下载合同</el-button>
        </el-card>
        <el-backtop></el-backtop>
        <el-card class="body">
            <h3 class="text_align_r">
                <span>合同编号：</span><input :disabled="flag" class="inputW_150 input_borderBottom_ccc"
                    v-model="form.contractNo" />
            </h3>

            <div class="quote_info clearfix">
                <h1 class="h3_title">房屋租赁合同</h1>

                <h2 class="h2_title">签约提示</h2>
                <p>
                    <span>1、请您确认，在签署合同前，</span><span
                        class="font_weight border_bottom">您阅读过合同条款并予以理解和接受，</span><span>同时我公司经办人员已向您告知与租赁房屋及合同签署有关的信息。</span>
                </p>
                <p>
                    <span>2、请您核查一式三份合同的内容（包括手工填写的部分）应</span><span class="font_weight border_bottom">完全一致。</span>
                </p>
                <p>
                    <span>3、在您签署的合同中，除需手动填写的地方以外，其他部分均</span><span
                        class="font_weight border_bottom">不得随意进行手写补充和修改，</span><span>否则将会影响合同或条款的有效性；如您确实需要对合同中条款进行补充或修改的，请您及其他签署方在补充或修改处签字确认并加盖我司公章后，方为生效。</span>
                </p>
                <p>
                    <span>4、在您向我司支付居间代理费或服务费时，</span><span
                        class="font_weight border_bottom">请向我司经办人员索取由我司加盖印章的专用收据；</span><span>如该经办人员不能提供上述专用收据的，</span><span
                        class="font_weight border_bottom">请您务必谨慎付款，</span><span>必要时，请与您签约的店面经理进行确认。</span>
                </p>
                <p>
                    <span>5、在填写相关合同文本时，如遇</span><span class="font_weight">“【】”</span><span>或</span><span
                        class="font_weight">“□”</span><span>等需要选择的内容，</span><span
                        class="font_weight border_bottom">请务必做出明确的勾选，</span><span>以划“√”方式选定您选择的事项；对于实际情况未发生，或双方当事人不作约定、或该条不适用时，应当划“×”，以示删除。</span>
                </p>
                <p>
                    6、为了保障您的资金安全，请您在向我司支付居间代理费及服务费时，优先选择如下方式:POS机刷卡----您可以使用本人或亲朋的银行储蓄卡或信用卡刷卡（本地卡、异地卡均可），刷卡后请持卡人在POS小票上亲笔签字。
                </p>
                <p class="font_weight">
                    7、特别提示：请勿向我公司经纪人支付现金，由其代为支付居间代理费。
                </p>
            </div>

            <div class="quote_info clearfix">
                <h2 class="h2_title margin_top_50">交易方留存信息表</h2>
                <h3 class="">房屋出租方</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chuZu_ChuShengRiQi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_ZhengJianMing" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerIdCardNo" @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">联系电话</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.sellerMobile" :maxlength="11"
                            @blur="rulesMobile(1)" />
                    </li>
                    <li class="width_10">电子邮箱</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_Email" @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZu_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3 class="">房屋出租方代理人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_Name" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chuZuDL_ChuShengRiQi"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_ZhengJianMing" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_ZhengJianNum"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">联系电话</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_Phone" :maxlength="11"
                            @blur="rulesMobile(2)" />
                    </li>
                    <li class="width_10">电子邮箱</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_Email" @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chuZuDL_Address" />
                    </li>
                </div>
            </div>
            <h3 class="quote_info margin_top_20">
                <div>
                    <span>甲方（出租方）:</span><input :disabled="flag" class="inputW_150 input_borderBottom_000"
                        v-model="form.chuZu1" />
                    <span class="font_weight600" style="text-align: right; float: right"><input :disabled="flag"
                            class="inputW_80px input_borderBottom_000" v-model="form.chuZu_Year" :maxlength="4" />年<input
                            :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.chuZu_Month"
                            :maxlength="2" @blur="rulesDate(1, 'chuZu_Month')" />月<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.chuZu_Day" :maxlength="2"
                            @blur="rulesDate(2, 'chuZu_Day')" />日</span>
                </div>
            </h3>

            <div class="quote_info clearfix margin_top_100">
                <h3 class="">房屋承租方</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chengZu_ChuShengRiQi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_ZhengJianMing" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerIdCardNo" @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">联系电话</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.buyerMobile" :maxlength="11"
                            @blur="rulesMobile(3)" />
                    </li>
                    <li class="width_10">电子邮箱</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_Email" @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZu_Address" />
                    </li>
                </div>
            </div>
            <div class="quote_info clearfix">
                <h3 class="">房屋承租方代理人</h3>
            </div>
            <div class="quote_info clearfix border_000">
                <div class="display_flex">
                    <li class="width_10">姓名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_Name" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">英文名</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_YingWenName" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">性别</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_Sex" />
                    </li>
                    <li class="width_10">国籍</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_GuoJi" />
                    </li>
                    <li class="width_10">出生日期</li>
                    <li class="inputW_23">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_ChuShengRiQi" />
                        <!-- <el-date-picker
              class="width_full"
              prefix-icon="none"
              v-model="form.chengZuDL_ChuShengRiQi"
              type="date"
              placeholder="x"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">证件名称</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_ZhengJianMing" />
                    </li>
                    <li class="width_10">证件号码</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_ZhengJianNum"
                            @blur="rulesIdNo" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">联系电话</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_Phone" :maxlength="11"
                            @blur="rulesMobile(4)" />
                    </li>
                    <li class="width_10">电子邮箱</li>
                    <li class="inputW_40">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_Email" @blur="onlyRules" />
                    </li>
                </div>
                <div class="display_flex">
                    <li class="width_10">通讯地址</li>
                    <li class="width_90">
                        <input :disabled="flag" class="width_98 border0" v-model="form.chengZuDL_Address" />
                    </li>
                </div>
            </div>
            <h3 class="quote_info margin_top_20">
                <div>
                    <span>乙方（承租方）:</span><input :disabled="flag" class="inputW_150 input_borderBottom_000"
                        v-model="form.chengZu1" />
                    <span class="font_weight600" style="text-align: right; float: right"><input :disabled="flag"
                            class="inputW_80px input_borderBottom_000" v-model="form.chengZu_Year" :maxlength="4" />年<input
                            :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.chengZu_Month"
                            :maxlength="2" @blur="rulesDate(3, 'chengZu_Month')" />月<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.chengZu_Day" :maxlength="2"
                            @blur="rulesDate(4, 'chengZu_Day')" />日</span>
                </div>
                <div class="margin_top_20 font_weight500">
                    本合同签署日期：<span class="font_weight600"><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                            v-model="form.qianShu_Year" :maxlength="4" />年<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month" :maxlength="2"
                            @blur="rulesDate(5, 'qianShu_Month')" />月<input :disabled="flag"
                            class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Day" :maxlength="2"
                            @blur="rulesDate(6, 'qianShu_Day')" />日</span>
                </div>
            </h3>

            <div class="quote_info clearfix font_weight500">
                <h2 class="h2_title margin_top_50">房屋租赁合同</h2>
                <h3 class="font_weight500">
                    房屋出租方（甲方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.chuZu2" />
                </h3>
                <h3 class="font_weight500">
                    房屋承租方（乙方）：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.chengZu2" />
                </h3>
                <h3 class="font_weight500">
                    居间方（丙方）：<input :disabled="flag" class="inputW_23 input_borderBottom_000" v-model="form.bingFang1" />
                </h3>

                <h3 class="font_weight500">
                    根据《中华人民共和国合同法》及有关法律、法规的规定，甲方与乙方在丙方的居间服务下，在平等、自愿的基础上，就房屋租赁的有关事宜达成协议如下：
                </h3>

                <h3 class="font_weight500">第一条 房屋基本情况</h3>
                <p>
                    （一）房屋坐落于大连市<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.fangWu_Address" />的房屋（以下简称“该房屋”），建筑面积<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000"
                        v-model="form.fangWu_MianJi" />平方米及其设备（见交接单），房屋具体面积与地址以房产证及相关正式法律文书上的记载为准。
                </p>
                <p>
                    （二）房屋权属状况：权属<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.quanShu" />，房屋所有权证书编号:<input :disabled="flag" class="inputW_40 input_borderBottom_000"
                        v-model="form.suoYouQuanHao" />,共同权证证号为<input :disabled="flag"
                        class="inputW_15 input_borderBottom_000" v-model="form.gongTongQuanHao" />,房屋所有权人姓名或名称:<input
                        :disabled="flag" class="inputW_15 input_borderBottom_000" v-model="form.suoYouQuanName" />。
                </p>

                <h3 class="font_weight500">第二条 房屋租赁情况</h3>
                <p>
                    租赁用途：<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.FWYT" />；如租赁用途为居住的，居住人数为:<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.juZhuRen" />最多不超过<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zuiDuoJuZhuRen" />人。
                </p>

                <h3 class="font_weight500">第三条 租赁期限</h3>
                <p>
                    （一）房屋租赁期自<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zuLinFromYear"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zuLinFromMonth" :maxlength="2" @blur="rulesDate(7, 'zuLinFromMonth')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zuLinFromDay"
                        :maxlength="2" @blur="rulesDate(8, 'zuLinFromDay')" />日 至<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zuLinToYear" :maxlength="4" />年<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zuLinToMonth"
                        :maxlength="2" @blur="rulesDate(9, 'zuLinToMonth')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.zuLinToDay" :maxlength="2"
                        @blur="rulesDate(10, 'zuLinToDay')" />日 共计<input class="inputW_80px input_borderBottom_000"
                        v-model="form.zuLinJiaoFuGJDSY" />个月， 甲方应于<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zuLinJiaoFuYear" :maxlength="4" />年<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zuLinJiaoFuMonth"
                        :maxlength="2" @blur="rulesDate(11, 'zuLinJiaoFuMonth')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.zuLinJiaoFuDay" :maxlength="2"
                        @blur="rulesDate(12, 'zuLinJiaoFuDay')" />日前将房屋按约定条件交付给乙方。
                </p>
                <p>
                    （二）租赁期满，如乙方欲继续承租该房屋，须在租期届满前<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.zuQiJieMan_Month" />
                    个月内以书面形式向甲方提出续租申请，甲乙双方可另行协商续租事宜。
                </p>

                <h3 class="font_weight500">第四条 租金及费用</h3>
                <p>
                    （一）月租金标准及支付方式：人民币（小写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.YueZu_XiaoXieYuan" @blur="moneyRule('YueZu_XiaoXieYuan')" />
                    元，人民币（大写）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                        v-model="form.YueZu_DaXieYuan" />。
                </p>
                <p>
                    （二）租赁期间，由甲方承担：
                    <label v-for="item in JiaFangFeiYongOptions" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedJiaFangFeiYong" />{{ item.name }}
                    </label>
                    <span><input :disabled="flag" class="inputW_80px input_borderBottom_000"
                            v-model="form.jf_QiTaFeiYong" />。由乙方承担：
                    </span>

                    <label style="vertical-align: sub" v-for="item in YiFangFeiYongOptions" :label="item.name"
                        :key="item.id"><input :disabled="flag" :value="item.id" type="checkbox"
                            v-model="formCheck.checkedYiFangFeiYong" />
                        {{ item.name }}
                    </label>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yf_QiTaFeiYong" />。
                </p>
                <p>
                    （三）租金的付款方式为：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJFKFSRadio"
                            label="1">月</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJFKFSRadio"
                            label="2">季付</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJFKFSRadio"
                            label="3">半年付</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJFKFSRadio"
                            label="4">年付</el-radio>
                    </span>，以
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJZFFSRadio"
                            label="1">第三方平台支付（微信、支付宝等）</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJZFFSRadio"
                            label="2">转账支票</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJZFFSRadio"
                            label="3">银行汇款</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJZFFSRadio"
                            label="4">银行转账</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.ZJZFFSRadio"
                            label="5">现金</el-radio>
                    </span>
                </p>
                <p>（四）租金的结算时间：</p>
                <p class="margin_top_10">
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year1"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month1" :maxlength="2" @blur="rulesDate(13, 'zj_Month1')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day1" :maxlength="2"
                        @blur="rulesDate(14, 'zj_Day1')" />日支付
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year2"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month2" :maxlength="2" @blur="rulesDate(15, 'zj_Month2')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day2" :maxlength="2"
                        @blur="rulesDate(16, 'zj_Day2')" />日至
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year3"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month3" @blur="rulesDate(17, 'zj_Month3')" />月<input :disabled="flag"
                        class="inputW_50px input_borderBottom_000" v-model="form.zj_Day3" :maxlength="2"
                        @blur="rulesDate(18, 'zj_Day3')" />日的租金，共计<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zj_Money1"
                        @blur="moneyRule('zj_Money1')" />元；
                </p>
                <p class="">
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year4"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month4" :maxlength="2" @blur="rulesDate(19, 'zj_Month4')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day4" :maxlength="2"
                        @blur="rulesDate(20, 'zj_Day4')" />日支付
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year5"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month5" :maxlength="2" @blur="rulesDate(21, 'zj_Month5')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day5" :maxlength="2"
                        @blur="rulesDate(22, 'zj_Day5')" />日至
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year6"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month6" :maxlength="2" @blur="rulesDate(23, 'zj_Month6')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day6" :maxlength="2"
                        @blur="rulesDate(24, 'zj_Day6')" />日的租金，共计<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zj_Money2"
                        @blur="moneyRule('zj_Money2')" />元；
                </p>
                <p class="">
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year7"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month7" :maxlength="2" @blur="rulesDate(25, 'zj_Month7')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day7" :maxlength="2"
                        @blur="rulesDate(26, 'zj_Day7')" />日支付
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year8"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month8" :maxlength="2" @blur="rulesDate(27, 'zj_Month8')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day8" :maxlength="2"
                        @blur="rulesDate(28, 'zj_Day8')" />日至
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year9"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month9" :maxlength="2" @blur="rulesDate(29, 'zj_Month9')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day9" :maxlength="2"
                        @blur="rulesDate(30, 'zj_Day9')" />日的租金，共计<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zj_Money3"
                        @blur="moneyRule('zj_Money3')" />元；
                </p>
                <p class="">
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year10"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month10" :maxlength="2" @blur="rulesDate(31, 'zj_Month10')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day10" :maxlength="2"
                        @blur="rulesDate(32, 'zj_Day10')" />日支付
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year11"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month11" :maxlength="2" @blur="rulesDate(33, 'zj_Month11')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day11" :maxlength="2"
                        @blur="rulesDate(34, 'zj_Day11')" />日至
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.zj_Year12"
                        :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000"
                        v-model="form.zj_Month12" :maxlength="2" @blur="rulesDate(35, 'zj_Month12')" />月<input
                        :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.zj_Day12" :maxlength="2"
                        @blur="rulesDate(36, 'zj_Day12')" />日的租金，共计<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.zj_Money4"
                        @blur="moneyRule('zj_Money4')" />元；
                </p>
                <p class="text-indent">
                    在本合同履行期内，如发生政府有关部门征收本合同未约定的费用项目，与乙方使用、居住或经营该房屋有关的费用，均由乙方承担，除此以外的费用由甲方承担。租赁期间乙方如需开具租赁等相关发票，费用由乙方承担。
                </p>
                <p>（五）房屋租金及各项费用，租赁双方自行转付，居间方不代收代转。</p>

                <h3 class="font_weight500">第五条 房屋维护及维修</h3>
                <p>
                    （一）甲方应保证出租房屋的建筑结构和设备设施符合建筑、消防、治安、卫生等方面的安全条件，不得危及人身安全；乙方保证遵守国家相关的法律法规规定以及房屋所在小区的物业管理规约。
                </p>
                <p>
                    （二）租赁期内，甲乙双方应共同保障该房屋及其附属物品、设备设施处于适用和安全的状态：
                </p>
                <p>
                    1、对于该房屋及其附属物品、设备设施因自然属性或合理使用而导致的损耗，乙方应及时通知甲方修复。甲方应在收到乙方通知后的七日内进行维修。逾期不维修的，乙方可代为维修，费用由甲方承担。因维修房屋影响乙方使用的，应相应减少租金或延长租赁期限。
                </p>
                <p>
                    2、因乙方保管不当或不合理使用，致使该房屋及其附属物品、设备设施发生损坏或故障的，乙方应负责维修或承担赔偿责任。
                </p>
                <p>
                    （三）甲方承诺，出租房屋在其本人或其近亲属持有期间，在房屋本体结构范围内
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFFSRadio" label="1">
                            <span>是</span>
                        </el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.SFFSRadio" label="2">
                            <span>否</span>
                        </el-radio>
                    </span>
                    发生过非正常死亡事件（包括但不限于自杀、他杀、从该房屋内坠出死亡、意外死亡等）。如甲方在签署本合同前就前述事项隐瞒真实情况的，各方确认：甲方构成欺诈，乙方有权依法撤销本合同，且甲方应赔偿乙方、丙方因此产生的全部损失。
                </p>

                <h3 class="font_weight500">第六条 履约保证金</h3>
                <p>
                    （一）乙方签署本合同时须向甲方支付人民币（小写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.BaoZhengJin_XiaoXieYuan" @blur="moneyRule('BaoZhengJin_XiaoXieYuan')" />
                    元，人民币（大写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.BaoZhengJin_DaXieYuan" />，作为履约保证金。
                </p>
                <p>
                    （二）履约保证金用做乙方履行本合同约定义务的担保，如乙方违反合同约定，甲方可于该保证金中直接扣除应由乙方承担的租金、违约金、赔偿金和相关费用。在本合同期内，如出现履约保证金不足以抵扣前述款项及费用时，乙方须在接到甲方通知后十日内补足，否则甲方有权解除本合同。
                </p>
                <p>
                    （三）本合同期满或提前终止时，甲方在扣除应由乙方承担的违约金、赔偿金和相关费用后，应将履约保证金剩余部分无息返还给乙方。
                </p>

                <h3 class="font_weight500">第七条 甲方承担责任</h3>
                <p>
                    （一）租赁期内，该房屋自然损坏的维修养护由甲方负责，除此以外由乙方自行负责维修。
                </p>
                <p>
                    （二）甲方需保证该房屋权属清楚，并保证共有人同意出租该房屋。同时，甲方需保证在签订本合同之前该房屋不存在出租、转让、查封等权利限制和相关债务，如有纠纷，由甲方负责处理。如给乙方造成损失的，甲方负责赔偿。
                </p>
                <p>
                    （三）在租赁期间，甲方如需将该房屋转让或抵押，甲方应提前三个月书面通知乙方并保证本合同的继续履行，乙方有该房屋的优先购买权。
                </p>
                <p>
                    （四）甲、乙双方确认，该房屋交付乙方前，与该房屋有关的水、电、煤气、采暖、物业等费用由甲方承担并负责结清；该房屋交付乙方后的租赁期内，与该房屋有关的相关费用按本合同的约定执行。
                </p>
                <p>
                    （五）如因甲方原因导致本合同解除的，甲方须向乙方支付违约赔偿金，赔偿金额为相当于本合同约定的<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.jf_ChengDanJiGeYue" />个月租金的金额。
                </p>

                <h3 class="font_weight500">第八条 乙方承担责任</h3>
                <p>
                    （一）在租赁期间，如出现需由甲方负责维修的情况时，乙方应及时通知甲方并积极协助，如因乙方延误通知或不配合给甲方以及第三方造成损失的，由乙方承担维修责任，无法维修的由乙方承担赔偿责任。
                </p>
                <p>
                    （二）本合同因双方协议解除或租期届满而终止履行时，乙方应在接到甲方解除合同的书面通知或租期届满之日迁出该房屋，并将该房屋及甲方所属内部设施、设备及乙方对该房屋所进行的装修（如有）完好无损的返还给甲方，如造成损坏，由乙方负责修缮或按价赔偿。
                </p>
                <p>
                    （三）乙方使用该房屋时，未经甲方书面同意，不得改变该房屋的用途；不得利用该房屋从事任何违法、非法、不道德、不恰当或有伤风化的活动；不得储存任何违禁品、易燃品、爆炸品等物品。同时必须严格遵守中华人民共和国法律和当地的有关规定，否则视为违约，自甲方提出限期整改之日起15天内仍未改正的，甲方有权解除本合同。
                </p>
                <p>
                    （四）如将房屋转租，必须征得甲方书面同意，并签订转租合同；如未经甲方同意并签订转租合同而擅自转租的，甲方可追究乙方违约责任，并有权解除合同。
                </p>
                <p>
                    （五）租赁期内，乙方欠缴本合同第四条第二款中的各项费用达一个月的，视为乙方违约，甲方有权解除本合同，并按照本合同第六条第二款处理。
                </p>
                <p>
                    （六）如乙方违反本合同第四条第四款的约定，每逾期一日，乙方应向甲方支付违约赔偿金，赔偿金额相当于应付款的千分之一；乙方无故拖延支付下一期租金（甲乙双方另有约定除外），逾期<input
                        :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.yf_YuQiDay" />日或逾期迁出该房屋时，甲方有权采取相关措施收回该房屋，并无需给予乙方任何赔偿、补偿，同时履约保证金不予退还，对于乙方遗留在该房屋内的任何财产、物品、文件、资料、设备等，甲方有权通过该房屋所在当地居委会或小区物业等相关部门，将上述物品妥善放置其他处所并书面通知乙方。
                </p>
                <p>
                    （七）如因乙方原因导致本合同解除的，乙方须向甲方支付违约赔偿金，赔偿金额相当与本合同约定的<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.yf_ChengDanJiGeYue" />个月租金。
                </p>

                <h3 class="font_weight500">
                    第九条 有以下情形之一而造成合同解除的，双方免责：
                </h3>
                <p>（一）因不可抗力的原因须提前解除的；</p>
                <p>（二）该房屋经房屋安全鉴定单位鉴定为危险房屋不能继续使用的；</p>
                <p>（三）因国家政策需要对该房屋进行拆迁或改造的。</p>

                <h3 class="font_weight500">第十条 居间方报酬与责任</h3>
                <p>
                    （一）基于居间方已提供居间服务，甲方须于本合同签订之日，一次性支付居间方居间服务费用人民币（小写）<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.jf_JuJianFangXiaoXieYuan"
                        @blur="moneyRule('jf_JuJianFangXiaoXieYuan')" />
                    元，人民币（大写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.jf_JuJianFangDaXieYuan" />，支付方式为POS机刷卡。乙方须一次性支付居间方居间服务费用人民币（小写）<input :disabled="flag"
                        class="inputW_80px input_borderBottom_000" v-model="form.yf_JuJianFangXiaoXieYuan"
                        @blur="moneyRule('yf_JuJianFangXiaoXieYuan')" />
                    元，人民币（大写）<input :disabled="flag" class="inputW_80px input_borderBottom_000"
                        v-model="form.yf_JuJianFangDaXieYuan" />，支付方式为POS机刷卡。此项费用只是作为甲、乙双方通过居间方出租与承租该房屋的居间服务费用。居间方在促成甲、乙双方签署本合同后即终止对甲乙双方的居间服务。
                    <span
                        class="font_weight600">甲、乙双方无论任何原因致使本合同不能正常履行（除居间方有过错外），由甲、乙双方自行解决，居间方服务费用将不予退还，</span>居间方只负责提供原始合同文本证明及双方其它相关证明的复印件（必须经得权利方书面同意）。
                </p>

                <h3 class="font_weight500">第十一条 其它</h3>
                <p>
                    （一）本合同的订立、生效、解释及争议，均适用中国法律。发生争议协商不成时，任何一方可向该房屋所在地人民法院起诉。
                </p>
                <p>
                    （二）本合同签署前三方在谈判中的声称、理解、承诺或以及相关协议内容，如有与本合同不相符之处，以本合同为准。
                </p>
                <p>
                    （三）合同一式三份，甲乙双方各执一份，丙方执一份，具同等法律效力。本合同自三方签字盖章之日起成立并生效。
                </p>
                <p>
                    （四）租赁双方保证在签署本合同时所确认的通讯地址真实有效，如日后需送达相关材料，以该地址为送达地址，相关材料邮寄该地址则视为送达对方，同时视为司法机关送达文书的依据。
                </p>

                <h3 class="font_weight500">
                    第十二条 居间方已做以下提醒，甲、乙双方充分知晓：
                </h3>
                <p>
                    （一）甲乙双方向居间方支付的款项，居间方均出具正式的收据或发票，请向收取人索取；
                </p>
                <p>（二）甲乙双方对合同约定的权利与义务已充分知晓并认可；</p>
                <p>（三）甲乙双方应向当地房屋租赁部门办理登记备案手续；</p>
                <p>（四）甲乙双方应向当地派出所办理治安责任登记；</p>
                <p>（五）甲乙双方应根据国家有关法律、法规交纳税费；</p>
                <p>
                    （六）每次缴租日之前，甲乙双方应对房屋各项使用费用的阶段费用进行核对，以免造成长时间欠缴；
                </p>
                <p>
                    （七）甲乙双方承诺就本合同提供的相关证明（房产证明、身份证明等）真实有效，已互验并认可，如有不实视为违约并承担违约责任；
                </p>
                <p>
                    （八）甲乙双方在签约时已见过对方本人或有授权书的代理人并认可对方的签约权利。
                </p>
                <p>（九）甲乙双方涉及境外手续的，由当事人自行办理。</p>
                <p>
                    （十）甲乙双方不要将款项交由居间方员工（无论任何职位）个人处保管，以保障甲乙双方资金安全，如因此产生损失，由甲乙双方自行承担。
                </p>

                <h3 class="font_weight500">第十三条 由甲乙双方确认的备件如下：</h3>
                <p>（一）签约当日甲方提供的证件原件有：</p>
                <p>
                    身份证明：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="1">身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="2">军/警官证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="3">护照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="4">往来港澳通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="5">企业营业执照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="6">组织机构代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="7">香港身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="8">港澳居民来往内地通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="9">台湾居民来往大陆通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="10">军/警身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="11">统一社会信用代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="12">往来台湾通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="13">澳门居民身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="14">税务登记证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMYJRadio"
                            label="15">其他</el-radio>
                    </span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.jf_SFYJQTVal" />
                </p>

                <p class="margin_top_20">
                    产权证明：
                    <label v-for="item in CQZYJOptions" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedCQZYJ" />{{ item.name }}
                    </label>
                    <!-- <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="1"
              >产权证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="2"
              >使用权证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="3"
              >购房合同及发票</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="4"
              >借款合同及发票</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="5"
              >军产证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="6"
              >地产证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMYJRadio"
              label="7"
              >其他</el-radio
            >
          </span> -->
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.jf_CQYJQTVal" />
                </p>

                <p class="margin_top_20">（二）签约当日乙方提供的证件原件有：</p>
                <p>
                    身份证明：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="1">身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="2">军/警官证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="3">护照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="4">往来港澳通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="5">企业营业执照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="6">组织机构代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="7">香港身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="8">港澳居民来往内地通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="9">台湾居民来往大陆通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="10">军/警身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="11">统一社会信用代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="12">往来台湾通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="13">澳门居民身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="14">税务登记证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMYJRadio"
                            label="15">其他</el-radio>
                    </span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yf_SFYJQTVal" />
                </p>

                <p class="margin_top_20">
                    （三）甲乙双方对证件复印件留存居间方的约定：
                </p>
                <p class="margin_top_10">1、甲乙双方同意由居间方留存的复印件有：</p>
                <p class="margin_top_20">甲方留存：</p>
                <p class="margin_top_10">
                    身份证明：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="1">身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="2">军/警官证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="3">护照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="4">往来港澳通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="5">企业营业执照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="6">组织机构代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="7">香港身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="8">港澳居民来往内地通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="9">台湾居民来往大陆通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="10">军/警身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="11">统一社会信用代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="12">往来台湾通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="13">澳门居民身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="14">税务登记证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.JFSFZMFYJRadio"
                            label="15">其他</el-radio>
                    </span>
                    <input class="inputW_80px input_borderBottom_000" v-model="form.jf_SFFYJQTVal" />
                </p>
                <p class="margin_top_10">
                    产权证明：
                    <label v-for="item in CQZFYJOptions" :label="item.name" :key="item.id"><input :disabled="flag"
                            :value="item.id" type="checkbox" v-model="formCheck.checkedCQZFYJ" />{{ item.name }}
                    </label>
                    <!-- <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="1"
              >产权证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="2"
              >使用权证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="3"
              >购房合同及发票</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="4"
              >借款合同及发票</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="5"
              >军产证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="6"
              >地产证</el-radio
            >
          </span>
          <span>
            <el-radio
              class="font_weight500"
              v-model="formCheck.JFCQZMFYJRadio"
              label="7"
              >其他</el-radio
            >
          </span> -->
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.jf_CQFYJQTVal" />
                </p>

                <p class="margin_top_20">乙方留存：</p>
                <p class="margin_top_10">
                    身份证明：
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="1">身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="2">军/警官证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="3">护照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="4">往来港澳通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="5">企业营业执照</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="6">组织机构代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="7">香港身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="8">港澳居民来往内地通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="9">台湾居民来往大陆通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="10">军/警身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="11">统一社会信用代码证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="12">往来台湾通行证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="13">澳门居民身份证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="14">税务登记证</el-radio>
                    </span>
                    <span>
                        <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.YFSFZMFYJRadio"
                            label="15">其他</el-radio>
                    </span>
                    <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.yf_SFFYJQTVal" />
                </p>
                <p class="margin_top_20">
                    2、双方不同意留存居间方复印件，居间方已对甲乙双方不留存复印件的风险进行告知，双方知晓并自愿承担风险，由此而产生的纠纷双方自行协商解决，与居间方无关。
                </p>

                <h3 class="font_weight500">第十四条 查封情况</h3>
                <p>
                    签约时甲方是否已提供大连房屋登记信息表（产调）根据签约时实际情况选择以下选项（二选一）：
                </p>
                <span>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.jf_YTGFWDJBRadio"
                        label="1">甲方签约时以提供大连房屋登记信息表（产调），房屋未被查封。</el-radio>
                </span>
                <br>
                <span>
                    <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.jf_YTGFWDJBRadio"
                        label="2">签约当日甲方未提供大连房屋登记信息表（产调），
                    </el-radio>
                </span>
                <span>
                    故丙方无法核定该房屋是否存在被查封等问题，乙方知晓并认同，现甲方自行承诺该房屋未被查封、未转让等有影响乙方承租/居住问题，若后续发现房屋已查封或被查封等影响乙方承租/居住等情形，则视甲方违约，乙方有权解除本合同且甲方须按合同约定承担违约责任，丙方已尽如实告知义务，因上述原因产生的责任由甲方承担，丙方不承担责任，甲乙双方知晓并认可。
                </span>
                <!-- <p><label><input id="chafeng1" type="radio" v-model="formCheck.jf_YTGFWDJBRadio" />甲方签约时以提供大连房屋登记信息表（产调），房屋未被查封。</label></p>
                <p><label><input id="chafeng1" type="radio" v-model="formCheck.jf_YTGFWDJBRadio" />签约当日甲方未提供大连房屋登记信息表（产调），故丙方无法核定该房屋是否存在被查封等问题，乙方知晓并认同，现甲方自行承诺该房屋未被查封、未转让等有影响乙方承租/居住问题，若后续发现房屋已查封或被查封等影响乙方承租/居住等情形，则视甲方违约，乙方有权解除本合同且甲方须按合同约定承担违约责任，丙方已尽如实告知义务，因上述原因产生的责任由甲方承担，丙方不承担责任，甲乙双方知晓并认可。</label></p> -->

                <h3 class="font_weight500">
                    第十五条 其他约定事项<input :disabled="flag" class="inputW_40 input_borderBottom_000" v-model="form.QTYDSX" />
                </h3>
                <div class="text_align_r" style="margin-right: 5%">（以下无正文）</div>
                <div class="margin_top_20">
                    <span class="">甲方（出租方）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.chuZu3" /></span><span class="float_right">乙方（承租方）<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.chengZu3" /></span>
                </div>
                <div class="margin_top_20">
                    <span class="">委托代理人:<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.jf_WTDLR" /></span><span class="float_right">委托代理人:<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.yf_WTDLR" /></span>
                </div>
                <div class="margin_top_20">
                    <span>丙方（章）:<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.BFZ" /></span>
                </div>
                <div class="margin_top_20">
                    <span class="">经纪执业人员签字:<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.JJZYRYQZ1" /></span><span class="float_right">资格注册证书编号:<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.ZGZCZSBH1" /></span>
                </div>
                <div class="margin_top_20">
                    <span class="">经纪执业人员签字:<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.JJZYRYQZ2" /></span><span class="float_right">资格注册证书编号:<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.ZGZCZSBH2" /></span>
                </div>
                <div class="margin_top_20">
                    <span class="">联系人签字:<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.LXRQZ" /></span><span class="float_right">联系电话:<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.LXDH" :maxlength="11"
                            @blur="rulesMobile(5)" /></span>
                </div>

                <h2 class="h2_title margin_top_150">补充协议说明</h2>
                <span>经买卖双方协商一致<el-input type="textarea" v-model="form.bcxysm" :rows="4"></el-input></span>
                <div class="margin_top_20">
                    <span class="">甲方（出租方）<input :disabled="flag" class="inputW_15 input_borderBottom_000"
                            v-model="form.chuZu3" /></span><span class="float_right">乙方（承租方）<input :disabled="flag"
                            class="input_borderBottom_000" v-model="form.chengZu3" /></span>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import Docxtemplater from "docxtemplater"
import PizZip from "pizzip"
import JsZipUtils from "jszip-utils"
import FileSaver from "file-saver"
import ZuLinTemplate from "./ZuLinTemplate.js"
import QianYueJs from "../QianYue.js"
import url from "../../../config/url.js"
import { Loading } from "element-ui"
import axios from "axios"
import { Message } from "element-ui"
export default {
    inject: ["reload"],
    data() {
        return {
            loading: false,
            disabled: true,
            flag: false,
            userId: localStorage.getItem("userId"),
            // contractId: this.$route.query.contractId,
            form: ZuLinTemplate.form,
            formCheck: ZuLinTemplate.formCheck,
            JiaFangFeiYongOptions: ZuLinTemplate.JiaFangFeiYongOptions,
            JiaFangFeiYongList: ZuLinTemplate.JiaFangFeiYongList,
            YiFangFeiYongOptions: ZuLinTemplate.YiFangFeiYongOptions,
            YiFangFeiYongList: ZuLinTemplate.YiFangFeiYongList,
            CQZYJOptions: ZuLinTemplate.CQZYJOptions,
            CQZYJList: ZuLinTemplate.CQZYJList,
            CQZFYJOptions: ZuLinTemplate.CQZFYJOptions,
            CQZFYJList: ZuLinTemplate.CQZFYJList,
            fileList: [],
            wu: "",
            MyHeader: {
                currentRole: "",
                accessToken: "",
                fp: ""
            }
        }
    },
    props: {
        formData: {
            type: Object,
            default: {}
        },
        form1: {
            type: Object,
            default: {}
        },
        contractId: {
            type: String,
            default: ""
        },
        state: {
            type: String,
            default: ""
        },
        contractStatus: {
            type: String,
            default: ""
        }
    },
    created() {
        if (this.contractStatus >= "20") {
            this.flag = true
        }
        this.MyHeader.accessToken = localStorage.getItem("accessToken") //获取accessToken
        this.MyHeader.fp = localStorage.getItem("fp") //浏览器指纹fp
        this.roleType = window.localStorage.getItem("roleType")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        var d = new Date()
        this.form.qianShu_Year = this.form.chuZu_Year = this.form.chengZu_Year = d.getFullYear()
        this.form.qianShu_Month = this.form.chuZu_Month = this.form.chengZu_Month =
            d.getMonth() + 1
        this.form.qianShu_Day = this.form.chuZu_Day = this.form.chengZu_Day = d.getDate()
        this.form.contractNo = this.formData.contractNo
        this.form.sellerName = this.formData.sellerName
        // this.form.chuZu1 = this.formData.sellerName;
        this.form.chuZu2 = this.formData.sellerName
        // this.form.chuZu3 = this.formData.sellerName;
        this.form.chuZu_Phone = this.formData.tel1
        this.form.buyerName = this.formData.buyerName
        // this.form.chengZu1 = this.formData.buyerName;
        this.form.chengZu2 = this.formData.buyerName
        // this.form.chengZu3 = this.formData.buyerName;
        this.form.fangWu_Address = this.formData.contractHouseAddress
        this.form.fangWu_Address1 = this.formData.contractHouseAddress
        // this.form.suoYouQuanHao = this.formData.houseNo;
        this.form.quanShu = this.formData.quanShu
        this.form.fangWuYongTu = this.formData.fangWuYongTu
        this.form.fangWu_MianJi = this.formData.square
        // this.form.chuZu_Address = this.formData.contractHouseAddress;
        this.form.sellerIdCardNo = this.formData.sellerIdCardNo
        this.form.sellerMobile = this.formData.sellerMobile
        this.form.buyerIdCardNo = this.formData.buyerIdCardNo
        this.form.buyerMobile = this.formData.buyerMobile
        this.form.companyName = this.formData.companyName
        this.form.bingFang1 = this.formData.companyName
        if (parseInt(this.form.sellerIdCardNo.slice(-2, -1)) % 2 == 1) {
            this.form.chuZu_Sex = "男"
        } else {
            this.form.chuZu_Sex = "女"
        }
        if (parseInt(this.form.buyerIdCardNo.slice(-2, -1)) % 2 == 1) {
            this.form.chengZu_Sex = "男"
        } else {
            this.form.chengZu_Sex = "女"
        }
        this.form.chuZu_ChuShengRiQi = this.form.sellerIdCardNo
            .slice(6, 14)
            .replace(/(.{4})(.{2})/, "$1.$2.")
        this.form.chengZu_ChuShengRiQi = this.form.buyerIdCardNo
            .slice(6, 14)
            .replace(/(.{4})(.{2})/, "$1.$2.")
        if (JSON.stringify(this.form1) != "{}") {
            this.form = this.form1
            this.formCheck.checkedJiaFangFeiYong = this.form.checkedJiaFangFeiYong
            this.formCheck.checkedYiFangFeiYong = this.form.checkedYiFangFeiYong
            this.formCheck.checkedCQZYJ = this.form.checkedCQZYJ
            this.formCheck.checkedCQZFYJ = this.form.checkedCQZFYJ
            this.formCheck.jf_YTGFWDJBRadio = this.form.jf_YTGFWDJBRadio
            this.formCheck.SFFSRadio = this.form.SFFSRadio
            this.formCheck.ZJFKFSRadio = this.form.ZJFKFSRadio
            this.formCheck.ZJZFFSRadio = this.form.ZJZFFSRadio
            this.formCheck.JFSFZMYJRadio = this.form.JFSFZMYJRadio
            this.formCheck.YFSFZMYJRadio = this.form.YFSFZMYJRadio
            this.formCheck.JFSFZMFYJRadio = this.form.JFSFZMFYJRadio
            this.formCheck.YFSFZMFYJRadio = this.form.YFSFZMFYJRadio
        }
    },
    watch: {
        "formCheck.jf_YTGFWDJBRadio"(val, oldVal) {
            console.log(val)
        }
    },
    methods: {
        onSetp(type) {
            this.$emit("onSetp", type)
        },
        goBack() {
            this.$router.go(-1)
        },
        concatData(a, b, checkedParams) {
            let data = []
            const arr = [...a, ...b]
            data = arr.filter((item) => {
                return !(a.includes(item) && b.includes(item))
            })
            data.forEach((element) => {
                checkedParams[element] = "□"
            })
            b.forEach((element) => {
                checkedParams[element] = "√"
            })
            return checkedParams
        },
        async onSave(type) {
            let _this = this
            _this.loadingFunction()
            JsZipUtils.getBinaryContent(
                "房屋租赁协议.docx",
                function (error, content) {
                    if (error) {
                        throw error
                    }
                    const zip = new PizZip(content)

                    const doc = new Docxtemplater(zip, {
                        paragraphLoop: true,
                        linebreaks: true
                    })

                    console.log("fangWuYongTu", _this.form.fangWuYongTu)
                    let checkedJiaFangFeiYong = {},
                        checkedYiFangFeiYong = {},
                        checkedCQZYJ = {},
                        checkedCQZFYJ = {}

                    //甲方费用
                    checkedJiaFangFeiYong = _this.concatData(
                        _this.JiaFangFeiYongList,
                        _this.formCheck.checkedJiaFangFeiYong,
                        {}
                    )

                    //乙方费用
                    checkedYiFangFeiYong = _this.concatData(
                        _this.YiFangFeiYongList,
                        _this.formCheck.checkedYiFangFeiYong,
                        {}
                    )

                    //乙方费用
                    checkedCQZYJ = _this.concatData(
                        _this.CQZYJList,
                        _this.formCheck.checkedCQZYJ,
                        {}
                    )

                    //乙方费用
                    checkedCQZFYJ = _this.concatData(
                        _this.CQZFYJList,
                        _this.formCheck.checkedCQZFYJ,
                        {}
                    )

                    //租金付款方式
                    if (_this.formCheck.ZJFKFSRadio == "1") {
                        _this.form.zjFuKuanFangShi_Month = "√"
                    } else if (_this.formCheck.ZJFKFSRadio == "2") {
                        _this.form.zjFuKuanFangShi_JiFu = "√"
                    } else if (_this.formCheck.ZJFKFSRadio == "3") {
                        _this.form.zjFuKuanFangShi_BanNianFu = "√"
                    } else if (_this.formCheck.ZJFKFSRadio == "4") {
                        _this.form.zjFuKuanFangShi_Year = "√"
                    } else {
                        _this.form.zjFuKuanFangShi_Month = "□"
                        _this.form.zjFuKuanFangShi_JiFu = "□"
                        _this.form.zjFuKuanFangShi_BanNianFu = "□"
                        _this.form.zjFuKuanFangShi_Year = "□"
                    }

                    //租金支付方式
                    if (_this.formCheck.ZJZFFSRadio == "1") {
                        _this.form.zjFuKuanFangShi_DiSanFangZhiFU = "√"
                    } else if (_this.formCheck.ZJZFFSRadio == "2") {
                        _this.form.zjFuKuanFangShi_ZhuangZhangZhiPiao = "√"
                    } else if (_this.formCheck.ZJZFFSRadio == "3") {
                        _this.form.zjFuKuanFangShi_YinHangHuiKuan = "√"
                    } else if (_this.formCheck.ZJZFFSRadio == "4") {
                        _this.form.zjFuKuanFangShi_YinHangZhuangZhang = "√"
                    } else if (_this.formCheck.ZJZFFSRadio == "5") {
                        _this.form.zjFuKuanFangShi_XianJin = "√"
                    } else {
                        _this.form.zjFuKuanFangShi_DiSanFangZhiFU = "□"
                        _this.form.zjFuKuanFangShi_ZhuangZhangZhiPiao = "□"
                        _this.form.zjFuKuanFangShi_YinHangHuiKuan = "□"
                        _this.form.zjFuKuanFangShi_YinHangZhuangZhang = "□"
                        _this.form.zjFuKuanFangShi_XianJin = "□"
                    }

                    //甲方身份证件原件
                    if (_this.formCheck.JFSFZMYJRadio == "1") {
                        _this.form.jf_SFYJSFZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "2") {
                        _this.form.jf_SFYJJJGZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "3") {
                        _this.form.jf_SFYJHZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "4") {
                        _this.form.jf_SFYJGATXZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "5") {
                        _this.form.jf_SFYJQYYYZZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "6") {
                        _this.form.jf_SFYJZZJGDMZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "7") {
                        _this.form.jf_SFYJXGSFZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "8") {
                        _this.form.jf_SFYJGAJMNDTXZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "9") {
                        _this.form.jf_SFYJTWJMDLTXZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "10") {
                        _this.form.jf_SFYJJJSFZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "11") {
                        _this.form.jf_SFYJTYSHXYDMZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "12") {
                        _this.form.jf_SFYJLWTWTXZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "13") {
                        _this.form.jf_SFYJAMJMSFZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "14") {
                        _this.form.jf_SFYJSWDJZ = "√"
                    } else if (_this.formCheck.JFSFZMYJRadio == "15") {
                        _this.form.jf_SFYJQT = "√"
                    } else {
                        _this.form.jf_SFYJSFZ = "□"
                        _this.form.jf_SFYJJJGZ = "□"
                        _this.form.jf_SFYJHZ = "□"
                        _this.form.jf_SFYJGATXZ = "□"
                        _this.form.jf_SFYJQYYYZZ = "□"
                        _this.form.jf_SFYJZZJGDMZ = "□"
                        _this.form.jf_SFYJXGSFZ = "□"
                        _this.form.jf_SFYJGAJMNDTXZ = "□"
                        _this.form.jf_SFYJTWJMDLTXZ = "□"
                        _this.form.jf_SFYJJJSFZ = "□"
                        _this.form.jf_SFYJTYSHXYDMZ = "□"
                        _this.form.jf_SFYJLWTWTXZ = "□"
                        _this.form.jf_SFYJAMJMSFZ = "□"
                        _this.form.jf_SFYJSWDJZ = "□"
                        _this.form.jf_SFYJQT = "□"
                    }

                    // 乙方身份证件原件
                    if (_this.formCheck.YFSFZMYJRadio == "1") {
                        _this.form.yf_SFYJSFZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "2") {
                        _this.form.yf_SFYJJJGZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "3") {
                        _this.form.yf_SFYJHZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "4") {
                        _this.form.yf_SFYJGATXZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "5") {
                        _this.form.yf_SFYJQYYYZZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "6") {
                        _this.form.yf_SFYJZZJGDMZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "7") {
                        _this.form.yf_SFYJXGSFZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "8") {
                        _this.form.yf_SFYJGAJMNDTXZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "9") {
                        _this.form.yf_SFYJTWJMDLTXZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "10") {
                        _this.form.yf_SFYJJJSFZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "11") {
                        _this.form.yf_SFYJTYSHXYDMZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "12") {
                        _this.form.yf_SFYJLWTWTXZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "13") {
                        _this.form.yf_SFYJAMJMSFZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "14") {
                        _this.form.yf_SFYJSWDJZ = "√"
                    } else if (_this.formCheck.YFSFZMYJRadio == "15") {
                        _this.form.yf_SFYJQT = "√"
                    } else {
                        _this.form.yf_SFYJSFZ = "□"
                        _this.form.yf_SFYJJJGZ = "□"
                        _this.form.yf_SFYJHZ = "□"
                        _this.form.yf_SFYJGATXZ = "□"
                        _this.form.yf_SFYJQYYYZZ = "□"
                        _this.form.yf_SFYJZZJGDMZ = "□"
                        _this.form.yf_SFYJXGSFZ = "□"
                        _this.form.yf_SFYJGAJMNDTXZ = "□"
                        _this.form.yf_SFYJTWJMDLTXZ = "□"
                        _this.form.yf_SFYJJJSFZ = "□"
                        _this.form.yf_SFYJTYSHXYDMZ = "□"
                        _this.form.yf_SFYJLWTWTXZ = "□"
                        _this.form.yf_SFYJAMJMSFZ = "□"
                        _this.form.yf_SFYJSWDJZ = "□"
                        _this.form.yf_SFYJQT = "□"
                    }

                    // 甲方身份证件复印件
                    if (_this.formCheck.JFSFZMFYJRadio == "1") {
                        _this.form.jf_SFFYJSFZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "2") {
                        _this.form.jf_SFFYJJJGZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "3") {
                        _this.form.jf_SFFYJHZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "4") {
                        _this.form.jf_SFFYJGATXZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "5") {
                        _this.form.jf_SFFYJQYYYZZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "6") {
                        _this.form.jf_SFFYJZZJGDMZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "7") {
                        _this.form.jf_SFFYJXGSFZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "8") {
                        _this.form.jf_SFFYJGAJMNDTXZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "9") {
                        _this.form.jf_SFFYJTWJMDLTXZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "10") {
                        _this.form.jf_SFFYJJJSFZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "11") {
                        _this.form.jf_SFFYJTYSHXYDMZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "12") {
                        _this.form.jf_SFFYJLWTWTXZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "13") {
                        _this.form.jf_SFFYJAMJMSFZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "14") {
                        _this.form.jf_SFFYJSWDJZ = "√"
                    } else if (_this.formCheck.JFSFZMFYJRadio == "15") {
                        _this.form.jf_SFFYJQT = "√"
                    } else {
                        _this.form.jf_SFFYJSFZ = "□"
                        _this.form.jf_SFFYJJJGZ = "□"
                        _this.form.jf_SFFYJHZ = "□"
                        _this.form.jf_SFFYJGATXZ = "□"
                        _this.form.jf_SFFYJQYYYZZ = "□"
                        _this.form.jf_SFFYJZZJGDMZ = "□"
                        _this.form.jf_SFFYJXGSFZ = "□"
                        _this.form.jf_SFFYJGAJMNDTXZ = "□"
                        _this.form.jf_SFFYJTWJMDLTXZ = "□"
                        _this.form.jf_SFFYJJJSFZ = "□"
                        _this.form.jf_SFFYJTYSHXYDMZ = "□"
                        _this.form.jf_SFFYJLWTWTXZ = "□"
                        _this.form.jf_SFFYJAMJMSFZ = "□"
                        _this.form.jf_SFFYJSWDJZ = "□"
                        _this.form.jf_SFFYJQT = "□"
                    }

                    // 乙方身份证件复印件
                    if (_this.formCheck.YFSFZMFYJRadio == "1") {
                        _this.form.yf_SFFFYJSFZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "2") {
                        _this.form.yf_SFFYJJJGZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "3") {
                        _this.form.yf_SFFYJHZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "4") {
                        _this.form.yf_SFFYJGATXZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "5") {
                        _this.form.yf_SFFYJQYYYZZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "6") {
                        _this.form.yf_SFFYJZZJGDMZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "7") {
                        _this.form.yf_SFFYJXGSFZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "8") {
                        _this.form.yf_SFFYJGAJMNDTXZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "9") {
                        _this.form.yf_SFFYJTWJMDLTXZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "10") {
                        _this.form.yf_SFFYJJJSFZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "11") {
                        _this.form.yf_SFFYJTYSHXYDMZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "12") {
                        _this.form.yf_SFFYJLWTWTXZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "13") {
                        _this.form.yf_SFFYJAMJMSFZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "14") {
                        _this.form.yf_SFFYJSWDJZ = "√"
                    } else if (_this.formCheck.YFSFZMFYJRadio == "15") {
                        _this.form.yf_SFYJQT = "√"
                    } else {
                        _this.form.yf_SFFFYJSFZ = "□"
                        _this.form.yf_SFFYJJJGZ = "□"
                        _this.form.yf_SFFYJHZ = "□"
                        _this.form.yf_SFFYJGATXZ = "□"
                        _this.form.yf_SFFYJQYYYZZ = "□"
                        _this.form.yf_SFFYJZZJGDMZ = "□"
                        _this.form.yf_SFFYJXGSFZ = "□"
                        _this.form.yf_SFFYJGAJMNDTXZ = "□"
                        _this.form.yf_SFFYJTWJMDLTXZ = "□"
                        _this.form.yf_SFFYJJJSFZ = "□"
                        _this.form.yf_SFFYJTYSHXYDMZ = "□"
                        _this.form.yf_SFFYJLWTWTXZ = "□"
                        _this.form.yf_SFFYJAMJMSFZ = "□"
                        _this.form.yf_SFFYJSWDJZ = "□"
                        _this.form.yf_SFYJQT = "□"
                    }

                    // 是否发生
                    if (_this.formCheck.SFFSRadio == "1") {
                        _this.form.sffs_S = "√"
                    } else if (_this.formCheck.SFFSRadio == "2") {
                        _this.form.sffs_F = "√"
                    } else {
                        _this.form.sffs_S = "□"
                        _this.form.sffs_F = "□"
                    }

                    // 房屋登记信息表
                    if (_this.formCheck.jf_YTGFWDJBRadio == "1") {
                        _this.form.jf_YTGFWDJB = "√"
                    } else if (_this.formCheck.jf_YTGFWDJBRadio == "2") {
                        _this.form.jf_WYTGFWDJB = "√"
                    } else {
                        _this.form.jf_YTGFWDJB = "□"
                        _this.form.jf_WYTGFWDJB = "□"
                    }

                    doc.setData({
                        ..._this.form,
                        ...checkedJiaFangFeiYong,
                        ...checkedYiFangFeiYong,
                        ...checkedCQZYJ,
                        ...checkedCQZFYJ
                    })

                    try {
                        doc.render()
                    } catch (error) {
                        function replaceErrors(key, value) {
                            if (value instanceof Error) {
                                return Object.getOwnPropertyNames(value).reduce(
                                    function (error, key) {
                                        error[key] = value[key]
                                        return error
                                    },
                                    {}
                                )
                            }
                            return value
                        }
                        console.log(
                            JSON.stringify({ error: error }, replaceErrors)
                        )

                        if (
                            error.properties &&
                            error.properties.errors instanceof Array
                        ) {
                            const errorMessages = error.properties.errors
                                .map(function (error) {
                                    return error.properties.explanation
                                })
                                .join("\n")
                        }
                        throw error
                    }
                    const blob = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    })
                    if (type == 2) {
                        _this.loading.close()
                        // _this.save()
                        saveAs(blob, "房屋租赁协议.docx")
                    } else if (type == 1) {
                        _this.httpRequest(blob, _this.contractId, type)
                        // _this.save()
                        // _this.draft()
                        _this.loading.close()
                    }
                }
            )
        },
        httpRequest(blob, contractId, type) {
            let that = this,
                params = {},
                url1 = url.TransferFileToESign
            const loading = Loading.service({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            let a = new FileReader()
            a.onload = function (e) {
                params = {
                    base64File: e.target.result.split("base64,")[1],
                    contractId: contractId
                }
                axios
                    .post(url1, params, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    .then((res) => {
                        if (res.data.msgCode == 1) {
                            setTimeout(function () {
                                Message.success({
                                    message: "签约基本信息保存成功！"
                                })
                                loading.close()
                                that.reload()
                                // that.Submit();
                                // return true
                            }, 3000)
                        } else {
                            Message.error({
                                message: res.data.message,
                                duration: 5000
                            })
                            loading.close()
                            // return true
                        }
                    })
                    .catch((res) => {
                        Message.error("请求错误")
                    })
            }
            a.readAsDataURL(blob)
        },
        applyPass() {
            let that = this
            that.loading = true
            const data = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.ConfirmComplete, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        that.loading = false
                        that.reload()
                    } else {
                        that.loading = false
                        that.$message.error(res.data.message)
                    }
                })
        },
        draft() {
            let _this = this,
                param = {}
            if (_this.form.YueZu_XiaoXieYuan == "×") {
                return _this.$message.error("请填写月租金金额")
            }
            if (_this.form.jf_JuJianFangXiaoXieYuan == "×") {
                return _this.$message.error("请填写服务费金额")
            }
            _this.form.checkedJiaFangFeiYong =
                _this.formCheck.checkedJiaFangFeiYong
            _this.form.checkedYiFangFeiYong =
                _this.formCheck.checkedYiFangFeiYong
            _this.form.checkedCQZYJ = _this.formCheck.checkedCQZYJ
            _this.form.checkedCQZFYJ = _this.formCheck.checkedCQZFYJ
            _this.form.jf_YTGFWDJBRadio = _this.formCheck.jf_YTGFWDJBRadio
            _this.form.SFFSRadio = _this.formCheck.SFFSRadio
            _this.form.ZJFKFSRadio = _this.formCheck.ZJFKFSRadio
            _this.form.ZJZFFSRadio = _this.formCheck.ZJZFFSRadio
            _this.form.JFSFZMYJRadio = _this.formCheck.JFSFZMYJRadio
            _this.form.YFSFZMYJRadio = _this.formCheck.YFSFZMYJRadio
            _this.form.JFSFZMFYJRadio = _this.formCheck.JFSFZMFYJRadio
            _this.form.YFSFZMFYJRadio = _this.formCheck.YFSFZMFYJRadio
            param = {
                ContractId: _this.contractId,
                ContractInfo: JSON.stringify(_this.form)
            }
            _this.$http
                .put(_this.$url.UpdateContractDraft, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        _this.$message.success("签约基本信息保存成功！")
                        _this.loading.close()
                    } else {
                        _this.loading.close()
                        _this.$message.error(res.data.message)
                    }
                })
        },
        save() {
            let _this = this,
                param = {}
            _this.disabled = true
            if (_this.form.YueZu_XiaoXieYuan == "×") {
                return _this.$message.error("请填写月租金金额")
            }
            if (_this.form.jf_JuJianFangXiaoXieYuan == "×") {
                return _this.$message.error("请填写服务费金额")
            }
            param = {
                yiFangFuWuFeiAmount: 0,
                dingJinAmount: 0,
                jiaFangFuWuFeiAmount: Number(_this.form.jf_JuJianFangXiaoXieYuan),
                yiFangFuWuFeiAmount: 0,
                baoZhangFuWuFeiAmount: 0,
                isDingZhuanYong: "0",
                userId: _this.formData.userId,
                contractId: _this.contractId,
                shopId: _this.formData.shopId,
                merchantId: _this.formData.merchantId,
                houseId: _this.formData.houseId,
                customerId: _this.formData.customerId,
                tradeType: _this.formData.tradeType,
                type: _this.formData.type,
                contractHouseAddress: _this.formData.contractHouseAddress,
                contractPrice: Number(_this.form.YueZu_XiaoXieYuan)
                // param.signDate = _this.signDate

                // fileId1: _this.form.fileId1.split(".")[0]
                //contractInfo: JSON.stringify(_this.form)
            }
            _this.$http
                .put(_this.$url.UpdateContract, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    _this.disabled = false
                    if (res.data.msgCode == 1) {
                        _this.onSave(1)
                        _this.loading.close()
                        if (!_this.contractId) {
                            this.$router.go(-1)
                        }
                    } else {
                        _this.loading.close()
                        _this.$message.error(res.data.message)
                    }
                })
                .catch((res) => {
                    _this.disabled = false
                    _this.loading.close()
                    this.$message.error("请求错误")
                })
        },
        // 邮箱校验
        onlyRules() {
            if (
                this.form.chuZu_Email != "×" &&
                this.form.chuZu_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuZu_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuZu_Email = ""
            }
            if (
                this.form.chuZuDL_Email != "×" &&
                this.form.chuZuDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chuZuDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chuZuDL_Email = ""
            }
            if (
                this.form.chengZu_Email != "×" &&
                this.form.chengZu_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chengZu_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chengZu_Email = ""
            }
            if (
                this.form.chengZuDL_Email != "×" &&
                this.form.chengZuDL_Email != "无" &&
                !this.$commonJs.numberEmail(this.form.chengZuDL_Email)
            ) {
                this.$message.error("格式不符，请填写正确的邮箱!")
                this.form.chengZuDL_Email = ""
            }
        },
        rulesDate(num, param) {
            if (this.form[param] !== "") {
                this.form[param] = parseInt(this.form[param])
                if (
                    num % 2 == 1 &&
                    (this.form[param] > 12 || this.form[param] < 1)
                ) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的月份")
                } else if (this.form[param] > 31 || this.form[param] < 1) {
                    this.form[param] = ""
                    this.$message.error("请输入正确的日期")
                }
            }
        },
        rulesIdNo() {
            // 证件号码
            if (
                this.form.sellerIdCardNo != "×" &&
                !this.$commonJs.shenfenZheng(this.form.sellerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.sellerIdCardNo = ""
                return
            }
            if (
                this.form.chuZuDL_ZhengJianNum != "×" &&
                !this.$commonJs.shenfenZheng(this.form.chuZuDL_ZhengJianNum)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.chuZuDL_ZhengJianNum = ""
                return
            }
            if (
                this.form.buyerIdCardNo != "×" &&
                !this.$commonJs.shenfenZheng(this.form.buyerIdCardNo)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.buyerIdCardNo = ""
                return
            }
            if (
                this.form.chengZuDL_ZhengJianNum != "×" &&
                !this.$commonJs.shenfenZheng(this.form.chengZuDL_ZhengJianNum)
            ) {
                this.$message.error("证件号码格式不正确")
                this.form.chengZuDL_ZhengJianNum = ""
                return
            }
        },
        rulesMobile(val) {
            if (
                val === 1 &&
                this.form.sellerMobile != "×" &&
                !this.$commonJs.numberPhone(this.form.sellerMobile)
            ) {
                this.form.sellerMobile = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 2 &&
                this.form.chuZuDL_Phone != "×" &&
                !this.$commonJs.numberPhone(this.form.chuZuDL_Phone)
            ) {
                this.form.chuZuDL_Phone = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 3 &&
                this.form.buyerMobile != "×" &&
                !this.$commonJs.numberPhone(this.form.buyerMobile)
            ) {
                this.form.buyerMobile = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 4 &&
                this.form.chengZuDL_Phone != "×" &&
                !this.$commonJs.numberPhone(this.form.chengZuDL_Phone)
            ) {
                this.form.chengZuDL_Phone = ""
                this.$message.error("请输入正确的手机号码")
            }
            if (
                val === 5 &&
                this.form.LXDH != "×" &&
                !this.$commonJs.numberPhone(this.form.LXDH)
            ) {
                this.form.LXDH = ""
                this.$message.error("请输入正确的手机号码")
            }
        },
        moneyRule(param) {
            if (
                this.form[param] != "" &&
                !this.$commonJs.moneyFormat(this.form[param] * 1)
            ) {
                this.form[param] = "×"
                this.$message.error("请输入正确金额")
            }
            if (
                param == "YueZu_XiaoXieYuan" &&
                this.form.YueZu_XiaoXieYuan != "×"
            ) {
                if (this.form.YueZu_XiaoXieYuan != "") {
                    this.form.YueZu_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.YueZu_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.YueZu_DaXieYuan = "×"
                    this.form.YueZu_XiaoXieYuan = "×"
                }
            }
            if (param == "zj_Money1" && this.form.zj_Money1 != "×") {
                if (this.form.zj_Money1 != "") {
                    this.form.zj_Money1 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money1 = "×"
                }
            }
            if (param == "zj_Money2" && this.form.zj_Money2 != "×") {
                if (this.form.zj_Money2 != "") {
                    this.form.zj_Money2 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money2 = "×"
                }
            }
            if (param == "zj_Money3" && this.form.zj_Money3 != "×") {
                if (this.form.zj_Money3 != "") {
                    this.form.zj_Money3 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money3 = "×"
                }
            }
            if (param == "zj_Money4" && this.form.zj_Money4 != "×") {
                if (this.form.zj_Money4 != "") {
                    this.form.zj_Money4 = Number(this.form[param]).toFixed(2)
                } else {
                    this.form.zj_Money4 = "×"
                }
            }
            if (
                param == "BaoZhengJin_XiaoXieYuan" &&
                this.form.BaoZhengJin_XiaoXieYuan != "×"
            ) {
                if (this.form.BaoZhengJin_XiaoXieYuan != "") {
                    this.form.BaoZhengJin_DaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.BaoZhengJin_XiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.BaoZhengJin_DaXieYuan = "×"
                    this.form.BaoZhengJin_XiaoXieYuan = "×"
                }
            }
            if (
                param == "jf_JuJianFangXiaoXieYuan" &&
                this.form.jf_JuJianFangXiaoXieYuan != "×"
            ) {
                if (this.form.jf_JuJianFangXiaoXieYuan != "") {
                    this.form.jf_JuJianFangDaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.jf_JuJianFangXiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.jf_JuJianFangDaXieYuan = "×"
                    this.form.jf_JuJianFangXiaoXieYuan = "×"
                }
            }
            if (
                param == "yf_JuJianFangXiaoXieYuan" &&
                this.form.yf_JuJianFangXiaoXieYuan != "×"
            ) {
                if (this.form.yf_JuJianFangXiaoXieYuan != "") {
                    this.form.yf_JuJianFangDaXieYuan = this.$commonJs.getDaXie(
                        this.form[param]
                    )
                    this.form.yf_JuJianFangXiaoXieYuan = Number(
                        this.form[param]
                    ).toFixed(2)
                } else {
                    this.form.yf_JuJianFangDaXieYuan = "×"
                    this.form.yf_JuJianFangXiaoXieYuan = "×"
                }
            }
        },
        loadingFunction() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
        },
        beforeUpload(file) {
            this.loadingFunction()
        },
        Submit(type) {
            let that = this
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            })
            that.disabled = true
            let param = {
                contractId: that.contractId
            }
            that.$http
                .post(that.$url.FlowOneStepESign, param, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((result) => {
                    that.disabled = false
                    that.loading.close()
                    if (result.data.msgCode == 1) {
                        that.$message.success("合同提交成功！")
                        that.$router.go(-1)
                    } else {
                        that.$message.error(result.data.message)
                    }
                })
                .catch((result) => {
                    that.loading.close()
                    that.disabled = false
                    that.$message.error("请求错误")
                })
        }
        // handleRemove(file, fileList) {
        //   this.fileList = [];
        // },
        // handleExceed(files, fileList) {
        //   this.$message.warning("当前限制选择1个文件，请删除当前文件后，重新上传!");
        // },
        // beforeRemove(file, fileList) {
        //   return this.$confirm(`确定移除 ${file.name}？`);
        // },
    }
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    padding: 1% 4%;
    font-size: 12px;
    font-family: "宋体";
    list-style: none;
    box-sizing: border-box;

    .font_weight {
        font-weight: 600;
    }

    .text_align_l {
        text-align: left;
    }

    .text_align_r {
        text-align: right;
    }

    .border_bottom {
        border-bottom: 1px solid #000;
    }

    .header_title {
        color: #b3b3b3;
        font-weight: 400;
        line-height: 25px;
        border-bottom: 1px solid #ccc;
    }

    input {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        font-size: 16px;
        color: red;
        text-align: center;
        height: 20px !important;
        vertical-align: sub;
    }

    .input_borderBottom_ccc {
        border-bottom: 1px solid #ccc;
    }

    .input_borderBottom_000 {
        border-bottom: 1px solid #000;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
    }

    /deep/ .el-radio__input+.el-radio__label,
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #000 !important;
    }

    /deep/ .el-checkbox__inner {
        border: 1px solid #000 !important;
    }

    /deep/ .el-input__inner {
        border: 0 !important;
        font-size: 16px;
        color: red;
        text-align: center;
    }

    .text-indent {
        text-indent: 35px;
    }

    .border0 {
        border: 0;
    }

    .border_000 {
        border: 1px solid #000;
    }

    .inputW_50px {
        width: 50px;
    }

    .inputW_80px {
        width: 80px;
    }

    .inputW_150 {
        width: 150px;
    }

    .inputW_15 {
        width: 15%;
    }

    .inputW_30 {
        width: 30%;
    }

    .inputW_23 {
        width: 23%;
    }

    .inputW_40 {
        width: 40%;
    }

    .margin_top_150 {
        margin-top: 150px !important;
    }

    .margin_top_100 {
        margin-top: 100px !important;
    }

    .margin_top_50 {
        margin-top: 50px !important;
    }

    .margin_top_20 {
        margin-top: 20px !important;
    }

    .margin_top_10 {
        margin-top: 10px !important;
    }

    .font_weight500 {
        font-weight: 500;
    }

    .font_weight600 {
        font-weight: 600;
    }

    .float_left {
        float: left;
    }

    .float_right {
        float: right;
    }

    // 清除浮动
    .clearfix:after {
        content: "";
        height: 0;
        line-height: 0;
        display: block;
        clear: both;
        visibility: hidden;
    }

    .clearfix {
        zoom: 1;
    }

    .el-card {
        line-height: 25px !important;
    }

    p {
        margin: 0;
    }

    .quote_info {
        font-size: 15px;
        color: #000;
        width: 80%;
        margin: 0 auto;

        .h3_title {
            float: left;
            width: 100%;
            font-size: 30px;
            letter-spacing: 1px;
            line-height: 100px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }

        .h2_title {
            float: left;
            width: 100%;
            font-size: 25px;
            letter-spacing: 1px;
            line-height: 50px;
            font-weight: 400;
            font-family: "黑体";
            color: #000;
            text-align: center;
        }
    }

    ul,
    li {
        list-style: none;
    }

    li {
        border: 1px solid #000;
        line-height: 35px;
    }

    .width_10 {
        width: 10%;
        text-align: center;
        font-weight: 600;
    }

    .width_90 {
        width: 90%;
        text-align: center;
        font-weight: 600;
    }

    .width_98 {
        width: 98%;
    }

    .width_full {
        width: 100%;
    }

    .display_flex {
        display: flex;
    }
}

.nav1 {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 40px;
    text-align: center;
    margin: 10px 0 !important;
}

.nav2 {
    margin-left: 0 !important;
}

/deep/ .el-upload-list {
    display: contents;

    .el-upload-list__item-name {
        width: 140px;
    }
}

.header {
    height: 55px;
    border-bottom: 1px solid #ccc;

    img {
        display: block;
        float: left;
        width: 160px;
    }

    h3 {
        float: right;
        line-height: 60px;
        color: #b3b3b3;
        font-weight: 400;
    }
}

.el-button {
    margin: 5px;
}

/deep/.el-radio__inner {
    border: 1px solid #df0202;
}

/deep/.el-radio__inner:hover {
    border: 1px solid #0262df;
}

/deep/input[type="checkbox"] {
    position: relative;
    border: 1px solid #df0202 !important;
    border-radius: 3px;
    width: 13px;
    height: 13px !important;
    top: -1px;
    -webkit-appearance: none;
}

/deep/input[type="checkbox"]:disabled {
    cursor: not-allowed;
    border-color: rgba(153, 153, 153, 0.3) !important;
    background-color: rgb(241, 241, 241);
}

/deep/input[type="checkbox"]:hover {
    border: 1px solid #0262df !important;
}

/deep/input[type="checkbox"]:hover:disabled {
    border: 1px solid #dbdbdb !important;
}

/deep/input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 13px;
    margin-top: 0px !important;
    font-weight: bold;
    background-color: #ee6716;
    top: -1px;
    position: absolute;
    left: -1px;
    width: 13px;
    height: 13px !important;
}

/deep/ input:disabled {
    background-color: #fff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #ee6716;
}</style>