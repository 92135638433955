<template>
    <div>
        <!-- 中间卡片视图区域 -->
        <el-card class="search">
            <el-row class="mabot8">
                <el-col :span="5">
                    <span class="title">钥匙人姓名：</span>
                    <el-input v-model="yaoshiRen" placeholder="请输入钥匙人姓名：" class="media-width"></el-input>
                </el-col>
                <el-col :span="5">
                    <span class="title">是否已退还：</span>
                    <el-select v-model="isTuihuan" placeholder="请选择是否已退还" class="media-width">
                        <el-option label="是" value="是">
                        </el-option>
                        <el-option label="否" value="否">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="14">
                    <span style="float: right">
                        <el-button size="mini" style="margin-left: 20px" type="primary" @click="searchList">查询</el-button>
                    </span>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <div style="font-size:12px;margin-top: 25px;">重要提示：<span style="color:red">（无论房源当前状态是已租、已售、租售或核销，无论您是租赁经纪人或买卖经纪人）</span>凡是您曾在上传过的钥匙，均可在列表中查找或进行退还操作</div>
        <el-card>
            <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column label="标题图" prop="biaotitu">
                    <template slot-scope="scope">
                        <el-image style="width: 100%;" :src="scope.row.biaotitu" :preview-src-list="[scope.row.biaotitu]" :key="scope.row.biaotitu">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <!-- <span :class="scope.row.key == '1' ? 'el-icon-key' : ''" style="position: absolute;right:3px;bottom:20px;font-weight:bold;color: #000;transform: rotate(30deg);"></span> -->
                    </template>
                </el-table-column>
                <el-table-column label="房源基础信息" prop="fangyuanXinXi" width="200px">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="right" title width="250">
                            <div style="width:250px;text-align:center;">
                                <div class="overflow-hidden">{{ scope.row.loupanName }}</div>
                                <div class="overflow-hidden font-size12" style="margin:8px 0">{{scope.row.bianhao}}</div>
                                <span class="overflow-hidden font-size12">{{!!scope.row.fangxing ? scope.row.fangxing+' · ':''}}</span>
                                <span class="overflow-hidden font-size12">{{!!scope.row.mianji ? scope.row.mianji+'平 · ':''}}</span>
                                <span class="overflow-hidden font-size12">{{!!scope.row.jiage ? scope.row.jiage+'元':''}}</span>
                            </div>
                            <div style="width:200px;margin-top:5px;cursor:pointer;" slot="reference">
                                <div class="overflow-hidden">{{ scope.row.loupanName }}</div>
                                <div class="overflow-hidden font-size12" style="margin:8px 0">{{scope.row.bianhao}}</div>
                                <span class="overflow-hidden font-size12">{{!!scope.row.fangxing ? scope.row.fangxing+' · ':''}}</span>
                                <span class="overflow-hidden font-size12">{{!!scope.row.mianji ? scope.row.mianji+'平 · ':''}}</span>
                                <span class="overflow-hidden font-size12">{{!!scope.row.jiage ? scope.row.jiage+'元':''}}</span>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="委托类型" prop="weituoType"></el-table-column>
                <el-table-column label="钥匙类型" prop="yaoshiType"></el-table-column>
                <el-table-column label="钥匙数量" prop="yaoshiCount"></el-table-column>
                <el-table-column label="钥匙人" prop="yaoshiRen"></el-table-column>
                <el-table-column label="联系方式" prop="contact"></el-table-column>
                <el-table-column label="借出人" prop="jiechuRen"></el-table-column>
                <el-table-column label="借出时间" prop="jiechuTime"></el-table-column>
                <el-table-column label="是否已退还" prop="isTuihuan"></el-table-column>
                <el-table-column label="退还时间" prop="tuihuanShijian"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope" class="right">
                        <el-button type="text" size="mini" class="el-icon-view" style="color: rgb(19, 177, 19);">查看凭证照片</el-button>
                        <el-button v-if="scope.row.isTuihuan!='是'" @click.native.stop="TuihuanYaoshi(scope.$index, scope.row)" type="text" size="mini" class="el-icon-s-promotion" style="color: rgb(218, 9, 9)">退还</el-button>
                        <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff">修改</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            loading: false,
            yaoshiRen: "",
            isTuihuan: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [
                {
                    biaotitu: require("../../../public/img/yaoshi.jpg"),
                    loupanName: "保利天禧公馆/东峦街6号楼6楼2号",
                    bianhao: "10024242435353",
                    fangxing: "1-1-0-1",
                    mianji: "50",
                    jiage: "700000",
                    weituoType: "二手买卖",
                    yaoshiType: "实体钥匙",
                    yaoshiCount: "3",
                    yaoshiRen: "陈xx",
                    contact: "13777777777",
                    jiechuRen:'xxx',
                    jiechuTime: "2021-04-20 10:20:30",
                    isTuihuan: "否",
                    tuihuanShijian: ""
                },
                {
                    biaotitu: require("../../../public/img/yaoshi.jpg"),
                    loupanName: "保利天禧公馆/东峦街6号楼6楼2号",
                    bianhao: "10024242435353",
                    fangxing: "1-1-0-1",
                    mianji: "50",
                    jiage: "700000",
                    weituoType: "首租",
                    yaoshiType: "实体钥匙",
                    yaoshiCount: "3",
                    yaoshiRen: "陈xx",
                    contact: "13777777777",
                    jiechuRen:'xxx',
                    jiechuTime: "2021-04-20 10:20:30",
                    isTuihuan: "是",
                    tuihuanShijian: "2021-09-20 10:20:30"
                },
                {
                    biaotitu: require("../../../public/img/yaoshi.jpg"),
                    loupanName: "保利天禧公馆/东峦街6号楼6楼2号",
                    bianhao: "10024242435353",
                    fangxing: "1-1-0-1",
                    mianji: "50",
                    jiage: "700000",
                    weituoType: "二手买卖",
                    yaoshiType: "实体钥匙",
                    yaoshiCount: "3",
                    yaoshiRen: "陈xx",
                    contact: "13777777777",
                    jiechuRen:'xxx',
                    jiechuTime: "2021-04-20 10:20:30",
                    isTuihuan: "否",
                    tuihuanShijian: ""
                }
            ]
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
    },
    methods: {
        // 查询
        getArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "YaoShiDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.$router.push({
                name: "YaoShiDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        /* 获取日志列表 */
        async getArticleList() {
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(this.currentPage, this.pageSize)
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        // 退还钥匙
        TuihuanYaoshi(index) {
            var data1 = [index.articleId]
            this.$confirm("你确定要退还钥匙吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                const { data: res } = this.$http
                    .delete(this.$url.deleteArticle, {
                        data: data1,
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8"
                        }
                    })
                    .then(res => {
                        this.loading = false
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.GetPetList()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.data.message
                            })
                        }
                    })
            })
            // .catch(err => {
            //     this.$message({
            //         type: "error",
            //         message: err
            //     })
            // })
        },
        xiangqing(index, row) {
            this.$router.push({
                name: "YaoShiDetail",
                query: {
                    articleId: row.articleId,
                    num: 2,
                    state: 3
                }
            })
        },
        searchList() {}
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
