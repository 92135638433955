<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/JiaoYiJiLuList' }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="21%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="交易单编号:" label-width="21%" prop="transactionNumber">
                            <el-input disabled class="inputwid" v-model="form.transactionNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同编号:" label-width="21%" prop="contractNo">
                            <el-input disabled class="inputwid" v-model="form.contractNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="交易产品:" label-width="21%" prop="tradingProducts">
                            <el-select :disabled="flag" v-model="form.tradingProducts" placeholder="请选择交易产品" class="inputwid">
                                <el-option v-for="item in tradingProductslist" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="交易来源:" label-width="21%" prop="sourceTransaction">
                            <el-select :disabled="flag" v-model="form.sourceTransaction" placeholder="请选择交易来源" class="inputwid">
                                <el-option v-for="item in sourceTransactionlist" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="运营区域:" label-width="21%" prop="operationArea">
                            <el-select :disabled="flag" v-model="form.operationArea" placeholder="请选择运营区域" class="inputwid">
                                <el-option v-for="item in operationArealist" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="佣金状态:" label-width="21%" prop="commissionStatus">
                            <el-select :disabled="flag" v-model="form.commissionStatus" placeholder="请选择佣金状态" class="inputwid">
                                <el-option v-for="item in commissionStatuslist" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经纪人:" label-width="21%" prop="agent">
                            <el-input :disabled="flag" class="inputwid" v-model="form.agent"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="经纪人联系方式:" label-width="21%" prop="agentmobile">
                            <el-input :disabled="flag" class="inputwid" v-model="form.agentmobile"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="卖方:" label-width="21%" prop="seller">
                            <el-input :disabled="flag" class="inputwid" v-model="form.seller"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="卖方联系方式:" label-width="21%" prop="sellermobile">
                            <el-input :disabled="flag" class="inputwid" v-model="form.weight"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="买方:" label-width="21%" prop="buyer">
                            <el-input :disabled="flag" class="inputwid" v-model="form.buyer"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="买方联系方式:" label-width="21%" prop="buyermobile">
                            <el-input :disabled="flag" class="inputwid" v-model="form.buyer"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="物业地址:" label-width="10.5%" prop="propertyAddress">
                            <el-input :disabled="flag" style="width: 82.5%" v-model="form.propertyAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="10%" label="签约文件:">
                            <span style="color: #999">建议上传200:100格式的图片</span>
                            <!-- 上传图片 -->
                            <el-upload :action="serverUrl" list-type="picture-card" :file-list="picture" :on-success="success" :headers="MyHeader" :disabled="flag" :on-preview="handlePictureCardPreview" :limit="1" :on-exceed="handleExceed" :class="{ disabled: isMax }" :on-change="change" :on-error="error" :before-remove="handleBeforeRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" v-if="logo" alt />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" @click="cancel" type="warning" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"

export default {
    data() {
        return {
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            serverUrl: URL + "api/management/FileUpload/UploadFiles",
            name: "",
            mm: "",
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            form: {
                transactionNumber: "",
                contractNo: "",
                tradingProducts: "",
                sourceTransaction: "",
                operationArea: "",
                commissionStatus: "",
                agent: "",
                agentmobile: "",
                seller: "",
                sellermobile: "",
                buyer: "",
                buyermobile: "",
                propertyAddress: ""
            },
            // 绑定字段开始
            transactionNumber: "",
            contractNo: "",
            tradingProducts: "",
            sourceTransaction: "",
            operationArea: "",
            commissionStatus: "",
            agent: "",
            agentmobile: "",
            seller: "",
            sellermobile: "",
            buyer: "",
            buyermobile: "",
            propertyAddress: "",
            tradingProductslist: [
                {
                    value: "新房",
                    label: "新房"
                },
                {
                    value: "二手房",
                    label: "二手房"
                },
                {
                    value: "租房",
                    label: "租房"
                }
            ],
            sourceTransactionlist: [
                {
                    value: "店铺内部",
                    label: "店铺内部"
                },
                {
                    value: "微博",
                    label: "微博"
                },
                {
                    value: "朋友圈",
                    label: "朋友圈"
                },
                {
                    value: "传单",
                    label: "传单"
                }
            ],
            operationArealist: [
                {
                    value: "沙河口区",
                    label: "沙河口区"
                },
                {
                    value: "高新园区",
                    label: "高新园区"
                },
                {
                    value: "甘井子区",
                    label: "甘井子区"
                },
                {
                    value: "旅顺口区",
                    label: "旅顺口区"
                },
                {
                    value: "中山区",
                    label: "中山区"
                }
            ],
            commissionStatuslist: [
                {
                    value: "已收齐",
                    label: "已收齐"
                },
                {
                    value: "未收齐",
                    label: "未收齐"
                }
            ],
            // 绑定字段结束
            rules: {
                tradingProducts: [
                    {
                        required: true,
                        message: "请选择交易产品",
                        trigger: "blur"
                    }
                ],
                sourceTransaction: [
                    {
                        required: true,
                        message: "请选择交易来源",
                        trigger: "blur"
                    }
                ],
                operationArea: [
                    {
                        required: true,
                        message: "请选择运营区域",
                        trigger: "blur"
                    }
                ],
                commissionStatus: [
                    {
                        required: true,
                        message: "请选择佣金状态",
                        trigger: "blur"
                    }
                ],
                agent: [
                    {
                        required: true,
                        message: "请输入经纪人",
                        trigger: "blur"
                    }
                ],
                agentmobile: [
                    {
                        required: true,
                        message: "请输入经纪人联系方式",
                        trigger: "blur"
                    }
                ],
                seller: [
                    {
                        required: true,
                        message: "请输入卖家",
                        trigger: "blur"
                    }
                ],
                sellermobile: [
                    {
                        required: true,
                        message: "请输入卖家联系方式",
                        trigger: "blur"
                    }
                ],
                buyer: [
                    {
                        required: true,
                        message: "请输入买家",
                        trigger: "blur"
                    }
                ],
                buyermobile: [
                    {
                        required: true,
                        message: "请输入买家联系方式",
                        trigger: "blur"
                    }
                ],
                propertyAddress: [
                    {
                        required: true,
                        message: "请输入物业地址",
                        trigger: "blur"
                    }
                ],
                firstPic: [
                    {
                        required: true,
                        message: "请上传文章图片",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "交易记录新增"
            this.form = {}
            // this.flag4 = false;
        } else if (this.num == 1) {
            this.name = "交易记录详情"
            this.flag = true
            this.isMax = true
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "交易记录修改"
            this.getArticleDetail()
        }
    },
    methods: {
        //修改
        async onUpdate(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleId: this.articleId,
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        enabled: this.form.enabled
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.updateArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改文章信息成功")
                        this.$router.push({ path: "/TongXunLuList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        //获取交易记录详情
        async getArticleDetail() {
            await this.$http
                .get(this.$url.getArticleDetail(this.articleId))
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        var uuu = {
                            url: URL + "uploads/allFiles/" + this.form.firstPic
                        }
                        if (
                            this.form.firstPic == "" ||
                            this.form.firstPic == null
                        ) {
                            this.picture = []
                            this.isMax = false
                        } else if (
                            this.form.firstPic != "" ||
                            this.form.firstPic != null
                        ) {
                            this.picture.push(uuu)
                            this.isMax = true
                        }
                        if (this.num == 1) {
                            if (
                                this.form.firstPic == "" ||
                                this.form.firstPic == null
                            ) {
                                this.isMax = true
                            }
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        success(response) {
            this.form.firstPic = response.id
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        handleBeforeRemove(file, fileList) {
            var mm = file.url.slice(47)
            this.mm = mm
            return this.$confirm(`确定移除 ？`).then(() => {
                var data = {
                    firstPic: mm,
                    moduleName: "PetBreed"
                }
                this.$http.delete(
                    this.$url.deleteOneFile(mm, data.moduleName),
                    {
                        data
                    }
                )
                if (res.msgCode == 1) {
                    this.$message.success("删除成功!")
                    this.isMax = false

                    this.form.firstPic = ""
                } else {
                    this.$message.success(res.message)
                }
            })
        },
        cancel() {
            this.$router.push({ path: "/JiaoYiJiLuList" })
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
.inputwid {
    width: 60%;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
</style>