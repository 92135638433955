<template>
    <div class="qiugouDetail">
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <el-button @click="cancel" size="mini" class="el-button1" type="primary" style="float: right">返回</el-button>
        </el-card>

        <el-tabs @tab-click="handleClick" v-model="activeName" class="qiugou-tabs" style="height: 40px;position: relative;top: 0px;margin-top: 10px;background-color: #fff;">
            <el-tab-pane name="xuqiukeyuan" label="客源信息" v-loading="loading">
                <KeYuanInformation v-if="activeName=='xuqiukeyuan'" @GetCustomerDetailRequest="GetCustomerDetailRequest" :isUpdate='isUpdate' :isRole='isRole' :customerId="customerId" :dtCustomer="dtCustomer" :dtCustomerDetail="dtCustomerDetail" :xuqiuList="xuqiuList" :ruleForm="ruleForm" :ruleFormParam="ruleFormDetail" :isRecommend="isRecommend" :laiyuanOptions="laiyuanOptions" :biaoqianOptions="biaoqianOptions" :fangwuyongtuOptions="fangwuyongtuOptions" :zhuangxiuOptions="zhuangxiuOptions" :cascaderShangquanOptions="cascaderShangquanOptions" :type="type"/>
                <WeiHuJiLu ref="daiKanRef" v-if="activeName=='xuqiukeyuan'" @GenJin='GenJin' :isGenJinList='isGenJinList' @DaiKan='DaiKan' :isGenJin='isGenJin' @GetCustomerDetailRequest="GetCustomerDetailRequest" :isUpdate='isUpdate' :isRole='isRole' :customerId=" customerId" :dtCustomer="dtCustomer" :dtCustomerDetail="dtCustomerDetail" :xuqiuList="xuqiuList" :ruleForm="ruleForm" :ruleFormParam="ruleFormDetail" :isRecommend="isRecommend" :laiyuanOptions="laiyuanOptions" :biaoqianOptions="biaoqianOptions" :fangwuyongtuOptions="fangwuyongtuOptions" :zhuangxiuOptions="zhuangxiuOptions" :cascaderShangquanOptions="cascaderShangquanOptions" />
            </el-tab-pane>
            <el-tab-pane name="weihujilu" label="维护记录" v-loading="loading">
                <WeiHuJiLu v-if="activeName=='weihujilu'" :isGenJinList='isGenJinList' @GetCustomerDetailRequest="GetCustomerDetailRequest" :isUpdate='isUpdate' :isRole='isRole' :customerId=" customerId" :dtCustomer="dtCustomer" :dtCustomerDetail="dtCustomerDetail" :xuqiuList="xuqiuList" :ruleForm="ruleForm" :ruleFormParam="ruleFormDetail" :isRecommend="isRecommend" :laiyuanOptions="laiyuanOptions" :biaoqianOptions="biaoqianOptions" :fangwuyongtuOptions="fangwuyongtuOptions" :zhuangxiuOptions="zhuangxiuOptions" :cascaderShangquanOptions="cascaderShangquanOptions" />
            </el-tab-pane>

        </el-tabs>
        <div v-if="isUpdate && type != '3'" class="hetong-xinxi" style="display: block;top: -33px;position: relative;z-index: 111;right: -3px; cursor:pointer">
            <span class="xinzengXuQiu" v-if="activeName=='xuqiukeyuan'" @click="updateGenJin('新增带看')">新增带看</span>
            <span class="xinzengXuQiu" v-if="activeName=='xuqiukeyuan'" @click="updateGenJin('新增跟进')">新增跟进</span>
        </div>
    </div>
</template>
<script>
import KeYuanJs from "./KeYuan.js"
import KeYuanInformation from "./KeYuanInformation.vue"
import WeiHuJiLu from "./WeiHuJiLu.vue"

export default {
    inject: ["reload"],
    components: {
        KeYuanInformation,
        WeiHuJiLu
    },
    data() {
        return {
            roleType: window.localStorage.getItem("roleType"),
            activeName: "",
            type: this.$route.query.type,
            num: this.$route.query.num,
            customerId: this.$route.query.customerId, //客源id
            state: this.$route.query.state, //1求购 0是求租
            self: this.$route.query.self,
            recommend: this.$route.query.recommend,
            isRecommend: false, //是否可以推荐给经纪人
            name: "",
            daiKan: "",
            isGenJinList: "3",
            isGenJin: false,
            loading: true,
            isRole: false,
            isUpdate: false,
            ruleForm: [],
            ruleFormDetail: [],
            xuqiuList: [],
            dtCustomerDetail: {
                type: "detail",
                customerName: "",
                gender: "",
                tel1: "",
                tel2: "",
                customerScore: "",
                laiyuan: "",
                xinXiLaiYuanId: "",
                idCardNo:'',
                wechatNo: "",
                email: "",
                ageRange: "",
                keHuBiaoQianId: "", //客户标签
                keHuBiaoQian: "",
                yiTuijian: false
            },
            dtCustomer: {
                type: "detail",
                customerName: "",
                gender: "",
                tel1: "",
                tel2: "",
                customerScore: "",
                laiyuan: "",
                xinXiLaiYuanId: "",
                idCardNo:'',
                wechatNo: "",
                email: "",
                ageRange: "",
                keHuBiaoQianId: "", //客户标签
                keHuBiaoQian: "",
                yiTuijian: false,
                isDeleteESign:"",
            }, //客户基本信息
            orientationOptions: this.$commonJs.orientationOptions,
            cascaderShangquanOptions: KeYuanJs.cascaderShangquanOptions,
            fangwuyongtuOptions: [
                { value: "买二手", label: "买二手" },
                { value: "租赁", label: "租赁" }
            ],
            zhuangxiuOptions: [],
            laiyuanOptions: [],
            biaoqianOptions: []
        }
    },
    created() {
        this.getShuJuZiDianList()
        // 0 新增 1 详情 2 修改
        if (this.num == 1) {
            this.loading = false
            if (this.state == "1") {
                this.name = "求购客源详情"
            } else {
                this.name = "求租客源详情"
            }
            this.ruleForm = []
        } else if (this.num == 2) {
            if (this.type == "1") {
                if (this.state == "1") {
                    this.name = "求购客源详情"
                } else {
                    this.name = "求租客源详情"
                }
            } else {
                if (this.state == "1") {
                    this.name = "求购客源修改"
                } else {
                    this.name = "求租客源修改"
                }
            }
        }
        if (
            (this.roleType == 1 && this.self == 1 && this.recommend == 0) ||
            ((this.roleType == 2 || this.roleType == 5 || this.roleType == 6) &&
                this.recommend == 0)
        ) {
            // ① roleType=1 经纪人权限下根据【self】为1和【recommend】为0时，可以【推荐合作经纪人】
            // ②经纪人权限下根据【self】为和【recommend】为1时，不可以【推荐合作经纪人】
            // ③ roleType=2 商圈、roleType=5 商户、roleType=6平台权限根据【recommend】为0时，可以【推荐合作经纪人】
            // ④roleType=2 商圈、roleType=5 商户、roleType=6平台权限根据【recommend】为1时，不可以【推荐合作经纪人】
            this.isRecommend = true
        }
        if (this.roleType == 1 || this.roleType == 5 || this.roleType == 6) {
            this.isRole = true //这几个角色可以查看相关信息
        }
        if (this.num == 2 && (this.roleType == 1 || this.roleType == 5 || this.roleType == 6)) {
            this.isUpdate = true //是否可以修改
        }
    },
    methods: {
        getShuJuZiDianList() {
            //数据字典
            let that = this
            that.$commonJs
                .getShuJuZiDianList(
                    "XINXILAIYUAN,KEHUBIAOQIAN,ZHUANGXIUZHUANGKUANG,SHANGQUAN"
                )
                .then((data) => {
                    that.laiyuanOptions = data.XinXiLaiYuan
                    that.biaoqianOptions = data.kehuBiaoQian
                    that.zhuangxiuOptions = data.ZHUANGXIUZHUANGKUANG
                    that.cascaderShangquanOptions = data.cascaderShangquan
                    if (that.customerId) {
                        that.GetCustomerDetail()
                    }
                })
        },
        GetCustomerDetailRequest() {
            this.GetCustomerDetail()
        },
        /* 获取日志列表 */
        GetCustomerDetail() {
            let that = this,
                dtRequirements = []
            that.xuqiuList = []
            that.ruleForm = []
            that.ruleFormDetail = []
            that.loading = true
            that.$http
                .get(that.$url.GetCustomerDetail, {
                    params: {
                        customerId: this.customerId //客源id
                    }
                })
                .then((res) => {
                    that.loading = false
                    if (res.data.msgCode == 1) {
                        if (res.data.dtCustomer.length > 0) {
                            that.dtCustomer.type = "detail"
                            that.dtCustomer = Object.assign(
                                that.dtCustomer,
                                res.data.dtCustomer[0]
                            )
                            that.dtCustomer.genderText =
                                that.dtCustomer.gender == "1" ? "男" : "女"
                            if (that.dtCustomer.xinXiLaiYuan != null) {
                                that.laiyuanOptions.forEach((item) => {
                                    if (
                                        item.label ==
                                        that.dtCustomer.xinXiLaiYuan.split(
                                            "/"
                                        )[0]
                                    ) {
                                        that.dtCustomer.laiyuan = item.value
                                        return false
                                    }
                                })
                            }
                            if (
                                res.data.dtCustomer[0].keHuBiaoQianId != null &&
                                that.biaoqianOptions.length > 0
                            ) {
                                that.biaoqianOptions.forEach((item) => {
                                    if (
                                        item.value ==
                                        that.dtCustomer.keHuBiaoQianId
                                    ) {
                                        that.dtCustomer.keHuBiaoQian =
                                            item.label
                                    }
                                })
                            }
                        }
                        if (
                            that.state == "1" &&
                            res.data.dtRequirementsToBuy.length > 0
                        ) {
                            dtRequirements = res.data.dtRequirementsToBuy //求购需求
                        }
                        if (
                            that.state == "0" &&
                            res.data.dtRequirementsToRent.length > 0
                        ) {
                            dtRequirements = res.data.dtRequirementsToRent //求租需求
                        }
                        if (dtRequirements.length > 0) {
                            dtRequirements.forEach((item, index) => {
                                if (
                                    item.fangWuYongTuId != "" &&
                                    item.fangWuYongTuId != null
                                ) {
                                    item.shangquan = []
                                    item.xuqiuType = ""
                                    item.yiTuijian = false
                                    item.id =
                                        that.state == "1"
                                            ? item.requirementToBuyId
                                            : item.requirementToRentId

                                    item.priceFrom =
                                        that.state == "1"
                                            ? item.priceFrom / 10000
                                            : item.priceFrom
                                    item.priceTo =
                                        that.state == "1"
                                            ? item.priceTo / 10000
                                            : item.priceTo
                                    item.ShouFuFrom =
                                        that.state == "1"
                                            ? item.ShouFuFrom / 10000
                                            : item.ShouFuFrom
                                    item.ShouFuTo =
                                        that.state == "1"
                                            ? item.ShouFuTo / 10000
                                            : item.ShouFuTo

                                    that.zhuangxiuOptions.forEach((element) => {
                                        //装修状况
                                        if (
                                            element.value ===
                                            item.zhuangXiuZhuangKuangId
                                        ) {
                                            item.zhuangXiuZhuangKuang =
                                                element.label
                                            return false
                                        }
                                    })
                                    item.areaNames = item.areaNames.replace(
                                        //意向商圈
                                        "/",
                                        "-"
                                    )
                                    let areaIds = item.areaIds.split(",")
                                    that.cascaderShangquanOptions.forEach(
                                        //意向商圈
                                        (cascaderShangquan, index) => {
                                            cascaderShangquan.children.forEach(
                                                (children, x) => {
                                                    areaIds.forEach(
                                                        (areaId, y) => {
                                                            if (
                                                                children.value ===
                                                                areaId
                                                            ) {
                                                                item.shangquan.push(
                                                                    [
                                                                        cascaderShangquan.label,
                                                                        children.value
                                                                    ]
                                                                )
                                                            }
                                                        }
                                                    )
                                                }
                                            )
                                        }
                                    )
                                    if (
                                        item.orientations != "" &&
                                        item.orientations != undefined &&
                                        item.orientations != null
                                    ) {
                                        //朝向
                                        let orientations = item.orientations
                                            .toString()
                                            .split("")
                                        item.orientationsLabel = []
                                        that.orientationOptions.forEach(
                                            (element, y) => {
                                                if (orientations[y] != "0") {
                                                    item.orientationsLabel.push(
                                                        element.label
                                                    )
                                                }
                                            }
                                        )
                                    } else {
                                        item.orientations = []
                                        item.orientationsLabel = []
                                    }
                                    that.xuqiuList.push(item)
                                }
                            })
                        }
                        that.ruleForm = that.xuqiuList
                        that.ruleFormDetail = JSON.parse(
                            JSON.stringify(that.xuqiuList)
                        )
                        that.activeName = "xuqiukeyuan"
                    } else {
                        that.$message.error(res.data.message)
                    }
                })
        },
        cancel() {
            if (this.$route.query.type == '3') {
               this.$router.push({
                name: "KeYuanGongXiangChiList",}) 
            } else {
                this.$router.push({
                name: "KeYuanList",
                query: {
                    state: this.state
                }
            })
            }
            
        },
        GenJin(val, type) {
            this.isGenJin = val
            this.isGenJinList = type
        },
        DaiKan(val) {
            this.daiKan = val
        },
        updateGenJin(val) {
            this.isGenJin = true
            if (val == "新增带看") {
                this.isGenJinList = "1"
            }
            if (val == "新增跟进") {
                this.isGenJinList = "0"
            }
        },
        handleClick(tab) {
            if (tab.label == "维护记录") {
                this.isGenJinList = "4"
            }
            if (tab.label == "客源信息") {
                this.isGenJinList = "3"
            }
        }
    }
}
</script>

<style lang="less" scoped>
.xinzengXuQiu {
    color: #df0202 !important;
    padding-right: 20px;
    border-radius: 3px;
    float: right;
    font-weight: 600;
    margin-top: 3px;
    font-size: 14px;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
.qiugou-tabs {
    font-size: 14px;
    /deep/ .el-card {
        line-height: 30px;
    }
    /deep/ .el-tabs__nav > .is-active {
        background: transparent !important;
        color: #df0202 !important;
    }
    /deep/ .el-tabs__active-bar {
        background-color: #df0202;
    }
    /deep/ .el-tabs__header {
        padding-left: 20px;
        margin: 0px;
    }
    /deep/ .el-tabs__active-bar,
    /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
    }
    /deep/ .el-card__body {
        margin: 5px 20px;
        padding: 0px !important;
    }
    /deep/ .el-tabs__item:hover {
        background-color: transparent;
    }
    /deep/ .el-card {
        border: none;
    }
    /deep/ .el-tabs__nav-wrap {
        position: static;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        font-size: 14px;
        letter-spacing: 1px;
        width: 100px;
        line-height: 30px;
    }
}
</style>