import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import TreeTable from "vue-table-with-tree-grid";
import preventReClick from "../src/components/Home/Disable.vue";
import md5 from "js-md5";
import axios from "axios";
import url from "./config/url.js";
import "./assets/css/global.css";
import "./assets/css/icon/iconfont.css";
import "./assets/css/icon2/iconfont.css";

import Print from "vue-print-nb"; // 打印
Vue.use(Print);
Vue.prototype.$print = Print;

import commonJs from "./assets/js/common.js"; //共通js方法
import timejs from "./assets/js/time.js"; //获取时间方法
import Router from "vue-router";
import ElementUI, { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; // element-ui的css

Vue.use(ElementUI); // 使用elementUI
let Message = ElementUI.Message;
Vue.use(Loading.directive);
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
Vue.use(preventReClick);
Vue.prototype.$md5 = md5;
Vue.prototype.$http = axios;
Vue.prototype.$url = url;
Vue.prototype.$commonJs = commonJs;
Vue.prototype.$timejs = timejs;

axios.interceptors.response.use(
  function(response) {
    if (response.data.message == "Unknown") {
      Message.error("未知的失败");
    } else if (response.data.message == "AuthFailure") {
      router.replace({ path: "/GetLogin" });
      localStorage.clear();
      Message.error("认证失败");
    } else if (response.data.message == "AuthSuccessButNoAccess") {
      Message.error("认证成功 但没有权限调用接口");
    } else if (response.data.message == "AuthFailureSinceHaveNoAuthMsg") {
      router.replace({ path: "/GetLogin" });
      localStorage.clear();
      Message.error("认证失败 因为没有fp或accessToken");
    } else if (response.data.message == "OperationFailure") {
      Message.error("业务失败");
    } else if (response.data.message == "OtherFailure") {
      Message.error("其他的失败");
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;
Vue.component("tree-table", TreeTable);

new Vue({
  router,
  Message,
  render: h => h(App)
}).$mount("#app");
