<template>
    <div>
        <div>
            <!-- 面包屑导航区域 -->
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>培训课程</el-breadcrumb-item>

      </el-breadcrumb> -->
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span class="title">培训课程标题：</span>
                        <el-input v-model="articleTitle" placeholder="请输入培训课程标题" style="width: 15%"></el-input>
                        <!-- <span style="margin-left: 15px;">发布日期：</span>
            <el-date-picker v-model="petBreedName"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="清选择发布日期"
                            style="width: 20%; text-align: left"
                            :picker-options="pickerOptions">
            </el-date-picker> -->
                        <!-- <span class="title" style="margin-left: 15px;">是否可用：</span>
                        <el-select v-model="enabled" placeholder="请选择是否可用" style="width: 25%">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select> -->
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetArticleList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px" v-if="roleType == 4 || roleType == 6">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style=" border: none">新增</el-button>
                        <!-- <el-button size="mini" 
                       @click="handleEdit"
                       type="primary"
                       icon="el-icon-edit"
                       style="background-color: rgb(14 153 219); border: none; margin-left: 10px">修改</el-button> -->
                        <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="培训课程标题" prop="articleTitle">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.articleTitle }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <!-- {{ scope.row }} -->
                            <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            drow: "",
            loading: false,
            total: 0,
            operationList: [],
            articleTitle: "",
            articleType: "PEIXUNKECHENG",
            currentPage: 1,
            pageSize: 10,
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            roleType: ""
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
        this.roleType = window.localStorage.getItem("roleType")
    },
    methods: {
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "PeiXunKeChengDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1,
                    articleType: this.articleType
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "PeiXunKeChengDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2,
                    articleType: "PEIXUNKECHENG"
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getArticleList() {
            const data = {
                articleTitle: this.articleTitle,
                articleType: this.articleType,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetArticleList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取培训课程成功")
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.getArticleList()
        },
        // 删除数据
        async handleDelete(row) {
            var data1 = [row.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then(res => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getArticleList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                }).catch(err => {})
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                        const { data: res } = this.$http
                            .delete(this.$url.DeleteArticle, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then(res => {
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.getArticleList()
                                    this.reload()
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    })
                    .catch(err => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "PeiXunKeChengDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3,
                    articleType: this.articleType
                }
            })
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$router.push({
                    name: "InsertArticle",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
