<template>
    <div>
        <div>
            <el-card :class="{'search': Number(HouseType) !== 1}">
                <el-row class="mabot8">
                    <el-col>
                        <!-- 其他 -->
                        <span v-if="AreaRegion==2 && XinXiForm == 2 && HouseType == 2" class="title" style="margin-right:0.5%">{{ label1 }}</span>
                        <el-input v-if="AreaRegion==2 && XinXiForm == 2 && HouseType == 2" v-model="jiaoYiQuanShuList.commonMasterName" :placeholder="'请输入' + label" style="width: 20%"></el-input>
                        <!-- 商圈和信息来源 -->
                        <span v-if="AreaRegion==1 || XinXiForm == 1" class="title" style="margin-right:0.5%;">{{ ShangQuanHeXinXi1 + ':' }} </span>
                        <el-input v-if="AreaRegion ==1 || XinXiForm == 1" v-model="jiaoYiQuanShuList.commonInform1" :placeholder="'请输入' + ShangQuanHeXinXi1" style="width: 20%"></el-input>
                        <span v-if="AreaRegion==1 || XinXiForm == 1" class="title" style="margin-right:0.5%;margin-left: 2%;">{{ ShangQuanHeXinXi2 + ':' }} </span>
                        <el-input v-if="AreaRegion ==1 || XinXiForm == 1" v-model="jiaoYiQuanShuList.commonInform2" :placeholder="'请输入' + ShangQuanHeXinXi2" style="width: 20%"></el-input>
                        <!-- 户型 -->
                        <!-- <span v-if="HouseType == 1" class="title" style="margin-right:0.5%;">户型:</span>
                        <el-input v-if="HouseType == 1" maxlength="1" v-model="jiaoYiQuanShuList.huXingNum1" placeholder="请输入数字" style="width: 8%" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                        <span class="huXing" v-if="HouseType == 1">室</span>
                        <el-input v-if="HouseType == 1" maxlength="1" v-model="jiaoYiQuanShuList.huXingNum2" placeholder="请输入数字" style="width: 8%" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                        <span class="huXing" v-if="HouseType == 1">厅</span>
                        <el-input v-if="HouseType == 1" maxlength="1" v-model="jiaoYiQuanShuList.huXingNum3" placeholder="请输入数字" style="width: 8%" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                        <span class="huXing" v-if="HouseType == 1">厨</span>
                        <el-input v-if="HouseType == 1" maxlength="1" v-model="jiaoYiQuanShuList.huXingNum4" placeholder="请输入数字" style="width: 8%" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                        <span class="huXing" v-if="HouseType == 1">卫</span> -->
                        <span style="float: right;" v-if="HouseType != 1">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetJiaoYiQuanShuList">查询</el-button>
                            <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                        </span>

                    </el-col>

                </el-row>
                <el-row v-if="this.roleType == '6'">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                        <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe class="dialogList">
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column v-if="AreaRegion!=1 && XinXiForm != 1 && HouseType != 1" :label="label">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row.commonMasterId)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.commonMasterName }}</el-button>
                        </template>
                    </el-table-column>
                    <!-- 商圈 -->
                    <el-table-column v-if="AreaRegion==1" label="区域" prop="Area" key="1">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row.commonMasterId)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.Area }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="AreaRegion ==1" label="商圈名" key="2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.AreaName }}</span>
                        </template>
                    </el-table-column>
                    <!-- 信息来源 -->
                    <el-table-column v-if="XinXiForm == 1" label="信息来源(一级)" prop="Inform1" key="1">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row.commonMasterId)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.Inform1 }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="XinXiForm == 1" label="信息来源(二级)" key="2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.Inform2 }}</span>
                        </template>
                    </el-table-column>
                    <!-- 户型 -->
                    <el-table-column v-if="HouseType == 1" label="户型" prop="Area" key="1">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row.commonMasterId)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.huXingNum1 + '室' + scope.row.huXingNum2 + '厅' + scope.row.huXingNum3 + '厨'+ scope.row.huXingNum4 + '卫' }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="权重" prop="weight"></el-table-column>
                    <el-table-column label="创建人" prop="createPersonName"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column fixed="right" label="操作" v-if="this.roleType == '6'">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row.commonMasterId)" type="text" size="small" class="el-icon-edit">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="jiaoYiQuanShuList.currentPage" :page-sizes="[10, 20, 50]" :page-size="jiaoYiQuanShuList.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
            <!-- 弹出层 -->
            <el-dialog :title="title" :visible.sync="addedDialog" width="40%" @close="addedDialogClose">
                <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                    <el-row v-if="AreaRegion==2 && XinXiForm == 2 && HouseType == 2">
                        <el-col :span="24" class="marginL7" style="height: 50px">
                            <el-form-item :label="label1" prop="commonMasterName">
                                <el-input :disabled="flag" style="width:75%" v-model="form.commonMasterName" :placeholder="'请输入' + label"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 商圈 -->
                    <el-row v-if="AreaRegion == 1">
                        <el-col :span="24" class="marginL7" style="height: 50px">
                            <el-form-item label="区域:" prop="valueArea">
                                <span v-if="isShow" style="position: relative;top: 0;left: -57.9px;color: #F56C6C;margin-right: 4px;">*</span>
                                <el-input :class="{activeClass : isShow }" :disabled="flag" style="width:75%" v-model="valueArea" placeholder="请输入区域"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="AreaRegion == 1">
                        <el-col :span="24" class="marginL7" style="height: 50px">
                            <el-form-item label="商圈名:" prop="valueAreaName">
                                <span v-if="isShow1" style="color: #F56C6C;position: relative;top: 0;left:-72.9px;margin-right: 4px;">*</span>
                                <el-input :class="{activeClass : isShow1 }" :disabled="flag" style="width:75%" v-model="valueAreaName" placeholder="请输入商圈名"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 信息来源 -->
                    <el-row v-if="XinXiForm == 1">
                        <el-col :span="24" class="marginL7" style="height: 50px">
                            <el-form-item label="信息来源(一级):" prop="information1" label-width="23%">
                                <span v-if="isXinXi1" style="position: relative;top: 0;left: -128.9px;color: #F56C6C;margin-right: 4px;">*</span>
                                <el-input :class="{activeClass : isXinXi1 }" :disabled="flag" style="width:75%" v-model="information1" placeholder="请输入信息来源(一级)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="marginL7" style="height: 50px">
                            <el-form-item label="信息来源(二级):" prop="information2" label-width="23%">
                                <span v-if="isXinXi2" style="position: relative;top: 0;left: -128.9px;color: #F56C6C;margin-right: 4px;">*</span>
                                <el-input :class="{activeClass : isXinXi2 }" :disabled="flag" style="width:75%" v-model="information2" placeholder="请输入信息来源(二级)"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 户型 -->
                    <el-row v-if="HouseType == 1">
                        <el-col :span="24" style="height: 50px; margin-left:1%">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="户型:" prop="HuXing1" label-width="41%">
                                        <span v-if="isHuXing" style="position: relative;top: 0;left: -57.9px;color: #F56C6C;margin-right: 4px;">*</span>
                                        <el-input maxlength="1" v-model="HuXing1" :class="{activeClass : isHuXing }" :disabled="flag" style="width: 92px" placeholder="请输入数字" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                                        <span style="margin-left:5px">室</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="HuXing2" style="margin-left:-18%">
                                        <el-input maxlength="1" v-model="HuXing2" :disabled="flag" placeholder="请输入数字" style="width: 92px" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                                        <span style="margin-left:5px">厅</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="HuXing3" style="margin-left:-20%">
                                        <el-input maxlength="1" v-model="HuXing3" :disabled="flag" placeholder="请输入数字" style="width: 92px" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                                        <span style="margin-left:6px">厨</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item prop="HuXing4" style="margin-left:-20%">
                                        <el-input maxlength="1" v-model="HuXing4" :disabled="flag" placeholder="请输入数字" style="width: 92px" onkeyup="this.value=this.value.replace(/\D/g, '')"></el-input>
                                        <span style="margin-left:5px">卫</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24" :class="{'HuXingmargin': Number(HouseType) === 1 , 'marginL7':Number(HouseType) === 2}" style="height: 50px">
                            <el-form-item label="权重:" prop="weight" :label-width="XinXiWeight">
                                <el-input maxlength="3" placeholder="请输入权重" :disabled="flag" style="width:75%" v-model="form.weight" v-on:input="form.weight=onlyNum(form.weight)"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="logtime el-row">
                        <el-col :span="12" class="marginL7">
                            <el-form-item v-if="num != 0" label="创建时间:" label-width="40%">{{ form.createTime }}</el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item v-if="num != 0" label="创建人:" label-width="40%">{{ form.userName }}</el-form-item>
                        </el-col>
                        <el-col :span="12" class="marginL7">
                            <el-form-item v-if="num != 0" label="更新时间:" label-width="40%">{{ form.updateTime }}</el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item v-if="num != 0" label="更新人:" label-width="40%">{{ form.updatePersonName }}</el-form-item>
                        </el-col>
                    </div>
                    <el-form-item style="margin-top:5%;" :class="{'commenBtn1': Number(HouseType) === 1,'commenBtn':Number(HouseType) === 2 }">
                        <el-button size="mini" type="warning" @click="addedDialog = false" v-if="num != 1">取消</el-button>
                        <el-button style="margin-left:30%" size="mini" type="warning" @click="addedDialog = false" v-if="num == 1">返回</el-button>
                        <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                        <el-button size="mini" type="primary" v-if="num == 2" @click="onUpdate('form')">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </div>
    </div>
</template>

<script>
export default {
    name: "JiaoYiQuanShuList",
    inject: ["reload"],
    data() {
        // 自定义校验
        var check = (rule, value, callback) => {
            if (!value) {
                // 交易权属
                if (this.name == "JiaoYiQuanShuList") {
                    return callback(new Error("请输入交易权属"))
                }
                // 房屋用途
                if (this.name == "FangWuYongTuList") {
                    return callback(new Error("请输入房屋用途"))
                }
                // 客户标签
                if (this.name == "KeHuBiaoQianList") {
                    return callback(new Error("请输入客户标签"))
                }
                // 户型
                if (this.name == "HuXingList") {
                    return callback(new Error("请输入数字"))
                }
                // 物业类型
                if (this.name == "WuYeLeiXingList") {
                    return callback(new Error("请输入物业类型"))
                }
                // 付佣方式
                if (this.name == "FuYongFangShiList") {
                    return callback(new Error("请输入付佣方式"))
                }
                // 信息来源
                if (this.name == "XinXiLaiYuanList") {
                    return callback(new Error("请输入信息来源"))
                }
                // 装修状况
                if (this.name == "ZhuangxiuzhuangkuangList") {
                    return callback(new Error("请输入装修状况"))
                }
                // 建筑结构
                if (this.name == "JianZhuJieGouList") {
                    return callback(new Error("请输入建筑结构"))
                }
            } else {
                callback() // 添加成功回调
            }
        }
        return {
            // 信息来源
            // 权重的label-width
            XinXiWeight: "",
            // 判断*和input的margin显示与隐藏
            isXinXi1: "",
            isXinXi2: "",
            // 判断是否显示信息来源列表和详情
            XinXiForm: "",
            // 商圈
            // 判断*和input的margin显示与隐藏
            isShow: false,
            isShow1: false,
            // 户型
            // 判断是否显示信息来源列表和详情
            HouseType: "",
            // 判断*和input的margin显示与隐藏
            isHuXing: false,
            // 判断表格是否显示商圈
            AreaRegion: "",
            // 信息来源v-model绑定数据
            information1: "",
            information2: "",
            // 商圈v-model绑定数据
            valueArea: "",
            valueAreaName: "",
            // 户型v-model绑定数据
            HuXing1: "",
            HuXing2: "",
            HuXing3: "",
            HuXing4: "",
            // 表单名字
            label: "",
            // 商圈和信息来源共用v-model
            ShangQuanHeXinXi1: "",
            ShangQuanHeXinXi2: "",
            // 判断页面值
            num: "",
            // 表单是否禁用
            flag: false,
            // 判断type
            commonMasterType: "",
            // 根据name给commonMasterType赋值
            name: this.$route.name,
            // 调用接口传的数据
            jiaoYiQuanShuList: {
                // 户型输入框
                huXingNum1: "",
                huXingNum2: "",
                huXingNum3: "",
                huXingNum4: "",
                // 商圈和信息来源输入框
                commonInform1: "",
                commonInform2: "",
                commonMasterName: "",
                currentPage: 1,
                pageSize: 10
            },
            // 弹出层
            addedDialog: false,
            // 弹出框头部文字
            title: "",
            // 是否加载
            loading: false,
            // 分页器数据
            total: 0,
            // 获取列表数据
            operationList: [],
            // 弹出层数据
            form: {},
            // 验证规则
            rules: {
                // 共用名字
                commonMasterName: [
                    { validator: check, trigger: "blur", required: true }
                ],
                // 权重
                weight: [
                    {
                        required: true,
                        message: "请输入权重",
                        trigger: "blur"
                    }
                ],
                // 商圈区域
                valueArea: [
                    {
                        required: true,
                        message: "请输入区域",
                        trigger: "blur"
                    }
                ],
                // 商圈 商圈名
                valueAreaName: [
                    {
                        required: true,
                        message: "请输入商圈名",
                        trigger: "blur"
                    }
                ],
                // 信息来源一
                information1: [
                    {
                        required: true,
                        message: "请输入信息来源（一级）",
                        trigger: "blur"
                    }
                ],
                // 信息来源二
                information2: [
                    {
                        required: true,
                        message: "请输入信息来源（二级）",
                        trigger: "blur"
                    }
                ],
                // 户型
                HuXing1: [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ],
                HuXing2: [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ],
                HuXing3: [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ],
                HuXing4: [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ],
                // 权限
                roleType: ""
            }
        }
    },
    mounted() {
        this.gettype()
    },

    watch: {
        $route(to, from) {
            this.name = this.$route.name
            // 对路由变化作出响应...
            this.gettype()
        },
        valueArea(to, from) {
            if (to != "") {
                this.isShow = true
                this.valueArea = to
                this.rules.valueArea = [
                    {
                        required: false,
                        message: "请输入商圈名",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isShow = false
                this.valueArea = to
                this.rules.valueArea = [
                    {
                        required: true,
                        message: "请输入商圈名",
                        trigger: "blur"
                    }
                ]
            }
        },
        valueAreaName(to, from) {
            if (to != "") {
                this.isShow1 = true
                this.valueAreaName = to
                this.rules.valueAreaName = [
                    {
                        required: false,
                        message: "请输入商圈名",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isShow1 = false
                this.valueAreaName = to
                this.rules.valueAreaName = [
                    {
                        required: true,
                        message: "请输入商圈名",
                        trigger: "blur"
                    }
                ]
            }
        },
        information1(to, from) {
            if (to != "") {
                this.isXinXi1 = true

                this.information1 = to
                this.rules.information1 = [
                    {
                        required: false,
                        message: "请输入信息来源（一级）",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isXinXi1 = false
                this.information1 = to
                this.rules.information1 = [
                    {
                        required: true,
                        message: "请输入信息来源（一级）",
                        trigger: "blur"
                    }
                ]
            }
        },
        information2(to, from) {
            if (to != "") {
                this.isXinXi2 = true

                this.information2 = to
                this.rules.information2 = [
                    {
                        required: false,
                        message: "请输入信息来源（二级）",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isXinXi2 = false
                this.information2 = to
                this.rules.information2 = [
                    {
                        required: true,
                        message: "请输入信息来源（二级）",
                        trigger: "blur"
                    }
                ]
            }
        },
        HuXing1(to, from) {
            if (to != "") {
                this.isHuXing = true
                this.HuXing1 = to
                this.rules.HuXing1 = [
                    {
                        required: false,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isHuXing = false
                this.HuXing1 = to
                this.rules.HuXing1 = [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            }
        },
        HuXing2(to, from) {
            if (to != "") {
                this.isHuXing = true
                this.HuXing2 = to
                this.rules.HuXing2 = [
                    {
                        required: false,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isHuXing = false
                this.HuXing2 = to
                this.rules.HuXing2 = [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            }
        },
        HuXing3(to, from) {
            if (to != "") {
                this.isHuXing = true
                this.HuXing3 = to
                this.rules.HuXing3 = [
                    {
                        required: false,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isHuXing = true
                this.HuXing3 = to
                this.rules.HuXing3 = [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            }
        },
        HuXing4(to, from) {
            if (to != "") {
                this.isHuXing = true
                this.HuXing4 = to
                this.rules.HuXing4 = [
                    {
                        required: false,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            } else {
                this.isHuXing = true
                this.HuXing4 = to
                this.rules.HuXing4 = [
                    {
                        required: true,
                        message: "请输入数字",
                        trigger: "blur"
                    }
                ]
            }
        }
    },

    created() {
        this.gettype()
        this.roleType = window.localStorage.getItem("roleType")
    },
    methods: {
        // 判断type值显示页面
        gettype() {
            this.jiaoYiQuanShuList.commonInform1 = ""
            this.jiaoYiQuanShuList.commonInform2 = ""
            this.jiaoYiQuanShuList.commonMasterName = ""
            this.form = {}
            this.valueArea = ""
            this.valueAreaName = ""
            this.HuXing1 = ""
            this.HuXing2 = ""
            this.HuXing3 = ""
            this.HuXing4 = ""
            // 商圈
            if (this.name == "ShangQuanList") {
                this.commonMasterType = "SHANGQUAN"
                this.AreaRegion = 1
                this.label = "区域"
                this.label1 = "区域:"
                this.ShangQuanHeXinXi1 = "区域"
                this.ShangQuanHeXinXi2 = "商圈名"
            } else {
                this.AreaRegion = 2
            }
            // 交易权属
            if (this.name == "JiaoYiQuanShuList") {
                this.commonMasterType = "JIAOYIQUANSHU"
                this.label = "交易权属"
                this.label1 = "交易权属:"
            }
            // 房屋用途
            if (this.name == "FangWuYongTuList") {
                this.commonMasterType = "FANGWUYONGTU"
                this.label = "房屋用途"
                this.label1 = "房屋用途:"
            }
            // 客户标签
            if (this.name == "KeHuBiaoQianList") {
                this.commonMasterType = "KEHUBIAOQIAN"
                this.label = "客户标签"
                this.label1 = "客户标签:"
            }
            // 户型
            if (this.name == "HuXingList") {
                this.commonMasterType = "HUXING"
                this.label = "户型"
                this.label1 = "户型:"
                this.HouseType = 1
            } else {
                this.HouseType = 2
            }
            // 物业类型
            if (this.name == "WuYeLeiXingList") {
                this.commonMasterType = "WUYELEIXING"
                this.label = "物业类型"
                this.label1 = "物业类型:"
            }
            // 付佣方式
            if (this.name == "FuYongFangShiList") {
                this.commonMasterType = "FUYONGFANGSHI"
                this.label = "付佣方式"
                this.label1 = "付佣方式:"
            }
            // 信息来源
            if (this.name == "XinXiLaiYuanList") {
                this.commonMasterType = "XINXILAIYUAN"
                this.label = "信息来源"
                this.label1 = "信息来源(一级):"
                this.XinXiForm = 1
                this.XinXiWeight = "23%"
                this.ShangQuanHeXinXi1 = "信息来源(一级)"
                this.ShangQuanHeXinXi2 = "信息来源(二级)"
            } else {
                this.XinXiForm = 2
                this.XinXiWeight = ""
            }
            // 装修状况
            if (this.name == "ZhuangxiuzhuangkuangList") {
                this.commonMasterType = "ZHUANGXIUZHUANGKUANG"
                this.label = "装修状况"
                this.label1 = "装修状况:"
            }
            // 建筑结构
            if (this.name == "JianZhuJieGouList") {
                this.commonMasterType = "JIANZHUJIEGOU"
                this.label = "建筑结构"
                this.label1 = "建筑结构:"
            }
            this.getJiaoYiQuanShuList()
        },
        // 新增
        jumpToInsert() {
            this.flag = false
            this.addedDialog = true
            this.num = 0
            this.title = this.label + "新增"
            this.valueArea = ""
            this.valueAreaName = ""
            this.information1 = ""
            this.information2 = ""
            this.HuXing1 = ""
            this.HuXing2 = ""
            this.HuXing3 = ""
            this.HuXing4 = ""
        },
        // 详情
        async openDetails2(id) {
            this.flag = true
            this.addedDialog = true
            this.num = 1
            this.title = this.label + "详情"
            const { data: res } = await this.$http.get(
                this.$url.GetCommonMasterDetail,
                {
                    params: {
                        commonMasterId: id
                    }
                }
            )
            if (res.msgCode == 1) {
                this.form = res.dtList[0]
                if (this.commonMasterType == "SHANGQUAN") {
                    this.valueArea = this.form.commonMasterName.split("/")[0]
                    this.valueAreaName =
                        this.form.commonMasterName.split("/")[1]
                }
                if (this.commonMasterType == "XINXILAIYUAN") {
                    this.information1 = this.form.commonMasterName.split("/")[0]
                    this.information2 = this.form.commonMasterName.split("/")[1]
                }
                if (this.commonMasterType == "HUXING") {
                    this.HuXing1 = this.form.commonMasterName.split("-")[0]
                    this.HuXing2 = this.form.commonMasterName.split("-")[1]
                    this.HuXing3 = this.form.commonMasterName.split("-")[2]
                    this.HuXing4 = this.form.commonMasterName.split("-")[3]
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 查询
        cgetJiaoYiQuanShuList() {
            this.jiaoYiQuanShuList.currentPage = 1
            if (
                this.commonMasterType == "SHANGQUAN" ||
                this.commonMasterType == "XINXILAIYUAN"
            ) {
                this.jiaoYiQuanShuList.commonMasterName =
                    this.jiaoYiQuanShuList.commonInform1 +
                    "/" +
                    this.jiaoYiQuanShuList.commonInform2
            }
            this.getJiaoYiQuanShuList()
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取列表 */
        async getJiaoYiQuanShuList() {
            let that = this
            that.operationList = []
            const data = {
                commonMasterName: that.jiaoYiQuanShuList.commonMasterName,
                commonMasterTypes: that.commonMasterType,
                currentPage: that.jiaoYiQuanShuList.currentPage,
                pageSize: that.jiaoYiQuanShuList.pageSize
            }
            that.loading = true

            const { data: res } = await that.$http.get(
                that.$url.GetCommonMasterList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                if (that.commonMasterType == "SHANGQUAN") {
                    for (var i = 0; i < res.dtList.length; i++) {
                        res.dtList[i].Area =
                            res.dtList[i].commonMasterName.split("/")[0]
                        res.dtList[i].AreaName =
                            res.dtList[i].commonMasterName.split("/")[1]
                    }
                    that.operationList = res.dtList
                } else {
                    that.operationList = res.dtList
                }
                if (that.commonMasterType == "XINXILAIYUAN") {
                    for (var i = 0; i < res.dtList.length; i++) {
                        res.dtList[i].Inform1 =
                            res.dtList[i].commonMasterName.split("/")[0]
                        res.dtList[i].Inform2 =
                            res.dtList[i].commonMasterName.split("/")[1]
                    }
                    that.operationList = res.dtList
                } else {
                    that.operationList = res.dtList
                }
                if (that.commonMasterType == "HUXING") {
                    for (var i = 0; i < res.dtList.length; i++) {
                        res.dtList[i].huXingNum1 =
                            res.dtList[i].commonMasterName.split("-")[0]
                        res.dtList[i].huXingNum2 =
                            res.dtList[i].commonMasterName.split("-")[1]
                        res.dtList[i].huXingNum3 =
                            res.dtList[i].commonMasterName.split("-")[2]
                        res.dtList[i].huXingNum4 =
                            res.dtList[i].commonMasterName.split("-")[3]
                    }
                    that.operationList = res.dtList
                } else {
                    that.operationList = res.dtList
                }
                that.total = res.maxRecords
                that.loading = false
            } else {
                that.loading = false
                that.$message({
                    type: "error",
                    message: res.data.message
                })
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.jiaoYiQuanShuList.pageSize = newSize
            this.getJiaoYiQuanShuList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.jiaoYiQuanShuList.currentPage = newPage
            this.getJiaoYiQuanShuList()
        },
        // 重置
        cancelbreedList() {
            this.jiaoYiQuanShuList.commonInform1 = ""
            this.jiaoYiQuanShuList.commonInform2 = ""
            this.jiaoYiQuanShuList.commonMasterName = ""
            this.jiaoYiQuanShuList.currentPage = 1
            this.jiaoYiQuanShuList.huXingNum1 = ""
            this.jiaoYiQuanShuList.huXingNum2 = ""
            this.jiaoYiQuanShuList.huXingNum3 = ""
            this.jiaoYiQuanShuList.huXingNum4 = ""
            this.getJiaoYiQuanShuList()
        },
        // 批量删除数据
        handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    confirmButtonText: "确定",
                    type: "warning"
                }).catch((err) => {})
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].commonMasterId)
                        }
                        const { data: res } = this.$http
                            .delete(this.$url.DeleteCommonMaster, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.getJiaoYiQuanShuList()
                                    // this.reload();
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => err)
            }
        },
        // 删除数据
        async handleDelete(row) {
            var data1 = [row.commonMasterId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteCommonMaster, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getJiaoYiQuanShuList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 点击修改
        async handleClick(id) {
            this.flag = false
            this.addedDialog = true
            this.num = 2
            this.title = this.label + "修改"
            const { data: res } = await this.$http.get(
                this.$url.GetCommonMasterDetail,
                {
                    params: {
                        commonMasterId: id
                    }
                }
            )
            if (res.msgCode == 1) {
                this.form = res.dtList[0]
                if (this.commonMasterType == "SHANGQUAN") {
                    this.valueArea = this.form.commonMasterName.split("/")[0]
                    this.valueAreaName =
                        this.form.commonMasterName.split("/")[1]
                }
                if (this.commonMasterType == "XINXILAIYUAN") {
                    this.information1 = this.form.commonMasterName.split("/")[0]
                    this.information2 = this.form.commonMasterName.split("/")[1]
                }
                if (this.commonMasterType == "HUXING") {
                    this.HuXing1 = this.form.commonMasterName.split("-")[0]
                    this.HuXing2 = this.form.commonMasterName.split("-")[1]
                    this.HuXing3 = this.form.commonMasterName.split("-")[2]
                    this.HuXing4 = this.form.commonMasterName.split("-")[3]
                }
                this.addedDialog = true
            }
        },
        // 弹出层关闭时清除校验
        addedDialogClose() {
            this.$refs.form.resetFields()
        },
        // 确定新增
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.commonMasterType == "SHANGQUAN") {
                        this.form.commonMasterName =
                            this.valueArea + "/" + this.valueAreaName
                    }
                    if (this.commonMasterType == "XINXILAIYUAN") {
                        this.form.commonMasterName =
                            this.information1 + "/" + this.information2
                    }
                    if (this.commonMasterType == "HUXING") {
                        this.form.commonMasterName =
                            this.HuXing1 +
                            "-" +
                            this.HuXing2 +
                            "-" +
                            this.HuXing3 +
                            "-" +
                            this.HuXing4
                    }

                    const data = {
                        commonMasterName: this.form.commonMasterName,
                        commonMasterType: this.commonMasterType,
                        weight: this.form.weight
                    }
                    const { data: res } = await this.$http.post(
                        this.$url.InsertCommonMaster,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增" + this.label + "成功")
                        this.getJiaoYiQuanShuList()
                        this.addedDialog = false
                        return
                    } else {
                        this.$message.error(res.message)
                    }
                }
            })
        },
        // 确定修改
        async onUpdate() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    if (this.commonMasterType == "SHANGQUAN") {
                        this.form.commonMasterName =
                            this.valueArea + "/" + this.valueAreaName
                    }
                    if (this.commonMasterType == "XINXILAIYUAN") {
                        this.form.commonMasterName =
                            this.information1 + "/" + this.information2
                    }
                    if (this.commonMasterType == "HUXING") {
                        this.form.commonMasterName =
                            this.HuXing1 +
                            "-" +
                            this.HuXing2 +
                            "-" +
                            this.HuXing3 +
                            "-" +
                            this.HuXing4
                    }
                    const data = {
                        commonMasterId: this.form.commonMasterId,
                        commonMasterName: this.form.commonMasterName,
                        commonMasterType: this.commonMasterType,
                        weight: this.form.weight
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.UpdateCommonMaster,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改" + this.label + "成功")
                        this.getJiaoYiQuanShuList()
                        this.addedDialog = false
                    } else {
                        this.$message.error(res.message)
                    }
                }
            })
        },
        onlyNum(value) {
            return value.replace(/\D/g, "")
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
/deep/.el-dialog__body {
    padding: 0 20px 20px 20px !important;
}
.activeClass {
    margin-left: -10.5px;
}
.active {
    width: 23%;
}
.huXing {
    padding: 0 5px;
}
.HuXingmargin {
    margin-left: -5%;
}
.commenBtn {
    margin-top: 10px;
    margin-left: 65%;
}
.commenBtn1 {
    margin-top: 10px;
    margin-left: 75%;
}
</style>
