<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
    </el-breadcrumb> -->
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/XianSuoList' }">
                <el-button size="mini" class="el-button1" type="primary" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="线索名称:" prop="xiansuoMC">
                            <el-input :disabled="flag" style="width: 60%" placeholder="请输入线索名称" v-model="form.xiansuoMC"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="线索级别:" prop="xiansuoJB">
                            <el-select :disabled="flag" v-model="form.xiansuoJB" placeholder="请选择线索级别" style="width: 60%">
                                <el-option v-for="item in xiansuoJB" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="业务类型:" prop="yewuLX">
                            <el-select :disabled="flag" v-model="form.yewuLX" placeholder="请选择业务类型" style="width: 60%">
                                <el-option v-for="item in yewuLX" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="线索来源:" prop="xiansuoLY">
                            <el-select :disabled="flag" v-model="form.xiansuoLY" placeholder="请选择线索来源" style="width: 60%">
                                <el-option v-for="item in xiansuoLY" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="意向区域:" prop="yixiangQY">
                            <el-select :disabled="flag" v-model="form.yixiangQY" placeholder="请选择意向区域" style="width: 60%">
                                <el-option v-for="item in yixiangQY" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" @click="cancel" v-if="num != 1">取消</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    props: {
        /*编辑器的内容*/
        value: null,
        /*图片大小*/
        maxSize: {
            type: Number,
            default: 4000 //kb
        }
    },
    components: {},
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            name: "",
            flag: false,
            dialogVisible: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            form: {
                xiansuoMC: "",
                xiansuoJB: "",
                yewuLX: "",
                xiansuoLY: "",
                yixiangQY: ""
            },
            xiansuoJB: [
                {
                    value: "0",
                    label: "精准线索"
                },
                {
                    value: "1",
                    label: "优质线索"
                },
                {
                    value: "2",
                    label: "普通线索"
                },
                {
                    value: "3",
                    label: "不展示线索"
                },
                {
                    value: "4",
                    label: "未知线索"
                }
            ],
            yewuLX: [
                {
                    value: "0",
                    label: "求购"
                },
                {
                    value: "1",
                    label: "求租"
                },
                {
                    value: "2",
                    label: "出售"
                },
                {
                    value: "3",
                    label: "出租"
                },
                {
                    value: "4",
                    label: "其他"
                }
            ],
            xiansuoLY: [
                {
                    value: "0",
                    label: "店内资料上传"
                },
                {
                    value: "1",
                    label: "核销房源业主资料"
                },
                {
                    value: "2",
                    label: "二手成交房源"
                },
                {
                    value: "3",
                    label: "租赁成交房源"
                },
                {
                    value: "4",
                    label: "贷款计算器小程序"
                },
                {
                    value: "5",
                    label: "购房资质小程序"
                }
            ],
            yixiangQY: [
                {
                    value: "0",
                    label: "中山"
                },
                {
                    value: "1",
                    label: "庄河"
                },
                {
                    value: "2",
                    label: "开发区"
                },
                {
                    value: "3",
                    label: "旅顺口"
                },
                {
                    value: "4",
                    label: "普兰店"
                },
                {
                    value: "5",
                    label: "沙河口"
                },
                {
                    value: "6",
                    label: "瓦房店"
                },
                {
                    value: "7",
                    label: "甘井子"
                },
                {
                    value: "8",
                    label: "西岗"
                },
                {
                    value: "9",
                    label: "金州"
                },
                {
                    value: "10",
                    label: "长海"
                },
                {
                    value: "11",
                    label: "高新园区"
                }
            ],
            rules: {
                xiansuoMC: [
                    {
                        required: true,
                        message: "请输入线索名称",
                        trigger: "blur"
                    }
                ],
                xiansuoJB: [
                    {
                        required: true,
                        message: "请选择线索级别",
                        trigger: "change"
                    }
                ],
                yewuLX: [
                    {
                        required: true,
                        message: "请选择线索类型",
                        trigger: "change"
                    }
                ],
                xiansuoLY: [
                    {
                        required: true,
                        message: "请选择线索来源",
                        trigger: "change"
                    }
                ],
                yixiangQY: [
                    {
                        required: true,
                        message: "请选择意向区域",
                        trigger: "change"
                    }
                ]
            }
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "线索新增"
            this.form = {}
            // this.flag4 = false;
        } else if (this.num == 1) {
            this.name = "线索详情"
            this.flag = true
            // this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "线索修改"
            // this.getArticleDetail()
        }
    },
    methods: {
        async onSubmit(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        // fileGuid: this.form.fileGuid,
                        enabled: this.form.enabled
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.insertArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增文章信息成功")
                        this.$router.push({ path: "/GetArticleList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // async getArticleDetail() {
        //     await this.$http
        //         .get(this.$url.getArticleDetail(this.articleId))
        //         .then(res => {
        //             if (res.data.msgCode == 1) {
        //                 this.form = res.data.dtList[0]
        //                 //   if (res.data.dtList[0].diseaseIds != "") {
        //                 //     this.diseaseIds = res.data.dtList[0].diseaseIds.split(",");
        //                 //     console.log(this.diseaseIds);
        //                 //   }
        //                 var uuu = {
        //                     url:
        //                         URL +
        //                         "uploads/allFiles/" +
        //                         // "http://demo4.dongliankeji.com/uploads/allFiles/" +
        //                         this.form.firstPic
        //                 }
        //                 // this.picture.push(uuu);
        //                 if (
        //                     this.form.firstPic == "" ||
        //                     this.form.firstPic == null
        //                 ) {
        //                     this.picture = []
        //                     this.isMax = false
        //                 } else if (
        //                     this.form.firstPic != "" ||
        //                     this.form.firstPic != null
        //                 ) {
        //                     this.picture.push(uuu)
        //                     this.isMax = true
        //                 }
        //                 if (this.num == 1) {
        //                     if (
        //                         this.form.firstPic == "" ||
        //                         this.form.firstPic == null
        //                     ) {
        //                         this.isMax = true
        //                     }
        //                 }
        //             } else {
        //                 this.$message.success(res.message)
        //             }
        //         })
        // },
        cancel() {
            this.$router.push({ path: "/XianSuoList" })
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>