<template>
    <div>
        <el-dialog :visible.sync="loupanDialog" direction="rtl" width="50%" top="4%" class="dialogCss">
            <el-card>
                <el-row>
                    <el-col>
                        <p>
                            <span class="el-form-item__label" style="line-height: 40px;">房源名称：</span>
                            <el-input v-model="loupanDialogName" placeholder="请输入房源名称" style="width: 50%"></el-input>
                            <span style="float: right">
                                <el-button size="mini" type="primary" @click="SearchLoupan">查询</el-button>
                                <el-button size="mini" type="warning" @click="ResetLoupan">重置</el-button>
                            </span>
                        </p>
                    </el-col>
                </el-row>
            </el-card>
            <el-table ref="multipleTable" v-loading="loupanDialogLoading" :data="loupanDialogList" @row-click="loupanDialogDetails" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <!-- <el-table-column type="selection" width="40"></el-table-column> -->
                <el-table-column label="楼盘名称" prop="name"></el-table-column>
                <el-table-column label="区域" prop="address"></el-table-column>
                <el-table-column label="开发商" prop="name"></el-table-column>
                <el-table-column label="地址" prop="address"></el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-row>
                <el-pagination style="width:70%;float:right;" @size-change="loupanDialogSizeChange" @current-change="loupanDialogCurrentChange" :current-page="loupanDialogCurrentPage" :page-sizes="[5, 10, 20]" :page-size="loupanDialogPageSize" layout="total, sizes, prev, pager, next, jumper" :total="loupanDialogTotal"></el-pagination>
            </el-row>
        </el-dialog>

        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/YaoShiList' }">
                <el-button size="mini" class="el-button1" type="primary" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="楼盘:" label-width="20%" prop="loupanName">
                            <el-button size="mini" class="buttonInput button_tanchu" @click="loupanDialog = true" :disabled="flag" style="width: 60%;height: 30px;line-height:0px;text-align: left;">
                                <span v-if="loupanName=='请选择楼盘'" style="color:#c0c4cc">{{ loupanName }}</span>
                                <span v-if="loupanName!='请选择楼盘'">{{ loupanName }}</span>
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="委托类型:" label-width="20%" prop="weituoLeixing">
                            <el-radio-group v-model="form.weituoLeixing" :disabled="flag">
                                <el-radio :label="1">二手买卖</el-radio>
                                <el-radio :label="2">首租</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- 
                        <el-form-item label="联系方式:" label-width="20%" prop="weight">
                            <el-input :disabled="flag" oninput="value=value.replace(/[^\d]/g,'')" style="width: 60%" v-model="form.weight"></el-input>
                        </el-form-item> -->
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="yaos:" label-width="20%" prop="articleContent">
                            <el-select :disabled="flag" v-model="form.articleContent" placeholder="请选择职位" style="width: 60%">
                                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可用:" label-width="20%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 60%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <!-- prop="firstPic" -->
                        <el-form-item label-width="10%" label="标题图:">
                            <span style="color: #999">建议上传200:100格式的图片</span>
                            <!-- <el-input v-model="taskPic" style="width: 60%"></el-input> -->
                            <!-- 上传图片 -->
                            <el-upload :action="serverUrl" list-type="picture-card" :file-list="picture" :on-success="success" :headers="MyHeader" :disabled="flag" :on-preview="handlePictureCardPreview" :limit="1" :on-exceed="handleExceed" :class="{ disabled: isMax }" :on-change="change" :on-error="error" :before-remove="handleBeforeRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" v-if="logo" alt />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                    <el-button size="mini" @click="cancel" v-if="num != 1">取消</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            num: this.$route.query.num,
            serverUrl: URL + "api/management/FileUpload/UploadFiles",
            name: "",
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            form: {
                loupanName: "",
                weituoLeixing: "",
                firstPic: "",
                weight: "",
                enabled: ""
            },
            options1: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            loupanName: "请选择楼盘",
            loupanDialogName: "",
            loupanDialogLoading: false,
            loupanDialogList: [
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                },
                {
                    name: "万达",
                    address: "上海市普陀区金沙江路 1518 弄"
                }
            ],
            loupanDialog: false,
            loupanDialogTotal: 0,
            loupanDialogCurrentPage: 1,
            loupanDialogPageSize: 10,
            rules: {
                loupanName: [
                    { required: true, message: "请选择楼盘", trigger: "change" }
                ],
                weituoLeixing: [
                    {
                        required: true,
                        message: "请选择委托类型",
                        trigger: "change"
                    }
                ],
                weight: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur"
                    }
                ],
                firstPic: [
                    {
                        required: true,
                        message: "请上传文章图片",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "钥匙新增"
            this.form = {}
            // this.flag4 = false;
        } else if (this.num == 1) {
            this.name = "钥匙详情"
            this.flag = true
            this.isMax = true
            //  document.getElementsByClassName(".ql-toolbar").styte("none")
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "钥匙修改"
            this.getArticleDetail()
        }
    },
    methods: {
        loupanDialogDetails(row) {
            this.loupanName = row.name
            this.loupanDialog = false
        },
        loupanDialogSizeChange(newSize) {
            this.loupanDialogPageSize = newSize
            this.getOrganizationaList()
        },
        /* 监听页码值改变的事件 */
        loupanDialogCurrentChange(newPage) {
            this.loupanDialogCurrentPage = newPage
            this.getOrganizationaList()
        },
        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        // fileGuid: this.form.fileGuid,
                        enabled: this.form.enabled
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.insertArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增文章信息成功")
                        this.$router.push({ path: "/YaoShiList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleId: this.articleId,
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        enabled: this.form.enabled
                    }
                    const { data: res } = await this.$http.put(
                        this.$url.updateArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改文章信息成功")
                        this.$router.push({ path: "/YaoShiList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async getArticleDetail() {
            await this.$http
                .get(this.$url.getArticleDetail(this.articleId))
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        var uuu = {
                            url:
                                URL +
                                "uploads/allFiles/" +
                                // "http://demo4.dongliankeji.com/uploads/allFiles/" +
                                this.form.firstPic
                        }
                        // this.picture.push(uuu);
                        if (
                            this.form.firstPic == "" ||
                            this.form.firstPic == null
                        ) {
                            this.picture = []
                            this.isMax = false
                        } else if (
                            this.form.firstPic != "" ||
                            this.form.firstPic != null
                        ) {
                            this.picture.push(uuu)
                            this.isMax = true
                        }
                        if (this.num == 1) {
                            if (
                                this.form.firstPic == "" ||
                                this.form.firstPic == null
                            ) {
                                this.isMax = true
                            }
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        success(response) {
            this.form.firstPic = response.id
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleBeforeRemove() {},
        handleExceed() {
            this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        cancel() {
            this.$router.push({ path: "/YaoShiList" })
        },
        SearchLoupan() {},
        ResetLoupan() {}
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
</style>