<template>
    <div>
        <div>
            <!-- 面包屑导航区域 -->
            <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>关注房源</el-breadcrumb-item>
      </el-breadcrumb> -->
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <span class="title"> 线索来源 </span>
                    <div v-for="item in item1" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive1 === item.label }" @click="changeClass1(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title"> 线索级别 </span>

                    <div v-for="item in item2" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive2 === item.label }" @click="changeClass2(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <el-row>
                    <span class="title"> 意向区域 </span>

                    <div v-for="item in item3" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive3 === item.label }" @click="changeClass3(item.label)">
                        {{ item.label }}
                    </div>
                </el-row>
                <!-- <el-row>
          <el-col>
            <span>文章名称：</span>
            <el-input v-model="articleTitle" placeholder="请输入文章名" style="width: 20%"></el-input> -->
                <!-- <span style="margin-left: 15px;">发布日期：</span>
            <el-date-picker v-model="petBreedName"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="清选择发布日期"
                            style="width: 20%; text-align: left"
                            :picker-options="pickerOptions">
            </el-date-picker> -->
                <!-- <span style="margin-left: 15px;">是否可用：</span>
            <el-select
                       v-model="enabled"
                       placeholder="请选择是否可用"
                       style="width: 20%">
              <el-option
                         v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <span style="float: right;">
              <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetArticleList">查询</el-button>
              <el-button @click="cancelbreedList" type="warning">重置</el-button>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="background-color:rgb(18,104,192); border: none">新增</el-button> -->
                <el-row>
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                    </el-col>
                </el-row>
                <!-- <el-button size="mini"
                       @click="handleEdit"
                       type="primary"
                       icon="el-icon-edit"
                       style="background-color: rgb(14 153 219); border: none; margin-left: 10px">修改</el-button> -->
                <!-- <el-button size="mini"
                       @click="handleDelete1()"
                       type="primary"
                       icon="el-icon-delete"
                       style="background-color:rgb(215 163 16); border: none">删除</el-button>
          </el-col>
        </el-row> -->
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="客户名" prop="articleTitle">
                        <template slot-scope="scope">
                            <el-button @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.articleTitle }}</el-button>
                        </template>

                    </el-table-column>
                    <el-table-column label="楼盘" prop="enabled"></el-table-column>
                    <el-table-column label="看房过程/记录" prop="createTime"></el-table-column>
                    <el-table-column label="沟通结果" prop="enabled"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <!-- <router-link :to="{ path: '/UpdateStaff/' + drow }">
                <span class="el-icon-edit" @click="xiangqing(scope.$index, scope.row)" style="color: #1890ff;">修改</span>
              </router-link> -->

                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="chengjiao(scope.row)" type="text" size="mini" class="el-icon-check" style="color: #f6ba03;">成交</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [],
            articleTitle: "",
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            item1: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "0",
                    label: "400来电未回拨"
                },
                {
                    value: "1",
                    label: "维护不当掉共享"
                },
                {
                    value: "1",
                    label: "手动无效客户"
                },
                {
                    value: "1",
                    label: "经纪人职位变更"
                },
                {
                    value: "1",
                    label: "外网抓取线索"
                },
                {
                    value: "1",
                    label: "400未处理线索"
                }
            ],

            item2: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "0",
                    label: "A级(精确线索)"
                },
                {
                    value: "0",
                    label: "B级(优质线索)"
                },
                {
                    value: "1",
                    label: "C级(普通线索)"
                },
                {
                    value: "1",
                    label: "D级(未知线索)"
                }
            ],

            item3: [
                {
                    value: "0",
                    label: "不限"
                },
                {
                    value: "0",
                    label: "中山"
                },
                {
                    value: "1",
                    label: "庄河"
                },
                {
                    value: "1",
                    label: "开发区"
                },
                {
                    value: "1",
                    label: "旅顺口"
                },
                {
                    value: "1",
                    label: "普兰店"
                },
                {
                    value: "1",
                    label: "沙河口"
                },
                {
                    value: "1",
                    label: "瓦房店"
                },
                {
                    value: "1",
                    label: "甘井子"
                },
                {
                    value: "1",
                    label: "西岗"
                },
                {
                    value: "1",
                    label: "金州"
                },
                {
                    value: "1",
                    label: "长海"
                },
                {
                    value: "1",
                    label: "高新园区"
                }
            ],
            isActive1: "不限",
            isActive2: "不限",
            isActive3: "不限"
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
    },
    methods: {
        changeClass1(i) {
            this.isActive1 = i
        },

        changeClass2(i) {
            this.isActive2 = i
        },

        changeClass3(i) {
            this.isActive3 = i
        },
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "BaoXiaoJiLuDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            //  this.$router.push('/InsertPetBreed/1/' + row.petBreedId)
            this.$router.push({
                name: "BaoXiaoJiLuDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getArticleList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    } else if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.getArticleList()
        },
        async getList() {
            let articleTitle =
                this.articleTitle === "" ? `%20` : this.articleTitle
            let enabled = this.enabled === "" ? `%20` : this.enabled
            const { data: res } = await this.$http.get(
                this.$url.getArticleList(
                    articleTitle,
                    enabled,
                    this.currentPage,
                    this.pageSize
                )
            )
            if (res.msgCode) {
                this.operationList = res.dtList
                this.total = res.maxRecords
                //   for (var i = 0; i < res.dtList.length; i++) {
                //     if (res.dtList[i].enabled === '1') {
                //       this.operationList[i].enabled = '是'
                //     } else if (res.dtList[i].enabled === '2') {
                //       this.operationList[i].enabled = '否'
                //     }
                //   }
            } else {
                this.$message.success(res.message)
            }
        },
        chengjiao(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将提交审核, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {})
                .catch((err) => {})
        },
        chengjiao1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将提交审核, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                    })
                    .catch((err) => {})
            }
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.deleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.GetPetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].articleId)
                        }
                        const { data: res } = this.$http.delete(
                            this.$url.deleteArticle,
                            {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            }
                        )
                        if (res.data.msgCode == 1) {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            })
                            this.reload()
                        } else {
                            this.$message.success(res.message)
                        }
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "BaoXiaoJiLuDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
            // this.$router.push('/InsertPetBreed/2/' + this.drow)
        },
        handleEdit() {
            var aa = this.$refs.multipleTable.selection
            if (aa.length > 1) {
                this.$message({
                    message: "请选择一条数据!"
                })
            } else if (aa == "") {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
                this.$router.push({
                    name: "BaoXiaoJiLuDetail",
                    query: {
                        articleId: aa[0].articleId,
                        num: 2,
                        state: 2
                    }
                })
                // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
            }
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
