<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ name }}</span>
      <span style="float: right">
        <el-button
          size="mini"
          type="primary"
          :disabled="huxingDisabled"
          v-if="
            roleType == 6 &&
            num == 1 &&
            state == 1
          "
          @click="examine()"
          >审核通过</el-button
        >
        <el-button
          size="mini"
          style="margin-left: 10px"
          class="el-button1"
          type="primary"
          @click="goBack(0)"
          >返回</el-button
        >
      </span>
    </el-card>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      class="qiugou-tabs"
      style="
        height: 40px;
        position: relative;
        top: 0px;
        margin-top: 7px;
        background-color: #fff;
      "
    >
      <el-tab-pane name="LouPanHome" label="楼盘首页">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-position="right"
          label-width="20%"
        >
          <el-card v-if="num != 1">
            <h4 style="margin-top: 0px; margin-bottom: 0px">房源信息</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label="楼盘名称:"
                  label-width="20%"
                  prop="newHouseName"
                >
                  <el-input
                    placeholder="请输入楼盘名称"
                    v-model="ruleForm.newHouseName"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="价格:" label-width="20%" prop="price">
                  <el-input
                    onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                    oninput="if(value>999999999)value=999999999"
                    placeholder="请输入房源价格"
                    class="padding-r0"
                    type="number"
                    min="0"
                    v-model="ruleForm.price"
                    style="width: 60%"
                  ></el-input
                  ><span style="margin-left: 7px">元/平</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="开盘时间:"
                  prop="kaiPanShiJian"
                >
                  <el-date-picker
                    v-model="ruleForm.kaiPanShiJian"
                    align="right"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择开盘时间"
                    style="width: 60%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="20%" label="商圈:" prop="areaIds">
                  <el-cascader
                    v-model="ruleForm.areaIds"
                    :options="cascaderShangquanOptions"
                    @change="shangquanChange"
                    :props="shangquanProps"
                    :show-all-levels="false"
                    clearable
                    style="width: 60%"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="20%" label="面积:" prop="squareInfo">
                  <el-input
                    placeholder="请输入面积,(如37-48)"
                    class="padding-r0"
                    v-model="ruleForm.squareInfo"
                    style="width: 60%"
                  ></el-input
                  ><span style="margin-left: 7px">平</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="售卖类型:"
                  prop="shouMaiLeiXing"
                >
                  <el-select
                    v-model="ruleForm.shouMaiLeiXing"
                    placeholder="请选择售卖类型"
                    style="width: 60%; margin: 0 7px 0 0"
                  >
                    <el-option
                      v-for="item in shouMaiLeiXingOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="交易权属:"
                  label-width="20%"
                  prop="jiaoYiQuanShuId"
                >
                  <el-select
                    v-model="ruleForm.jiaoYiQuanShuId"
                    placeholder="请选择交易权属"
                    style="width: 60%; margin: 0 7px 0 0"
                  >
                    <el-option
                      v-for="item in jiaoyiquanshuOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label-width="10%"
                  label="详细地址:"
                  prop="newHouseAddress"
                >
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="ruleForm.newHouseAddress"
                    style="width: 82.2%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="楼盘优惠:"
                  prop="loupanyouhui"
                >
                  <el-input
                    type="textarea"
                    placeholder="请输入楼盘优惠信息"
                    v-model="ruleForm.loupanyouhui"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所有户型:" prop="huXingInfo">
                  <el-input
                    v-model="ruleForm.huXingInfo"
                    type="textarea"
                    style="width: 60%"
                    placeholder="请输入户型,如(1室,2室,3室...)"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="房屋用途:"
                  label-width="20%"
                  prop="fangWuYongTuId"
                >
                  <el-select
                    :disabled="flag"
                    v-model="ruleForm.fangWuYongTuId"
                    placeholder="请选择房屋用途"
                    style="width: 60%; margin: 0 7px 0 0"
                  >
                    <el-option
                      v-for="item in fangwuyongtuOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="代理方式:"
                  prop="daiLiFangShi"
                >
                  <el-input
                    type="textarea"
                    placeholder="请输入代理方式"
                    v-model="ruleForm.daiLiFangShi"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="上传房源图片"
                  label-width="10%"
                  prop="newHousePics"
                >
                  <el-upload
                    :file-list="dtNewHousePics"
                    :action="UploadPics"
                    :on-progress="fileChange"
                    :limit="20"
                    list-type="picture-card"
                    :on-success="houseSuccess"
                    accept="image/*"
                    :headers="MyHeader"
                    :on-preview="handlePictureCardPreview"
                    :on-error="houseError"
                    :on-remove="handleBeforeRemove"
                    style="width: 75.5%"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-if="num != 1">
            <h4 style="margin-top: 0px; margin-bottom: 0px">项目资料</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <el-row style="margin-top: 10px">
              <el-col class="newHouseDocs">
                <el-form-item
                  label-width="10%"
                  label="上传项目资料:"
                  prop="newHouseDocs"
                >
                  <el-upload
                    class="upload-demo"
                    :action="UploadFiles"
                    accept=".pdf,.PDF,.word,.docx,.xlsx"
                    :file-list="dtNewHouseDocs"
                    :on-success="successFile"
                    :headers="MyHeader"
                    :on-preview="handlePictureCardPreviewFile"
                    :limit="3"
                    :class="{ fileMargin: isHeight }"
                    :on-error="errorFile"
                    :before-remove="handleFileBeforeRemove"
                  >
                    <span
                      style="color: #1890ff; cursor: pointer"
                      v-show="ruleForm.newHouseDocs.length < 3"
                      >选择上传文件</span
                    >
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-card v-if="num != 1">
            <h4 style="margin-top: 0px; margin-bottom: 0px">基本信息</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="开发商:"
                  prop="kaifashang"
                >
                  <el-input
                    placeholder="请输入开发商"
                    v-model="ruleForm.kaifashang"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="参考均价:"
                  prop="cankaojunjia"
                >
                  <el-input
                    onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                    oninput="if(value>999999999)value=999999999"
                    placeholder="请输入参考均价"
                    class="padding-r0"
                    type="number"
                    min="0"
                    v-model="ruleForm.cankaojunjia"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="楼盘状态:"
                  prop="loupanzhaungtai"
                >
                  <el-input
                    placeholder="请输入楼盘状态"
                    v-model="ruleForm.loupanzhaungtai"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="交房时间:"
                  prop="jiaofangshijian"
                >
                  <el-date-picker
                    v-model="ruleForm.jiaofangshijian"
                    align="right"
                    type="datetime"
                    placeholder="选择交房时间"
                    style="width: 60%"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item
                label-width="10%"
                label="售楼处地址:"
                prop="shoulouchu"
              >
                <el-input
                  placeholder="请输入售楼处地址"
                  type="textarea"
                  v-model="ruleForm.shoulouchu"
                  style="width: 82.2%"
                ></el-input>
              </el-form-item>
            </el-row>
          </el-card>
          <el-card v-if="num != 1">
            <h4 style="margin-top: 0px; margin-bottom: 0px">规划信息</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="建筑结构:"
                  prop="jianzhujiegou"
                >
                  <el-select
                    v-model="ruleForm.jianzhujiegou"
                    style="width: 60%"
                    placeholder="请选择建筑结构"
                  >
                    <el-option
                      v-for="item in jianzhujiegouOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="产权年限:"
                  prop="chanquannianxian"
                >
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入产权年限"
                    class="padding-r0"
                    type="number"
                    min="0"
                    v-model="ruleForm.chanquannianxian"
                    style="width: 60%"
                  ></el-input
                  ><span style="margin-left: 7px">年</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="20%" label="容积率:" prop="rongjilv">
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入容积率(100%)"
                    v-model="ruleForm.rongjilv"
                    style="width: 60%"
                  ></el-input
                  ><span style="margin-left: 7px">%</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="20%" label="绿化率:" prop="lvhualv">
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入绿化率(100%)"
                    v-model="ruleForm.lvhualv"
                    style="width: 60%"
                  ></el-input
                  ><span style="margin-left: 7px">%</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="规划户数:"
                  prop="guihauhushu"
                >
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入规划户数"
                    v-model="ruleForm.guihauhushu"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="规划车位:"
                  prop="guihuachewei"
                >
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入规划车位"
                    v-model="ruleForm.guihuachewei"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-if="num != 1">
            <h4 style="margin-top: 0px; margin-bottom: 0px">配套信息</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="物业类型:"
                  prop="wuyeleixing"
                >
                  <el-input
                    placeholder="请输入物业类型"
                    v-model="ruleForm.wuyeleixing"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="物业公司:"
                  prop="wuyegongsi"
                >
                  <el-input
                    placeholder="请输入物业公司"
                    v-model="ruleForm.wuyegongsi"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item label-width="20%" label="物业费:" prop="wuyefei">
                  <el-input
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                    placeholder="请输入物业费"
                    class="padding-r0"
                    type="number"
                    min="0"
                    v-model="ruleForm.wuyefei"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="取暖方式:"
                  prop="qunuanfangshi"
                >
                  <el-input
                    placeholder="请输入取暖方式"
                    v-model="ruleForm.qunuanfangshi"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="水电燃气:"
                  prop="shuidianranqi"
                >
                  <el-input
                    placeholder="请输入水电燃气"
                    v-model="ruleForm.shuidianranqi"
                    style="width: 60%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-row style="margin: 20px" v-if="num != 1">
            <el-col>
              <el-form-item label-width="10%">
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="huxingDisabled"
                  @click="addNewHouse()"
                  >提交</el-button
                >
                <el-button size="mini" @click="goBack(1)">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-card v-if="num == 1" style="margin-top: -9px !important">
            <el-row style="padding: 20px 0">
              <el-col :span="8" style="margin: 0px 40px 0 0">
                <!-- <el-image style="width:100%;height:270px;margin:0 10px" v-for="url in ruleForm.huxingList" :key="url" :src="url" :preview-src-list="ruleForm.huxingList">
                        </el-image> -->
                <div style="position: relative">
                  <span @click="showPic" class="chakanMore"
                    >共{{ ruleForm.newHousePics.length }}张,点击查看更多></span
                  >
                  <img
                    :src="ruleForm.newHousePics[0].url"
                    style="width: 100%; height: 270px"
                    alt=""
                  />
                </div>
              </el-col>
              <el-col :span="15">
                <div>
                  <div style="margin-top: 0px" class="title">
                    {{ ruleForm.newHouseName }}-{{ ruleForm.fangWuYongTuName
                    }}<span class="contant">{{ ruleForm.price }}</span
                    ><span class="contant1">元/平</span>
                    <el-button
                      v-if="favourite != '1'"
                      style="float: right"
                      size="mini"
                      type="primary"
                      :disabled="huxingDisabled"
                      @click="guanzhu"
                      >关注楼盘</el-button
                    >
                  </div>

                  <hr
                    style="
                      height: 1px;
                      border: none;
                      backgroundcolor: rgb(241 241 241);
                      margin-top: 3px;
                      margin-bottom: 0px;
                    "
                  />
                  <el-row>
                    <el-col :span="6">
                      <span>楼盘号：{{ ruleForm.newHouseNo }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>项目地址：{{ ruleForm.newHouseAddress }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>楼盘优惠：{{ ruleForm.loupanyouhui }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span>开盘时间：{{ ruleForm.kaiPanShiJian }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      <span
                        >项目资料库：<span
                          style="cursor: pointer"
                          @click="showFiled"
                          >共{{
                            dtNewHouseDocs.length + dtNewHousePics.length
                          }}条资料</span
                        ></span
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="marginLeft">
                      <span class="marginRight tedianXinxi">{{
                        ruleForm.fangWuYongTuName
                      }}</span
                      ><span class="marginRight tedianXinxi">{{
                        ruleForm.daiLiFangShi
                      }}</span
                      ><span class="marginRight tedianXinxi">{{
                        ruleForm.isBenYueKaiPan
                      }}</span>
                      <span
                        style="float: right; cursor: pointer"
                        @click="showLouPan"
                        >查看更多楼盘信息></span
                      >
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-if="num != 0 && newHouseHuXing.length > 0">
            <h4 style="margin-top: 0px; margin-bottom: 0px">户型介绍</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            />
            <div
              v-for="(item, i) in newHouseHuXing"
              :key="i"
              style="margin: 10px 0 20px"
            >
              <el-card v-show="item.huXingType == ''">
                <el-col style="margin: 10px 0px 20px">
                  <el-image
                    style="
                      width: 100px;
                      height: 100px;
                      margin: 0 10px;
                      float: left;
                    "
                    :src="item.pics[0].url"
                    :preview-src-list="[item.pics[0].pics]"
                  ></el-image>
                  <div style="float: left; margin-left: 15px">
                    <p class="huxingContant" style="margin: 0; font-size: 18px">
                      {{ item.newHouseHuXingName
                      }}<el-tag
                        style="margin-left: 15px"
                        type="success"
                        size="mini"
                        >{{
                          item.isSelling == "0"
                            ? "即将开盘"
                            : item.isSelling == "1"
                            ? "在售"
                            : "售罄"
                        }}</el-tag
                      >
                    </p>
                    <p
                      class="huxingContant"
                      style="margin-top: 10px; font-size: 14px"
                    >
                      <span>{{ item.huXingId }}</span
                      ><span class="marginLeft">{{ item.square }}平米</span
                      ><span class="marginLeft">{{
                        item.orientationsName
                      }}</span>
                    </p>
                    <p class="huxingContant">
                      <span>{{ item.createrRemark }}</span>
                    </p>
                  </div>
                  <span style="color: red; font-size: 18px; margin-left: 15px"
                    >{{ item.huXingPrice }}元/平</span
                  >
                </el-col>
                <div
                  v-if="num == 2"
                  class="hetong-xinxi"
                  style="
                    right: 20px;
                    display: block;
                    margin-top: 20px;
                    position: absolute;
                  "
                >
                  <span
                    class="xinzengXuQiu"
                    v-if="item.huXingType == ''"
                    @click="updateXuqiu(item, 'delete', i)"
                    >删除</span
                  >
                  <span
                    class="xinzengXuQiu"
                    v-if="item.huXingType == ''"
                    @click="updateXuqiu(item, 'update', i)"
                    >修改</span
                  >
                </div>
              </el-card>

              <!-- <hr style="height:1px;border:none;backgroundColor:#ccc;margin-top:0px;margin-bottom:10px;" /> -->
              <el-card v-show="num == 2 && item.huXingType != ''">
                <el-form
                  :ref="'newHouseHuXingDetail' + [i]"
                  :model="newHouseHuXingDetail[i]"
                  :rules="huxingRules"
                  label-position="right"
                >
                  <el-row>
                    <el-col :span="12">
                      <el-form-item
                        label="上传户型图"
                        label-width="20%"
                        prop="pics"
                      >
                        <el-upload
                          :file-list="newHouseHuXingDetail[i].pics"
                          :action="UploadPics"
                          :on-progress="fileChange1"
                          :class="{
                            hide: newHouseHuXingDetail[i]
                              .hideHuXingDetailUpload,
                          }"
                          :limit="1"
                          list-type="picture-card"
                          :on-success="updateHuXingPicsSuccess.bind(item, i)"
                          accept="image/*"
                          :headers="MyHeader"
                          :on-preview="handlePictureCardPreview1"
                          :on-error="huxingError"
                          :on-remove="updateHuXingPics"
                          style="width: 75.5%"
                        >
                          <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible1">
                          <div
                            v-for="item in newHouseHuXingDetail[i].pics"
                            :key="item.pics"
                          >
                            <img width="100%" :src="item.pics" alt="" />
                          </div>
                        </el-dialog>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="户型名:"
                        prop="newHouseHuXingName"
                      >
                        <el-input
                          v-model="newHouseHuXingDetail[i].newHouseHuXingName"
                          placeholder="请输入户型名"
                          style="width: 60%"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="状态:"
                        prop="isSelling"
                      >
                        <el-select
                          v-model="newHouseHuXingDetail[i].isSelling"
                          placeholder="请选择状态"
                          style="width: 60%"
                        >
                          <el-option
                            v-for="item in stateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="价格:"
                        prop="huXingPrice"
                      >
                        <el-input
                          onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                          oninput="if(value>999999999)value=999999999"
                          v-model="newHouseHuXingDetail[i].huXingPrice"
                          class="padding-r0"
                          type="number"
                          min="0"
                          placeholder="请输入价格"
                          style="width: 60%"
                        ></el-input
                        ><span style="margin-left: 7px">元/平</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="面积:"
                        prop="square"
                      >
                        <!-- <p class="text-indent">甲方所售房屋（以下简称该房屋）位于大连市<input class="inputW_15 input_borderBottom_000" v-model="newHouseHuXingDetail[i].square" />的房屋，建筑面积约<input class="inputW_80px input_borderBottom_000" v-model="newHouseHuXingDetail[i].square" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" />平方米（房屋具体面积与地址以房屋所有权证/不动产权证上的记载为准）。
                                                    <el-input onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="newHouseHuXingDetail[i].square" class="padding-r0" type="number" min="0" placeholder="请输入面积" style="width: 60%"></el-input><span style="margin-left:7px;">平</span>
                                                </p> -->
                        <el-input
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                          v-model="newHouseHuXingDetail[i].square"
                          class="padding-r0"
                          type="number"
                          min="0"
                          placeholder="请输入面积"
                          style="width: 60%"
                        ></el-input
                        ><span style="margin-left: 7px">平</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="设施:"
                        prop="createrRemark"
                      >
                        <el-input
                          type="textarea"
                          v-model="newHouseHuXingDetail[i].createrRemark"
                          placeholder="请输入设施(以'/'分隔,如(明卫/落地窗/泳池...))"
                          style="width: 60%"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="margin-left12">
                      <el-form-item
                        label-width="20%"
                        label="户型:"
                        prop="huXingId"
                      >
                        <el-input
                          type="text"
                          v-model="newHouseHuXingDetail[i].huXingId"
                          placeholder="请输入户型(例：1-1-1-1)"
                          style="width: 60%"
                        ></el-input>

                        <!-- <el-select v-model="newHouseHuXingDetail[i].huXingId" placeholder="请选择" style="width: 60%;">
                                                    <el-option v-for="item in huxingOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                </el-select> -->
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label-width="20%"
                        label="朝向:"
                        prop="orientations"
                      >
                        <el-checkbox-group
                          v-model="newHouseHuXingDetail[i].orientations"
                          @change="
                            handleCheckedChaoxiang(
                              newHouseHuXingDetail[i].orientations
                            )
                          "
                        >
                          <el-checkbox
                            v-for="orientations in $commonJs.orientationOptions"
                            :label="orientations.label"
                            :key="orientations.value"
                            >{{ orientations.label }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label-width="10%">
                    <el-button
                      size="mini"
                      @click="updateXuqiu(item, 'cancel', i)"
                      >取消</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      :disabled="huxingDisabled"
                      @click="updateHuxing('newHouseHuXingDetail' + [i], i)"
                      >确定</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </el-card>
          <el-card v-if="num == 2">
            <h4 style="margin-top: 0px; margin-bottom: 0px">新建户型</h4>
            <hr
              style="
                height: 1px;
                border: none;
                backgroundcolor: #ccc;
                margin-top: 0px;
                margin-bottom: 10px;
              "
            />
            <el-form
              ref="ruleFormDetail"
              :model="ruleFormDetail[0]"
              :rules="huxingRules"
              label-position="right"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    label="上传户型图"
                    label-width="20%"
                    prop="pics"
                  >
                    <el-upload
                      :file-list="ruleFormDetail[0].picture1"
                      :action="UploadPics"
                      :on-progress="fileChange1"
                      :class="{ hide: hideUpload }"
                      :limit="1"
                      list-type="picture-card"
                      :on-success="huxingSuccess"
                      accept="image/*"
                      :headers="MyHeader"
                      :on-preview="handlePictureCardPreview1"
                      :on-error="huxingError"
                      :on-remove="handleBeforeRemove1"
                      style="width: 75.5%"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible1">
                      <img
                        width="100%"
                        :src="ruleFormDetail[0].dialogImageUrl1"
                        alt=""
                      />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="户型名:"
                    prop="newHouseHuXingName"
                  >
                    <el-input
                      v-model="ruleFormDetail[0].newHouseHuXingName"
                      placeholder="请输入户型名"
                      style="width: 60%"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="状态:"
                    prop="isSelling"
                  >
                    <el-select
                      v-show="ruleForm.shouMaiLeiXing == '即将开盘'"
                      v-model="ruleFormDetail[0].isSelling"
                      placeholder="请选择状态"
                      style="width: 60%"
                    >
                      <el-option label="即将开盘" key="0" value="0"></el-option>
                    </el-select>
                    <el-select
                      v-show="ruleForm.shouMaiLeiXing == '在售'"
                      v-model="ruleFormDetail[0].isSelling"
                      placeholder="请选择状态"
                      style="width: 60%"
                    >
                      <el-option label="在售" key="1" value="1"></el-option>
                      <el-option label="售罄" key="2" value="2"></el-option>
                    </el-select>
                    <el-select
                      v-show="ruleForm.shouMaiLeiXing == '售罄'"
                      v-model="ruleFormDetail[0].isSelling"
                      placeholder="请选择状态"
                      style="width: 60%"
                    >
                      <el-option label="售罄" key="3" value="3"></el-option>
                    </el-select>
                    <!-- <el-select v-model="ruleFormDetail[0].isSelling" placeholder="请选择状态" style="width: 60%">
                                            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select> -->
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="价格:"
                    prop="huXingPrice"
                  >
                    <el-input
                      onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                      oninput="if(value>999999999)value=999999999"
                      v-model="ruleFormDetail[0].huXingPrice"
                      class="padding-r0"
                      type="number"
                      min="0"
                      placeholder="请输入价格"
                      style="width: 60%"
                    ></el-input
                    ><span style="margin-left: 7px">元/平</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label-width="20%" label="面积:" prop="square">
                    <el-input
                      v-model="ruleFormDetail[0].square"
                      onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                      oninput="if(value>99999)value=99999"
                      class="padding-r0"
                      type="number"
                      min="0"
                      placeholder="请输入面积"
                      style="width: 60%"
                    ></el-input
                    ><span style="margin-left: 7px">平</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="设施:"
                    prop="createrRemark"
                  >
                    <el-input
                      type="textarea"
                      v-model="ruleFormDetail[0].createrRemark"
                      placeholder="请输入设施(以'/'分隔,如(明卫/落地窗/泳池...))"
                      style="width: 60%"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="margin-left12">
                  <el-form-item label-width="20%" label="户型:" prop="huXingId">
                    <el-input
                      type="text"
                      v-model="ruleFormDetail[0].huXingId"
                      placeholder="请输入户型(例：1-1-1-1)"
                      style="width: 60%"
                    ></el-input>
                    <!-- <el-select v-model="ruleFormDetail[0].huXingId" placeholder="请选择" style="width: 60%;">
                                            <el-option v-for="item in huxingOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select> -->
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label-width="20%"
                    label="朝向:"
                    prop="orientations"
                  >
                    <el-checkbox-group
                      v-model="ruleFormDetail[0].orientations"
                      @change="
                        handleCheckedChaoxiang(ruleFormDetail[0].orientations)
                      "
                    >
                      <el-checkbox
                        v-for="orientations in $commonJs.orientationOptions"
                        :label="orientations.label"
                        :key="orientations.value"
                        >{{ orientations.label }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label-width="10%">
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="huxingDisabled"
                  @click="addHuxing()"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </el-card>
        </el-form>
      </el-tab-pane>
      <el-tab-pane
        name="LouPanPic"
        label="楼盘相册"
        v-if="num == 1"
        v-loading="loading"
      >
        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">楼盘相册</span>
          <!-- <el-button @click="goBack" size="mini" type="primary" style="float: right">返回</el-button> -->
        </el-card>
        <el-card style="margin-top: 1px !important">
          <el-image
            style="width: 100px; height: 100px; margin: 0 10px"
            v-for="item in ruleForm.newHousePics"
            :key="item.fileId"
            :src="item.url"
            :preview-src-list="newHousePics"
          >
          </el-image>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="XiangMuZiLiao"
        label="项目资料"
        v-if="num == 1"
        v-loading="loading"
      >
        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">项目文档</span>
          <span style="margin-left: 10px; font-size: 12px"
            >(共{{ dtNewHouseDocs.length }}个)</span
          >
          <!-- <el-dropdown style="float: right;">
                        <span class="el-dropdown-link">
                            查看全部<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>Word</el-dropdown-item>
                            <el-dropdown-item>Xlsx</el-dropdown-item>
                            <el-dropdown-item>Pdf</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
        </el-card>
        <el-card style="margin-top: 1px !important; padding-bottom: 30px">
          <el-col
            :span="24"
            v-for="(item, i) in dtNewHouseDocs"
            :key="i"
            style="margin-top: 10px; border-bottom: 1px solid #eaedf1"
          >
            <el-col :span="2" style="text-align: left">
              <img
                v-if="item.originalFileName.split('.')[1] == 'pdf'"
                style="text-align: center"
                src="../../../public/img/pdf.png"
              />
              <img
                v-if="item.originalFileName.split('.')[1] == 'word'"
                style="text-align: center"
                src="../../../public/img/word.png"
              />
              <img
                v-if="item.originalFileName.split('.')[1] == 'xlsx'"
                style="text-align: center"
                src="../../../public/img/xlsx.png"
              />
            </el-col>
            <el-col :span="20">
              <div>{{ item.originalFileName }}</div>
              <div>{{ item.createTime }}</div>
            </el-col>
            <el-col :span="2" style="text-align: right; margin-top: 10px">
              <img
                @click="downloadNewHouseFile(item.url, item.name)"
                style="
                  margin-top: 10px;
                  text-align: center;
                  width: 25px;
                  cursor: pointer;
                "
                src="../../../public/img/download.png"
              />
            </el-col>
          </el-col>
        </el-card>

        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">项目图片</span>
          <span style="margin-left: 10px; font-size: 12px"
            >(共{{ dtNewHousePics.length }}个)</span
          >
        </el-card>
        <el-card style="margin-top: 1px !important; padding-bottom: 30px">
          <el-col
            :span="4"
            v-for="(item, i) in dtNewHousePics"
            :key="i"
            style="
              margin: 10px 30px 10px 0px;
              border-bottom: 1px solid #eaedf1;
              padding-bottom: 10px;
            "
          >
            <el-col :span="24" style="text-align: left">
              <img
                style="
                  margin-top: 10px;
                  text-align: center;
                  width: 230px;
                  height: 230px;
                "
                :src="item.url1"
              />
            </el-col>
            <el-col :span="24">
              <div style="font-size: 16px">{{ item.originalFileName }}</div>
              <img
                @click="downloadNewHouseFile1(item.url1)"
                style="
                  margin-top: 10px;
                  text-align: center;
                  width: 15px;
                  cursor: pointer;
                "
                src="../../../public/img/download.png"
              />
              <div>{{ item.createTime }}</div>
            </el-col>
          </el-col>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="XiangXiXinXi"
        label="详细信息"
        v-if="num == 1"
        v-loading="loading"
      >
        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">基本信息</span>
        </el-card>
        <el-card style="margin-top: 1px !important; padding: 10px 0px">
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">开发商：</span>
              <span class="labelValue">{{
                ruleForm.kaifashang == undefined ? "暂无" : ruleForm.kaifashang
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">参考均价：</span>
              <span class="labelValue">{{
                ruleForm.cankaojunjia == undefined
                  ? "暂无"
                  : ruleForm.cankaojunjia
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">楼盘状态:</span>
              <span class="labelValue">{{
                ruleForm.loupanzhaungtai == undefined
                  ? "暂无"
                  : ruleForm.loupanzhaungtai
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">交房时间：</span>
              <span class="labelValue">{{
                ruleForm.jiaofangshijian == undefined
                  ? "暂无"
                  : ruleForm.jiaofangshijian
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">开盘时间：</span>
              <span class="labelValue">{{ ruleForm.kaiPanShiJian }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">楼盘地址:</span>
              <span class="labelValue">{{ ruleForm.newHouseAddress }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">区域位置：</span>
              <span class="labelValue">{{ ruleForm.areaNames }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">售楼处地址:</span>
              <span class="labelValue">{{
                ruleForm.shoulouchu == undefined ? "暂无" : ruleForm.shoulouchu
              }}</span>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">规划信息</span>
        </el-card>
        <el-card style="margin-top: 1px !important; padding: 10px 0px">
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">建筑结果：</span>
              <span class="labelValue">{{
                ruleForm.jianzhujiegouName == undefined
                  ? "暂无"
                  : ruleForm.jianzhujiegouName
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">产权年限：</span>
              <span class="labelValue">{{
                ruleForm.chanquannianxian == undefined
                  ? "暂无"
                  : ruleForm.chanquannianxian
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">容积率:</span>
              <span class="labelValue">{{
                ruleForm.rongjilv == undefined ? "暂无" : ruleForm.rongjilv
              }}</span
              ><span style="margin-left: 7px">%</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">绿化率：</span>
              <span class="labelValue">{{
                ruleForm.lvhualv == undefined ? "暂无" : ruleForm.lvhualv
              }}</span
              ><span style="margin-left: 7px">%</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">规划户数：</span>
              <span class="labelValue">{{
                ruleForm.guihauhushu == undefined
                  ? "暂无"
                  : ruleForm.guihauhushu
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">规划车位:</span>
              <span class="labelValue">{{
                ruleForm.guihuachewei == undefined
                  ? "暂无"
                  : ruleForm.guihuachewei
              }}</span>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">配套信息</span>
        </el-card>
        <el-card style="margin-top: 1px !important; padding: 10px 0px">
          <el-row>
            <el-col :span="12">
              <span class="titleLabel">物业类型：</span>
              <span class="labelValue">{{
                ruleForm.wuyeleixing == undefined
                  ? "暂无"
                  : ruleForm.wuyeleixing
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">物业公司：</span>
              <span class="labelValue">{{
                ruleForm.wuyegongsi == undefined ? "暂无" : ruleForm.wuyegongsi
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">物业费:</span>
              <span class="labelValue">{{
                ruleForm.wuyefei == undefined ? "暂无" : ruleForm.wuyefei
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">供暖方式：</span>
              <span class="labelValue">{{
                ruleForm.qunuanfangshi == undefined
                  ? "暂无"
                  : ruleForm.qunuanfangshi
              }}</span>
            </el-col>
            <el-col :span="12">
              <span class="titleLabel">水电燃气：</span>
              <span class="labelValue">{{
                ruleForm.shuidianranqi == undefined
                  ? "暂无"
                  : ruleForm.shuidianranqi
              }}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        name="HuXingJieShao"
        label="户型介绍"
        v-if="num == 1"
        v-loading="loading"
      >
        <el-card class="intanle">
          <span style="font-weight: 600; color: #000">楼盘户型</span>
        </el-card>
        <el-card style="margin-top: 1px !important; padding: 10px 0px">
          <div style="width: 40%; float: left">
            <span style="width: 10%; float: left">类型：</span>
            <span
              :class="[changeHuXingNum == null ? 'active' : '']"
              style="width: 10%; float: left; cursor: pointer"
              @click="changeHuXing(null)"
              >全部({{ HuXingLXList.length }})</span
            >
            <span
              :class="[changeHuXingNum == item.num ? 'active' : '']"
              v-for="(item, i) in HuXingLX"
              :key="i"
              style="width: 10%; float: left; cursor: pointer"
              @click="changeHuXing(item.num)"
              >{{ item.num }}室({{ item.length }})</span
            >
          </div>
          <el-col style="margin-top: 10px">
            <div v-for="(item, i) in newHouseHuXing1" :key="i">
              <el-col
                style="
                  margin: 10px 0px;
                  border-bottom: 1px solid rgb(234, 237, 241);
                  padding-bottom: 20px;
                "
              >
                <el-image
                  style="
                    width: 100px;
                    height: 100px;
                    margin: 0 10px 0 0;
                    float: left;
                  "
                  v-for="(url, i) in item.pics"
                  :key="i"
                  :src="url.url"
                  :preview-src-list="[item.pics[0].pics]"
                ></el-image>

                <div style="float: left; margin-left: 15px">
                  <p class="huxingContant" style="margin: 0; font-size: 18px">
                    {{ item.newHouseHuXingName
                    }}<el-tag
                      style="margin-left: 15px"
                      type="success"
                      size="mini"
                      >{{
                        item.isSelling == "0"
                          ? "即将开盘"
                          : item.isSelling == "1"
                          ? "在售"
                          : "售罄"
                      }}</el-tag
                    >
                  </p>
                  <p
                    class="huxingContant"
                    style="margin-top: 10px; font-size: 14px"
                  >
                    <span>{{ item.huXingName }}</span
                    ><span class="marginLeft">{{ item.square }}平米</span
                    ><span class="marginLeft">{{ item.orientationsName }}</span>
                  </p>
                  <p class="huxingContant">
                    <span>{{ item.createrRemark }}</span>
                  </p>
                </div>
                <span
                  style="
                    float: right;
                    color: red;
                    font-size: 18px;
                    margin-right: 0px;
                  "
                  >{{ item.huXingPrice }}元/平</span
                >
              </el-col>
            </div>
          </el-col>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      UploadFiles: this.$url.UploadOthers,
      MyHeader: {
        currentRole: "",
        accessToken: "",
        fp: "",
      },
      newHouseHuXing: [],
      newHouseHuXingDetail: [],
      newHouseHuXing1: [],
      num: this.$route.query.num,
      state: this.$route.query.state,
      newHouseId: this.$route.query.newHouseId,
      houseId: this.$route.query.houseId,
      activeName: "LouPanHome",
      loading: false,
      picArray: [],
      dongtaiData: [1, 2, 3, 4, 5],
      isMax: false,
      userId: "",
      isHeight: "",
      jiaoyiquanshuOptions: [],
      ruleForm: {
        huXingId: "",
        jiaoYiQuanShuId: "",
        fangWuYongTuId: "",
        daiLiFangShi: "",
        squareInfo: "",
        areaIds: "",
        shouMaiLeiXing: "",
        rongjilv: "",
        lvhualv: "",
        guihauhushu: "",
        guihuachewei: "",
        wuyeleixing: "",
        wuyefei: "",
        qunuanfangshi: "",
        shuidianranqi: "",
        wuyegongsi: "",
        chanquannianxian: "",
        kaifashang: "",
        cankaojunjia: "",
        loupanzhaungtai: "",
        jiaofangshijian: "",
        jianzhujiegouName: "",
        jiaoYiQuanShuIdName: "",
        shoulouchu: "",
        xaingximiaoshu: "",
        jiaotongqingkuang: "",
        jiaoyuziyuan: "",
        yiliaopeitao: "",
        shenghuoyule: "",
        lvdigongyuan: "",
        huxingList: [],
        createrRemark: "用户点评",
        price: "",
        huXingInfo: "",
        newHouseName: "",
        jieshao: "",
        shangquan: [],
        newHouseAddress: "",
        loupanyouhui: "",
        kaiPanShiJian: "",
        fileid: "",
        tuipan: "",
        newHousePics: [
          {
            url: "",
          },
        ],
        newHouseDocs: [],
      },
      newHousePics: [],
      jianzhujiegouOptions: [],
      cascaderShangquanOptions: [
        {
          value: "",
          label: "",
          children: [{ value: "", label: "" }],
        },
      ],
      shouMaiLeiXingOptions: [
        {
          value: "1",
          label: "即将开盘",
        },
        {
          value: "2",
          label: "在售",
        },
        {
          value: "3",
          label: "售罄",
        },
      ],
      huxingRules: {
        pics: [
          {
            required: true,
            message: "请上传户型图",
            trigger: "change",
          },
        ],
        newHouseHuXingName: [
          {
            required: true,
            message: "请输入户型名",
            trigger: "blur",
          },
        ],
        isSelling: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        huXingPrice: [
          {
            required: true,
            message: "请输入价格",
            trigger: "blur",
          },
        ],
        square: [
          {
            required: true,
            message: "请输入面积",
            trigger: "blur",
          },
        ],
        orientations: [
          {
            required: true,
            message: "请选择朝向",
            trigger: "change",
          },
        ],
        createrRemark: [
          {
            required: true,
            message: "请输入设施",
            trigger: "blur",
          },
        ],
        huXingId: [
          {
            required: true,
            message: "请选择户型",
            trigger: "change",
          },
        ],
        newHouseDocs: [
          {
            required: true,
            message: "请上传项目资料",
            trigger: "change",
          },
        ],
      },
      shangquanProps: { multiple: true, checkStrictly: true },
      sheshiOptions: [
        {
          value: "0",
          label: "落地窗",
          // children: [{ value: "", label: "" }]
        },
        {
          value: "1",
          label: "观景台",
        },
      ],
      shangquanParams: [],
      shiKanPics: [],
      huxingOptions: [],
      name: "",
      UploadPics: this.$url.UploadNewhouses,
      xinfangPicture: [],
      picture1: [],
      disabled: false,
      disabled2: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleFile: false,
      dialogImageUrl: "",
      dialogVisibleResources: false,
      dialogImageUrlResources: "",
      dialogImageFileUrl: "",
      dongtaiData: [1, 2, 3, 4],
      xuqiuList: [
        {
          dialogImageUrl1: "",
          picture1: [],
          orientations: [],
          pics: [],
        },
      ],
      HuXingLX: [], //户型类型
      HuXingLXList: [],
      changeHuXingNum: null,
      ruleForm1: [],
      ruleFormDetail: [],
      formDetailIndex: 0,
      rules: {
        newHouseName: [
          {
            required: true,
            message: "请输入楼盘名称",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入房源价格",
            trigger: "blur",
          },
        ],
        kaiPanShiJian: [
          {
            required: true,
            message: "请输入开盘时间",
            trigger: "blur",
          },
        ],
        areaIds: [
          {
            required: true,
            message: "请选择商圈",
            trigger: "change",
          },
        ],
        squareInfo: [
          {
            required: true,
            message: "请输入面积",
            trigger: "change",
          },
        ],
        shouMaiLeiXing: [
          {
            required: true,
            message: "请输入售卖类型",
            trigger: "change",
          },
        ],
        jiaoYiQuanShuId: [
          {
            required: true,
            message: "请选择交易权属",
            trigger: "change",
          },
        ],
        newHouseAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        loupanyouhui: [
          {
            required: true,
            message: "请输入楼盘优惠",
            trigger: "blur",
          },
        ],
        huXingInfo: [
          {
            required: true,
            message: "请输入户型",
            trigger: "blur",
          },
        ],
        fangWuYongTuId: [
          {
            required: true,
            message: "请选择房屋用途",
            trigger: "change",
          },
        ],
        daiLiFangShi: [
          {
            required: true,
            message: "请输入代理方式",
            trigger: "blur",
          },
        ],
        newHousePics: [
          {
            required: true,
            message: "请上传房源图片",
            trigger: "blur",
          },
        ],
        newHouseDocs: [
          {
            required: true,
            message: "请上传项目资料",
            trigger: "blur",
          },
        ],
        kaifashang: [
          {
            required: true,
            message: "请输入开发商",
            trigger: "blur",
          },
        ],
        cankaojunjia: [
          {
            required: true,
            message: "请输入参考均价",
            trigger: "blur",
          },
        ],
        loupanzhaungtai: [
          {
            required: true,
            message: "请输入楼盘状态",
            trigger: "blur",
          },
        ],
        jiaofangshijian: [
          {
            required: true,
            message: "请输入交房时间",
            trigger: "blur",
          },
        ],
        shoulouchu: [
          {
            required: true,
            message: "请输入售楼处地址",
            trigger: "blur",
          },
        ],
        jianzhujiegou: [
          {
            required: true,
            message: "请选择建筑结构",
            trigger: "change",
          },
        ],
        chanquannianxian: [
          {
            required: true,
            message: "请输入产权年限",
            trigger: "blur",
          },
        ],
        rongjilv: [
          {
            required: true,
            message: "请输入容积率(%)",
            trigger: "blur",
          },
        ],
        lvhualv: [
          {
            required: true,
            message: "请输入绿化率(%)",
            trigger: "blur",
          },
        ],
        guihauhushu: [
          {
            required: true,
            message: "请输入规划户数",
            trigger: "blur",
          },
        ],
        guihuachewei: [
          {
            required: true,
            message: "请输入规划车位",
            trigger: "blur",
          },
        ],
        wuyeleixing: [
          {
            required: true,
            message: "请输入物业类型",
            trigger: "blur",
          },
        ],
        wuyegongsi: [
          {
            required: true,
            message: "请输入物业公司",
            trigger: "blur",
          },
        ],
        wuyefei: [
          {
            required: true,
            message: "请输入物业费",
            trigger: "blur",
          },
        ],
        qunuanfangshi: [
          {
            required: true,
            message: "请输入取暖方式",
            trigger: "blur",
          },
        ],
        shuidianranqi: [
          {
            required: true,
            message: "请输入水电燃气",
            trigger: "blur",
          },
        ],
      },
      huXingSelectOptions: [
        {
          value: "0",
          label: "A户型",
        },
        {
          value: "1",
          label: "B户型",
        },
        {
          value: "2",
          label: "C户型",
        },
        {
          value: "3",
          label: "D户型",
        },
      ],
      stateOptions: [
        {
          value: "0",
          label: "即将开盘",
        },
        {
          value: "1",
          label: "在售",
        },
        {
          value: "2",
          label: "售罄",
        },
      ],
      jiegouOptions: [
        {
          value: "0",
          label: "室卫厅",
        },
      ],
      orientations: ["东", "东南", "南", "西南", "西", "西北", "北", "东北"],
      shangquan1: [],
      dtNewHouseDocs: [],
      dtNewHousePics: [],
      huxingDisabled: false,
      fangwuyongtuOptions: [],
      flag: false,
      hideUpload: false,
      hideHuXingDetailUpload: false,
      Date: new Date(),
      HuxingIndex: 0,
      favourite: "",
      editableTabs: [],
    };
  },
  created() {
    this.editableTabs = window.localStorage.getItem("editableTabs");
    this.activeIndex = window.localStorage.getItem("activeIndex");
    if (this.editableTabs) {
      this.editableTabs = JSON.parse(this.editableTabs);
      this.editableTabs.forEach((item) => {
        if (this.activeIndex == item.path) {
          this.activeIndex = this.activeIndex;
          this.editableTabsValue = item.name.toString();
        }
      });
    }
    this.MyHeader.accessToken = localStorage.getItem("accessToken"); //获取accessToken
    this.MyHeader.fp = localStorage.getItem("fp"); //浏览器指纹fp
    this.roleType = window.localStorage.getItem("roleType");
    this.userId = window.localStorage.getItem("userId");

    if (this.roleType == undefined) {
      this.MyHeader.currentRole = localStorage.getItem("userType").charAt(0);
    } else {
      this.MyHeader.currentRole = localStorage.getItem("roleType");
    }
    this.ruleFormDetail = this.xuqiuList;

    this.getShuJuZiDianList();
  },
  methods: {
    handleClick(tab, event) {},
    huxingFormat(param) {
      let orientations = [0, 0, 0, 0, 0, 0, 0, 0];
      param.map((item, i) => {
        if (item == "东") {
          orientations[0] = 1;
        }
        if (item == "东南") {
          orientations[1] = 1;
        }
        if (item == "南") {
          orientations[2] = 1;
        }
        if (item == "西南") {
          orientations[3] = 1;
        }
        if (item == "西") {
          orientations[4] = 1;
        }
        if (item == "西北") {
          orientations[5] = 1;
        }
        if (item == "北") {
          orientations[6] = 1;
        }
        if (item == "东北") {
          orientations[7] = 1;
        }
      });
      return orientations;
    },
    updateHuxing(Form, i) {
      let that = this,
        ruleForm = "",
        pics = [],
        orientations = [];
      that.HuxingIndex = i;
      that.$refs[Form][0].validate(async (valid) => {
        if (valid) {
          ruleForm = JSON.parse(JSON.stringify(that.newHouseHuXingDetail));
          orientations = that.huxingFormat(ruleForm[i].orientations);

          ruleForm[i].pics.forEach((item) => {
            pics.push(item.name);
          });
          that.huxingDisabled = true;
          const data = {
            newHouseId: this.newHouseId,
            newHouseHuXingId: ruleForm[i].newHouseHuXingId,
            newHouseHuXingName: ruleForm[i].newHouseHuXingName,
            square: ruleForm[i].square,
            orientations: orientations.join(""),
            pics: pics.toString(),
            createrRemark: ruleForm[i].createrRemark,
            isSelling: ruleForm[i].isSelling,
            huXingId: ruleForm[i].huXingId,
            huXingPrice: ruleForm[i].huXingPrice,
          };
          this.$http
            .put(this.$url.UpdateNewHouseHuXing, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              if (res.data.msgCode == 1) {
                that.huxingDisabled = false;
                this.$message.success("户型修改成功!");
                this.$router.go(-1);
              }
            })
            .catch((res) => {
              that.huxingDisabled = false;
            });
        } else {
          return false;
        }
      });
    },
    addHuxing() {
      let that = this,
        orientations = [];
      that.$refs.ruleFormDetail.validate(async (valid) => {
        if (valid) {
          let ruleFormDetail = JSON.parse(JSON.stringify(this.ruleFormDetail));
          orientations = that.huxingFormat(ruleFormDetail[0].orientations);

          that.huxingDisabled = true;
          const data = {
            newHouseId: this.newHouseId,
            newHouseHuXingName: ruleFormDetail[0].newHouseHuXingName,
            square: ruleFormDetail[0].square,
            orientations: orientations.join(""),
            pics: ruleFormDetail[0].pics.toString(),
            createrRemark: ruleFormDetail[0].createrRemark,
            isSelling: ruleFormDetail[0].isSelling,
            huXingId: ruleFormDetail[0].huXingId,
            huXingPrice: ruleFormDetail[0].huXingPrice,
          };
          this.$http
            .post(this.$url.InsertNewHouseHuXing, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              if (res.data.msgCode == 1) {
                this.$message.success("户型新增成功!");
                this.$router.go(-1);
              }
            })
            .catch((res) => {
              that.huxingDisabled = false;
            });
        } else {
          return false;
        }
      });
    },
    GetNewHouseDetail() {
      let that = this,
        newHouseInfo = {};
      this.$http
        .get(this.$url.GetNewHouseDetail, {
          params: {
            newHouseId: that.newHouseId,
          },
        })
        .then((res) => {
          if (res.data.msgCode == 1) {
            // that.dialogImageUrl = res.data.dtNewHouseHuXing[0].pics
            let data = res.data.dtList[0];
            // let data3 = res.data.dtNewHouseHuXing[0]
            // that.dialogImageUrl = this.$url.newHousesPicSrc + data.newHousePics
            newHouseInfo = JSON.parse(data.newHouseInfo);
            newHouseInfo.newHouseName = data.newHouseName;
            newHouseInfo.price = data.price;
            newHouseInfo.newHouseName = data.newHouseName;
            newHouseInfo.kaiPanShiJian = data.kaiPanShiJian;
            newHouseInfo.areaIds = data.areaIds;
            newHouseInfo.squareInfo = data.squareInfo;
            newHouseInfo.shouMaiLeiXing = data.shouMaiLeiXing;
            newHouseInfo.jiaoYiQuanShuId = data.jiaoYiQuanShuId;
            newHouseInfo.newHouseAddress = data.newHouseAddress;
            newHouseInfo.huXingInfo = data.huXingInfo;
            newHouseInfo.daiLiFangShi = data.daiLiFangShi;
            newHouseInfo.newHouseNo = data.newHouseNo;
            that.ruleForm = newHouseInfo;
            let kaiPanShiJian = that.ruleForm.kaiPanShiJian.split("-")[1] * 1;
            if (kaiPanShiJian == that.Date.getMonth() + 1) {
              that.ruleForm.isBenYueKaiPan = "本月开盘"; //是否本月开盘
            } else {
              that.ruleForm.isBenYueKaiPan = kaiPanShiJian + "月开盘";
            }
            if (data.newHouseDocs == null) {
              that.ruleForm.newHouseDocs = [];
            } else {
              if (data.newHouseDocs.indexOf(",") > -1) {
                that.ruleForm.newHouseDocs = data.newHouseDocs.split(",");
              } else {
                that.ruleForm.newHouseDocs = [data.newHouseDocs];
              }
            }
            that.jianzhujiegouOptions.map((item) => {
              if (newHouseInfo.jianzhujiegou == item.value) {
                that.ruleForm.jianzhujiegouName = item.label;
              }
            });
            that.jiaoyiquanshuOptions.map((item) => {
              if (data.jiaoYiQuanShuId == item.value) {
                that.ruleForm.jiaoYiQuanShuIdName = item.label;
              }
            });
            let HuXingLX = [],
              obj = [];
            res.data.dtNewHouseHuXing.forEach((item) => {
              HuXingLX.push(item.huXingId.substr(0, 1));
              if (item.pics.indexOf(",") > -1) {
                item.pics = item.pics.split(",").map((pic) => {
                  pic.pic = that.$url.newHousesPicSrc + pic.fileId;
                  pic.url = that.$url.newHousesPicSrc + pic.fileId;
                  pic.name = pic.fileId;
                });
              } else {
                item.pics = [
                  {
                    pics: that.$url.newHousesPicSrc + item.pics,
                    url: that.$url.newHousesPicSrc + item.pics,
                    name: item.pics,
                  },
                ];
              }
              let orientations = item.orientations.split("");
              let orientation = [];
              orientations.map((item, i) => {
                if (i == 0 && item == "1") {
                  orientation.push("东");
                }
                if (i == 1 && item == "1") {
                  orientation.push("东南");
                }
                if (i == 2 && item == "1") {
                  orientation.push("南");
                }
                if (i == 3 && item == "1") {
                  orientation.push("西南");
                }
                if (i == 4 && item == "1") {
                  orientation.push("西");
                }
                if (i == 5 && item == "1") {
                  orientation.push("西北");
                }
                if (i == 6 && item == "1") {
                  orientation.push("北");
                }
                if (i == 7 && item == "1") {
                  orientation.push("东北");
                }
              });
              item.orientations = orientation;
              item.orientationsName = orientation.join(",");
              item.huXingType = "";
              that.newHouseHuXing.push(item);
            });
            that.newHouseHuXingDetail = JSON.parse(
              JSON.stringify(that.newHouseHuXing)
            );

            that.newHouseHuXing1 = that.newHouseHuXing;
            that.HuXingLXList = HuXingLX;
            Array.from(new Set(HuXingLX)).map((item) => {
              that.HuXingLX.push({ num: item });
            });
            for (let i = 0, len = HuXingLX.length; i < len; i++) {
              if (obj[HuXingLX[i]]) {
                obj[HuXingLX[i]]++;
              } else {
                obj[HuXingLX[i]] = 1;
              }
            }
            obj = Array.from(obj);
            for (let index = 0; index < obj.length; index++) {
              if (obj[index] == undefined) {
                obj.splice(index, 1);
                index = -1;
              }
            }
            obj.map((item, i) => {
              that.HuXingLX[i].length = item;
            });

            if (res.data.dtNewHouseDocs) {
              res.data.dtNewHouseDocs.map((item, i) => {
                item.url = that.$url.othersPicSrc + item.fileId;
                item.name = item.originalFileName;
              });
              that.dtNewHouseDocs = res.data.dtNewHouseDocs;
            }
            res.data.dtNewHousePics.forEach((item) => {
              that.dtNewHousePics.push({
                name: item.fileId,
                url: that.$url.newHousesPicSrc + item.fileId,
              });
            });
            // res.data.dtNewHousePics.map((item, i) => {
            //   item.url1 = that.$url.newHousesPicSrc + item.fileId;
            //   item.name = item.originalFileName;
            // });
            // that.dtNewHousePics = res.data.dtNewHousePics;
            that.ruleForm.newHousePics = that.dtNewHousePics;

            let areaIds = data.areaIds.split(",");
            that.ruleForm.areaIds = [];
            that.cascaderShangquanOptions.forEach(
              //意向商圈
              (cascaderShangquan, index) => {
                cascaderShangquan.children.forEach((children, x) => {
                  areaIds.forEach((areaId, y) => {
                    if (children.value === areaId) {
                      that.ruleForm.areaIds.push([
                        cascaderShangquan.label,
                        children.value,
                      ]);
                    }
                  });
                });
              }
            );
            for (let x = 0; x < that.fangwuyongtuOptions.length; x++) {
              const element = that.fangwuyongtuOptions[x];
              if (that.ruleForm.fangWuYongTuId == element.value) {
                that.ruleForm.fangWuYongTuName = element.label;
              }
            }
          }
        });
    },
    downloadNewHouseFile(url, name) {
      var fileName = name;
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // a.download = fileName
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
    },
    downloadNewHouseFile1(url1) {
      let a = document.createElement("a");
      a.href = url1;
      a.setAttribute("download", url1);
      a.click();

      //   var alink = document.createElement("a");
      //   alink.href = url1;
      //   alink.download = "pic"; //图片名
      //   alink.click();
    },
    updateXuqiu(item, type, i) {
      let that = this;
      if (type != "delete") {
        for (const element of that.newHouseHuXing) {
          if (item.newHouseHuXingId == element.newHouseHuXingId) {
            if (type == "update") {
              // console.log(that.newHouseHuXingDetail)
              element.huXingType = "update";
              // that.newHouseHuXingDetail = JSON.parse(
              //     JSON.stringify(that.newHouseHuXing)
              // )

              that.newHouseHuXingDetail.forEach(function (i) {
                if (i.pics.length != 0) {
                  i.hideHuXingDetailUpload = true;

                  console.log("w");
                }
              });

              console.log(
                " that.newHouseHuXingDetail",
                that.newHouseHuXingDetail
              );
            } else if (type == "cancel") {
              element.huXingType = "";
            }
          }
        }
      } else {
        this.$confirm("确认删除？")
          .then((_) => {
            that.loading = true;
            this.$http
              .delete(this.$url.DeleteNewHouseHuXing, {
                data: [item.newHouseHuXingId],
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              })
              .then((res) => {
                that.loading = false;
                if (res.data.msgCode == 1) {
                  that.$message.success("户型删除成功！");
                  that.newHouseHuXing.splice(i, 1);
                } else {
                  that.$message.error(res.data.message);
                }
              });
            done();
          })
          .catch((_) => {});
      }
    },
    changeHuXing(num) {
      let that = this;
      that.changeHuXingNum = num;
      if (num) {
        that.newHouseHuXing1 = [];
        that.newHouseHuXing.map((item) => {
          if (item.huXingId.substr(0, 1) == num) {
            that.newHouseHuXing1.push(item);
          }
        });
      } else {
        that.newHouseHuXing1 = that.newHouseHuXing;
      }
    },
    picFormat(data, param) {
      let that = this;
      if (data.indexOf(",") > -1) {
        that[param] = data.split(",").map((newHousePic) => {
          newHousePic = that.$url.newHousesPicSrc + newHousePic;
        });
      } else {
        that[param].push(that.$url.newHousesPicSrc + data);
      }
    },

    getShuJuZiDianList() {
      const that = this;
      that.$commonJs
        .getShuJuZiDianList(
          "HUXING,FANGWUYONGTU,SHANGQUAN,JIANZHUJIEGOU,JIAOYIQUANSHU"
        )
        .then((data) => {
          that.huxingOptions = data.HuXing;
          that.jianzhujiegouOptions = data.JianZhuJieGou;
          that.fangwuyongtuOptions = data.FANGWUYONGTU;
          that.jiaoyiquanshuOptions = data.JiaoYiQuanShu;
          if (that.num == 0) {
            that.name = "新房新增";
          } else if (that.num == 1) {
            that.name = "新房详情";
          } else if (that.num == 2) {
            that.name = "新房修改";
          }
          that.cascaderShangquanOptions = data.cascaderShangquan;
          if (that.num != 0) {
            that.GetNewHouseDetail();
          }
          let activeIndex = window.localStorage.getItem("activeIndex");
          that.$nextTick(() => {
            if (activeIndex == "XinFangList") {
              let checkboxList = [];
              checkboxList = document.querySelectorAll(
                ".el-cascader-menu:nth-child(1) .el-checkbox"
              );
              for (let index = 0; index < checkboxList.length; index++) {
                checkboxList[index].style.display = "none";
              }
            }
          });
        });
    },
    addNewHouse() {
      let that = this,
        url = "",
        method = "",
        fileId = [];
      that.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          that.huxingDisabled = true;
          let ruleFormDetail = JSON.parse(JSON.stringify(this.ruleFormDetail));
          this.orientations = [
            "东",
            "东南",
            "南",
            "西南",
            "西",
            "西北",
            "北",
            "东北",
          ];
          for (const item of ruleFormDetail) {
            item.orientations = item.orientations.map(Number);
            item.orientations.forEach((item) => {
              this.orientations[item] = 1;
            });
            item.orientations = this.orientations.join("");
          }
          that.shangquan1 = [];
          that.ruleForm.areaIds.forEach((item) => {
            that.shangquan1.push(item[1]);
          });
          that.ruleForm.newHousePics.forEach((item) => {
            fileId.push(item.fileId);
          });
          const newHouseInfo = {
            loupanyouhui: this.ruleForm.loupanyouhui,
            fangWuYongTuId: this.ruleForm.fangWuYongTuId,
            fileid: this.ruleForm.fileid,
            kaifashang: this.ruleForm.kaifashang,
            cankaojunjia: this.ruleForm.cankaojunjia,
            loupanzhaungtai: this.ruleForm.loupanzhaungtai,
            jiaofangshijian: that.$commonJs.dateTimeFormatter(
              this.ruleForm.jiaofangshijian
            ),
            shoulouchu: this.ruleForm.shoulouchu,
            jianzhujiegou: this.ruleForm.jianzhujiegou,
            chanquannianxian: this.ruleForm.chanquannianxian,
            rongjilv: this.ruleForm.rongjilv,
            lvhualv: this.ruleForm.lvhualv,
            guihauhushu: this.ruleForm.guihauhushu,
            guihuachewei: this.ruleForm.guihuachewei,
            wuyeleixing: this.ruleForm.wuyeleixing,
            wuyegongsi: this.ruleForm.wuyegongsi,
            wuyefei: this.ruleForm.wuyefei,
            qunuanfangshi: this.ruleForm.qunuanfangshi,
            shuidianranqi: this.ruleForm.shuidianranqi,
            tuipan: this.ruleForm.tuipan,
            xaingximiaoshu: this.ruleForm.xaingximiaoshu,
            jiaotongqingkuang: this.ruleForm.jiaotongqingkuang,
            jiaoyuziyuan: this.ruleForm.jiaoyuziyuan,
            yiliaopeitao: this.ruleForm.yiliaopeitao,
            shenghuoyule: this.ruleForm.shenghuoyule,
            lvdigongyuan: this.ruleForm.lvdigongyuan,
          };
          const data = {
            newHouseName: this.ruleForm.newHouseName,
            areaIds: this.shangquan1.join(","),
            price: this.ruleForm.price,
            shouMaiLeiXing: this.ruleForm.shouMaiLeiXing,
            newHouseAddress: this.ruleForm.newHouseAddress,
            daiLiFangShi: this.ruleForm.daiLiFangShi,
            jiaoYiQuanShuId: this.ruleForm.jiaoYiQuanShuId,
            squareInfo: this.ruleForm.squareInfo,
            huXingInfo: this.ruleForm.huXingInfo,
            // MaintenanceUserId: this.userId,
            kaiPanShiJian: that.$commonJs.dateTimeFormatter(
              this.ruleForm.kaiPanShiJian
            ),
            newHouseInfo: JSON.stringify(newHouseInfo),
            newHousePics: fileId.toString(),
            newHouseDocs: this.ruleForm.newHouseDocs.toString(),
            // picture1: ruleFormDetail
          };
          if (!this.newHouseId) {
            url = this.$url.InsertNewHouse;
            method = "post";
          } else {
            data.newHouseId = this.newHouseId;
            url = this.$url.UpdateNewHouse;
            method = "put";
          }
          this.$http[method](url, data, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
            .then((res) => {
              if (res.data.msgCode == 1) {
                console.log(res);
                that.huxingDisabled = false;
                this.$message.success("操作成功！");
                if (!this.newHouseId) {
                  this.$router.go(-1);
                }
              }
            })
            .catch((res) => {
              that.huxingDisabled = false;
            });
        } else {
          return false;
        }
      });
    },
    shangquanChange(value) {
      console.log(value);
      let that = this;
      if (value.length > 3) {
        that.$message.info("最多只能选三项");
        that.$nextTick(() => {
          that.ruleForm.shangquan = that.shangquan;
        });
      } else {
        value.forEach((item) => {
          that.shangquanParams.push(item[1]);
        });
        that.shangquan = value;
        that.shangquan1 = [];
        that.shangquan.forEach((i) => {
          that.shangquan1.push(i[1]);
        });
      }
    },
    fileChange() {
      this.disabled = true;
    },
    fileChange1() {
      this.disabled1 = true;
    },
    resourcesChange() {
      this.disabled2 = true;
    },
    houseSuccess(response) {
      let that = this;
      if (response.msgCode == "1") {
        this.disabled = false;
        this.ruleForm.newHousePics.push({
          fileId: response.id,
          url: that.$url.newHousesPicSrc + response.id,
          name: response.id,
          originalFileName: response.id,
        });
        setTimeout(function () {
          that.$refs.ruleForm.clearValidate(["newHousePics"]); // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
        }, 30);
        this.$message.success("上传图片成功");
      } else if (response.msgCode !== "1") {
        this.$message({
          type: "error",
          message: response.message,
        });
      }
    }, //图片上传删除
    handleBeforeRemove(file, fileList) {
      var mm = "";
      if (!file.response) {
        mm = file.fileId;
      } else {
        mm = file.response.id;
      }
      let ids = new Set(this.ruleForm.newHousePics);
      ids.delete(mm);
      for (let index = 0; index < this.ruleForm.newHousePics.length; index++) {
        const element = this.ruleForm.newHousePics[index];
        if (mm == element.fileId) {
          this.ruleForm.newHousePics.splice(index, 1);
          console.log(mm);
          if (file.response == undefined) {
            // this.$confirm(`确认移除 ？`).then(() => {
            const data1 = [mm, "newhouses"];
            const { data: res } = this.$http
              .delete(this.$url.DeleteOneFile, {
                data: data1,
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              })
              .then((res) => {
                if (res.data.msgCode == 1) {
                  console.log(res);
                }
              });
            // })
          }

          return false;
        }
      }

      if (this.ruleForm.newHousePics.length == 0) {
        this.rules.newHousePics[0].message = "请上传房源图片";
      }
    },
    huxingSuccess(response) {
      let that = this;
      if (response.msgCode == "1") {
        that.disabled = false;
        that.ruleFormDetail[0].pics.push(response.id);
        setTimeout(function () {
          that.$refs.ruleFormDetail.clearValidate(["pics"]); // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
        }, 30);
        that.$message.success("上传图片成功");
        if (that.ruleFormDetail[0].pics.length > 0) {
          this.hideUpload = true;
        }
      } else if (response.msgCode !== "1") {
        that.$message({
          type: "error",
          message: response.message,
        });
      }
    },
    //图片上传id
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardResources(file) {
      this.dialogImageUrlResources = file.url;
      this.dialogVisibleResources = true;
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    //图片上传失败
    houseError: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    resourcesError: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    huxingError: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    updateHuXingPics(file, fileList) {
      console.log(file);
      var fileName = file.name;
      var mm = "";

      if (!file.response) {
        mm = file.fileId;
      } else {
        mm = file.response.id;
      }
      console.log(mm);
      let ids = new Set(this.newHouseHuXingDetail[0].pics);

      ids.delete(mm);
      // this.$confirm(`确认移除 ？`).then(() => {
      this.$commonJs.deleteImg(file, "newhouses");
      // })
      this.newHouseHuXingDetail[0].pics = Array.from(ids);
      if (this.newHouseHuXingDetail[0].pics.length == 0) {
        this.huxingRules.pics[0].message = "请上传户型图";
        // this.hideHuXingDetailUpload = false
      }
      for (let index = 0; index < this.newHouseHuXingDetail.length; index++) {
        let item = this.newHouseHuXingDetail[index];
        if (item.pics.length != 0) {
          if (item.pics[0].name == fileName) {
            this.newHouseHuXingDetail[index].pics = [];
            item.hideHuXingDetailUpload = false;
            break;
          }
        }
      }
    },
    updateHuXingPicsSuccess(index, response) {
      let that = this;
      if (response.msgCode == "1") {
        that.disabled = false;
        console.log(that.newHouseHuXingDetail[index].pics, "上传前");
        that.newHouseHuXingDetail[index].pics.push({
          pic: that.$url.newHousesPicSrc + response.id,
          url: that.$url.newHousesPicSrc + response.id,
          name: response.id,
        });
        console.log(that.newHouseHuXingDetail[0].pics, "上传后");
        setTimeout(function () {
          that.$refs[
            "newHouseHuXingDetail" + that.HuxingIndex
          ][0].clearValidate(["pics"]); // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
        }, 30);
        that.$message.success("上传图片成功");

        that.newHouseHuXingDetail.forEach(function (i) {
          if (i.pics.length > 0) {
            i.hideHuXingDetailUpload = true;
          }
        });
      } else if (response.msgCode !== "1") {
        that.$message({
          type: "error",
          message: response.message,
        });
      }
    },

    handleBeforeRemove1(file, fileList) {
      var mm = "";
      if (!file.response) {
        mm = file.fileId;
      } else {
        mm = file.response.id;
      }
      let ids = new Set(this.ruleFormDetail[0].pics);
      ids.delete(mm);
      console.log(file);
      // this.$confirm(`确认移除 ？`).then(() => {
      this.$commonJs.deleteImg(file, "newhouses");
      // })
      this.ruleFormDetail[0].pics = Array.from(ids);
      if (this.ruleFormDetail[0].pics.length == 0) {
        this.huxingRules.pics[0].message = "请上传户型图";
        this.hideUpload = false;
      }
    },
    showPic() {
      this.activeName = "LouPanPic";
    },
    showFiled() {
      this.activeName = "XiangMuZiLiao";
    },
    showLouPan() {
      this.activeName = "XiangXiXinXi";
    },
    successFile(response) {
      let that = this;
      if (response.msgCode == "1") {
        this.disabled = false;
        this.ruleForm.newHouseDocs.push(response.id);
        setTimeout(function () {
          that.$refs.ruleForm.clearValidate(["newHouseDocs"]); // 消除校验,这里的setTimeOut不能去掉,去掉之后会不生效
        }, 30);
        this.$message.success("上传文件成功");
      } else if (response.msgCode !== "1") {
        this.$message({
          type: "error",
          message: response.message,
        });
      }
    },
    //图片上传删除
    handleFileBeforeRemove(file, fileList) {
      var mm = "";
      if (!file.response) {
        mm = file.fileId;
      } else {
        mm = file.response.id;
      }
      let ids = new Set(this.ruleForm.newHouseDocs);
      ids.delete(mm);
      console.log(file);
      this.ruleForm.newHouseDocs = Array.from(ids);
      if (this.ruleForm.newHouseDocs.length == 0) {
        this.rules.newHouseDocs[0].message = "请上传项目资料";
      }
      const data1 = [mm, "newhouses"];
      const { data: res } = this.$http
        .delete(this.$url.DeleteOneFile, {
          data: data1,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.data.msgCode == 1) {
            console.log(res);
          }
        });
    },
    handlePictureCardPreviewFile(file) {
      this.dialogImageFileUrl = file.url;
      this.dialogVisibleFile = true;
    },
    errorFile: function (err, file, fileList) {
      this.$message.error("上传失败");
    },
    examine() {
      //审核
      this.huxingDisabled = true;
      const data = {
        newHouseId: this.newHouseId,
      };
      this.$http
        .post(this.$url.ConfirmNewHouse, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.data.msgCode == 1) {
            this.huxingDisabled = false;
            this.$message.success("审核通过!");
            this.$router.go(-1);
          } else {
            this.huxingDisabled = false;
            this.$message.success("审核失败!");
          }
        })
        .catch((res) => {
          this.huxingDisabled = false;
          this.$message.success("审核失败!");
        });
    },
    handleCheckedChaoxiang(val, i) {},
    goBack(type) {
      if (type == 0 && this.num == 1) {
        this.addTab("新房", "XinFangList");
      } else {
        this.$router.go(-1);
      }
    },
    // 点击导航栏新增tab页
    addTab(targetName, path) {
      let flag = true; //判断是否需要新增页面
      var tabs = this.editableTabs; //活跃当前tab数组
      for (var i = 0; i < tabs.length; i++) {
        //如果存在相同的tab页 不新增tab页
        if (tabs[i].path === path) {
          this.editableTabsValue = tabs[i].name.toString(); //定位到已打开页面
          this.$router.push(path);
          flag = false;
        }
      }
      // 需要新增页面
      if (flag) {
        let newTabName = this.editableTabs.length.toString();
        this.editableTabs.push({
          title: targetName,
          name: newTabName,
          path: path,
        });
        this.editableTabsValue = newTabName;
        window.localStorage.setItem(
          "editableTabs",
          JSON.stringify(this.editableTabs)
        );
      }
      this.reload();
      window.localStorage.setItem("activeIndex", path);
    },
    guanzhu() {
      console.log(this.newHouseId);
      this.$confirm("此操作将关注该房源, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            this.$url.SubmitNewHouseFavorite,
            {
              NewHouseId: this.newHouseId,
            }
          );
          if (res.msgCode == 1) {
            this.$message.success("关注成功");
            this.favourite = "1";
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped lang="less">
.newHouseDocs /deep/ .el-upload-list {
  width: 30%;
}
.padding-r0 /deep/ input {
  padding-right: 0px !important;
}
/deep/ .el-carousel__indicator--horizontal {
  display: none !important;
}
/deep/ .el-carousel__container {
  position: relative;
  height: 230px !important;
}
.title {
  font-size: 26px;
}
.contant {
  color: red;
  margin-left: 20px;
  font-size: 40px;
}
.contant1 {
  font-size: 16px;
  color: #afafaf;
  margin-left: 10px;
}
.xinzengXuQiu {
  color: #df0202 !important;
  padding-right: 20px;
  border-radius: 3px;
  float: right;
  font-weight: 600;
  margin-top: 3px;
  font-size: 14px;
  cursor: pointer;
}
.huxingContant {
  height: 20px;
  line-height: 20px;
}
.icon {
  margin-top: 10px;
  text-align: center;
  vertical-align: text-bottom;
  width: 20px;
  margin-right: 5px;
}
.marginLeft {
  margin-left: 10px;
}
.marginRight {
  margin-right: 20px;
}
.active {
  color: #df0202;
}
.qiugou-tabs {
  font-size: 14px;
  /deep/ .el-card {
    line-height: 30px;
  }
  /deep/ .el-tabs__nav > .is-active {
    background: transparent !important;
    color: #df0202 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #df0202;
  }
  /deep/ .el-tabs__header {
    padding-left: 20px;
    margin: 0px;
  }
  /deep/ .el-tabs__active-bar,
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-card__body {
    margin: 5px 20px;
    padding: 0px !important;
  }
  /deep/ .el-tabs__item:hover {
    background-color: transparent;
  }
  /deep/ .el-card {
    border: none;
  }
  /deep/ .el-tabs__nav-wrap {
    position: static;
  }
  /deep/ .el-form-item__label {
    text-align: right;
    font-size: 14px;
    letter-spacing: 1px;
    width: 100px;
    line-height: 30px;
  }
  /deep/ .el-card,
  .el-message {
    border-radius: 0px;
  }
}

.hide /deep/.el-upload--picture-card {
  display: none !important;
}
.chakanMore {
  position: absolute;
  bottom: 10px;
  right: 0px;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: right;
  z-index: 1111;
  cursor: pointer;
  background-color: rgba(102, 100, 100, 0.7);
}
.tedianXinxi {
  background: #f1f1f1;
  padding: 1px 7px;
  border-radius: 3px;
  font-size: 10px;
  color: #909090;
}
</style>