<template>
    <div>
        <!-- 筛选 -->
        <el-card class="search">
            <el-row class="searchrow">
                <el-col :span="6">
                    <span class="titleLabel">合同编号：</span>
                    <el-input v-model="search.ContractNo" placeholder="请输入合同编号" style="width: 70%"></el-input>
                </el-col>
                <el-col :span="6">
                    <span class="titleLabel">房源编号：</span>
                    <el-input v-model="search.HouseNo" placeholder="请输入合同编号" style="width: 70%"></el-input>
                </el-col>
                <el-col :span="6">
                    <span class="titleLabel">产品类型：</span>
                    <el-select v-model="search.TradeType" placeholder="请选择产品类型" class="media-width">
                        <el-option v-for="item in jiaoyiOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <span class="titleLabel">创建时间：</span>
                    <!-- <el-date-picker class="media-width" v-model="createTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker> -->
                    <el-date-picker class="media-width" style="height: 30px;" v-model="createTime"
                        format="yyyy - MM - dd" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false"></el-date-picker>

                </el-col>
            </el-row>
            <el-row class="searchrow">

                <el-col :span="24">
                    <span style="float: right">
                        <el-button size="mini" style="margin-left: 20px" type="primary" @click="cgetChengJiaoJiLu">查询
                        </el-button>
                        <el-button size="mini" @click="cancelbreedList" type="warning">重置</el-button>
                    </span>
                </el-col>
            </el-row>
        </el-card>
        <!-- 交易记录列表 -->
        <el-card>
            <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark"
                :header-cell-style="{
                    'text-align': 'center',
                    'color': '#333',
                    'font-size': '14px',
                }" :cell-style="{ 'text-align': 'center' }" border stripe>
                <el-table-column label="合同编号" prop="contractNo"></el-table-column>
                <el-table-column label="金额" prop="contractPrice">
                    <template slot-scope="scope">
                        <span v-if="scope.row.tradeType == '1'">{{ scope.row.contractPrice }}元</span>
                        <span v-else>{{ scope.row.contractPrice }}元/月</span>
                    </template>
                </el-table-column>
                <el-table-column label="产品类型" prop="tradeType">
                    <template slot-scope="scope">
                        <span v-if="scope.row.tradeType == '1'">买卖</span>
                        <span v-else>租赁</span>
                    </template>
                </el-table-column>
                <el-table-column label="甲方" prop="buyerName"></el-table-column>
                <el-table-column label="乙方" prop="sellerName"></el-table-column>
                <el-table-column label="经纪人" prop="createPersonName"></el-table-column>
                <el-table-column label="门店" prop="shopName"></el-table-column>
                <el-table-column label="楼盘名称" prop="louPanName" min-width="150"></el-table-column>
                <el-table-column label="房源编号" prop="houseNo" width="200">
                    <template slot-scope="scope">
                        <span @click="jumpToDetail(scope.row)">{{ scope.row.houseNo }}</span>
                    </template>
                    <!-- <template slot-scope="scope">
                        <div style="display: flex;">
                            <el-button style="width: 92%;" @click="jumpToDetail(scope.row)" type="text" size="mini">
                                <div class="tableButSpan" style="font-size:14px;color:#000;text-align:center; display:inline-block;">
                                    <span>{{scope.row.houseNo}}</span>
                                </div>
                            </el-button>
                        </div>
                    </template> -->
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime" min-width="120"></el-table-column>
                <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                    <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                    <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                </div>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            editableTabs: [],
            drow: "",
            loading: false,
            roleType: "",
            // 分页开始
            currentPage: 1,
            pageSize: 10,
            total: 0,
            search: {
                contractNo: "",
                houseNo: "",
                tradeType: "", //交易类型 (买卖或者租赁)
                createTimeFrom: "",
                createTimeTo: "",
                currentPage: 1,
                pageSize: 10
            },
            date: ["", ""],
            createTime: ["", ""],
            jiaoyiOptions: [
                {
                    value: "",
                    label: "全部"
                },
                {
                    value: "1",
                    label: "买卖"
                },
                {
                    value: "0",
                    label: "租赁"
                }
            ],
            operationList: []
        }
    },
    created() {
        // 获取角色
        var role = ""
        role = window.localStorage.getItem("roleType")
        const createTimeFrom = new Date()
        const createTimeTo = new Date()
        createTimeFrom.setTime(createTimeFrom.getTime() - 3600 * 1000 * 24 * 90)
        this.date[0] = this.$commonJs
            .dateTimeFormatter(createTimeFrom)
            .split("T")[0]
        this.date[1] = this.$commonJs
            .dateTimeFormatter(createTimeTo)
            .split("T")[0]
        this.createTime = this.date
        this.search.createTimeFrom = this.createTime[0]
        this.search.createTimeTo = this.createTime[1] + " 23:59:59"
        this.editableTabs = window.localStorage.getItem("editableTabs")
        // this.activeIndex = window.localStorage.getItem("activeIndex");
        if (this.editableTabs) {
            this.editableTabs = JSON.parse(this.editableTabs)
            this.editableTabs.forEach((item) => {
                if (this.activeIndex == item.path) {
                    this.activeIndex = this.activeIndex
                    this.editableTabsValue = item.name.toString()
                }
            })
        }
        this.getChengJiaoJiLu()
    },
    watch: {
        createTime(minDate) {
            if (minDate != null) {
                this.search.createTimeFrom = minDate[0]
                this.search.createTimeTo = minDate[1] + " 23:59:59"
            } else {
                this.search.createTimeFrom = ""
                this.search.createTimeTo = ""
            }
        }
    },
    methods: {
        //跳转房源详情
        jumpToDetail(row) {
            var that = this
            if (row.tradeType == "0") {
                that.addTab(
                    row.louPanName,
                    "ChuZuDetail?louPanId=" +
                    row.louPanId +
                    "&houseId=" +
                    row.houseId +
                    "&canMerchantEdit=" +
                    row.canMerchantEdit +
                    "&canShopEdit=" +
                    row.canShopEdit +
                    "&num=1&state=2&=isConfirmed=" +
                    row.isConfirmed
                )
                this.$router.push({
                    name: "ChuZuDetail",
                    query: {
                        // currentPage1:this.currentPage,
                        louPanId: row.louPanId,
                        enabled: row.enabled,
                        houseId: row.houseId,
                        canMerchantEdit: row.canMerchantEdit,
                        canShopEdit: row.canShopEdit,
                        num: 1,
                        state: 2,
                        isConfirmed: row.isConfirmed,
                        louCeng: row.louCengDuan,
                        Ftype: "jiaoyi"
                    }
                })
            } else {
                this.addTab(
                    row.louPanName,
                    "ChuShouDetail?louPanId=" +
                    row.louPanId +
                    "&houseId=" +
                    row.houseId +
                    "&canMerchantEdit=" +
                    row.canMerchantEdit +
                    "&canShopEdit=" +
                    row.canShopEdit +
                    "&num=1&state=2&=isConfirmed=" +
                    row.isConfirmed +
                    "&louCeng=" +
                    row.louCengDuan
                )
                var JiShou
                if (row.isOnUrgentSale == "1") {
                    this.JiShou = true
                } else {
                    this.JiShou = false
                }
                this.$router.push({
                    name: "ChuShouDetail",
                    query: {
                        // currentPage1:this.currentPage,
                        isOnUrgentSale: this.JiShou,
                        louPanId: row.louPanId,
                        enabled: row.enabled,
                        houseId: row.houseId,
                        canMerchantEdit: row.canMerchantEdit,
                        canShopEdit: row.canShopEdit,
                        num: 1,
                        state: 2,
                        isConfirmed: row.isConfirmed,
                        louCeng: row.louCengDuan,
                        Ftype: "jiaoyi"
                    }
                })
            }
        },
        // 点击导航栏新增tab页
        addTab(targetName, path) {
            let flag = true //判断是否需要新增页面
            var tabs = this.editableTabs //活跃当前tab数组
            for (var i = 0; i < tabs.length; i++) {
                //如果存在相同的tab页 不新增tab页
                if (tabs[i].path === path) {
                    this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
                    this.$router.push(path)
                    flag = false
                }
            }
            // 需要新增页面
            if (flag) {
                let newTabName = this.editableTabs.length.toString()
                this.editableTabs.push({
                    title: targetName,
                    name: newTabName,
                    path: path
                })
                this.editableTabsValue = newTabName
                window.localStorage.setItem(
                    "editableTabs",
                    JSON.stringify(this.editableTabs)
                )
            }
            this.reload()
            window.localStorage.setItem("activeIndex", path)
        },
        // 查询
        cgetChengJiaoJiLu() {
            this.getChengJiaoJiLu()
        },
        /* 获取交易记录列表 */
        async getChengJiaoJiLu() {
            this.loading = true
            await this.$http
                .get(this.$url.GetChengJiaoJiLu, {
                    params: this.search
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        if (res.data.dtList.length > 0) {
                            this.operationList = res.data.dtList
                        } else {
                            this.operationList = []
                        }
                        this.loading = false
                        this.total = res.data.maxRecords
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.search.pageSize = newSize
            this.getChengJiaoJiLu()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.search.currentPage = newPage
            this.getChengJiaoJiLu()
        },
        //重置
        cancelbreedList() {
            var that = this
                ; (that.search = {
                    contractNo: "",
                    houseNo: "",
                    tradeType: "", //交易类型 (买卖或者租赁)
                    createTimeFrom: "",
                    createTimeTo: "",
                    currentPage: 1,
                    pageSize: 10
                }),
                    (this.createTime = this.date)
            this.search.createTimeFrom = this.date[0]
            this.search.createTimeTo = this.date[1] + " 23:59:59"
            that.getChengJiaoJiLu()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.deleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.GetPetList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err
                    })
                })
        },
        //跳转修改页面
        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "JiaoYiJiLuDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.search {
    .title {
        width: 100px;
        display: inline-block;
        text-align: right;
        margin-right: 20px;
    }

    .media-width {
        height: 30px;
    }

    .searchrow {
        margin: 8px 0;

        .srgjz {
            .el-input__inner {
                border-radius: 4px 0 0 4px;
            }
        }
    }
}
</style>
<style lang="less">
.search {
    .srgjz {
        .el-select {
            .el-input__inner {
                border-radius: 4px 0 0 4px !important;
                border-left: 1px solid #dcdfe6 !important;
                border-right: 1px solid #dcdfe6 !important;
            }
        }

        .el-input {
            .el-input__inner {
                border-radius: 0px;
                border-left: none;
                border-right: none;
            }
        }

        .el-button {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
        line-height: 22px;
    }

    .el-range-editor .el-range-input {
        line-height: 28px;
        height: 28px !important;
    }

    .el-select .el-input.is-focus .el-input__inner,
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border: 1px solid #dcdfe6;
    }
}
</style>
