import { render, staticRenderFns } from "./TongXunLuDetail.vue?vue&type=template&id=7ff81e32&scoped=true"
import script from "./TongXunLuDetail.vue?vue&type=script&lang=js"
export * from "./TongXunLuDetail.vue?vue&type=script&lang=js"
import style0 from "./TongXunLuDetail.vue?vue&type=style&index=0&id=7ff81e32&prod&scoped=true&lang=css"
import style1 from "./TongXunLuDetail.vue?vue&type=style&index=1&id=7ff81e32&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff81e32",
  null
  
)

export default component.exports