<template>
  <div>
    <el-card v-if="page != 2" class="intanle">
      <span class="h1">{{ name }}</span>
      <el-button
        size="mini"
        @click="goBack"
        class="el-button1"
        type="primary"
        style="float: right"
        >返回</el-button
      >
    </el-card>
    <el-card v-show="page == 1" class="qianyue" :loading="loading">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="right"
        label-width="20%"
      >
        <el-row>
          <!-- <el-col :span="12">
                        <el-form-item label="合同类型" label-width="20%" prop="contractType">
                            <el-select v-model="form.contractType" placeholder="请选择合同类型" style="width: 60%">
                                <el-option v-for="item in ContractTypeOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
          <el-col :span="12">
            <el-form-item label="交易类型" label-width="20%">
              <!-- <el-select v-model="form.tradeType" placeholder="请选择签约类型" style="width: 100%">
                                <el-option v-for="item in jiaoyiOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                            </el-select> -->
              <el-input
                size="mini"
                v-model="form.tradeTypeName"
                :disabled="true"
                class="buttonInput button_tanchu"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约类型" label-width="20%">
              <el-select
                v-model="form.hetongTypeName"
                placeholder="请选择签约类型"
                style="width: 60%"
                @change="hetongTab"
              >
                <el-option
                  v-for="item in hetongOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
              <!-- <el-input size="mini" v-model="form.tradeTypeName" :disabled="true" class="buttonInput button_tanchu" style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                ">
                            </el-input> -->
            </el-form-item>
          </el-col>
        </el-row>

        <!--平台管理员 -->
        <!-- <el-row>
          <el-col :span="12" v-if="roleType == 5 || roleType == 6">
            <el-form-item label="店铺" label-width="20%" prop="shopId">
              <el-button
                size="mini"
                class="buttonInput button_tanchu"
                @click="openDianPuDialog"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span
                  v-if="form.shopName == '请选择店铺'"
                  style="color: #c0c4cc"
                  >{{ form.shopName }}</span
                >
                <span v-if="form.shopName != '请选择店铺'">{{
                  form.shopName
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="roleType == 6">
            <el-form-item label="商户" label-width="20%" prop="merchantId">
              <el-button
                size="mini"
                class="buttonInput button_tanchu"
                @click="openShangHuDialog"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span
                  v-if="form.merchantName == '请选择商户'"
                  style="color: #c0c4cc"
                  >{{ form.merchantName }}</span
                >
                <span v-if="form.merchantName != '请选择商户'">{{
                  form.merchantName
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-row>
          <el-col :span="12">
            <el-form-item
              label="产权地址"
              label-width="20%"
              prop="contractHouseAddress"
            >
              <el-input
                size="mini"
                v-model="form.contractHouseAddress"
                placeholder="请输入产权地址"
                class="buttonInput button_tanchu"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约房源" label-width="20%" prop="houseId">
              <el-button
                size="mini"
                class="buttonInput button_tanchu"
                @click="openFangyuanDialog"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span
                  v-if="form.louPanName == '请选择签约房源'"
                  style="color: #c0c4cc"
                  >{{ form.louPanName }}</span
                >
                <span v-if="form.louPanName != '请选择签约房源'">{{
                  form.louPanName
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="签约客源" label-width="20%" prop="customerId">
              <el-button
                size="mini"
                class="buttonInput button_tanchu"
                @click="openKeyuanDialog"
                style="
                  width: 60%;
                  height: 22px;
                  line-height: 0px;
                  text-align: left;
                "
              >
                <span
                  v-if="form.keHuXingMing == '请选择签约客源'"
                  style="color: #c0c4cc"
                  >{{ form.keHuXingMing }}</span
                >
                <span v-if="form.keHuXingMing != '请选择签约客源'">{{
                  form.keHuXingMing
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="roleType == 5 || roleType == 6">
          <el-col :span="12">
            <el-form-item label="签约经纪人" label-width="20%" prop="userId">
              <el-button
                size="mini"
                @click="openJingJiRenDialog"
                style="
                  width: 60%;
                  height: 30px;
                  vertical-align: middle;
                  text-align: left;
                "
              >
                <span
                  v-if="contractUserName == '请选择签约经纪人'"
                  style="color: #c0c4cc"
                  >{{ contractUserName }}</span
                >
                <span v-if="contractUserName != '请选择签约经纪人'">{{
                  contractUserName
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label-width="10%">
          <el-button
            size="mini"
            type="primary"
            :disabled="disabled"
            @click="onSubmit('form')"
            >保存</el-button
          >
          <el-button
            size="mini"
            type="warning"
            v-if="contractId"
            @click="onSetp(2)"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>

    <!--word模板 房源 -->
    <ZuLinTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '0' && form.tradeType == '0' && page == 2 && templateVersion == 1"
    ></ZuLinTemplate>
    <!-- 租赁合同2.0版本 -->
    <NewZuLinTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '0' && form.tradeType == '0' && page == 2 && templateVersion == 2"
    ></NewZuLinTemplate>
    <MaiMaiTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '0' && form.tradeType == '1' && page == 2 && templateVersion == 1"
    ></MaiMaiTemplate>
    <!-- 房屋买卖合同 2.0 版本 -->
    <NewMaiMaiTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '0' && form.tradeType == '1' && page == 2 && templateVersion == 2"
    ></NewMaiMaiTemplate>

    <!--word模板 车位 -->
    <CheWeiZuLinTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '1' && form.tradeType == '0' && page == 2"
    ></CheWeiZuLinTemplate>
    <CheWeiMaiMaiTemplate
      :formData="this.formData"
      :contractId="contractId"
      :state="state"
      :contractStatus="this.contractStatus"
      :form1="this.form1"
      @onSetp="onSetp"
      v-if="form.hetongType == '1' && form.tradeType == '1' && page == 2"
    ></CheWeiMaiMaiTemplate>
    <!-- 合同附件 -->
    <Contract
      :formData="this.formData"
      @Previousstep="Previousstep"
      v-if="page == 3"
    ></Contract>

    <!-- 店铺 -->
    <el-dialog
      title="店铺"
      :visible.sync="dianpuDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <DianPuList
        :isQianyueOpen="true"
        @colseDianPuDialog="colseDianPuDialog"
      ></DianPuList>
    </el-dialog>

    <!-- 商户 -->
    <el-dialog
      title="商户"
      :visible.sync="shanghuDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <ShangHuGuanLiList
        :isQianyueOpen="true"
        @colseShangHuDialog="colseShangHuDialog"
      ></ShangHuGuanLiList>
    </el-dialog>

    <!-- 签约房源 -->
    <el-dialog
      title="签约房源"
      :visible.sync="fangyuanDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <el-tabs
        v-model="fangyuanTabPane"
        @tab-click="handleClickTab"
        class="qiugou-tabs"
        style="
          position: relative;
          top: 0px;
          margin-bottom: 20px;
          background-color: #fff;
        "
      >
        <el-tab-pane name="出售" label="出售"> </el-tab-pane>
        <el-tab-pane name="出租" label="出租"> </el-tab-pane>
      </el-tabs>
      <ChuShouList
        v-if="form.tradeType == '1'"
        :isKeyuanDaikan="true"
        @colseFangyuanDialog="colseFangyuanDialog"
      ></ChuShouList>
      <ChuZuList
        v-if="form.tradeType == '0'"
        :isKeyuanDaikan="true"
        @colseFangyuanDialog="colseFangyuanDialog"
      ></ChuZuList>
    </el-dialog>

    <!-- 签约房源 -->
    <el-dialog
      title="签约客源"
      :visible.sync="keyuanDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <KeYuanList
        :fangyuanState="form.tradeType"
        :isGenJin="true"
        @colseKeyuanDialog="colseKeyuanDialog"
      ></KeYuanList>
    </el-dialog>

    <!-- 店铺 -->
    <el-dialog
      title="店铺"
      :visible.sync="jingJiRenDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <TongXunLuList
        :isQianyueOpen="true"
        @colseJingJiRenDialog="colseJingJiRenDialog"
      ></TongXunLuList>
    </el-dialog>
  </div>
</template>
<script>
import DianPuList from "../../components/DianPu/DianPuList.vue";
import ShangHuGuanLiList from "../../components/ShangHuGuanLi/ShangHuGuanLiList.vue";
import ChuShouList from "../../components/ChuShou/ChuShouList.vue";
import ChuZuList from "../../components/ChuZu/ChuZuList.vue";
import KeYuanList from "../../components/KeYuan/KeYuanList.vue";
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue";
import ZuLinTemplate from "../../components/QianYue/WordTemplate/ZuLinTemplate.vue";
import MaiMaiTemplate from "../../components/QianYue/WordTemplate/MaiMaiTemplate.vue";
import CheWeiZuLinTemplate from "../../components/QianYue/WordTemplate/CheWeiZuLinTemplate.vue";
import CheWeiMaiMaiTemplate from "../../components/QianYue/WordTemplate/CheWeiMaiMaiTemplate.vue";
import Contract from "../../components/QianYue/Contract.vue";

import NewMaiMaiTemplate from '../../components/QianYue/WordTemplate/NewMaiMaiTemplate.vue'
import NewZuLinTemplate from '../../components/QianYue/WordTemplate/NewZuLinTemplate.vue'

import QianYueJs from "./QianYue.js";
import { Table } from "element-ui";

export default {
  inject: ["reload"],
  components: {
    DianPuList,
    ShangHuGuanLiList,
    ChuShouList,
    ChuZuList,
    KeYuanList,
    TongXunLuList,
    ZuLinTemplate,
    MaiMaiTemplate,
    CheWeiZuLinTemplate,
    CheWeiMaiMaiTemplate,
    Contract,
    NewMaiMaiTemplate,
    NewZuLinTemplate
  },
  data() {
    return {
      templateVersion: '',
      roleType: localStorage.getItem("roleType"),
      userId: localStorage.getItem("userId"),
      state: this.$route.query.state,
      contractId: this.$route.query.contractId,
      name: "",
      MyHeader: { accessToken: "", fp: "" },
      loading: false,
      dianpuDialog: false,
      shanghuDialog: false,
      fangyuanTabPane: "出售",
      fangyuanTab: "出售",
      fangyuanDialog: false,
      keyuanDialog: false,
      jingJiRenDialog: false,
      contractUserName: "请选择签约经纪人",
      disabled: false,
      contractStatus: "",
      page: 0, //页数
      form1: {},
      form: {
        // contractType: "",
        shopId: "",
        shopName: "请选择店铺",
        merchantId: "",
        merchantName: "请选择商户",
        customerId: "",
        keHuXingMing: "请选择签约客源",
        houseId: "",
        louPanName: "请选择签约房源",
        tradeType: "1", //1买卖0租赁
        tradeTypeName: "", //1买卖0租赁
        hetongType: "0", //0房源1车位
        hetongTypeName: "房源",
        contractHouseAddress: "", //产权地址
        userId: "", //经纪人Id
        newContractNo: "" //合同编号
      },
      hetongOptions: [
        {
          label: "房源",
          value: "房源"
        },
        {
          label: "车位",
          value: "车位"
        }
      ],
      fangwuyongtuOptions: [],
      formData: {
        contractNo: ""
      },
      rules: {
        contractHouseAddress: [
          {
            required: true,
            message: "请输入产权地址",
            trigger: "blur"
          }
        ],
        shopId: [
          {
            required: true,
            message: "请选择店铺",
            trigger: "blur"
          }
        ],
        merchantId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur"
          }
        ],
        // contractType: [
        //     {
        //         required: true,
        //         message: "请选择合同类型",
        //         trigger: "change"
        //     }
        // ],
        houseId: [
          {
            required: true,
            message: "请选择签约房源",
            trigger: "blur"
          }
        ],
        customerId: [
          {
            required: true,
            message: "请选择签约客源",
            trigger: "blur"
          }
        ],
        userId: [
          {
            required: true,
            message: "请选择签约经纪人",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.MyHeader.accessToken = localStorage.getItem("accessToken");
    this.MyHeader.fp = localStorage.getItem("fp");
    // 0 新增 1 详情 2 修改
    if (this.state == 0) {
      this.page = 1;
      this.name = "签约(合同)新增";
    } else if (this.state == 1) {
      this.name = "签约(合同)详情";
    } else if (this.state == 2) {
      // this.name = "签约(合同)修改";
      this.getContractDetail();
    }
  },
  methods: {
    onSetp(type) {
      this.page = type;
    },
    Previousstep(type) {
      this.page = type;
    },
    // 房源详情
    GetHouseDetail() {
      var that = this;
      this.$http.get(this.$url.GetHouseDetail, {
          params: {
            houseId: this.form.houseId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            console.log("!", that.formData);
            that.loading = false;
            const data = res.data.dtList[0];
            that.formData = Object.assign(that.form, data);
            for (let i = 0; i < that.fangwuyongtuOptions.length; i++) {
              if (that.fangwuyongtuOptions[i].value == data.fangWuYongTuId) {
                that.formData.fangWuYongTu = that.fangwuyongtuOptions[i].label;
              }
            }
            for (let i = 0; i < that.jiaoyiquanshuOptions.length; i++) {
              if (that.jiaoyiquanshuOptions[i].value == data.jiaoYiQuanShuId) {
                that.formData.quanShu = that.jiaoyiquanshuOptions[i].label;
              }
            }
            console.log("!", that.formData);
            console.log("@", that.formData.userId);
            that.page = 2;
          }
        });
    },
    // 查询合同详情
    async getContractDetail() {
      let that = this;
      that.loading = true;
      await that.$http.get(that.$url.GetContractDetail, {
          params: {
            contractId: that.contractId //客源id
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            if (res.data.dtList.length > 0) {
              that.templateVersion = res.data.dtList[0].contractVersion
              that.form = Object.assign(that.form, res.data.dtList[0]);
              that.contractStatus = that.form.contractStatus;
              if (that.form.tradeType == "1") {
                that.form.tradeTypeName = "买卖";
              } else {
                that.form.tradeTypeName = "租赁";
              }
              that.form.hetongType = that.form.type;
              if (res.data.dtList[0].contractInfo) {
                that.form1 = JSON.parse(res.data.dtList[0].contractInfo);
                // } else {
                //   that.form1= {}
              }
              that.formData.userId = res.data.dtList[0].userId;
            }
            that.getShuJuZiDianList();
            that.loading = false;
          } else {
            that.loading = false;
            that.$message.error(res.data.message);
          }
        })
        .catch(res => {
          this.$message.error("请求错误");
        });
    },
    getShuJuZiDianList() {
      const that = this;
      that.$commonJs
        .getShuJuZiDianList("FANGWUYONGTU,JIAOYIQUANSHU")
        .then(data => {
          this.fangwuyongtuOptions = data.FANGWUYONGTU;
          this.jiaoyiquanshuOptions = data.JiaoYiQuanShu;
          this.GetHouseDetail();
        });
    },

    async onSubmit(ruleForm) {
      let that = this,
        param = {},
        url = "",
        methods = "";
      that.$refs[ruleForm].validate(async valid => {
        if (valid) {
          that.loading = true;
          that.disabled = true;
          let param = {
            userId: that.userId,
            contractHouseAddress: that.form.contractHouseAddress,
            // shopId: that.form.shopId,
            // merchantId: that.form.merchantId,
            houseId: that.form.houseId,
            customerId: that.form.customerId,
            tradeType: that.form.tradeType,
            type: that.form.hetongType
          };
          // if (this.roleType != 1) {
          //   param.userId = that.form.userId;
          // }
          if (that.state == 0) {
            url = that.$url.InsertContract;
            methods = "post";
          } else if (that.state == 2) {
            param.contractId = that.contractId;
            url = that.$url.UpdateContract;
            methods = "put";
          }
          that.$http[methods](url, param, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          })
            .then(res => {
              that.loading = false;
              that.disabled = false;
              if (res.data.msgCode == 1) {
                that.formData.contractNo = res.data.newContractNo;
                that.contractId = res.data.newContractId;
                that.getContractDetail();
                that.$message.success("签约基本信息保存成功！");
                if (!that.contractId) {
                  this.$router.go(-1);
                }
              } else {
                that.$message.error(res.data.message);
              }
            })
            .catch(res => {
              that.loading = false;
              that.disabled = false;
              this.$message.error("请求错误");
            });
        }
      });
    },
    openDianPuDialog() {
      this.dianpuDialog = true;
    },
    colseDianPuDialog(dianPuData) {
      this.form.shopId = dianPuData.shopId;
      this.form.shopName = dianPuData.shopName;
      this.dianpuDialog = false;
    },

    openShangHuDialog() {
      this.shanghuDialog = true;
    },
    colseShangHuDialog(shanghuData) {
      this.form.merchantId = shanghuData.merchantId;
      this.form.merchantName = shanghuData.merchantName;
      this.shanghuDialog = false;
    },

    openFangyuanDialog() {
      this.fangyuanDialog = true;
    },
    colseFangyuanDialog(fangyuanData) {
      this.form.houseId = fangyuanData.houseId;
      this.form.louPanName = fangyuanData.louPanName;
      if (this.form.tradeType == "1") {
        this.form.tradeTypeName = "买卖";
      } else {
        this.form.tradeTypeName = "租赁";
      }
      this.fangyuanDialog = false;
    },

    openKeyuanDialog() {
      this.keyuanDialog = true;
    },
    colseKeyuanDialog(keyuanData) {
      this.form.customerId = keyuanData.customerId;
      this.form.keHuXingMing = keyuanData.customerName;
      this.keyuanDialog = false;
    },
    openJingJiRenDialog() {
      this.jingJiRenDialog = true;
    },
    colseJingJiRenDialog(jingJiRenData) {
      this.contractUserName = jingJiRenData.userName;
      this.form.userId = jingJiRenData.userId;
      this.jingJiRenDialog = false;
    },
    handleClickTab(tab, event) {
      if (this.fangyuanTab != tab.label) {
        this.form.customerId = "";
        this.form.keHuXingMing = "";
        this.form.houseId = "";
        this.form.louPanName = "";
      }
      this.fangyuanTab = tab.label;
      if (tab.label == "出售") {
        this.form.tradeType = "1";
        this.form.tradeTypeName = "买卖";
      } else if (tab.label == "出租") {
        this.form.tradeType = "0";
        this.form.tradeTypeName = "租赁";
      }
    },
    hetongTab(tab) {
      if (tab == "房源") {
        this.form.hetongType = "0";
      } else if (tab == "车位") {
        this.form.hetongType = "1";
      }
      console.log(this.form.hetongType);
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .el-card {
  margin-top: 15px;
}
.xuqiuDialog /deep/ .el-dialog__body {
  padding: 10px 20px;
}
.xuqiuDialog /deep/ .el-cascader__tags {
  right: 25px !important;
}
.xuqiuDialog /deep/ .el-tag--small {
  padding: 0 5px !important;
}
.xuqiuDialog /deep/ .el-tag {
  margin: 2px 0 2px 3px !important;
}
.qiugou-tabs {
  font-size: 14px;
  /deep/ .el-card {
    line-height: 30px;
  }
  /deep/ .el-tabs__nav > .is-active {
    background: transparent !important;
    color: #df0202 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #df0202;
  }
  /deep/ .el-tabs__header {
    margin: 0px;
  }
  /deep/ .el-tabs__active-bar,
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }
  /deep/ .el-card__body {
    margin: 5px 25px;
  }
  /deep/ .el-tabs__item:hover {
    background-color: transparent;
  }
  /deep/ .el-card {
    border: none;
  }
  /deep/ .el-tabs__nav-wrap {
    position: static;
  }
  /deep/ .el-form-item__label {
    text-align: right;
    font-size: 14px;
    letter-spacing: 1px;
    width: 100px;
    line-height: 30px;
  }
}
</style>
