<template>
  <div class="qianyue">
    <el-card class="intanle">
      <span v-if="state == '2'" class="h1">签约(合同)修改</span>
      <span v-if="state == '0'" class="h1">签约(合同)新增</span>
      <el-button style="float: right" size="mini" @click="goBack" class="el-button" type="primary">返回</el-button>
      <el-button v-if="contractStatus == '10'" style="float: right" class="el-button1" type="warning" size="mini" @click="draft()">保存草稿</el-button>
      <!-- <el-button v-if="contractStatus=='10'&&roleType==1" style="float: right" class="el-button1" type="warning" size="mini" @click="apply()">提交申请</el-button> -->
      <el-button v-if="contractStatus == '10' && roleType != 1" style="float: right" class="el-button1" type="warning" size="mini" @click="applyPass()">审核通过</el-button>
      <!-- <el-button v-if="contractStatus=='20'" style="float: right" class="el-button1" type="warning" size="mini" @click="onSave(1)">保存合同</el-button> -->
      <el-button v-if="contractStatus == '20'" style="float: right" class="el-button1" type="warning" size="mini" @click="save()">保存合同</el-button>
      <el-button v-if="contractStatus == '30'" style="float: right" class="el-button1" size="mini" type="warning" @click="Submit()">电子签章</el-button>
      <el-button v-if="roleType == 2 || roleType == 5 || roleType == 6" style="float: right" class="el-button1" size="mini" type="warning" @click="onSave(2)">下载合同</el-button>
    </el-card>
    <el-backtop></el-backtop>
    <!-- <div id="printTest"> -->
    <el-card class="body">
      <div class="header">
        <img src="../../../../public/img/logo4.jpg" />
      </div>
      <h3 class="text_align_r"><span>合同编号：</span><input :disabled="flag" class="inputW_150 input_borderBottom_ccc" v-model="form.contractNo" /></h3>

      <div class="quote_info clearfix font_weight500">
        <h2 class="h2_title margin_top_50">车位／车库转让居间合同</h2>
        <h3 class="font_weight500">转让方（以下简称“甲方”）：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaName" /></h3>
        <h3 class="font_weight500">地址：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.jiaAddress" /></h3>
        <h3 class="font_weight500">受让方（以下简称“乙方”）：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiName" /></h3>
        <h3 class="font_weight500">地址：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.yiAddress" /></h3>
        <h3 class="font_weight500">丙方（居间方）：<input :disabled="flag" class="inputW_23 input_borderBottom_000" v-model="form.companyName" /> 联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.companyMobile" /></h3>

        <h3 class="font_weight500">
          根据《中华人民共和国合同法》、《物权法》及其他相关法律、法规规定，甲、乙双方在真实、自愿、协商一致的基础上，就甲方向乙方转让
          <input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquName" />
          小区编号<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquNo" />
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="1">地上</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="2">地下</el-radio>
          </span>
          停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          相关事项，达成如下协议：
        </h3>
        <h3 class="font_weight500">
          为了保障交易资金安全，避免在交易过程中出现资金风险，导致您产生经济损失，现中介方温馨提示如下：
        </h3>

        <!-- 第一条 -->
        <h3>一、标的</h3>
        <h3 class="font_weight500">
          本次转让 小区编号<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquNo" />
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="1">地上</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="2">地下</el-radio>
          </span>
          停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 。甲方保证对以上停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 享有完全的处分权利，甲方确保上述停车<input
            :disabled="flag"
            class="inputW_50px input_borderBottom_000"
            v-model="form.parkingLot"
          />
          产权清晰，没有经济和民事纠纷。
        </h3>

        <h3 class="font_weight500">
          甲方同意将上述停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 所有权转让给乙方，乙方享有与“<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquName" /> 小区”甲方原购车<input
            :disabled="flag"
            class="inputW_50px input_borderBottom_000"
            v-model="form.parkingLot"
          />
          合同规定同等年限的停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          所有权；若国家法律政策对
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="1">地上</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="2">地下</el-radio>
          </span>
          使用权年限作出具体规定，乙方所享有的
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="1">地上</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="2">地下</el-radio>
          </span>
          停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          使用年限按国家法律政策的规定处理。
        </h3>
        <!-- 第二条 -->
        <h3>二、价款及支付方式</h3>
        <h3 class="font_weight500">
          1、本协议所指本宗
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="1">地上</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.parkingLotType" label="2">地下</el-radio>
          </span>
          停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          转让金总价款为人民币
          <input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.sec_carPrice_xiaoxie" @change="getDX(form.sec_carPrice_xiaoxie, 1)" />
          元（小写）。
          <input :disabled="flag" class="inputW_150px input_borderBottom_000" v-model="form.sec_carPrice_daxie" />
          元（大写）。
        </h3>
        <h3 class="font_weight500">
          2、乙方付款方式为一次性付清全款。乙方于本协议签订后<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.sec_qiandingDay" /> 日内向甲方付清全部停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          转让金。
        </h3>
        <h3 class="font_weight500">
          3、款项交接其他说明
        </h3>
        <!-- 第三条 -->
        <h3>三、车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />交付</h3>
        <h3 class="font_weight500">
          1、甲方收到乙方停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 转让金后，同时将甲方从原<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquName" /> 小区开发商购买车<input
            :disabled="flag"
            class="inputW_50px input_borderBottom_000"
            v-model="form.parkingLot"
          />
          的合同、收据等全部手续一并交给乙方。
        </h3>
        <h3 class="font_weight500">
          2、与该停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 的相关权益（占有、使用、收益、处分的权利）也一并全部转移给乙方，甲方对该停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          不再享有任何权益。
        </h3>
        <h3 class="font_weight500">
          3、转让后，甲乙双方须到<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.xiaoquName" /> 小区所在的物业公司备案，就停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          物业管理费缴存办理移交手续。
        </h3>
        <h3 class="font_weight500">
          4、乙方知悉，车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.shifouType" label="1">能</el-radio>
          </span>
          <span style="padding-left:10px">
            <el-radio :disabled="flag" class="font_weight500" v-model="formCheck.shifouType" label="2">不能</el-radio>
          </span>
          办理独立权属证书，不能设立抵押。
        </h3>

        <!-- 第四条 -->
        <h3>四、使用管理</h3>
        <h3 class="font_weight500">
          自甲方向乙方移交该停车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          之后所发生的一切费用及法律责任均由乙方自行承担。
        </h3>

        <!-- 第五条 -->
        <h3>五、其他费用</h3>
        <h3 class="font_weight500">
          1、在签订本合同时，由甲方应向丙方支付中介服务费人民币<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.fif_jia_zhongjieFei_xiaoxie" @change="getDX(form.fif_jia_zhongjieFei_xiaoxie, 2)" /> （小写）<input
            :disabled="flag"
            class="inputW_150px input_borderBottom_000"
            v-model="form.fif_jia_zhongjieFei_daxie"
          />
          （大写）元整；由乙方应向丙方支付中介服务费人民币<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.fif_yi_zhongjieFei_xiaoxie" @change="getDX(form.fif_yi_zhongjieFei_xiaoxie, 3)" /> （小写）<input
            :disabled="flag"
            class="inputW_150px input_borderBottom_000"
            v-model="form.fif_yi_zhongjieFei_daxie"
          />
          （大写）元整。
        </h3>
        <h3 class="font_weight500">
          2、如车<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" /> 有产权交易产生的相关税费，由<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.fif_chengdan" />
          方全额承担。
        </h3>

        <!-- 第六条 -->
        <h3>六、补偿条款</h3>
        <h3 class="font_weight500">
          交易完成，甲方及其家人以任何理由主张该转让协议无效、可撤销，致使该转让自始无效的，甲方应当向乙方返还购买款、并补足车
          <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          交易价与市场价的差额。
        </h3>
        <h3 class="font_weight500">
          乙方以不能归责予甲方的原因主张该转让协议无效，致使该转让自始无效的。乙方应当向甲方补足车
          <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          交易价与市场价的差额。
        </h3>

        <!-- 第七条 -->
        <h3>七、违约责任</h3>
        <h3 class="font_weight500">
          任何一方违约，需向对方支付转让价款20％的违约责任，该条款为独立条款，不因合同无效、撤销而失效。
        </h3>

        <!-- 第八条 -->
        <h3>八、丙方权力及义务</h3>
        <h3 class="font_weight500">
          1、利用自身专业知识，为甲、乙双方提供物业买卖/租赁的专业意见和资讯。
        </h3>
        <h3 class="font_weight500">
          2、居间协调，促成双方签订本合同的同时，有权按照合同约定及时足额收取中介服务费。/租赁的专业意见和资讯。
        </h3>

        <!-- 第九条 -->
        <h3>
          九、本协议自甲、乙双方签字后生效，甲、乙双方对本协议未尽事项可协商补充或附件，合同附件、补充协议与本协议具有同等法律效力。双方发生争议时应先协商解决，协商不成由车
          <input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.parkingLot" />
          所在地人民法院管辖。
        </h3>

        <!-- 第十条 -->
        <h3>
          十、本协议正本一式三份，甲乙丙三方各执一份，具有同等法律效力。
        </h3>

        <div style="margin:100px 0">
          <h3 class="font_weight500" style="display:flex;">
            <span style="flex:2">甲方：</span>
            <span style="flex:1">乙方：</span>
          </h3>
          <h3 class="font_weight500" style="display:flex;">
            <span style="flex:2">身份证号：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaIdCardNo"/></span>
            <span style="flex:1">身份证号：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiIdCardNo"/></span>
          </h3>
          <h3 class="font_weight500" style="display:flex;">
            <span style="flex:2">联系方式：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.jiaMobile"/></span>
            <span style="flex:1">联系方式：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.yiMobile"/></span>
          </h3>
          <h3 class="font_weight500" style="display:flex;">
            <span style="flex:2"
              >签约时间：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.qianShu_Year" :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month" :maxlength="2" @blur="rulesDate(7, 'qianShu_Month')" />月<input
                :disabled="flag"
                class="inputW_50px input_borderBottom_000"
                v-model="form.qianShu_Day"
                :maxlength="2"
                @blur="rulesDate(8, 'qianShu_Day')"
              />日</span
            >
            <span style="flex:1"
              >签约时间：<input :disabled="flag" class="inputW_80px input_borderBottom_000" v-model="form.qianShu_Year" :maxlength="4" />年<input :disabled="flag" class="inputW_50px input_borderBottom_000" v-model="form.qianShu_Month" :maxlength="2" @blur="rulesDate(7, 'qianShu_Month')" />月<input
                :disabled="flag"
                class="inputW_50px input_borderBottom_000"
                v-model="form.qianShu_Day"
                :maxlength="2"
                @blur="rulesDate(8, 'qianShu_Day')"
              />日</span
            >
          </h3>
          <h3 class="font_weight500" style="display:flex;margin-top:50px">
            <span>居间人：<input :disabled="flag" class="inputW_250 input_borderBottom_000" v-model="form.companyName"/></span>
          </h3>
          <h3 class="font_weight500" style="display:flex;">
            <span style="flex:2">签约经纪人：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.qianshu_jjrName"/></span>
            <span style="flex:1">联系电话：<input :disabled="flag" class="inputW_150 input_borderBottom_000" v-model="form.qianshu_jjrMobile"/></span>
          </h3>
        </div>
      </div>
    </el-card>
    <!-- </div> -->
  </div>
</template>
<script>
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JsZipUtils from 'jszip-utils'
import FileSaver from 'file-saver'
import MaiMaiTemplate from './CheWeiMaiMaiTemplate.js'
import QianYueJs from '../QianYue'
import url from '../../../config/url.js'
import { Loading } from 'element-ui'
import axios from 'axios'
import { Message } from 'element-ui'

export default {
  inject: ['reload'],
  data() {
    return {
      loading: false,
      disabled: true,
      flag: false,
      // contractId: this.$route.query.contractId,
      userId: localStorage.getItem('userId'),
      form: MaiMaiTemplate.form,
      formCheck: MaiMaiTemplate.formCheck,
      MyHeader: {
        currentRole: '',
        accessToken: '',
        fp: ''
      }
    }
  },
  props: {
    formData: {
      type: Object,
      default: {}
    },
    form1: {
      type: Object,
      default: {}
    },
    contractId: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    },
    contractStatus: {
      type: String,
      default: ''
    }
  },
  created() {
    if (this.contractStatus >= '20') {
      this.flag = true
    }
    this.MyHeader.accessToken = localStorage.getItem('accessToken') //获取accessToken
    this.MyHeader.fp = localStorage.getItem('fp') //浏览器指纹fp
    this.roleType = window.localStorage.getItem('roleType')
    if (this.roleType == undefined) {
      this.MyHeader.currentRole = localStorage.getItem('userType').charAt(0)
    } else {
      this.MyHeader.currentRole = localStorage.getItem('roleType')
    }
    this.form.contractNo = this.formData.contractNo //合同编号
    this.form.companyName = this.formData.companyName //公司名称
    var d = new Date()
    this.form.qianShu_Year = d.getFullYear() //签署日期 年
    this.form.qianShu_Month = d.getMonth() + 1 //签署日期 月
    this.form.qianShu_Day = d.getDate() //签署日期 日
    this.form.jiaName = this.formData.sellerName //甲方姓名
    this.form.jiaIdCardNo = this.formData.sellerIdCardNo //甲方身份证号
    this.form.jiaMobile = this.formData.sellerMobile //甲方手机号
    this.form.yiName = this.formData.buyerName //乙方姓名
    this.form.yiIdCardNo = this.formData.buyerIdCardNo //乙方身份证号
    this.form.yiMobile = this.formData.buyerMobile //乙方手机号
    if (JSON.stringify(this.form1) != '{}') {
      this.form = this.form1
      this.formCheck.shifouType = this.form.shifouType //shifouType
      this.formCheck.parkingLotType = this.form.parkingLotType //停车位类型
    }
  },
  methods: {
    onSetp(type) {
      this.$emit('onSetp', type)
    },
    goBack() {
      this.$router.go(-1)
    },
    concatData(a, b, checkedParams) {
      let data = []
      const arr = [...a, ...b]
      data = arr.filter(item => {
        return !(a.includes(item) && b.includes(item))
      })
      data.forEach(element => {
        checkedParams[element] = '□'
      })
      b.forEach(element => {
        checkedParams[element] = '√'
      })
      return checkedParams
    },
    async onSave(type) {
      let that = this
      var get = QianYueJs.httpRequest
      // for (var i in that.form) {
      //     if (that.form[i] === "") {
      //         that.$message.error("请完善数据")
      //         return false
      //     }
      // }
      // for (var i in that.formCheck) {
      //     if (that.formCheck[i].length === 0) {
      //         that.$message.error("请至少选择一项")
      //         return false
      //     }
      // }

      that.loadingFunction()
      JsZipUtils.getBinaryContent('车位转让居间合同.docx', function(error, content) {
        if (error) {
          throw error
        }
        const zip = new PizZip(content)

        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true
        })

        // 停车位情况
        if (that.formCheck.parkingLotType == '1') {
          that.form.diShangCheWei = '√'
        } else if (that.formCheck.parkingLotType == '2') {
          that.form.diXiaCheWei = '√'
        } else {
          that.form.diShangCheWei = '□'
          that.form.diXiaCheWei = '□'
        }

        // 独立权属证书情况
        if (that.formCheck.shifouType == '1') {
          that.form.shi = '√'
        } else if (that.formCheck.shifouType == '2') {
          that.form.fou = '√'
        } else {
          that.form.shi = '□'
          that.form.fou = '□'
        }

        doc.setData({
          ...that.form
        })

        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render()
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                error[key] = value[key]
                return error
              }, {})
            }
            return value
          }
          console.log(JSON.stringify({ error: error }, replaceErrors))

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function(error) {
                return error.properties.explanation
              })
              .join('\n')
          }
          throw error
        }
        const blob = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
        // Output the document using Data-URI
        // saveAs(blob, "房屋买卖协议.docx");
        if (type == 2) {
          that.loading.close()
          // that.save()
          saveAs(blob, '车位转让居间合同.docx')
        } else if (type == 1) {
          that.httpRequest(blob, that.contractId, type)
          // that.save()
          that.loading.close()
        }
      })
    },
    httpRequest(blob, contractId, type) {
      let that = this,
        params = {},
        url1 = url.TransferFileToESign
      const loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let a = new FileReader()
      a.onload = function(e) {
        params = {
          base64File: e.target.result.split('base64,')[1],
          contractId: contractId
        }
        axios
          .post(url1, params, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(res => {
            if (res.data.msgCode == 1) {
              setTimeout(function() {
                Message.success({
                  message: '签约基本信息保存成功！'
                })
                loading.close()
                that.reload()
                // that.Submit()
                // return true
              }, 3000)
            } else {
              Message.error({
                message: res.data.message,
                duration: 5000
              })
              loading.close()
              // return true
            }
          })
          .catch(res => {
            Message.error('请求错误')
          })
      }
      a.readAsDataURL(blob)
    },
    applyPass() {
      let that = this
      // that.draft();
      that.loading = true
      const data = {
        contractId: that.contractId
      }
      that.$http
        .post(that.$url.ConfirmComplete, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.loading = false
            that.reload()
          } else {
            that.loading = false
            that.$message.error(res.data.message)
          }
        })
    },
    draft() {
      let that = this,
        param = {}
      that.form.parkingLotType = that.formCheck.parkingLotType
      that.form.shifouType = that.formCheck.shifouType
      param = {
        ContractId: that.contractId,
        ContractInfo: JSON.stringify(that.form)
      }
      that.$http
        .put(that.$url.UpdateContractDraft, param, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.$message.success('签约基本信息保存成功！')
            that.loading.close()
          } else {
            that.loading.close()
            that.$message.error(res.data.message)
          }
        })
    },
    save() {
      let that = this,
        param = {}
      that.disabled = true
      if (that.form.sec_carPrice_xiaoxie == 'x') {
        return that.$message.error('请填写车位转让金额')
      }
      var numReg = /^[0-9]+$/
      var numRe = new RegExp(numReg)
      if (!numRe.test(that.form.sec_carPrice_xiaoxie)) {
        return that.$message.error('车位转让金额（小写）有误')
      }
      if (that.form.fif_jia_zhongjieFei_xiaoxie == 'x') {
        return that.$message.error('请填写甲方服务费金额')
      }
      var numReg = /^[0-9]+$/
      var numRe = new RegExp(numReg)
      if (!numRe.test(that.form.fif_jia_zhongjieFei_xiaoxie)) {
        return that.$message.error('甲方服务费金额（小写）有误')
      }
      if (that.form.fif_yi_zhongjieFei_xiaoxie == 'x') {
        return that.$message.error('请填写乙方服务费金额')
      }
      param = {
        dingJinAmount: 0,
        jiaFangFuWuFeiAmount: Number(that.form.fif_jia_zhongjieFei_xiaoxie),
        yiFangFuWuFeiAmount: that.form.fif_yi_zhongjieFei_xiaoxie,
        baoZhangFuWuFeiAmount: 0,
        isDingZhuanYong: '0',
        userId: that.formData.userId,
        contractId: that.contractId,
        shopId: that.formData.shopId,
        merchantId: that.formData.merchantId,
        houseId: that.formData.houseId,
        customerId: that.formData.customerId,
        tradeType: that.formData.tradeType,
        type: that.formData.type,
        contractHouseAddress: that.formData.contractHouseAddress,
        contractPrice: Number(that.form.sec_carPrice_xiaoxie)
        // fileId1: that.form.fileId1.split(".")[0]
        // contractInfo: JSON.stringify(that.form)
      }
      console.log(param, '8786465451')
      that.$http
        .put(that.$url.UpdateContract, param, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(res => {
          that.disabled = false
          if (res.data.msgCode == 1) {
            // that.$message.success("签约基本信息保存成功！");
            that.onSave(1)
            that.loading.close()
            if (!that.contractId) {
              this.$router.go(-1)
            }
          } else {
            that.loading.close()
            that.$message.error(res.data.message)
          }
        })
        .catch(res => {
          that.disabled = false
          that.loading.close()
          this.$message.error('请求错误')
        })
    },
    // 邮箱校验
    onlyRules() {
      if (this.form.chuShou_Email != 'x' && this.form.chuShou_Email != '无' && !this.$commonJs.numberEmail(this.form.chuShou_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.chuShou_Email = ''
        return
      }
      if (this.form.chuShouGY_Email != 'x' && this.form.chuShouGY_Email != '无' && !this.$commonJs.numberEmail(this.form.chuShouGY_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.chuShouGY_Email = ''
        return
      }
      if (this.form.chuShouDL_Email != 'x' && this.form.chuShouDL_Email != '无' && !this.$commonJs.numberEmail(this.form.chuShouDL_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.chuShouDL_Email = ''
        return
      }
      if (this.form.gouMai_Email != 'x' && this.form.gouMai_Email != '无' && !this.$commonJs.numberEmail(this.form.gouMai_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.gouMai_Email = ''
        return
      }
      if (this.form.gouMaiGY_Email != 'x' && this.form.gouMaiGY_Email != '无' && !this.$commonJs.numberEmail(this.form.gouMaiGY_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.gouMaiGY_Email = ''
        return
      }
      if (this.form.gouMaiDL_Email != 'x' && this.form.gouMaiDL_Email != '无' && !this.$commonJs.numberEmail(this.form.gouMaiDL_Email)) {
        this.$message.error('格式不符，请填写正确的邮箱!')
        this.form.gouMaiDL_Email = ''
        return
      }
    },
    rulesDate(num, param) {
      if (this.form[param] !== '') {
        this.form[param] = parseInt(this.form[param])
        if (num % 2 == 1 && (this.form[param] > 12 || this.form[param] < 1)) {
          this.form[param] = ''
          this.$message.error('请输入正确的月份')
        } else if (this.form[param] > 31 || this.form[param] < 1) {
          this.form[param] = ''
          this.$message.error('请输入正确的日期')
        }
      }
    },
    rulesIdNo() {
      // 证件号码
      if (this.form.sellerIdCardNo != '×' && !this.$commonJs.shenfenZheng(this.form.sellerIdCardNo)) {
        this.$message.error('证件号码格式不正确')
        this.form.sellerIdCardNo = ''
        return
      }
      if (this.form.sellerIdCardNoGongYou != 'x' && !this.$commonJs.shenfenZheng(this.form.sellerIdCardNoGongYou)) {
        this.$message.error('证件号码格式不正确')
        this.form.sellerIdCardNoGouYou = ''
        return
      }
      if (this.form.sellerIdCardNoDaiLi != 'x' && !this.$commonJs.shenfenZheng(this.form.sellerIdCardNoDaiLi)) {
        this.$message.error('证件号码格式不正确')
        this.form.sellerIdCardNoDaiLi = ''
        return
      }
      if (this.form.buyerIdCardNo != 'x' && !this.$commonJs.shenfenZheng(this.form.buyerIdCardNo)) {
        this.$message.error('证件号码格式不正确')
        this.form.buyerIdCardNo = ''
        return
      }
      if (this.form.buyerIdCardNoGongYou != 'x' && !this.$commonJs.shenfenZheng(this.form.buyerIdCardNoGongYou)) {
        this.$message.error('证件号码格式不正确')
        this.form.buyerIdCardNoGongYou = ''
        return
      }
      if (this.form.buyerIdCardNoDaiLi != 'x' && !this.$commonJs.shenfenZheng(this.form.buyerIdCardNoDaiLi)) {
        this.$message.error('证件号码格式不正确')
        this.form.buyerIdCardNoDaiLi = ''
        return
      }
      if (this.form.cmr_ZJHM != 'x' && !this.$commonJs.shenfenZheng(this.form.cmr_ZJHM)) {
        this.$message.error('证件号码格式不正确')
        this.form.cmr_ZJHM = ''
        return
      }
      if (this.form.cmr_GYQRZJHM != 'x' && !this.$commonJs.shenfenZheng(this.form.cmr_GYQRZJHM)) {
        this.$message.error('证件号码格式不正确')
        this.form.cmr_GYQRZJHM = ''
        return
      }
      if (this.form.msr_ZJHM != 'x' && !this.$commonJs.shenfenZheng(this.form.msr_ZJHM)) {
        this.$message.error('证件号码格式不正确')
        this.form.msr_ZJHM = ''
        return
      }
      if (this.form.msr_GYGMRZJHM != 'x' && !this.$commonJs.shenfenZheng(this.form.msr_GYGMRZJHM)) {
        this.$message.error('证件号码格式不正确')
        this.form.msr_GYGMRZJHM = ''
        return
      }
    },
    // numberTel(obj) {
    //     var type = /^[04]\d{2,3}-?\d{3}-?\d{4,5}$/
    //     var flag = type.test(obj)
    //     return flag
    // },
    // rulesTel(val) {
    //     if (val === 1 && !this.numberTel(this.form.tel1)) {
    //         this.form.tel1 = ""
    //         this.$message.error("请输入正确的号码")
    //     }
    //     if (val === 2 && !this.numberTel(this.form.tel2)) {
    //         this.form.tel2 = ""
    //         this.$message.error("请输入正确的号码")
    //     }
    // },
    fun() {
      this.form.bingFang2 = this.form.bingFang1
    },
    rulesMobile(val) {
      if (val === 1 && !this.$commonJs.numberPhone(this.form.LXDH1)) {
        this.form.LXDH1 = ''
        this.$message.error('请输入正确的手机号码')
      }
      if (val === 2 && !this.$commonJs.numberPhone(this.form.LXDH2)) {
        this.form.LXDH2 = ''
        this.$message.error('请输入正确的手机号码')
      }
    },
    moneyRule(param) {
      if (this.form[param] != 'x' && !this.$commonJs.moneyFormat(this.form[param] * 1)) {
        this.form[param] = 'x'
        this.$message.error('请输入正确金额')
      }
      if (param == 'yueZu_XiaoXieYuan' && this.form.yueZu_XiaoXieYuan != 'x') {
        if (this.form.yueZu_XiaoXieYuan != '') {
          this.form.yueZu_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.yueZu_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.yueZu_XiaoXieYuan = 'x'
          this.form.yueZu_DaXieYuan = 'x'
        }
      }
      if (param == 'yaJin_XiaoXieYuan' && this.form.yaJin_XiaoXieYuan != 'x') {
        if (this.form.yaJin_XiaoXieYuan != '') {
          this.form.yaJin_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.yaJin_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.yaJin_DaXieYuan = 'x'
          this.form.yaJin_XiaoXieYuan = 'x'
        }
      }
      if (param == 'zongFangKuan_XiaoXieYuan' && this.form.zongFangKuan_XiaoXieYuan != 'x') {
        if (this.form.zongFangKuan_XiaoXieYuan != '') {
          this.form.zongFangKuan_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.zongFangKuan_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.zongFangKuan_DaXieYuan = 'x'
          this.form.zongFangKuan_XiaoXieYuan = 'x'
        }
      }
      if (param == 'fangWuJiaGe_XiaoXieYuan' && this.form.fangWuJiaGe_XiaoXieYuan != 'x') {
        if (this.form.fangWuJiaGe_XiaoXieYuan != '') {
          this.form.fangWuJiaGe_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.fangWuJiaGe_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.fangWuJiaGe_DaXieYuan = 'x'
          this.form.fangWuJiaGe_XiaoXieYuan = 'x'
        }
      }
      if (param == 'zhuangXiuKuan_XiaoXieYuan1' && this.form.zhuangXiuKuan_XiaoXieYuan1 != 'x') {
        if (this.form.zhuangXiuKuan_XiaoXieYuan1 != '') {
          this.form.zhuangXiuKuan_DaXieYuan1 = this.$commonJs.getDaXie(this.form[param])
          this.form.zhuangXiuKuan_XiaoXieYuan1 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.zhuangXiuKuan_DaXieYuan1 = 'x'
          this.form.zhuangXiuKuan_XiaoXieYuan1 = 'x'
        }
      }
      if (param == 'dingJin_XiaoXieYuan1' && this.form.dingJin_XiaoXieYuan1 != 'x') {
        if (this.form.dingJin_XiaoXieYuan1 != '') {
          this.form.dingJin_DaXieYuan1 = this.$commonJs.getDaXie(this.form[param])
          this.form.dingJin_XiaoXieYuan1 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.dingJin_DaXieYuan1 = 'x'
          this.form.dingJin_XiaoXieYuan1 = 'x'
        }
      }
      if (param == 'diYiBi_XiaoXieYuan' && this.form.diYiBi_XiaoXieYuan != 'x') {
        if (this.form.diYiBi_XiaoXieYuan != '') {
          this.form.diYiBi_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.diYiBi_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.diYiBi_DaXieYuan = 'x'
          this.form.diYiBi_XiaoXieYuan = 'x'
        }
      }
      if (param == 'diErBi_XiaoXieYuan' && this.form.diErBi_XiaoXieYuan != 'x') {
        if (this.form.diErBi_XiaoXieYuan != '') {
          this.form.diErBi_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.diErBi_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.diErBi_DaXieYuan = 'x'
          this.form.diErBi_XiaoXieYuan = 'x'
        }
      }
      if (param == 'cunGuanDingJin_XiaoXieYuan1' && this.form.cunGuanDingJin_XiaoXieYuan1 != 'x') {
        if (this.form.cunGuanDingJin_XiaoXieYuan1 != '') {
          this.form.cunGuanDingJin_DaXieYuan1 = this.$commonJs.getDaXie(this.form[param])
          this.form.cunGuanDingJin_XiaoXieYuan1 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.cunGuanDingJin_DaXieYuan1 = 'x'
          this.form.cunGuanDingJin_XiaoXieYuan1 = 'x'
        }
      }
      if (param == 'cunGuanDingJin_XiaoXieYuan2' && this.form.cunGuanDingJin_XiaoXieYuan2 != 'x') {
        if (this.form.cunGuanDingJin_XiaoXieYuan2 != '') {
          this.form.cunGuanDingJin_DaXieYuan2 = this.$commonJs.getDaXie(this.form[param])
          this.form.cunGuanDingJin_XiaoXieYuan2 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.cunGuanDingJin_DaXieYuan2 = 'x'
          this.form.cunGuanDingJin_XiaoXieYuan2 = 'x'
        }
      }
      if (param == 'shengYuDingJin_XiaoXieYuan' && this.form.shengYuDingJin_XiaoXieYuan != 'x') {
        if (this.form.shengYuDingJin_XiaoXieYuan != '') {
          this.form.shengYuDingJin_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.shengYuDingJin_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.shengYuDingJin_DaXieYuan = 'x'
          this.form.shengYuDingJin_XiaoXieYuan = 'x'
        }
      }
      if (param == 'fangKuan_XiaoXieYuan1' && this.form.fangKuan_XiaoXieYuan1 != 'x') {
        if (this.form.fangKuan_XiaoXieYuan1 != '') {
          this.form.fangKuan_DaXieYuan1 = this.$commonJs.getDaXie(this.form[param])
          this.form.fangKuan_XiaoXieYuan1 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.fangKuan_DaXieYuan1 = 'x'
          this.form.fangKuan_XiaoXieYuan1 = 'x'
        }
      }
      if (param == 'zhuangXiukuan_XiaoXieYuan2' && this.form.zhuangXiukuan_XiaoXieYuan2 != 'x') {
        if (this.form.zhuangXiukuan_XiaoXieYuan2 != '') {
          this.form.zhuangXiukuan_DaXieYuan2 = this.$commonJs.getDaXie(this.form[param])
          this.form.zhuangXiukuan_XiaoXieYuan2 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.zhuangXiukuan_DaXieYuan2 = 'x'
          this.form.zhuangXiukuan_XiaoXieYuan2 = 'x'
        }
      }
      if (param == 'fangKuan_XiaoXieYuan2' && this.form.fangKuan_XiaoXieYuan2 != 'x') {
        if (this.form.fangKuan_XiaoXieYuan2 != 'x') {
          this.form.fangKuan_DaXieYuan2 = this.$commonJs.getDaXie(this.form[param])
          this.form.fangKuan_XiaoXieYuan2 = Number(this.form[param]).toFixed(2)
        } else {
          this.form.fangKuan_DaXieYuan2 = 'x'
          this.form.fangKuan_XiaoXieYuan2 = 'x'
        }
      }
      if (param == 'daiKuan_XiaoXieYuan' && this.form.daiKuan_XiaoXieYuan != 'x') {
        if (this.form.daiKuan_XiaoXieYuan != '') {
          this.form.daiKuan_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.daiKuan_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.daiKuan_DaXieYuan = 'x'
          this.form.daiKuan_XiaoXieYuan = 'x'
        }
      }
      if (param == 'zhongJieFei_XiaoXieYuan' && this.form.zhongJieFei_XiaoXieYuan != 'x') {
        if (this.form.zhongJieFei_XiaoXieYuan != 'x') {
          this.form.zhongJieFei_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.zhongJieFei_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.zhongJieFei_DaXieYuan = 'x'
          this.form.zhongJieFei_XiaoXieYuan = 'x'
        }
      }
      if (param == 'jfzjf_XiaoXieYuan' && this.form.jfzjf_XiaoXieYuan != 'x') {
        if (this.form.jfzjf_XiaoXieYuan != '') {
          this.form.jfzjf_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.jfzjf_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.jfzjf_DaXieYuan = 'x'
          this.form.jfzjf_XiaoXieYuan = 'x'
        }
      }
      if (param == 'jfzxzf_XiaoXieYuan' && this.form.jfzxzf_XiaoXieYuan != 'x') {
        if (this.form.jfzxzf_XiaoXieYuan != '') {
          this.form.jfzxzf_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.jfzxzf_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.jfzxzf_DaXieYuan = 'x'
          this.form.jfzxzf_XiaoXieYuan = 'x'
        }
      }
      if (param == 'jfwtzf_XiaoXieYuan' && this.form.jfwtzf_XiaoXieYuan != 'x') {
        if (this.form.jfwtzf_XiaoXieYuan != '') {
          this.form.jfwtzf_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.jfwtzf_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.jfwtzf_DaXieYuan = 'x'
          this.form.jfwtzf_XiaoXieYuan = 'x'
        }
      }
      if (param == 'yfzjf_XiaoXieYuan' && this.form.yfzjf_XiaoXieYuan != 'x') {
        if (this.form.yfzjf_XiaoXieYuan != '') {
          this.form.yfzjf_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.yfzjf_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.yfzjf_DaXieYuan = 'x'
          this.form.yfzjf_XiaoXieYuan = 'x'
        }
      }
      if (param == 'sydk_XiaoXieYuan' && this.form.sydk_XiaoXieYuan != 'x') {
        if (this.form.sydk_XiaoXieYuan != '') {
          this.form.sydk_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.sydk_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.sydk_DaXieYuan = 'x'
          this.form.sydk_XiaoXieYuan = 'x'
        }
      }
      if (param == 'gjjdk_XiaoXieYuan' && this.form.gjjdk_XiaoXieYuan != 'x') {
        if (this.form.gjjdk_XiaoXieYuan != '') {
          this.form.gjjdk_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.gjjdk_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.gjjdk_DaXieYuan = 'x'
          this.form.gjjdk_XiaoXieYuan = 'x'
        }
      }
      if (param == 'bzfwf_XiaoXieYuan' && this.form.bzfwf_XiaoXieYuan != 'x') {
        if (this.form.bzfwf_XiaoXieYuan != '') {
          this.form.bzfwf_DaXieYuan = this.$commonJs.getDaXie(this.form[param])
          this.form.bzfwf_XiaoXieYuan = Number(this.form[param]).toFixed(2)
        } else {
          this.form.bzfwf_DaXieYuan = 'x'
          this.form.bzfwf_XiaoXieYuan = 'x'
        }
      }
    },

    loadingFunction() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    beforeUpload(file) {
      this.loadingFunction()
    },
    Submit(type) {
      let that = this
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      that.disabled = true
      // this.onSave(2);
      let param = {
        contractId: that.contractId
      }
      that.$http
        .post(that.$url.FlowOneStepESign, param, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        .then(result => {
          that.disabled = false
          that.loading.close()
          if (result.data.msgCode == 1) {
            that.$message.success('合同提交成功！')
            that.$router.go(-1)
          } else {
            that.$message.error(result.data.message)
          }
        })
        .catch(result => {
          that.loading.close()
          that.disabled = false
          that.$message.error('请求错误')
        })
    },
    // handleSuccess(res, file) {
    //   if (res.msgCode == "1") {
    //     let that = this;
    //     that.disabled = true;
    //     let param = {
    //       userId: that.contractId,
    //     };
    //     that.$http
    //       .post(that.$url.FlowOneStepESign, param, {
    //         headers: {
    //           "Content-Type": "application/json;charset=UTF-8",
    //         },
    //       })
    //       .then((result) => {
    //         that.disabled = false;
    //         that.loading.close();
    //         if (result.data.msgCode == 1) {
    //           that.$message.success("合同提交成功！");
    //           that.$router.go(-1);
    //         } else {
    //           that.$message.error(result.data.message);
    //         }
    //       })
    //       .catch((result) => {
    //         that.loading.close();
    //         that.disabled = false;
    //         that.$message.error("请求错误");
    //       });
    //   } else {
    //     that.loading.close();
    //     this.$message.error("合同提交失败");
    //   }
    // },
    // handleRemove(file, fileList) {
    //   this.fileList = [];
    // },
    // handleExceed(files, fileList) {
    //   this.$message.warning("当前限制选择1个文件，请删除当前文件后，重新上传!");
    // },
    // beforeRemove(file, fileList) {
    //   return this.$confirm(`确定移除 ${file.name}？`);
    // },

    smallToBig(money, num) {
      //  将数字金额转换为大写金额
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖') //汉字的数字
      var cnIntRadice = new Array('', '拾', '佰', '仟') //基本单位
      var cnIntUnits = new Array('', '万', '亿', '兆') //对应整数部分扩展单位
      var cnDecUnits = new Array('角', '分', '毫', '厘') //对应小数部分单位
      var cnInteger = '整' //整数金额时后面跟的字符
      var cnIntLast = '元' //整数完以后的单位
      //最大处理的数字
      var maxNum = 999999999999999.9999
      var integerNum //金额整数部分
      var decimalNum //金额小数部分
      //输出的中文金额字符串
      var chineseStr = ''
      var parts //分离金额后用的数组，预定义
      if (money == '') {
        return ''
      }

      money = parseFloat(money)
      if (money >= maxNum) {
        //超出最大处理数字
        return '超出最大处理数字'
      }
      if (money == 0) {
        chineseStr = cnNums[0]
        if (num == 1) {
          this.form.sec_carPrice_daxie = chineseStr
        } else if (num == 2) {
          this.form.fif_jia_zhongjieFei_daxie = chineseStr
        } else if (num == 3) {
          this.form.fif_yi_zhongjieFei_daxie = chineseStr
        }
        return chineseStr
      }

      //四舍五入保留两位小数,转换为字符串
      money = Math.round(money * 100).toString()
      integerNum = money.substr(0, money.length - 2)
      decimalNum = money.substr(money.length - 2)

      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1)
          var p = IntLen - i - 1
          var q = p / 4
          var m = p % 4
          if (n == '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            //归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        // chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1)
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0]
      } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
        // chineseStr += cnInteger;
      }
      if (num == 1) {
        this.form.sec_carPrice_daxie = chineseStr
      } else if (num == 2) {
        this.form.fif_jia_zhongjieFei_daxie = chineseStr
      } else if (num == 3) {
        this.form.fif_yi_zhongjieFei_daxie = chineseStr
      }
    },
    getDX(number, num) {
      this.smallToBig(number, num)
    }
  }
}
</script>
<style scoped>
input[type='checkbox'] {
  vertical-align: sub !important;
}
</style>
<style lang="less" scoped>
.body {
  width: 100%;
  padding: 1% 4%;
  font-size: 12px;
  font-family: '宋体';
  list-style: none;
  box-sizing: border-box;

  .font_weight {
    font-weight: 600;
  }

  .text_align_l {
    text-align: left;
  }

  .text_align_r {
    text-align: right;
  }

  .border_bottom {
    border-bottom: 1px solid #000;
  }

  .header_title {
    color: #b3b3b3;
    font-weight: 400;
    line-height: 25px;
    border-bottom: 1px solid #ccc;
  }

  .header {
    height: 70px;
    border-bottom: 1px solid #ccc;

    img {
      display: block;
      float: left;
      width: 90px;
    }

    h3 {
      float: right;
      line-height: 90px;
      color: #b3b3b3;
      font-weight: 400;
    }
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 16px;
    color: red;
    text-align: center;
    height: 20px !important;
    vertical-align: text-bottom;
  }

  .input_borderBottom_ccc {
    border-bottom: 1px solid #ccc;
  }

  .input_borderBottom_000 {
    border-bottom: 1px solid #000;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
  }

  /deep/ .el-radio__input + .el-radio__label,
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #000 !important;
  }

  /deep/ .el-checkbox__inner {
    border: 1px solid #000 !important;
  }

  /deep/ .el-input__inner {
    border: 0 !important;
    font-size: 16px;
    color: red;
    text-align: center;
  }

  .text-c {
    text-align: center;
  }

  .text-indent {
    text-indent: 35px;
  }

  .text-indent-12 {
    text-indent: 12px;
  }

  .text-indent-44 {
    text-indent: 44px;
  }

  .borderN {
    border-bottom: none;
  }

  .border0 {
    border: 0;
  }

  .border_000 {
    border: 1px solid #000;
  }

  .inputW_50px {
    width: 50px;
  }

  .inputW_80px {
    width: 80px;
  }

  .inputW_150 {
    width: 150px;
  }

  .inputW_250 {
    width: 250px;
  }

  .inputW_15 {
    width: 15%;
  }

  .inputW_30 {
    width: 30%;
  }

  .inputW_23 {
    width: 23%;
  }

  .inputW_40 {
    width: 40%;
  }

  .margin_top_150 {
    margin-top: 150px !important;
  }

  .margin_top_100 {
    margin-top: 100px !important;
  }

  .margin_top_50 {
    margin-top: 50px !important;
  }

  .margin_top_20 {
    margin-top: 20px !important;
  }

  .margin_top_10 {
    margin-top: 10px !important;
  }

  .margin_left_100 {
    margin-left: 100px;
  }

  // .padding_left_100 {
  //     text-indent: 35px;
  // }
  .font_weight500 {
    font-weight: 500;
  }

  .font_weight600 {
    font-weight: 600;
  }

  .float_left {
    float: left;
  }

  .float_right {
    float: right;
  }

  // 清除浮动
  .clearfix:after {
    content: '';
    height: 0;
    line-height: 0;
    display: block;
    clear: both;
    visibility: hidden;
  }

  .clearfix {
    zoom: 1;
  }

  .el-card {
    line-height: 25px !important;
  }

  p {
    margin: 0;
  }

  .quote_info {
    font-size: 15px;
    color: #000;
    width: 70%;
    margin: 0 auto;

    .h3_title {
      float: left;
      width: 100%;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 100px;
      font-weight: 400;
      font-family: '黑体';
      color: #000;
      text-align: center;
    }

    .h2_title {
      float: left;
      width: 100%;
      font-size: 25px;
      letter-spacing: 1px;
      line-height: 50px;
      font-weight: 400;
      font-family: '黑体';
      color: #000;
      text-align: center;
    }
  }

  .box {
    width: 520px;
    height: 200px;
    padding: 5px 20px;

    div {
      width: 300px;
      margin: 0 auto;
    }
  }

  .box1 {
    width: 150px;
    margin: 0 auto;
  }

  ul,
  li {
    list-style: none;
  }

  li {
    border: 1px solid #000;
    line-height: 35px;
  }

  .width_10 {
    width: 10%;
    text-align: center;
    font-weight: 600;
  }

  .width_20 {
    width: 16.3%;
    text-align: center;
    font-weight: 600;
  }

  .width_90 {
    width: 90%;
    text-align: center;
    font-weight: 600;
  }

  .width_98 {
    width: 98%;
  }

  .width_100 {
    width: 100%;
    font-weight: 600;
  }

  .width_full {
    width: 100%;
  }

  .display_flex {
    display: flex;
  }
}

.nav1 {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 40px;
  text-align: center;
  margin: 10px 0 !important;
}

.nav2 {
  margin-left: 0 !important;
}

/deep/ .el-upload-list {
  display: contents;

  .el-upload-list__item-name {
    width: 140px;
  }
}

.el-button {
  margin: 5px;
}

/deep/.el-radio__inner {
  border: 1px solid #df0202;
}

/deep/.el-radio__inner:hover {
  border: 1px solid #0262df;
}

/deep/input[type='checkbox'] {
  position: relative;
  border: 1px solid #df0202 !important;
  border-radius: 3px;
  width: 13px;
  height: 13px !important;
  top: -1px;
  -webkit-appearance: none;
}

/deep/input[type='checkbox']:disabled {
  cursor: not-allowed;
  border-color: rgba(153, 153, 153, 0.3) !important;
  background-color: rgb(241, 241, 241);
}

/deep/input[type='checkbox']:hover {
  border: 1px solid #0262df !important;
}

/deep/input[type='checkbox']:hover:disabled {
  border: 1px solid #dbdbdb !important;
}

/deep/input[type='checkbox']:checked::after {
  content: '✓';
  font-size: 13px;
  margin-top: 0px !important;
  font-weight: bold;
  background-color: #ee6716;
  top: -1px;
  position: absolute;
  left: -1px;
  width: 13px;
  height: 13px !important;
}

/deep/ input:disabled {
  background-color: #fff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #ee6716;
}
</style>
