<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/GetStatistics' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
    </el-breadcrumb> -->
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/GetArticleList' }">
                <el-button size="mini" class="el-button1" type="primary" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="文章标题:" label-width="10%" prop="articleTitle">
                            <el-input :disabled="flag" style="width: 75.5%" v-model="form.articleTitle"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="文章内容:" label-width="10%" prop="articleContent">
                            <el-upload :disabled="flag" style="height: 0px" class="avatar-uploader" :action="serverUrl" name="image" :headers="MyHeader" multiple :show-file-list="false" :on-success="uploadSuccess" :on-error="uploadError" :before-upload="beforeUpload"></el-upload>
                            <quill-editor style="width: 75.5%; display: inline-block" class="editor" :class="[flag ? 'editornone' : '']" :disabled="flag" v-model="form.articleContent" :options="editorOption" ref="myQuillEditor" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"></quill-editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="权重:" label-width="20%" prop="weight">
                            <el-input :disabled="flag" oninput="value=value.replace(/[^\d]/g,'')" style="width: 45%" v-model="form.weight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可用:" label-width="20%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 45%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="10%" label="文章图片:" prop="firstPic">
                            <span style="color: #999">建议上传200:100格式的图片</span>
                            <!-- <el-input v-model="taskPic" style="width: 45%"></el-input> -->
                            <!-- 上传图片 -->
                            <el-upload :action="serverUrl" list-type="picture-card" :file-list="picture" :on-success="success" :headers="MyHeader" :disabled="flag" :on-preview="handlePictureCardPreview" :limit="1" :on-exceed="handleExceed" :class="{ disabled: isMax }" :on-change="change" :on-error="error" :before-remove="handleBeforeRemove">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" v-if="logo" alt />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                    <el-button size="mini" @click="cancel" v-if="num != 1">取消</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
// 工具栏配置
const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    // [{ header: 1 }, { header: 2 }], // 1、2 级标题
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
    [{ script: "sub" }, { script: "super" }], // 上标/下标
    [{ indent: "-1" }, { indent: "+1" }], // 缩进  2
    [
        {
            color: [
                "#ffffff",
                "#ffd7d5",
                "#ffdaa9",
                "#fffed5",
                "#d4fa00",
                "#73fcd6",
                "#a5c8ff",
                "#ffacd5",
                "#ff7faa",
                "#d6d6d6",
                "#ffacaa",
                "#ffb995",
                "#fffb00",
                "#73fa79",
                "#00fcff",
                "#78acfe",
                "#d84fa9",
                "#ff4f79",
                "#b2b2b2",
                "#d7aba9",
                "#ff6827",
                "#ffda51",
                "#00d100",
                "#00d5ff",
                "#0080ff",
                "#ac39ff",
                "#ff2941",
                "#888888",
                "#7a4442",
                "#ff4c00",
                "#ffa900",
                "#3da742",
                "#3daad6",
                "#0052ff",
                "#7a4fd6",
                "#d92142",
                "#000000",
                "#7b0c00",
                "#F56C6C",
                "#d6a841",
                "#407600",
                "#007aaa",
                "#021eaa",
                "#797baa",
                "#ab1942"
            ]
        },
        {
            background: [
                "#ffffff",
                "#ffd7d5",
                "#ffdaa9",
                "#fffed5",
                "#d4fa00",
                "#73fcd6",
                "#a5c8ff",
                "#ffacd5",
                "#ff7faa",
                "#d6d6d6",
                "#ffacaa",
                "#ffb995",
                "#fffb00",
                "#73fa79",
                "#00fcff",
                "#78acfe",
                "#d84fa9",
                "#ff4f79",
                "#b2b2b2",
                "#d7aba9",
                "#ff6827",
                "#ffda51",
                "#00d100",
                "#00d5ff",
                "#0080ff",
                "#ac39ff",
                "#ff2941",
                "#888888",
                "#7a4442",
                "#ff4c00",
                "#ffa900",
                "#3da742",
                "#3daad6",
                "#0052ff",
                "#7a4fd6",
                "#d92142",
                "#000000",
                "#7b0c00",
                "#F56C6C",
                "#d6a841",
                "#407600",
                "#007aaa",
                "#021eaa",
                "#797baa",
                "#ab1942"
            ]
        }
    ], // 字体颜色、字体背景颜色
    [{ size: ["small", false, "large", "huge"] }], // 字体大小 2
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    [{ font: [] }], // 字体种类 2
    [{ direction: "rtl" }], // 文本方向  2
    [{ align: [] }], // 对齐方式 2
    ["clean"], // 清除文本格式
    ["link", "image", "video"] // 链接、图片、视频
]

export default {
    inject: ["reload"],
    props: {
        /*编辑器的内容*/
        value: null,
        /*图片大小*/
        maxSize: {
            type: Number,
            default: 4000 //kb
        }
    },
    components: {
        quillEditor
    },
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            diseaseIdsid: "",
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            serverUrl: URL + "api/management/FileUpload/UploadFiles",
            name: "",
            mm: "",
            options: [],
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            petBreedDetailList: [],
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            form: {
                articleTitle: "",
                articleContent: "",
                firstPic: "",
                weight: "",
                enabled: ""
            },
            editorOption: {
                theme: "snow", // or 'bubble'
                placeholder: "您想说点什么？",
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            image: function (value) {
                                if (value) {
                                    // 触发input框选择图片文件
                                    document
                                        .querySelector(".avatar-uploader input")
                                        .click()
                                } else {
                                    this.quill.format("image", false)
                                }
                            }
                        }
                    }
                }
            },
            diseaseId: [],
            dialogFormVisible: false,
            diseaseName: "",
            petType: "",
            currentPage: 1,
            pageSize: 100,
            rules: {
                articleTitle: [
                    {
                        required: true,
                        message: "请输入文章标题",
                        trigger: "blur"
                    }
                ],
                articleContent: [
                    {
                        required: true,
                        message: "请选输入文章内容",
                        trigger: "blur"
                    }
                ],
                weight: [
                    { required: true, message: "请输入权重", trigger: "blur" }
                ],
                firstPic: [
                    {
                        required: true,
                        message: "请上传文章图片",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // this.num = this.$route.path.split('/')[2]
        // this.petBreedId = this.$route.path.split('/')[3]
        // let num1 = this.$route.path.split('/')[3]

        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "文章新增"
            this.form = {}
            // this.flag4 = false;
        } else if (this.num == 1) {
            this.name = "文章详情"
            this.flag = true
            this.isMax = true
            //  document.getElementsByClassName(".ql-toolbar").styte("none")
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "文章修改"
            this.getArticleDetail()
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val
            // console.log(this.multipleSelection);
        },
        onEditorBlur() {
            //失去焦点事件
        },
        onEditorFocus(event) {
            //获得焦点事件
        },
        onEditorChange({ editor, html, text }) {
            this.content = html
            //内容改变事件
            this.$emit("textareaData", this.content)
        },
        uploadSuccess(res, file) {
            // res为图片服务器返回的数据
            // 获取富文本组件实例
            let quill = this.$refs.myQuillEditor.quill
            // 如果上传成功
            // if (res.error == 0) {
            // 获取光标所在位置
            let length = quill.getSelection().index
            // 插入图片  res.url为服务器返回的图片地址
            quill.insertEmbed(
                length,
                "image",
                URL + "uploads/allFiles/" + res.id,
                // "http://demo4.dongliankeji.com/uploads/allFiles/" + res.id,
                {
                    width: "1000px",
                    height: "100%"
                }
            )
            // 调整光标到最后
            quill.setSelection(length + 1)
            // } else {
            // this.$message.error("图片插入失败");
            // }
        },
        // 富文本图片上传前
        beforeUpload() {},
        // 富文本图片上传失败
        uploadError() {
            this.$message.error("图片插入失败")
        },
        async onSubmit(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        // fileGuid: this.form.fileGuid,
                        enabled: this.form.enabled
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.insertArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增文章信息成功")
                        this.$router.push({ path: "/GetArticleList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleId: this.articleId,
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        enabled: this.form.enabled
                    }
                    // console.log(diseaseIds)
                    const { data: res } = await this.$http.put(
                        this.$url.updateArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改文章信息成功")
                        this.$router.push({ path: "/GetArticleList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async getArticleDetail() {
            await this.$http
                .get(this.$url.getArticleDetail(this.articleId))
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        //   if (res.data.dtList[0].diseaseIds != "") {
                        //     this.diseaseIds = res.data.dtList[0].diseaseIds.split(",");
                        //     console.log(this.diseaseIds);
                        //   }
                        var uuu = {
                            url:
                                URL +
                                "uploads/allFiles/" +
                                // "http://demo4.dongliankeji.com/uploads/allFiles/" +
                                this.form.firstPic
                        }
                        // this.picture.push(uuu);
                        if (
                            this.form.firstPic == "" ||
                            this.form.firstPic == null
                        ) {
                            this.picture = []
                            this.isMax = false
                        } else if (
                            this.form.firstPic != "" ||
                            this.form.firstPic != null
                        ) {
                            this.picture.push(uuu)
                            this.isMax = true
                        }
                        if (this.num == 1) {
                            if (
                                this.form.firstPic == "" ||
                                this.form.firstPic == null
                            ) {
                                this.isMax = true
                            }
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        success(response) {
            this.form.firstPic = response.id
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.success("最多只能上传一张图片!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        handleBeforeRemove(file, fileList) {
            var mm = file.url.slice(47)
            this.mm = mm
            // alert(this.mm)
            return this.$confirm(`确定移除 ？`).then(() => {
                // this.$message.success("已移除");
                var data = {
                    firstPic: mm,
                    moduleName: "PetBreed"
                }
                this.$http.delete(
                    this.$url.deleteOneFile(mm, data.moduleName),
                    {
                        data
                    }
                )
                if (res.msgCode == 1) {
                    this.$message.success("删除成功!")
                    this.isMax = false

                    this.form.firstPic = ""
                } else {
                    this.$message.success(res.message)
                }
            })
        },
        cancel() {
            this.$router.push({ path: "/GetArticleList" })
        },
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                })
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].reportId)
                        }
                        this.$http
                            .delete(this.$url.deleteReport, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                this.loading = false
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.reload()
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.data.message
                                    })
                                }
                            })
                    })
                    .catch((err) => {})
            }
        },
        handleDelete(index) {
            var data1 = [index.reportId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.loading = true
                    this.$http
                        .delete(this.$url.deleteReport, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.reload()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {})
        },
        alertInsert() {
            this.dialogFormVisible = true
        },
        edit() {
            this.diseaseIds.push()
            // console.log(this.diseaseIds)
            this.dialogFormVisible = false
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>