// let URL = "http://10.0.0.169:8080/"; //本地地址
// let URL = "http://82.157.18.61/"; //测试地址
let URL = "https://www.dlznt.com/"; //外网地址
export default {
  boyImg: URL + "img/boy.png", //男生默认头像
  girlImg: URL + "img/girl.png", //女生默认头像
  UploadFiles: URL + "api/pc/FileUpload/UploadFiles", // 上传文件
  UploadPics: URL + "api/pc/FileUpload/UploadPics", // 上传图片
  UploadHouses: URL + "api/pc/FileUpload/UploadHouses",//实勘、钥匙、证件图片上传
  qianyueImage: URL + "uploads/contracts/",//签约图片显示路径
  UploadAvatars: URL + "api/pc/FileUpload/UploadAvatars", //头像上传
  UploadContracts: URL + "api/pc/FileUpload/UploadContracts", //合同上传
  UploadHousestolet: URL + "api/pc/FileUpload/UploadHousestolet", //出租上传
  UploadHousestosell: URL + "api/pc/FileUpload/UploadHousestosell", //出售上传
  UploadLoupan: URL + "api/pc/FileUpload/UploadLoupan", //楼盘上传
  UploadNewhouses: URL + "api/pc/FileUpload/UploadNewhouses", //新房上传
  UploadOthers: URL + "api/pc/FileUpload/UploadOthers", //其他文件上传

  picSrc: URL + "uploads/allFiles/", // 图片预览

  avatarsPicSrc: URL + "uploads/avatars/", //头像预览
  docsPicSrc: URL + "uploads/docs/", // 除图片之外的预览
  // housesToletPicSrc: URL + "uploads/housestolet/", // 房源出租图片预览
  // housesToSellPicSrc: URL + "uploads/housestosell/", //房源出售图片预览
  houses: URL + "uploads/houses/", //房源（出售、出售）图片预览
  loupanPicSrc: URL + "uploads/loupan/", //楼盘图片预览
  newHousesPicSrc: URL + "uploads/newhouses/", //新房图片预览
  JobAccountFileSrc: URL + "uploads/exportFiles/", //作业统计导出
  othersPicSrc: URL + "uploads/others/", //商户店铺预览

  DeleteOneFile: URL + "api/pc/FileUpload/DeleteOneFile", // 单张图片删除

  Get_weiXinGeneratingCode: URL + "api/pc/House/GetWeiXinGeneratingCode", // 获取小程序码
  xcxQrPic: URL + "uploads/xcxQrPic/", // 小程序码图片链接

  login: (mobile, password, fp) =>
    URL + `api/pc/Home/Login/${mobile}/${password}/${fp}`, // 登陆
  ResetPassword: (mobile, password, verificationCode) =>
    URL + `api/pc/Home/ResetPassword/${mobile}/${password}/${verificationCode}`, // 找回密码
  updatePassword: (oldPassword, newPassword) =>
    URL + `api/pc/Home/UpdatePassword/${oldPassword}/${newPassword}`, // 修改密码
  GetVerificationCode: URL + "api/pc/Home/GetVerificationCode", // 获取短信验证码
  UpdateUserInfo: URL + "api/pc/Home/UpdateUserInfo", // 修改个人信息
  getLogs: (startTime, endTime, currentPage, pageSize) =>
    URL +
    `api/management/Log/GetLogList/${startTime}/${endTime}/${currentPage}/${pageSize}`,

  // 人员管理
  InsertUser: URL + "api/pc/user/InsertUser", //人员管理新增
  GetUserList: URL + "api/pc/user/GetUserList", //人员管理列表查询
  GetUserDetail: URL + "api/pc/user/GetUserDetail", //人员管理列表查询
  UpdateUser: URL + "api/pc/user/UpdateUser", //人员管理更新
  DeleteUser: URL + "api/pc/user/DeleteUser", //人员管理删除
  UpdateUserCurrentBasicSalary: URL + "api/pc/User/UpdateUserCurrentBasicSalary", //基本工资更新

  GetUserListInSameMerchant: URL + "api/pc/user/GetUserListInSameMerchant", //同商户的用户
  SetUserDisabled: URL + "api/pc/user/SetUserDisabled", //人员管理分配经纪人
  ResetUserPassword: URL + "api/pc/User/ResetUserPassword", //人员管理重置密码

  // 商圈、交易权属、房屋用途、客户标签、户型、 物业类型、 付佣方式、 信息来源、装修状况、建筑结构
  GetCommonMasterList: URL + "api/pc/Master/GetCommonMasterList", //列表查询
  GetCommonMasterDetail: URL + "api/pc/Master/GetCommonMasterDetail", //详情
  InsertCommonMaster: URL + "api/pc/Master/InsertCommonMaster", //新增
  UpdateCommonMaster: URL + "api/pc/Master/UpdateCommonMaster", //修改
  DeleteCommonMaster: URL + "api/pc/Master/DeleteCommonMaster", //删除

  // 楼盘字段管理
  InsertLouPan: URL + "api/pc/LouPan/InsertLouPan", // 楼盘新增
  GetLouPanList: URL + "api/pc/LouPan/GetLouPanList", // 楼盘列表查询
  GetLouPanDetail: URL + "api/pc/LouPan/GetLouPanDetail", // 楼盘详情查询
  UpdateLouPan: URL + "api/pc/LouPan/UpdateLouPan", // 楼盘更新
  DeleteLouPan: URL + "api/pc/LouPan/DeleteLouPan", // 楼盘删除
  // 楼栋字段管理
  InsertLouDong: URL + "api/pc/LouDong/InsertLouDong", // 楼栋新增
  InsertLouDongBatch: URL + "api/pc/LouDong/InsertLouDongBatch", // 楼栋新增(批量新增)
  GetLouDongList: URL + "api/pc/LouDong/GetLouDongList", // 楼栋列表查询
  GetLouDongDetail: URL + "api/pc/LouDong/GetLouDongDetail", // 楼栋详情查询
  UpdateLouDong: URL + "api/pc/LouDong/UpdateLouDong", // 楼栋更新
  DeleteLouDong: URL + "api/pc/LouDong/DeleteLouDong", // 楼栋删除
  DeleteMenPaiHao: URL + "api/pc/LouDong/DeleteMenPaiHao", // 门牌号删除
  //二手房
  HouseList: URL + "api/pc/House/HouseList", // 二手房列表
  InsertHouse: URL + "api/pc/House/InsertHouse", //新增出租房源
  UpdateHouse: URL + "api/pc/House/UpdateHouse", //修改出租房源
  GetHouseDetail: URL + "api/pc/House/GetHouseDetail", //新增出租房源
  UpdateHouseMaintenance: URL + "api/pc/House/UpdateHouseMaintenance", //修改维护信息
  UpdateHouseShiKan: URL + "api/pc/House/UpdateHouseShiKan", //修改实勘信息
  NotConfirmHouse: URL + "api/pc/House/NotConfirmHouse",//审核不通过
  SubmitHouseFavorite: URL + "api/pc/House/SubmitHouseFavorite", //关注房源
  ConfirmHouse: URL + "api/pc/House/ConfirmHouse", //审核房源
  UpdateHouseEnabled: URL + "api/pc/House/UpdateHouseEnabled", //无效房源
  CheckHouseExist: URL + "api/pc/House/CheckHouseExist", // 检查重复,
  GetHouseDetailDaiKan: URL + "api/pc/House/GetHouseDetailDaiKan", //带看记录列表
  GetHouseDetailGenJin: URL + "api/pc/House/GetHouseDetailGenJin", //跟进记录列表
  GetHouseDetailPriceRecord: URL + "api/pc/House/GetHouseDetailPriceRecord", //调价记录
  GetHouseDetailBrowsingHistory: URL + "api/pc/House/GetHouseDetailBrowsingHistory", //浏览房源记录
  UpdateHouseIsChengJiao: URL + "api/pc/House/UpdateHouseIsChengJiao",//房源是否成交

  //客源
  GetCustomerList: URL + "api/pc/Customer/CustomerList", // 求购求租列表
  GetCustomerDetail: URL + "api/pc/Customer/GetCustomerDetail", // 求购求租详情
  InsertCustomer: URL + "api/pc/Customer/InsertCustomer", // 新增求购求租
  UpdateCustomer: URL + "api/pc/Customer/UpdateCustomer", // 修改求购求租
  InsertRequirementToBuy: URL + "api/pc/Requirement/InsertRequirementToBuy", // 新增求购需求
  InsertRequirementToRent: URL + "api/pc/Requirement/InsertRequirementToRent", // 新增求租需求
  UpdateRequirementToBuy: URL + "api/pc/Requirement/UpdateRequirementToBuy", // 修改求购需求
  UpdateRequirementToRent: URL + "api/pc/Requirement/UpdateRequirementToRent ", //修改求租需求
  DeleteRequirement: URL + "api/pc/Requirement/DeleteRequirement", // 删除需求
  RecommendToUser: URL + "api/pc/Customer/RecommendToUser", // 推荐给经纪人
  CancelRecommend: URL + "api/pc/Customer/CancelRecommend", // 取消合作经纪人
  UpdateCustomerEnabled: URL + "api/pc/Customer/UpdateCustomerEnabled", // 无效客源
  CheckCustomerExist: URL + "api/pc/Customer/CheckCustomerExist", // 客源检查重复
  GetcustomerEnquiryList: URL + "api/pc/CustomerEnquiry/GetcustomerEnquiryList", //客户咨询列表
  DeleteCustomer: URL + "api/pc/Customer/DeleteCustomer", //客户删除
  DeleteCustomerEnquiry: URL + "api/pc/CustomerEnquiry/DeleteCustomerEnquiry", //客户咨询删除
  DeleteCustomerESign: URL + "api/pc/ESign/DeleteCustomerESign", //删除E签宝信息

  CustomerPoolList: URL + "api/pc/Customer/CustomerPoolList", // 客源共享池列表
  UpdateCustomerPoolRenLing: URL + "api/pc/Customer/UpdateCustomerPoolRenLing", // 客源共享池认领
  GetAssignmentCustomerList: URL + "api/pc/Customer/GetAssignmentCustomerList", // 客源分配列表
  UpdateAssignmentCustomer: URL + "api/pc/Customer/UpdateAssignmentCustomer", //客源分配
  GetAssignmentHistoryCustomer: URL + "api/pc/Customer/GetAssignmentHistoryCustomer", //客源已分配

  //客源-跟进记录
  GetGenJinList: URL + "api/pc/GenJin/GetGenJinList", // 跟进记录列表
  InsertGenJin: URL + "api/pc/GenJin/InsertGenJin", // 新增跟进记录
  UpdateGenJin: URL + "api/pc/GenJin/UpdateGenJin", // 修改跟进记录
  DeleteGenJin: URL + "api/pc/GenJin/DeleteGenJin", // 删除跟进记录

  // 客源-带看记录
  GetDaiKanList: URL + "api/pc/DaiKan/GetDaiKanList",
  InsertDaiKan: URL + "api/pc/DaiKan/InsertDaiKan",
  UpdateDaiKan: URL + "api/pc/DaiKan/UpdateDaiKan",
  DeleteDaiKan: URL + "api/pc/DaiKan/DeleteDaiKan",
  GetDaiKanMan: URL + "api/pc/DaiKan/GetDaiKanMan",

  // 客源-操作记录
  GetActivityRecordList: URL + "api/pc/Customer/GetActivityRecordList",

  // 签约
  GetContractList: URL + "api/pc/Contract/GetContractList", // 签约列表
  InsertContract: URL + "api/pc/Contract/InsertContract", // 新增签约（平台管理员【要选店铺和商户】，店东【选店铺，不需要选商户】,经纪人两个角色）
  UpdateContract: URL + "api/pc/Contract/UpdateContract", // 修改签约（平台管理员【要选店铺和商户】，店东【选店铺，不需要选商户】,经纪人两个角色）
  InsertOfflineContract: URL + "api/pc/Contract/InsertOfflineContract", // 新增签约线下合同（平台管理员【要选店铺和商户】，店东【选店铺，不需要选商户】,经纪人两个角色）
  GetContractDetail: URL + "api/pc/Contract/GetContractDetail", // 签约详情
  DeleteContract: URL + "api/pc/Contract/DeleteContract", // 删除签约
  DeleteContract: URL + "api/pc/Contract/DeleteContract", // 删除签约
  ESignUploadFile: "api/pc/ESign/UploadFile", // e签宝上传文件
  TransferFileToESign: URL + "api/pc/ESign/TransferFileToESign", // 上传文件
  contractsPicSrc: URL + "uploads/contracts/", //合同预览
  FlowOneStepESign: URL + "api/pc/ESign/FlowOneStepESign", // e签宝一步发起签署
  PostTestBase64: URL + "api/pc/ESign/PostTestBase64", // e签宝一步发起签署
  PaymentComplete: URL + "api/pc/Contract/PaymentComplete", // 付款完成
  ConfirmComplete: URL + "api/pc/Contract/ConfirmComplete", // 合同审核
  OwnerComplete: URL + "api/pc/Contract/OwnerComplete", // 过户完成
  OfflineFlowComplete: URL + "api/pc/Contract/OfflineFlowComplete", // 线下过户完成
  FlowComplete: URL + "api/pc/Contract/FlowComplete", // 交易完成
  UpdateContractDraft: URL + "api/pc/Contract/UpdateContractDraft", // 草稿
  GetContractFinancialList: URL + "api/pc/Contract/GetContractFinancialList", //列表
  DeleteOneContractFinancial: URL + "api/pc/Financial/DeleteOneContractFinancial", //删除列表
  AddPerformanceAssignment: URL + "api/pc/Financial/AddPerformanceAssignment", //客源端业绩分成
  AddFileIdsToFinancialId: URL + "api/pc/Financial/AddFileIdsToFinancialId", //上传交易凭证
  GetChengJiaoJiLu: URL + "api/pc/Contract/GetChengJiaoJiLu", //成交记录
  RushSignESign: URL + "api/pc/ESign/RushSignESign", //催签

  //财务
  InsertFinancial: URL + "api/pc/Financial/InsertFinancial", //新增入账路径
  GetFinancialList: URL + "api/pc/Financial/GetFinancialList", //入账列表路径
  GetFinancialDetail: URL + "api/pc/Financial/GetFinancialDetail", //详情
  ConfirmFinancial: URL + "api/pc/Financial/ConfirmFinancial", //审批
  InsertFuiouOrder: URL + "api/pc/FuiouPay/InsertFuiouOrder",
  GetFinancialReceipt: URL + "api/pc/Financial/GetFinancialReceipt", //查询收据信息
  InsertFinancialReceipt: URL + "api/pc/Financial/InsertFinancialReceipt", //收据新增
  ContractInsertFinancial: URL + "api/pc/Financial/ContractInsertFinancial", ////新增入账路径（新）
  // 日志列表
  GetLogList: URL + "api/pc/Log/GetLogList", //获取日志列表
  GetCheckTelExists: URL + "api/pc/Customer/GetCheckTelExists", //验证电话号码是否存在

  // 政策文件 规章制度 培训课程 空白协议模板 公告管理
  GetArticleList: URL + "api/pc/Article/GetArticleList", // 获取列表
  GetArticleDetail: URL + "api/pc/Article/GetArticleDetail", // 获取详情
  InsertArticle: URL + "api/pc/Article/InsertArticle", // 新增
  UpdateArticle: URL + "api/pc/Article/UpdateArticle", // 修改
  DeleteArticle: URL + "api/pc/Article/DeleteArticle", // 删除

  // 提成比设置
  GetRatioConfigList: URL + "api/pc/RatioConfig/GetRatioConfigList", // 获取列表
  // GetArticleDetail: URL + "api/pc/RatioConfig/GetArticleDetail", // 获取详情
  // InsertArticle: URL + "api/pc/RatioConfig/InsertArticle", // 新增
  // UpdateArticle: URL + "api/pc/RatioConfig/UpdateArticle", // 修改
  // DeleteArticle: URL + "api/pc/RatioConfig/DeleteArticle", // 删除

  //消息中心
  GetMessageList: URL + "api/pc/Message/GetMessageList", //获取列表
  GetMessageDetail: URL + "api/pc/Message/GetMessageDetail", //获取详情
  DeleteMessage: URL + "api/pc/Message/DeleteMessage", //删除
  UpdateReadMessages: URL + "api/pc/Message/UpdateReadMessages", //设置已读消息

  // 经纪人通讯录
  GetJingJiRenList: URL + "api/pc/User/GetJingJiRenList",
  SubmitThumbUp: URL + "api/pc/ThumbUp/SubmitThumbUp", // 点赞
  //店铺
  GetShopList: URL + "api/pc/Shop/GetShopList", //获取列表
  GetShopDetail: URL + "api/pc/Shop/GetShopDetail", // 获取详情
  InsertShop: URL + "api/pc/Shop/InsertShop", // 新增
  UpdateShop: URL + "api/pc/Shop/UpdateShop", // 修改
  DeleteShop: URL + "api/pc/Shop/DeleteShop", // 删除店铺

  // 商户
  GetMerchantList: URL + "api/pc/Merchant/GetMerchantList", //获取列表
  GetMerchantDetail: URL + "api/pc/Merchant/GetMerchantDetail", // 获取详情
  InsertMerchant: URL + "api/pc/Merchant/InsertMerchant", // 新增
  UpdateMerchant: URL + "api/pc/Merchant/UpdateMerchant", // 修改
  DeleteMerchant: URL + "api/pc/Merchant/DeleteMerchant", // 删除商户
  HousePoolList: URL + "api/pc/House/HousePoolList",
  UpdateHousePoolRenLing: URL + "api/pc/House/UpdateHousePoolRenLing",
  DeleteHousePool: URL + "api/pc/House/DeleteHousePool",

  //新房
  NewHouseList: URL + "api/pc/NewHouse/NewHouseList", //新房列表
  InsertNewHouse: URL + "api/pc/NewHouse/InsertNewHouse", //新增新房
  UpdateNewHouse: URL + "api/pc/NewHouse/UpdateNewHouse", //修改新房
  GetNewHouseDetail: URL + "api/pc/NewHouse/GetNewHouseDetail", //新房详情
  DeleteNewHouse: URL + "api/pc/NewHouse/DeleteNewHouse", //删除新房
  ConfirmNewHouse: URL + "api/pc/NewHouse/ConfirmNewHouse", //新房审核 （roleType == 2 || roleType == 5）
  InsertNewHouseHuXing: URL + "api/pc/NewHouse/InsertNewHouseHuXing", //新增户型
  UpdateNewHouseHuXing: URL + "api/pc/NewHouse/UpdateNewHouseHuXing", //修改户型
  DeleteNewHouseHuXing: URL + "api/pc/NewHouse/DeleteNewHouseHuXing", //删除户型
  SubmitNewHouseFavorite: URL + "api/pc/NewHouse/SubmitNewHouseFavorite", //关注房源
  UpdateNewHouseEnabled: URL + "api/pc/NewHouse/UpdateNewHouseEnabled", //无效房源

  // 组织结构
  GetCompanyStructureTree: URL + "api/pc/CompanyStructure/GetCompanyStructureTree", //获取树形结构列表
  // 组织结构
  GetJobAccountList: URL + "api/pc/JobAccount/GetJobAccountList",

  //   公告管理
  //   GetNoticeList: URL + "api/pc/Notice/GetNoticeList", // 获取列表
  //   GetNoticeDetail: URL + "api/pc/Notice/GetNoticeDetail", //获取详情
  //   InsertNotice: URL + "api/pc/Notice/InsertNotice", // 新增
  //   UpdateNotice: URL + "api/pc/Notice/UpdateNotice", // 修改
  //   DeleteNotice: URL + "api/pc/Notice/DeleteNotice", // 删除
  GetNoticeDisplayGroup: URL + "api/pc/Article/GetNoticeDisplayGroup", // 获取商户和店铺列表
  GetArticleDisplayGroup: URL + "api/pc/Article/GetArticleDisplayGroup", //获取群组
  // 首页列表
  GetHomepageInfo: URL + "api/pc/Home/GetHomepageInfo",
  GetToDoConfirmList: URL + "api/pc/Home/GetToDoConfirmList", // 待办事项
  GetPerformanceStatistics: URL + "api/pc/Home/GetPerformanceStatistics", // 业绩echart
  // 工作日报
  GetDailyReportList: URL + "api/pc/DailyReport/GetDailyReportList", //获取列表
  GetDailyReportDetail: URL + "api/pc/DailyReport/GetDailyReportDetail", //获取详情
  InsertDailyReport: URL + "api/pc/DailyReport/InsertDailyReport", //新增
  UpdateDailyReport: URL + "api/pc/DailyReport/UpdateDailyReport", // 修改
  DeleteDailyReport: URL + "api/pc/DailyReport/DeleteDailyReport", //删除
  // 分配房源
  GetAssignmentList: URL + "api/pc/House/GetAssignmentList", // 列表
  UpdateHouseAssignment: URL + "api/pc/House/UpdateHouseAssignment", // 分配
  GetAssignmentHistory: URL + "api/pc/House/GetAssignmentHistory", // 分配历史
  //工资表
  GetSalaryList: URL + "api/pc/Salary/GetSalaryList", // 工资表列表
  InsertSalary: URL + "api/pc/Salary/InsertSalary", // 新增工资表
  GetSalaryDetail: URL + "api/pc/Salary/GetSalaryDetail", // 工资表详情
  UpdateSalary: URL + "api/pc/Salary/UpdateSalary", // 工资表修改
  ConfirmSalary: URL + "api/pc/Salary/ConfirmSalary", // 工资表审核
  GenerateMonthlySalary: URL + "api/pc/Salary/GenerateMonthlySalary", // 批量生成月工资
  DeleteSalary: URL + "api/pc/Salary/DeleteSalary", //删除工资
  ExportSalaryListExcel: URL + "api/pc/Salary/ExportSalaryListExcel", //导出工资
  //业绩列表
  GetPerformanceList: URL + "api/pc/Performance/GetPerformanceList", //业绩列表
  GetPerformanceDetail: URL + "api/pc/Performance/GetPerformanceDetail", //业绩详情
  InsertPerformance: URL + "api/pc/Performance/InsertPerformance", //线下合同新增
  ExportJobAccountExcel: (dateFrom, dateTo, searchShopId) =>
    URL + `api/pc/JobAccount/ExportJobAccountExcel/${dateFrom}/${dateTo}/${searchShopId}` //导出作业统计
};
