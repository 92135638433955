<template>
    <div>
        <div>
            <!-- 中间卡片视图区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span class="title">店铺：</span>
                        <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
                        <span style="float: right;">
                            <el-button size="mini" style="margin-left: 20px;" type="primary" @click="cgetDianPuList">查询</el-button>
                            <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 3px;height: auto;">
                    <el-col :span="0.9">
                        <span class="title">商圈:</span>
                    </el-col>
                    <el-col :span="20">
                        <el-tabs v-model="list" style="margin-left:0%;" type="card" @tab-click="handleClick">
                            <el-tab-pane>
                                <span slot="label" @click="gobuxian">不限</span>
                            </el-tab-pane>
                            <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :label="item.name">
                                <el-checkbox-group :max="3" style="font-size: 14px;float:left;margin:5px;" v-model="daQuInclude">
                                    <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{item1.name}}</el-checkbox>
                                </el-checkbox-group>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>

                <el-row style="margin-top: 10px" v-if="MerchantFalse&&!isQianyueOpen">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
                        <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable" @row-click="xzDianPu"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <el-table-column type="selection" width="40"> </el-table-column>
                    <el-table-column label="店铺" prop="shopName">
                        <template slot-scope="scope">

                            <el-button v-if="isQianyueOpen || isCaiWu" class="tableButSpan" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.shopName }}</el-button>
                            <el-button v-else class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.shopName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户" prop="merchantName"></el-table-column>
                    <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
                    <el-table-column label="法人代表" prop="bossName"></el-table-column>
                    <el-table-column label="是否可用" prop="enabled"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column label="操作" v-if="MerchantFalse&&!isQianyueOpen&&!isCaiWu">
                        <template slot-scope="scope" class="right">
                            <!-- {{ scope.row }} -->
                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            ShopName: "",
            userid: localStorage.getItem("userId"),
            list: "",
            drow: "",
            loading: false,
            total: 0,
            operationList: [
                {
                    shopName: "无名缘米粉",
                    createTime: "2020-2-2",
                    areaNames: "开发区/东山"
                }
            ],
            areaIds: "",
            shopName: "",
            merchantId: "",
            currentPage: 1,
            pageSize: 10,
            enabled: "",
            options: [
                {
                    value: "1",
                    label: "是"
                },
                {
                    value: "0",
                    label: "否"
                }
            ],
            regionList1: [
                {
                    name: "",
                    includeShangQuan: [{ name: "", areaIds: "" }]
                }
            ],
            daQuInclude: [],
            roleType: "",
            MerchantFalse: false
        }
    },
    props: {
        isQianyueOpen: {
            //签约打开
            type: Boolean,
            default: false
        },
        isCaiWu: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.roleType = localStorage.getItem("roleType")
        if (this.roleType == 6) {
            this.MerchantFalse = true
        }
        /* 获取所有的日志列表 */
        this.getDianPuList()
        this.$commonJs.getShuJuZiDianList("SHANGQUAN").then((data) => {
            this.regionList1 = data.shangquan
        })
    },
    methods: {
        gobuxian() {
            this.daQuInclude = ""
        },
        xzDianPu(row) {
            if (this.isQianyueOpen ||this.isCaiWu) {
                this.$emit("colseDianPuDialog", row)
            }
        },
        // 查询
        cgetDianPuList() {
            this.currentPage = 1
            this.getDianPuList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "DianPuDetail",
                query: {
                    shopId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "DianPuDetail",
                query: {
                    shopId: row.shopId,
                    num: 1,
                    state: 2,
                    merchantId: row.merchantId
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getDianPuList() {
            var daQuInclude = ""
            if (this.daQuInclude == "") {
                daQuInclude = ""
            } else {
                daQuInclude = this.daQuInclude.join(",")
            }
            const data = {
                areaIds: daQuInclude, //商圈id
                shopName: this.shopName,
                merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                // this.$message.success("获取店铺成功")
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                for (var i = 0; i < res.dtList.length; i++) {
                    if (res.dtList[i].enabled === "1") {
                        this.operationList[i].enabled = "是"
                    }
                    if (res.dtList[i].enabled === "0") {
                        this.operationList[i].enabled = "否"
                    }
                }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getDianPuList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getDianPuList()
        },
        //重置
        cancelDianPuList() {
            this.daQuInclude = []
            this.shopName = ""
            this.currentPage = 1
            this.getDianPuList()
        },
        // 删除数据
        async handleDelete(row) {
            var data1 = [row.shopId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteShop, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getDianPuList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        // 删除数据
        async handleDelete1() {
            var data1 = this.multipleSelection
            var data2 = []
            if (this.multipleSelection == undefined) {
                this.$confirm("请勾选数据", "提示", {
                    type: "warning"
                }).catch((err) => {})
            } else {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        for (var i = 0; i < data1.length; i++) {
                            data2.push(data1[i].shopId)
                        }
                        const { data: res } = this.$http
                            .delete(this.$url.DeleteShop, {
                                data: data2,
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                            .then((res) => {
                                if (res.data.msgCode == 1) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功!"
                                    })
                                    this.getDianPuList()
                                    this.reload()
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err
                        })
                    })
            }
        },
        xiangqing(index, row) {
            this.drow = row.shopId
            this.$router.push({
                name: "DianPuDetail",
                query: {
                    shopId: this.drow,
                    num: 2,
                    state: 3,
                    merchantId: row.merchantId
                }
            })
        },
        handleClick(tab, event) {
            // this.daQuInclude = []
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
// 商圈
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
}
// /deep/.business {
//     border: 0 !important;
//     height: 21px !important;
//     line-height: 23px !important;
// }
/deep/ .el-tabs__nav > .is-active {
    color: red !important;
    background: none !important;
    height: 20px;
    // z-index: 1111;
}
/deep/ .el-tabs__header {
    margin: 0;
}
.el-checkbox {
    margin-left: 10px;
}
.business {
    background: #fff;
    // border: 1px solid #ccc;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0 !important;
}
/deep/ .el-tabs__nav .is-top {
    height: 20px;
    line-height: 20px;
    border: 0;
    padding: 0;
    margin-left: 2% !important;
}
/deep/ .el-tabs--card > .el-tabs__header {
    border: 0;
}
/deep/ .el-tabs__nav .is-top {
    margin-left: 5px;
}
/deep/
    .el-tabs--top.el-tabs--card
    > .el-tabs__header
    .el-tabs__item:nth-child(2) {
    padding: 0;
}
/deep/ .el-tabs__active-bar .is-top {
    width: 0 !important;
}
</style>
