<template>
  <div>
    <!-- 中间卡片视图区域 -->
    <el-card class="search">
      <el-row>
        <el-col :span="5">
          <span class="title">楼盘：</span>
          <!-- <el-cascader v-model="fangyuanloudong" class="media-width" clearable filterable placeholder="请选择房源楼栋" :options="options" @change="handleItemChange" :props="{
      value: 'louPanId',
      label: 'louPanName',
      children: 'cities',
      checkStrictly: true
    }"></el-cascader> -->
          <el-input placeholder="楼盘名称" v-model="LouPanNameOrNo" class="media-width"></el-input>
        </el-col>
        <el-col :span="5">
          <span class="title">楼栋：</span>
          <el-input placeholder="楼栋号" v-model="GongChengLouHao" class="media-width"></el-input>
        </el-col>
        <el-col :span="5">
          <span class="title">维护人：</span>
          <el-input placeholder="维护人姓名" v-model="userName" class="media-width"></el-input>
        </el-col>
        <el-col :span="5">
          <span class="title">房源编号：</span>
          <el-input placeholder="房源编号" maxlength="8" v-model="houseNo" class="media-width"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="warning" size="mini" style="margin-left: 5px; float: right" @click="resultHouseList">重置 </el-button>
          <el-button type="primary" size="mini" style="margin-left: 15px; float: right" @click="toFind">查询</el-button>
        </el-col>
      </el-row>

      <el-row style="margin-top: 10px">
        <el-col :span="5" v-if="roleType == 6">
          <span class="title">商户：</span>
          <el-input @focus="merchantDialogTrue" placeholder="请选择商户" v-model="MerchantName" class="media-width"> </el-input>
          <!-- @input="changeInput" -->
        </el-col>
        <el-col :span="5" v-if="roleType == 6">
          <span class="title">店铺：</span>
          <el-input @focus="shopDialogTrue" placeholder="请选择店铺" readonly="readonly" v-model="ShopName" class="media-width"></el-input>
        </el-col>
        <el-col :span="8">
          <span class="title">显示未维护房源：</span>
          <el-select v-model="IsGanTanHao" placeholder="请选择">
            <el-option v-for="item in optionsTanHao" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- <hr
        id="hrOne"
        style="
          height: 1px;
          border: none;
          backgroundcolor: #eee;
          margin-top: 20px;
        "
      /> -->

      <el-row style="margin-top: 3px; height: auto">
        <span class="title">范围:</span>
        <div v-for="item in item1" :key="item.label" :value="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive1 === item.label }" @click="changeClass1(item.label)">
          {{ item.label }}
        </div>
      </el-row>
      <el-row style="margin-top: 3px; height: auto">
        <el-col :span="0.9">
          <span class="title">商圈:</span>
        </el-col>
        <el-col :span="23" style="margin-left: 3px !important">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane name="不限" label="不限" style="margin-left: 0.5% !important">
              <span slot="label">不限</span>
            </el-tab-pane>
            <el-tab-pane v-for="item in regionList1" :value="item.name" :key="item.name" :name="item.name" :label="item.name">
              <el-checkbox-group :max="3" style="font-size: 14px; float: left; margin: 5px" v-model="daQuInclude">
                <el-checkbox v-for="item1 in item.includeShangQuan" :label="item1.areaIds" :value="item.areaIds" :key="item1.areaIds">{{ item1.name }}</el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>

      <el-row style="margin-top: 3px">
        <span class="title">价格:</span>

        <div v-for="item in item3" :key="item.label" :value="item.value" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive3 === item.value }" @click="changeClass3(item.label, item.value)">
          {{ item.label }}
        </div>
        <span style="margin-left: 0.5%; font-size: 14px">
          <el-input maxlength="5" placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="priceFrom" @change="priceChange" style="width: 60px"> </el-input>
          -
          <el-input maxlength="5" placeholder="最大" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckPrice" v-model="priceTo" @change="priceChange" style="width: 60px"></el-input>
          万
        </span>
      </el-row>
      <el-row style="margin-top: 3px">
        <span class="title">面积:</span>

        <div v-for="item in item4" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive4 === item.value }" @click="changeClass4(item.label, item.value)">
          {{ item.label }}
        </div>
        <span style="margin-left: 0.5%; font-size: 14px">
          <el-input placeholder="最小" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" v-model="squareFrom" style="width: 60px"></el-input>
          -
          <el-input placeholder="最大" :maxlength="4" onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')" oninput="if(value>99999)value=99999" @blur="CheckSquare" v-model="squareTo" style="width: 60px"></el-input>
          平米
        </span>
      </el-row>
      <el-row style="margin-top: 3px;">
        <span class="title" style="float:left;">户型:</span>
        <!-- <div style="font-size: 14px;float:left;margin-left:0.75%;" class="xuanxiang2" :class="{ active: isActive5 === '不限' }" @click="changeClass5('不限','')">不限</div> -->
        <div style="font-size: 14px;float:left;margin-left:0.75%;" v-for="item in item5" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive5 === item.label }" @click="changeClass5(item.label, item.value)">
          {{ item.label }}
        </div>
        <span style="margin-left:20px;font-size:14px">
          <!-- <el-input placeholder="最小" style="width:60px;"></el-input>
                    - -->
          <el-input :disabled="flag" :maxlength="1" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" v-model="roomCount" style="width:60px;"></el-input>
          室
        </span>

        <!-- <span class="title" style="margin-left:50px">卫生间:</span> -->
        <div style="font-size: 14px;margin-left:0.75%;" v-for="item in itemWC" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActiveWC === item.label }" @click="changeClassWC(item.label, item.value)">
          {{ item.label }}
        </div>
        <span style="margin-left:20px;font-size:14px">
          <!-- <el-input placeholder="最小" style="width:60px;"></el-input>
                    - -->
          <el-input :disabled="flagWC" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" :maxlength="1" v-model="ToiletCount" style="width:60px;"></el-input>
          间
        </span>
      </el-row>
      <el-row style="margin-top: 3px">
        <span class="title">楼层:</span>

        <div v-for="item in item6" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive6 === item.value }" @click="changeClass6(item.label, item.value)">
          {{ item.label }}
        </div>
        <span style="margin-left: 20px; font-size: 14px">
          <el-input :maxlength="2" :disabled="flag1" placeholder="最小" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="louCengFrom" style="width: 60px"></el-input>
          -
          <el-input :maxlength="2" :disabled="flag1" placeholder="最大" oninput="value=value.replace(/[^0-9.]/g,'')" @blur="CheckLouCeng" v-model="louCengTo" style="width: 60px"></el-input>
          层
        </span>
      </el-row>
      <el-row style="margin-top: 3px">
        <span class="title" style="float: left">朝向:</span>
        <div style="font-size: 14px; float: left; margin-left: 0.75%" class="xuanxiang2" :class="{ active: isActive7 === '不限' }" @click="changeClass7('不限')">
          不限
        </div>
        <el-checkbox-group :max="3" style="height: 30px; font-size: 14px; float: left; margin-left: 2px" v-model="checkedActive7">
          <el-checkbox v-for="item in item7" :label="item.value" :key="item.value">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
      </el-row>
      <el-row style="margin-top: 3px">
        <span class="title">筛选:</span>
        <el-dropdown v-for="item in shaixuan" :key="item.value" style="margin-left: 0.75%" @command="handleCommand">
          <span style="font-size: 14px">{{ item.label }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu style="font-size: 14px" slot="dropdown">
            <el-dropdown-item v-for="item1 in item.shaixuanList" :command="item1.value" :key="item1.value">{{ item1.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
      <el-row v-if="show == true">
        <span class="title">房龄:</span>
        <div v-for="item in item10" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive10 === item.value }" @click="changeClass10(item.label, item.value)">
          {{ item.label }}
        </div>
      </el-row>
      <el-row v-if="show == true">
        <span class="title">可看:</span>
        <div v-for="item in item11" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive11 === item.label }" @click="changeClass11(item.label)">
          {{ item.label }}
        </div>
      </el-row>
      <el-row v-if="show == true">
        <span class="title" style="float: left">交易权属:</span>
        <div style="font-size: 14px; float: left; margin-left: 0.75%" class="xuanxiang2" :class="{ active: isActive12 === '' }" @click="changeClass12('不限', '')">
          不限
        </div>
        <div v-for="item in JiaoYiQuanShu" :key="item.label" effect="dark" class="xuanxiang2" :class="{ active: isActive12 === item.value }" @click="changeClass12(item.label, item.value)">
          {{ item.label }}
        </div>
      </el-row>
      <el-row v-if="show == true">
        <span class="title" style="float: left">建筑结构:</span>
        <div style="font-size: 14px; float: left; margin-left: 0.75%" class="xuanxiang2" :class="{ active: isActive13 === '' }" @click="changeClass13('不限', '')">
          不限
        </div>
        <div v-for="item in JianZhuJieGou" :key="item.label" :type="item.type" effect="dark" class="xuanxiang2" :class="{ active: isActive13 === item.value }" @click="changeClass13(item.label, item.value)">
          {{ item.label }}
        </div>
      </el-row>
      <el-row style="margin-top: 10px" v-if="!isKeyuanDaikan">
        <el-col>
          <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增 </el-button>
        </el-col>
      </el-row>
      <el-row>
        <span
          v-if="show == true"
          @click="showItem()"
          style="
            font-size: 12px;
            color: #ccc;
            margin-left: 45%;
            cursor: pointer;
          "
          >收起选项<span class="el-icon-caret-top"></span
        ></span>
        <span
          v-if="show == false"
          @click="showItem()"
          style="
            font-size: 12px;
            color: #ccc;
            margin-left: 45%;
            cursor: pointer;
          "
          >展开选项<span class="el-icon-caret-bottom"></span
        ></span>
      </el-row>
    </el-card>
    <!-- 底部内容区域 -->
    <el-card>
      <div style="padding-left: 10px">已为您找到{{ total }}个房源</div>
      <el-table
        @sort-change="sortChange"
        @row-click="xzFangYuan"
        ref="multipleTable"
        v-loading="loading"
        :data="HouseList"
        :cell-class-name="tableCellClassName"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
        class="matop0"
      >
        <!-- <el-table-column
          type="selection"
          width="40"
          v-if="!isKeyuanDaikan"
        ></el-table-column> -->
        <el-table-column label="标题图" prop="customerName" width="138">
          <template slot-scope="scope">
            <div v-if="scope.row.oneKeting != ''" style="overflow: hidden; max-height: 100px">
              <el-image style="width: 100%; height: 4.6vw; position: relative" :src="scope.row.oneKeting" :preview-src-list="[scope.row.oneKeting]" :key="scope.row.oneKeting">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div v-else>
              <img src="../../../public/img/zanwutupian.jpg" style="width: 100%; height: 4.6vw" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="楼盘名称" width="160px;" prop="louPanName">
          <template slot-scope="scope">
            <el-tooltip
              v-if="(scope.row.weiGenJinShiChang >= 7 && scope.row.weiDaiKanShiChang == null) || (scope.row.weiGenJinShiChang == null && scope.row.weiDaiKanShiChang >= 7) || (scope.row.weiGenJinShiChang >= 7 && scope.row.weiDaiKanShiChang >= 7)"
              class="item"
              effect="dark"
              :content="'已超过七天未维护此房源'"
              placement="right"
            >
              <div
                class="el-icon-warning"
                style="
                  color: red;
                  position: absolute;
                  right: 10px;
                  height: 36px;
                  line-height: 36px;
                  cursor: pointer;
                  z-index: 999;
                "
              ></div>
            </el-tooltip>
            <el-tooltip v-if="scope.row.weiGenJinShiChang == null && scope.row.weiDaiKanShiChang == null" class="item" effect="dark" :content="'此房源未带看，未跟进'" placement="right">
              <div
                class="el-icon-warning"
                style="
                  color: #ffab3c;
                  position: absolute;
                  right: 10px;
                  height: 36px;
                  line-height: 36px;
                  cursor: pointer;
                  z-index: 999;
                "
              ></div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" trigger="hover" :content="scope.row.louPanName" placement="top-start">
              <el-button class="tableButSpan" type="text" size="mini" style="font-size: 16px; font-weight: bold; color: #000">
                <img v-if="scope.row.isOnUrgentSale == '1'" style="width:20px;height:20px;padding-right:10px" src="../../../public/img/ji.png" />
                <span @click="openDetails2(scope.row)">{{ scope.row.louPanName }}</span
                ><br />
              </el-button>
            </el-tooltip>
            <div>
              <span style="font-size: 12px; font-weight: normal">{{ scope.row.houseNo }}</span>
              <span
                v-if="scope.row.keyUserId != null"
                style="
                  font-weight: 100;
                  font-size: 13px;
                  color: rgb(19, 177, 19);
                  margin-left: 5px;
                "
                >钥匙</span
              >
              <span
                style="
                  font-weight: 100;
                  font-size: 13px;
                  color: red;
                  margin-left: 5px;
                "
                >{{ scope.row.isConfirmed === '1' ? '已审核' : '未审核' }}</span
              >
              <span v-if="scope.row.enabled == '1'">
                <el-popover placement="bottom" trigger="manual" v-model="scope.row.visible">
                  <img v-if="sharePic != ''" :src="sharePic" />
                  <img
                    v-if="scope.row.isConfirmed === '1'"
                    src="../../../public/img/xiaochengxu.png"
                    slot="reference"
                    @click="clickWeiXinGeneratingCode(scope.row)"
                    style="
                      margin-left: 5px;
                      vertical-align: text-top;
                      width: 20px;
                      height: 20px;
                      cursor: pointer;
                    "
                  />
                </el-popover>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="roleType == 6" label="商铺名称" width="100px;" prop="merchantName"></el-table-column>
        <el-table-column v-if="roleType == 6" label="店铺名称" width="100px;" prop="shopName"></el-table-column>
        <el-table-column label="户型" prop="huXingId"></el-table-column>
        <el-table-column label="面积" sortable="custom" prop="square">
          <template slot-scope="scope">
            <span>{{ scope.row.square }}平</span>
          </template>
        </el-table-column>
        <el-table-column label="总价" sortable="custom" prop="priceToSell">
          <template slot-scope="scope">
            <span>{{ scope.row.priceToSell / 10000 }}万元</span>
          </template>
        </el-table-column>
        <el-table-column label="单价" sortable="custom" prop="priceToLet" width="110px;">
          <template slot-scope="scope">
            <span>{{ scope.row.unitPrice / 10000 }}万元/平</span>
          </template>
        </el-table-column>
        <el-table-column label="楼层" prop="louCeng">
          <template slot-scope="scope">
            <!-- <span style="cursor:pointer;" class="fontColorRed">{{scope.row.newlouCeng}}/{{scope.row.floorCount}}</span> -->
            <span>{{ scope.row.louCengDuan }}/{{ scope.row.floorCount }}</span>
            <!-- <span v-if="show1 == false" style="cursor:pointer;" class="fontColorBlack">{{cengshu}}/{{scope.row.floorCount}}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="朝向" prop="orientations">
          <template slot-scope="scope">
            {{ scope.row.orientations }}
          </template>
          <!-- <span>{{fangxiangToString}}</span> -->
        </el-table-column>
        <!-- <el-table-column label="15天带看" prop="daikan" width="105px;">
                </el-table-column> -->
        <!-- <el-table-column label="维护完成度" prop="weihu" width="120px;"> -->
        <!-- <template slot-scope="scope">
                            {{scope.row.weihu.slice(0,scope.row.weihu.length-1)}}<span :class="scope.row.weihu.charAt(scope.row.weihu.length-1) == '优' ? 'font-class-green' : 'font-class-blue'">{{scope.row.weihu.charAt(scope.row.weihu.length-1)}}</span>
                        </template> -->
        <!-- </el-table-column> -->
        <el-table-column label="挂牌" prop="createTime" width="120px;"></el-table-column>
        <el-table-column label="维护人" prop="maintenanceUserName"></el-table-column>
        <el-table-column label="操作" width="165px;" v-if="!isKeyuanDaikan">
          <template slot-scope="scope" class="right">
            <el-button
              v-if="
                (roleType == 1 && userId == scope.row.maintenanceUserId && scope.row.enabled == '1') ||
                  (roleType == 2 && scope.row.canShopEdit == 1 && scope.row.enabled == '1') ||
                  (roleType == 5 && scope.row.canMerchantEdit == 1 && scope.row.enabled == '1') ||
                  (roleType == 6 && scope.row.enabled == '1')
              "
              @click.native.stop="xiangqing(scope.$index, scope.row)"
              type="text"
              size="mini"
              class="el-icon-edit caozuo"
              style="color: #1890ff"
              >修改</el-button
            >
            <el-button v-if="((roleType == 2 && scope.row.canShopEdit == 1) || (roleType == 5 && scope.row.canMerchantEdit == 1) || roleType == 6) && scope.row.isConfirmed != 1" @click.native.stop="openDetails2(scope.row)" type="text" size="mini" class="el-icon-edit caozuo" style="color: hotpink"
              >审核</el-button
            >
            <!-- <el-button @click.native.stop="chengjiao(scope.row)" type="text" size="mini" class="el-icon-check caozuo" style="color: #f6ba03">成交</el-button> -->
            <el-button @click.native.stop="guanzhu(scope.row)" type="text" size="mini" class="el-icon-view" style="color: rgb(19, 177, 19)">{{ scope.row.favourite == '0' ? '关注' : '已关注' }}</el-button>
            <el-button
              v-if="(roleType == 1 && ((userId == scope.row.maintenanceUserId && scope.row.enabled == '0') || (localShopId == scope.row.shopId && scope.row.enabled == '0'))) || (roleType == 2 && scope.row.canShopEdit == 1) || (roleType == 5 && scope.row.canMerchantEdit == 1) || roleType == 6"
              @click.native.stop="wuXiaoFangYuan(scope.row)"
              type="text"
              size="mini"
              class="el-icon-lock caozuo"
              style="color: #909399"
              >{{ scope.row.enabled == '0' ? '有效' : '无效' }}</el-button
            >
            <!-- <el-button v-if="(roleType ==1&&userId==scope.row.maintenanceUserId)||(roleType ==2&&scope.row.canShopEdit==1)||(roleType ==5&&scope.row.canMerchantEdit==1)||roleType ==6" type="text" size="mini" class="el-icon-delete caozuo" style="color: #da0909">删除</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
          <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
          <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
        </div>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      <!-- 商户弹出层 -->
      <el-dialog :visible.sync="merchantDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
        <el-card class="search">
          <el-row>
            <el-col>
              <span class="title">商户：</span>
              <el-input v-model="merchantName" placeholder="请输入商户" style="width: 25%"></el-input>
              <span style="float: right">
                <el-button size="mini" style="margin-left: 20px" type="primary" @click="cgetMerchantList">查询</el-button>
                <el-button size="mini" @click="cancelMerchantList" type="warning">重置</el-button>
              </span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col>
              <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
              <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
            </el-col>
          </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-table
          ref="multipleTable"
          @row-click="MerchantDialogDetails"
          :data="operationList1"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px'
          }"
          :cell-style="{ 'text-align': 'center' }"
          @selection-change="handleSelectionChange"
          border
          stripe
        >
          <el-table-column label="商户">
            <template slot-scope="scope">
              <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.merchantName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="是否可用" prop="enabled"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
            <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
          </div>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="merchantCurrentPage" :page-sizes="[10, 20, 50]" :page-size="merchantPageSize" layout="total, sizes, prev, pager, next, jumper" :total="merchantDialogTotal"> </el-pagination>
      </el-dialog>
      <!-- 店铺弹出层 -->
      <el-dialog :visible.sync="shopDialog" direction="rtl" width="70%" top="4%" class="dialogCss">
        <el-card class="search">
          <el-row>
            <el-col>
              <span class="title">店铺：</span>
              <el-input v-model="shopName" placeholder="请输入店铺" style="width: 25%"></el-input>
              <span style="float: right">
                <el-button size="mini" style="margin-left: 20px" type="primary" @click="cgetDianPuList">查询</el-button>
                <el-button size="mini" @click="cancelDianPuList" type="warning">重置</el-button>
              </span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px" v-if="roleType == 6">
            <el-col>
              <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus">新增</el-button>
              <el-button size="mini" @click="handleDelete1()" type="warning" icon="el-icon-delete">删除</el-button>
            </el-col>
          </el-row>
        </el-card>
        <!-- 底部内容区域 -->
        <el-table
          ref="multipleTable"
          @row-click="ShopDialogDetails"
          :data="operationList2"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px'
          }"
          :cell-style="{ 'text-align': 'center' }"
          @selection-change="handleSelectionChange"
          border
          stripe
        >
          <el-table-column label="店铺" prop="shopName">
            <template slot-scope="scope">
              <el-button class="tableButSpan" type="text" size="mini">{{ scope.row.shopName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="商户" prop="merchantName"></el-table-column>
          <el-table-column label="所属商圈" prop="areaNames" width="400"></el-table-column>
          <el-table-column label="是否可用" prop="enabled"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
            <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
          </div>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="shopCurrentPage" :page-sizes="[10, 20, 50]" :page-size="shopPageSize" layout="total, sizes, prev, pager, next, jumper" :total="shopDialogTotal"> </el-pagination>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import varData from '../ChuZu/varData.js'
export default {
  name: 'GetStaffList',
  inject: ['reload'],
  data() {
    return {
      flag: true,
      flag1: true,
      flagWC: true,
      reast: false,
      userId: window.localStorage.getItem('userId'),
      localShopId: window.localStorage.getItem('shopId'),
      // 商圈区域多选框数据
      activeName: '不限',
      temp: '',
      fangyuanloudong: [],
      daQuInclude: [],
      regionList1: [
        {
          name: '',
          includeShangQuan: [{ name: '', areaIds: '' }]
        }
      ],
      isn: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      enabled: '',
      shopName: '',
      ShopName: '',
      shopId: '',
      isJiShou: '', // 急售
      shopPageSize: 10,
      shopCurrentPage: 1,
      shopDialog: false,
      shopDialogTotal: 0,
      operationList2: [],
      merchantName: '',
      MerchantName: '',
      merchantId: '',
      merchantPageSize: 10,
      merchantCurrentPage: 1,
      merchantDialogTotal: 0,
      operationList1: [],
      merchantDialog: false,
      drow: '',
      show: false,
      show1: true,
      loading: false,
      petBreedName: '',
      role: 1,
      currentPage: 1,
      pageSize: 10,
      fangyuanTotal: 0,
      loupan: '',
      loudong: '',
      weihu: '',
      fangyuanbianhao: '',
      list1: [],
      articleTitle: '',
      item1: [],
      item3: [],
      item4: [],
      item5: [],
      itemWC: [],
      item6: [],
      item7: [],
      shaixuan: [],
      shaixuanList: [],
      item10: [],
      item11: [],
      isActive1: '不限',
      isActive2: '不限',
      isActive3: '0,0',
      isActive4: '0,0',
      isActive5: '不限',
      isActiveWC: '不限',
      isActive6: '0,0',
      isActive7: '不限',
      isActive8: '不限',
      isActive9: '',
      isActive10: '0,0',
      isActive11: '不限',
      isActive12: '不限',
      isActive13: '不限',
      checkedActive7: [],
      checkedActive8: [],
      JiaoYiQuanShu: [],
      JianZhuJieGou: [],
      HuXing: [],
      ShangQuan: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      priceFrom: 0,
      priceTo: 0,
      sortBy: '',
      squareFrom: 0,
      squareTo: 0,
      huXingId: '',
      roomCount: '',
      ToiletCount: '',
      louCengFrom: 0,
      louCengTo: 0,
      hasVip: '',
      hasKey: '',
      HasShiKan: '',
      myRole: '',
      fangZhuangXianKuang: '',
      zhengJianZhuangTai: '',
      // fangWuYongTuId: "",
      zhuangXiuZhuangKuangId: '',
      isPublic: '',
      houseAgeFrom: 0,
      houseAgeTo: 0,
      kanFangShiJian: '',
      jiaoYiQuanShuId: '',
      jianZhuJieGouId: '',
      options: [],
      str: '00000000',
      // LouPanNameOrNo: "",
      LouPanNameOrNo: '',
      GongChengLouHao: '',
      IsGanTanHao: '0',
      userName: '',
      houseNo: '',
      louPanId: '',
      louDongId: '',
      HouseList: [],
      chaoxiangList: [],
      fangxiangToString: '',
      cengshu: '',
      cengshu1: '',
      Array: [],
      roleType: '',
      IsConfirmed: '',
      favourite: '',
      IsEnabled: '',
      IsFavorite: '',
      IsMyRole: '',
      editableTabs: [],
      QueryType: '',
      chuShouParams: JSON.parse(window.localStorage.getItem('chuShouParams')),
      sharePic: '', //小程序码
      optionsTanHao: [
        {
          value: '1',
          label: '是'
        },
        {
          value: '0',
          label: '否'
        }
      ]
    }
  },
  props: {
    /* 是否为客源带看 */
    isKeyuanDaikan: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    fangyuanloudong(val) {
      console.log(val)
      if (val.length != undefined && val.length == 1) {
        this.louPanId = val[0]
      } else {
        this.louPanId = val[0]
      }
    },
    checkedActive7(val) {
      let that = this
      if (val.length > 0) {
        this.isActive7 = ''
      } else {
        this.isActive7 = '不限'
      }
      if (val.length >= 3) {
        that.$message.info('最多只能选三项')
      }

      if (val.indexOf('1') != -1) {
        const Array = this.str.split('')
        Array[0] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[0] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('2') != -1) {
        const Array = this.str.split('')
        Array[1] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[1] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('3') != -1) {
        const Array = this.str.split('')
        Array[2] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[2] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('4') != -1) {
        const Array = this.str.split('')
        Array[3] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[3] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('5') != -1) {
        const Array = this.str.split('')
        Array[4] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[4] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('6') != -1) {
        const Array = this.str.split('')
        Array[5] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[5] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('7') != -1) {
        const Array = this.str.split('')
        Array[6] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[6] = '0'
        this.str = Array.join('')
      }
      if (val.indexOf('8') != -1) {
        const Array = this.str.split('')
        Array[7] = '1'
        this.str = Array.join('')
      } else {
        const Array = this.str.split('')
        Array[7] = '0'
        this.str = Array.join('')
      }
      // that.Array = that.str.split("")
      // val.forEach((item) => {
      //     that.Array.forEach((i,v) => {
      //         if (val.indexOf(item) > -1) {
      //             that.Array[]
      //         }
      //     })
      // })
      // console.log(val)
      // console.log(that.Array)
      // console.log(that.str)
      // that.getHouseList()
    },
    daQuInclude(val) {
      if (val.length >= 1) {
        if (val.length >= 3) {
          this.$message.info('最多只能选三项')
        }
        if (val[0] != '') {
          this.getHouseList()
        }
      }
    }
  },
  created() {
    this.getNodes()
    var varData1 = varData.Function()
    console.log(varData1, '555555555555555555555')
    this.item1 = varData1.item1
    this.item3 = varData1.item3
    this.item4 = varData1.item4
    this.item5 = varData1.item5
    this.itemWC = varData1.itemWC
    this.item6 = varData1.item6
    this.item7 = varData1.item7
    if (this.isKeyuanDaikan) {
      varData1.shaixuan[5].shaixuanList = [
        {
          value: '6-1',
          label: '审核已通过'
        }
      ]
    }
    this.shaixuan = varData1.shaixuan
    this.item10 = varData1.item10
    this.item11 = varData1.item11

    this.editableTabs = window.localStorage.getItem('editableTabs')
    this.activeIndex = window.localStorage.getItem('activeIndex')
    if (this.editableTabs) {
      this.editableTabs = JSON.parse(this.editableTabs)
      this.editableTabs.forEach(item => {
        if (this.activeIndex == item.path) {
          this.activeIndex = this.activeIndex
          this.editableTabsValue = item.name.toString()
        }
      })
    }
    this.roleType = localStorage.getItem('roleType')

    if (this.chuShouParams) {
      this.louPanId = this.chuShouParams.louPanId
      this.louDongId = this.chuShouParams.louDongId
      // this.LouPanNameOrNo = this.chuShouParams.LouPanNameOrNo
      this.LouPanNameOrNo = this.chuShouParams.LouPanNameOrNo
      this.GongChengLouHao = this.chuShouParams.GongChengLouHao
      this.IsGanTanHao = this.chuShouParams.IsGanTanHao
      this.userName = this.chuShouParams.userName
      this.houseNo = this.chuShouParams.houseNo
      this.QueryType = this.chuShouParams.QueryType
      this.isActive1 = this.chuShouParams.QueryType == '' ? '不限' : this.chuShouParams.QueryType
      this.daQuInclude = this.chuShouParams.areaIds.split(',')
      this.priceFrom = this.chuShouParams.priceFrom / 10000
      this.priceTo = this.chuShouParams.priceTo / 10000
      this.isActive3 = this.priceFrom + ',' + this.priceTo
      this.squareFrom = this.chuShouParams.squareFrom
      this.squareTo = this.chuShouParams.squareTo
      this.isActive4 = this.squareFrom + ',' + this.squareTo
      this.huXingId = this.chuShouParams.huXingId
      this.louCengFrom = this.chuShouParams.louCengFrom
      this.louCengTo = this.chuShouParams.louCengTo
      this.isActive6 = this.louCengFrom + ',' + this.louCengTo
      this.roomCount = this.chuShouParams.roomCount
      this.ToiletCount = this.chuShouParams.ToiletCount
      let orientations = this.chuShouParams.orientations.split('')
      if (this.chuShouParams.orientations == '00000000') {
        this.isActive7 = '不限'
        this.checkedActive7 = []
      } else {
        this.isActive7 = ''
        let paramData = []
        orientations.forEach(function(param, index) {
          if (param == '1') {
            paramData.push((index + 1).toString())
          }
        })
        this.checkedActive7 = paramData
      }

      this.hasVip = this.chuShouParams.hasVip
      this.isPublic = this.chuShouParams.isPublic
      this.houseAgeFrom = this.chuShouParams.houseAgeFrom
      this.houseAgeTo = this.chuShouParams.houseAgeTo
      this.isActive10 = this.houseAgeFrom + ',' + this.houseAgeTo

      this.kanFangShiJian = this.chuShouParams.kanFangShiJian
      this.isActive11 = this.chuShouParams.kanFangShiJian == '' ? '不限' : this.chuShouParams.kanFangShiJian

      this.jiaoYiQuanShuId = this.chuShouParams.jiaoYiQuanShuId
      this.isActive12 = this.chuShouParams.jiaoYiQuanShuId == '' ? '' : this.chuShouParams.jiaoYiQuanShuId
      this.jianZhuJieGouId = this.chuShouParams.jianZhuJieGouId
      this.isActive13 = this.chuShouParams.jianZhuJieGouId == '' ? '' : this.chuShouParams.jianZhuJieGouId
      this.isHouseToSell = this.chuShouParams.isHouseToSell

      this.hasKey = this.chuShouParams.hasKey
      if (this.hasKey == '1') {
        this.shaixuan[0].value = '1-1'
        this.shaixuan[0].label = '有钥匙'
      } else if (this.hasKey == '0') {
        this.shaixuan[0].value = '1-2'
        this.shaixuan[0].label = '无钥匙'
      } else {
        this.shaixuan[0].value = '1'
        this.shaixuan[0].label = '钥匙'
      }

      this.myRole = this.chuShouParams.myRole
      if (this.myRole == 'createPerson') {
        this.shaixuan[1].value = '2-1'
        this.shaixuan[1].label = '录入人'
      } else if (this.myRole == 'maintenanceUserId') {
        this.shaixuan[1].value = '2-2'
        this.shaixuan[1].label = '维护人'
      } else if (this.myRole == 'keyUserId') {
        this.shaixuan[1].value = '2-3'
        this.shaixuan[1].label = '钥匙人'
      } else if (this.myRole == 'shiKanUserId') {
        this.shaixuan[1].value = '2-4'
        this.shaixuan[1].label = '实勘人'
      } else if (this.myRole == 'certificatesUserId') {
        this.shaixuan[1].value = '2-5'
        this.shaixuan[1].label = '证件人'
      }

      this.fangZhuangXianKuang = this.chuShouParams.fangZhuangXianKuang
      if (this.fangZhuangXianKuang == '自住') {
        this.shaixuan[2].value = '3-1'
        this.shaixuan[2].label = '自住'
      } else if (this.fangZhuangXianKuang == '空置') {
        this.shaixuan[2].value = '3-2'
        this.shaixuan[2].label = '空置'
      } else if (this.fangZhuangXianKuang == '出租中') {
        this.shaixuan[2].value = '3-3'
        this.shaixuan[2].label = '出租中'
      } else if (this.fangZhuangXianKuang == '经营中') {
        this.shaixuan[2].value = '3-4'
        this.shaixuan[2].label = '经营中'
      }

      this.zhengJianZhuangTai = this.chuShouParams.zhengJianZhuangTai
      if (this.zhengJianZhuangTai == '证件不全') {
        this.shaixuan[3].value = '4-1'
        this.shaixuan[3].label = '证件不全'
      } else if (this.zhengJianZhuangTai == '证件齐全') {
        this.shaixuan[3].value = '4-2'
        this.shaixuan[3].label = '证件齐全'
      } else {
        this.shaixuan[3].value = '4'
        this.shaixuan[3].label = '证件状态'
      }

      this.zhuangXiuZhuangKuangId = this.chuShouParams.zhuangXiuZhuangKuangId

      this.IsConfirmed = this.chuShouParams.IsConfirmed
      this.isActiveShenHe = this.chuShouParams.IsConfirmed
      this.zhengJianZhuangTai = this.chuShouParams.zhengJianZhuangTai
      if (this.IsConfirmed == '1') {
        this.shaixuan[5].value = '6-1'
        this.shaixuan[5].label = '审核已通过'
      } else if (this.IsConfirmed == '0') {
        this.shaixuan[5].value = '6-2'
        this.shaixuan[5].label = '审核未通过'
      } else {
        this.shaixuan[5].value = '6'
        this.shaixuan[5].label = '审核状态'
      }
      this.HasShiKan = this.chuShouParams.HasShiKan
      if (this.HasShiKan == '1') {
        this.shaixuan[6].value = '7-1'
        this.shaixuan[6].label = '有实勘'
      } else if (this.HasShiKan == '0') {
        this.shaixuan[6].value = '1-2'
        this.shaixuan[6].label = '无实勘'
      } else {
        this.shaixuan[6].value = '7'
        this.shaixuan[6].label = '实勘'
      }
      this.sortBy = this.chuShouParams.sortBy
      this.currentPage = this.chuShouParams.currentPage
      this.pageSize = this.chuShouParams.pageSize
    }
    if (localStorage.getItem('chuShouActiveName')) {
      this.activeName = localStorage.getItem('chuShouActiveName')
    }
    this.getShuJuZiDianList()
    this.getHouseList()
    if (this.roleType == 6) {
      this.getMerchantList()
    }
  },
  mounted() {
    this.getStyle()
  },
  methods: {
    getStyle() {
      let that = this
      // if (that.roleType == 6) {
      //   document.getElementById("hrOne").style.marginTop = 20 + "px";
      // }
    },
    shopDialogTrue() {
      if (this.roleType == 5) {
        this.shopDialog = true
        this.getDianPuList()
      }
      if (this.roleType == 6) {
        if (this.MerchantName == '' || this.MerchantName == null) {
          this.$message.error('请先选择商户')
        } else {
          this.shopDialog = true
          this.getDianPuList()
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      // console.log(this.multipleSelection);
    },
    // 商户查询
    cgetMerchantList() {
      this.currentPage = 1
      this.getMerchantList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      const multipleSelection1 = val
      this.multipleSelection2 = []
      for (let i = 0; i < multipleSelection1.length; i++) {
        console.log(multipleSelection1[i].name)
        this.multipleSelection2.push(multipleSelection1[i].name)
      }
    },
    /* 获取商户列表 */
    async getMerchantList() {
      const data = {
        merchantName: this.merchantName,
        currentPage: this.merchantCurrentPage,
        pageSize: this.merchantPageSize
      }
      const { data: res } = await this.$http.get(this.$url.GetMerchantList, {
        params: data
      })
      if (res.msgCode == 1) {
        this.operationList1 = res.dtList
        this.merchantDialogTotal = res.maxRecords
        for (var i = 0; i < res.dtList.length; i++) {
          console.log(res.dtList[i])
          if (res.dtList[i].enabled === '1') {
            this.operationList1[i].enabled = '是'
          }
          if (res.dtList[i].enabled === '0') {
            this.operationList1[i].enabled = '否'
          }
        }
      } else {
        this.$message.success(res.message)
      }
    },
    /* 监听商户pagesize改变的事件 */
    handleSizeChange1(newSize) {
      this.merchantPageSize = newSize
      this.getMerchantList()
    },
    /* 监听商户页码值改变的事件 */
    handleCurrentChange1(newPage) {
      this.merchantCurrentPage = newPage
      this.getMerchantList()
    },
    //重置商户搜多条件
    cancelMerchantList() {
      this.merchantName = ''
      this.enabled = ''
      this.merchantCurrentPage = 1
      this.getMerchantList()
    },
    // 商户弹出层
    merchantDialogTrue() {
      this.merchantDialog = true
    },
    // 选择商户赋值
    MerchantDialogDetails(row) {
      const that = this
      that.MerchantName = row.merchantName
      that.MerchantId = row.merchantId
      that.merchantId = row.merchantId
      that.shopId = ''
      that.ShopName = ''
      that.merchantDialog = false
      this.getDianPuList()
    },
    //重置店铺搜索条件
    cancelDianPuList() {
      this.shopName = ''
      this.enabled = ''
      this.shopCurrentPage = 1
      this.getDianPuList()
    },
    // 选择店铺赋值
    ShopDialogDetails(row) {
      const that = this
      that.ShopName = row.shopName
      that.ShopId = row.shopId
      that.shopId = row.shopId
      that.shopDialog = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      const multipleSelection1 = val
      this.multipleSelection2 = []
      for (let i = 0; i < multipleSelection1.length; i++) {
        console.log(multipleSelection1[i].name)
        this.multipleSelection2.push(multipleSelection1[i].name)
      }
    },
    /* 监听店铺pagesize改变的事件 */
    handleSizeChange2(newSize) {
      this.shopPageSize = newSize
      this.getDianPuList()
    },
    /* 监听店铺页码值改变的事件 */
    handleCurrentChange2(newPage) {
      this.shopCurrentPage = newPage
      this.getDianPuList()
    },
    // 查询店铺
    cgetDianPuList() {
      this.currentPage = 1
      this.getDianPuList()
    },
    /* 获取店铺列表 */
    async getDianPuList() {
      if (this.roleType == 5) {
        const data = {
          areaIds: '', //商圈id
          shopName: this.shopName,
          merchantId: localStorage.getItem('merchantId'),
          currentPage: this.shopCurrentPage,
          pageSize: this.shopPageSize
        }
        const { data: res } = await this.$http.get(this.$url.GetShopList, {
          params: data
        })
        if (res.msgCode == 1) {
          // this.$message.success("获取店铺成功")
          this.operationList2 = res.dtList
          this.shopDialogTotal = res.maxRecords
          for (var i = 0; i < res.dtList.length; i++) {
            if (res.dtList[i].enabled === '1') {
              this.operationList2[i].enabled = '是'
            }
            if (res.dtList[i].enabled === '0') {
              this.operationList2[i].enabled = '否'
            }
          }
        } else {
          this.$message.success(res.message)
        }
      } else {
        const data = {
          areaIds: '', //商圈id
          shopName: this.shopName,
          merchantId: this.merchantId,
          currentPage: this.shopCurrentPage,
          pageSize: this.shopPageSize
        }
        const { data: res } = await this.$http.get(this.$url.GetShopList, {
          params: data
        })
        if (res.msgCode == 1) {
          // this.$message.success("获取店铺成功")
          this.operationList2 = res.dtList
          this.shopDialogTotal = res.maxRecords
          for (var i = 0; i < res.dtList.length; i++) {
            if (res.dtList[i].enabled === '1') {
              this.operationList2[i].enabled = '是'
            }
            if (res.dtList[i].enabled === '0') {
              this.operationList2[i].enabled = '否'
            }
          }
        } else {
          this.$message.success(res.message)
        }
      }
    },
    clickWeiXinGeneratingCode(row) {
      let that = this
      that.sharePic = ''
      that.$commonJs.getWeiXinGeneratingCode('chuShowList', row.houseNo).then(data => {
        that.HouseList.forEach(item => {
          if (row.houseNo == item.houseNo && item.visible != true) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        that.sharePic = data
      })
    },
    toFind() {
      this.LouPanNameOrNo = this.LouPanNameOrNo
      this.GongChengLouHao = this.GongChengLouHao
      this.IsGanTanHao = this.IsGanTanHao
      this.userName = this.userName
      this.houseNo = this.houseNo
      this.MerchantName = this.MerchantName
      this.ShopName = this.ShopName
      this.currentPage = 1
      this.getHouseList()
    },
    getHouseList() {
      var that = this
      that.loading = true
      if (that.isKeyuanDaikan == true) {
        that.IsConfirmed = '1'
      }
      if (that.priceTo != 0 || that.priceFrom != 0) {
        if (parseInt(that.priceTo) <= parseInt(that.priceFrom)) {
          this.$message.error('价格区间有误！')
          that.loading = false
          return
        }
      }
      const params = {
        shopId: that.shopId,
        merchantId: that.merchantId,
        louPanId: that.louPanId,
        louDongId: that.louDongId,
        // LouPanNameOrNo: that.LouPanNameOrNo,
        LouPanNameOrNo: that.LouPanNameOrNo,
        GongChengLouHao: that.GongChengLouHao,
        IsGanTanHao: that.IsGanTanHao,
        userName: that.userName,
        houseNo: that.houseNo,
        areaIds: that.daQuInclude.join(','),
        priceFrom: that.priceFrom * 10000,
        priceTo: that.priceTo * 10000,
        squareFrom: that.squareFrom,
        squareTo: that.squareTo,
        huXingId: that.huXingId,
        roomCount: that.roomCount,
        ToiletCount: that.ToiletCount,
        louCengFrom: that.louCengFrom,
        louCengTo: that.louCengTo,
        orientations: that.str,
        hasVip: that.hasVip,
        hasKey: that.hasKey,
        HasShiKan: that.HasShiKan,
        myRole: that.myRole,
        fangZhuangXianKuang: that.fangZhuangXianKuang,
        zhengJianZhuangTai: that.zhengJianZhuangTai,
        zhuangXiuZhuangKuangId: that.zhuangXiuZhuangKuangId,
        isPublic: that.isPublic,
        houseAgeFrom: that.houseAgeFrom,
        houseAgeTo: that.houseAgeTo,
        kanFangShiJian: that.kanFangShiJian,
        jiaoYiQuanShuId: that.jiaoYiQuanShuId,
        jianZhuJieGouId: that.jianZhuJieGouId,
        currentPage: that.currentPage,
        pageSize: that.pageSize,
        isHouseToSell: '1',
        sortBy: that.sortBy,
        IsConfirmed: that.IsConfirmed,
        QueryType: this.QueryType
      }

      localStorage.setItem('chuShouParams', JSON.stringify(params))
      localStorage.setItem('chuShouActiveName', that.activeName)

      that.$http.post(that.$url.HouseList, params).then(res => {
        if (res.data.msgCode == 1) {
          that.chaoxiangList = []
          that.total = res.data.maxRecords
          if (res.data.dtList.length != 0) {
            for (let i = 0; i < res.data.dtList.length; i++) {
              let list = res.data.dtList[i].orientations.split('')
              list.forEach(function(item, i1) {
                if (i1 == 0 && item == '1') {
                  that.chaoxiangList.push('东')
                }
                if (i1 == 1 && item == '1') {
                  that.chaoxiangList.push('东南')
                }
                if (i1 == 2 && item == '1') {
                  that.chaoxiangList.push('南')
                }
                if (i1 == 3 && item == '1') {
                  that.chaoxiangList.push('西南')
                }
                if (i1 == 4 && item == '1') {
                  that.chaoxiangList.push('西')
                }
                if (i1 == 5 && item == '1') {
                  that.chaoxiangList.push('西北')
                }
                if (i1 == 6 && item == '1') {
                  that.chaoxiangList.push('北')
                }
                if (i1 == 7 && item == '1') {
                  that.chaoxiangList.push('东北')
                }
              })
              res.data.dtList[i].orientations = that.chaoxiangList.join('/')
              that.chaoxiangList = []
              that.list1.push(res.data.dtList[i].louCeng)
              res.data.dtList[i].oneKeting = ''
              if (res.data.dtList[i].shiKanInfo != null && that.$commonJs.noNull(JSON.parse(res.data.dtList[i].shiKanInfo).keting) != '' && res.data.dtList[i].shiKanInfo != '{}') {
                if (JSON.parse(res.data.dtList[i].shiKanInfo).keting.split(',')[0] != null) {
                  res.data.dtList[i].oneKeting = that.$url.houses + JSON.parse(res.data.dtList[i].shiKanInfo).keting.split(',')[0]
                }
              }
              res.data.dtList[i].visible = false
            }
            that.HouseList = res.data.dtList
            console.log(that.total, '00000000')
            console.log(that.HouseList, '11111111111')
            for (var i = 0; i < that.HouseList.length; i++) {
              that.favourite = that.HouseList[i].favourite
            }
            // that.reload()
          } else {
            that.HouseList = []
          }
        } else {
          // that.$message.error(res.data.message)
        }
        that.loading = false
      })
    },
    CheckPrice() {
      if (this.priceFrom > this.priceTo) {
        // this.$message.error("最小价格不能大于最大价格!")
        let temp = ''
        temp = this.priceFrom
        this.priceFrom = this.priceTo
        this.priceTo = temp
      }
      this.getHouseList()
    },
    CheckSquare() {
      if (this.squareFrom > this.squareTo) {
        // this.$message.error("最小面积不能大于最大面积!")
        let temp = ''
        temp = this.squareFrom
        this.squareFrom = this.squareTo
        this.squareTo = temp
        this.getHouseList()
      }
    },
    CheckLouCeng() {
      if (this.louCengFrom > this.louCengTo) {
        // this.$message.error("最小面积不能大于最大面积!")
        let temp = ''
        temp = this.louCengFrom
        this.louCengFrom = this.louCengTo
        this.louCengTo = temp
        this.getHouseList()
      }
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex
      column.index = columnIndex
    },
    xzFangYuan(row) {
      if (this.isKeyuanDaikan) {
        console.log(row)
        this.$emit('colseFangyuanDialog', row)
      }
    },
    resultHouseList() {
      this.reast = true
      this.ShopName = ''
      this.MerchantName = ''
      this.ShopId = ''
      this.fangyuanloudong = []
      this.louPanId = ''
      this.louDongId = ''
      // this.LouPanNameOrNo = ""
      this.LouPanNameOrNo = ''
      this.GongChengLouHao = ''
      this.IsGanTanHao = '0'
      this.userName = ''
      this.houseNo = ''
      this.userName = ''
      this.houseNo = ''
      this.daQuInclude = []
      this.activeName = '不限'
      this.priceFrom = 0
      this.priceTo = 0
      this.squareFrom = 0
      this.squareTo = 0
      this.huXingId = ''
      this.roomCount = ''
      this.ToiletCount = ''
      this.louCengFrom = 0
      this.louCengTo = 0
      this.str = '00000000'
      this.hasVip = ''
      this.hasKey = ''
      this.HasShiKan = ''
      this.myRole = ''
      this.fangZhuangXianKuang = ''
      this.zhengJianZhuangTai = ''
      // this.fangWuYongTuId = ""
      this.zhuangXiuZhuangKuangId = ''
      this.isPublic = ''
      this.houseAgeFrom = 0
      this.houseAgeTo = 0
      this.kanFangShiJian = ''
      this.jiaoYiQuanShuId = ''
      this.jianZhuJieGouId = ''
      this.IsConfirmed = ''
      this.currentPage = 1
      this.pageSize = 10
      this.isHouseToSell = '1'
      this.sortBy = ''
      this.item1 = varData.Function()['item1']
      this.item3 = varData.Function()['item3']
      this.item4 = varData.Function()['item4']
      this.item5 = varData.Function()['item5']
      this.itemWC = varData.Function()['itemWC']
      this.item6 = varData.Function()['item6']
      this.item7 = varData.Function()['item7']
      if (this.isKeyuanDaikan) {
        varData.Function()['shaixuan'][5] = [
          {
            value: '6-1',
            label: '审核已通过'
          }
        ]
      }
      this.shaixuan = varData.Function()['shaixuan']
      this.shaixuan[4].shaixuanList = this.shaixuanList
      this.item10 = varData.Function()['item10']
      this.item11 = varData.Function()['item11']
      this.changeClass1('不限')
      this.changeClass3('不限', '0,0')
      this.changeClass4('不限', '0,0')
      this.changeClass5('不限', '')
      this.changeClassWC('不限', '')
      this.changeClass6('不限', '0,0')
      this.changeClass7('不限')
      this.changeClass9('不限')
      this.changeClass10('不限', '0,0')
      this.changeClass11('不限')
      this.changeClass12('不限', '')
      this.changeClass13('不限', '')
      this.getHouseList()
    },
    //列表排序
    sortChange(column) {
      console.log(column.prop)
      if (column.order == 'ascending') {
        if (column.prop == 'square') {
          this.sortBy = 'h.square ASC'
        } else if (column.prop == 'priceToLet') {
          this.sortBy = 'unitPrice ASC'
        } else if (column.prop == 'priceToSell') {
          this.sortBy = 'hts.price ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'square') {
          this.sortBy = 'h.square DESC'
        } else if (column.prop == 'priceToLet') {
          this.sortBy = 'unitPrice DESC'
        } else if (column.prop == 'priceToSell') {
          this.sortBy = 'hts.price DESC'
        }
      }
      this.getHouseList()
    },
    getShuJuZiDianList() {
      /* 获取交易权属列表 */
      let that = this
      this.$commonJs.getShuJuZiDianList('JIAOYIQUANSHU,JIANZHUJIEGOU,HUXING,ZHUANGXIUZHUANGKUANG,SHANGQUAN').then(data => {
        that.HuXing = data.HuXing
        that.JianZhuJieGou = data.JianZhuJieGou
        that.JiaoYiQuanShu = data.JiaoYiQuanShu
        // that.shaixuan[5].shaixuanList = data.FANGWUYONGTU
        that.shaixuan[4].shaixuanList = data.ZHUANGXIUZHUANGKUANG
        that.shaixuanList = data.ZHUANGXIUZHUANGKUANG
        that.shaixuan[4].shaixuanList.forEach((item, index) => {
          if (that.zhuangXiuZhuangKuangId == item.value) {
            that.shaixuan[4].value = '5-' + (index + 1)
            that.shaixuan[4].label = item.label
          }
        })
        that.regionList1 = data.shangquan
      })
    },
    handleClick(tab, event) {
      this.daQuInclude = []
    },
    changeClass1(i) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive1 = i
      this.currentPage = 1
      if (i == '不限') {
        this.QueryType = ''
      } else {
        this.QueryType = i
      }
      // console.log(this.QueryType)
      if (i == '关注房源') {
        this.IsFavorite = '1'
      } else {
        this.IsFavorite = ''
      }
      if (i == '无效房源') {
        this.IsEnabled = '0'
      } else {
        this.IsEnabled = ''
      }
      if (i == '急售房源') {
        this.isJiShou = '1'
      } else {
        this.isJiShou = ''
      }
      if (i == '角色房源') {
        this.IsMyRole = '1'
      } else {
        this.IsMyRole = ''
      }
      if (this.reast != true) {
        this.getHouseList()
      }
    },

    changeClass2(i) {
      this.isActive2 = i
      this.currentPage = 1
    },

    changeClass3(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive3 = v
      this.currentPage = 1
      this.currentPage = 1
      let list = v.split(',')
      this.priceFrom = list[0] * 1
      this.priceTo = list[1] * 1
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass4(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive4 = v
      this.currentPage = 1
      let list = v.split(',')
      this.squareFrom = list[0] * 1
      this.squareTo = list[1] * 1
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass5(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive5 = i
      this.isActiveWC = i
      if (v != '1') {
        this.currentPage = 1
        this.roomCount = v
        this.ToiletCount = v
        this.flag = true
        this.flagWC = true
        // this.huXingId = v
        // this.getHouseList()
        if (this.reast != true) {
          this.getHouseList()
        }
      } else if (v == '1') {
        this.flag = false
      }
    },
    changeClassWC(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActiveWC = i
      if (v != '1') {
        this.currentPage = 1
        this.ToiletCount = v
        this.flagWC = true
        // this.huXingId = v
        if (this.reast != true) {
          this.getHouseList()
        }
      } else if (v == '1') {
        this.flagWC = false
      }
    },
    changeClass6(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive6 = v
      if (i != '填写楼层') {
        this.currentPage = 1
        this.flag1 = true
        let list = v.split(',')
        this.louCengFrom = list[0] * 1
        this.louCengTo = list[1] * 1
        // this.getHouseList()
        if (this.reast != true) {
          this.getHouseList()
        }
      } else {
        this.flag1 = false
        this.louCengFrom = '1'
        this.louCengTo = '1'
      }
    },
    changeClass7(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive7 = i
      this.currentPage = 1
      this.checkedActive7 = []
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass8(i) {
      this.isActive8 = i
      this.currentPage = 1
    },
    changeClass9(i) {
      this.isActive9 = i
      this.currentPage = 1
    },
    changeClass10(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive10 = v
      this.currentPage = 1
      let list = v.split(',')
      this.houseAgeFrom = list[0] * 1
      this.houseAgeTo = list[1] * 1
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass11(i) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive11 = i
      this.currentPage = 1
      if (i == '不限') {
        this.kanFangShiJian = ''
      } else {
        this.kanFangShiJian = i
      }
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass12(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive12 = v
      this.currentPage = 1
      this.jiaoYiQuanShuId = v
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    changeClass13(i, v) {
      if (i != '不限') {
        this.reast = false
      }
      this.isActive13 = v
      this.currentPage = 1
      this.jianZhuJieGouId = v
      // this.getHouseList()
      if (this.reast != true) {
        this.getHouseList()
      }
    },
    jumpToInsert() {
      this.$router.push({
        name: 'ChuShouDetail',
        query: {
          num: 0,
          state: 1
        }
      })
    },
    openDetails2(row) {
      console.log(row.louCengDuan)
      if (!this.isKeyuanDaikan) {
        this.addTab(row.louPanName, 'ChuShouDetail?louPanId=' + row.louPanId + '&houseId=' + row.houseId + '&canMerchantEdit=' + row.canMerchantEdit + '&canShopEdit=' + row.canShopEdit + '&num=1&state=2&=isConfirmed=' + row.isConfirmed + '&louCeng=' + row.louCengDuan)
        var JiShou
        if (row.isOnUrgentSale == '1') {
          this.JiShou = true
        } else {
          this.JiShou = false
        }
        this.$router.push({
          name: 'ChuShouDetail',
          query: {
            isOnUrgentSale: this.JiShou,
            louPanId: row.louPanId,
            enabled: row.enabled,
            shopId: row.shopId,
            houseId: row.houseId,
            canMerchantEdit: row.canMerchantEdit,
            canShopEdit: row.canShopEdit,
            num: 1,
            state: 2,
            isConfirmed: row.isConfirmed,
            louCeng: row.louCengDuan,
            keting: row.oneKeting
          }
        })
      }
    },
    // 点击导航栏新增tab页
    addTab(targetName, path) {
      let flag = true //判断是否需要新增页面
      var tabs = this.editableTabs //活跃当前tab数组
      for (var i = 0; i < tabs.length; i++) {
        //如果存在相同的tab页 不新增tab页
        if (tabs[i].path === path) {
          this.editableTabsValue = tabs[i].name.toString() //定位到已打开页面
          this.$router.push(path)
          flag = false
        }
      }
      // 需要新增页面
      if (flag) {
        let newTabName = this.editableTabs.length.toString()
        this.editableTabs.push({
          title: targetName,
          name: newTabName,
          path: path
        })
        this.editableTabsValue = newTabName
        window.localStorage.setItem('editableTabs', JSON.stringify(this.editableTabs))
      }
      this.reload()
      // window.localStorage.setItem("activeIndex", path)
    },
    // 获取多选框的值
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getHouseList()
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.getHouseList()
    },
    chengjiao(index) {
      var data1 = [index.articleId]
      this.$confirm('此操作将提交审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$router.push({
        //     name: "ChengJiaoGaiLanDetail",
        //     query: {
        //         num: 0,
        //         state: 1
        //     }
        // })
      })
      // .catch((err) => {
      //   this.$message({
      //     type: "error",
      //     message: err,
      //   });
      // });
    },
    guanzhu(index) {
      var title
      if (index.favourite == '0') {
        title = '关注'
      } else {
        title = '取消关注'
      }
      this.$confirm('此操作将' + title + '该房源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.post(this.$url.SubmitHouseFavorite, {
            HouseId: index.houseId
          })
          if (res.msgCode == '1') {
            this.getHouseList()
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: err
          })
        })
    },
    // 删除数据
    handleDelete(index) {
      var data1 = [index.articleId]
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$http
          .delete(this.$url.deleteArticle, {
            data: data1,
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(res => {
            this.loading = false
            if (res.data.msgCode == 1) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.GetPetList()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
          .catch(err => {
            this.loading = false
            this.$message({
              type: 'error',
              message: err
            })
          })
      })
    },
    async wuXiaoFangYuan(row) {
      //无效房源
      let title = '',
        enabled = ''
      console.log(row)
      if (row.enabled == '0') {
        title = '有效房源'
        enabled = '1'
      } else {
        title = '无效房源'
        enabled = '0'
      }
      this.$confirm('要将该房源设置为' + title + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          // if (
          //     this.roleType == 2 ||
          //     this.roleType == 5 ||
          //     this.roleType == 6
          // ) {
          //     await this.$http
          //         .put(this.$url.UpdateHouseEnabled, {
          //             houseId: row.houseId,
          //             isHouseToSell: "0",
          //             houseToLetId: row.houseToLetId,
          //             enabled: enabled
          //         })
          //         .then((res) => {
          //             if (res.data.msgCode == "1") {
          //                 this.getHouseList()
          //             } else {
          //                 this.$message({
          //                     type: "error",
          //                     message: res.data.message
          //                 })
          //             }
          //         })

          //     return false
          // }
          // this.$confirm("是否提交审核", "提示", {
          //     confirmButtonText: "确定",
          //     cancelButtonText: "取消",
          //     type: "warning"
          // })
          // .then(async () => {
          await this.$http
            .put(this.$url.UpdateHouseEnabled, {
              houseId: row.houseId,
              isHouseToSell: '1',
              HouseToSellId: row.houseToSellId,
              enabled: enabled
              // LouPanId: row.louPanId,
              // LouDongId: row.louDongId,
              // MenPaiHao: row.menPaiHao,
            })
            .then(res => {
              if (res.data.msgCode == '1') {
                this.getHouseList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
        })
        .catch(err => {
          console.log(err)
        })
      // })
    },
    xiangqing(index, row) {
      var JiShou
      this.drow = row.articleId
      if (row.isOnUrgentSale == '1') {
        this.JiShou = true
      } else {
        this.JiShou = false
      }
      this.$router.push({
        name: 'ChuShouDetail',
        query: {
          isOnUrgentSale: this.JiShou,
          canShopEdit: row.canShopEdit,
          canMaintenanceEdit: row.canMaintenanceEdit,
          canMerchantEdit: row.canMerchantEdit,
          louPanId: row.louPanId,
          houseId: row.houseId,
          num: 2,
          state: 3
        }
      })
      // this.$router.push('/InsertPetBreed/2/' + this.drow)
    },
    handleEdit() {
      var aa = this.$refs.multipleTable.selection
      if (aa.length > 1) {
        this.$message({
          message: '请选择一条数据!'
        })
      } else if (aa == '') {
        this.$confirm('请勾选数据', '提示', {
          type: 'warning'
        })
      } else {
        // this.$router.push('/InsertPetBreed/' + aa[0].staffId)
        this.$router.push({
          name: 'ChuShouDetail',
          query: {
            articleId: aa[0].articleId,
            num: 2,
            state: 2
          }
        })
        // 取到这一栏的值，也就是明白是在那一栏进行操作，从而将编辑后的数据存到表格中
      }
    },
    //搜索栏下拉菜单点击事件
    handleCommand(command) {
      let that = this
      let shaixuanCommand = ''
      if (command == '4-1') {
        this.zhengJianZhuangTai = '证件不全'
      }
      if (command == '4-2') {
        this.zhengJianZhuangTai = '证件齐全'
      }
      if (command == '6-1') {
        this.IsConfirmed = '1'
      }
      if (command == '6-2') {
        this.IsConfirmed = '0'
      }
      that.shaixuan.forEach(item => {
        item.shaixuanList.forEach(item1 => {
          if (item1.value == command) {
            if (command.indexOf('-') != -1) {
              shaixuanCommand = command.split('-')[0]
              if (shaixuanCommand == 1) {
                that.hasKey = item1.text
              }
              if (shaixuanCommand == 2) {
                that.myRole = item1.text
              }
              if (shaixuanCommand == 3) {
                that.fangZhuangXianKuang = item1.label
              }
              if (shaixuanCommand == 7) {
                that.HasShiKan = item1.text
              }
            } else {
              item.shaixuanList.forEach(item1 => {
                // if (
                //     item1.value1 != undefined &&
                //     item1.value1.split("-")[0] == 6
                // ) {
                //     that.fangWuYongTuId = command
                // }
                if (item1.value1 != undefined && item1.value1.split('-')[0] == 8) {
                  that.zhuangXiuZhuangKuangId = command
                }
              })
            }
            item.label = item1.label
          }
        })
      })
      // this.shaixuan = shaixuanCommand
      this.currentPage = 1
      this.getHouseList()
    },
    showItem() {
      this.show = !this.show
    },
    add() {
      this.isn = !this.isn
    },
    handleChange(e) {
      console.log(e)
    },
    getNodes(val) {
      this.$http
        .get(this.$url.GetLouPanList, {
          params: {
            louPanNo: '', //楼盘编号
            louPanName: '', //楼盘名称
            areaIds: '', //商圈id
            louPanAddress: '', //楼盘地址
            kaiFaDanWei: '', //开发单位
            isConfirmed: '',
            isPublic: '',
            currentPage: 1,
            pageSize: 10
          }
        })
        .then(
          response => {
            if (response.data && response.data.msgCode === 1) {
              let Items = response.data.dtList
              if (response.data.dtList.length > 0) {
                // 初始化 加载一级
                this.options = Items.map((value, i) => {
                  return {
                    louPanId: value.louPanId,
                    louPanName: value.louPanName,
                    cities: []
                  }
                })
                let louPanId1 = ''
                if (val != undefined) {
                  louPanId1 = val.toString()
                }
                if (louPanId1) {
                  this.$http
                    .get(this.$url.GetLouDongList, {
                      params: {
                        louPanId: louPanId1, //楼盘编号
                        currentPage: 1,
                        pageSize: 10
                      }
                    })
                    .then(res => {
                      let Items1 = res.data.dtList
                      this.options.map((value, i) => {
                        if (val != undefined) {
                          if (value.louPanId === val[0]) {
                            this.louPanId = value.louPanId
                            if (!value.cities.length) {
                              value.cities = Items1.map((value, i) => {
                                return {
                                  louPanId: value.louDongId,
                                  louPanName: value.gongChengLouHao
                                }
                              })
                            }
                          }
                        }
                      })
                    })
                }
              }
            } else {
              console.log(response.data.msg)
            }
          },
          error => {
            console.log(error)
          }
        )
    },
    handleItemChange(val) {
      if (val.length == 1) {
        this.getNodes(val)
      } else if (val.length == 2) {
        this.louDongId = val[1]
      } else {
        return false
      }
    },
    priceChange() {
      this.isActive3 = 0
    }
  }
}
</script>

<style lang="less" scoped>
.xuqiuDialog /deep/ .el-dialog__body {
  padding: 10px 20px;
}

.el-card {
  margin-top: 15px;
}

.title {
  font-size: 14px;
  //   width: 73px;
  //   display: inline-block;
  text-align: right;
}

.el-checkbox {
  margin-right: 10px;
}

.caozuo {
  margin-left: 3px !important;
}

.font-class-red {
  color: red !important;
}

.font-class-green {
  color: rgb(19, 177, 19) !important;
}

.font-class-blue {
  color: #1890ff !important;
}

.font-class-black {
  color: #000 !important;
}

.fontColorRed {
  color: red;
}

.fontColorBlack {
  color: black;
}

// 商圈
/deep/ .el-tabs__item {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
}

// /deep/.business {
//     border: 0 !important;
//     height: 21px !important;
//     line-height: 23px !important;
// }
/deep/ .el-tabs__nav > .is-active {
  color: red !important;
  background: none !important;
  height: 20px;
  // z-index: 1111;
}

/deep/ .el-tabs__header {
  margin: 0;
}

.el-checkbox {
  margin-left: 10px;
}

.business {
  background: #fff;
  // border: 1px solid #ccc;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0 !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0 !important;
}

/deep/ .el-tabs__nav .is-top {
  height: 20px;
  line-height: 20px;
  border: 0;
  padding: 0;
  margin-left: 1.5% !important;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border: 0;
}

/deep/ .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding: 0 3px;
}

/deep/ .el-tabs__active-bar .is-top {
  width: 0 !important;
}
</style>
<style>
.el-cascader__suggestion-panel {
  height: 204px;
}
</style>
