import { render, staticRenderFns } from "./ZuZhiJiaGouList.vue?vue&type=template&id=2bb3c44b&scoped=true"
import script from "./ZuZhiJiaGouList.vue?vue&type=script&lang=js"
export * from "./ZuZhiJiaGouList.vue?vue&type=script&lang=js"
import style0 from "./ZuZhiJiaGouList.vue?vue&type=style&index=0&id=2bb3c44b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb3c44b",
  null
  
)

export default component.exports