<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/GetStatistics',query:{activeName2:0} }">
                <el-button size="mini" class="el-button1" type="warning" style="float: right">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col>
                        <el-form-item label="门店名称:" label-width="10%" prop="shopName">
                            <el-input :disabled="flag" style="width: 40%" v-model="form.shopName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="联系人:" label-width="10%" prop="userName">
                            <el-input :disabled="flag" style="width: 40%" v-model="form.userName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="联系方式(一):" label-width="10%" prop="mobile1">
                            <el-input :disabled="flag" oninput="value=value.replace(/[^\d]/g,'')" style="width: 40%" v-model="form.mobile1"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="联系方式(二):" label-width="10%" prop="mobile2">
                            <el-input :disabled="flag" oninput="value=value.replace(/[^\d]/g,'')" style="width: 40%" v-model="form.mobile2"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="职位:" label-width="10%" prop="userType1">
                            <el-input :disabled="flag" type="textarea" :rows="2" style="width: 40%" v-model="form.userType1"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="是否可用:" label-width="10%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 60%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label-width="10%" label="联系人图片:">
                            <div>
                                <el-image style="width: 100px; height: 100px" :src="form.imgtoux" :preview-src-list="[form.imgtoux]">
                                </el-image>
                            </div>

                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            name: "",
            flag: false,
            dialogImageUrl: "",
            isMax: false,
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            form: {
                articleTitle: "",
                articleContent: "",
                firstPic: "",
                weight: "",
                enabled: ""
            },
            currentPage: 1,
            pageSize: 10,
            rules: {
                articleTitle: [
                    { required: true, message: "请输入联系人", trigger: "blur" }
                ],
                articleContent: [
                    { required: true, message: "请选选择职位", trigger: "blur" }
                ],
                weight: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur"
                    }
                ],
                firstPic: [
                    {
                        required: true,
                        message: "请上传文章图片",
                        trigger: "blur"
                    }
                ]
            }
        }
    },
    created() {
        var list = decodeURIComponent(this.$route.query.form)
        this.form = JSON.parse(list)
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        // 0 新增 1 详情 2 修改
        if (this.num == 1) {
            this.name = "通讯录详情"
            this.flag = true
            this.isMax = true
            // this.getArticleDetail()
            this.getDetail()
        }
    },
    methods: {
        getDetail() {
            console.log(this.form)
            console.log(this.form.userType)
            console.log(this.form)
            this.form.userType1 = ""
            this.form.imgtoux = ""
            if (this.form.gender === "1") {
                this.form.gender = "男"
            }
            if (this.form.gender === "2") {
                this.form.gender = "女"
            }
            if (this.form.userType.indexOf("1") != -1) {
                this.form.userType1 = "经纪人,"
            }
            if (this.form.userType.indexOf("2") != -1) {
                this.form.userType1 += "商圈经理,"
            }
            if (this.form.userType.indexOf("3") != -1) {
                this.form.userType1 += "商户财务,"
            }
            if (this.form.userType.indexOf("4") != -1) {
                this.form.userType1 += "人事专员,"
            }
            if (this.form.userType.indexOf("5") != -1) {
                this.form.userType1 += "店东,"
            }
            if (this.form.userType.indexOf("6") != -1) {
                this.form.userType1 += "平台管理员,"
            }
            if (this.form.userType.indexOf("7") != -1) {
                this.form.userType1 += "交易中心,"
            }
            // 去除最后面的逗号
            if (this.form.userType1.length > 0) {
                this.form.userType1 = this.form.userType1.substr(
                    0,
                    this.form.userType1.length - 1
                )
            }
            if (this.form.pictureId != null) {
                this.form.imgtoux =
                    this.$url.avatarsPicSrc + this.form.pictureId
                console.log(this.form.imgtoux)
            }
        },
        async onSubmit(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        // fileGuid: this.form.fileGuid,
                        enabled: this.form.enabled
                    }

                    const { data: res } = await this.$http.post(
                        this.$url.insertArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("新增文章信息成功")
                        this.$router.push({ path: "/TongXunLuList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            let diseaseIds = this.diseaseIds === "" ? "" : this.diseaseIds
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleId: this.articleId,
                        articleTitle: this.form.articleTitle,
                        articleContent: this.form.articleContent,
                        firstPic: this.form.firstPic,
                        weight: this.form.weight,
                        enabled: this.form.enabled
                    }
                    // console.log(diseaseIds)
                    const { data: res } = await this.$http.put(
                        this.$url.updateArticle,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.msgCode == 1) {
                        this.$message.success("修改文章信息成功")
                        this.$router.push({ path: "/TongXunLuList" })
                    } else {
                        this.$message.success(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        async getArticleDetail() {
            await this.$http
                .get(this.$url.getArticleDetail(this.articleId))
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        var uuu = {
                            url: URL + "uploads/allFiles/" + this.form.firstPic
                        }
                        // this.picture.push(uuu);
                        if (
                            this.form.firstPic == "" ||
                            this.form.firstPic == null
                        ) {
                            this.picture = []
                            this.isMax = false
                        } else if (
                            this.form.firstPic != "" ||
                            this.form.firstPic != null
                        ) {
                            this.picture.push(uuu)
                            this.isMax = true
                        }
                        if (this.num == 1) {
                            if (
                                this.form.firstPic == "" ||
                                this.form.firstPic == null
                            ) {
                                this.isMax = true
                            }
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },

        cancel() {
            this.$router.push({ path: "/TongXunLuList" })
        }
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>