<template>
  <div>
    <div>
      <!-- 中间卡片视图区域 -->
      <el-card class="search">
        <el-row>
          <el-col :span="6" class="gzjlhe">
            <div class="titleLabel" style="margin-left: -32px">选择时间：</div>
            <el-date-picker
              class="media-width"
              style="width: 75%"
              v-model="time"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="6" class="gzjlhe">
            <div class="titleLabel" style="margin-left: -32px">店铺：</div>
            <el-input
              @focus="shopDialogTrue"
              placeholder="请选择店铺"
              readonly="readonly"
              v-model="shopName1"
              class="media-width"
            ></el-input>
          </el-col>
          <el-col :span="12">
            <span style="float: right">
              <el-button
                size="mini"
                style="margin-left: 20px"
                type="primary"
                @click="cgetArticleList"
                >查询</el-button
              >
              <el-button size="mini" @click="cancelbreedList" type="warning"
                >重置</el-button
              >
              <el-button
                size="mini"
                @click="ExportJobAccountExcel()"
                type="export"
                style="
                  background-color: rgb(14 153 219);
                  color: #fff;
                  border: none;
                "
                >导出</el-button
              >
            </span>
          </el-col>
        </el-row>
      </el-card>
      <!-- 底部内容区域 -->
      <el-card>
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="operationList"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px'
          }"
          :cell-style="{ 'text-align': 'center' }"
          border
          stripe
        >
          <el-table-column label="经纪人" prop="userName" fixed>
            <template slot-scope="scope">
              <el-button
                class="tableButSpan"
                type="text"
                size="mini"
                style="color: #606266; text-decoration: underline"
                >{{ scope.row.userName }}</el-button
              >

              <!-- <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0; text-decoration: underline">{{ scope.row.name }}</el-button> -->
            </template>
          </el-table-column>
          <el-table-column label="房源" prop="position">
            <el-table-column label="新增房" prop="entryCount"></el-table-column>
            <el-table-column label="实勘" prop="shiKanCount"></el-table-column>
            <el-table-column label="钥匙" prop="keyCount"></el-table-column>
            <el-table-column
              label="维护"
              prop="maintenanceCount"
            ></el-table-column>
            <el-table-column
              label="证件"
              prop="certificatesCount"
            ></el-table-column>
            <!-- <el-table-column label="VIP" prop="VIPCount"></el-table-column> -->
            <!-- <el-table-column label="委托" prop="consign"></el-table-column> -->
          </el-table-column>
          <el-table-column label="客源" prop="position">
            <el-table-column
              label="新增客"
              prop="customerCount"
            ></el-table-column>
            <el-table-column label="带看" prop="daiKanCount"></el-table-column>
          </el-table-column>
          <el-table-column label="成交" prop="dealCount">
            <el-table-column label="成交" prop="dealCount"></el-table-column>
          </el-table-column>
          <el-table-column label="跟进" prop="position">
            <el-table-column
              label="房源跟进"
              prop="houseGenJinCount"
            ></el-table-column>
            <el-table-column
              label="客源跟进"
              prop="customerGenJinCount"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="业绩" prop="receivable">
            <el-table-column
              label="房源业绩"
              prop="housePerformance"
            ></el-table-column>
            <el-table-column
              label="客源业绩"
              prop="customerPerformance"
            ></el-table-column>
          </el-table-column>
          <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
            <img
              style="width: 200px; margin-top: 50px"
              src="../../../public/img/noList.png"
            />
            <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
          </div>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>

        <!-- 店铺弹出层 -->
        <el-dialog
          :visible.sync="shopDialog"
          direction="rtl"
          width="70%"
          top="4%"
          class="dialogCss"
        >
          <el-card class="search">
            <el-row>
              <el-col>
                <span class="title">店铺：</span>
                <el-input
                  v-model="shopName2"
                  placeholder="请输入店铺"
                  style="width: 25%"
                ></el-input>
                <span style="float: right">
                  <el-button
                    size="mini"
                    style="margin-left: 20px"
                    type="primary"
                    @click="cgetDianPuList"
                    >查询</el-button
                  >
                  <el-button
                    size="mini"
                    @click="cancelDianPuList"
                    type="warning"
                    >重置</el-button
                  >
                </span>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col>
                <el-button
                  size="mini"
                  @click="jumpToInsert()"
                  type="primary"
                  icon="el-icon-plus"
                  >新增</el-button
                >
                <el-button
                  size="mini"
                  @click="handleDelete1()"
                  type="warning"
                  icon="el-icon-delete"
                  >删除</el-button
                >
              </el-col>
            </el-row>
          </el-card>
          <!-- 底部内容区域 -->
          <el-table
            ref="multipleTable"
            @row-click="ShopDialogDetails"
            :data="operationList2"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              color: '#333',
              'font-size': '14px'
            }"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
            border
            stripe
          >
            <el-table-column label="店铺" prop="shopName">
              <template slot-scope="scope">
                <el-button class="tableButSpan" type="text" size="mini">{{
                  scope.row.shopName
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="商户" prop="merchantName"></el-table-column>
            <el-table-column
              label="所属商圈"
              prop="areaNames"
              width="400"
            ></el-table-column>
            <el-table-column label="是否可用" prop="enabled"></el-table-column>
            <el-table-column
              label="创建时间"
              prop="createTime"
            ></el-table-column>
            <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
              <img
                style="width: 200px; margin-top: 50px"
                src="../../../public/img/noList.png"
              />
              <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
            </div>
          </el-table>
          <!-- 分页区域 -->
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="shopCurrentPage"
            :page-sizes="[10, 20, 50]"
            :page-size="shopPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="shopDialogTotal"
          >
          </el-pagination>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "JiXiaoTongJiList",
  inject: ["reload"],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "本周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "上周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      time: "",
      loading: true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      operationList: [],
      enabled: "不限",
      timeOld: "",
      shopDialog: false,
      shopCurrentPage: 1,
      shopPageSize: 10,
      shopDialogTotal: 0,
      operationList2: [],
      shopId: "",
      shopName1: "",
      shopName2: ""
    };
  },
  created() {
    /* 获取列表 */
    this.newTime();
    this.getList();
  },
  methods: {
    // 查询
    cgetArticleList() {
      this.currentPage = 1;
      this.getList();
    },
    //跳转详情
    openDetails2(row) {
      this.$router.push({
        name: "JiXiaoTongJiDetail",
        query: {
          articleId: row.articleId,
          num: 1,
          state: 2
        }
      });
    },
    /* 获取列表 */
    async getList() {
      var dateFrom = this.time[0];
      var dateTo = this.time[1];
      const data = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        searchShopId: this.shopId,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      };
      const { data: res } = await this.$http.get(this.$url.GetJobAccountList, {
        params: data
      });
      if (res.msgCode == 1) {
        this.operationList = res.dtList;
        this.loading = false;
        this.total = res.maxRecords;
      } else {
        this.loading = false;
        this.$message.success(res.message);
      }
    },
    /* 监听pagesize改变的事件 */
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList();
    },
    /* 监听页码值改变的事件 */
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getList();
    },
    //重置
    cancelbreedList() {
      this.enabled = "不限";
      this.currentPage = 1;
      this.time = this.timeOld;
      this.shopId = "";
      this.shopName1 = "";
      this.getList();
    },
    //导出
    ExportJobAccountExcel() {
      let that = this;
      let a = ''
      if(this.shopId == ""){
a='%20'
      }else{
a=this.shopId
      }
      const date = {
        dateFrom: this.time[0],
        dateTo: this.time[1],
        searchShopId: a
      };
      let filepath;
      let fileName;
      this.$http
        .post(
          this.$url.ExportJobAccountExcel(
            date.dateFrom,
            date.dateTo,
            date.searchShopId
          )
        )
        .then(res => {
          if (res.data.msgCode == 1) {
            filepath = that.$url.JobAccountFileSrc + res.data.fileName;
            fileName = res.data.fileName;
            console.log(fileName, "11111");
            let a = document.createElement("a");
            a.href = filepath;
            a.setAttribute("download", fileName);
            a.click();
          } else {
            this.$message({
              message: res.data.message,
              type: "warning"
            });
          }
        });
    },
    newTime() {
      var myDate = new Date();
      var dateTo =
        myDate
          .toLocaleDateString()
          .split("/")
          .join("-")
      var pastMonth = myDate.getMonth() - 2;
      var time3de = myDate.setMonth(pastMonth);
      var time3denew = new Date(time3de);

      var dateFROM =
        time3denew.getFullYear() +
        "-" +
        time3denew.getMonth() +
        "-" +
        myDate.getDate();
      this.time = [dateFROM, dateTo];
      this.timeOld = [dateFROM, dateTo];
    },
    shopDialogTrue() {
      //   if (this.roleType == 5) {
      this.shopDialog = true;
      this.getDianPuList();
      //   }
      //   if (this.roleType == 6) {
      //       this.shopDialog = true;
      //       this.getDianPuList();
      //   }
    },
    //重置店铺搜索条件
    cancelDianPuList() {
      this.shopName2 = "";
      this.enabled = "";
      this.shopCurrentPage = 1;
      this.getDianPuList();
    },
    // 查询店铺
    cgetDianPuList() {
      this.shopCurrentPage = 1;
      this.getDianPuList();
    },
    // 选择店铺赋值
    ShopDialogDetails(row) {
      const that = this;
      that.shopName1 = row.shopName;
      that.shopId = row.shopId;
      that.shopDialog = false;
    },
    /* 获取店铺列表 */
    async getDianPuList() {
      const data = {
        areaIds: "", //商圈id
        shopName: this.shopName,
        merchantId: localStorage.getItem("merchantId"),
        currentPage: this.shopCurrentPage,
        pageSize: this.shopPageSize
      };
      const { data: res } = await this.$http.get(this.$url.GetShopList, {
        params: data
      });
      if (res.msgCode == 1) {
        // this.$message.success("获取店铺成功")
        this.operationList2 = res.dtList;
        this.shopDialogTotal = res.maxRecords;
        for (var i = 0; i < res.dtList.length; i++) {
          if (res.dtList[i].enabled === "1") {
            this.operationList2[i].enabled = "是";
          }
          if (res.dtList[i].enabled === "0") {
            this.operationList2[i].enabled = "否";
          }
        }
      } else {
        this.$message.success(res.message);
      }
    },
    /* 监听店铺pagesize改变的事件 */
    handleSizeChange2(newSize) {
      this.shopPageSize = newSize;
      this.getDianPuList();
    },
    /* 监听店铺页码值改变的事件 */
    handleCurrentChange2(newPage) {
      this.shopCurrentPage = newPage;
      this.getDianPuList();
    },
    // 获取多选框的值
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 15px;
}
</style>
<style>
.el-date-editor .el-range-separator,
.el-date-editor .el-range__icon {
  line-height: 22px;
}
.el-range-editor .el-range-input {
  line-height: 28px;
  height: 28px !important;
}
.gzjlhe .el-range-editor.el-input__inner {
  height: 30px;
}
</style>
