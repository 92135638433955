<template>
  <div>
    <el-card>
      <span>{{ detail }}</span>
      <span style="float: right">
        <!-- <el-button v-if="num == 2" size="mini" type="primary">开收据</el-button> -->
        <el-button size="mini" type="primary" @click="goBack">返回</el-button>
      </span>
    </el-card>
    <el-card v-if="num == 0">
      <el-form :model="form" ref="form" :rules="rules">
        <el-row v-if="label == '入账管理'">
          <el-col :span="12">
            <el-form-item label="选择合同：" prop="contractId" label-width="20%">
              <el-button
                size="mini"
                @click="openHetongDialog"
                class="buttonInput button_tanchu"
                style="
                    width: 60%;
                    height: 22px;
                    line-height: 0px;
                    text-align: left;
                  "
              >
                <span
                  v-if="form.contractNo == '请选择合同'"
                  style="color: #c0c4cc"
                  >{{ form.contractNo }}</span
                >
                <span v-if="form.contractNo != '请选择合同'">{{
                  form.contractNo
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="签约经纪人"
              required
              label-width="20%"
              prop="userId"
            >
              <el-button
                size="mini"
                @click="openJingJiRenDialog"
                style="
                  width: 60%;
                  height: 30px;
                  vertical-align: middle;
                  text-align: left;
                "
              >
                <span
                  v-if="form.contractUserName == '请选择签约经纪人'"
                  style="color: #c0c4cc"
                  >{{ form.contractUserName }}</span
                >
                <span v-if="form.contractUserName != '请选择签约经纪人'">{{
                  form.contractUserName
                }}</span>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="业绩类型："
              label-width="20%"
              prop="performanceType"
            >
              <el-select
                v-model="form.performanceType"
                placeholder="请选择业绩类型"
                style="width: 60%;"
              >
                <el-option
                  v-for="item in performanceTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业绩月份：" label-width="20%" prop="yyyyMM">
              <el-date-picker
                style="width: 60%;"
                v-model="form.yyyyMM"
                value-format="yyyyMM"
                type="month"
                placeholder="请选择业绩月份"
                class="media-width"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="业绩："
              label-width="20%"
              prop="performanceAmount"
            >
              <el-input
                v-model="form.performanceAmount"
                placeholder="请输入业绩"
                class="media-width"
                style="width: 60%;"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="num != '1'" style="margin: 20px 10px;">
          <el-col :span="12">
            <el-form-item>
              <el-button
                size="mini"
                style="margin-right:10px"
                type="primary"
                v-if="num == 0"
                @click="onSave('form', 'add')"
                >新增</el-button
              >
              <el-button
                size="mini"
                type="warning"
                @click="cancel"
                v-if="num != 1"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-dialog
      title="选择合同"
      :visible.sync="hetongDialog"
      direction="rtl"
      width="80%"
      top="4%"
      class="xuqiuDialog"
    >
      <QianYueList
        :isCaiWu="true"
        @colseHetongDialog="colseHetongDialog"
      ></QianYueList>
    </el-dialog>

    <!-- 店铺 -->
    <el-dialog
      title="店铺"
      :visible.sync="jingJiRenDialog"
      direction="rtl"
      width="70%"
      top="4%"
      class="xuqiuDialog"
    >
      <TongXunLuList
        :isQianyueOpen="true"
        @colseJingJiRenDialog="colseJingJiRenDialog"
      ></TongXunLuList>
    </el-dialog>
  </div>
</template>
<script>
import QianYueList from "../../components/QianYue/QianYueList.vue";
import TongXunLuList from "../../components/TongXunLu/TongXunLuList.vue";
export default {
  components: {
    QianYueList,
    TongXunLuList
  },
  data() {
    return {
      jingJiRenDialog: false,
      detail: "",
      num: this.$route.query.num,
      label: this.$route.query.label,
      hetongType: "",
      tradeType: "",
      hetongDialog: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      rules: {
        userId: [
          {
            required: true,
            message: "请选择经纪人",
            trigger: "blur"
          }
        ],
        contractId:[
          {
            required: true,
            message: "请选择合同",
            trigger: "blur"
          }
        ],
        performanceType:[
          {
            required: true,
            message: "请选择业绩类型",
            trigger: "blur"
          }
        ],
        yyyyMM:[
          {
            required: true,
            message: "请选择业绩月份",
            trigger: "blur"
          }
        ],
        performanceAmount:[
          {
            required: true,
            message: "请输入业绩",
            trigger: "blur"
          }
        ],
      },
      form: {
        userId: "", //经纪人id
        contractUserName:"请选择签约经纪人",
        contractNo: "请选择合同", //合同编号
        contractId: "", //合同ID
        performanceType: "", //资金类型
        performanceAmount: "", //业绩
        yyyyMM: "", //业绩月份
      },
      performanceTypeList: [
        {
          label: "房源端业绩",
          value: "房源端业绩"
        },
        {
          label: "客源端业绩",
          value: "客源端业绩"
        },
        {
          label: "其他",
          value: "其他"
        }
      ]
    };
  },
  created() {
    if (this.num == 0) {
      this.detail = "线下合同新增";
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    },
    // 合同弹出层
    openHetongDialog() {
      this.hetongDialog = true;
    },
    colseHetongDialog(hetongData) {
      this.form.contractNo = hetongData.contractNo;
      this.form.contractId = hetongData.contractId;
      this.hetongDialog = false;
      this.val = hetongData.contractId;
    },
    // 经纪人弹出层
    openJingJiRenDialog() {
      this.jingJiRenDialog = true;
    },
    colseJingJiRenDialog(jingJiRenData) {
      this.form.contractUserName = jingJiRenData.userName;
      this.form.userId = jingJiRenData.userId;
      this.jingJiRenDialog = false;
    },
    onSave(formName, type) {
      const that = this;
      if (type == "add") {
        that.$refs[formName].validate(async valid => {
          if (valid) {
            that.loading = true;
            let param = {
              userId: that.form.userId,
              contractId: that.form.contractId,
              yyyyMM: that.form.yyyyMM,
              performanceType: that.form.performanceType,
              performanceAmount: parseInt(that.form.performanceAmount) 
            };
            that.$http
              .post(that.$url.InsertPerformance, param, {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8"
                }
              })
              .then(res => {
                that.loading = false;
                if (res.data.msgCode == 1) {
                  that.$message.success("新增成功！");
                  that.$router.go(-1);
                } else {
                  that.$message.error(res.data.message);
                }
              })
              .catch(res => {
                that.loading = false;
                that.disabled = false;
                that.$message.error("请求错误");
              });
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.labelValue {
  padding-left: 10px;
  font-size: 16px;
  color: #a7a7a7;
}
</style>
