<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <router-link :to="{ path: '/GetStatistics',query:{activeName2:2} }">
                <el-button size="mini" class="el-button1" type="primary" style="float: right;margin-bottom: 7px;">返回</el-button>
            </router-link>
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" label-position="right" label-width="20%">
                <el-row>
                    <el-col>
                        <el-form-item label="客户姓名:" label-width="10%" prop="customerName">
                            <el-input :disabled="flag" style="width: 40%" v-model="form.customerName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="跟进日期:" label-width="10%" prop="genJinTime">
                            <el-input :disabled="flag" style="width: 40%" v-model="form.genJinTime"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="跟进内容" label-width="10%" prop="genJinInfo">
                            <el-input :disabled="flag" style="width: 40%" type="textarea" :rows="2" placeholder="请输入内容" v-model="form.genJinInfo">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            num: this.$route.query.num,
            form: {}
        }
    },
    created() {
        var list = decodeURIComponent(this.$route.query.form)
        this.form = JSON.parse(list)
        // 0 新增 1 详情 2 修改
        if (this.num == 1) {
            this.name = "跟进记录详情"
            this.flag = true
        }
    },
    methods: {}
}
</script>
<style scoped>
</style>
