<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ name }}</span>
            <!-- <router-link :to="{ path: '/GuiZhangZhiDuList' }"> -->
            <el-button size="mini" class="el-button1" type="warning" style="float: right" @click="cancelCommon">返回</el-button>
            <!-- </router-link> -->
        </el-card>
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="20%">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="规章制度标题:" label-width="10%" prop="articleTitle">
                            <el-input v-if="num != 1" :disabled="flag" style="width: 35.6%" v-model="form.articleTitle" placeholder="请输入规章制度标题"></el-input>
                            <el-form-item v-if="num == 1" label-width="10%">{{ form.articleTitle }}</el-form-item>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="规章制度内容:" label-width="10%" prop="articleContent">
                            <el-input v-if="num != 1" :disabled="flag" style="width: 90%" type="textarea" :rows="20" placeholder="请输入内容" v-model="form.articleContent">
                            </el-input>
                            <el-form-item v-if="num == 1" label-width="10%" style="white-space: pre-line;">{{ form.articleContent }}</el-form-item>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">

                        <el-form-item style="" label-width="10%" label="规章制度上传:">
                            <!-- <el-input v-model="taskPic" style="width: 45%"></el-input> -->
                            <span class="redStar">*</span>
                            <!-- 上传图片 -->
                            <span v-if="isFormat == '3'" style="color: #1890ff; margin-left:110px" :disabled='flag'>未上传文件</span>
                            <span v-if="isFormat == '1'" style="color: #1890ff; cursor: pointer ;margin-left:110px" @click="downloadFile"><i class="el-icon-document" style="margin-right:7px"></i>{{form.originalFileName}}</span>
                            <el-upload v-if="commonDetail == '1'" :disabled="flag" class="upload-demo" :action="UploadFiles" :file-list="picture" :on-success="success" :headers="MyHeader" :on-preview="handlePictureCardPreview" :limit="1" :on-exceed="handleExceed" :class="{ disabled: isMax,fileMargin: isHeight }" :on-change="change" :on-error="error" :before-remove="handleBeforeRemove" :on-remove="handleRemove" :before-upload="handleBeforeUpload" accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF,.docx,.xlsx,.pptx,.doc,.xls,.ppt,.txt,.zip,.7z,.rar,.gz,.psd,.rtf,.jfif">
                                <span style="color: #1890ff; cursor: pointer;" v-if="isShow">选择上传文件</span>
                            </el-upload>
                            <div v-if="commonDetail == '2'" style="margin-top: -23px;">
                                <el-image style="width: 100px; height: 100px" :src="commonUrl" :preview-src-list="commonUrlList">
                                </el-image>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="this.roleType== '6' || this.roleType == '5'">

                    <!-- 发送群组 -->
                    <el-col :span="24" style="">
                        <el-form-item label="发送群组:" prop="notice" label-width="10%" class="notice-pad">
                            <el-select :disabled="sendOut" v-if="roleType==6&&num != 1" v-model="form.notice" multiple :placeholder="placeholder" class="noticePadding" style="width: 36%">
                                <el-option v-for="item in noticeOptions" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" multiple>
                                </el-option>
                            </el-select>
                            <el-form-item v-if="num == 1" disabled label-width="10%" style="white-space: pre-line;">{{ merchantNamede }}</el-form-item>
                            <el-form-item v-if="num == 1" disabled label-width="10%" style="white-space: pre-line;">{{ shopNamede }}</el-form-item>
                            <el-select :disabled="flag" v-if="roleType==5&&num != 1" v-model="form.notice" multiple :placeholder="placeholder" class="noticePadding" style="width: 36%">
                                <el-option v-for="item in noticeOptions" :key="item.shopId" :label="item.shopName" :value="item.shopId" multiple>
                                </el-option>
                            </el-select>
                            <!-- <el-cascader collapse-tags style="margin-top: 6px;width:35.7%" ref="cascader" @change="handleChange" v-model="form.notice" :options="treeData" :props="props" clearable></el-cascader> -->
                        </el-form-item>

                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="权重:" label-width="20%" prop="weight">
                            <el-input v-if="num != 1" placeholder="请输入权重" :disabled="flag" oninput="value=value.replace(/[^\d]/g,'')" style="width: 45%" v-model="form.weight"></el-input>
                            <el-form-item v-if="num == 1" label-width="20%" style="white-space: pre-line;">{{ form.weight }}</el-form-item>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item label="是否可用:" label-width="20%">
                            <el-radio-group v-model="form.enabled" :disabled="flag" style="width: 45%">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <div class="logtime">
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建时间:" label-width="20%">{{ form.createTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新时间:" label-width="20%">{{ form.updateTime }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="创建人:" label-width="20%">{{ form.createPersonName }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item v-if="this.num != 0" label="更新人:" label-width="20%">{{ form.updatePersonName }}</el-form-item>
                    </el-col>
                </div>
                <el-form-item label-width="10%">
                    <el-button size="mini" type="warning" @click="cancel" v-if="num != 1">取消</el-button>
                    <el-button size="mini" type="primary" v-if="num == 0" @click="onSubmit('form')">新增</el-button>
                    <el-button size="mini" type="primary" v-if="this.num == 2" @click="onUpdate('form')">确定</el-button>
                </el-form-item>
            </el-form>
        </el-card>

    </div>
</template>
<script>
export default {
    inject: ["reload"],
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            placeholder: "请选择通知群组（可多选）",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },

            current: this.$route.query.current,
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            articleType: this.$route.query.articleType,
            name: "",
            mm: "",
            options: [],
            isShow: true,
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            isHeight: false,
            roleType: "",
            MyHeader: { currentRole: "", accessToken: "", fp: "" },
            loading: true,
            UploadFiles: this.$url.UploadOthers,
            id: "",
            form: {
                redStars: "1",
                articleTitle: "",
                articleContent: "",
                fileIds: "",
                weight: "",
                enabled: "0",
                notice: []
            },
            firstPic: "",

            currentPage: 1,
            pageSize: 10,
            rules: {
                articleTitle: [
                    {
                        required: true,
                        message: "请输入标题",
                        trigger: "blur"
                    }
                ],
                articleContent: [
                    {
                        required: true,
                        message: "请选输入内容",
                        trigger: "blur"
                    }
                ],
                weight: [
                    {
                        required: true,
                        message: "请选输入权重",
                        trigger: "blur"
                    }
                ],
                notice: [
                    {
                        required: true,
                        message: "请选择发送群组",
                        trigger: "blur"
                    }
                ],
                redStars: [
                    {
                        required: true,
                        message: "请选择上传文件",
                        trigger: "blur"
                    }
                ]
            },
            sendOut: false,
            noticeOptions: [],
            daQuInclude: "",
            merchantName: "",
            shopName: "",
            merchantId: "",
            isFormat: "",
            commonDetail: "",
            commonUrl: "",
            notice: "",
            shopNamede: "",
            merchantNamede: "",
            commonUrlList: []
        }
    },
    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        if (this.roleType == undefined) {
            this.MyHeader.currentRole = localStorage
                .getItem("userType")
                .charAt(0)
        } else {
            this.MyHeader.currentRole = localStorage.getItem("roleType")
        }
        this.roleType = localStorage.getItem("roleType")
        console.log(this.roleType)
        // if (this.roleType == "6") {
        //     this.getMerchantList()
        // }
        // if (this.roleType == "5") {
        //     this.getDianPuList()
        // }

        if (this.roleType == "1") {
            // this.getMerchantList()
        }

        // 0 新增 1 详情 2 修改
        if (this.num == 0) {
            this.loading = false
            this.name = "规章制度新增"
            if (this.roleType == "6") {
                this.GetArticleDisplayGroup(1)
            }
            if (this.roleType == "5") {
                this.GetArticleDisplayGroup(0)
            }
            this.form = {}
            this.commonDetail = 1
            // this.flag4 = false;
        } else if (this.num == 1) {
            this.name = "规章制度详情"
            this.flag = true
            this.isMax = true
            this.isShow = false
            this.isHeight = true
            this.sendOut = true
            this.getArticleDetail()
        } else if (this.num == 2) {
            this.name = "规章制度修改"
            this.sendOut = true
            this.getArticleDetail()
        }
    },
    methods: {
        // 下载文件
        downloadFile() {
            console.log(this.commonUrl)
            let a = document.createElement("a")
            a.href = this.commonUrl
            a.setAttribute("download", this.commonUrl)
            a.click()
        },
        //发送群组
        async GetArticleDisplayGroup(ID, type) {
            var that = this
            const { data: res } = await this.$http.get(
                this.$url.GetArticleDisplayGroup,
                {
                    params: {
                        isPlatfrom: ID
                    }
                }
            )
            if (res.msgCode == 1) {
                this.noticeOptions = res.dtList
                if (type == "xq") {
                    that.notice.forEach(function (i) {
                        that.noticeOptions.forEach(function (n) {
                            if (ID == 1) {
                                if (i == n.merchantId) {
                                    that.form.notice.push(n.merchantName)
                                    that.merchantNamede = n.merchantName
                                    console.log(n, "345")
                                }
                            } else {
                                if (i == n.shopId) {
                                    that.form.notice.push(n.shopName)
                                    that.shopNamede = n.shopName
                                    console.log(n, "345")
                                }
                            }
                        })
                    })
                    that.placeholder = ""
                }
            }
        },
        // 获取商户列表
        async getMerchantList() {
            const data = {
                merchantName: this.merchantName,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetMerchantList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取列表成功")
                this.noticeOptions = res.dtList
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        // 获取店铺列表
        async getDianPuList() {
            const data = {
                areaIds: this.daQuInclude, //商圈id
                shopName: this.shopName,
                merchantId: this.merchantId,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(this.$url.GetShopList, {
                params: data
            })
            if (res.msgCode == 1) {
                this.noticeOptions = res.dtList
                this.loading = false
            } else {
                this.$message.success(res.message)
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },

        async onSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.roleType == 6) {
                        const data = {
                            articleType: this.articleType,
                            articleTitle: this.form.articleTitle,
                            articleContent: this.form.articleContent,
                            FileIds: this.firstPic,
                            weight: this.form.weight,
                            MerchantIds: this.form.notice.join(",")
                        }
                        if (this.firstPic === "") {
                            this.loading = false
                            return this.$message.error("请上传文件")
                        }
                        if (this.form.notice == "") {
                            return this.$message.error("请选择通知群组！")
                        }
                        this.$http
                            .post(this.$url.InsertArticle, data)
                            .then((res) => {
                                if (res.data.msgCode == 1) {
                                    this.$router.push({
                                        path: "/GuiZhangZhiDuList"
                                    })
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    } else if (this.roleType == 5) {
                        const data = {
                            articleType: this.articleType,
                            articleTitle: this.form.articleTitle,
                            articleContent: this.form.articleContent,
                            FileIds: this.firstPic,
                            weight: this.form.weight,
                            ShopIds: this.form.notice.join(",")
                        }
                        if (this.firstPic === "") {
                            this.loading = false
                            return this.$message.error("请上传文件")
                        }
                        if (this.form.notice == "") {
                            return this.$message.error("请选择通知群组！")
                        }
                        this.$http
                            .post(this.$url.InsertArticle, data)
                            .then((res) => {
                                if (res.data.msgCode == 1) {
                                    this.$router.push({
                                        path: "/GuiZhangZhiDuList"
                                    })
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    } else {
                        const data = {
                            articleType: this.articleType,
                            articleTitle: this.form.articleTitle,
                            articleContent: this.form.articleContent,
                            FileIds: this.firstPic,
                            weight: this.form.weight
                        }
                        if (this.firstPic === "") {
                            this.loading = false
                            return this.$message.error("请上传文件")
                        }
                        if (this.form.notice == "") {
                            return this.$message.error("请选择通知群组！")
                        }
                        this.$http
                            .post(this.$url.InsertArticle, data)
                            .then((res) => {
                                if (res.data.msgCode == 1) {
                                    this.$router.push({
                                        path: "/GuiZhangZhiDuList"
                                    })
                                } else {
                                    this.$message.success(res.message)
                                }
                            })
                    }
                } else {
                    return false
                }
            })
        },
        async onUpdate(formName) {
            var that = this
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const data = {
                        articleId: that.articleId,
                        articleType: that.form.articleType,
                        articleTitle: that.form.articleTitle,
                        articleContent: that.form.articleContent,
                        FileIds: that.firstPic,
                        weight: that.form.weight
                    }
                    if (this.firstPic === "") {
                        this.loading = false
                        return this.$message.error("请上传文件")
                    }
                    that.$http
                        .put(that.$url.UpdateArticle, data)
                        .then((res) => {
                            if (res.data.msgCode == 1) {
                                that.$router.push({
                                    path: "/GuiZhangZhiDuList"
                                })
                            } else {
                                that.$message.success(res.message)
                            }
                        })
                } else {
                    return false
                }
            })
        },
        async getArticleDetail() {
            this.$http
                .get(this.$url.GetArticleDetail, {
                    params: {
                        articleId: this.articleId
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        this.form = res.data.dtList[0]
                        this.form.notice = []
                        this.notice = res.data.dtList[0].displayGroup.split(",")

                        var ID = res.data.dtList[0].isPlatfrom
                        this.GetArticleDisplayGroup(ID, "xq")
                        // if (res.data.dtList[0].displayGroup != null) {
                        //     this.form.notice = res.data.dtList[0].displayGroup.split(
                        //         ","
                        //     )
                        // }
                        this.commonUrlList.push(
                            this.$url.othersPicSrc + this.form.fileIds
                        )
                        this.commonUrl =
                            this.$url.othersPicSrc + this.form.fileIds
                        const picFiles = [
                            "jpg",
                            "png",
                            "gif",
                            "bmp",
                            "jpeg",
                            "jfif"
                        ]
                        const fileIdS = this.form.fileIds.substring(
                            this.form.fileIds.lastIndexOf(".") + 1
                        )

                        if (picFiles.indexOf(fileIdS) !== -1) {
                            console.log(fileIdS)
                            this.commonDetail = 2
                            if (this.num == 2) {
                                this.isFormat = 2
                                this.commonDetail = 1
                            }
                        }
                        const filesList = [
                            "txt",
                            "word",
                            "pdf",
                            "xlsx",
                            "docx",
                            "pptx",
                            "doc",
                            "xls",
                            "ppt",
                            "zip",
                            "7z",
                            "rar",
                            "gz",
                            "psd",
                            "rtf"
                        ]
                        if (filesList.indexOf(fileIdS) !== -1) {
                            this.isFormat = 1
                            if (this.num == 2) {
                                this.isFormat = 2
                                this.commonDetail = 1
                            }
                        }
                        console.log(this.commonUrlList)
                        // this.$message.success("获取详情成功")
                        this.firstPic = res.data.dtList[0].fileIds
                        var uuu = {
                            id: this.form.fileIds,
                            name: res.data.dtList[0].originalFileName,
                            url: this.$url.othersPicSrc + this.form.fileIds
                        }
                        if (
                            this.form.fileIds != "" ||
                            this.form.fileIds != null
                        ) {
                            this.picture.push(uuu)
                            this.isMax = true
                        }
                        if (this.form.fileIds == "") {
                            console.log(this.form.fileIds == "")
                            this.commonDetail = "1"
                            this.picture = []
                        }
                        if (this.num == 1) {
                            if (
                                this.form.fileIds == "" ||
                                this.form.fileIds == null
                            ) {
                                this.isMax = true
                                this.isFormat = 3
                            }
                        }
                    } else {
                        this.$message.success(res.message)
                    }
                })
        },
        handleRemove(file) {
            this.firstPic = ""
            const data1 = [file.id, "others"]
            const { data: res } = this.$http
                .delete(this.$url.DeleteOneFile, {
                    data: data1,
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                .then((res) => {
                    if (res.data.msgCode == 1) {
                        console.log(res)
                    }
                })
        },
        handleBeforeUpload(file) {
            console.log(
                file.name.substring(file.name.lastIndexOf(".") + 1),
                "www"
            )
            const fileSuffix = file.name.substring(
                file.name.lastIndexOf(".") + 1
            )
            const whileList = [
                "jpg",
                "jpeg",
                "png",
                "gif",
                "bmp",
                "pdf",
                "JPG",
                "JPEG",
                "PBG",
                "GIF",
                "BMP",
                "PDF",
                "docx",
                "xlsx",
                "pptx",
                "doc",
                "xls",
                "ppt",
                "txt",
                "zip",
                "7z",
                "rar",
                "gz",
                "psd",
                "rtf",
                "jfif"
            ]
            if (whileList.indexOf(fileSuffix) == -1) {
                this.$message.error("不支持上传文件类型")
                return false
            }
        },
        success(response) {
            this.firstPic = response.id
            this.$refs.form.clearValidate("redStars")
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.success("最多只能上传一条文件!")
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("上传失败")
            if (fileList.length >= 1) {
                this.isMax = true
            } else {
                this.isMax = false
            }
        },
        handleBeforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        cancelCommon() {
            if (this.current == "1") {
                return this.$router.push({
                    path: "/GetStatistics",
                    query: {
                        activeName1: 1
                    }
                })
            }
            this.$router.push({ path: "/GuiZhangZhiDuList" })
        },
        cancel() {
            this.$router.push({ path: "/GuiZhangZhiDuList" })
        }
    }
}
</script>

<style scoped>
.upload-demo {
    margin-top: -30px;
}
.disabled .el-upload--picture-card {
    display: none;
}
.fileMargin {
    margin-top: -36px;
}
.el-card {
    margin-top: 15px;
}
.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}
.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.editornone .ql-toolbar {
    display: none;
}
.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
.el-upload-list {
    width: 20%;
}
</style>