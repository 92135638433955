/* 参数为手机号格式方法 */
var inputPhone = (rule, value, callback) => {
  let type = /^1[3456789]\d{9}$/;
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else if (!type.test(value)) {
    callback(new Error("格式不符，请填写正确的手机号!"));
  } else {
    callback();
  }
};
var inputIdCardNo = (rule, value, callback) => {
  // let type = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  let type = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
  if (
    value != "" &&
    type.test(value)===false
  ) {
    callback(new Error("身份证号格式不正确，请填写正确的身份证号!"));
  } else {
    callback();
  }
};
export default {
  weihurenDialogList: [
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    },
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    },
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    },
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    },
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    },
    {
      pic: require("../../../public/img/jueseren.png"),
      name: "宋东野",
      xitonghao: "1002821023",
      zhiwei: "综合经纪人",
      dianzu: "保利海涵庄园店A组"
    }
  ],
  chaoxiangOptions: [
    {
      value: "1",
      label: "东"
    },
    {
      value: "2",
      label: "东南"
    },
    {
      value: "3",
      label: "南"
    },
    {
      value: "4",
      label: "西南"
    },
    {
      value: "5",
      label: "西"
    },
    {
      value: "6",
      label: "西北"
    },
    {
      value: "7",
      label: "北"
    },
    {
      value: "8",
      label: "东北"
    }
  ],
  fnagwuzhaungkuangOptions: [
    {
      value: "0",
      label: "自住"
    },
    {
      value: "1",
      label: "空置"
    },
    {
      value: "2",
      label: "出租中"
    },
    {
      value: "3",
      label: "经营中"
    }
  ],
  kanfangshijianList: [
    {
      value: "0",
      label: "下班后可看"
    },
    {
      value: "1",
      label: "提前预约随时可看"
    },
    {
      value: "2",
      label: "只周末可看"
    },
    {
      value: "3",
      label: "有租户需预约"
    },
    {
      value: "4",
      label: "有钥匙"
    }
  ],
  zhengJianZhuangTaiList: [
    {
      value: "1",
      label: "证件不齐"
    },
    {
      value: "2",
      label: "证件齐全"
    }
  ],
  jueserenData: [
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "录入人",
      zhongjieName: "链家",
      roleType: "录入人",
      message: "无组对盘权限",
      icon: "iconfont icon-lururenyuan"
    },
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "",
      zhongjieName: "链家",
      roleType: "维护人",
      message: "24小时内仅维护人可以操作",
      icon: "iconfont icon-weihurenyuan"
    },
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "实勘人",
      zhongjieName: "链家",
      roleType: "实勘人",
      message: "24小时内仅实勘人可以操作",
      icon: "iconfont icon-shikanren"
    },
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "",
      zhongjieName: "链家",
      roleType: "钥匙人",
      message: "无组对盘权限",
      icon: "iconfont icon-icon_yuechiren"
    },
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "",
      zhongjieName: "链家",
      roleType: "证件人",
      message: "24小时内仅证件人可以操作",
      icon: "iconfont icon-zhengjianren"
    },
    {
      jueserenPic: require("../../../public/img/jueseren.png"),
      name: "王xx",
      phone: "13516861566",
      mendian: "34中唐山街店A组",
      role: "",
      zhongjieName: "链家",
      roleType: "VIP",
      message: "24小时内仅VIP可以操作",
      icon: "iconfont icon-vip"
    }
  ],
  rules: {
    yongtu: [
      {
        required: true,
        message: "请输入房屋用途",
        trigger: "change"
      }
    ],
    jianzhujiegou: [
      {
        required: true,
        message: "请选择建筑结构",
        trigger: "change"
      }
    ],
    fangZhuangXianKuang: [
      {
        required: true,
        message: "请输入房屋状况",
        trigger: "change"
      }
    ],
    articleTitle: [
      {
        required: true,
        message: "请输入文章标题",
        trigger: "blur"
      }
    ],
    weight: [{ required: true, message: "请选择单元", trigger: "change" }],
    zhuangxiu: [
      {
        required: true,
        message: "请输入装修状况",
        trigger: "change"
      }
    ],
    firstPic: [
      {
        required: true,
        message: "请上传钥匙图片",
        trigger: "blur"
      }
    ],
    chuzufangshi: [
      {
        required: true,
        message: "请选择出租方式",
        trigger: "change"
      }
    ],
    louPanName: [
      {
        required: true,
        message: "请选择楼盘",
        trigger: "change"
      }
    ],
    louDongId: [
      {
        required: true,
        message: "请选择栋座",
        trigger: "change"
      }
    ],
    danyuan: [
      {
        required: true,
        message: "请选择单元",
        trigger: "change"
      }
    ],
    createrRemark: [
      {
        required: true,
        message: "请经纪人点评此房源",
        trigger: "blur"
      }
    ],
    louceng: [
      {
        required: true,
        message: "请选择楼层",
        trigger: "change"
      }
    ],
    menPaiHao: [
      {
        required: true,
        message: "请选择门牌",
        trigger: "change"
      }
    ],
    laiyuan1: [
      {
        required: true,
        message: "请选择信息来源",
        trigger: "change"
      }
    ],
    houseAge: [
      {
        required: true,
        message: "请输入房源",
        trigger: "blur"
      }
    ],
    quanshu: [
      {
        required: true,
        message: "请输入交易权属",
        trigger: "change"
      }
    ],
    laiyuan2: [
      {
        required: true,
        message: "请选择信息来源",
        trigger: "change"
      }
    ],
    yaoshi: [
      {
        required: true,
        message: "请选择是否有钥匙",
        trigger: "change"
      }
    ],
    chuzufangshi: [
      {
        required: true,
        message: "请选择出租方式",
        trigger: "change"
      }
    ],
    name: [
      {
        required: true,
        message: "请输入业主姓名",
        trigger: "blur"
      }
    ],
    tel1: [
      {
        required: true,
        validator: inputPhone,
        trigger: "blur"
      }
    ],
    tel2: [
      {
        // required: true,
        max: 11,
        min: 11,
        validator: inputPhone,
        trigger: "blur"
      }
    ],
    idCardNo: [
      {
        required: false,
        max: 18,
        min: 18,
        validator: inputIdCardNo,
        trigger: "blur",
      }
    ],
    yezHuXingming: [
      {
        required: true,
        message: "请输入业主姓名",
        trigger: "blur"
      }
    ],
    IsPublic: [
      {
        required: true,
        message: "请选择是否公开",
        trigger: "change"
      }
    ],
    huxing: [
      {
        required: true,
        message: "请输入室户型",
        trigger: "change"
      }
    ],
    zhengJianZhuangTai: [
      {
        required: true,
        message: "请选择证件状态",
        trigger: "change"
      }
    ],
    square: [
      {
        required: true,
        message: "请填写面积",
        trigger: "blur"
      }
    ],
    checkedchaoxiang: [
      {
        required: true,
        message: "请选择朝向",
        type: "array",
        trigger: "change"
      }
    ],
    priceToLet: [
      {
        required: true,
        message: "请输入租价",
        trigger: "change"
      }
    ],
    priceToSell: [
      {
        required: true,
        message: "请输入售价",
        trigger: "change"
      }
    ],
    time: [
      {
        required: true,
        message: "请选择看房时间",
        trigger: "change"
      }
    ],
    fangWuXianZhuang:[
      { 
        required: true, 
        message: '请输入房屋现状', 
        trigger: 'change' 
      },
    ],
    shouFangYuanYin:[
      { 
        required: true, 
        message: '请输入售房原因', 
        trigger: 'change' 
      },
    ],
    HukoQingKuang:[
      { 
        required: true, 
        message: '请输入户口情况', 
        trigger: 'change' 
      },
    ],
    diYa:[
      { 
        required: true, 
        message: '请输入抵押情况', 
        trigger: 'change' 
      },
    ],
    maintenanceUserId: [
      {
        required: true,
        message: "请选择维护人",
        trigger: "change"
      }
    ],
    createPerson: [
      {
        required: true,
        message: "请选择录入人",
        trigger: "change"
      }
    ]
  }
};
