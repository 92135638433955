<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ name }}</span>
      <span style="float:right;">
        <el-button size="mini" class="el-button1" type="primary" @click="goBack"
          >返回</el-button
        >
      </span>
    </el-card>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="right"
      label-width="20%"
    >
      <el-card>
        <span
          class="xinzengXuQiu"
          style="z-index: 111; right: 150px; cursor: pointer"
          v-if="roleType == 6 && form.isDeleteESign == 1"
          @click="deleteEQB()"
          >删除此人e签宝信息</span
        >
        <h4 style="margin-top:0px;margin-bottom:0px;">业主信息</h4>
        
        <hr
          style="height:1px;border:none;backgroundColor:#ccc;margin-top:5px;margin-bottom:0px;"
        />
        
        <el-row style="margin-top:10px;">
          <el-col :span="12">
            <el-form-item label="业主姓名：" label-width="20%">
              <!-- <span @click="xianshi('customerName')" style="cursor: pointer;" :style="{color:(customerName=='查看'?'red':'#000')}">{{customerName}}</span> -->
              <span style="cursor: pointer; ">{{ form.customerName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信：" label-width="20%">
              <!-- <span @click="xianshi('weChatNo')" style="cursor: pointer;" :style="{color:(weChatNo=='查看'?'red':'#000')}">{{weChatNo}}</span> -->
              <span style="cursor: pointer; ">{{ form.weChatNo }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top:10px;">
          <el-col :span="12">
            <el-form-item label="手机号：" label-width="20%">
              <!-- <span @click="xianshi('tel1')" style="cursor: pointer;" :style="{color:(tel1=='查看'?'red':'#000')}">{{tel1}}</span> -->
              <span style="cursor: pointer; ">{{ form.tel1 }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备用手机号：" label-width="20%">
              <!-- <span @click="xianshi('tel2')" style="cursor: pointer;" :style="{color:(tel2=='查看'?'red':'#000')}">{{tel2}}</span> -->
              <span style="cursor: pointer; ">{{ form.tel2 }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      houseId: this.$route.query.houseId,
      name: "",
      roleType: window.localStorage.getItem("roleType"),
      form: {},
      rules: {},
      list: "", //详情信息
      // customerName: "查看", //业主姓名
      // tel1: "查看", //业主电话1
      // tel2: "查看", //业主电话2
      // weChatNo: "查看", //业主微信号
      customerName: "", //业主姓名
      tel1: "", //业主电话1
      tel2: "", //业主电话2
      weChatNo: "" //业主微信号
    };
  },
  created() {
    this.name = "业主信息";
    this.GetHouseDetail();
  },
  methods: {
    xianshi(type) {
      var that = this;
      if (type == "customerName") {
        that.customerName = that.customerName1;
      } else if (type == "tel1") {
        that.tel1 = that.tel11;
      } else if (type == "tel2") {
        that.tel2 = that.tel21;
      } else if (type == "weChatNo") {
        that.weChatNo = that.weChatNo1;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    //获取业主详情信息
    GetHouseDetail() {
      var that = this;
      this.$http
        .get(this.$url.GetHouseDetail, {
          params: {
            houseId: this.houseId
          }
        })
        .then(res => {
          if (res.data.msgCode == 1) {
            that.form = res.data.dtList[0];
          }
        });
    },
    async deleteEQB() {
      let that = this;
      const data = [that.form.customerId];
      console.log(data);
      const { data: res } = await this.$http.delete(
        this.$url.DeleteCustomerESign,
        {
          data: data
        }
      );

      if (res.msgCode == 1) {
        that.$message.success("删除成功！");
        // that.$emit("GetCustomerDetailRequest");
        // that.dtCustomer.type = "detail";
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style>
.xinzengXuQiu {
  color: #df0202 !important;
  padding-right: 20px;
  border-radius: 3px;
  float: right;
  font-weight: 600;
  margin-top: 3px;
  font-size: 14px;
}
</style>
