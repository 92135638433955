<template>
    <div>
        <div>
            <!-- 面包屑导航区域 -->
            <el-card class="search">
                <el-row>
                    <el-col>
                        <span class="title">公告标题：</span>
                        <el-input v-model="noticeTitle" placeholder="请输入公告标题" style="width: 20%"></el-input>
                        <!-- <span class="title" style="margin-left: 15px">公告类别：</span>
                        <el-select v-model="noticeType" placeholder="请选择公告类别" style="width: 20%">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select> -->
                        <!-- <span class="title" style="margin-left: 15px">创建时间:</span>
                        <el-date-picker style="height: 32px;margin-left: 10px;" v-model="disabledDate" type="daterange" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker> -->
                        <span class="title" style="float: right">
                            <el-button size="mini" style="margin-left: 20px" type="primary" @click="cgetArticleList">查询</el-button>
                            <el-button @click="cancelbreedList" type="warning" size="mini">重置</el-button>
                        </span>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px" v-if="roleType == 5 || roleType ==6 || roleType == 2">
                    <el-col>
                        <el-button size="mini" @click="jumpToInsert()" type="primary" icon="el-icon-plus" style="border: none">新增</el-button>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 底部内容区域 -->
            <el-card>
                <el-table ref="multipleTable" v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{
            'text-align': 'center',
            color: '#333',
            'font-size': '14px',
          }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                    <el-table-column label="公告标题">
                        <template slot-scope="scope">
                            <el-button @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0; text-decoration: underline">{{ scope.row.articleTitle }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="公告内容" prop="articleContent"></el-table-column>
                    <el-table-column label="公告类别" prop="noticeType"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column label="操作" v-if="roleType == 5 || roleType ==6 || roleType == 2">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff">修改</el-button>
                            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909">删除</el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid; margin-bottom: 50px">
                        <img style="width: 200px; margin-top: 50px" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px; margin: 0; line-height: 30px"></p>
                    </div>
                </el-table>

                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            disabledDate: "",
            // 开始时间结束时间
            dateFrom: "",
            dateTo: "",
            drow: "",
            loading: false,
            petBreedName: "",
            role: 1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            noticeTitle: "",
            noticeContent: "",
            noticeType: "",
            operationList: [
                {
                    noticeTitle: "入职申请",
                    noticeContent: "威震天已进入高新店",
                    noticeType: "入职",
                    createTime: "2021-01-28"
                },
                {
                    noticeTitle: "升职申请",
                    noticeContent: "擎天柱已晋升为店长",
                    noticeType: "升职",
                    createTime: "2021-01-28"
                }
            ],
            articleTitle: "",
            enabled: "",
            value1: "",
            options: [
                {
                    value: "重大事项",
                    label: "重大事项"
                },
                {
                    value: "交易提示",
                    label: "交易提示"
                },
                {
                    value: "一般公告",
                    label: "一般公告"
                }
            ],
            roleType: ""
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.getArticleList()
        this.roleType = window.localStorage.getItem("roleType")
    },
    watch: {
        disabledDate(val) {
            this.dateFrom = this.disabledDate[0]
            this.dateTo = this.disabledDate[1]
            console.log("a", this.dateFrom)
            console.log("b", this.dateTo)
            console.log(val)
            console.log(this.disabledDate)
        }
    },
    methods: {
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.getArticleList()
        },
        jumpToInsert() {
            this.$router.push({
                name: "GongGaoDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        openDetails2(row) {
            this.$router.push({
                name: "GongGaoDetail",
                query: {
                    articleId: row.articleId,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        /* 获取日志列表 */
        async getArticleList() {
            const data = {
                articleTitle: this.noticeTitle,
                articleType: "GONGGAOGUANLI",
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetArticleList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                // this.$message.success("获取列表成功")
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
                // for (var i = 0; i < res.dtList.length; i++) {
                //     if (res.dtList[i].enabled === "1") {
                //         this.operationList[i].enabled = "是"
                //     } else if (res.dtList[i].enabled === "0") {
                //         this.operationList[i].enabled = "否"
                //     }
                // }
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.getArticleList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.getArticleList()
        },
        //重置
        cancelbreedList() {
            this.noticeTitle = ""
            this.noticeType = ""
            this.disabledDate = ""
            this.dateFrom = ""
            this.dateTo = ""
            this.currentPage = 1
            this.getArticleList()
        },
        // 删除数据
        handleDelete(index) {
            var data1 = [index.articleId]
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    const { data: res } = this.$http
                        .delete(this.$url.DeleteArticle, {
                            data: data1,
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        })
                        .then((res) => {
                            this.loading = false
                            if (res.data.msgCode == 1) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                })
                                this.getArticleList()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.data.message
                                })
                            }
                        })
                })
                .catch((err) => err)
        },
        // 删除数据
        // handleDelete1() {
        //     var data1 = this.multipleSelection
        //     var data2 = []
        //     if (this.multipleSelection == undefined) {
        //         this.$confirm("请勾选数据", "提示", {
        //             type: "warning"
        //         }).catch((err) => {})
        //     } else {
        //         this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        //             confirmButtonText: "确定",
        //             cancelButtonText: "取消",
        //             type: "warning"
        //         })
        //             .then(() => {
        //                 for (var i = 0; i < data1.length; i++) {
        //                     data2.push(data1[i].articleId)
        //                 }
        //                 const { data: res } = this.$http.delete(
        //                     this.$url.DeleteArticle,
        //                     {
        //                         data: data2,
        //                         headers: {
        //                             "Content-Type":
        //                                 "application/json;charset=UTF-8"
        //                         }
        //                     }
        //                 )
        //                 if (res.data.msgCode == 1) {
        //                     this.loading = false
        //                     this.reload()
        //                     this.$message({
        //                         type: "success",
        //                         message: "删除成功!"
        //                     })
        //                     this.getArticleList()
        //                 } else {
        //                     this.$message.success(res.message)
        //                 }
        //             })
        //             .catch((err) => {
        //                 this.$message({
        //                     type: "error",
        //                     message: err
        //                 })
        //             })
        //     }
        // },

        xiangqing(index, row) {
            this.drow = row.articleId
            this.$router.push({
                name: "GongGaoDetail",
                query: {
                    articleId: this.drow,
                    num: 2,
                    state: 3
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
