function DataFunction() {
  let data = {
    valueType: [
      {
        value: "0",
        label: "不限"
      }
    ],
    item1: [
      {
        value: "0",
        label: "不限"
      },
      {
        value: "0",
        label: "维护盘房源"
      },
      // {
      //   value: "1",
      //   label: "店共享池房源"
      // },
      // {
      //   value: "2",
      //   label: "店东共享池房源"
      // },

      {
        value: "4",
        label: "关注房源"
      },
      {
        value: "5",
        label: "角色房源"
      },
      {
        value: "6",
        label: "无效房源"
      },
      {
        value: "7",
        label: "急售房源"
      },
      {
        value: "8",
        label: "成交房源"
      }
    ],
    item3: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "1,99",
        label: "100万以下"
      },
      {
        value: "99,149",
        label: "100-150万"
      },
      {
        value: "150,199",
        label: "150-200万"
      },
      {
        value: "200,249",
        label: "200-250万"
      },
      {
        value: "250,299",
        label: "250-300万"
      },
      {
        value: "300,499",
        label: "300-500万"
      },
      {
        value: "500,999",
        label: "500-1000万"
      },
      {
        value: "1000,9999",
        label: "1000万以上"
      }
    ],
    item4: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "1,49",
        label: "50平米以下"
      },
      {
        value: "50,69",
        label: "50-70平米"
      },
      {
        value: "70,89",
        label: "70-90平米"
      },
      {
        value: "90,109",
        label: "90-110平米"
      },
      {
        value: "110,129",
        label: "110-130平米"
      },
      {
        value: "130,149",
        label: "130-150平米"
      },
      {
        value: "150,199",
        label: "150-200平米"
      },
      {
        value: "200,99999",
        label: "200平米以上"
      }
    ],
    item5: [
      {
        value: "",
        label: "不限"
      },
      {
        value: "1",
        label: "填写卧室数"
      },
    ],
    itemWC: [
      // {
      //   value: "",
      //   label: "不限"
      // },
      {
        value: "1",
        label: "填写卫生间数"
      },
    ],
    item6: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "-10,-1",
        label: "地下室"
      },
      {
        value: "1,1",
        label: "一层"
      },
      {
        value: "120,120",
        label: "填写楼层"
      }
    ],
    item7: [
      {
        value: "1",
        label: "东"
      },
      {
        value: "2",
        label: "东南"
      },
      {
        value: "3",
        label: "南"
      },
      {
        value: "4",
        label: "西南"
      },
      {
        value: "5",
        label: "西"
      },
      {
        value: "6",
        label: "西北"
      },
      {
        value: "7",
        label: "北"
      },
      {
        value: "8",
        label: "东北"
      }
    ],
    item8: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "1,4999",
        label: "5000元/㎡以下"
      },
      {
        value: "5000,9999",
        label: "5000-10000元/㎡"
      },
      {
        value: "10000,14999",
        label: "10000-15000元/㎡"
      },
      {
        value: "15000,19999",
        label: "15000-20000元/㎡"
      },
      {
        value: "20000,24999",
        label: "20000-25000元/㎡"
      },
      {
        value: "25000,29999",
        label: "25000-30000元/㎡"
      },
      {
        value: "30000,49999",
        label: "30000-50000元/㎡"
      },
      {
        value: "50000,999999",
        label: "50000元/㎡以上"
      }
    ],
    shaixuan: [
      {
        value: "1",
        label: "钥匙",
        shaixuanList: [
          {
            value: "1-1",
            label: "有钥匙",
            text: "1"
          },
          {
            value: "1-2",
            label: "无钥匙",
            text: "0"
          }
        ]
      },
      {
        value: "2",
        label: "我的角色",
        shaixuanList: [
          {
            value: "2-1",
            label: "录入人",
            text: "createPerson"
          },
          {
            value: "2-2",
            label: "维护人",
            text: "maintenanceUserId"
          },
          {
            value: "2-3",
            label: "钥匙人",
            text: "keyUserId"
          },
          {
            value: "2-4",
            label: "实勘人",
            text: "shiKanUserId"
          },
          {
            value: "2-5",
            label: "证件人",
            text: "certificatesUserId"
          }
        ]
      },
      {
        value: "3",
        label: "房状现况",
        shaixuanList: [
          {
            value: "3-1",
            label: "自住"
          },
          {
            value: "3-2",
            label: "空置"
          },
          {
            value: "3-3",
            label: "出租中"
          },
          {
            value: "3-4",
            label: "经营中"
          }
        ]
      },
      {
        value: "4",
        label: "证件状态",
        shaixuanList: [
          {
            value: "4-1",
            label: "证件不全"
          },
          {
            value: "4-2",
            label: "证件齐全"
          }
        ]
      },
      //   {
      //     value: "6",
      //     label: "房屋用途",
      //     shaixuanList: []
      //   },
      // {
      //   value: "7",
      //   label: "房状等级",
      //   shaixuanList: [
      //     {
      //       value: "7-1",
      //       label: "A"
      //     },
      //     {
      //       value: "7-2",
      //       label: "B"
      //     },
      //     {
      //       value: "7-3",
      //       label: "C"
      //     }
      //   ]
      // },
      {
        value: "5",
        label: "装修",
        shaixuanList: []
      },
      {
        value: "6",
        label: "审核状态",
        shaixuanList: [
          {
            value: "6-1",
            label: "审核已通过"
          },
          {
            value: "6-2",
            label: "未审核"
          }
        ]
      },{
        value: "7",
        label: "实勘",
        shaixuanList: [
          {
            value: "7-1",
            label: "有实勘",
            text: "1"
          },
          {
            value: "7-2",
            label: "无实勘",
            text: "0"
          }
        ]
      }
    ],
    item10: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "0,2",
        label: "两年内"
      },
      {
        value: "0,5",
        label: "五年内"
      },
      {
        value: "0,10",
        label: "十年内"
      },
      {
        value: "0,15",
        label: "十五年内"
      },
      {
        value: "0,20",
        label: "二十年内"
      },
      {
        value: "0,30",
        label: "三十年内"
      },
      {
        value: "30,120",
        label: "三十年以上"
      }
    ],
    item11: [
      {
        value: "0",
        label: "不限"
      },
      {
        value: "1",
        label: "下班后可看"
      },
      {
        value: "2",
        label: "提前预约随时可看"
      },
      {
        value: "3",
        label: "只周末可看"
      },
      {
        value: "4",
        label: "有租户需预约"
      },
      {
        value: "5",
        label: "有钥匙"
      }
    ],
    item12: [
      {
        value: "0,0",
        label: "不限"
      },
      {
        value: "1,1000",
        label: "1000元以下"
      },
      {
        value: "1000,1499",
        label: "1000-1500"
      },
      {
        value: "1500,1999",
        label: "1500-2000"
      },
      {
        value: "2000,2499",
        label: "2000-2500"
      },
      {
        value: "2500,2999",
        label: "2500-3000"
      },
      {
        value: "3000,4999",
        label: "3000-5000"
      },
      {
        value: "5000,99999",
        label: "5000元以上"
      }
    ],
    shouMaiLeiXingOptions: [
      {
        value: "0",
        label: "不限"
      },
      {
        value: "1",
        label: "即将开盘"
      },
      // {
      //   value: "2",
      //   label: "排卡中"
      // },
      {
        value: "2",
        label: "在售"
      },
      {
        value: "3",
        label: "售罄"
      }
    ]
  };
  return data;
}
export default {
  /* 参数只能为数字方法 */
  Function() {
    return DataFunction();
  }
};
