<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: "App",
    provide() {
        return {
            reload: this.reload,
            roleType: ""
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    mounted() {
        //    关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
        var token = document.cookie.split(";")
        if (document.cookie.indexOf("token=1") == -1) {
            localStorage.clear()
        }
        // for (var i = 0; i < token.length; i++) {

        // if (token[i].indexOf("token=1") == -1) {
        //     localStorage.clear()
        // }
        // }
    },
    created() {
        axios.interceptors.request.use(config => {
            if (localStorage.getItem("accessToken")) {
                this.roleType = window.localStorage.getItem("roleType")
                if (this.roleType == undefined) {
                    config.headers.common.currentRole = localStorage
                        .getItem("userType")
                        .charAt(0)
                } else {
                    config.headers.common.currentRole = localStorage.getItem(
                        "roleType"
                    )
                }
                config.headers.common.accessToken = localStorage.getItem(
                    "accessToken"
                )
                config.headers.common.fp = localStorage.getItem("fp")
                // this.instance.defaults.headers.common['tokens'] = token
            } else {
                this.$router.push("/GetLogin")
                // window.location.href='http://demo3.dongliankeji.com/#/GetLogin'
            }
            return config
        })
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function() {
                this.isRouterAlive = true
            })
        }
    }
}
</script>
