<template>
    <div>
        <div>
            <el-card>
                <el-table ref="multipleTable"  v-loading="loading" :data="operationList" tooltip-effect="dark" :header-cell-style="{ 'text-align': 'center','color':'#333','font-size':'14px'}" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange" border stripe>
                    <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
                    <el-table-column v-if="roleType==6" label="角色" prop="areaName">
                        <template slot-scope="scope">
                            <el-button class="tableButSpan" @click="openDetails2(scope.row)" type="text" size="mini" style="color: #1268c0;text-decoration: underline">{{ scope.row.areaName }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="提成比例" prop="businessName"></el-table-column>
                    <!-- <el-table-column label="维护人提成比" prop="maintenanceUserRatio"></el-table-column>
                    <el-table-column label="实勘人提成比" prop="shiKanUserRatio"></el-table-column>
                    <el-table-column label="钥匙人提成比" prop="keyUserRatio"></el-table-column>
                    <el-table-column label="证件人提成比" prop="certificatesUserRatio"></el-table-column>-->
                    <el-table-column label="创建人" prop="createPersonName"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope" class="right">
                            <el-button @click.native.stop="xiangqing(scope.$index, scope.row)" type="text" size="mini" class="el-icon-edit" style="color: #1890ff;">修改</el-button>
                            <!-- <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="mini" class="el-icon-delete" style="color: #da0909;">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <div slot="empty" style="display: inline-grid;margin-bottom: 50px;">
                        <img style="width: 200px;margin-top: 50px;" src="../../../public/img/noList.png" />
                        <p style="font-size: 14px;margin: 0;line-height: 30px;"> </p>
                    </div>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
var _index
export default {
    name: "GetStaffList",
    inject: ["reload"],
    data() {
        return {
            drow: "",
            loading: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            operationList: [
                {
                    shopname: "七贤岭",
                    luruCommission: "8%",
                    weihuCommission: "10%",
                    shikanCommission: "5%",
                    yaoshiCommission: "3%",
                    zhengjianCommission: "2%",
                    time: "2021-05-10"
                }
            ],
            articleTitle: "",
            enabled: "",
            roleType: localStorage.getItem("roleType")
        }
    },
    created() {
        /* 获取所有的日志列表 */
        this.GetRatioConfigList()
    },
    methods: {
        // 查询
        cgetArticleList() {
            this.currentPage = 1
            this.GetRatioConfigList()
        },
        //新增
        jumpToInsert() {
            this.$router.push({
                name: "TiChengBiDetail",
                query: {
                    articleId: 0,
                    num: 0,
                    state: 1
                }
            })
        },
        //详情
        openDetails2(row) {
            this.$router.push({
                name: "TiChengBiDetail",
                query: {
                    commonMasterId: row.commonMasterId,
                    areaName: row.areaName,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    num: 1,
                    state: 2
                }
            })
        },
        // 获取多选框的值
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        async GetRatioConfigList() {
            const data = {
                commonMasterName: "",
                commonMasterTypes: "TICHENGBILI",
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            const { data: res } = await this.$http.get(
                this.$url.GetCommonMasterList,
                {
                    params: data
                }
            )
            if (res.msgCode == 1) {
                this.operationList = res.dtList
                this.loading = false
                this.total = res.maxRecords
            } else {
                this.$message.success(res.message)
            }
        },
        /* 监听pagesize改变的事件 */
        handleSizeChange(newSize) {
            this.pageSize = newSize
            this.GetRatioConfigList()
        },
        /* 监听页码值改变的事件 */
        handleCurrentChange(newPage) {
            this.currentPage = newPage
            this.GetRatioConfigList()
        },
        //重置
        cancelbreedList() {
            this.articleTitle = ""
            this.enabled = ""
            this.currentPage = 1
            this.GetRatioConfigList()
        },

        //修改
        xiangqing(index, row) {
            // this.drow = row.articleId
            this.$router.push({
                name: "TiChengBiDetail",
                query: {
                    commonMasterId: row.commonMasterId,
                    areaName: row.areaName,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    num: 2,
                    state: 3
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-card {
    margin-top: 15px;
}
</style>
