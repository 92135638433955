<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ name }}</span>
      <el-button
        @click="cancel"
        size="mini"
        class="el-button1"
        type="primary"
        style="float: right"
        >返回</el-button
      >
    </el-card>

    <el-dialog
      class="heguiDialog"
      title="客源录入合规告知"
      :visible.sync="heguiDialogVisible"
      width="38%"
      :before-close="heguiDialogClose"
    >
      <div v-html="hegui"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="heguiDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <div class="div-tabs" v-loading="loading">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-position="right"
      >
        <div class="title">基本信息</div>
        <el-card>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="20%" label="姓名:" prop="customerName">
                <el-input
                  style="width: 60%"
                  placeholder="请输入姓名"
                  v-model="ruleForm.customerName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="20%" label="性别:" prop="gender">
                <el-radio-group v-model="ruleForm.gender">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label-width="20%" label="手机号:" prop="tel1">
                <el-input
                  placeholder="请输入手机号"
                  v-model="ruleForm.tel1"
                  maxlength="11"
                  style="width: 60%"
                  class="shoujihaoSelect input-with-select"
                >
                </el-input>
                <div>
                  <i
                    class="el-icon-warning"
                    style="color: red; margin-right: 5px"
                  ></i
                  >联系方式不可更改，且会作为核查真带看的重要依据，请认真填写
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label-width="20%" label="其他手机号:" prop="tel2">
                <el-input
                  placeholder="请输入其他手机号"
                  v-model="ruleForm.tel2"
                  maxlength="11"
                  style="width: 60%"
                  class="shoujihaoSelect input-with-select"
                >
                </el-input>
                <div>
                  <i
                    class="el-icon-warning"
                    style="color: red; margin-right: 5px"
                  ></i
                  >联系方式不可更改，且会作为核查真带看的重要依据，请认真填写
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="意愿等级:"
                label-width="20%"
                prop="customerScore"
              >
                <el-select
                  v-model="ruleForm.customerScore"
                  placeholder="请选择意愿等级"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in customerScoreOptions"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="信息来源:" label-width="20%" required>
                <div class="xuqiuForm-item">
                  <el-form-item prop="laiyuan">
                    <el-select
                      v-model="ruleForm.laiyuan"
                      @change="changeLaiyuan"
                      placeholder="请选择信息来源"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in laiyuanOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <span style="margin: 0 4px">-</span>
                  <el-form-item prop="xinXiLaiYuanId">
                    <el-select
                      v-model="ruleForm.xinXiLaiYuanId"
                      placeholder="请选择信息来源"
                      style="width: 100%"
                    >
                      <span v-if="ruleForm.laiyuan">
                        <el-option
                          v-for="item in laiyuanOptions[ruleForm.laiyuan]
                            .laiyuan1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </span>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label-width="20%"
                label="客户标签:"
                prop="keHuBiaoQianId"
              >
                <el-select
                  v-model="ruleForm.keHuBiaoQianId"
                  placeholder="请选择客户标签"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in biaoqianOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="客户年龄:" label-width="20%" prop="ageRange">
                <el-select
                  v-model="ruleForm.ageRange"
                  placeholder="请选择客户年龄"
                  style="width: 60%"
                >
                  <el-option
                    v-for="item in kehuNianLingOptions"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="身份证号:" label-width="20%" prop="idCardNo">
                <el-input
                  style="width: 60%"
                  placeholder="请输入身份证号"
                  v-model="ruleForm.idCardNo"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="微信:" label-width="20%" prop="wechatNo">
                <el-input
                  style="width: 60%"
                  placeholder="请输入微信"
                  v-model="ruleForm.wechatNo"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="邮箱:" label-width="20%" prop="email">
                <el-input
                  style="width: 60%"
                  placeholder="请输入邮箱"
                  v-model="ruleForm.email"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item class="xiayibu">
            <el-button size="mini" type="primary" @click="save('ruleForm')"
              >检查重复</el-button
            >
          </el-form-item>
        </el-card>
      </el-form>

      <div class="" style="margin: 10px 0px">
        *请保护用户隐私，确保遵守<span
          style="color: red; cursor: pointer"
          @click="heguiDialogVisible = true"
          >《客源录入合规告知》</span
        >
      </div>

      <el-tabs
        class="qiugou-tabs"
        style="top: 0px; background-color: #fff"
        v-for="(item, i) in xuqiuList"
        :key="i"
      >
        <el-card style="margin-top: 20px !important">
          <el-form
            :ref="'ruleFormDetail' + [i]"
            :model="ruleFormDetail[i]"
            :rules="xuQiuRules"
            label-position="right"
          >
            <el-row>
              <el-col
                :span="12"
                v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
              >
                <el-form-item
                  label-width="20%"
                  label="购房目的"
                  prop="gouFangMuDi"
                >
                  <el-select
                    v-model="ruleFormDetail[i].gouFangMuDi"
                    placeholder="请选择购房目的"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in gouFangMuDiOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="房屋用途:"
                  prop="fangWuYongTuId"
                >
                  <el-select
                    v-model="ruleFormDetail[i].fangWuYongTuId"
                    placeholder="请选择房屋用途"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in fangwuyongtuOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="ruleFormDetail[i].fangWuYongTuId != '买二手'"
              >
                <el-form-item
                  label-width="20%"
                  label="是否整租:"
                  prop="isZhengZu"
                >
                  <el-radio-group v-model="ruleFormDetail[i].isZhengZu">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="20%" label="意向价位:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="priceFrom">
                      <el-input
                        v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                        style="width: 100%"
                        placeholder="请输入最小值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>99999)value=99999"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].priceFrom"
                      ></el-input>
                      <el-input
                        v-if="ruleFormDetail[i].fangWuYongTuId == '租赁'"
                        style="width: 100%"
                        placeholder="请输入最小值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].priceFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="priceTo">
                      <el-input
                        v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                        style="width: 100%"
                        placeholder="请输入最大值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>99999)value=99999"
                        :min="ruleFormDetail[i].priceFrom"
                        type="number"
                        v-model="ruleFormDetail[i].priceTo"
                      ></el-input>
                      <el-input
                        v-if="ruleFormDetail[i].fangWuYongTuId == '租赁'"
                        style="width: 100%"
                        placeholder="请输入最大值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>999999999)value=999999999"
                        :min="ruleFormDetail[i].priceFrom"
                        type="number"
                        v-model="ruleFormDetail[i].priceTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">{{
                      ruleFormDetail[i].fangWuYongTuId == "买二手"
                        ? "万元"
                        : "元"
                    }}</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="需求等级:"
                  label-width="20%"
                  prop="requirementLevel"
                >
                  <el-select
                    v-model="ruleFormDetail[i].requirementLevel"
                    placeholder="请选择需求等级"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in requirementLevelOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="20%" label="面积:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="squareFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最小值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>99999)value=99999"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].squareFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="squareTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最大值(整数)"
                        class="padding-r0"
                        onkeyup="this.value=this.value.replace(/^(\d*\.?\d{0,2}).*/,'$1')"
                        oninput="if(value>99999)value=99999"
                        :min="ruleFormDetail[i].squareFrom"
                        type="number"
                        v-model="ruleFormDetail[i].squareTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">平</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="20%" label="居室:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="roomCountFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入居室(整数)"
                        class="padding-r0"
                        oninput="if(value>=9)value=9"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].roomCountFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="roomCountTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入居室(整数)"
                        class="padding-r0"
                        oninput="if(value>=9)value=9  "
                        :min="ruleFormDetail[i].roomCountFrom"
                        type="number"
                        v-model="ruleFormDetail[i].roomCountTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">居</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label-width="20%" label="城市:" prop="city">
                  <el-input
                    style="width: 60%"
                    placeholder="请输入城市"
                    maxlength="16"
                    v-model="ruleFormDetail[i].city"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="意向商圈:"
                  prop="areaIds"
                >
                  <el-cascader
                    v-model="ruleFormDetail[i].areaIds"
                    :options="cascaderShangquanOptions"
                    @change="shangquanChange"
                    :props="shangquanProps"
                    :show-all-levels="false"
                    clearable
                    style="width: 60%"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <el-form-item label-width="20%" label="楼层:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="louCengFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最小值"
                        class="padding-r0"
                        oninput="if(value>120)value=120"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].louCengFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="louCengTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最大值"
                        class="padding-r0"
                        oninput="if(value>120)value=120"
                        :min="ruleFormDetail[i].louCengFrom"
                        type="number"
                        v-model="ruleFormDetail[i].louCengTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">层</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
              >
                <span class="redStar" style="margin-left: 70px">*</span>
                <el-form-item
                  label-width="20%"
                  label="朝向:"
                  prop="orientations"
                  style="margin-top: -35px"
                >
                  <el-checkbox-group
                    v-model="ruleFormDetail[i].orientationsLabel"
                    @change="
                      handleCheckedChaoxiang(
                        ruleFormDetail[i].orientationsLabel,
                        i
                      )
                    "
                  >
                    <el-checkbox
                      v-for="orientations in orientationOptions"
                      :label="orientations.label"
                      :key="orientations.label"
                      >{{ orientations.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="装修状况:"
                  prop="zhuangXiuZhuangKuangId"
                >
                  <el-select
                    v-model="ruleFormDetail[i].zhuangXiuZhuangKuangId"
                    placeholder="请选择装修状况"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="zhuangxiu in zhuangxiuOptions"
                      :key="zhuangxiu.value"
                      :label="zhuangxiu.label"
                      :value="zhuangxiu.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="20%" label="房龄:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="houseAgeFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最小值"
                        class="padding-r0"
                        oninput="if(value>120)value=120"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].houseAgeFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="houseAgeTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最大值"
                        class="padding-r0"
                        oninput="if(value>120)value=120"
                        :min="ruleFormDetail[i].houseAgeFrom"
                        type="number"
                        v-model="ruleFormDetail[i].houseAgeTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">年</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="贷款首付/全款可付:"
                  required
                >
                  <div class="xuqiuForm-item">
                    <el-form-item prop="shouFuFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最小值"
                        class="padding-r0"
                        oninput="if(value>9999)value=9999"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].shouFuFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="shouFuTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最大值"
                        class="padding-r0"
                        oninput="if(value>9999)value=9999"
                        :min="ruleFormDetail[i].shouFuFrom"
                        type="number"
                        v-model="ruleFormDetail[i].shouFuTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">万元</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="付款方式:"
                  prop="fuKuanFangShi"
                >
                  <el-select
                    v-if="ruleFormDetail[i].fangWuYongTuId == '买二手'"
                    v-model="ruleFormDetail[i].fuKuanFangShi"
                    placeholder="请选择付款方式"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in qiuGouFuKuanOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleFormDetail[i].fangWuYongTuId != '买二手'">
              <el-col :span="12">
                <el-form-item
                  label-width="20%"
                  label="付款方式:"
                  prop="fuKuanFangShi"
                >
                  <el-select
                    v-model="ruleFormDetail[i].fuKuanFangShi"
                    placeholder="请选择付款方式"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in qiuZuFuKuanOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col
                :span="12"
                v-if="
                  ruleFormDetail[i].fangWuYongTuId == '买二手' &&
                  ruleFormDetail[i].fuKuanFangShi != '全款'
                "
              >
                <el-form-item label-width="20%" label="月供:" required>
                  <div class="xuqiuForm-item">
                    <el-form-item prop="yueGongFrom">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最小值"
                        class="padding-r0"
                        oninput="if(value>99999)value=99999"
                        min="0"
                        type="number"
                        v-model="ruleFormDetail[i].yueGongFrom"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">-</span>
                    <el-form-item prop="yueGongTo">
                      <el-input
                        style="width: 100%"
                        placeholder="请输入最大值"
                        class="padding-r0"
                        oninput="if(value>99999)value=99999"
                        :min="ruleFormDetail[i].yueGongFrom"
                        type="number"
                        v-model="ruleFormDetail[i].yueGongTo"
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 4px">元</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item class="xiayibu">
              <el-button
                size="mini"
                type="primary"
                @click="saveXuQiu('ruleFormDetail' + [i], i)"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import KeYuanJs from "./KeYuan.js";
export default {
  inject: ["reload"],
  components: {},
  watch: {},
  data() {
    var inputPhone = (rule, value, callback) => {
      if (this.ruleForm.tel1 == "") {
        callback(new Error("请输入手机号"));
      } else if (this.ruleForm.tel2 == this.ruleForm.tel1) {
        callback(new Error("两次输入手机号一致!"));
      } else if (!this.$commonJs.numberPhone(this.ruleForm.tel1)) {
        callback(new Error("格式不符，请填写正确的手机号!"));
      } else {
        callback();
      }
    };
    var inputPhone2 = (rule, value, callback) => {
      if (
        this.ruleForm.tel2 != "" &&
        this.ruleForm.tel1 != "" &&
        this.ruleForm.tel2 == this.ruleForm.tel1
      ) {
        callback(new Error("两次输入手机号一致!"));
      } else if (
        this.ruleForm.tel2 != "" &&
        !this.$commonJs.numberPhone(this.ruleForm.tel2)
      ) {
        callback(new Error("格式不符，请填写正确的手机号!"));
      } else {
        callback();
      }
    };
    var inputEmail = (rule, value, callback) => {
      if (
        this.ruleForm.email != "" &&
        !this.$commonJs.numberEmail(this.ruleForm.email)
      ) {
        callback(new Error("格式不符，请填写正确的邮箱!"));
      } else {
        callback();
      }
    };
    var inputIdCardNo = (rule, value, callback) => {
      if (
        this.ruleForm.idCardNo != "" &&
        !this.$commonJs.shenfenZheng(this.ruleForm.idCardNo)
      ) {
        callback(new Error("身份证号格式不正确，请填写正确的身份证号!"));
      } else {
        callback();
      }
    };
    var NumberFormat = (param1, param2) => {
      let that = this;
      that.ruleFormDetail[that.formDetailIndex][param1] =
        that.$commonJs.NumberFormat(
          that.ruleFormDetail[that.formDetailIndex][param1]
        );
      that.ruleFormDetail[that.formDetailIndex][param2] =
        that.$commonJs.NumberFormat(
          that.ruleFormDetail[that.formDetailIndex][param2]
        );
    };
    var inputPrice1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("priceFrom", "priceTo");
      if (that.ruleFormDetail[that.formDetailIndex].priceTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "priceTo"
        );
      }
      callback();
    };
    var inputPrice2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("priceFrom", "priceTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].priceTo <
        that.ruleFormDetail[that.formDetailIndex].priceFrom
      ) {
        callback(new Error("最大金额需大于最小金额!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].priceTo == 0) {
        callback(new Error("请输入金额!"));
      } else {
        callback();
      }
    };
    var inputSquare1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("squareFrom", "squareTo");
      if (that.ruleFormDetail[that.formDetailIndex].squareTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "squareTo"
        );
      }
      callback();
    };
    var inputSquare2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("squareFrom", "squareTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].squareTo <
        that.ruleFormDetail[that.formDetailIndex].squareFrom
      ) {
        callback(new Error("最大面积需大于最小面积!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].squareTo == 0) {
        callback(new Error("请输入面积!"));
      } else {
        callback();
      }
    };
    var inputRoomCount1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("roomCountFrom", "roomCountTo");
      if (that.ruleFormDetail[that.formDetailIndex].roomCountTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "roomCountTo"
        );
      }
      callback();
    };
    var inputRoomCount2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("roomCountFrom", "roomCountTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].roomCountTo <
        that.ruleFormDetail[that.formDetailIndex].roomCountFrom
      ) {
        callback(new Error("最多居室需大于最少居室!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].roomCountTo == 0) {
        callback(new Error("请输入居室!"));
      } else {
        callback();
      }
    };
    var inputShouFu1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("shouFuFrom", "shouFuTo");
      if (that.ruleFormDetail[that.formDetailIndex].shouFuTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "shouFuTo"
        );
      }
      callback();
    };
    var inputShouFu2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("shouFuFrom", "shouFuTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].shouFuTo <
        that.ruleFormDetail[that.formDetailIndex].shouFuFrom
      ) {
        callback(new Error("最多首付需多于最少首付!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].shouFuTo == 0) {
        callback(new Error("请输入首付!"));
      } else {
        callback();
      }
    };
    var inputYueGong1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("yueGongFrom", "yueGongTo");
      if (that.ruleFormDetail[that.formDetailIndex].yueGongTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "yueGongTo"
        );
      }
      callback();
    };
    var inputYueGong2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("yueGongFrom", "yueGongTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].yueGongTo <
        that.ruleFormDetail[that.formDetailIndex].yueGongFrom
      ) {
        callback(new Error("最多月供需大于最少月供!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].yueGongTo == 0) {
        callback(new Error("请输入月供!"));
      } else {
        callback();
      }
    };
    var inputLouCeng1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("louCengFrom", "louCengTo");
      if (that.ruleFormDetail[that.formDetailIndex].louCengTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "louCengTo"
        );
      }
      callback();
    };
    var inputLouCeng2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("louCengFrom", "louCengTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].louCengTo <
        that.ruleFormDetail[that.formDetailIndex].louCengFrom
      ) {
        callback(new Error("最高楼层需高于最低楼层!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].louCengTo == 0) {
        callback(new Error("请输入楼层!"));
      } else {
        callback();
      }
    };
    var inputHouseAge1 = (rule, value, callback) => {
      let that = this;
      NumberFormat("houseAgeFrom", "houseAgeTo");
      if (that.ruleFormDetail[that.formDetailIndex].houseAgeTo !== "") {
        that.$refs["ruleFormDetail" + that.formDetailIndex][0].validateField(
          "houseAgeTo"
        );
      }
      callback();
    };
    var inputHouseAge2 = (rule, value, callback) => {
      let that = this;
      NumberFormat("houseAgeFrom", "houseAgeTo");
      if (
        that.ruleFormDetail[that.formDetailIndex].houseAgeTo <
        that.ruleFormDetail[that.formDetailIndex].houseAgeFrom
      ) {
        callback(new Error("最大房龄需大于最小房龄!"));
      } else if (that.ruleFormDetail[that.formDetailIndex].houseAgeTo == 0) {
        callback(new Error("请输入房龄!"));
      } else {
        callback();
      }
    };
    return {
      num: this.$route.query.num,
      state: this.$route.query.state, //1求购 0是求租
      name: "",
      show: false,
      loading: false,
      addPhoneNumber: false,
      xuqiuList: [],
      ruleFormDetail: [],
      customerId: "",
      ruleForm: {
        customerName: "",
        gender: "",
        tel1: "",
        tel2: "",
        customerScore: "",
        laiyuan: "",
        xinXiLaiYuanId: "",
        idCardNo: "",
        wechatNo: "",
        email: "",
        ageRange: "",
        keHuBiaoQianId: "", //客户标签,
      },
      rules: {
        customerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        tel1: [
          {
            required: true,
            validator: inputPhone,
            trigger: "blur",
          },
        ],
        tel2: [
          {
            required: false,
            validator: inputPhone2,
            trigger: "blur",
          },
        ],
        customerScore: [
          {
            required: true,
            message: "请选择意愿等级",
            trigger: "change",
          },
        ],
        laiyuan: [
          {
            required: true,
            message: "请选择信息来源",
            trigger: "change",
          },
        ],
        xinXiLaiYuanId: [
          {
            required: true,
            message: "请选择信息来源",
            trigger: "change",
          },
        ],
        keHuBiaoQianId: [
          {
            required: true,
            message: "请选择客户标签",
            trigger: "change",
          },
        ],
        idCardNo: [
          {
            required: false,
            validator: inputIdCardNo,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: false,
            validator: inputEmail,
            trigger: "blur",
          },
        ],
      },
      xuQiuRules: {
        gouFangMuDi: [
          {
            required: true,
            message: "请选择购房目的",
            trigger: "change",
          },
        ],
        priceFrom: [
          {
            required: true,
            validator: inputPrice1,
            trigger: "blur",
          },
        ],
        priceTo: [
          {
            required: true,
            validator: inputPrice2,
            trigger: "blur",
          },
        ],
        squareFrom: [
          {
            required: true,
            validator: inputSquare1,
            trigger: "blur",
          },
        ],
        squareTo: [
          {
            required: true,
            validator: inputSquare2,
            trigger: "blur",
          },
        ],
        roomCountFrom: [
          {
            required: true,
            validator: inputRoomCount1,
            trigger: "change",
          },
        ],
        roomCountTo: [
          {
            required: true,
            validator: inputRoomCount2,
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: "请输入城市",
            trigger: "blur",
          },
        ],
        areaIds: [
          {
            required: true,
            message: "请选择意向商圈",
            trigger: "change",
          },
        ],
        fangWuYongTuId: [
          {
            required: true,
            message: "请选择房屋用途",
            trigger: "change",
          },
        ],
        fuKuanFangShi: [
          {
            required: true,
            message: "请选择付款方式",
            trigger: "change",
          },
        ],
        shouFuFrom: [
          {
            required: true,
            validator: inputShouFu1,
            trigger: "blur",
          },
        ],
        shouFuTo: [
          {
            required: true,
            validator: inputShouFu2,
            trigger: "blur",
          },
        ],
        yueGongFrom: [
          {
            required: true,
            validator: inputYueGong1,
            trigger: "blur",
          },
        ],
        yueGongTo: [
          {
            required: true,
            validator: inputYueGong2,
            trigger: "blur",
          },
        ],
        zhuangXiuZhuangKuangId: [
          {
            required: true,
            message: "请选择装修状况",
            trigger: "change",
          },
        ],
        orientationsLabel: [
          {
            required: true,
            message: "请选择朝向",
            trigger: "change",
          },
        ],
        louCengFrom: [
          {
            required: true,
            validator: inputLouCeng1,
            trigger: "blur",
          },
        ],
        louCengTo: [
          {
            required: true,
            validator: inputLouCeng2,
            trigger: "blur",
          },
        ],
        houseAgeFrom: [
          {
            required: true,
            validator: inputHouseAge1,
            trigger: "blur",
          },
        ],
        houseAgeTo: [
          {
            required: true,
            validator: inputHouseAge2,
            trigger: "blur",
          },
        ],
        isZhengZu: [
          {
            required: true,
            message: "请选择是否整租",
            trigger: "change",
          },
        ],
        requirementLevel: [
          {
            required: true,
            message: "请选择需求等级",
            trigger: "change",
          },
        ],
      },
      laiyuanOptions: [],
      customerScoreOptions: KeYuanJs.customerScoreOptions,
      biaoqianOptions: [],
      kehuNianLingOptions: KeYuanJs.kehuNianLingOptions,
      hunyinZhuangKuangOptions: KeYuanJs.hunyinZhuangKuangOptions,
      mingxiaFangChanOptions: KeYuanJs.mingxiaFangChanOptions,
      daikuanDiLuOptions: KeYuanJs.daikuanDiLuOptions,
      gouFangMuDiOptions: KeYuanJs.gouFangMuDiOptions,
      jushiOptions: KeYuanJs.jushiOptions,
      shangquanProps: { multiple: true, checkStrictly: true },
      shangquan: [],
      oldValAreaIds: [],
      shangquanParams: [],
      cascaderShangquanOptions: KeYuanJs.cascaderShangquanOptions,
      fangwuyongtuOptions: [
        { value: "买二手", label: "买二手" },
        { value: "租赁", label: "租赁" },
      ],
      qiuZuFuKuanOptions: KeYuanJs.qiuZuFuKuanOptions,
      qiuGouFuKuanOptions: KeYuanJs.qiuGouFuKuanOptions,
      zhuangxiuOptions: [],
      orientationOptions: this.$commonJs.orientationOptions,
      orientations: [0, 0, 0, 0, 0, 0, 0, 0],
      requirementLevelOptions: KeYuanJs.requirementLevelOptions,
      xuqiuDialogVisible: false,
      xuqiuProps: { multiple: true },
      xuqiuGouOptions: KeYuanJs.xuqiuGouOptions,
      xuqiuZuOptions: KeYuanJs.xuqiuZuOptions,
      qiugouXuqiuValue: [],
      qiuzuXuqiuValue: [],
      heguiDialogVisible: false, //合规书弹层
      hegui: KeYuanJs.hegui,
      formDetailIndex: 0,
      fangWu: "",
    };
  },
  created() {
    // 0 新增 1 详情 2 修改
    if (this.num == 0) {
      if (this.state == "1") {
        this.name = "求购客源新增";
        this.fangWu = "买二手";
      } else {
        this.name = "求租客源新增";
        this.fangWu = "租赁";
      }
    } else if (this.num == 1) {
      if (this.state == "1") {
        this.name = "求购客源详情";
      } else {
        this.name = "求租客源详情";
      }
    }
    this.getShuJuZiDianList();
  },

  watch: {},
  onShow() {},
  methods: {
    getShuJuZiDianList() {
      //数据字典
      let that = this;
      that.$commonJs
        .getShuJuZiDianList(
          "XINXILAIYUAN,KEHUBIAOQIAN,ZHUANGXIUZHUANGKUANG,SHANGQUAN"
        )
        .then((data) => {
          that.laiyuanOptions = data.XinXiLaiYuan;
          that.biaoqianOptions = data.kehuBiaoQian;
          that.zhuangxiuOptions = data.ZHUANGXIUZHUANGKUANG;
          that.cascaderShangquanOptions = data.cascaderShangquan;
        });
    },
    changeLaiyuan() {
      this.ruleForm.xinXiLaiYuanId = "";
    },
    // 朝向多选选中
    handleCheckedChaoxiang(val, i) {
      let that = this;
      let orientationsLabel = that.ruleFormDetail[i].orientationsLabel;
      that.orientations = [0, 0, 0, 0, 0, 0, 0, 0];
      orientationsLabel.forEach((item, x) => {
        if (item == "东") {
          that.orientations[0] = 1;
        }
        if (item == "东南") {
          that.orientations[1] = 1;
        }
        if (item == "南") {
          that.orientations[2] = 1;
        }
        if (item == "西南") {
          that.orientations[3] = 1;
        }
        if (item == "西") {
          that.orientations[4] = 1;
        }
        if (item == "西北") {
          that.orientations[5] = 1;
        }
        if (item == "北") {
          that.orientations[6] = 1;
        }
        if (item == "东北") {
          that.orientations[7] = 1;
        }
      });
      that.ruleFormDetail[i].orientations = that.orientations;
    },
    shangquanChange(value, i) {
      let that = this;
      that.shangquanParams = [];
      if (value.length > 3) {
        that.$message.info("最多只能选三项");
        that.$nextTick(() => {
          that.shangquanParams = that.shangquan;
          that.ruleFormDetail[i].areaIds = that.oldValAreaIds;
        });
      } else {
        value.forEach((item) => {
          that.shangquanParams.push(item[1]);
        });
        that.shangquan = that.shangquanParams;
        that.oldValAreaIds = value;
      }
    },
    xuqiuChange(value) {
      console.log(this.qiugouXuqiuValue);
    },
    saveXuqiu() {
      let that = this;
      let addXuqiuList = {
        requirementName: "",
        id: "add",
        xuqiuType: "add",
        yiTuijian: false,
        gouFangMuDi: "",
        priceFrom: 0,
        priceTo: 0,
        squareFrom: 0,
        squareTo: 0,
        roomCountFrom: 0,
        roomCountTo: 0,
        city: "大连",
        shangquan: [],
        fangWuYongTuId: that.fangWu,
        fuKuanFangShi: "",
        isZhengZu: "",
        shouFuFrom: 0,
        shouFuTo: 0,
        yueGongFrom: 0,
        yueGongTo: 0,
        zhuangXiuZhuangKuang: "",
        orientations: [],
        orientationsLabel: [],
        louCengFrom: 0,
        louCengTo: 0,
        houseAgeFrom: 0,
        houseAgeTo: 0,
        requirementLevel: "",
        genJinRequirement: "",
      };
      that.xuqiuList.push(JSON.parse(JSON.stringify(addXuqiuList)));
      that.ruleFormDetail = that.xuqiuList;
      that.xuqiuValue = [];
      let activeIndex = window.localStorage.getItem("activeIndex");
      that.$nextTick(() => {
        if (activeIndex == "KeYuanList") {
          let checkboxList = [];
          checkboxList = document.querySelectorAll(
            ".el-cascader-menu:nth-child(1) .el-checkbox"
          );
          for (let index = 0; index < checkboxList.length; index++) {
            checkboxList[index].style.display = "none";
          }
        }
      });
    },
    deleteXuqiu(item, type, i) {
      let that = this;
      this.$confirm("确认关闭？")
        .then((_) => {
          that.ruleForm.title = "";
          done();
        })
        .catch((_) => {});
    },
    xuqiuDialogClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    heguiDialogClose(done) {
      done();
    },
    async save(ruleForm) {
      let that = this;
      that.$refs[ruleForm].validate(async (valid) => {
        console.log("this.ruleForm", that.ruleForm);
        if (valid) {
          const data = {
            Tel1: that.ruleForm.tel1,
            Tel2: that.ruleForm.tel2,
          };
          const { data: res } = await this.$http.post(
            this.$url.CheckCustomerExist,
            data
          );
          if (res.msgCode == 1) {
            that
              .$confirm(res.message, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                that.$message.warning("请添加需求！");
                if (that.customerId == "") {
                  if (that.xuqiuList.length == 0) {
                    that.saveXuqiu();
                  }
                }
              })
              .catch(() => {});
          } else {
            that
              .$confirm(res.message, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {})
              .catch(() => {});
          }
        } else {
          return false;
        }
      });
    },
    saveInformation(i) {
      let that = this,
        url = "",
        method = "";
      that.loading = true;
      const data = {
        customerName: that.ruleForm.customerName,
        gender: that.ruleForm.gender,
        tel1: that.ruleForm.tel1,
        tel2: that.ruleForm.tel2,
        customerScore: that.ruleForm.customerScore,
        xinXiLaiYuanId: that.ruleForm.xinXiLaiYuanId,
        idCardNo: that.ruleForm.idCardNo,
        wechatNo: that.ruleForm.wechatNo,
        email: that.ruleForm.email,
        ageRange: that.ruleForm.ageRange,
        keHuBiaoQianId: that.ruleForm.keHuBiaoQianId, //客户标签,
        requirementName: "",
      };
      if (that.state == 1) {
        data.isCustomerToBuy = "1";
      } else {
        data.isCustomerToBuy = "0";
      }
      method = "post";
      url = that.$url.InsertCustomer;
      that.$http[method](url, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          if (res.data.msgCode == 1) {
            if (res.data.newCustomerId) {
              that.customerId = res.data.newCustomerId;
              that.saveXuQiuData(i);
            }
          } else {
            that.$message.error(res.data.message);
          }
          that.loading = false;
        })
        .catch((res) => {
          that.$message.error("请求失败！");
        });
    },
    async saveXuQiu(ruleFormDetail, i) {
      //修改需求
      let that = this;
      that.formDetailIndex = i; //需求下标
      console.log(this.ruleFormDetail);
      this.$refs[ruleFormDetail][0].validate(async (valid) => {
        if (valid) {
          that.saveInformation(i);
        }
      });
    },
    async saveXuQiuData(i) {
      let that = this,
        url = "",
        method = "";
      that.loading = true;
      const data = {
        customerId: that.customerId,
        squareFrom: that.ruleFormDetail[i].squareFrom,
        squareTo: that.ruleFormDetail[i].squareTo,
        roomCountFrom: that.ruleFormDetail[i].roomCountFrom,
        roomCountTo: that.ruleFormDetail[i].roomCountTo,
        city: that.ruleFormDetail[i].city,
        areaIds: that.shangquanParams.toString(),
        fangWuYongTuId: that.ruleFormDetail[i].fangWuYongTuId,
        requirementLevel: that.ruleFormDetail[i].requirementLevel,
        fuKuanFangShi: that.ruleFormDetail[i].fuKuanFangShi,
        requirementName: that.ruleFormDetail[i].fangWuYongTuId,
      };
      if (that.ruleFormDetail[i].fangWuYongTuId == "买二手") {
        //求购需求
        data.gouFangMuDi = that.ruleFormDetail[i].gouFangMuDi;
        data.priceFrom = that.ruleFormDetail[i].priceFrom * 10000;
        data.priceTo = that.ruleFormDetail[i].priceTo * 10000;
        data.shouFuFrom = that.ruleFormDetail[i].shouFuFrom * 10000;
        data.shouFuTo = that.ruleFormDetail[i].shouFuTo * 10000;
        if (typeof that.ruleFormDetail[i].orientations == "object") {
          data.orientations = that.ruleFormDetail[i].orientations.join("");
        } else {
          data.orientations = that.ruleFormDetail[i].orientations.toString();
        }

        data.louCengFrom = that.ruleFormDetail[i].louCengFrom;
        data.louCengTo = that.ruleFormDetail[i].louCengTo;
        data.houseAgeFrom = that.ruleFormDetail[i].houseAgeFrom;
        data.houseAgeTo = that.ruleFormDetail[i].houseAgeTo;
        data.yueGongFrom = that.ruleFormDetail[i].yueGongFrom;
        data.yueGongTo = that.ruleFormDetail[i].yueGongTo;
        data.zhuangXiuZhuangKuangId =
          that.ruleFormDetail[i].zhuangXiuZhuangKuangId;
        data.isCustomerToBuy = "1";
        if (that.ruleFormDetail[i].requirementToBuyId) {
          data.requirementToBuyId = that.ruleFormDetail[i].requirementToBuyId;
          url = that.$url.UpdateRequirementToBuy;
          method = "put";
        } else {
          url = that.$url.InsertRequirementToBuy;
          method = "post";
        }
      } else {
        //求租需求
        data.isCustomerToBuy = "0";
        data.priceFrom = that.ruleFormDetail[i].priceFrom;
        data.priceTo = that.ruleFormDetail[i].priceTo;
        data.isZhengZu = that.ruleFormDetail[i].isZhengZu;
        if (that.ruleFormDetail[i].requirementToRentId) {
          //求租需求修改
          data.requirementToRentId = that.ruleFormDetail[i].requirementToRentId;
          url = that.$url.UpdateRequirementToRent;
          method = "put";
        } else {
          url = that.$url.InsertRequirementToRent;
          method = "post";
        }
      }

      await that.$http[method](url, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }).then((res) => {
        that.loading = false;
        if (res.data.msgCode == 1) {
          that.$message.success("保存成功！");
          if (that.ruleFormDetail[i].fangWuYongTuId == "买二手") {
            that.ruleFormDetail[i].requirementToBuyId =
              res.data.requirementToBuyId;
          } else {
            that.ruleFormDetail[i].requirementToRentId =
              res.data.requirementToRentId;
          }
          this.$router.push({
            path: "KeYuanList",
            query: {
              state: this.state,
            },
          });

          that.$emit("GetCustomerDetailRequest");
        } else {
          that.$message.error(res.data.message);
        }
      });
    },
    cancel() {
      // return window.history.go(-1)
      this.$router.push({
        name: "KeYuanList",
        query: {
          state: this.state,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.padding-r0 /deep/ input {
  padding-right: 0px !important;
}

.div-tabs {
  margin-top: 15px;
  font-size: 14px;
  background-color: transparent;
  /deep/ .el-card {
    margin-top: 0px !important;
    border-top: 1px solid #ebeef5 !important;
  }
  /deep/ .el-card__body {
    margin: 20px auto 0 !important;
  }
  .title {
    background-color: #fff;
    padding: 15px 20px 15px;
    margin-top: 20px;
  }
  .xiayibu {
    text-align: center;
    margin: 50px auto 0px;
    /deep/ .el-form-item__content {
      margin-left: 0px !important;
    }
  }
  /deep/ .el-card {
    border-radius: 0px !important;
    border: none;
  }
  /deep/ .el-input-group__prepend {
    width: 30%;
  }
  .shoujihaoSelect /deep/.el-input__suffix {
    top: 6px;
  }
  /deep/ .el-input-group__prepend {
    background-color: #ffffff;
  }
}
.heguiDialog {
  /deep/ .el-dialog__body {
    padding: 0px 20px;
    color: #000;
    .hegui-div {
      margin-bottom: 10px;
    }
  }
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #ddd;
  }
}
.xuqiuForm-item {
  display: flex;
  /deep/ .el-form-item {
    width: 29%;
    margin-bottom: 0px !important;
  }
  /deep/ .el-form-item__content {
    margin-left: 0px;
  }
}
</style>