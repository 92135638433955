export default {
  // 表单对象
  form: {
    companyName: "×", //企业名称
    contractNo: "×", //合同编号
    // texu: "x", //受谁特许
    sellerName: "×", //甲方出租方姓名
    chuZu_YingWenName: "×", //甲方出租方英文名
    chuZu_Sex: "×", //甲方出租方性别
    chuZu_GuoJi: "×", //甲方出租方国籍
    chuZu_ChuShengRiQi: "×", //甲方出租方出生日期
    chuZu_ZhengJianMing: "身份证", //甲方出租方证件名称
    sellerIdCardNo: "×", //甲方出租方证件号码
    sellerMobile: "×", // 甲方出租方联系电话
    chuZu_Email: "×", // 甲方出租方电子邮箱
    chuZu_Address: "×", // 甲方出租方通讯地址
    chuZuDL_Name: "×", //甲方出租方代理人姓名
    chuZuDL_YingWenName: "×", //甲方出租方代理人英文名
    chuZuDL_Sex: "×", //甲方出租方代理人性别
    chuZuDL_GuoJi: "×", //甲方出租方代理人国籍
    chuZuDL_ChuShengRiQi: "×", //甲方出租方代理人出生日期
    chuZuDL_ZhengJianMing: "×", //甲方出租方代理人证件名称
    chuZuDL_ZhengJianNum: "×", //甲方出租方代理人证件号码
    chuZuDL_Phone: "×", // 甲方出租方代理人联系电话
    chuZuDL_Email: "×", // 甲方出租方代理人电子邮箱
    chuZuDL_Address: "×", // 甲方出租方代理人通讯地址
    chuZu1: "×", // 甲方（出租方）
    chuZu_Year: "×", // 甲方出租方-年
    chuZu_Month: "×", // 甲方出租方-月
    chuZu_Day: "×", // 甲方出租方-日

    buyerName: "×", //乙方承租方姓名
    buyerMobile: "×", // 乙方承租方联系电话
    chengZu_YingWenName: "×", //乙方承租方英文名
    chengZu_Sex: "×", //乙方承租方性别
    chengZu_GuoJi: "×", //乙方承租方国籍
    chengZu_ChuShengRiQi: "×", //乙方承租方出生日期
    chengZu_ZhengJianMing: "身份证", //乙方承租方证件名称
    buyerIdCardNo: "×", //乙方承租方证件号码
    chengZu_Phone: "×", // 乙方承租方联系电话
    chengZu_Email: "×", // 乙方承租方电子邮箱
    chengZu_Address: "×", // 乙方承租方通讯地址
    chengZuDL_Name: "×", //乙方承租方代理人姓名
    chengZuDL_YingWenName: "×", //乙方承租方代理人英文名
    chengZuDL_Sex: "×", //乙方承租方代理人性别
    chengZuDL_Phone: "×",//乙方承租方代理人证件号码
    chengZuDL_GuoJi: "×", //乙方承租方代理人国籍
    chengZuDL_ChuShengRiQi: "×", //乙方承租方代理人出生日期
    chengZuDL_ZhengJianMing: "×", //乙方承租方代理人证件名称
    chengZuDL_ZhengJianNum: "×", //乙方承租方代理人证件号码
    chengZuDL_Email: "×", // 乙方承租方代理人电子邮箱
    chengZuDL_Address: "×", // 乙方承租方代理人通讯地址
    chengZu1: "×", // 乙方（承租方）
    chengZu_Year: "×", // 乙方承租方-年
    chengZu_Month: "×", // 乙方承租方-月
    chengZu_Day: "×", // 乙方承租方-日

    qianShu_Year: "×", // 签署日期-年
    qianShu_Month: "×", // 签署日期-月
    qianShu_Day: "×", // 签署日期-日

    chuZu2: "×", // 甲方（出租方）
    chengZu2: "×", // 乙方（承租方）
    bingFang1: "×", // 丙方（居间方）
    fangWu_Address: "×", //房屋地址
    fangWu_MianJi: "×", //房屋面积
    quanShu: "×", //房屋权属
    suoYouQuanHao: "×", //房屋所有权证书编号
    gongTongQuanHao: "×", //房屋共同权证证号
    suoYouQuanName: "×", //房屋所有权人姓名或名称
    FWYT: "×", //房屋租赁用途
    juZhuRen: "×", //房屋居住人数
    zuiDuoJuZhuRen: "×", //房屋居住最多不超过多少人
    zuLinFromYear: "×", //房屋租赁期开始-年
    zuLinFromMonth: "×", //房屋租赁期开始-月
    zuLinFromDay: "×", //房屋租赁期开始-日
    zuLinToYear: "×", //房屋租赁期结束-年
    zuLinToMonth: "×", //房屋租赁期结束-月
    zuLinToDay: "×", //房屋租赁期结束-日
    zuLinJiaoFuYear: "×", //房屋租赁期结束交付时间-年
    zuLinJiaoFuMonth: "×", //房屋租赁期结束交付时间-月
    zuLinJiaoFuDay: "×", //房屋租赁期结束交付时间-日
    zuLinJiaoFuGJDSY: "×", //房屋租赁期共計多少個月
    zuQiJieMan_Month: "×", //租期届满前多少个月
    YueZu_XiaoXieYuan: "×", //月租金标准及支付方式：人民币（小写）元
    YueZu_DaXieYuan: "×", //月租金标准及支付方式：人民币（大写）元

    jf_QiTaFeiYong: "×", //租赁期间，由甲方承担其他费用
    yf_QiTaFeiYong: "×", //租赁期间，由乙方承担其他费用

    zjFuKuanFangShi_Month: "□", //租金的付款方式-月
    zjFuKuanFangShi_JiFu: "□", //租金的付款方式-季付
    zjFuKuanFangShi_BanNianFu: "□", //租金的付款方式-半年付
    zjFuKuanFangShi_Year: "□", //租金的付款方式-年付
    zjFuKuanFangShi_DiSanFangZhiFU: "□", //租金的付款方式-第三方平台支付（微信、支付宝等）
    zjFuKuanFangShi_ZhuangZhangZhiPiao: "□", //租金的付款方式-转账支票
    zjFuKuanFangShi_YinHangHuiKuan: "□", //租金的付款方式-银行汇款
    zjFuKuanFangShi_YinHangZhuangZhang: "□", //租金的付款方式-银行转账
    zjFuKuanFangShi_XianJin: "□", //租金的付款方式-现金

    zj_Year1: "×", //租金1的结算时间-年
    zj_Month1: "×", //租金1的结算时间-月
    zj_Day1: "×", //租金1的结算时间-日
    zj_Year2: "×", //租金2的结算时间-年
    zj_Month2: "×", //租金2的结算时间-月
    zj_Day2: "×", //租金2的结算时间-日
    zj_Year3: "×", //租金3的结算时间-年
    zj_Month3: "×", //租金3的结算时间-月
    zj_Day3: "×", //租金3的结算时间-日
    zj_Money1: "×", //租金1共计多少元

    zj_Year4: "×", //租金4的结算时间-年
    zj_Month4: "×", //租金4的结算时间-月
    zj_Day4: "×", //租金4的结算时间-日
    zj_Year5: "×", //租金5的结算时间-年
    zj_Month5: "×", //租金5的结算时间-月
    zj_Day5: "×", //租金5的结算时间-日
    zj_Year6: "×", //租金6的结算时间-年
    zj_Month6: "×", //租金6的结算时间-月
    zj_Day6: "×", //租金6的结算时间-日
    zj_Money2: "×", //租金2共计多少元

    zj_Year7: "×", //租金7的结算时间-年
    zj_Month7: "×", //租金7的结算时间-月
    zj_Day7: "×", //租金7的结算时间-日
    zj_Year8: "×", //租金8的结算时间-年
    zj_Month8: "×", //租金8的结算时间-月
    zj_Day8: "×", //租金8的结算时间-日
    zj_Year9: "×", //租金9的结算时间-年
    zj_Month9: "×", //租金9的结算时间-月
    zj_Day9: "×", //租金9的结算时间-日
    zj_Money3: "×", //租金3共计多少元

    zj_Year10: "×", //租金10的结算时间-年
    zj_Month10: "×", //租金10的结算时间-月
    zj_Day10: "×", //租金10的结算时间-日
    zj_Year11: "×", //租金11的结算时间-年
    zj_Month11: "×", //租金11的结算时间-月
    zj_Day11: "×", //租金11的结算时间-日
    zj_Year12: "×", //租金12的结算时间-年
    zj_Month12: "×", //租金12的结算时间-月
    zj_Day12: "×", //租金12的结算时间-日
    zj_Money4: "×", //租金4共计多少元

    sffs_S: "□", //发生过非正常死亡事件-是
    sffs_F: "□", //发生过非正常死亡事件-否

    BaoZhengJin_XiaoXieYuan: "×", //履约保证金：人民币（小写）元
    BaoZhengJin_DaXieYuan: "×", //履约保证金：人民币（大写）元

    jf_ChengDanJiGeYue: "×", //甲方承担责任：多少个月租金的金额
    yf_YuQiDay: "×", //乙方承担责任：逾期多少天
    yf_ChengDanJiGeYue: "×", //乙方承担责任：多少个月租金的金额

    jf_JuJianFangXiaoXieYuan: "×", //居间方报酬与责任：甲方一次性支付居间方居间服务费用人民币（小写）元
    jf_JuJianFangDaXieYuan: "×", //居间方报酬与责任：甲方一次性支付居间方居间服务费用人民币（大写）元
    yf_JuJianFangXiaoXieYuan: "×", //居间方报酬与责任：乙方一次性支付居间方居间服务费用人民币（小写）元
    yf_JuJianFangDaXieYuan: "×", //居间方报酬与责任：乙方一次性支付居间方居间服务费用人民币（大写）元

    jf_SFYJSFZ: "□", //甲方证件原件身份证
    jf_SFYJJJGZ: "□", //甲方证件原件军/警官证
    jf_SFYJHZ: "□", //甲方证件原件护照
    jf_SFYJGATXZ: "□", //甲方证件原件往来港澳通行证
    jf_SFYJQYYYZZ: "□", //甲方证件原件企业营业执照
    jf_SFYJZZJGDMZ: "□", //甲方证件原件组织机构代码证
    jf_SFYJXGSFZ: "□", //甲方证件原件香港身份证
    jf_SFYJGAJMNDTXZ: "□", //甲方证件原件港澳居民来往内地通行证
    jf_SFYJTWJMDLTXZ: "□", //甲方证件原件台湾居民来往大陆通行证
    jf_SFYJJJSFZ: "□", //甲方证件原件军/警身份证
    jf_SFYJTYSHXYDMZ: "□", //甲方证件原件统一社会信用代码证
    jf_SFYJLWTWTXZ: "□", //甲方证件原件往来台湾通行证
    jf_SFYJAMJMSFZ: "□", //甲方证件原件澳门居民身份证
    jf_SFYJSWDJZ: "□", //甲方证件原件税务登记证
    jf_SFYJQT: "□", //甲方证件原件其他证件
    jf_SFYJQTVal: "×", //甲方身份证件原件其他证件值

    // jf_CQYJTWJMDLTXZ: "□", //甲方证件原件产权证
    // jf_CQYJJJSFZ: "□", //甲方证件原件使用权证
    // jf_CQYJTYSHXYDMZ: "□", //甲方证件原件购房合同及发票
    // jf_CQYJLWTWTXZ: "□", //甲方证件原件借款合同及发票
    // jf_CQYJAMJMSFZ: "□", //甲方证件原件军产证
    // jf_CQYJSWDJZ: "□", //甲方证件原件地产证
    // jf_CQYJQT: "□", //甲方证件原件其他证件
    jf_CQYJQTVal: "×", //甲方产权证件原件其他证件值

    yf_SFYJSFZ: "□", //乙方证件原件身份证
    yf_SFYJJJGZ: "□", //乙方证件原件军/警官证
    yf_SFYJHZ: "□", //乙方证件原件护照
    yf_SFYJGATXZ: "□", //乙方证件原件往来港澳通行证
    yf_SFYJQYYYZZ: "□", //乙方证件原件企业营业执照
    yf_SFYJZZJGDMZ: "□", //乙方证件原件组织机构代码证
    yf_SFYJXGSFZ: "□", //乙方证件原件香港身份证
    yf_SFYJGAJMNDTXZ: "□", //乙方证件原件港澳居民来往内地通行证
    yf_SFYJTWJMDLTXZ: "□", //乙方证件原件台湾居民来往大陆通行证
    yf_SFYJJJSFZ: "□", //乙方证件原件军/警身份证
    yf_SFYJTYSHXYDMZ: "□", //乙方证件原件统一社会信用代码证
    yf_SFYJLWTWTXZ: "□", //乙方证件原件往来台湾通行证
    yf_SFYJAMJMSFZ: "□", //乙方证件原件澳门居民身份证
    yf_SFYJSWDJZ: "□", //乙方证件原件税务登记证
    yf_SFYJQT: "□", //乙方证件原件其他证件
    yf_SFYJQTVal: "×", //乙方身份证件原件其他证件

    jf_SFFYJSFZ: "□", //甲方证件复印件身份证
    jf_SFFYJJJGZ: "□", //甲方证件复印件军/警官证
    jf_SFFYJHZ: "□", //甲方证件复印件护照
    jf_SFFYJGATXZ: "□", //甲方证件复印件往来港澳通行证
    jf_SFFYJQYYYZZ: "□", //甲方证件复印件企业营业执照
    jf_SFFYJZZJGDMZ: "□", //甲方证件复印件组织机构代码证
    jf_SFFYJXGSFZ: "□", //甲方证件复印件香港身份证
    jf_SFFYJGAJMNDTXZ: "□", //甲方证件复印件港澳居民来往内地通行证
    jf_SFFYJTWJMDLTXZ: "□", //甲方证件复印件台湾居民来往大陆通行证
    jf_SFFYJJJSFZ: "□", //甲方证件复印件军/警身份证
    jf_SFFYJTYSHXYDMZ: "□", //甲方证件复印件统一社会信用代码证
    jf_SFFYJLWTWTXZ: "□", //甲方证件复印件往来台湾通行证
    jf_SFFYJAMJMSFZ: "□", //甲方证件复印件澳门居民身份证
    jf_SFFYJSWDJZ: "□", //甲方证件复印件税务登记证
    jf_SFFYJQT: "×", //甲方证件复印件其他证件
    jf_SFFYJQTVal: "×", //甲方身份证件复印件其他证件

    // jf_CQFYJTWJMDLTXZ: "□", //甲方证件复印件产权证
    // jf_CQFYJJJSFZ: "□", //甲方证件复印件使用权证
    // jf_CQFYJTYSHXYDMZ: "□", //甲方证件复印件购房合同及发票
    // jf_CQFYJLWTWTXZ: "□", //甲方证件复印件借款合同及发票
    // jf_CQFYJAMJMSFZ: "□", //甲方证件复印件军产证
    // jf_CQFYJSWDJZ: "□", //甲方证件复印件地产证
    // jf_CQFYJQT: "□", //甲方证件复印件其他证件
    jf_CQFYJQTVal: "×", //甲方产权证件复印件其他证件

    yf_SFFFYJSFZ: "□", //乙方证件复印件身份证
    yf_SFFYJJJGZ: "□", //乙方证件复印件军/警官证
    yf_SFFYJHZ: "□", //乙方证件复印件护照
    yf_SFFYJGATXZ: "□", //乙方证件复印件往来港澳通行证
    yf_SFFYJQYYYZZ: "□", //乙方证件复印件企业营业执照
    yf_SFFYJZZJGDMZ: "□", //乙方证件复印件组织机构代码证
    yf_SFFYJXGSFZ: "□", //乙方证件复印件香港身份证
    yf_SFFYJGAJMNDTXZ: "□", //乙方证件复印件港澳居民来往内地通行证
    yf_SFFYJTWJMDLTXZ: "□", //乙方证件复印件台湾居民来往大陆通行证
    yf_SFFYJJJSFZ: "□", //乙方证件复印件军/警身份证
    yf_SFFYJTYSHXYDMZ: "□", //乙方证件复印件统一社会信用代码证
    yf_SFFYJLWTWTXZ: "□", //乙方证件复印件往来台湾通行证
    yf_SFFYJAMJMSFZ: "□", //乙方证件复印件澳门居民身份证
    yf_SFFYJSWDJZ: "□", //乙方证件复印件税务登记证
    yf_SFFYJQT: "□", //乙方证件复印件其他证件
    yf_SFFYJQTVal: "×", //乙方身份证件复印件其他证件

    jf_YTGFWDJB: "□", //甲方以提供大连房屋登记信息表（产调）
    jf_WYTGFWDJB: "□", //甲方是否未提供大连房屋登记信息表（产调）

    QTYDSX: "×", //其他约定事项
    chuZu3: "×", //甲方（出租方）
    chengZu3: "×", //乙方（承租方）
    jf_WTDLR: "×", //委托代理人
    yf_WTDLR: "×", //委托代理人
    BFZ: "×", //丙方（章）
    JJZYRYQZ1: "×", //经纪执业人员签字
    ZGZCZSBH1: "×", //资格注册证书编号
    JJZYRYQZ2: "×", //经纪执业人员签字
    ZGZCZSBH2: "×", //资格注册证书编号
    LXRQZ: "×", //联系人签字
    LXDH: "×", //联系电话
    
    checkedJiaFangFeiYong: [], //租赁期间，由甲方承担费用选中值
    checkedYiFangFeiYong: [], //租赁期间，由乙方承担费用选中值
    checkedCQZYJ: [], //甲方产权证件原件
    checkedCQZFYJ: [], //甲方产权证件复印件
    jf_YTGFWDJBRadio: "", //甲方以提供大连房屋登记信息表（产调）-单选
    SFFSRadio: "", //是否发生
    ZJFKFSRadio: "", //租赁期间，租金的付款方式
    ZJZFFSRadio: "", //租赁期间，租金的支付方式
    JFSFZMYJRadio: "", //甲方身份证件原件
    YFSFZMYJRadio: "", //乙方身份证件原件
    JFSFZMFYJRadio: "", //甲方身份证件复印件
    YFSFZMFYJRadio: "", //乙方身份证件复印件

    bcxysm:"x"//补充协议说明
  },
  formCheck: {
    jf_YTGFWDJBRadio: "", //甲方以提供大连房屋登记信息表（产调）-单选
    SFFSRadio: "", //是否发生
    checkedJiaFangFeiYong: [], //租赁期间，由甲方承担费用选中值
    checkedYiFangFeiYong: [], //租赁期间，由乙方承担费用选中值
    ZJFKFSRadio: "", //租赁期间，租金的付款方式
    ZJZFFSRadio: "", //租赁期间，租金的支付方式
    JFSFZMYJRadio: "", //甲方身份证件原件
    checkedCQZYJ: [], //甲方产权证件原件
    YFSFZMYJRadio: "", //乙方身份证件原件
    JFSFZMFYJRadio: "", //甲方身份证件复印件
    checkedCQZFYJ: [], //甲方产权证件复印件
    YFSFZMFYJRadio: "" //乙方身份证件复印件
  },
  JiaFangFeiYongOptions: [
    { name: "水费", id: "jf_ShuiFei" },
    { name: "电费", id: "jf_DianFei" },
    { name: "电话费", id: "jf_DianHuaFei" },
    { name: "有线电视初装费", id: "jf_DianShiChuZhuangFei" },
    { name: "有线电视电视收视费", id: "jf_DianShiShouShiFei" },
    { name: "供暖费", id: "jf_GongNuanFei" },
    { name: "燃气费", id: "jf_RanQiFei" },
    { name: "燃气初装费", id: "jf_RanQiChuZhuangFei" },
    { name: "房屋租赁税费", id: "jf_ZuLinShuiFei" },
    { name: "卫生费", id: "jf_WeiShengFei" },
    { name: "宽带初装费", id: "jf_KuanDaiChuZhuangFei" },
    { name: "宽带使用", id: "jf_KuanDaiFei" },
    { name: "上网费", id: "jf_ShangWangFei" },
    { name: "车位费", id: "jf_CheWeiFei" },
    { name: "室内设施维修费", id: "jf_SheShiWeiXiuFei" },
    { name: "电梯费", id: "jf_DianTiFei" },
    { name: "煤气初装费", id: "jf_MeiQiChuZhuangFei" },
    { name: "煤气费", id: "jf_MeiQiFei" },
    { name: "物业管理费", id: "jf_WuYeGuanLiFei" },
    { name: "其他", id: "jf_QiTaFei" }
  ],
  JiaFangFeiYongList: [
    //甲方费用列表
    "jf_ShuiFei",
    "jf_DianFei",
    "jf_DianHuaFei",
    "jf_DianShiChuZhuangFei",
    "jf_DianShiShouShiFei",
    "jf_GongNuanFei",
    "jf_RanQiFei",
    "jf_RanQiChuZhuangFei",
    "jf_ZuLinShuiFei",
    "jf_WeiShengFei",
    "jf_KuanDaiChuZhuangFei",
    "jf_KuanDaiFei",
    "jf_ShangWangFei",
    "jf_CheWeiFei",
    "jf_SheShiWeiXiuFei",
    "jf_DianTiFei",
    "jf_MeiQiChuZhuangFei",
    "jf_MeiQiFei",
    "jf_WuYeGuanLiFei",
    "jf_QiTaFei"
  ],
  YiFangFeiYongOptions: [
    { name: "水费", id: "yf_ShuiFei" },
    { name: "电费", id: "yf_DianFei" },
    { name: "电话费", id: "yf_DianHuaFei" },
    { name: "有线电视初装费", id: "yf_DianShiChuZhuangFei" },
    { name: "有线电视电视收视费", id: "yf_DianShiShouShiFei" },
    { name: "供暖费", id: "yf_GongNuanFei" },
    { name: "燃气费", id: "yf_RanQiFei" },
    { name: "燃气初装费", id: "yf_RanQiChuZhuangFei" },
    { name: "房屋租赁税费", id: "yf_ZuLinShuiFei" },
    { name: "卫生费", id: "yf_WeiShengFei" },
    { name: "宽带初装费", id: "yf_KuanDaiChuZhuangFei" },
    { name: "宽带使用", id: "yf_KuanDaiFei" },
    { name: "上网费", id: "yf_ShangWangFei" },
    { name: "车位费", id: "yf_CheWeiFei" },
    { name: "室内设施维修费", id: "yf_SheShiWeiXiuFei" },
    { name: "电梯费", id: "yf_DianTiFei" },
    { name: "煤气初装费", id: "yf_MeiQiChuZhuangFei" },
    { name: "煤气费", id: "yf_MeiQiFei" },
    { name: "物业管理费", id: "yf_WuYeGuanLiFei" },
    { name: "其他", id: "yf_QiTaFei" }
  ],
  YiFangFeiYongList: [
    //乙方费用列表
    "yf_ShuiFei",
    "yf_DianFei",
    "yf_DianHuaFei",
    "yf_DianShiChuZhuangFei",
    "yf_DianShiShouShiFei",
    "yf_GongNuanFei",
    "yf_RanQiFei",
    "yf_RanQiChuZhuangFei",
    "yf_ZuLinShuiFei",
    "yf_WeiShengFei",
    "yf_KuanDaiChuZhuangFei",
    "yf_KuanDaiFei",
    "yf_ShangWangFei",
    "yf_CheWeiFei",
    "yf_SheShiWeiXiuFei",
    "yf_DianTiFei",
    "yf_MeiQiChuZhuangFei",
    "yf_MeiQiFei",
    "yf_WuYeGuanLiFei",
    "yf_QiTaFei"
  ],
  CQZYJOptions: [
    { name : "产权证", id: "CQZ" },
    { name : "使用权证", id: "SYQZ" },
    { name : "购房合同及发票", id: "GFHTJFP" },
    { name : "借款合同及发票", id: "JKHTJFP" },
    { name : "军产证", id: "JCZ" },
    { name : "地产证", id: "DCZ" },
    { name : "其他", id: "QTYJ" }
  ],
  CQZYJList: [
    "CQZ",
    "SYQZ",
    "GFHTJFP",
    "JKHTJFP",
    "JCZ",
    "DCZ",
    "QTYJ"
  ],
  CQZFYJOptions: [
    { name : "产权证", id: "CQZFYJ" },
    { name : "使用权证", id: "SYQZFYJ" },
    { name : "购房合同及发票", id: "GFHTJFPFYJ" },
    { name : "借款合同及发票", id: "JKHTJFPFYJ" },
    { name : "军产证", id: "JCZFYJ" },
    { name : "地产证", id: "DCZFYJ" },
    { name : "其他", id: "QTYJFYJ" }
  ],
  CQZFYJList: [
    "CQZFYJ",
    "SYQZFYJ",
    "GFHTJFPFYJ",
    "JKHTJFPFYJ",
    "JCZFYJ",
    "DCZFYJ",
    "QTYJFYJ"
  ]
};
