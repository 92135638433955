<template>
    <div v-if="jiaZai">
        <!-- background:red -->
        <div id="print_demo" ref="HaiBao" style="padding-top:40px;">
            <el-row :gutter="20">
                <el-col>
                    <img src="../../../public/img/logo2.jpg" style="width:100%;height:250px" />
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24"  style="text-align:center;margin-top:30px">
                    <div class="grid-content bg-purple">
                         <span style="display: block;font-size:30px;font-weight:bold;">联系人: 
                             <span style="font-weight:bold;font-size:30px;padding-left:20px">{{name}}</span>
                             <span style="font-weight:bold;font-size:30px;padding-left:20px">{{mobile}}</span>
                             </span>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top:30px;">
                <el-col :span="20" :offset="2">
                    <div class="grid-content bg-purple" style="text-align:center">
                        <span style="font-weight:bold;font-size:45px">{{ruleForm.louPanName}}</span>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" :style="{'margin-top': zhuangXiuName == '公共部分精装修' ? '20px' : '30px'}">
                
            </el-row>
            <el-row :gutter="20" style="" >
                 <el-col :span="6" style="position:absolute;left:0">
                    <div class="grid-content bg-purple shouFont">
                        <span v-if="ruleForm.priceToSell != null">售</span>
                        <span v-if="ruleForm.priceToSell == null">租</span>
                    </div>
                </el-col>
                <el-col :span="12" :offset="6">
                    <div class="grid-content bg-purple" style="text-align:center">
                        <div style="font-weight:bold;font-size:65px;color:rgb(223, 2, 2)" v-if="ruleForm.priceToSell != null">{{ruleForm.priceToSell}}&nbsp;<span style="font-size:50px">万</span> </div>
                        <div style="font-weight:bold;font-size:65px;color:rgb(223, 2, 2)" v-if="ruleForm.priceToSell == null">{{ruleForm.priceToLet}}&nbsp;<span style="font-size:50px">元</span> </div>
                    </div>
                </el-col>
            </el-row>
            <el-row style="margin-top:30px;" :style="{'margin-top': zhuangXiuName == '公共部分精装修' ? '15px' : '30px'}">
                <el-col :span="12">
                    <div class="grid-content bg-purple" style="text-align:right;margin-right:15%;font-size:40px">
                        <span>面积: {{ruleForm.square}}㎡</span>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light" style="margin-left:0%;font-size:40px">
                        <span v-if="ruleForm.priceToSell != null">均价: {{ruleForm.unitPrice/10000}}万/平</span>
                        <span v-if="ruleForm.priceToSell == null">单价: {{ruleForm.priceToLet}}元/月</span>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-row :gutter="24" style="margin-top:50px;"> -->
            <!-- <el-col :span="20" :offset="2"> -->
            <!-- <div class="grid-content bg-purple" style="background:#aaa;width:100%;height:5px"></div> -->
            <!-- </el-col> -->
            <!-- </el-row> -->
            <el-row style="margin-top:50px;">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <!-- <el-image v-if="huxingList.length != 0" :src="huxingList[0]" style="width:100%;height: 265px;"></el-image> -->
                        <el-image v-if="tupian != 0" :src="tupian" style="width:100%;height: 200px;padding:5px 0"></el-image>
                        <el-image v-if="tupian != 0" :src="ruleForm.huxingList[0]" style="width:100%;height: 200px;padding:5px 0"></el-image>
                        <!-- <el-image v-else :src="errorImg" style="width:100%;height: 265px;"></el-image> -->
                        <img v-else src="../../../public/img/zanwutupian.jpg" style="width:100%;height: 265px;">
                    </div>
                </el-col>
                <el-col :span="12" :style="{'margin-top': zhuangXiuName == '公共部分精装修' ? '-15px' : '0px'}">
                    <div class="grid-content bg-purple-light" style="margin-left:20%;font-size:40px;line-height:80px">
                        <span style="display: block;">户型: {{ruleForm.huxing}}</span>
                        <span style="display: block;">产权: {{ruleForm.jiaoYiQuanShu}}</span>
                        <span style="display: block;">朝向: {{ruleForm.orientations}}</span>
                        <span style="display: block;">楼层: {{ruleForm.louCeng}}/{{ruleForm.floorCount}}</span>
                        <span style="display: block;">装修: {{zhuangXiuName}}</span>
                        <!-- <span style="display: block;">编号: {{ruleForm.houseNo}}</span>
                        <span style="display: block;">年代: {{ruleForm.jianZhuNianDai}}</span> -->

                    </div>
                </el-col>
            </el-row>
            
            

            
        </div>
        <el-button id="DaYin" v-print="'#print_demo'">打印</el-button>
    </div>
</template>
<script>
export default {
    props: {
        ruleForm: {
            type: Object
        },

        huxingList: {
            type: Array
        },
        tupian: {
            type:String
        }
    },
    data() {
        return {
            jiaZai: false,
            daYinData: [],
            zhuangXiuName: "",
            mobile:''
            // ruleForm: {}
        }
    },

    created() {
        console.log(this.ruleForm, "打印")
        this.getZhuangXiuList()
        console.log(this.ruleForm.shiKanInfo)
        console.log(this.huxingList)
        this.mobile = localStorage.getItem("mobile")
        this.name = localStorage.getItem("staffName")
        // this.ruleForm = JSON.parse(this.$route.query.ruleForm)
    },
    methods: {
        getZhuangXiuList() {
            this.$commonJs
                .getShuJuZiDianList("ZHUANGXIUZHUANGKUANG")
                .then((res) => {
                    this.daYinData = res.ZHUANGXIUZHUANGKUANG
                    console.log(this.daYinData, "里")
                    this.daYinData.forEach((item) => {
                        console.log(item)
                        if (
                            item.commonMasterId ==
                            this.ruleForm.zhuangXiuZhuangKuangId
                        ) {
                            this.zhuangXiuName = item.areaName
                        }
                    })
                    this.jiaZai = true
                })
        }
    }
}
</script>
<style scoped lang="less">
.shouFont {
    color: white;
    border: 5px solid #d3d3d3;
    width: 100px;
    height: 100px;
    background: red;
    border-radius: 50%;
    text-align: center;
    line-height: 95px;
    font-size: 50px;
}
@page {
    margin-top: 1mm; /* 去掉页眉 */
    margin-bottom: 1mm; /* 去掉页脚 */
}
</style>
 
